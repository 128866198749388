import React, {
  createContext, useEffect, useMemo, useState,
} from 'react';
import { debugLog } from '../Logger';
import english from '../../assets/lang/english';
import { ChildrenType, ContextType, ProviderType } from './Types';

const TAG = 'LocalizationProvider: ';

export const LANGUAGE_ENGLISH = 'en';
export const LANGUAGE_BAHASA = 'ba';
const DEFAULT_LANGUAGE = LANGUAGE_BAHASA;
const initialContextData = {
  appLanguage: LANGUAGE_BAHASA,
  setAppLanguage: () => {},
  translations: english,
};
export const LocalizationContext = createContext<ContextType>({ ...initialContextData });
export function LocalizationConsumer({ children }: ChildrenType) {
  return (
    <LocalizationContext.Consumer>
      {(context) => {
        if (context === undefined) {
          throw new Error(
            'LocalizationConsumer must be used within a LocalizationProvider',
          );
        }
        return children(context);
      }}
    </LocalizationContext.Consumer>
  );
}

export function LocalizationProvider({ children }: ProviderType) {
  const [appLanguage, setAppLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const translations = english;
  useEffect(() => {
    debugLog(TAG, `init language : ${appLanguage}`);
  }, [appLanguage]);

  const mProps = useMemo(() => ({
    appLanguage,
    setAppLanguage,
    translations,
  }), [appLanguage]);

  return (
    <LocalizationContext.Provider value={mProps}>
      {children}
    </LocalizationContext.Provider>
  );
}
