import React, { Ref, forwardRef } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import {
  LeftElementAndComponent,
  METAL_CONCENTRATIONS, SOLID_PERCENTAGE, TOTAL_ACID_DESCRIPTION, VISCOSITY_DESCRIPTION, WATER_DESCRIPTION, pdfColorCode,
} from './constant';
import { debugLog } from '../Logger';
import { OilReportPdfDataResultType } from '../../Screens/oilReport/utils/types';
import ChartComponent from '../ChartComponent';

interface OilReportPdfModalPropType {
  show?: boolean;
  oilReportPdfData?: OilReportPdfDataResultType
}

interface GetRectangularComponentPropType {
  leftHeading: string;
  rightHeading: string;
}

const OilReportPdfModal = forwardRef(({ show, oilReportPdfData }: OilReportPdfModalPropType, ref: Ref<HTMLDivElement>) => {
  debugLog(show);
  const bgColor = oilReportPdfData?.ColorDetails?.BackgroundColor;
  const borderColor = oilReportPdfData?.ColorDetails?.BorderColor;
  const emailAddress = 'support@taiservices.com';

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const getColors = (code: string) => (
    <div style={{
      backgroundColor: code,
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      border: '1px solid #000000',
    }}
    />
  );
  // eslint-disable-next-line consistent-return
  const getDotStyle = (colorCode: string) => {
    if (colorCode?.toLowerCase()
      === pdfColorCode.ABNORMAL_CODE?.toLowerCase()
      || colorCode?.toLowerCase()
      === pdfColorCode.ABNORMAL?.toLowerCase()) {
      return getColors('blue');
    }
    if (colorCode?.toLowerCase()
      === pdfColorCode.CAUTION?.toLowerCase()) {
      return getColors('yellow');
    }
    if (colorCode?.toLowerCase()
      === pdfColorCode.SEVERE?.toLowerCase()) {
      return getColors('red');
    }
    return (
      <div style={{
        backgroundColor: '#FFFFFF',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        border: '1px solid #FFFFFF',
      }}
      />
    );
  };

  const getRectangularComponent = ({ leftHeading, rightHeading }: GetRectangularComponentPropType) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          border: `1px solid ${borderColor}`,
          width: '50%',
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {leftHeading}
      </div>
      <div
        style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: '500',
          border: `1px solid ${borderColor}`,
          width: '50%',
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {rightHeading}
      </div>
    </div>
  );

  const oilReportFirstPage = () => (
    <>
      <div style={{
        padding: '10px 0px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
      }}
      >
        <div>
          <img src={oilReportPdfData?.ColorDetails?.LogoLink} style={{ width: '200px', height: '100px' }} alt="" />
        </div>
        <div
          style={{
            padding: '10px 10px',
            borderRadius: '20px',
            border: `2px solid ${borderColor}`,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            backgroundColor: `${bgColor}`,
          }}
        >
          <div style={{ display: 'flex', gap: '30px' }}>
            <div>
              <div
                style={{
                  textAlign: 'left',
                  fontSize: '8px',
                  fontWeight: '600',
                  color: '#000000',
                }}
              >
                TAI Services, Inc.
              </div>
              <div style={{
                textAlign: 'left',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                1000 Cobb Place Blvd NW
              </div>
              <div style={{
                textAlign: 'left',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                Bldg 300, Suite 300
              </div>
              <div style={{
                textAlign: 'left',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                Kennesaw, GA 30144
              </div>
            </div>
            <div>
              <div style={{
                textAlign: 'right',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                Phone: (770) 763-5930
              </div>
              <div style={{
                textAlign: 'right',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                (800) 554-4127
              </div>
              <div style={{
                textAlign: 'right',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                West Coast: (800) 763-3899
              </div>
              <div style={{
                textAlign: 'right',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                Fax: (770) 763-5935
              </div>
              <div style={{
                textAlign: 'right',
                fontSize: '8px',
                fontWeight: '600',
                color: '#000000',
              }}
              >
                (800) 591-6860
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '30px' }}>
            <div style={{
              textAlign: 'left',
              fontSize: '8px',
              fontWeight: '600',
              color: '#000000',
            }}
            >
              Sales:
              <a href={`mailto:${emailAddress}`} onClick={handleEmailClick}>support@taiservices.com</a>
            </div>
            <div style={{
              textAlign: 'right',
              fontSize: '8px',
              fontWeight: '600',
              color: '#000000',
            }}
            >
              Web:
              <a href="www.taiservices.com">www.taiservices.com</a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', gap: '10px' }}>
        <div style={{ width: '20%' }}>
          <div style={{
            fontSize: '12px', fontWeight: '700', color: '#000000', textAlign: 'center',
          }}
          >
            {` UIN ${oilReportPdfData?.PdfOtherDetails?.UinNumnber}`}
          </div>
          <div
            style={{
              fontSize: '10px', fontWeight: '600', color: '#000000', textAlign: 'center',
            }}
          >
            {`Kit #: ${oilReportPdfData?.PdfOtherDetails?.KitNumber}`}
          </div>
          <div
            style={{
              padding: '5px 5px',
              border: `2px solid ${borderColor}`,
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              backgroundColor: `${bgColor}`,
            }}
          >
            <div style={{
              fontSize: '12px', fontWeight: '700', color: '#000000',
            }}
            >
              {oilReportPdfData?.PdfOtherDetails?.ReportName}
            </div>
            <div style={{
              fontSize: '12px', fontWeight: '700', color: '#000000',
            }}
            >
              {`Unit No. ${oilReportPdfData?.PdfOtherDetails?.UinNumnber}`}
            </div>
            <div style={{
              display: 'flex', alignItems: 'center', width: '100%', height: '2px', backgroundColor: `${borderColor}`,
            }}
            />
            <div style={{
              fontSize: '12px', fontWeight: '700', color: '#000000',
            }}
            >
              Unit:
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Make ${oilReportPdfData?.PdfOtherDetails?.UnitMake}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Model ${oilReportPdfData?.PdfOtherDetails?.UnitModel}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Serial No  ${oilReportPdfData?.PdfOtherDetails?.UnitSerialNumber}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Site ${oilReportPdfData?.PdfOtherDetails?.UnitSite}`}
            </div>
            <div style={{
              display: 'flex', alignItems: 'center', width: '100%', height: '2px', backgroundColor: `${borderColor}`,
            }}
            />
            <div style={{
              fontSize: '12px', fontWeight: '700', color: '#000000',
            }}
            >
              Compartment:
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Name ${oilReportPdfData?.PdfOtherDetails?.CompName}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Make ${oilReportPdfData?.PdfOtherDetails?.CompMake}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Model ${oilReportPdfData?.PdfOtherDetails?.CompModel}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Serial No ${oilReportPdfData?.PdfOtherDetails?.CompSerialNumber}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000',
            }}
            >
              {`Capacity ${oilReportPdfData?.PdfOtherDetails?.CompCapacity}`}
              Capacity
            </div>
          </div>
          <div
            style={{
              marginTop: '5px',
              padding: '5px 5px',
              border: `2px solid ${borderColor}`,
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              backgroundColor: `${bgColor}`,
            }}
          >
            <div style={{
              fontSize: '10px', fontWeight: '600', color: '#000000', display: 'flex', justifyContent: 'center',
            }}
            >
              {`Customer: ${oilReportPdfData?.PdfOtherDetails?.CustomerId}`}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '650', color: '#000000',
            }}
            >
              {oilReportPdfData?.PdfOtherDetails?.CustomerName}
            </div>
            <div style={{
              fontSize: '10px', fontWeight: '650', color: '#000000',
            }}
            >
              {oilReportPdfData?.PdfOtherDetails?.CustomerAddress}
            </div>
          </div>
          <div
            style={{
              marginTop: '5px',
              padding: '5px 5px',
              border: `2px solid ${borderColor}`,
              display: 'flex',
              gap: '5px',
              flexDirection: 'column',
              backgroundColor: `${bgColor}`,
            }}
          >
            <div style={{
              fontSize: '12px',
              fontWeight: '800',
              color: '#000000',
              display: 'flex',
              justifyContent: 'center',
              borderBottom: `1px solid ${borderColor}`,
            }}
            >
              Diagnosis
            </div>
            <div style={{
              fontSize: '9px', fontWeight: '650', color: '#000000',
            }}
            >
              {oilReportPdfData?.PdfOtherDetails?.Diagnosis}
            </div>
          </div>
        </div>
        <div className="OilReportPdfModal_body_right">
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }} component="th" scope="row">
                    <div
                      style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                    >
                      Date Sampled
                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Date Received
                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Date Reported
                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                      >
                        {row?.DateSampled?.Value}
                      </div>
                      <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                      >
                        {row?.DateReceived?.Value}
                      </div>
                      <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                      >
                        {row?.DateReported?.Value}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}
                    component="th"
                    scope="row"
                  >
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Lab No.
                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      SIF No.
                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Time on Unit (Hrs)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Time on Oil (Hrs)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Oil Brand

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Oil Type

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Oil Grade

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Oil Added

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Filter

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Oil Changed

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      WO Number

                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.LabNo?.Value}
                        {getDotStyle(row?.LabNo?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.SifNo?.Value}
                        {getDotStyle(row?.SifNo?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.TimeonUnit?.Value}
                        {getDotStyle(row?.TimeonUnit?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.TimeonOil?.Value}
                        {getDotStyle(row?.TimeonOil?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.OilBrand?.Value}
                        {getDotStyle(row?.OilBrand?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.OilType?.Value}
                        {getDotStyle(row?.OilType?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.OilGrade?.Value}
                        {getDotStyle(row?.OilGrade?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.OilAdded?.Value}
                        {getDotStyle(row?.OilAdded?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Filter?.Value}
                        {getDotStyle(row?.Filter?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.OilChanged?.Value}
                        {getDotStyle(row?.OilChanged?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.WoNumber?.Value}
                        {getDotStyle(row?.WoNumber?.Color)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}
                    component="th"
                    scope="row"
                  >
                    <div
                      style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600',
                      }}
                    >
                      Metals (ppm)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Iron (Fe)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Chromium (Cr)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Lead (Pb)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Copper (Cu)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Tin (Sn)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Aluminum (Al)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Nickel (Ni)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Silver (Ag)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Titanium (Ti)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Vanadium (V)

                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div />
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Iron?.Value}
                        {getDotStyle(row?.Iron?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Chromium?.Value}
                        {getDotStyle(row?.Chromium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Lead?.Value}
                        {getDotStyle(row?.Lead?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Copper?.Value}
                        {getDotStyle(row?.Copper?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Tin?.Value}
                        {getDotStyle(row?.Tin?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Aluminum?.Value}
                        {getDotStyle(row?.Aluminum?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Nickel?.Value}
                        {getDotStyle(row?.Nickel?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Silver?.Value}
                        {getDotStyle(row?.Silver?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Titanium?.Value}
                        {getDotStyle(row?.Titanium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Vanadium?.Value}
                        {getDotStyle(row?.Vanadium?.Color)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}
                    component="th"
                    scope="row"
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        fontSize: '10px',
                        fontWeight: '600',
                      }}
                    >
                      Contaminants (ppm)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Silicon (Si)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Sodium (Na)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Potassium (K)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Water by Karl Fischer (PPM)

                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div />
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Silicon?.Value}
                        {getDotStyle(row?.Silicon?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Sodium?.Value}
                        {getDotStyle(row?.Sodium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Potassium?.Value}
                        {getDotStyle(row?.Potassium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.WaterByKarlFischer?.Value}
                        {getDotStyle(row?.WaterByKarlFischer?.Color)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}
                    component="th"
                    scope="row"
                  >
                    <div
                      style={{
                        textAlign: 'left',
                        fontSize: '10px',
                        fontWeight: '600',
                      }}
                    >
                      Additives (ppm)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Magnesium (Mg)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Calcium (Ca)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Barium (Ba)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Phosphoros (P)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Zinc (Zn)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Molybdenum (Mo)

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Boron (B)

                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div />
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Magnesium?.Value}
                        {getDotStyle(row?.Magnesium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Calcium?.Value}
                        {getDotStyle(row?.Calcium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Barium?.Value}
                        {getDotStyle(row?.Barium?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Phosphorus?.Value}
                        {getDotStyle(row?.Phosphorus?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Zinc?.Value}
                        {getDotStyle(row?.Zinc?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Molybdenum?.Value}
                        {getDotStyle(row?.Molybdenum?.Color)}
                      </div>
                      <div
                        style={{
                          textAlign: 'left',
                          fontSize: '10px',
                          fontWeight: '600',
                          color: '#000000',
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                        }}
                      >
                        {row?.Boron?.Value}
                        {getDotStyle(row?.Boron?.Color)}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }} component="th" scope="row">
                    <div
                      style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600',
                      }}
                    >
                      Physical Tests

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Viscosity (cSt 40C)

                    </div>
                    {/* <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}>Solids (%)</div> */}
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div />
                      <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                      >
                        {row?.Viscosity?.Value}

                      </div>
                      {/* <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}>{row?.Solids?.Value}</div> */}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}
                    component="th"
                    scope="row"
                  >
                    <div
                      style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600',
                      }}
                    >
                      Physical/Chemical

                    </div>
                    <div style={{
                      textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                    }}
                    >
                      Acid Number (mgKOH/g)

                    </div>
                  </TableCell>
                  {oilReportPdfData?.TableData?.map((row) => (
                    <TableCell sx={{ border: `2px solid ${borderColor}`, padding: '5px' }}>
                      <div />
                      <div style={{
                        textAlign: 'left', fontSize: '10px', fontWeight: '600', color: '#000000',
                      }}
                      >
                        {row?.AcidNumber?.Value}

                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="OilReportPdfModal_body_right_buttonContainer">
            <div className="OilReportPdfModal_body_right_buttonContainer_column">
              <div style={{ display: 'flex', alignItems: 'center' }} className="OilReportPdfModal_body_left_subHeading">
                Caution
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    border: '1px solid #000000',
                    borderRadius: '50%',
                    background: 'yellow',
                    marginLeft: '34px',
                  }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} className="OilReportPdfModal_body_left_subHeading">
                Abnormal
                <div style={{
                  width: '10px',
                  height: '10px',
                  border: '1px solid #000000',
                  borderRadius: '50%',
                  background: 'blue',
                  marginLeft: '20px',
                }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }} className="OilReportPdfModal_body_left_subHeading">
                Severe
                <div style={{
                  width: '10px',
                  height: '10px',
                  border: '1px solid #000000',
                  borderRadius: '50%',
                  background: 'red',
                  marginLeft: '40px',
                }}
                />
              </div>
            </div>
            <div>
              <VerifiedIcon />
              <VerifiedIcon />
              <VerifiedIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const oilReportThirdPage = () => (
    <div style={{ paddingTop: '20px' }}>
      <div
        className="OilReportPdfModal_heading"
        style={{
          fontSize: '25px', fontWeight: '600', textAlign: 'center',
        }}
      >
        Explanation of Analysis
      </div>
      <div className="OilReportPdfModal_subHeading">(TEST RESULTS ARE NORMAL UNLESS SPECIFIED OTHERWISE)</div>
      {getRectangularComponent({ leftHeading: 'Physical Data', rightHeading: 'Solids % Volume' })}
      <div>{SOLID_PERCENTAGE}</div>
      <div className="OilReportPdfModal_metalText pageSecondText">Metal Concentrations in Parts per Million by Weight</div>
      <TableContainer sx={{ margin: '5px 0px', padding: '0px 5px' }}>
        <Table aria-label="simple table" style={{ backgroundColor: `${bgColor}` }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: 'left', fontSize: '12px', fontWeight: '500',
                }}
                sx={{ border: `2px solid ${borderColor}` }}
              >
                Element
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
                }}
                sx={{ border: `2px solid ${borderColor}` }}
              >
                Component

              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
                }}
                sx={{ border: `2px solid ${borderColor}` }}
              >
                Element

              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
                }}
                sx={{ border: `2px solid ${borderColor}` }}
              >
                Component

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {LeftElementAndComponent?.map((row) => (
              <TableRow
                key={row?.FirstElement}
              >
                <TableCell
                  style={{
                    textAlign: 'left', fontSize: '12px', fontWeight: '500',
                  }}
                  sx={{ border: `2px solid ${borderColor}` }}
                  component="th"
                  scope="row"
                >
                  {row?.FirstElement}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
                  }}
                  sx={{ border: `2px solid ${borderColor}` }}
                >
                  {row?.FirstComponent}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left', fontSize: '12px', fontWeight: '500',
                  }}
                  sx={{ border: `2px solid ${borderColor}` }}
                  component="th"
                  scope="row"
                >
                  {row?.SecondElement}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
                  }}
                  sx={{ border: `2px solid ${borderColor}` }}
                >
                  {row?.SecondComponent}

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{
        textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
      }}
      >
        {METAL_CONCENTRATIONS}

      </div>
      <div className="OilReportPdfModal_additional pageSecondText">Additional Physical Information</div>
      {getRectangularComponent({ leftHeading: 'VIC (Viscosity)', rightHeading: 'Oil Weight' })}
      <div style={{
        textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
      }}
      >
        {VISCOSITY_DESCRIPTION}

      </div>
      {getRectangularComponent({ leftHeading: 'WKF (Water Karl Fisher)', rightHeading: 'Water Content' })}
      <div style={{
        textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
      }}
      >
        {WATER_DESCRIPTION}

      </div>
      {getRectangularComponent({ leftHeading: 'TAN (Total Acid Number)', rightHeading: 'Oil Acidity' })}
      <div style={{
        textAlign: 'left', fontSize: '12px', fontWeight: '500', color: '#000000',
      }}
      >
        {TOTAL_ACID_DESCRIPTION}

      </div>
    </div>
  );

  const oilReportSecondPage = () => (
    <div>
      <div style={{
        marginTop: '100px',
        padding: '10px 0px',
      }}
      >
        <div style={{ width: '100%', alignItems: 'center' }}>
          <img src={oilReportPdfData?.ColorDetails?.LogoLink} style={{ width: '200px', height: '100px' }} alt="" />
        </div>
        <div style={{ width: '100%', alignItems: 'center' }}>
          <ChartComponent
            data={oilReportPdfData?.ChartDetails?.Chart1}
            xKey="Date"
            bar1Key="Viscosity"
            bar1Color="#FF9966"
          />
        </div>
      </div>
      <div style={{ width: '100%', alignItems: 'center' }}>
        <ChartComponent
          data={oilReportPdfData?.ChartDetails?.Chart2}
          xKey="Date"
          bar1Key="Chromium"
          bar2Key="Iron"
          bar1Color="#5a96dc"
          bar2Color="#c8e65a"
        />
      </div>
      <div style={{ width: '100%', alignItems: 'center' }}>
        <ChartComponent
          data={oilReportPdfData?.ChartDetails?.Chart3}
          xKey="Date"
          bar1Key="AcidNumberKoh"
          bar1Color="#9999ff"
        />
      </div>
      <div style={{ width: '100%', alignItems: 'center' }}>
        <ChartComponent
          data={oilReportPdfData?.ChartDetails?.Chart4}
          xKey="Date"
          bar1Key="Copper"
          bar2Key="Lead"
          bar3Key="Tin"
          bar1Color="#ff0000"
          bar2Color="#008000"
          bar3Color="#ffff00"
        />
      </div>
      <div style={{ width: '100%', alignItems: 'center' }}>
        <ChartComponent
          data={oilReportPdfData?.ChartDetails?.Chart5}
          xKey="Date"
          bar1Key="Aluminium"
          bar2Key="Sodium"
          bar3Key="Silicon"
          bar1Color="#CCFFFF"
          bar2Color="#FFFFCC"
          bar3Color="#CCCCFF"
        />
      </div>
    </div>
  );
  return (
    <div ref={ref} style={{ backgroundColor: '#FFFFFF', padding: '0px 10px 10px 10px' }}>
      {oilReportFirstPage()}
      {oilReportSecondPage()}
      <div style={{ marginTop: '500px' }} />
      {oilReportThirdPage()}
    </div>
  );
});

export default OilReportPdfModal;
