import {
  Button,
  Card, Grid, IconButton, TextField, Tooltip,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import RequiredFiled from '../../../../../Common/RequiredField';
import searchIcon from '../../../../../assets/search_icon.svg';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import { MainDefaultValue } from '../utils/constants';
import {
  EnumAddFreonForm, FreonMainFormValues, PayloadAddFreonCylinderTypes,
} from '../utils/types';
import AddCustomerModal from '../../../../../Common/AddCustomerModal';
import { GetCustomerByIdPayload } from '../../../../addCustomer/utils/types';
import { getCustomerByIdStart, removeSelectedCustomer } from '../../../../addCustomer/redux/addCustomerSlice';
import { RootState } from '../../../../../redux/rootState';
import {
  createCylinder, editFreonStart, resetStateFreon, unitPrice,
} from '../redux/sliceAddFreonCylinder';
import EndPoints from '../../../../../Routes/EndPoints';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import { getFreonByIdStart, resetData } from '../../FreonCylinderList/redux/sliceListFreonCylinder';
import { MainFormSchema } from '../utils/validation';
import { setLoader } from '../../../../../redux/loaderSlice';
import { CommonDateInput } from '../../../../../Common/CommonDateInput';

export function ComponentAddFreonCylinder() {
  const navigate = useNavigate();
  const { kitId } = useParams();
  const [kidId, setKitId] = useState('');
  const { getKitDetailsById, isLoading } = useSelector((state: RootState) => state.FreonListReducer);
  const { translations } = useContext(LocalizationContext);

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const { otherCustomerdetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const {
    unitPriceInitial, isSuccess,
  } = useSelector((state: RootState) => state.createFreonReducer);

  const {
    handleSubmit, control,
    formState: { errors },
    setValue, watch, reset, trigger,
  } = useForm<FreonMainFormValues>({
    defaultValues: MainDefaultValue,
    resolver: yupResolver(MainFormSchema as unknown as ObjectSchema<FreonMainFormValues>),
  });

  useEffect(() => {
    if (getKitDetailsById) {
      [
        { name: EnumAddFreonForm.customerSearch, value: getKitDetailsById.CustId },
        { name: EnumAddFreonForm.customer, value: getKitDetailsById.CustId },
        { name: EnumAddFreonForm.contact, value: getKitDetailsById.ContactNumber },
        { name: EnumAddFreonForm.shipVia, value: getKitDetailsById.ShipVia },
        { name: EnumAddFreonForm.blueCylinders, value: getKitDetailsById.NumberOfKits },
        { name: EnumAddFreonForm.cylindersPrice, value: getKitDetailsById.PerUnitPrice },
        { name: EnumAddFreonForm.additionalAmount, value: getKitDetailsById.AdditionalAmount },
        { name: EnumAddFreonForm.billAmount, value: getKitDetailsById.TotalAmount },
        { name: EnumAddFreonForm.invoice, value: getKitDetailsById.InvoiceNumber },
        { name: EnumAddFreonForm.PO, value: getKitDetailsById.PurchaseOrderNumber },
      ].forEach(({ name, value }) => setValue(name, value));
      setKitId(getKitDetailsById?.KitId);
      setValue(EnumAddFreonForm.shipDate, dayjs(new Date(getKitDetailsById?.ShipDate)));
      setValue(EnumAddFreonForm.invoiceDate, dayjs(new Date(getKitDetailsById?.InvoiceDate)));
      // if (getKitDetailsById?.ShipDate) {
      //   const shipDateApi = dayjs(new Date(getKitDetailsById?.ShipDate));
      //   if (shipDateApi) {
      //     setValue(EnumAddFreonForm.shipDate, shipDateApi),
      //   }
      // }
      // if (getKitDetailsById?.InvoiceDate) {
      //   const invoiceDateApi = dayjs(new Date(getKitDetailsById?.InvoiceDate));
      //   if (invoiceDateApi) {
      //     setValue(EnumAddFreonForm.invoiceDate, invoiceDateApi),
      //   }
      // }
    }
  }, [getKitDetailsById]);

  const blueCylinder = watch(EnumAddFreonForm.blueCylinders);
  const cylindersPrice = watch(EnumAddFreonForm.cylindersPrice);
  const additionalAmount = watch(EnumAddFreonForm.additionalAmount);

  const handleTabCell = (customerId: string) => {
    setShowModal(false);
    const payload: GetCustomerByIdPayload = {
      customerId,
      otherCustomer: true,
    };
    dispatch(getCustomerByIdStart(payload));
    setValue(EnumAddFreonForm.customerSearch, customerId);
    trigger(EnumAddFreonForm.customerSearch);
  };

  const onSubmit = (values: FreonMainFormValues) => {
    const formattedShipDate = dayjs(values?.shipDate).format('DD-MM-YYYY') || '';
    const formattedInvoiceDate = dayjs(values?.invoiceDate).format('DD-MM-YYYY') || '';

    const data: PayloadAddFreonCylinderTypes = {
      type: 1,
      CustId: values.customerSearch,
      PurchaseOrderNumber: values.PO,
      ContactNumber: values.contact,
      ShipDate: formattedShipDate,
      ShipVia: values.shipVia,
      NumberOfKits: Number(values.blueCylinders),
      PerUnitPrice: Number(values.cylindersPrice),
      AdditionalAmount: Number(values.additionalAmount),
      TotalAmount: Number(values.billAmount),
      InVoiceNumber: values.invoice,
      InvoiceDate: formattedInvoiceDate,
    };
    if (kitId) {
      const reqBody = {
        ...data,
        KitId: kidId,
      };
      dispatch(editFreonStart(reqBody));
    } else {
      dispatch(createCylinder(data));
    }
  };

  useEffect(() => {
    if (otherCustomerdetails) {
      setValue(EnumAddFreonForm.customer, otherCustomerdetails?.CompanyInformation?.CustomerName);
    }
  }, [otherCustomerdetails]);

  useEffect(() => {
    setValue(EnumAddFreonForm.cylindersPrice, unitPriceInitial);
  }, [unitPriceInitial]);

  useEffect(() => {
    setValue(EnumAddFreonForm.billAmount, Number(blueCylinder) * cylindersPrice + Number(additionalAmount));
  }, [blueCylinder, cylindersPrice, additionalAmount]);

  useEffect(() => {
    dispatch(unitPrice());
    return () => {
      dispatch(resetData());
    };
  }, []);

  const handleCancel = () => {
    dispatch(removeSelectedCustomer());
    dispatch(resetStateFreon());
    navigate(EndPoints.FREON_CYLINDER);
  };

  useEffect(() => {
    if (kitId) {
      const data = {
        KitId: kitId,
      };
      dispatch(getFreonByIdStart(data));
    }
  }, [kitId]);

  useEffect(() => {
    if (isSuccess) {
      navigate(EndPoints.FREON_CYLINDER);
      dispatch(resetStateFreon());
      dispatch(removeSelectedCustomer());
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="div_job_container_info customBodyWithoutBtnContainer">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    <Controller
                      name={EnumAddFreonForm.customerSearch}
                      control={control}
                      render={({ field: { value } }) => (
                        <TextField
                          size="small"
                          placeholder="Customer ID *"
                          value={value}
                          onClick={() => setShowModal(true)}
                          error={!!errors.customerSearch}
                          helperText={errors?.customerSearch?.message}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Search here..">
                                <IconButton onClick={() => setShowModal(true)}>
                                  <img src={searchIcon} alt="" />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={4} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.customer}</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={EnumAddFreonForm.customer}
                          size="small"
                          id={EnumAddFreonForm.customer}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.contact}</label>
                        <ComponentTextInput
                          control={control}
                          regEx={/^[A-Za-z ]+$/}
                          inputProps={{ maxLength: 16 }}
                          name={EnumAddFreonForm.contact}
                          size="small"
                          id={EnumAddFreonForm.contact}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.shipDate}</label>
                        <div className="datePicker_style">
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={shipDate}
                              onChange={(newValue) => dispatch(setShipDate(newValue))}
                              slotProps={{ textField: { size: 'small', fullWidth: true } }}
                            />
                          </LocalizationProvider> */}
                          <CommonDateInput
                            control={control}
                            errors={errors}
                            name={EnumAddFreonForm.shipDate}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.shipVia}</label>
                        <ComponentTextInput
                          control={control}
                          regEx={/^[A-Za-z ]+$/}
                          inputProps={{ maxLength: 20 }}
                          name={EnumAddFreonForm.shipVia}
                          size="small"
                          id={EnumAddFreonForm.shipVia}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} lg={3} xl={2}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          {translations.blueCylinders}
                          {' '}
                          <RequiredFiled />
                        </label>
                        <ComponentTextInput
                          control={control}
                          regEx={/^-?\d+$/}
                          inputProps={{ maxLength: 3 }}
                          name={EnumAddFreonForm.blueCylinders}
                          size="small"
                          id={EnumAddFreonForm.blueCylinders}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} lg={3} xl={2}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          {translations.cylindersPriceEach}
                          {' '}
                          ($)
                        </label>
                        <ComponentTextInput
                          control={control}
                          regEx={/^-?\d+$/}
                          inputProps={{ maxLength: 5 }}
                          name={EnumAddFreonForm.cylindersPrice}
                          size="small"
                          id={EnumAddFreonForm.cylindersPrice}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    {translations.billing}
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={3} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          {translations.additionalAmount}
                          {' '}
                          ($)
                        </label>
                        <ComponentTextInput
                          control={control}
                          regEx={/^-?\d+$/}
                          inputProps={{ maxLength: 18 }}
                          name={EnumAddFreonForm.additionalAmount}
                          size="small"
                          id={EnumAddFreonForm.additionalAmount}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          {translations.billAmount}
                          {' '}
                          ($)
                        </label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          regEx={/^-?\d+$/}
                          name={EnumAddFreonForm.billAmount}
                          size="small"
                          id={EnumAddFreonForm.billAmount}
                        />
                      </div>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.invoice}</label>
                        <ComponentTextInput
                          control={control}
                          inputProps={{ maxLength: 20 }}
                          name={EnumAddFreonForm.invoice}
                          size="small"
                          id={EnumAddFreonForm.invoice}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.invoiceDate}</label>
                        <div className="datePicker_style">
                          <CommonDateInput
                            control={control}
                            errors={errors}
                            name={EnumAddFreonForm.invoiceDate}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">{translations.PO}</label>
                        <ComponentTextInput
                          control={control}
                          inputProps={{ maxLength: 20 }}
                          name={EnumAddFreonForm.PO}
                          size="small"
                          id={EnumAddFreonForm.PO}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={handleCancel}>{translations.cancel}</Button>
                </div>
                <div className="button_margin_left">
                  <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
      <AddCustomerModal show={showModal} setOpenFrom={() => setShowModal(false)} handleTabCell={handleTabCell} />
    </>
  );
}
