import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../Common/Config';
import { makeGetRequest } from '../../../Common/NetworkOps';
import { DataSheetEditResponseFormat, DataSheetQueryPayload, DataSheetResponseFormat } from '../utils/types';
import { showErrorToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  DataSheetActions, getDataSheet, getDataSheetEdit, getDataSheetEditFailure, getDataSheetEditSuccess, getDataSheetFailure, getDataSheetSuccess,
} from './sliceDataSheet';

async function getDataSheetFunction(data: DataSheetQueryPayload): Promise<DataSheetResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.dataSheetURl.getDataSheet}?pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<DataSheetResponseFormat>(url);
  return result.data;
}

export const getDataSheetEpic = (action$: Observable<DataSheetActions>) => action$.pipe(
  ofType(getDataSheet.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: DataSheetQueryPayload) => from(getDataSheetFunction(data)).pipe(
    map((res: DataSheetResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDataSheetSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getDataSheetFailure();
    }),
    takeUntil(action$.pipe(filter(getDataSheet.match))),
    catchError((error) => of(getDataSheetFailure(error))),
  )),
);

async function getDataSheetEditFunction(data: DataSheetQueryPayload): Promise<DataSheetEditResponseFormat> {
  const {
    page, rowsPerPage, searchQuery, sheetId,
  } = data;
  const url = `${Config.dataSheetURl.getDataSheet}?JobOrder=${sheetId}&pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<DataSheetEditResponseFormat>(url);
  return result.data;
}

export const getDataSheetEditEpic = (action$: Observable<DataSheetActions>) => action$.pipe(
  ofType(getDataSheetEdit.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ payload, navigate }) => from(getDataSheetEditFunction(payload)).pipe(
    map((res: DataSheetEditResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDataSheetEditSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      navigate(-1);
      return getDataSheetEditFailure();
    }),
    takeUntil(action$.pipe(filter(getDataSheetEdit.match))),
    catchError((error) => of(getDataSheetEditFailure(error))),
  )),
);
