import * as yup from 'yup';
import { ProductDetailsDataByIdInterface } from '../SendEquipmentScreen/redux/type';
import { EquipmentRequestListingFields } from '../TestEquipmentRequestScreen/redux/type';
import { InventoryFormFields, InventoryIssueEnum } from './constants';
import {
  EquipmentRequestListData,
  InventoryData, InventoryIssueHistoryData, InventoryListData, IssueHistoryData,
} from './types';
import { maxDatePicker, minDatePicker } from '../../../utils/Constant';

export function formatInventoryData(data: InventoryData[]) {
  const res: InventoryListData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: InventoryData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete&issueHistory',
      status: '',
      equipmentType: '',
      category: '',
      product: '',
      description: '',
      serial: '',
      issuesTo: '',
      id: '',
    };
    obj.id = col.ProductNumber;
    obj.product = col.ProductNumber;
    obj.status = col.Status;
    obj.equipmentType = col.EquipmentType;
    obj.category = col.Category;
    obj.description = col.Description;
    obj.serial = col.SerialNumber;
    obj.issuesTo = col.AnalystName;
    res.push(obj);
  });

  return res;
}

export function formatIssueHistoryData(data: IssueHistoryData[]) {
  const res: InventoryIssueHistoryData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: IssueHistoryData, index) => {
    const obj = {
      sn: index,
      analyst: '',
      issued: '',
      returned: '',
      id: '',
    };
    obj.id = col.AnalystName;
    obj.analyst = col.AnalystName;
    obj.issued = col.IssueDate;
    obj.returned = col.ReturnDate;
    res.push(obj);
  });

  return res;
}

export const setInventoryValues = (setValue: SetValue, openedFormData: ProductDetailsDataByIdInterface) => {
  setValue(InventoryFormFields.discarded, openedFormData.IsDiscarded);
  setValue(InventoryFormFields.serialised, openedFormData.IsSerialized);
  setValue(InventoryFormFields.assignedFor, openedFormData.AssignedFor);
  setValue(InventoryFormFields.product, openedFormData.ProductNumber);
  setValue(InventoryFormFields.material, openedFormData.MaterialId);
  setValue(InventoryFormFields.description, openedFormData.Description);
  setValue(InventoryFormFields.uns, openedFormData.UnsNumber);
  setValue(InventoryFormFields.onHand, openedFormData.OnHand);
  setValue(InventoryFormFields.serial, openedFormData.SerialNumber);
  setValue(InventoryFormFields.category, openedFormData.CategoryId);
  setValue(InventoryFormFields.equipmentType, openedFormData.EquipmentTypeId);
  setValue(InventoryFormFields.location, openedFormData.Location);
  setValue(InventoryFormFields.specification, openedFormData.Specification);
};

export const resetSetInventoryValues = (setValue: SetValue) => {
  setValue(InventoryFormFields.status, 'Available');
  setValue(InventoryFormFields.assignedFor, '');
  setValue(InventoryFormFields.material, 0);
  setValue(InventoryFormFields.description, '');
  setValue(InventoryFormFields.uns, '');
  setValue(InventoryFormFields.onHand, '');
  setValue(InventoryFormFields.serial, '');
  setValue(InventoryFormFields.category, '');
  setValue(InventoryFormFields.equipmentType, 0);
  setValue(InventoryFormFields.location, '');
  setValue(InventoryFormFields.specification, '');
  setValue(InventoryFormFields.serialised, false);
  setValue(InventoryFormFields.discarded, false);
};

export const setEquipmentIssueValues = (setValue: SetValue, openedFormData: InventoryData) => {
  setValue(InventoryIssueEnum.serial, openedFormData.SerialNumber);
  setValue(InventoryIssueEnum.analyst, openedFormData.AnalystId);
  setValue(InventoryIssueEnum.product, openedFormData.ProductNumber);
  setValue(InventoryIssueEnum.description, openedFormData.Description);
};

export const resetEquipmentIssueValues = (setValue: SetValue) => {
  setValue(InventoryIssueEnum.serial, '');
  setValue(InventoryIssueEnum.analyst, '');
  setValue(InventoryIssueEnum.product, '');
  setValue(InventoryIssueEnum.description, '');
};

export function formatEquipmentRequestListData(data: EquipmentRequestListingFields[]) {
  const res: EquipmentRequestListData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: EquipmentRequestListingFields, index) => {
    const obj = {
      sn: index,
      job: '',
      vessel: '',
      requestedBy: '',
      action: 'edit&delete',
      id: '',
    };
    obj.id = col.RequestId;
    obj.job = col.UnitId;
    obj.vessel = col.Vessel;
    obj.requestedBy = col.RequestedBy;
    res.push(obj);
  });

  return res;
}

export const inventoryFormSchema = yup.object().shape({
  issuedDate: yup.date().min(minDatePicker).max(maxDatePicker),
});
