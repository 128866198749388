/* eslint-disable max-len */
import React from 'react';

export function CustomersIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 12C16.66 12 17.99 10.66 17.99 9C17.99 7.34 16.66 6 15 6C14.68 6 14.37 6.05 14.09 6.14C14.66 6.95 14.99 7.93 14.99 9C14.99 10.07 14.65 11.04 14.09 11.86C14.37 11.95 14.68 12 15 12ZM10 12C11.66 12 12.99 10.66 12.99 9C12.99 7.34 11.66 6 10 6C8.34 6 7 7.34 7 9C7 10.66 8.34 12 10 12ZM10 14C8 14 4 15 4 17V18C4 18.55 4.45 19 5 19H15C15.55 19 16 18.55 16 18V17C16 15 12 14 10 14ZM16.62 14.16C17.45 14.89 18 15.82 18 17V18.5C18 18.67 17.98 18.84 17.95 19H20.5C20.78 19 21 18.78 21 18.5V17C21 15.46 18.63 14.51 16.62 14.16Z" fill="#092245" />
    </svg>
  );
}
