/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../../../../Common/DataTable';
import { JobColumns } from '../../utils/constants';
import { JobDataInterface, TypesSelectJobModal } from '../../utils/types';
import { getAssociatedUnitsStart, removeBidState } from '../../../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import { RootState } from '../../../../../redux/rootState';
import { formatGeneralUnitData } from '../../utils/helper';
import { setSelectedRowId } from '../../redux/equipmentRequestSlice';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function SelectJobModal({
  show, setOpenFrom, onSelect,
}: TypesSelectJobModal): JSX.Element {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { generalUnitList, totalGeneralUnits } = useSelector((state: RootState) => state.bidsReducer);
  const { selectedRowId } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      bidId: '',
    };
    dispatch(getAssociatedUnitsStart(payload));
  };

  useEffect(() => {
    if (show) {
      handleData();
    }
    return () => {
      dispatch(removeBidState());
    };
  }, [show]);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          Select Job #
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DataTable<JobDataInterface>
                data={generalUnitList?.length > 0 ? formatGeneralUnitData(generalUnitList) : []}
                columns={JobColumns}
                totalRecords={totalGeneralUnits}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
                selectedRadioWithKey={selectedRowId}
                setSelectedRadioWithKey={(id: string) => dispatch(setSelectedRowId(id))}
                radioButtonColumnsWithKey={['sn']}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button
                    className="button_save_and_next"
                    onClick={() => {
                      setOpenFrom();
                      if (onSelect) {
                        onSelect();
                      }
                    }}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SelectJobModal;
