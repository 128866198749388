import React, { useContext, useMemo } from 'react';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ComponentDashboardCard.scss';
import { Grid } from '@mui/material';
import { TypeChartProps } from './utils/TypeDashboard';
import { LocalizationContext } from '../../Common/Localization/LocalizationProvider';

function DashboardChart({ oilKitOrders, freonOrders }: TypeChartProps) {
  const { translations } = useContext(LocalizationContext);

  const calOilKitOrderPercentage = useMemo(() => {
    const totalOrders = oilKitOrders + freonOrders;
    if (totalOrders) {
      const oilKitPercentage = (oilKitOrders / totalOrders) * 100;
      return oilKitPercentage?.toFixed(2);
    }
    return 0;
  }, [oilKitOrders, oilKitOrders]);

  const calFreonOrderPercentage = useMemo(() => {
    const totalOrders = oilKitOrders + freonOrders;
    if (totalOrders) {
      const oilKitPercentage = (freonOrders / totalOrders) * 100;
      return oilKitPercentage?.toFixed(2);
    }
    return 0;
  }, [oilKitOrders, oilKitOrders]);

  const oilKitsReceived = useMemo(() => `${oilKitOrders} - Received`, [oilKitOrders]);
  const freonReceived = useMemo(() => `${freonOrders} - Received`, [freonOrders]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <div className="dashboardCircularbarWrapper">
          <div className="dashboardHeader">
            <div className="dashboardCircularbarText">{translations.numberOfOrder}</div>
          </div>
          <div className="divider" />
          <div className="dashboardCircularbarAlign">
            <div className="dashboardCircularbar">
              <CircularProgressbar
                value={Number(calFreonOrderPercentage)}
                strokeWidth={50}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathColor: '#D74B8E',
                  trailColor: '#6A68C5',
                })}
              />
            </div>
          </div>
          <div className="graphBottomData">
            <div className="buttomWrapper">
              <div className="flex">
                <div className="rectangular" />
                <div className="buttomWrapperText">{translations.oilKits}</div>
              </div>
              <div className="buttomWrapperSubText">{oilKitsReceived}</div>
              <div className="greenText">{`${calOilKitOrderPercentage}%`}</div>
            </div>
            <div className="buttomWrapper">
              <div className="flex">
                <div className="rectangularLightBlue" />
                <div className="buttomWrapperText">{translations.freonCylinders}</div>
              </div>
              <div className="buttomWrapperSubText ml">{freonReceived}</div>
              <div className="greenText">{`${calFreonOrderPercentage}%`}</div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default React.memo(DashboardChart);
