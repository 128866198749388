import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../Common/TabsStyles';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import './Style/index.scss';
import {
  DETAILS_TAB, USERS_TAB, LABEL_DETAILS_TAB, LABEL_USERS_TAB, SUBSCRIBED_CUSTOMER, LABEL_SUBSCRIBED_CUSTOMER, SUBSCRIBED_SITE, LABEL_SUBSCRIBED_SITE,
} from './Utils/ConstantSmc';
import { LocalizationContext } from '../../Common/Localization/LocalizationProvider';
import ComponentDetails from './Components/ComponentDetails';
import ComponentUsers from './Components/ComponentUsers';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import SubscribedCustomer from './Components/SubscribedCustomers';
import { getSmcUserByIdStart, removeSelectedSMC, setSelectedTab } from './Redux/sliceAddSmc';
import SubscribedSites from './Components/SubscribedSites';
import StorageUtils from '../../utils/StorageUtils';
import { SMC_ID } from '../addCustomer/utils/constants';

export default function ScreenAddSmc() {
  const { translations } = useContext(LocalizationContext);
  const { isLoading, selectedTab, smcId: smc } = useSelector((state:RootState) => state.addSmcDetailsReducer);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  useEffect(() => () => {
    dispatch(removeSelectedSMC());
  }, []);

  const { smcId } = useParams();
  useEffect(() => {
    if (smcId) {
      const payload = {
        smcId: String(smcId),
      };
      dispatch(getSmcUserByIdStart(payload));
    }
  }, [smcId]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (smc) {
      const payload = {
        smcId: String(smc),
      };
      dispatch(getSmcUserByIdStart(payload));
      StorageUtils.setString(SMC_ID, smc);
    }
  }, [smc]);

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case DETAILS_TAB:
        return <ComponentDetails />;
      case USERS_TAB:
        return <ComponentUsers />;
      case SUBSCRIBED_CUSTOMER:
        return <SubscribedCustomer />;
      default:
        return <SubscribedSites />;
    }
  }

  return (
    <>
      <ComponentPageHeader subHeading={translations.SafetyManagementCompany} />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          className="main_tabs"
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            className="tab_styles"
            label={LABEL_DETAILS_TAB}
            style={selectedTab === DETAILS_TAB ? selectedTabStyles : tabStyles}
            value={DETAILS_TAB}
            disabled={!smc}
          />
          <Tab
            className="tab_styles"
            label={LABEL_USERS_TAB}
            style={selectedTab === USERS_TAB ? selectedTabStyles : tabStyles}
            value={USERS_TAB}
            disabled={!smc}
          />
          <Tab
            className="tab_styles"
            label={LABEL_SUBSCRIBED_CUSTOMER}
            style={selectedTab === SUBSCRIBED_CUSTOMER ? selectedTabStyles : tabStyles}
            disabled={!smc}
            value={SUBSCRIBED_CUSTOMER}
          />
          <Tab
            className="tab_styles"
            label={LABEL_SUBSCRIBED_SITE}
            style={selectedTab === SUBSCRIBED_SITE ? selectedTabStyles : tabStyles}
            disabled={!smc}
            value={SUBSCRIBED_SITE}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>

  );
}
