import React from 'react';
import { Link } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import styles from '../utils/AnalystReportsStyle.module.scss';
import bannerImage from '../../../assets/BannerImage.svg';

function ReportsDashBoard() {
  return (
    <>
      <ComponentPageHeader subHeading="Dashboard" />
      <div className={`${styles.dashboardCardWrapper} div_job_container_info`}>
        <div className="dashboardBanner"><img src={bannerImage} alt="Banner" height={200} /></div>
      </div>
      <div className="footer">
        Copyright © 2023 &nbsp;
        <Link to="http://taiservices.com/" target="_blank">Taiservices</Link>
        . Designed by&nbsp;
        <Link to="https://binmile.com/" target="_blank">Binmile Technologies Private Limited</Link>
        .&nbsp;All rights reserved.
      </div>
    </>
  );
}

export default ReportsDashBoard;
