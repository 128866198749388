import React from 'react';
import { BayDetailsTableProps } from '../types';

function BayDetailsTable({ data }: BayDetailsTableProps) {
  return (
    <table className="bordered-table w40 noTableBreak">
      {data?.map((value, index) => (
        <tr>
          <td className="textCenter w50">{`Bay ${index + 1}`}</td>
          <td className="textCenter w50">
            {value.toFixed(2)}
            {' '}
            &quot;
          </td>
        </tr>
      ))}
    </table>
  );
}

export default BayDetailsTable;
