import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../../../../Common/DataTable';
import ComponentDeleteModal from '../../../../../Common/DeleteModal/ComponentDeleteModal';
import { RootState } from '../../../../../redux/rootState';
// import { setLoader } from '../../../../../redux/loaderSlice';
import EndPoints from '../../../../../Routes/EndPoints';
import { deleteBidStart, getAssociatedBidsStart, resetJobStarterState } from '../redux/addJobSlice';
import { bidsColumns } from '../utils/constants';
import { formatBidListingData } from '../utils/helper';
import { BidsData } from '../utils/types';

export default function ComponentBid() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const { jobId, bidList, totalBids } = useSelector((state: RootState) => state.jobStarterReducer);
  const [selectedId, setSelectedId] = useState('');

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    navigate(`${EndPoints.ADD_JOB_BID}/${jobId}`);
    dispatch(resetJobStarterState());
  };

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      jobId,
    };
    dispatch(getAssociatedBidsStart(payload));
  };

  useEffect(() => {
    if (String(jobId)) {
      handleData();
    }
  }, [jobId]);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleDeleteModal = () => {
    const payload = {
      jobId,
      bidid: selectedId,
    };
    dispatch(deleteBidStart(payload));
    setDeleteModal(false);
  };

  const handleEdit = (id: string) => {
    navigate(`${EndPoints.BID_WORKSHEET}/${id}`);
    dispatch(resetJobStarterState());
  };

  const generateBid = () => {
    navigate(`/${EndPoints.BID_PDF_SCREEN}/${jobId}`);
  };

  return (
    <>
      <DataTable<BidsData>
        handleButtonClick={handleButtonClick}
        data={formatBidListingData(bidList)}
        columns={bidsColumns}
        totalRecords={totalBids}
        buttonText="Add Bid"
        searchButton="Generate Bid"
        searchButtonDisable={bidList?.length === 0}
        handleSearchButtonClick={generateBid}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
      />

      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </>
  );
}
