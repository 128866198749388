import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { makeGetRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import { BidPdfDetailsResponseFormat } from './types';
import {
  BidPdfActions, getBidPdfDetailsData, getBidPdfDetailsDataFailure, getBidPdfDetailsDataSuccess,
} from './bidPdfSlice';

// ---------------------------------------------------> // GET BID PDF DETAILS
async function getBidPdfDetails(data: string): Promise<BidPdfDetailsResponseFormat> {
  const url = `${Config.bids.getBidGenerationData}?JobId=${data}`;
  const result = await makeGetRequest<BidPdfDetailsResponseFormat>(url);
  return result?.data;
}

export const epicGetBidPdfDetails = (action$: Observable<BidPdfActions>) => action$.pipe(
  filter(getBidPdfDetailsData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getBidPdfDetails(data)).pipe(
    map((res: BidPdfDetailsResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getBidPdfDetailsDataSuccess(res?.BMT.Result);
      }
      return getBidPdfDetailsDataFailure(false);
    }),
    takeUntil(action$.pipe(filter(getBidPdfDetailsData.match))),
    catchError(() => of(getBidPdfDetailsDataFailure(false))),
  )),
);
