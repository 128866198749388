import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddTestEndListData, TestEndListPayloadListing, PayloadTypeDeleteTestEndList,
  PayloadTypeGetTestEndList, TestEndListState, UpdateTestEndListData,
} from '../utils/types';

const initialState: TestEndListState = {
  TestEndListData: {} as TestEndListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const testEndListSlice = createSlice({
  name: 'testEndList',
  initialState,
  reducers: {
    getTestEndListStart(state, _action: PayloadAction<PayloadTypeGetTestEndList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successTestEndList(state, action: PayloadAction<TestEndListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        TestEndListData: action?.payload as TestEndListPayloadListing,
      };
    },
    failureTestEndList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateTestEndList(state, _action: PayloadAction<UpdateTestEndListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateTestEndList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateTestEndList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addTestEndListStart(state, _action: PayloadAction<AddTestEndListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddTestEndList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddTestEndList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteTestEndListStart(state, _action: PayloadAction<PayloadTypeDeleteTestEndList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteTestEndList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteTestEndList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getTestEndListStart, successTestEndList, failureTestEndList, updateTestEndList,
  successUpdateTestEndList, failureUpdateTestEndList, resetState, deleteTestEndListStart,
  successDeleteTestEndList, failureDeleteTestEndList,
  addTestEndListStart, successAddTestEndList, failureAddTestEndList,
} = testEndListSlice.actions;
export const ListTestEndReducer = testEndListSlice.reducer;

export type TestEndTypeActions =
  | ReturnType<typeof getTestEndListStart>
  | ReturnType<typeof successTestEndList>
  | ReturnType<typeof failureTestEndList>

  | ReturnType<typeof updateTestEndList>
  | ReturnType<typeof successUpdateTestEndList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateTestEndList>

  | ReturnType<typeof deleteTestEndListStart>
  | ReturnType<typeof successDeleteTestEndList>
  | ReturnType<typeof failureDeleteTestEndList>

  | ReturnType<typeof addTestEndListStart>
  | ReturnType<typeof successAddTestEndList>
  | ReturnType<typeof failureAddTestEndList>;
