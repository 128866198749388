import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../../../Common/NetworkOps';
import {
  DeleteApiResponse, DeleteFreon, GetFreonByIdPayload, GetFreonByIdResponse, GetFreonCylinderQuery, GetResponseFreon,
} from '../utils/TypesFreonCylinderList';
import {
  getFreonList, getFreonListFailure, getFreonListSuccess, FreonActions, deleteFreonStart, deleteFreonSuccess,
  deleteFreonFailure, getFreonByIdStart, getFreonByIdSuccess, getFreonByIdFailure,
} from './sliceListFreonCylinder';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';
// get Contacts
async function getContacts(data: GetFreonCylinderQuery): Promise<GetResponseFreon> {
  // eslint-disable-next-line max-len
  const url = `${Config.freonCylinder.listFreon}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&Type=1&FromDate=${data.fromDate}&ToDate=${data.toDate}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetResponseFreon>(url);
  return result.data;
}

export const epicFreonList = (action$: Observable<FreonActions>) => action$.pipe(
  ofType(getFreonList.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetFreonCylinderQuery) => from(getContacts(data)).pipe(
    map((res: GetResponseFreon) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getFreonListSuccess(res.BMT.Result);
      }
      return getFreonListFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getFreonList.match))),
    catchError((error) => of(getFreonListFailure(error))),
  )),
);

// Delete Freon
async function deleteFreon(data: DeleteFreon): Promise<DeleteApiResponse> {
  const url = `${Config.freonCylinder.deleteFreon}?Type=1&KitId=${data.kitId}`;
  const result = await makeDeleteRequest<DeleteApiResponse>(url);
  return result.data;
}

export const epicDeleteFreon = (action$: Observable<FreonActions>) => action$.pipe(
  ofType(deleteFreonStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteFreon) => from(deleteFreon(data)).pipe(
    mergeMap((res: DeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
          fromDate: data.fromDate,
          toDate: data.toDate,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteFreonSuccess(), getFreonList(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteFreonFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteFreonStart.match))),
    catchError((error) => of(deleteFreonFailure(error))),
  )),
);

// Get Freon By Id
async function getFreonById(data: GetFreonByIdPayload): Promise<GetFreonByIdResponse> {
  const url = `${Config.freonCylinder.getFreonById}?KitId=${data.KitId}&Type=1`;
  const result = await makeGetRequest<GetFreonByIdResponse>(url);
  return result.data;
}

export const epicFreonById = (action$ : Observable<FreonActions>) => action$.pipe(
  ofType(getFreonByIdStart.type),
  map((x) => x.payload),
  mergeMap((data: GetFreonByIdPayload) => from(getFreonById(data)).pipe(
    map((res: GetFreonByIdResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getFreonByIdSuccess(res.BMT.Result);
      }
      return getFreonByIdFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getFreonByIdStart.match))),
    catchError((error) => of(getFreonByIdFailure(error))),
  )),
);
