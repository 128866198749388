import {
  Box, Button, Grid,
} from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import DataTable from '../../../Common/DataTable';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { Cancel } from '../../../media/images/Cancel';
import { RootState } from '../../../redux/rootState';
import { Datatablestyle } from '../../Job/jobStarter/addJob/utils/constants';
import { formatInspectionSitesTableData } from '../utils/helper';
import { SitesData } from '../../Job/jobStarter/addJob/components/customerSiteInfo/addSiteModal/utils/Type';
import { getGeneralSitesStart } from '../../Job/jobStarter/addJob/redux/addJobSlice';
import { TypesSiteSearchModal } from '../utils/types';

export function ComponentAllSites({ handleClose, handleTabCell }: TypesSiteSearchModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<SitesData[] | []>([]);
  const [selectedRadio, setSelectedRadio] = useState('');
  const {
    generalSites, totalgeneralSites,
  } = useSelector((state:RootState) => state.jobStarterReducer);

  const dispatch = useDispatch();
  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getGeneralSitesStart(payload));
  };

  const { translations } = useContext(LocalizationContext);

  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'siteID', label: 'Site ID' },
      { key: 'site', label: translations.site },
      { key: 'address', label: translations.address },
      { key: 'country', label: translations.country },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
      { key: 'zip', label: 'Zip' },
    ],
    [],
  );

  const handleSave = () => {
    if (handleTabCell) {
      handleTabCell(selectedRadio);
    }
    handleClose();
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    setData(formatInspectionSitesTableData(generalSites));
  }, [generalSites]);

  return (
    <Box sx={Datatablestyle}>
      <div className="customerInfo_container flex-row">
        <div className="customerInfo">
          {translations.siteSelector}
        </div>
        <div className="cancel-icon" onClick={handleClose}>
          <Cancel />
        </div>
      </div>
      <DataTable<SitesData>
        data={data}
        columns={columns}
        totalRecords={totalgeneralSites}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
        radioButtonColumns={['select']}
        customClassName="tableWithExtraTab"
      />
      {
          data.length > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                </div>
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleSave}>{translations.select}</Button>
                </div>
              </div>
            </Grid>
          )
        }
    </Box>
  );
}
