import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import english from '../../../assets/lang/english';
import DataTable from '../../../Common/DataTable';
import { FIRST_SELECTED_TAB, JobUnScheduleTableColumns } from './utils/constants';
import { JobsScheduleTableData } from './utils/types';
import { formatJobsData } from './utils/helper';
import {
  getJobScheduleListData, resetJobScheduleState, setNavigateFromScheduleTab, setSelectedTab,
} from './addJob/redux/jobScheduleSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function UnscheduledJobs() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { jobScheduleData, isLoading } = useSelector((state: RootState) => state.jobScheduleReducer);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      type: 2,
    };
    dispatch(getJobScheduleListData(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    handleData();
    return () => {
      dispatch(resetJobScheduleState());
    };
  }, []);

  const handleScheduleClick = (row: Empty) => {
    dispatch(setSelectedTab(FIRST_SELECTED_TAB));
    dispatch(setNavigateFromScheduleTab(false));
    navigate(`${row?.id}/${row?.jobId}`);
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: jobScheduleData?.Data,
      },
      'JobsUnSchedule_list',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading={String(english.UnscheduledJobs)} downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<JobsScheduleTableData>
          data={formatJobsData(jobScheduleData?.Data)}
          columns={JobUnScheduleTableColumns}
          totalRecords={jobScheduleData?.TotalRecords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleScheduleClick={handleScheduleClick}
        />
      </Grid>
    </Grid>
  );
}

export default UnscheduledJobs;
