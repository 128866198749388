import React, { useEffect } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedTabStyles, stylesTabIndicator, tabsStyles, tabStyles,
} from '../../../Common/TabsStyles';
import './StyleAddAnalyst.scss';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  LABEL_MAIN_TAB, MAIN_TAB, LABEL_VACATION_TAB, VACATION_TAB,
} from '../../addCustomer/utils/constants';
import ComponentMainTab from './component/main/index';
import { RootState } from '../../../redux/rootState';
import VacationComponent from './component/vacation';
import { resetAddAnalystState, setSelectedTab } from './redux/AddAnalystSlice';

export default function AnalystAddScreen() {
  const dispatch = useDispatch();
  const { analystId } = useParams();
  const { selectedTab } = useSelector((state: RootState) => state.AddAnalystSliceReducer);

  function showSelectedTabComponent(tab: number): JSX.Element {
    switch (tab) {
      case MAIN_TAB:
        return <ComponentMainTab />;
      default:
        return <VacationComponent />;
    }
  }

  useEffect(() => {
    dispatch(resetAddAnalystState());
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  const getAnalystHeading = () => {
    if (analystId) {
      return 'Analyst Edit';
    }
    return 'Analyst Add';
  };
  return (
    <>
      <ComponentPageHeader subHeading={getAnalystHeading()} fieldId={analystId} fieldName="Analyst ID" />
      <div className="container_job">
        <Tabs
          style={tabsStyles}
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label={LABEL_MAIN_TAB}
            value={MAIN_TAB}
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
          />
          {analystId && (
          <Tab
            label={LABEL_VACATION_TAB}
            value={VACATION_TAB}
            style={selectedTab === VACATION_TAB ? selectedTabStyles : tabStyles}
          />
          )}
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent(selectedTab)}
      </div>
    </>

  );
}
