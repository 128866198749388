export interface ExpenseReportsData {
  sn: number;
  JobOrder: string;
  Date: string;
  Analyst: string;
  Check: string;
  CheckDate: string;
  id: string;
}

export enum ExpenseFormEnum {
  incomplete = 'incomplete',
  date = 'date',
  analyst = 'analyst',
  jobOrder = 'jobOrder',
  miscDescription = 'miscDescription',

  CashLodge = 'CashLodge',
  ChargeLodge = 'ChargeLodge',
  TotalLodge = 'TotalLodge',

  CashBreak = 'CashBreak',
  ChargeBreak = 'ChargeBreak',
  TotalBreak = 'TotalBreak',

  CashLunch = 'CashLunch',
  ChargeLunch = 'ChargeLunch',
  TotalLunch = 'TotalLunch',

  CashDinner = 'CashDinner',
  ChargeDinner = 'ChargeDinner',
  TotalDinner = 'TotalDinner',

  CashCar = 'CashCar',
  ChargeCar = 'ChargeCar',
  TotalCar = 'TotalCar',

  CashPark = 'CashPark',
  ChargePark = 'ChargePark',
  TotalPark = 'TotalPark',

  CashGas = 'CashGas',
  ChargeGas = 'ChargeGas',
  TotalGas = 'TotalGas',

  CashAir = 'CashAir',
  ChargeAir = 'ChargeAir',
  TotalAir = 'TotalAir',

  CashSkycap = 'CashSkycap',
  ChargeSkycap = 'ChargeSkycap',
  TotalSkycap = 'TotalSkycap',

  CashTaxi = 'CashTaxi',
  ChargeTaxi = 'ChargeTaxi',
  TotalTaxi = 'TotalTaxi',

  CashEnter = 'CashEnter',
  ChargeEnter = 'ChargeEnter',
  TotalEnter = 'TotalEnter',

  CashPhone = 'CashPhone',
  ChargePhone = 'ChargePhone',
  TotalPhone = 'TotalPhone',

  CashMisc = 'CashMisc',
  ChargeMisc = 'ChargeMisc',
  TotalMisc = 'TotalMisc',
}

export interface ExpenseMainFormValues {
  incomplete: boolean,
  date: string,
  analyst: string,
  jobOrder: string,
  miscDescription: string,
  CashLodge: number,
  ChargeLodge: number,
  TotalLodge: number,

  CashBreak: number,
  ChargeBreak: number,
  TotalBreak: number,

  CashLunch: number,
  ChargeLunch: number,
  TotalLunch: number,

  CashDinner: number,
  ChargeDinner: number,
  TotalDinner: number,

  CashCar: number,
  ChargeCar: number,
  TotalCar: number,

  CashPark: number,
  ChargePark: number,
  TotalPark: number,

  CashGas: number,
  ChargeGas: number,
  TotalGas: number,

  CashAir: number,
  ChargeAir: number,
  TotalAir: number,

  CashSkycap: number,
  ChargeSkycap: number,
  TotalSkycap: number,

  CashTaxi: number,
  ChargeTaxi: number,
  TotalTaxi: number,

  CashEnter: number,
  ChargeEnter: number,
  TotalEnter: number,

  CashPhone: number,
  ChargePhone: number,
  TotalPhone: number,

  CashMisc: number,
  ChargeMisc: number,
  TotalMisc: number
}

export interface ExpenseMainFormAPIValues {
  Incomplete: boolean,
  ExpDate: string,
  AnalystId: string,
  JobOrder: string,
  MiscDesc: string,
  Id: number,

  CashLodge: number,
  ChargeLodge: number,
  CashBreak: number,
  ChargeBreak: number,
  CashLunch: number,
  ChargeLunch: number,
  CashDinner: number,
  ChargeDinner: number,
  CashCar: number,
  ChargeCar: number,
  CashPark: number,
  ChargePark: number,
  CashGas: number,
  ChargeGas: number,
  CashAir: number,
  ChargeAir: number,
  CashSkycap: number,
  ChargeSkycap: number,
  CashTaxi: number,
  ChargeTaxi: number,
  CashEnter: number,
  ChargeEnter: number,
  CashPhone: number,
  ChargePhone: number,
  CashMisc: number,
  ChargeMisc: number
}
export interface TypesReceiptsDialogBox {
  show: boolean;
  setOpenFrom: () => void;
  jobOrderId: string;
  page?: number;
  rowsPerPage?: number;
}

export interface ScannedReceiptsDataType {
  sn: number;
  action: string;
  file: string;
  id: string;
}

export interface ExpenseDeleteApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface ExpenseUpdateApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface ExpenseReportDataResponse {
  JobOrder: string;
  ExpDate : string;
  FilePath?: string;
  AnalystName: string;
  CheckNo: string;
  CheckDate: string;
  Id: string;
}

export interface ExpenseReportResult {
  TotalRecords: number;
  Data: ExpenseReportDataResponse[];
}

export interface ExpenseReportResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: ExpenseReportResult;
  }
}

export interface ExpenseReportQueryPayload {
  jobOrderId: string,
  page: string,
  rowsPerPage: string,
  searchQuery: string,
}

export interface ExpenseDeleteApiPayload {
  id: string;
  jobOrderId?: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface ResultDataById {
  Id: number;
  CashLodge: number;
  ChequeLodge: number;
  CashBreak: number;
  ChequeBreak: number;
  CashLunch: number;
  ChequeLunch: number;
  CashDinner: number;
  ChequeDinner: number;
  CashCar: number;
  ChequeCar: number;
  CashPark: number;
  ChequePark: number;
  CashGas: number;
  ChequeGas: number;
  CashAir: number;
  ChequeAir: number;
  CashSkycap: number;
  ChequeSkycap: number;
  CashTaxi: number;
  ChequeTaxi: number;
  CashEnter: number;
  ChequeEnter: number;
  CashPhone: number;
  ChequePhone: number;
  CashMisc: number;
  ChequeMisc: number;
  MiscDesc: string;
  CheckNo: string;
  CheckDate: string;
  AnalystName: string;
  AnalystId: string;
  Incomplete: boolean;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedOn: Date;
  CreatedBy: string;
  ModifiedOn: string;
  ModifiedBy: string | null;
  JobOrder: string;
  IsMigrated: boolean;
  ExpDate: string;
}

interface ExpenseReportFormDataById {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: ResultDataById;
  }
}

export interface AddScannedReceiptData {
  ImageUrl: string;
}
export interface AddScannedReceiptApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: AddScannedReceiptData;
  }
}

export interface TypesScannedImageDialog {
  show: boolean;
  filePath: string;
  setOpenImage: (value: boolean) => void;
}
