import * as Yup from 'yup';

export enum GenerateForms {
  jobOrder = 'jobOrder',
  signature = 'signature',
  reportStyle = 'reportStyle',
}

export const DefaultPDFValues = {
  [GenerateForms.jobOrder]: '',
  [GenerateForms.signature]: '',
  [GenerateForms.reportStyle]: '',
};

export const PDFFormSchema = Yup.object({
  [GenerateForms.jobOrder]: Yup.string(),
  [GenerateForms.signature]: Yup.string(),
  [GenerateForms.reportStyle]: Yup.string(),
});

export enum ReportCheckListForm {
  tubeCounts = 'tubeCounts',
  job = 'job',
  summaryInfo = 'summaryInfo',
  drawing = 'drawing',
  vesselInfo = 'vesselInfo',

  sendReportTo = 'sendReportTo',
  firstField = 'firstField',
  secondField = 'secondField',
  thirdField = 'thirdField',
  forthField = 'forthField',
  retestDateJob = 'retestDateJob',
}

export const DefaultReportCheckListValues = {
  [ReportCheckListForm.tubeCounts]: false,
  [ReportCheckListForm.job]: '',
  [ReportCheckListForm.summaryInfo]: '',
  [ReportCheckListForm.drawing]: '',
  [ReportCheckListForm.vesselInfo]: '',

  [ReportCheckListForm.sendReportTo]: true,
  [ReportCheckListForm.firstField]: '',
  [ReportCheckListForm.secondField]: '',
  [ReportCheckListForm.thirdField]: '',
  [ReportCheckListForm.forthField]: '',
  [ReportCheckListForm.retestDateJob]: false,
};

export const ReportCheckListFormSchema = Yup.object({
  [ReportCheckListForm.tubeCounts]: Yup.boolean(),
  [ReportCheckListForm.job]: Yup.string(),
  [ReportCheckListForm.summaryInfo]: Yup.string(),
  [ReportCheckListForm.drawing]: Yup.string(),
  [ReportCheckListForm.vesselInfo]: Yup.string(),

  [ReportCheckListForm.sendReportTo]: Yup.boolean(),
  [ReportCheckListForm.firstField]: Yup.string(),
  [ReportCheckListForm.secondField]: Yup.string(),
  [ReportCheckListForm.thirdField]: Yup.string(),
  [ReportCheckListForm.forthField]: Yup.string(),
  [ReportCheckListForm.retestDateJob]: Yup.boolean(),
});

export const SendMailDefaultValue = {
  to: '',
  cc: '',
  subject: '',
};

export enum SendEmailForm {
  to = 'to',
  cc = 'cc',
  subject = 'subject',
}

export const radioOptions = [
  { value: '1', label: 'None' },
  { value: '2', label: 'Defects, No Action Required' },
  { value: '3', label: 'Review Defects, Action Required' },
  { value: '4', label: 'No Measurable Defect Noted' },
  { value: '5', label: 'Restricted Tubes' },
];

export const sendEmailSchema = Yup.object({
  to: Yup.string().required('Email is required'),
  cc: Yup.string(),
  subject: Yup.string().required('Subject is required'),
});
