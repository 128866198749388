import {
  Avatar,
  AvatarGroup,
  Card, CardContent, Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import style from '../Utils/RBACStyle.module.scss';

function ComponentCard({ name }: { name:string }) {
  return (
    <Card className={style.RBACCardStyle}>
      <CardContent className={style.cardContentStyle}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Total 4 users
        </Typography>
        <AvatarGroup>
          <Tooltip title="Remy Sharp" placement="top" arrow>
            <Avatar className={style.avatarHover} alt="Remy Sharp" src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250" />
          </Tooltip>
          <Avatar className={style.avatarHover} alt="Travis Howard" src="https://i.pravatar.cc/250?u=mail@ashallendesign.co.uk" />
          <Avatar className={style.avatarHover} alt="Cindy Baker" src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250" />
          <Avatar className={style.avatarHover} alt="Agnes Walker" src="https://i.pravatar.cc/250?u=mail@ashallendesign.co.uk" />
          <Avatar className={style.avatarHover} alt="Trevor Henderson" src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250" />
        </AvatarGroup>
      </CardContent>
      <div className={style.TypographyStyle}>
        <Typography sx={{ fontSize: 25, fontWeight: 600 }}>
          {name}
        </Typography>
      </div>
    </Card>
  );
}

export default ComponentCard;
