import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentDefaultListTable from './Components';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenDefaultTypeStyle() {
  const { DefaultListData } = useSelector((state: RootState) => state.ListDefaultReducer);

  const excelData = useMemo(() => DefaultListData?.Data?.map((item) => {
    const transformedDataItems = {
      Model: item?.Model,
      Style: item?.Style,
      Type: item?.Type,
      Notes: item?.Notes,
    };
    return transformedDataItems;
  }), [DefaultListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Default_types_and_style_list',
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Default Type and Style List" downloadExcel={downloadExcel} />
      </Grid>
      <div className="whiteSpace" />
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentDefaultListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenDefaultTypeStyle;
