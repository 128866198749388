import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card, Grid,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { AddInfoFormNames } from '../utils/constants';
import { InspectionAddInfoFormValues } from '../utils/types';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { RootState } from '../../../redux/rootState';
import { setValues } from '../utils/helper';
import { resetSuccessState, updateInspectionSummaryDataById } from '../redux/sliceInspectionSummary';
import DialogBoxConfirmation from './DialogBoxConfirmation';

function AddInfoInspectionSummary() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { inspectionId } = useParams();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [initialChange, setInitialChange] = useState(true);
  const commentInputRef = useRef<HTMLInputElement>(null);
  const [newComment, setNewComment] = useState<string>('');
  const {
    control, setValue, handleSubmit, watch,
  } = useForm<InspectionAddInfoFormValues>({
    // resolver: yupResolver(unitInfoValidationSchema),
  });
  const { inspectionSummaryDataById, mainFormValue, isSuccess } = useSelector((state: RootState) => state.inspectionSummaryReducer);
  const { codeList } = useSelector((state: RootState) => state.masterDataReducer);
  const previousComment = watch(AddInfoFormNames.comment);

  const onSubmit = (values: InspectionAddInfoFormValues) => {
    const payload = {
      ReportType: mainFormValue,
      SummaryId: Number(inspectionId),
      DefectCode: values?.Code,
      AdditionalInfo: values?.comment,
    };
    dispatch(updateInspectionSummaryDataById(payload));
  };

  useEffect(() => {
    if (inspectionSummaryDataById) {
      setValues(inspectionSummaryDataById, setValue);
    }
  }, [inspectionSummaryDataById]);

  const handleCodeChange = (value: string) => {
    const item = codeList?.find((newItem) => newItem?.Value === value);
    const newComment2 = `${previousComment || ''} ${item?.Description}`.trim();
    if (initialChange) {
      setConfirmationOpen(true);
      setNewComment(newComment2);
    } else {
      setValue(AddInfoFormNames.comment, newComment2);
    }
  };

  const confirmChange = () => {
    setValue(AddInfoFormNames.comment, newComment || '');
    setInitialChange(false);
    setConfirmationOpen(false);
  };

  const cancelChange = () => {
    setConfirmationOpen(false);
    setInitialChange(false);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
    return () => {
      dispatch(resetSuccessState(false));
    };
  }, [isSuccess]);

  useEffect(() => {
    if (commentInputRef.current) {
      commentInputRef.current.focus();
    }
  }, [previousComment]);

  const handleCommentChange = (_name: string, value: string) => {
    if (initialChange) {
      setConfirmationOpen(true);
      setNewComment(value);
    } else {
      setValue(AddInfoFormNames.comment, value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div className="customerInfo_container" style={{ justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
          <span className="customerID">
            Job Order #
          </span>
          <div className="job_input_div">
            <div className="job_input_style">{inspectionSummaryDataById?.JobOrder}</div>
          </div>
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  Code
                </label>
                <ComponentSelectInput
                  control={control}
                  handleChange={handleCodeChange}
                  name={AddInfoFormNames.Code}
                  size="small"
                  entries={codeList || []}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  Additional Info
                </label>
                <ComponentTextInput
                  control={control}
                  name={AddInfoFormNames.comment}
                  id={AddInfoFormNames.comment}
                  size="small"
                  rowSize={12}
                  multiline
                  handleChange={handleCommentChange}
                  inputProps={{ maxLength: 3000 }}
                  inputRef={commentInputRef}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <Card style={{ margin: '20px 0px' }}>
        <div className="customerInfo_container">
          <div className="customerInfo">
            Unit Specification
          </div>
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Manufacturer
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={AddInfoFormNames.infoManufacturer}
                  id={AddInfoFormNames.infoManufacturer}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Model
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={AddInfoFormNames.infoModel}
                  id={AddInfoFormNames.infoModel}
                  size="small"
                />
              </div>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_disabled">
                  Serial #
                </label>
                <ComponentTextInput
                  control={control}
                  isDisable
                  name={AddInfoFormNames.infoSerialNumber}
                  id={AddInfoFormNames.infoSerialNumber}
                  size="small"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <DialogBoxConfirmation
        show={confirmationOpen && initialChange}
        setOpenFrom={cancelChange}
        confirmAction={confirmChange}
      />
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next">Save</Button>
          </div>
        </div>
      </Grid>
    </form>
  );
}

export default AddInfoInspectionSummary;
