import React from 'react';
import InspectionLetterTableComponent from './InspectionLetterTableComponent';

function ScreenDue() {
  return (
    <InspectionLetterTableComponent />
  );
}

export default ScreenDue;
