export enum SiteInfoFields {
  SMC = 'smc',
  SMCName = 'SMCName',
  SMCId = 'SMCId',
  Site = 'site',
  Contact = 'contact',
  Address = 'address',
  OptionalAddress = 'optionalAddress',
  Zip = 'zip',
  State = 'state',
  City = 'city',
  CityCode = 'cityCode',
  Country = 'country',
  PhoneCode = 'phoneCode',
  Phone = 'phone',
  Cell = 'cell',
  SiteType = 'siteType',
  Note = 'note',
  SITE_OTHER_CITY = 'siteOtherCity',
}

export const JobBidTableColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'Date', label: 'Date' },
  { key: 'Job', label: 'Job #' },
  { key: 'Site', label: 'Site' },
  { key: 'Cust', label: 'Cust #' },
  { key: 'TotalBid', label: 'TotalBid' },
];
