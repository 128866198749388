/* eslint-disable max-len */
import React from 'react';

export function DoubleArrowBlackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path d="M17.8461 8.59926L12.4464 2.59958C12.3312 2.47239 12.1704 2.40039 12 2.40039H8.4002C8.16382 2.40039 7.94903 2.53958 7.85183 2.75557C7.75584 2.97276 7.79544 3.22595 7.95383 3.40114L12.9924 9.00003L7.95383 14.5977C7.79544 14.7741 7.75464 15.0273 7.85183 15.2433C7.94903 15.4605 8.16382 15.5997 8.4002 15.5997H12C12.1704 15.5997 12.3312 15.5265 12.4464 15.4017L17.8461 9.40201C18.0513 9.17402 18.0513 8.82604 17.8461 8.59926Z" fill="black" />
      <path d="M10.0465 8.59926L4.64682 2.59958C4.53163 2.47239 4.37084 2.40039 4.20045 2.40039H0.600642C0.364255 2.40039 0.149467 2.53958 0.0522719 2.75557C-0.0437229 2.97276 -0.00412505 3.22595 0.154266 3.40114L5.19279 9.00003L0.154266 14.5977C-0.00412505 14.7741 -0.0449228 15.0273 0.0522719 15.2433C0.149467 15.4605 0.364255 15.5997 0.600642 15.5997H4.20045C4.37084 15.5997 4.53163 15.5265 4.64682 15.4017L10.0465 9.40201C10.2517 9.17402 10.2517 8.82604 10.0465 8.59926Z" fill="black" />
    </svg>
  );
}
