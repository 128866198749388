import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationResponse } from '../../ScreenAddSmc/Utils/TypeSmc';
import { SmcListResult } from '../utils/TypeSmc';

interface SmcListStateState {
  smcList: SmcListResult[];
  isLoading: boolean;
  error: string;
  totalEntries: number;
}

const initialState: SmcListStateState = {
  smcList: [] as SmcListResult[],
  totalEntries: 0,
  isLoading: false,
  error: '',
};

interface PayloadTypeGetSmcUserStart {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}
interface PayloadTypeDeleteSmc {
  smcId: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

const getSmcUserListSlice = createSlice({
  name: 'smcUserList',
  initialState,
  reducers: {
    getSmcUserStart(state, _action: PayloadAction< PayloadTypeGetSmcUserStart >) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSmcUser(state, action: PayloadAction<PaginationResponse<SmcListResult> >) {
      return {
        ...state,
        isLoading: false,
        smcList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureGetSmcUser(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // --------------------------------------------------> delete api
    deleteSmcStart(state, _action: PayloadAction< PayloadTypeDeleteSmc >) {
      return {
        ...state,
        isLoading: true,
        deletingRow: _action.payload,
      };
    },
    successDeleteSmc(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteSmc(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  getSmcUserStart, successGetSmcUser, failureGetSmcUser, deleteSmcStart, successDeleteSmc, failureDeleteSmc,
} = getSmcUserListSlice.actions;
export const getSmcUserReducer = getSmcUserListSlice.reducer;

export type GetSmcUserActions =
| ReturnType<typeof getSmcUserStart>
| ReturnType<typeof successGetSmcUser>
| ReturnType<typeof failureGetSmcUser>
| ReturnType<typeof deleteSmcStart>
| ReturnType<typeof successDeleteSmc>
| ReturnType<typeof failureDeleteSmc>;
