import dayjs from 'dayjs';

export const REFRESH_TOKEN_CODE = 401;
export const FORBIDDEN_CODE = 403;
export const generateInvoiceType = {
  OIL_KITS_TYPE: 2,
  FREON_CYLINDER_TYPE: 1,
};

export const minDatePicker = dayjs('1990-01-01T00:00:00.000');
export const maxDatePicker = dayjs('2099-12-31T00:00:00.000');
