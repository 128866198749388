import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import DataTable from '../../../../../Common/DataTable';
import { ReqEqColumns } from '../../utils/data';
import { ReqEqData } from '../../utils/type';
import { setLoader } from '../../../../../redux/loaderSlice';
import {
  getEquipmentRequestDataStart, resetInventoryEquipmentRequest,
} from '../../../../InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { RootState } from '../../../../../redux/rootState';
import { formatEquipmentRequestListData } from '../../utils/helper';
import { ACCEPT_REQUEST, REJECT_REQUEST, VIEW_REQUEST } from '../../utils/constants';

function ComponentEquipmentDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { unitId } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const { isLoading, equipmentRequestData } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleData = () => {
    const payload = {
      unitId,
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getEquipmentRequestDataStart(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleButtonClick = () => {
    dispatch(resetInventoryEquipmentRequest());
    navigate(`/test-request/${unitId}`);
  };

  const handleView = (id: string) => {
    const submittedRequest = equipmentRequestData?.Data?.find((ele) => Number(ele?.RequestId) === Number(id));
    if (submittedRequest?.Status === 'Submitted') {
      navigate(`/test-request/${unitId}/${id}/${VIEW_REQUEST}`);
      return;
    }
    navigate(`/test-request/${unitId}/${id}/${ACCEPT_REQUEST}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/test-request/${unitId}/${id}/${REJECT_REQUEST}`);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <DataTable<ReqEqData>
          data={equipmentRequestData?.Data?.length > 0 ? formatEquipmentRequestListData(equipmentRequestData?.Data) : []}
          totalRecords={equipmentRequestData?.TotalRecords}
          buttonText="Add Request"
          columns={ReqEqColumns}
          handleButtonClick={handleButtonClick}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleView={handleView}
          handleEdit={handleEdit}
        />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12}>
        <div className="save_and_next_div">
          <Button className="button_save_and_next">
            Copy Request to Another Unit
          </Button>
        </div>
      </Grid> */}
    </Grid>
  );
}

export default ComponentEquipmentDetails;
