export enum ProfileFormValue {
  LAST_NAME = 'lastName',
  FIRST_NAME = 'firstName',
  EMAIL = 'email',
  ADDRESS = 'address',
  PHONE = 'phone',
  CELL = 'cell',
  CODE = 'code',
  TITLE = 'title',
  INITIALS = 'initials',
}

export const MainDefaultValue = {
  title: 1,
  code: '+91',
};
