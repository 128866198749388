import { InputAdornment, TextField } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowUpDownIcon, ShuffleIcon,
} from '../../../../assets/TableIcons';
import { UnitData, UpdateExcelPayloadAction } from '../../utils/types';
import { RootState } from '../../../../redux/rootState';
import { getAllComponentSerialNumbersStart, setCompData, updateExcelDataComp } from '../../redux/oilReportSlice';
import NoData from '../../../../assets/NoData.svg';
import { ModalPagination } from '../pagination';
import { GetListingPayload } from '../../../../utils/type';

export function ReplaceCompartment() {
  const {
    selectedCompartment, allComponentSerialNumbers,
    excelData, totalComponentSerialNumbers,
  } = useSelector((state:RootState) => state.oilReportReducer);
  const dispatch = useDispatch();

  // eslint-disable-next-line max-len
  const selectedComp:string = useMemo(() => excelData.find((data) => data.ReportId === selectedCompartment)?.CompartmentSerialNumber || '', [selectedCompartment]);
  // eslint-disable-next-line max-len
  const selectedUnit:string = useMemo(() => excelData.find((data) => data.ReportId === selectedCompartment)?.UnitSerialNumber || '', [selectedCompartment]);

  const [compPage, setCompUnitPage] = useState(1);
  const [compRowsPerPage, setCompUnitRowsPerPage] = useState(10);
  const [compSearchQuery, setCompUnitSearchQuery] = useState('');

  const handleCompData = () => {
    const payload:GetListingPayload = {
      page: compPage,
      rowsPerPage: compRowsPerPage,
      searchQuery: compSearchQuery,
    };
    dispatch(getAllComponentSerialNumbersStart(payload));
  };

  const handleReplaceComp = (value: string) => {
    const ReplacedValue = excelData.find((data) => data.ReportId === selectedCompartment)?.CompartmentSerialNumber || '';
    const obj:UnitData = {
      ReportId: selectedCompartment,
      Value: value,
      ReplacedValue,
    };

    const updateExcelPayload:UpdateExcelPayloadAction = {
      unitSerialNumber: value,
      isReplaced: true,
    };

    dispatch(setCompData(obj));
    dispatch(updateExcelDataComp(updateExcelPayload));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompUnitSearchQuery(event.target.value);
    const payload:GetListingPayload = {
      page: compPage,
      rowsPerPage: compRowsPerPage,
      searchQuery: event.target.value,
    };
    dispatch(getAllComponentSerialNumbersStart(payload));
  };

  return (
    <div className="CustomTableContainer CustomTableBorderedContainer">
      <div className="CustomSearchTable">
        <TextField
          className="subHeaderTextfield"
          size="small"
          placeholder="Search..."
          value={compSearchQuery}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div className="oilReportTableContainer">
        <div className="oilReportTableHead">
          <div className="headerTableCell wd-5">
            SN
            {' '}
            <ArrowUpDownIcon />
          </div>
          <div className="headerTableCell wd-45">
            Selected Unit & Comp
            {' '}
            <ArrowUpDownIcon />
          </div>
          <div className="headerTableCell wd-45">
            Compartments
            {' '}
            <ArrowUpDownIcon />
          </div>
        </div>
        {totalComponentSerialNumbers > 0
          ? (
            <div className="oilReportTableBody">
              <div className="oilReportTableList">
                <div className="oilReportTableCell wd-5">1</div>
                <div className="oilReportTableCell wd-45">{`${selectedUnit} & ${selectedComp}`}</div>
                <div className="oilReportTableCell wd-45">
                  <div>{allComponentSerialNumbers?.[0]?.CompartmentSerialNumber}</div>
                  <div
                    className="actionBtn"
                    onClick={() => handleReplaceComp(allComponentSerialNumbers?.[0]?.CompartmentSerialNumber)}
                  >
                    <ShuffleIcon />
                  </div>
                </div>
              </div>
              {allComponentSerialNumbers?.slice(1)?.map((entry) => (
                <div className="oilReportTableList" key={entry.ReportId}>
                  <div className="oilReportTableCell noBorder wd-5">&nbsp;</div>
                  <div className="oilReportTableCell noBorder wd-45">&nbsp;</div>
                  <div className="oilReportTableCell wd-45">
                    <div>{entry.CompartmentSerialNumber}</div>
                    <div
                      className="actionBtn"
                      onClick={() => handleReplaceComp(entry.CompartmentSerialNumber)}
                    >
                      <ShuffleIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
          : (
            <div className="CustomTableContainer CustomTableBorderedContainer allCentered">
              <div className=""><img src={NoData} alt="No data found" /></div>
            </div>
          )}
      </div>
      <ModalPagination
        rowsPerPage={compRowsPerPage}
        page={compPage}
        setRowsPerPage={setCompUnitRowsPerPage}
        setPage={setCompUnitPage}
        totalEntries={totalComponentSerialNumbers}
        handleData={handleCompData}
      />
    </div>
  );
}
