/* eslint-disable max-len */
import React from 'react';

export function CalendarIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M19.9714 20.4864H4.02857C3.75578 20.4864 3.49416 20.378 3.30126 20.1851C3.10837 19.9923 3 19.7306 3 19.4578V8.4007C3 8.3325 3.02709 8.26709 3.07532 8.21887C3.12354 8.17065 3.18894 8.14355 3.25714 8.14355H20.7429C20.8111 8.14355 20.8765 8.17065 20.9247 8.21887C20.9729 8.26709 21 8.3325 21 8.4007V19.4578C21 19.7306 20.8916 19.9923 20.6987 20.1851C20.5058 20.378 20.2442 20.4864 19.9714 20.4864ZM3.51429 8.65784V19.4578C3.51429 19.5942 3.56847 19.725 3.66492 19.8215C3.76136 19.9179 3.89217 19.9721 4.02857 19.9721H19.9714C20.1078 19.9721 20.2386 19.9179 20.3351 19.8215C20.4315 19.725 20.4857 19.5942 20.4857 19.4578V8.65784H3.51429Z" fill="#092245" />
      <path d="M20.7429 8.65628H3.25714C3.18894 8.65628 3.12354 8.62919 3.07532 8.58096C3.02709 8.53274 3 8.46733 3 8.39913V5.57056C3 5.29777 3.10837 5.03615 3.30126 4.84325C3.49416 4.65036 3.75578 4.54199 4.02857 4.54199H6.34286C6.41106 4.54199 6.47646 4.56908 6.52468 4.61731C6.57291 4.66553 6.6 4.73094 6.6 4.79914C6.6 4.86733 6.57291 4.93274 6.52468 4.98096C6.47646 5.02919 6.41106 5.05628 6.34286 5.05628H4.02857C3.89217 5.05628 3.76136 5.11046 3.66492 5.20691C3.56847 5.30336 3.51429 5.43417 3.51429 5.57056V8.14199H20.4857V5.57056C20.4857 5.43417 20.4315 5.30336 20.3351 5.20691C20.2386 5.11046 20.1078 5.05628 19.9714 5.05628H17.6571C17.5889 5.05628 17.5235 5.02919 17.4753 4.98096C17.4271 4.93274 17.4 4.86733 17.4 4.79914C17.4 4.73094 17.4271 4.66553 17.4753 4.61731C17.5235 4.56908 17.5889 4.54199 17.6571 4.54199H19.9714C20.2442 4.54199 20.5058 4.65036 20.6987 4.84325C20.8916 5.03615 21 5.29777 21 5.57056V8.39913C21 8.46733 20.9729 8.53274 20.9247 8.58096C20.8765 8.62919 20.8111 8.65628 20.7429 8.65628Z" fill="#092245" />
      <path d="M15.857 5.05628H8.14276C8.07456 5.05628 8.00916 5.02919 7.96094 4.98096C7.91271 4.93274 7.88562 4.86733 7.88562 4.79914C7.88562 4.73094 7.91271 4.66553 7.96094 4.61731C8.00916 4.56908 8.07456 4.54199 8.14276 4.54199H15.857C15.9252 4.54199 15.9907 4.56908 16.0389 4.61731C16.0871 4.66553 16.1142 4.73094 16.1142 4.79914C16.1142 4.86733 16.0871 4.93274 16.0389 4.98096C15.9907 5.02919 15.9252 5.05628 15.857 5.05628Z" fill="#092245" />
      <path d="M17.4 6.6H16.1143C15.9779 6.6 15.8471 6.54582 15.7506 6.44937C15.6542 6.35292 15.6 6.22211 15.6 6.08571V3.51429C15.6 3.37789 15.6542 3.24708 15.7506 3.15063C15.8471 3.05418 15.9779 3 16.1143 3H17.4C17.5364 3 17.6672 3.05418 17.7636 3.15063C17.8601 3.24708 17.9143 3.37789 17.9143 3.51429V6.08571C17.9143 6.22211 17.8601 6.35292 17.7636 6.44937C17.6672 6.54582 17.5364 6.6 17.4 6.6ZM16.1143 3.51429V6.08571H17.4V3.51429H16.1143Z" fill="#092245" />
      <path d="M7.88569 6.6H6.59998C6.46358 6.6 6.33277 6.54582 6.23632 6.44937C6.13988 6.35292 6.08569 6.22211 6.08569 6.08571V3.51429C6.08569 3.37789 6.13988 3.24708 6.23632 3.15063C6.33277 3.05418 6.46358 3 6.59998 3H7.88569C8.02209 3 8.1529 3.05418 8.24935 3.15063C8.3458 3.24708 8.39998 3.37789 8.39998 3.51429V6.08571C8.39998 6.22211 8.3458 6.35292 8.24935 6.44937C8.1529 6.54582 8.02209 6.6 7.88569 6.6ZM7.88569 3.51429H6.59998V6.08571H7.88569V3.51429Z" fill="#092245" />
      <path d="M18.091 12.2573H16.8372C16.7307 12.2572 16.6286 12.2148 16.5533 12.1395C16.478 12.0642 16.4357 11.9622 16.4355 11.8557V10.6019C16.4357 10.4954 16.478 10.3933 16.5533 10.318C16.6286 10.2427 16.7307 10.2003 16.8372 10.2002H18.091C18.1975 10.2003 18.2996 10.2427 18.3749 10.318C18.4502 10.3933 18.4926 10.4954 18.4927 10.6019V11.8557C18.4926 11.9622 18.4502 12.0642 18.3749 12.1395C18.2996 12.2148 18.1975 12.2572 18.091 12.2573ZM16.9498 11.7431H17.9784V10.7145H16.9498V11.7431Z" fill="#092245" />
      <path d="M14.4483 12.2573H13.1948C13.0882 12.2573 12.9861 12.2149 12.9107 12.1396C12.8354 12.0643 12.793 11.9622 12.7928 11.8557V10.6019C12.793 10.4953 12.8354 10.3932 12.9107 10.3179C12.9861 10.2426 13.0882 10.2003 13.1948 10.2002H14.4483C14.5548 10.2003 14.6569 10.2427 14.7322 10.318C14.8075 10.3933 14.8499 10.4954 14.85 10.6019V11.8557C14.8499 11.9622 14.8075 12.0642 14.7322 12.1395C14.6569 12.2148 14.5548 12.2572 14.4483 12.2573ZM13.3071 11.7431H14.3357V10.7145H13.3071V11.7431Z" fill="#092245" />
      <path d="M10.8053 12.2573H9.55168C9.4452 12.2572 9.34311 12.2148 9.26782 12.1395C9.19252 12.0642 9.15016 11.9622 9.15002 11.8557V10.6019C9.15016 10.4954 9.19252 10.3933 9.26782 10.318C9.34311 10.2427 9.4452 10.2003 9.55168 10.2002H10.8053C10.9118 10.2003 11.0139 10.2426 11.0893 10.3179C11.1646 10.3932 11.207 10.4953 11.2072 10.6019V11.8557C11.207 11.9622 11.1646 12.0643 11.0893 12.1396C11.0139 12.2149 10.9118 12.2573 10.8053 12.2573ZM9.66431 11.7431H10.6929V10.7145H9.66431V11.7431Z" fill="#092245" />
      <path d="M18.091 15.3403H16.8372C16.7307 15.3402 16.6286 15.2978 16.5533 15.2226C16.478 15.1473 16.4357 15.0452 16.4355 14.9387V13.6849C16.4357 13.5784 16.478 13.4763 16.5533 13.401C16.6286 13.3257 16.7307 13.2833 16.8372 13.2832H18.091C18.1975 13.2833 18.2996 13.3257 18.3749 13.401C18.4502 13.4763 18.4926 13.5784 18.4927 13.6849V14.9387C18.4926 15.0452 18.4502 15.1473 18.3749 15.2226C18.2996 15.2978 18.1975 15.3402 18.091 15.3403ZM16.9498 14.8261H17.9784V13.7975H16.9498V14.8261Z" fill="#092245" />
      <path d="M14.4483 15.3403H13.1948C13.0882 15.3403 12.9861 15.2979 12.9107 15.2226C12.8354 15.1473 12.793 15.0452 12.7928 14.9387V13.6849C12.793 13.5783 12.8354 13.4762 12.9107 13.4009C12.9861 13.3256 13.0882 13.2833 13.1948 13.2832H14.4483C14.5548 13.2833 14.6569 13.3257 14.7322 13.401C14.8075 13.4763 14.8499 13.5784 14.85 13.6849V14.9387C14.8499 15.0452 14.8075 15.1473 14.7322 15.2226C14.6569 15.2978 14.5548 15.3402 14.4483 15.3403ZM13.3071 14.8261H14.3357V13.7975H13.3071V14.8261Z" fill="#092245" />
      <path d="M10.8053 15.3403H9.55168C9.4452 15.3402 9.34311 15.2978 9.26782 15.2226C9.19252 15.1473 9.15016 15.0452 9.15002 14.9387V13.6849C9.15016 13.5784 9.19252 13.4763 9.26782 13.401C9.34311 13.3257 9.4452 13.2833 9.55168 13.2832H10.8053C10.9118 13.2833 11.0139 13.3256 11.0893 13.4009C11.1646 13.4762 11.207 13.5783 11.2072 13.6849V14.9387C11.207 15.0452 11.1646 15.1473 11.0893 15.2226C11.0139 15.2979 10.9118 15.3403 10.8053 15.3403ZM9.66431 14.8261H10.6929V13.7975H9.66431V14.8261Z" fill="#092245" />
      <path d="M7.16257 15.3403H5.90874C5.80225 15.3402 5.70017 15.2978 5.62487 15.2226C5.54958 15.1473 5.50722 15.0452 5.50708 14.9387V13.6849C5.50722 13.5784 5.54958 13.4763 5.62487 13.401C5.70017 13.3257 5.80225 13.2833 5.90874 13.2832H7.16257C7.26905 13.2833 7.37113 13.3257 7.44643 13.401C7.52173 13.4763 7.56409 13.5784 7.56422 13.6849V14.9387C7.56409 15.0452 7.52173 15.1473 7.44643 15.2226C7.37113 15.2978 7.26905 15.3402 7.16257 15.3403ZM6.02137 14.8261H7.04994V13.7975H6.02137V14.8261Z" fill="#092245" />
      <path d="M14.4483 18.4312H13.1948C13.0882 18.4311 12.9861 18.3888 12.9107 18.3135C12.8354 18.2382 12.793 18.136 12.7928 18.0295V16.7757C12.793 16.6692 12.8354 16.567 12.9107 16.4917C12.9861 16.4164 13.0882 16.3741 13.1948 16.374H14.4483C14.5548 16.3742 14.6569 16.4165 14.7322 16.4918C14.8075 16.5671 14.8499 16.6692 14.85 16.7757V18.0295C14.8499 18.136 14.8075 18.2381 14.7322 18.3134C14.6569 18.3887 14.5548 18.431 14.4483 18.4312ZM13.3071 17.9169H14.3357V16.8883H13.3071V17.9169Z" fill="#092245" />
      <path d="M10.8053 18.4312H9.55168C9.4452 18.431 9.34311 18.3887 9.26782 18.3134C9.19252 18.2381 9.15016 18.136 9.15002 18.0295V16.7757C9.15016 16.6692 9.19252 16.5671 9.26782 16.4918C9.34311 16.4165 9.4452 16.3742 9.55168 16.374H10.8053C10.9118 16.3741 11.0139 16.4164 11.0893 16.4917C11.1646 16.567 11.207 16.6692 11.2072 16.7757V18.0295C11.207 18.136 11.1646 18.2382 11.0893 18.3135C11.0139 18.3888 10.9118 18.4311 10.8053 18.4312ZM9.66431 17.9169H10.6929V16.8883H9.66431V17.9169Z" fill="#092245" />
      <path d="M7.16257 18.4312H5.90874C5.80225 18.431 5.70017 18.3887 5.62487 18.3134C5.54958 18.2381 5.50722 18.136 5.50708 18.0295V16.7757C5.50722 16.6692 5.54958 16.5671 5.62487 16.4918C5.70017 16.4165 5.80225 16.3742 5.90874 16.374H7.16257C7.26905 16.3742 7.37113 16.4165 7.44643 16.4918C7.52173 16.5671 7.56409 16.6692 7.56422 16.7757V18.0295C7.56409 18.136 7.52173 18.2381 7.44643 18.3134C7.37113 18.3887 7.26905 18.431 7.16257 18.4312ZM6.02137 17.9169H7.04994V16.8883H6.02137V17.9169Z" fill="#092245" />
    </svg>
  );
}
