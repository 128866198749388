import dayjs from 'dayjs';
import { ConcentrationFields, MainFormFields, ResultFields } from './constant';
import { EditReportForm, ReportDetailsById } from './types';

export function assignValues(selectedReportDetails:ReportDetailsById, setValue: SetValue) {
  [
    { name: MainFormFields.TaiReportNumber, value: selectedReportDetails?.TaiReportNo },
    { name: MainFormFields.SampleNumber, value: selectedReportDetails?.SampleNo },
    { name: MainFormFields.ReportDate, value: selectedReportDetails?.ReportDate ? dayjs(selectedReportDetails?.ReportDate, 'DD-MM-YY') : dayjs() },
    { name: MainFormFields.SerialNumber, value: selectedReportDetails?.SerialNo },
    {
      name: MainFormFields.DateReceived,
      value: selectedReportDetails?.DateReceived ? dayjs(selectedReportDetails?.DateReceived, 'DD-MM-YY') : dayjs(),
    },
    { name: MainFormFields.Location, value: selectedReportDetails?.Location },
    {
      name: MainFormFields.DateDrawn,
      value: selectedReportDetails?.DateDrawn ? dayjs(selectedReportDetails?.DateDrawn, 'DD-MM-YY') : dayjs(),
    },
    { name: MainFormFields.UnitMake, value: selectedReportDetails?.UnitMake },
    { name: MainFormFields.UnitModel, value: selectedReportDetails?.UnitModel },
    {
      name: MainFormFields.DateTested,
      value: selectedReportDetails?.DateTested ? dayjs(selectedReportDetails?.DateTested, 'DD-MM-YY') : dayjs(),
    },
    { name: MainFormFields.CustomerNumber, value: selectedReportDetails?.CustNo },
    { name: MainFormFields.Manufacturer, value: selectedReportDetails?.Manufacture },
    { name: MainFormFields.Model, value: selectedReportDetails?.Model },
    { name: MainFormFields.KitNumber, value: selectedReportDetails?.KitNo },
    { name: MainFormFields.UnitNumber, value: selectedReportDetails?.UnitNo },
    { name: MainFormFields.CompanySerialNumber, value: selectedReportDetails?.CompanySerial },
    { name: MainFormFields.Component, value: selectedReportDetails?.Component },

    { name: ConcentrationFields.Silicon, value: selectedReportDetails?.Silicone },
    { name: ConcentrationFields.Iron, value: selectedReportDetails?.Iron },
    { name: ConcentrationFields.Chromium, value: selectedReportDetails?.Chromium },
    { name: ConcentrationFields.Aluminium, value: selectedReportDetails?.Aluminium },
    { name: ConcentrationFields.Copper, value: selectedReportDetails?.Cooper },
    { name: ConcentrationFields.Lead, value: selectedReportDetails?.Lead },
    { name: ConcentrationFields.Tin, value: selectedReportDetails?.Tin },
    { name: ConcentrationFields.Nickel, value: selectedReportDetails?.Nickele },
    { name: ConcentrationFields.Molybdenum, value: selectedReportDetails?.Molybdenum },
    { name: ConcentrationFields.Sodium, value: selectedReportDetails?.Sodium },
    { name: ConcentrationFields.Magnesium, value: selectedReportDetails?.Magnisium },
    { name: ConcentrationFields.Barium, value: selectedReportDetails?.Barium },
    { name: ConcentrationFields.Phosphorus, value: selectedReportDetails?.Phosphorus },
    { name: ConcentrationFields.Calcium, value: selectedReportDetails?.Calcium },
    { name: ConcentrationFields.Visc, value: selectedReportDetails?.Visc },
    { name: ConcentrationFields.Zinc, value: selectedReportDetails?.Zinc },
    { name: ConcentrationFields.WaterKF, value: selectedReportDetails?.WaterKf },
    { name: ConcentrationFields.TAN, value: selectedReportDetails?.Tan },
    { name: ConcentrationFields.Potassium, value: selectedReportDetails?.Potassium },
    { name: ConcentrationFields.Manganese, value: selectedReportDetails?.Manganese },
    { name: ConcentrationFields.Titanium, value: selectedReportDetails?.Titanium },
    { name: ConcentrationFields.Vanadium, value: selectedReportDetails?.Vanadium },

    { name: ResultFields.OilChanged, value: selectedReportDetails?.OilChanged },
    { name: ResultFields.FluidType, value: selectedReportDetails?.FluidType },
    { name: ResultFields.FluidBrand, value: selectedReportDetails?.FluidBrand },
    { name: ResultFields.FluidGrade, value: selectedReportDetails?.FluidGrade },
    { name: ResultFields.MHMRUnit, value: selectedReportDetails?.MhMrUnit },
    { name: ResultFields.CompAge, value: selectedReportDetails?.CompAge },
    { name: ResultFields.FilterAge, value: selectedReportDetails?.FilterAge },
    { name: ResultFields.MhOil, value: selectedReportDetails?.MhOll },
    { name: ResultFields.OilAdded, value: selectedReportDetails?.OilAdded },
    { name: ResultFields.Condition, value: selectedReportDetails?.Condition },
    { name: ResultFields.Result, value: selectedReportDetails?.Result },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function assignPayload(values:EditReportForm, reportId: string) {
  return {
    ReportId: reportId,
    CustId: values.customerNumber,
    TaiReportNo: values.taiReportNumber,
    SampleNo: values.sampleNumber,
    ReportDate: values.reportDate ? dayjs(values?.reportDate).format('DD-MM-YYYY') : '',
    SerialNo: values.serialNumber,
    DateReceived: values.dateRecieved ? dayjs(values?.dateRecieved).format('DD-MM-YYYY') : '',
    Location: values.location,
    DateDrawn: values.dateDrawn ? dayjs(values?.dateDrawn).format('DD-MM-YYYY') : '',
    UnitMake: values.unitMake,
    UnitModel: values.unitModel,
    DateTested: values.dateTested ? dayjs(values?.dateTested).format('DD-MM-YYYY') : '',
    CustNo: values.customerNumber,
    Manufacture: values.manufacturer,
    Model: values.model,
    KitNo: values.kitNumber,
    UnitNo: values.unitNumber,
    CompanySerial: values.companySerialNumber,
    Component: values.component,
    Silicone: values.silicon,
    Iron: values.iron,
    Chromium: values.chromium,
    Aluminium: values.aluminium,
    Cooper: values.copper,
    Lead: values.lead,
    Tin: values.tin,
    Nickele: values.nickel,
    Molybdenum: values.molybdenum,
    Magnisium: values.magnesium,
    Sodium: values.sodium,
    Boron: values.boron,
    Barium: values.barium,
    Phosphorus: values.phosphorus,
    Calcium: values.calcium,
    Visc: values.visc,
    Zinc: values.zinc,
    WaterKf: values.waterKF,
    Tan: values.tan,
    Potassium: values.potassium,
    Titanium: values.titanium,
    Vanadium: values.vanadium,
    Manganese: values.manganese,
    FluidType: values.fluidType,
    FluidBrand: values.fluidBrand,
    FluidGrade: values.fluidGrade,
    MhMrUnit: values.mhMrUnit,
    CompAge: values.compAge,
    FilterAge: values.filterAge,
    MhOll: values.mhOil,
    OilAdded: values.oilAdded,
    Condition: values.condition,
    Result: values.result,
    OilChanged: values.oilChanged,
  };
}
