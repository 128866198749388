import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { ReportsInformationRes, ReportsInformationListingInterface } from './utils/reportsTypes';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { getVesselInformationStart, onCopyReportsStart } from '../VesselInformation/redux/sliceVesselInformation';
import { ReportsInformationTableColumns } from './utils/reportsHelper';
import { AnalystData, AnalystDataPayload } from '../VesselInformation/utils/type';
import { convertDateFormat } from '../../utils/CommonFunctions';

function ReportsInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { vesselInformation, totalVesselInfo, isLoading } = useSelector((state:RootState) => state.VesselInformationReducer);
  const [data, setData] = useState<ReportsInformationListingInterface[]>([]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getVesselInformationStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const tableData: ReportsInformationListingInterface[] = useMemo(() => {
    if (vesselInformation?.length > 0) {
      const transformedData = vesselInformation?.map((ele, index) => ({
        sn: index,
        action: 'eye',
        jobOrder: ele.JobOrder,
        vessel: ele.VesselName,
        serialNumber: ele.SerialNumber,
        jobSite: ele.SiteName,
        modelNumber: ele.Model,
        analyst: ele.Analyst,
        inspectionDate: convertDateFormat(ele.InspectionDate),
        id: `${ele.JobOrder}_${ele.VesselId}_${ele.AnalystId}`,
      }));
      return transformedData;
    }
    return [];
  }, [vesselInformation]);

  useEffect(() => {
    if (vesselInformation) {
      setData(tableData);
    }
  }, [vesselInformation]);

  const handleClickViews = (id: string) => {
    const record = vesselInformation.find((ele:ReportsInformationRes) => `${ele.JobOrder}_${ele.VesselId}_${ele.AnalystId}` === id);
    if (record) {
      navigate(`${record.JobOrder}/${record.VesselId}/${record.AnalystId}`);
    }
  };

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: ReportsInformationListingInterface) => {
      if (element?.id === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    handleData();
  }, []);

  const excelData = useMemo(() => vesselInformation?.map((item) => {
    const transformedDataItems = {
      JobOrder: item.JobOrder,
      VesselName: item.VesselName,
      SerialNumber: item.SerialNumber,
      JobSite: item.SiteName,
      ModelNumber: item.Model,
      Analyst: item.Analyst,
      InspectionDate: convertDateFormat(item.InspectionDate),
    };
    return transformedDataItems;
  }), [vesselInformation]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'REPORTS INFORMATION',
    );
  };

  const handleButtonClick = () => {
    const jobIds: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        jobIds.push(element?.id);
      }
    });
    const payload = {
      selectedIds: jobIds,
    };
    const payLoad: AnalystDataPayload = {
      data: payload?.selectedIds?.map((item): AnalystData => {
        const parts = item.split('_');
        return {
          JobOrder: `${parts[0]}`,
          VesselId: parts[1],
          AnalystId: parts[2],
        };
      }),
    };
    dispatch(onCopyReportsStart({ payLoad, handleData }));
  };

  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="REPORTS" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<ReportsInformationListingInterface>
          data={data?.length > 0 ? data : []}
          columns={ReportsInformationTableColumns}
          totalRecords={totalVesselInfo}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          buttonText="Download"
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleViews={handleClickViews}
          checkBoxWithKey={['sn']}
          handleCheckBoxWithKey={handleCheckBoxWithKey}
          handleButtonClick={handleButtonClick}
          textButtonDisabled={jobSelected}
        />
      </Grid>
    </Grid>
  );
}

export default ReportsInformation;
