import {
  Observable,
  catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  sendOtpData, otpDataSendSuccess, otpDataSendFailure, OtpActions,
} from './sliceOtpVerify';
import { makePostRequest } from '../../../../Common/NetworkOps';
import { OtpDataTypes, OtpResponseData } from '../utils/types';
import Config from '../../../../Common/Config';

async function otp(data: OtpDataTypes): Promise<OtpResponseData> {
  const url = `${Config.auth.otpVerify}`;
  const result = await makePostRequest<OtpResponseData>(url, data);
  return result.data;
}

export const epicOtpVerify = (action$: Observable<OtpActions>) => action$.pipe(
  filter(sendOtpData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: OtpDataTypes) => from(otp(data)).pipe(
    map((res: OtpResponseData) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return otpDataSendSuccess(res.BMT.Result);
      }
      return otpDataSendFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendOtpData.match))),
    catchError((error: AxiosError<OtpResponseData>) => of(otpDataSendFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
