export enum ChangePaswordFormVal {
  CURRENT_PASSWORD = 'OldPassword',
  NEW_PASSWORD = 'NewPassword',
  CONFIRM_PASSWORD = 'ConfirmPassword',
}
export const initialValuesChangePwd = {
  OldPassword: '',
  NewPassword: '',
  ConfirmPassword: '',
};
