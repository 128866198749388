import {
  Grid, Card,
} from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { RootState } from '../../../../redux/rootState';
import { phoneRegex } from '../../../../utils/regex';
import { CutomerContactForm, ICustomerContact } from '../../utils/types';
import './StyleMainTab.scss';

export default function CustomerContact({ control, errors }:ICustomerContact): JSX.Element {
  const { phoneCodes, salutations } = useSelector((state:RootState) => state.masterDataReducer);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Contact(Contractor)
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="div_label_text">
              <label className="no_label_Style" />
              <ComponentSelectInput
                name={CutomerContactForm.contactTitle}
                control={control}
                required
                size="small"
                entries={salutations}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">First Name</label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                name={CutomerContactForm.contactFirstName}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                id={CutomerContactForm.contactFirstName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Last Name   </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={CutomerContactForm.contactLastName}
                id={CutomerContactForm.contactLastName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Email ID   </label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 255 }}
                className="nonCapitalizeField"
                name={CutomerContactForm.contactEmailId}
                id={CutomerContactForm.contactEmailId}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Code</label>
              <ComponentSelectInput
                name={CutomerContactForm.contactCountryCode}
                control={control}
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Phone</label>
              <ComponentTextInput
                regEx={phoneRegex}
                control={control}
                name={CutomerContactForm.contactPhone}
                id={CutomerContactForm.contactPhone}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Alternative Phone</label>
              <ComponentTextInput
                control={control}
                name={CutomerContactForm.contactAlternativePhone}
                id={CutomerContactForm.contactAlternativePhone}
                regEx={phoneRegex}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
