/* eslint-disable max-len */
import React from 'react';

export function IssueHistoryIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3277_61978)">
        <path d="M5.4839 5.83596H11.1091C11.3032 5.83596 11.4607 5.67856 11.4607 5.48439C11.4607 5.29021 11.3032 5.13281 11.1091 5.13281H5.4839C5.28972 5.13281 5.13232 5.29021 5.13232 5.48439C5.13232 5.67856 5.28972 5.83596 5.4839 5.83596Z" fill="#00A751" />
        <path d="M12.867 7.2422C12.867 7.04803 12.7095 6.89062 12.5154 6.89062H5.4839C5.28972 6.89062 5.13232 7.04803 5.13232 7.2422C5.13232 7.43637 5.28972 7.59377 5.4839 7.59377H12.5154C12.7095 7.59377 12.867 7.43637 12.867 7.2422Z" fill="#00A751" />
        <path d="M12.8669 9.00001C12.8669 8.80584 12.7095 8.64844 12.5153 8.64844H7.24171C7.04754 8.64844 6.89014 8.80584 6.89014 9.00001C6.89014 9.19419 7.04754 9.35159 7.24171 9.35159H12.5153C12.7095 9.35159 12.8669 9.19419 12.8669 9.00001Z" fill="#00A751" />
        <path d="M14.6249 9.38304V4.36948C14.6249 4.0878 14.5152 3.82296 14.316 3.62369L11.9153 1.22303C11.7161 1.02379 11.4513 0.914062 11.1695 0.914062H4.42924C3.84767 0.914062 3.37452 1.38721 3.37452 1.96879V6.60455L2.29712 5.52715C1.77232 5.00235 0.918415 5.00239 0.393584 5.52715C-0.131212 6.05194 -0.131177 6.90585 0.393584 7.43064L3.37448 10.4115V14.6254C3.37448 15.207 3.84763 15.6801 4.42921 15.6801H11.1516C11.8616 16.5385 12.9343 17.0864 14.1327 17.0864C16.2651 17.0864 18 15.3516 18 13.2191C18 11.2534 16.5258 9.62556 14.6249 9.38304ZM1.90293 7.94556L2.812 7.03649L6.37204 10.5965L6.8266 11.9602L5.46294 11.5056L1.90293 7.94556ZM0.890816 6.02438C1.14149 5.77374 1.54928 5.77371 1.79992 6.02438L2.3148 6.53926L1.40573 7.44833L0.890851 6.93345C0.640213 6.68284 0.640213 6.27502 0.890816 6.02438ZM4.42924 14.9771C4.23538 14.9771 4.07767 14.8193 4.07767 14.6255V11.1148L5.02442 12.0615C5.06302 12.1001 5.11007 12.1292 5.16185 12.1465L7.2713 12.8496C7.30773 12.8617 7.34524 12.8677 7.38243 12.8677C7.47423 12.8677 7.56409 12.8317 7.6311 12.7647C7.72526 12.6705 7.75813 12.5313 7.71604 12.4049L7.01289 10.2955C6.99563 10.2437 6.96656 10.1967 6.92795 10.1581L4.0777 7.3078V1.96879C4.0777 1.77493 4.23542 1.61721 4.42928 1.61721H11.1092V3.72666C11.1092 4.11501 11.424 4.42981 11.8123 4.42981H13.9218V9.3577C12.9792 9.4086 12.1254 9.79864 11.4803 10.4076C11.4738 10.4072 11.4673 10.4066 11.4608 10.4066H8.2966C8.10242 10.4066 7.94502 10.564 7.94502 10.7582C7.94502 10.9523 8.10242 11.1097 8.2966 11.1097H10.893C10.6828 11.4315 10.519 11.7862 10.4116 12.1645H8.64817C8.454 12.1645 8.2966 12.3219 8.2966 12.516C8.2966 12.7102 8.454 12.8676 8.64817 12.8676H10.2816C10.2711 12.9835 10.2654 13.1007 10.2654 13.2192C10.2654 13.8518 10.4181 14.4493 10.6885 14.9771H4.42924ZM14.1327 16.3834C12.388 16.3834 10.9685 14.9639 10.9685 13.2192C10.9685 11.4745 12.388 10.055 14.1327 10.055C15.8774 10.055 17.2969 11.4745 17.2969 13.2192C17.2969 14.9639 15.8774 16.3834 14.1327 16.3834Z" fill="#00A751" />
        <path d="M14.1328 10.9336C13.8416 10.9336 13.6055 11.1697 13.6055 11.461V13.2188C13.6055 13.5101 13.8416 13.7462 14.1328 13.7462C14.4241 13.7462 14.6602 13.5101 14.6602 13.2188V11.461C14.6602 11.1697 14.4241 10.9336 14.1328 10.9336Z" fill="#00A751" />
        <path d="M14.1328 15.5039C14.4241 15.5039 14.6602 15.2678 14.6602 14.9766C14.6602 14.6853 14.4241 14.4492 14.1328 14.4492C13.8416 14.4492 13.6055 14.6853 13.6055 14.9766C13.6055 15.2678 13.8416 15.5039 14.1328 15.5039Z" fill="#00A751" />
      </g>
      <defs>
        <clipPath id="clip0_3277_61978">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
