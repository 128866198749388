import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  BidInfoProps, BidWorkSheetFormValues, CreateBidPayload, DriverExpensePayload, UpdateBidPayload,
} from '../../utils/types';
import { BidWorkSheetFormNames } from '../../utils/constants';
import { RootState } from '../../../../../redux/rootState';
import EndPoints from '../../../../../Routes/EndPoints';
import { getJobByIdStart, getSiteTypesStart } from '../../../jobStarter/addJob/redux/addJobSlice';
import {
  assignAnalystValues, assignCustomerValues, assignSiteValues, constructBidPricingPayload,
} from '../../utils/helper';
import { setLoader } from '../../../../../redux/loaderSlice';
import {
  createBidStart, getAnalystCityStart, getBidByIdStart, getBidPricingStart,
  getDriverExpenseStart, getNearestAnalystStart, updateBidStart,
} from '../../redux/bidWorksheetSlice';
import DialogBoxCustomerNotes from '../customerNotesPopup';
import BidInfoForm from './formComponent';

export default function BidInfo({ bidPricingValues, scrollTop }:BidInfoProps) {
  const {
    control, setValue, watch, handleSubmit,
  } = useForm<BidWorkSheetFormValues>();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    analystDetails, isLoading: bidLoader, selectedBid, nearestAnalystDetails, bidId: SelectedBidId, customerAnalyst,
  } = useSelector((state:RootState) => state.bidsReducer);
  const { otherCustomerdetails, isLoading } = useSelector((state:RootState) => state.addCustomerReducer);
  const {
    selectedJob, selectedSite, isLoading: jobLoading,
  } = useSelector((state:RootState) => state.jobStarterReducer);
  const { pathname } = useLocation();
  const { bidId, jobId } = useParams();
  const dispatch = useDispatch();

  const driveFrom = watch(BidWorkSheetFormNames.drivenFrom);
  const roundMiles = watch(BidWorkSheetFormNames.roundTripMiles);
  const gulfCoastFL = watch(BidWorkSheetFormNames.gulfCoastFL);

  useEffect(() => {
    if (selectedBid?.BidId) {
      setIsEdit(true);
    } else if (pathname?.includes(EndPoints.ADD_JOB_BID)) {
      setIsEdit(false);
      dispatch(getJobByIdStart(String(jobId)));
    } else {
      dispatch(getBidByIdStart(String(bidId)));
      setIsEdit(true);
    }
    dispatch(getSiteTypesStart());
  }, [pathname]);

  const handleAction = () => {
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading || jobLoading || bidLoader));
  }, [isLoading, jobLoading, bidLoader]);

  useEffect(() => {
    if (selectedJob?.JobInfo) {
      setValue(BidWorkSheetFormNames.custId, selectedJob?.JobInfo?.CustId);
      const payload = {
        custId: selectedJob?.JobInfo?.CustId || '',
        siteId: selectedJob?.JobInfo?.SiteId || '',
        bidId: SelectedBidId || '',
      };
      dispatch(getBidPricingStart(payload));
    }
  }, [selectedJob]);

  useEffect(() => {
    if (otherCustomerdetails?.CompanyInformation) {
      assignCustomerValues(setValue, otherCustomerdetails);
    }
  }, [otherCustomerdetails]);

  useEffect(() => {
    if (selectedSite?.SiteId) {
      assignSiteValues(setValue, selectedSite);
    }
    if (!SelectedBidId && selectedSite?.City) {
      dispatch(getNearestAnalystStart(selectedSite?.City));
    }

    if (SelectedBidId && selectedSite?.City && !(analystDetails?.AnalystCities?.length > 0)) {
      const payload = {
        analystId: selectedBid?.BidDetails?.AnalystId,
        siteCity: selectedSite?.City,
        calculateDistance: true,
      };
      dispatch(getAnalystCityStart(payload));
    }
  }, [selectedSite]);

  useEffect(() => {
    if (driveFrom && analystDetails?.BaseCity) {
      assignAnalystValues(setValue, analystDetails, driveFrom);
    }
  }, [driveFrom, analystDetails]);

  useEffect(() => {
    if (analystDetails?.AnalystCities) {
      setValue(BidWorkSheetFormNames.drivenFrom, analystDetails?.AnalystCities[0].Value);
    }
  }, [analystDetails]);

  useEffect(() => {
    if (customerAnalyst?.RoundTripMiles) {
      setValue(BidWorkSheetFormNames.roundTripMiles, customerAnalyst?.RoundTripMiles);
    }
  }, [customerAnalyst]);

  useEffect(() => {
    if (selectedJob?.JobInfo?.SiteId) {
      const payload:DriverExpensePayload = {
        roundTripMiles: roundMiles || 0,
        calculatedDays: Number(bidPricingValues?.calculatedDays) || 0,
        overridedays: Number(bidPricingValues?.overRideDays) || 0,
        siteId: selectedJob?.JobInfo?.SiteId,
        gulfCoastFL: gulfCoastFL || false,
        airfare: Number(bidPricingValues?.airfare) || 0,
      };
      dispatch(getDriverExpenseStart(payload));
    }
  }, [roundMiles, bidPricingValues?.overRideDays, selectedJob, bidPricingValues?.calculatedDays, gulfCoastFL, bidPricingValues?.airfare]);

  useEffect(() => {
    if (nearestAnalystDetails?.NearestAnalyst && selectedSite.City) {
      setValue(BidWorkSheetFormNames.analystName, nearestAnalystDetails.NearestAnalyst);
      setValue(BidWorkSheetFormNames.roundTripMiles, nearestAnalystDetails.Distance);
    }
  }, [nearestAnalystDetails, selectedSite]);

  useEffect(() => {
    if (selectedBid.BidId) {
      [
        { name: BidWorkSheetFormNames.analystName, value: selectedBid?.BidDetails.AnalystId },
        { name: BidWorkSheetFormNames.outOfCountry, value: selectedBid?.BidDetails.IsOutOfCountry },
        { name: BidWorkSheetFormNames.drivenFrom, value: selectedBid?.BidDetails.DriveFrom },
        { name: BidWorkSheetFormNames.gulfCoastFL, value: selectedBid?.BidDetails.GulfCoastFL },
        { name: BidWorkSheetFormNames.roundTripMiles, value: selectedBid?.BidDetails.RoundTripMiles },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [selectedBid]);

  const onSubmit = (values:BidWorkSheetFormValues) => {
    const bidPayload = {
      JobId: String(jobId),
      GulfCoastFL: values.gulfCoastFL || false,
      AnalystId: values.analystName || '0',
      DriveFrom: values.drivenFrom || '0',
      RoundTripMiles: Number(values.roundTripMiles) || 0,
      IsOutOfCountry: values.outOfCountry || false,
    };
    const bidPricingPayload = constructBidPricingPayload(bidPricingValues);
    if (isEdit) {
      const payload: UpdateBidPayload = {
        BidId: SelectedBidId,
        BidDetails: { ...bidPayload, JobId: selectedBid?.BidDetails.JobId },
        BidPriceDetails: bidPricingPayload,
      };
      dispatch(updateBidStart(payload));
    } else {
      const payload: CreateBidPayload = {
        BidDetails: bidPayload,
        BidPriceDetails: bidPricingPayload,
      };
      dispatch(createBidStart(payload));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BidInfoForm control={control} handleAction={handleAction} watch={watch} scrollTop={() => scrollTop()} />
      </form>
      <DialogBoxCustomerNotes notes={otherCustomerdetails?.CompanyInformation?.CustomerNote || ''} show={open} setOpenFrom={handleAction} />
    </>
  );
}
