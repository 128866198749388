/* eslint-disable max-len */
import React from 'react';

export function SidebarOpenIcon() {
  return (
    <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8594 4.76156L2.01563 4.76156C1.77872 4.76156 1.55386 4.81264 1.38104 4.90448C1.21749 4.99141 1.05 5.14587 1.05 5.36847C1.05 5.59107 1.21749 5.74554 1.38104 5.83246C1.55386 5.92431 1.77872 5.97539 2.01562 5.97539L18.8594 5.97539C19.0963 5.97539 19.3211 5.92431 19.494 5.83247C19.6575 5.74554 19.825 5.59108 19.825 5.36848C19.825 5.14588 19.6575 4.99141 19.494 4.90449C19.3211 4.81264 19.0963 4.76156 18.8594 4.76156Z" fill="#092245" stroke="#092245" strokeWidth="0.4" />
      <path d="M18.5582 4.3534L20.0881 5.36797L18.5616 6.38028C18.2682 6.55943 18.2256 6.91192 18.3848 7.17712C18.5616 7.47185 18.9377 7.52278 19.1922 7.35313L21.4422 5.85313C21.6129 5.73929 21.7 5.56288 21.7 5.36797C21.7 5.17306 21.6129 4.99665 21.4422 4.88281L19.1922 3.38281C19.0958 3.31853 18.9797 3.29297 18.875 3.29297C18.7042 3.29297 18.5065 3.37584 18.3898 3.55078C18.2122 3.81724 18.3031 4.1833 18.5578 4.35313L18.5582 4.3534Z" fill="#092245" stroke="#092245" strokeWidth="0.4" />
      <path d="M1.625 10.45H15.125C15.4417 10.45 15.7 10.1917 15.7 9.875C15.7 9.55829 15.4417 9.3 15.125 9.3H1.625C1.30829 9.3 1.05 9.55829 1.05 9.875C1.05 10.1917 1.30829 10.45 1.625 10.45ZM1.625 1.45H15.125C15.4417 1.45 15.7 1.19171 15.7 0.875C15.7 0.558293 15.4417 0.3 15.125 0.3H1.625C1.30829 0.3 1.05 0.558293 1.05 0.875C1.05 1.19171 1.30829 1.45 1.625 1.45Z" fill="#002646" stroke="#092245" strokeWidth="0.4" />
    </svg>
  );
}
