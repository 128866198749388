/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../../../Common/Config';
import { makeGetRequest, makePostRequest, makePutRequest } from '../../../../../Common/NetworkOps';
import {
  AddOilKitsActions, onCreatedOilKit, onCreatedOilKitFailure, onCreateOilKit,
  onAddedKit, getKitRangeList, onEditSuccessOilKit, onEditOilKit, getKitRangeListData, gotDetailsById, getDetailsById, onAddKit, onDeleteKit, onDeletedKit, getOilKitsUnitPrice, gotOilKitsUnitPriceFailure, gotOilKitsUnitPrice,
} from './addOilKitsSlice';
import {
  Empty,
  GetAddOilKitResponseFormat, GetDetailsOilKitsResponseFormat, GetKitRangeResponseFormat, OilKitsAddDetails, OilKitsDetails, OilKitsRangePayload,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';
import { BMTResponse } from '../../../FreonCylinder/AddFreonCylinder/utils/types';

async function createOilKitData(data: OilKitsDetails): Promise<GetAddOilKitResponseFormat> {
  const url = Config.oilKits.createKit;
  const result = await makePostRequest<GetAddOilKitResponseFormat>(url, data);
  return result.data;
}

export const addOilKitEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(onCreateOilKit.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(createOilKitData(data)).pipe(
    map((res: GetAddOilKitResponseFormat) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        return onCreatedOilKit();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onCreatedOilKitFailure();
    }),
    takeUntil(action$.pipe(filter(onCreateOilKit.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function getOilKitRangeData(data: OilKitsRangePayload): Promise<GetKitRangeResponseFormat> {
  const url = `${Config.oilKits.GetOilKitRange}?KitId=${data?.kitId}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<GetKitRangeResponseFormat>(url);
  return result.data;
}

export const getOilKitRangeListEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(getKitRangeList.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(getOilKitRangeData(data)).pipe(
    map((res: GetKitRangeResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getKitRangeListData(res?.BMT?.Result);
      }
      return onCreatedOilKitFailure();
    }),
    takeUntil(action$.pipe(filter(getKitRangeList.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function createOilRangeData(data: OilKitsAddDetails): Promise<GetAddOilKitResponseFormat> {
  const url = Config.oilKits.createKit;
  const result = await makePostRequest<GetAddOilKitResponseFormat>(url, data);
  return result.data;
}

export const addOilRangeEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(onAddKit.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(createOilRangeData(data)).pipe(
    map((res: GetAddOilKitResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return onAddedKit();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onCreatedOilKitFailure();
    }),
    takeUntil(action$.pipe(filter(onAddKit.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function deleteOilRangeData(data: OilKitsAddDetails): Promise<Empty> {
  const url = Config.oilKits.deleteKitRange;
  const result = await makePutRequest<Empty>(url, data);
  return result.data;
}

export const addOilRangeDeleteEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(onDeleteKit.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(deleteOilRangeData(data)).pipe(
    map((res: Empty) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return onDeletedKit(res.BMT.ResponseCode);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onDeletedKit(res.BMT.ResponseCode);
    }),
    takeUntil(action$.pipe(filter(onDeleteKit.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function getDetailsByIdCall(data: OilKitsAddDetails): Promise<GetDetailsOilKitsResponseFormat> {
  const url = `${Config.oilKits.getKitDetailsById}?KitId=${data}&Type=2`;
  const result = await makeGetRequest<GetDetailsOilKitsResponseFormat>(url);
  return result.data;
}

export const getDetailsByIdEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(getDetailsById.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(getDetailsByIdCall(data)).pipe(
    map((res: GetDetailsOilKitsResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotDetailsById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return gotDetailsById(res.BMT.Result);
    }),
    takeUntil(action$.pipe(filter(getDetailsById.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function editOilKitData(data: OilKitsDetails): Promise<GetAddOilKitResponseFormat> {
  const url = Config.oilKits.updateKit;
  const result = await makePutRequest<GetAddOilKitResponseFormat>(url, data);
  return result.data;
}

export const editOilKitEpic = (action$ : Observable<AddOilKitsActions>) => action$.pipe(
  ofType(onEditOilKit.type),
  map((x) => x.payload),
  mergeMap((data: Empty) => from(editOilKitData(data)).pipe(
    map((res: GetAddOilKitResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return onEditSuccessOilKit();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onEditSuccessOilKit();
    }),
    takeUntil(action$.pipe(filter(onEditOilKit.match))),
    catchError((error) => of(onCreatedOilKitFailure(error))),
  )),
);

async function unitPriceData(): Promise<BMTResponse> {
  const url = `${Config.oilKits.unitPrice}`;
  const result = await makeGetRequest<BMTResponse>(url);
  return result.data;
}

export const epicOilKitsUnitPrice = (action$: Observable<AddOilKitsActions>) => action$.pipe(
  filter(getOilKitsUnitPrice.match),
  debounceTime(250),
  map((x:AddOilKitsActions) => x.payload),
  mergeMap(() => from(unitPriceData()).pipe(
    map((res: BMTResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotOilKitsUnitPrice(res.BMT.Result.UnitPrice);
      }
      return gotOilKitsUnitPriceFailure();
    }),
    takeUntil(action$.pipe(filter(getOilKitsUnitPrice.match))),
    catchError((error) => of(gotOilKitsUnitPriceFailure(error?.response?.data?.BMT?.ResponseMessage))),
  )),
);
