/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog, DialogContent, DialogTitle, IconButton, TextField, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TypesDialogBox } from '../../../../../utils/interface';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function DialogBoxCustomerNotes({ show, setOpenFrom, notes }: TypesDialogBox): JSX.Element {
  return (
    <div>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle style={{ borderBottom: '1px solid #E9EBEC' }}>
          Customer Notes
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '0px' }}>
          <div style={{ padding: '20px' }}>
            <TextField
              fullWidth
              value={notes || ''}
              disabled
              multiline
              rows={18}
              maxRows={18}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxCustomerNotes;
