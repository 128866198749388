import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AddModalIcon } from '../../media/images/AddModalIcon';
import { TypesAddOilKitsModal } from './TypeOilKit';
import styles from './AddOilKitModalStyle.module.scss';
import { Cancel } from '../../media/images/Cancel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};

export default function ComponentAddOilKitModal({ show, setOpenFrom }: TypesAddOilKitsModal): JSX.Element {
  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className={styles.deleteIcon}>
            <AddModalIcon />
          </div>
          <div className={styles.cancelIcon} onClick={setOpenFrom}>
            <Cancel />
          </div>

          <div className={styles.modalModalHeading}>
            Oil Kits have been added successfully
          </div>
          <div className={styles.divButtons}>
            <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>OK</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
