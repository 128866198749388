import React, { useState } from 'react';
import {
  Grid, Card, Accordion, AccordionSummary, Typography, AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CustomExpandIcon } from '../../../../../../../utils/AccordionUtils/AccordionUtils';
import ComponentTextInput from '../../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { JobProps } from '../../../../utils/types';
import { JobScheduleFields } from '../../../../utils/constants';
import { RegexWithNumberAndSpecial, RegexFiveWith2D } from '../../../../../../../utils/regex';

function CarRental({ control }: JobProps) {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        className="accordionHeading"
        onClick={handleExpand}
        expandIcon={(
          <div />
        )}
      >
        <div className="customAccordionHeader">
          <div className="leftSideHeading">
            <CustomExpandIcon />
            <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Car Rental</Typography>
          </div>
          <div className="rightSideHeading">
            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </div>
        </div>
      </AccordionSummary>

      <AccordionDetails className="borderTop">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={3} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Company</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 30 }}
                        name={JobScheduleFields.carRentalCompany}
                        size="small"
                        id={JobScheduleFields.carRentalCompany}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Phone</label>
                      <ComponentTextInput
                        control={control}
                        regEx={RegexWithNumberAndSpecial}
                        name={JobScheduleFields.carRentalPhone}
                        size="small"
                        id={JobScheduleFields.carRentalPhone}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Price ($)</label>
                      <ComponentTextInput
                        control={control}
                        regEx={RegexFiveWith2D}
                        name={JobScheduleFields.carRentalPrice}
                        size="small"
                        id={JobScheduleFields.carRentalPrice}
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Confirmation #</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 20 }}
                        name={JobScheduleFields.carRentalConfirmation}
                        size="small"
                        id={JobScheduleFields.carRentalConfirmation}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Instructions</label>
                      <ComponentTextInput
                        control={control}
                        name={JobScheduleFields.carInstruction}
                        id={JobScheduleFields.carInstruction}
                        size="small"
                        rowSize={8}
                        multiline
                        inputProps={{ maxLength: 200 }}
                      />
                    </div>
                  </Grid>

                </Grid>
              </div>
            </Card>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default CarRental;
