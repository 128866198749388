import React from 'react';
import { Controller } from 'react-hook-form';
// import './StyleInputCheckBox.scss';
import { IComponentCheckBox } from './Type';
import './Style.scss';

function CustomCheckbox({
  control, name, label, required, defaultValue, className, handleChange, isDisable,
}: IComponentCheckBox) {
  const handleEventChange = (val: boolean, onChange: (val:boolean) => void) => {
    if (isDisable) return;
    onChange(val);
    if (handleChange) {
      handleChange(val);
    }
  };

  return (
    <div className="container_checkmark_div">
      { label && (
      <label className="container_label" htmlFor={name}>
        {label}
        {required && <span className="errorMessage">*</span>}
        {' '}
        {/* Display asterisk if required */}
      </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }} // Add required rule based on the prop
        render={({ field: { value, onChange } }) => (
          <label className="container_checkmark">
            <input
              type="checkbox"
              id={name}
              name={name}
              className={className}
              disabled={isDisable}
              checked={value}
              onChange={(event) => handleEventChange(event.target.checked, onChange)}
            />
            <span className="checkmark" />
          </label>
        )}
      />
    </div>
  );
}

export default CustomCheckbox;
