import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddDefaultListApiResponse, AddDefaultListDataAPI, DeleteDefaultListResponse, GetAllDefaultListApiResponse,
  GetById,
  PayloadTypeDeleteDefaultList, PayloadTypeGetDefaultList, UnitMasterResponse, UpdateDefaultListApiResponse, UpdateDefaultListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addDefaultListStart, deleteDefaultListStart, failureAddDefaultList, failureDeleteDefaultList, failureDefaultList,
  failureUpdateDefaultList, getDefaultListStart,
  DefaultTypeActions, successAddDefaultList, successDeleteDefaultList, successDefaultList,
  successUpdateDefaultList, updateDefaultList, getUnitMasterStart, getUnitMasterSuccess, getUnitMasterFailure,
} from './sliceDefaultTypeList';

async function GetDefaultList(data: PayloadTypeGetDefaultList): Promise<GetAllDefaultListApiResponse> {
  const url = `${Config.Lists.getDefaultList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllDefaultListApiResponse>(url);
  return result?.data;
}

export const epicGetDefaultList = (action$: Observable<DefaultTypeActions>) => action$.pipe(
  filter(getDefaultListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetDefaultList) => from(GetDefaultList(data)).pipe(
    map((res: GetAllDefaultListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successDefaultList(res?.BMT?.Result);
      }
      return failureDefaultList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getDefaultListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetAllDefaultListApiResponse>) => of(failureDefaultList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateDefaultList(data: UpdateDefaultListData): Promise<UpdateDefaultListApiResponse> {
  const url = `${Config.Lists.updateDefaultList}`;
  const result = await makePostRequest<UpdateDefaultListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateDefaultList = (action$: Observable<DefaultTypeActions>) => action$.pipe(
  filter(updateDefaultList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateDefaultList(data)).pipe(
    mergeMap((res: UpdateDefaultListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateDefaultList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateDefaultList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateDefaultList.match))),
    catchError((error: AxiosError<UpdateDefaultListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateDefaultList(errorMessage));
    }),
  )),
);

async function AddDefaultList(data: AddDefaultListDataAPI): Promise<AddDefaultListApiResponse> {
  const url = `${Config.Lists.addDefaultList}`;
  const result = await makePostRequest<AddDefaultListApiResponse>(url, data);
  return result?.data;
}

export const epicAddDefaultList = (action$: Observable<DefaultTypeActions>) => action$.pipe(
  filter(addDefaultListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddDefaultList(data)).pipe(
    mergeMap((res: AddDefaultListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddDefaultList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddDefaultList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addDefaultListStart.match))),
    catchError((error: AxiosError<AddDefaultListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddDefaultList(errorMessage));
    }),
  )),
);

async function DeleteDefaultList(data: PayloadTypeDeleteDefaultList): Promise<DeleteDefaultListResponse> {
  const url = `${Config.Lists.deleteDefaultList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteDefaultListResponse>(url);
  return result?.data;
}

export const epicDeleteDefaultList = (action$: Observable<DefaultTypeActions>) => action$.pipe(
  filter(deleteDefaultListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteDefaultList) => from(DeleteDefaultList(data)).pipe(
    mergeMap((res: DeleteDefaultListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteDefaultList(), getDefaultListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteDefaultList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteDefaultListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteDefaultListResponse>) => of(failureDeleteDefaultList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function getUnitMaster(): Promise<GetById<UnitMasterResponse>> {
  const url = `${Config.units.unitMaster}`;
  const result = await makeGetRequest<GetById<UnitMasterResponse>>(url);
  return result.data;
}

export const getTypeStyleMasterEpic = (action$ : Observable<DefaultTypeActions>) => action$.pipe(
  ofType(getUnitMasterStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getUnitMaster()).pipe(
    map((res: GetById<UnitMasterResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitMasterSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUnitMasterFailure();
    }),
    takeUntil(action$.pipe(filter(getUnitMasterStart.match))),
    catchError((error) => of(getUnitMasterFailure(error))),
  )),
);
