import { Control, FieldErrors } from 'react-hook-form';
import { Empty } from '../ScreenAddSmc/Utils/TypeSmc';
import { BidPdfDetailsResponseData } from './redux/types';

export interface BidItemsInterface {
  contactName: string;
  customer: string;
  address: string | undefined;
  city: string | undefined;
  effectiveDate: string | undefined;
  quoteNumber: string;
  customerNumber: string;
  paymentTerms: string;
  note: string;
  jobLocation: string;
}

export enum BidFields {
  contactName = 'contactName',
  customer = 'customer',
  address = 'address',
  city = 'city',
  effectiveDate = 'effectiveDate',
  quoteNumber = 'quoteNumber',
  customerNumber = 'customerNumber',
  paymentTerms = 'paymentTerms',
  note = 'note',
  jobLocation = 'jobLocation',
}

export interface BidsItems {
  services: string,
  model: string,
  tubeQty: string,
  location: string,
  total: string,
  remark: string,
}

export interface FieldInputComponentProps {
  control: Control<BidItemsInterface>;
  errors: FieldErrors<BidItemsInterface>;
}
export interface DynamicInputFieldsProps {
  handleInputChange: Empty;
  bidsItems: BidsItems[];
  removeKit: Empty;
  setBidsItems: Empty;
}
export interface BidPdfInitialState {
  bidFieldsValue: BidItemsInterface;
  bidTableData: BidsItems[];
  isLoading: boolean;
  bidPdfDetailsData: BidPdfDetailsResponseData;
}
export interface TypesBidPdfModal {
  show: boolean;
  setOpenFrom: () => void;
  comment: string;
}
