import React from 'react';
import InspectionLetterTableComponent from './InspectionLetterTableComponent';

function ScreenPasDue() {
  return (
    <InspectionLetterTableComponent />
  );
}

export default ScreenPasDue;
