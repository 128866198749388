import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CommentTabResult, JobScheduleListDataResult,
  JobScheduleUnitDataResult, JobTravelDataResult, TravelDataByIdResult,
} from '../../utils/types';
import { Empty } from '../../../../ScreenAddSmc/Utils/TypeSmc';
import {
  AllVesselsListingDetails,
  GetAssociatedVesselsPayload, GetGeneralVesselsPayload, PaginationResponse, VesselsListingDetails,
} from '../utils/types';
import { DeleteVesselReportPayload, SetVesselData } from '../components/AddReportModal/utils/Type';

const initialState = {
  isLoading: true,
  jobScheduleData: {} as JobScheduleListDataResult,
  isUnitLoading: false,
  jobScheduleDataById: {},
  jobScheduleUnitData: {} as JobScheduleUnitDataResult,
  isTravelListLoading: false,
  jobTravelListData: {} as JobTravelDataResult,
  selectedTab: 1,
  jodId: '',
  addedSchedule: false,
  addedAccommadation: false,
  hotelListData: {} as JobTravelDataResult,
  commentData: {} as CommentTabResult,
  isCommentLoading: false,
  isSiteDataUpdated: false,
  travelDataById: {} as TravelDataByIdResult,
  navigateFromScheduleTab: false,
  readyToSend: false,
  associatedVessels: [] as VesselsListingDetails[],
  totalAssociatedVessels: 0,
  generalAllVesselsList: [] as AllVesselsListingDetails[],
  reportDataById: [] as SetVesselData[],
};

export const jobScheduleSlice = createSlice({
  name: 'jobSchedule',
  initialState,
  reducers: {
    resetJobScheduleState() {
      return initialState;
    },
    setNavigateFromScheduleTab(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        navigateFromScheduleTab: action.payload,
      };
    },
    setJobId(state, action: PayloadAction<string>) {
      return {
        ...state,
        jodId: action.payload,
      };
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    getJobScheduleListData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getJobScheduleListDataSuccess(state, action: PayloadAction<JobScheduleListDataResult>) {
      return {
        ...state,
        isLoading: false,
        jobScheduleData: action.payload,
      };
    },
    getJobScheduleListDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getJobScheduleByIdData(state, _action) {
      return {
        ...state,
        isUnitLoading: false,
      };
    },
    getJobScheduleByIdDataSuccess(state, action: PayloadAction<Empty>) {
      return {
        ...state,
        isUnitLoading: false,
        jobScheduleDataById: action.payload,
      };
    },
    getJobScheduleByIdDataFailure(state) {
      return {
        ...state,
        isUnitLoading: false,
      };
    },
    getJobScheduleUnitData(state, _action) {
      return {
        ...state,
        isUnitLoading: true,
      };
    },
    getJobScheduleUnitDataSuccess(state, action: PayloadAction<JobScheduleUnitDataResult>) {
      return {
        ...state,
        isUnitLoading: false,
        jobScheduleUnitData: action.payload,
      };
    },
    getJobScheduleUnitDataFailure(state) {
      return {
        ...state,
        isUnitLoading: false,
      };
    },
    getJobTravelListData(state, _action) {
      return {
        ...state,
        isTravelListLoading: true,
      };
    },
    getJobTravelListDataSuccess(state, action: PayloadAction<JobTravelDataResult>) {
      return {
        ...state,
        isTravelListLoading: false,
        jobTravelListData: action.payload,
      };
    },
    getJobTravelListDataFailure(state) {
      return {
        ...state,
        isTravelListLoading: false,
      };
    },
    onAddSchedule(state, _action) {
      return {
        ...state,
        isTravelListLoading: false,
      };
    },
    onAddScheduleSuccess(state) {
      return {
        ...state,
        addedSchedule: true,
      };
    },
    onAddScheduleFailure(state) {
      return {
        ...state,
        addedSchedule: false,
        isCommentLoading: false,
      };
    },
    onUpdateSchedule(state, _action) {
      return {
        ...state,
        isTravelListLoading: false,
      };
    },
    onAddAccommodationSchedule(state, _action) {
      return {
        ...state,
        addedAccommadation: false,
      };
    },
    onAddAccommodationScheduleSuccess(state) {
      return {
        ...state,
        addedAccommadation: true,
      };
    },
    onAddAccommodationScheduleFailure(state) {
      return {
        ...state,
        addedAccommadation: false,
      };
    },
    onUpdateAccommodationSchedule(state, _action) {
      return {
        ...state,
        addedAccommadation: false,
      };
    },
    onDeleteAccommodationSchedule(state, _action) {
      return {
        ...state,
        addedAccommadation: false,
      };
    },
    getHotelList(state, _action) {
      return {
        ...state,
        isHotelListLoading: false,
      };
    },
    gotHotelList(state, action: PayloadAction<JobTravelDataResult>) {
      return {
        ...state,
        isHotelListLoading: false,
        hotelListData: action.payload,
      };
    },
    onCreateJobSchedule(state, _action) {
      return {
        ...state,
        createdJobSchedule: false,
      };
    },
    onCreateJobScheduleSuccess(state) {
      return {
        ...state,
        createdJobSchedule: false,
      };
    },
    getCommentData(state, _action) {
      return {
        ...state,
        isCommentLoading: true,
      };
    },
    gotCommentData(state, action: PayloadAction<CommentTabResult>) {
      return {
        ...state,
        isCommentLoading: false,
        commentData: action.payload,
      };
    },
    onUpdateSiteData(state, _action) {
      return {
        ...state,
        isSiteDataUpdated: false,
      };
    },
    onUpdateSiteDateSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSiteDataUpdated: action.payload,
      };
    },
    onUpdateSiteDateFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSiteDataUpdated: action.payload,
      };
    },
    getTravelDataById(state, _action) {
      return {
        ...state,
      };
    },
    gotTravelDataById(state, action: PayloadAction<TravelDataByIdResult>) {
      return {
        ...state,
        travelDataById: action.payload,
      };
    },
    travelDataByIdFailure(state, action: PayloadAction<TravelDataByIdResult>) {
      return {
        ...state,
        travelDataById: action.payload,
      };
    },
    onReadyToSendStart(state, _action) {
      return {
        ...state,
        readyToSend: false,
      };
    },
    onReadyToSendSuccess(state) {
      return {
        ...state,
        readyToSend: true,
      };
    },
    onReadyToSendFailure(state) {
      return {
        ...state,
        readyToSend: false,
      };
    },

    // Get Associated Vessels
    getAssociatedVesselsStart(state, _action:PayloadAction<GetAssociatedVesselsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAssociatedVesselsSuccess(state, action:PayloadAction<PaginationResponse<VesselsListingDetails>>) {
      return {
        ...state,
        associatedVessels: action.payload.Data,
        totalAssociatedVessels: action.payload.TotalRecords,
        isLoading: false,
      };
    },
    getAssociatedVesselsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get General Vessels
    getGeneralVesselsStart(state, _action:PayloadAction<GetGeneralVesselsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getGeneralVesselsSuccess(state, action:PayloadAction<AllVesselsListingDetails[]>) {
      return {
        ...state,
        generalAllVesselsList: action.payload,
        isLoading: false,
      };
    },
    getGeneralVesselsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    reSetAllVesselsList(state) {
      return {
        ...state,
        generalAllVesselsList: [],
        reportDataById: [],
      };
    },

    reSetReportDataById(state) {
      return {
        ...state,
        reportDataById: [],
      };
    },

    // Add Report
    addJobReport(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addJobReportSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addJobReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Report By Id
    getReportByIdStart(state, _action:PayloadAction<GetGeneralVesselsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getReportByIdSuccess(state, action:PayloadAction<SetVesselData[]>) {
      return {
        ...state,
        reportDataById: action.payload,
        isLoading: false,
      };
    },
    getReportByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Delete Report
    deleteReportByIdStart(state, _action:PayloadAction<DeleteVesselReportPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteReportByIdSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteReportByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getJobScheduleListData, resetJobScheduleState, getJobScheduleListDataSuccess, getJobScheduleListDataFailure,
  getJobScheduleByIdData, getJobScheduleByIdDataSuccess, getJobScheduleByIdDataFailure, getJobScheduleUnitData,
  getJobScheduleUnitDataSuccess, getJobScheduleUnitDataFailure, getJobTravelListData, getJobTravelListDataSuccess,
  setSelectedTab, setJobId, onAddSchedule, onAddScheduleSuccess, onAddScheduleFailure,
  getJobTravelListDataFailure, onUpdateSchedule, onAddAccommodationSchedule,
  onAddAccommodationScheduleSuccess, onAddAccommodationScheduleFailure,
  onUpdateAccommodationSchedule, onDeleteAccommodationSchedule, getHotelList, gotHotelList,
  onCreateJobSchedule, onCreateJobScheduleSuccess, getCommentData, gotCommentData, onUpdateSiteData, onUpdateSiteDateSuccess,
  onUpdateSiteDateFailure, getTravelDataById,
  gotTravelDataById, travelDataByIdFailure, setNavigateFromScheduleTab, onReadyToSendStart,
  onReadyToSendSuccess, onReadyToSendFailure,
  getAssociatedVesselsStart, getAssociatedVesselsSuccess, getAssociatedVesselsFailure,
  getGeneralVesselsStart, getGeneralVesselsSuccess, getGeneralVesselsFailure,
  reSetAllVesselsList, addJobReport, addJobReportSuccess, addJobReportFailure,
  getReportByIdStart, getReportByIdSuccess, getReportByIdFailure,
  deleteReportByIdStart, deleteReportByIdSuccess, deleteReportByIdFailure, reSetReportDataById,
} = jobScheduleSlice.actions;
export const jobScheduleReducer = jobScheduleSlice.reducer;

export type JobScheduleActions =
  ReturnType<typeof resetJobScheduleState> |
  ReturnType<typeof getJobScheduleListData> |
  ReturnType<typeof getJobScheduleListDataSuccess> |
  ReturnType<typeof getJobScheduleListDataFailure> |
  ReturnType<typeof getJobScheduleByIdData> |
  ReturnType<typeof getJobScheduleByIdDataSuccess> |
  ReturnType<typeof getJobScheduleByIdDataFailure> |
  ReturnType<typeof getJobScheduleUnitData> |
  ReturnType<typeof getJobScheduleUnitDataSuccess> |
  ReturnType<typeof getJobScheduleUnitDataFailure> |
  ReturnType<typeof getJobTravelListData> |
  ReturnType<typeof getJobTravelListDataSuccess> |
  ReturnType<typeof getJobTravelListDataFailure> |
  ReturnType<typeof setSelectedTab> |
  ReturnType<typeof onAddSchedule> |
  ReturnType<typeof onAddScheduleSuccess> |
  ReturnType<typeof onAddScheduleFailure> |
  ReturnType<typeof onUpdateSchedule> |
  ReturnType<typeof onAddAccommodationSchedule> |
  ReturnType<typeof onAddAccommodationScheduleSuccess> |
  ReturnType<typeof onAddAccommodationScheduleFailure> |
  ReturnType<typeof onUpdateAccommodationSchedule> |
  ReturnType<typeof onDeleteAccommodationSchedule> |
  ReturnType<typeof getHotelList> |
  ReturnType<typeof gotHotelList> |
  ReturnType<typeof onCreateJobSchedule> |
  ReturnType<typeof onCreateJobScheduleSuccess> |
  ReturnType<typeof getCommentData> |
  ReturnType<typeof gotCommentData> |
  ReturnType<typeof onUpdateSiteData> |
  ReturnType<typeof onUpdateSiteDateSuccess> |
  ReturnType<typeof onUpdateSiteDateFailure> |
  ReturnType<typeof getTravelDataById> |
  ReturnType<typeof gotTravelDataById> |
  ReturnType<typeof travelDataByIdFailure> |
  ReturnType<typeof setJobId> |
  ReturnType<typeof setNavigateFromScheduleTab> |
  ReturnType<typeof onReadyToSendStart> |
  ReturnType<typeof onReadyToSendSuccess> |
  ReturnType<typeof onReadyToSendFailure> |
  ReturnType<typeof getAssociatedVesselsFailure> |
  ReturnType<typeof getAssociatedVesselsStart> |
  ReturnType<typeof getAssociatedVesselsSuccess> |
  ReturnType<typeof getGeneralVesselsFailure> |
  ReturnType<typeof getGeneralVesselsStart> |
  ReturnType<typeof getGeneralVesselsSuccess> |
  ReturnType<typeof reSetAllVesselsList> |
  ReturnType<typeof addJobReport> |
  ReturnType<typeof addJobReportSuccess> |
  ReturnType<typeof addJobReportFailure> |
  ReturnType<typeof getReportByIdStart> |
  ReturnType<typeof getReportByIdSuccess> |
  ReturnType<typeof deleteReportByIdStart> |
  ReturnType<typeof deleteReportByIdSuccess> |
  ReturnType<typeof deleteReportByIdFailure> |
  ReturnType<typeof reSetReportDataById>;
