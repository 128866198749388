/* eslint-disable max-len */
import React from 'react';

export function EquipmentMarkAsSendIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0977 21.1305L16.5142 26.1046C17.3043 26.832 18.5273 26.7933 19.2703 26.0271L28.9335 16.7752C29.7195 16.0194 29.7433 14.769 28.9872 13.9826C28.2312 13.1974 26.9814 13.1728 26.1954 13.9286L17.8252 21.9426L13.7736 18.2221C12.9709 17.4836 11.7203 17.5354 10.9814 18.3389C10.2425 19.1415 10.2946 20.3913 11.0977 21.1305ZM20 0.982422C30.504 0.982422 39.0188 9.49727 39.0188 20.0012C39.0188 30.5052 30.504 39.02 20 39.02C9.49605 39.02 0.981201 30.5052 0.981201 20.0012C0.981201 9.49727 9.49605 0.982422 20 0.982422Z" fill="white" />
    </svg>
  );
}
