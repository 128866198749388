import React, {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Grid, Input } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddStripChartImage, AddStripChartRequest, StripChartTableColumns } from '../utils/helper';
import { AddStripChartFields, StripListingInterface } from '../utils/type';
import DataTable from '../../../Common/DataTable';
import EditStripChartComponent from '../commonModal/EditStripChartComponent';
import AddStripChartComponent from '../commonModal/AddStripChartComponent';
import { createStripChartStart, deleteStripChartStart, getStripChartStart } from '../redux/sliceVesselInformation';
import { RootState } from '../../../redux/rootState';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../../redux/loaderSlice';
import { ANALYST_ROLE_ID } from '../../../Routes/constant';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import StripChartEditorComponent from '../commonModal/stripChart/stripChartEditModel';

function StripeChartComponent() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [newFileName, setNewFileName] = useState<string>('');
  const [fileDetails, setFileDetails] = useState<{ zone: string, row: string, tube: string } | null>(null);
  const { jobOrderId, vesselId } = useParams();
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const { stripChartData, totalStripChart, isLoading } = useSelector((state:RootState) => state.VesselInformationReducer);

  const btnRef = useRef<HTMLButtonElement>(null);

  const tableData: StripListingInterface[] = useMemo(() => {
    if (stripChartData?.length > 0) {
      const transformedData = stripChartData?.map((ele, index) => ({
        sn: index,
        action: 'view&delete',
        section: ele?.Section || '',
        zone: ele?.Zone,
        row: ele?.Row,
        tube: ele?.Tube,
        loc: ele?.Location,
        title: ele?.Title,
        id: String(ele?.Id),
        viewOnly: true,
      }));
      return transformedData;
    }
    return [];
  }, [stripChartData]);

  const handleData = () => {
    const payload = {
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getStripChartStart(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleButtonClick = () => {
    btnRef.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileName = file.name;
      const match = fileName.match(/Z(\d+)R(\d+)T(\d+)/);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedFile(reader?.result as string);
        setNewFileName(fileName);
      };
      if (match) {
        const [, zone, row, tube] = match;
        setFileDetails({ zone, row, tube });
        setShowAddModal(true);
      } else {
        const fileNameNew = file.name;
        const readerNew = new FileReader();
        readerNew.readAsDataURL(file);
        readerNew.onload = () => {
          const payLoad = AddStripChartImage(fileNameNew, readerNew?.result as string, jobOrderId || '', vesselId || '');
          const getListPayload = {
            jobOrder: jobOrderId || '',
            vesselId: vesselId || '',
            page,
            rowsPerPage,
            searchQuery,
          };
          dispatch(createStripChartStart({ payLoad, getListPayload }));
        };
        setFileDetails(null);
      }
    }
  };

  const handleSave = (data: AddStripChartFields) => {
    const payLoad = AddStripChartRequest(newFileName, selectedFile, fileDetails, data, jobOrderId || '', vesselId || '');
    const getListPayload = {
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(createStripChartStart({ payLoad, getListPayload }));
    setShowAddModal(false);
    setFileDetails(null);
  };

  const handleDelete = (Id: string) => {
    setDeleteModal(true);
    setSelectedId(Id);
  };

  const handelDeleteModal = () => {
    const deletePayload = {
      id: selectedId,
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteStripChartStart(deletePayload));
    setDeleteModal(false);
    setSelectedId('');
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            {(roleId === ANALYST_ROLE_ID) ? (
              <DataTable<StripListingInterface>
                data={tableData?.length > 0 ? tableData : []}
                columns={StripChartTableColumns}
                totalRecords={totalStripChart}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
              />
            ) : (
              <DataTable<StripListingInterface>
                data={tableData?.length > 0 ? tableData : []}
                columns={StripChartTableColumns}
                totalRecords={totalStripChart}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
                handleView={(id: string) => {
                  setSelectedId(id);
                  setShowEditorModal(true);
                }}
                radioButtonColumnsWithKey={['sn']}
                selectedRadioWithKey={selectedId}
                setSelectedRadioWithKey={setSelectedId}
                handleButtonClick={handleButtonClick}
                buttonText="Add Strip Chart"
                handleDelete={handleDelete}
              />
            )}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  className="button_save_and_next"
                  onClick={() => setShowModal(true)}
                  disabled={selectedId === '' || roleId === ANALYST_ROLE_ID}
                >
                  Change Zone, Row, Tube
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {!(roleId === ANALYST_ROLE_ID) && (
      <>
        {showModal && (
        <EditStripChartComponent
          show={showModal}
          setOpenFrom={() => setShowModal(false)}
          rowId={selectedId}
          callback={handleData}
          page={page}
          rowsPerPage={rowsPerPage}
          searchQuery={searchQuery}
        />
        ) }
        <AddStripChartComponent
          show={showAddModal}
          setOpenFrom={() => setShowAddModal(false)}
          handleSave={handleSave}
        />
        <ComponentDeleteModal
          show={showDeleteModal}
          setOpenFrom={() => setDeleteModal(false)}
          handleDelete={handelDeleteModal}
        />
        {showEditorModal && (
        <StripChartEditorComponent
          show={showEditorModal}
          setOpenFrom={setShowEditorModal}
          rowId={selectedId}
          setRowId={setSelectedId}
        />
        )}
        <div style={{ display: 'none' }}>
          <div className="d-flex justify-content-center">
            <Input
              type="file"
              inputProps={{ accept: 'image/*', style: { display: 'none' } }}
              id="fileInput"
              onChange={handleChange}
            />
            <label htmlFor="fileInput">
              <Button ref={btnRef} variant="contained" color="success" component="span">
                Import File
              </Button>
            </label>
          </div>
        </div>
      </>
      )}
    </>
  );
}

export default StripeChartComponent;
