import { FreonCylinderListData } from './TypesFreonCylinderList';

export const tableData:FreonCylinderListData[] = [
  {
    id: '1',
    sn: 1,
    action: 'edit&delete',
    KitId: '',
    numberOfKit: '4',
    custId: '3976',
    customerName: 'Comfort System USA',
    purchaseOrderNumber: '38-4066',
    shipDate: '11/01/2022',
    billAmount: '168.00',
    billed: true,
    shipVia: 'Ground',
    createdBy: '',
  },
];

export const TableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'numberOfKit', label: 'Blue Cyl Qty' },
  { key: 'custId', label: 'Cust #' },
  { key: 'customerName', label: 'Customer' },
  { key: 'purchaseOrderNumber', label: 'PO #' },
  { key: 'shipDate', label: 'Ship Date ' },
  { key: 'billAmount', label: 'Bill Amt' },
  { key: 'billed', label: 'Billed' },
  { key: 'shipVia', label: 'Ship Via' },
];
