/* eslint-disable max-len */
import * as React from 'react';

export function DeleteModalIcon() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M30.625 28.625H34.375V58.625H30.625V28.625Z" fill="#F06548" />
      <path d="M38.125 28.625H41.875V58.625H38.125V28.625Z" fill="#F06548" />
      <path d="M45.625 28.625H49.375V58.625H45.625V28.625Z" fill="#F06548" />
      <path d="M15.625 17.375H64.375V21.125H15.625V17.375Z" fill="#F7B84B" />
      <path d="M49.25 17.25H45.75V13.5C45.75 12.375 44.875 11.5 43.75 11.5H36.25C35.125 11.5 34.25 12.375 34.25 13.5V17.25H30.75V13.5C30.75 10.5 33.25 8 36.25 8H43.75C46.75 8 49.25 10.5 49.25 13.5V17.25Z" fill="#F06548" />
      <path d="M51.25 69.875H28.75C25.75 69.875 23.125 67.375 22.875 64.375L19.375 19.375L23.125 19.125L26.625 64.125C26.75 65.25 27.75 66.125 28.75 66.125H51.25C52.375 66.125 53.375 65.125 53.375 64.125L56.875 19.125L60.625 19.375L57.125 64.375C56.875 67.5 54.25 69.875 51.25 69.875Z" fill="#F7B84B" />
    </svg>
  );
}
