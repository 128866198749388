import { Grid, Card, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
// eslint-disable-next-line import/no-relative-packages
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './StyleCommentsTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CommentPayload } from '../../utils/types';
import { RootState } from '../../../../redux/rootState';
import { addCommentStart, setSelectedTab, updateCommentStart } from '../../redux/addCustomerSlice';
import { CONTACT_TAB, MAIN_TAB } from '../../utils/constants';
import { customOptions } from '../../../../Common/TextEditorOptions';

export default function ComponentCommentsTab() {
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [instruction, setInstruction] = useState(EditorState.createEmpty());
  const { customerId, commentDetails } = useSelector((state:RootState) => state?.addCustomerReducer);
  const dispatch = useDispatch();

  const { customerId: custId } = useParams();

  // Function to update the editor state when content changes
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const handleInstruction = (newEditorState: EditorState) => {
    setInstruction(newEditorState);
  };

  // Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const handleSave = () => {
    const com = getHtmlFromEditorHTMLtoString(comment);
    const inst = getHtmlFromEditorHTMLtoString(instruction);

    const payload:CommentPayload = {
      Comments: com,
      Instructions: inst,
      CustomerId: customerId,
    };

    if (commentDetails || custId) {
      dispatch(updateCommentStart(payload));
    } else {
      dispatch(addCommentStart(payload));
    }

    dispatch(setSelectedTab(CONTACT_TAB));
  };

  useEffect(() => {
    if (commentDetails?.Comments) {
      const convertedComment = htmlToEditorState(commentDetails?.Comments);
      setComment(convertedComment);
    }

    if (commentDetails?.Instructions) {
      const convertedInst = htmlToEditorState(commentDetails?.Instructions);
      setInstruction(convertedInst);
    }
  }, [commentDetails]);

  const handleChangeTab = () => {
    dispatch(setSelectedTab(MAIN_TAB));
  };

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="comment-card">
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Comments
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="editorContainer">
                        <Editor
                          editorState={comment}
                          onEditorStateChange={handleComment}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{ locale: 'en' }}
                          toolbar={customOptions}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="instruction-card">
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Instructions
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="editorContainer">
                        <Editor
                          editorState={instruction}
                          onEditorStateChange={handleInstruction}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{ locale: 'en' }}
                          toolbar={customOptions}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={handleChangeTab}>Back</Button>
            </div>
            <div className="button_margin_left">
              <Button className="button_save_and_next" onClick={handleSave}>Save</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
