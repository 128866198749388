import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPriceListTable from './Components';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { RootState } from '../../../redux/rootState';
import { PriceListingstData } from './utils/TypesPriceList';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenPriceList() {
  const { SystemPriceList } = useSelector((state: RootState) => state.SystemPriceListReducer);
  const excelData = useMemo(() => SystemPriceList?.Data?.map((item: PriceListingstData) => {
    const transformedDataItems = {
      KitDescription: item?.KitDescription,
      MeasurementType: item?.MeasurementType,
      Price: `$ ${item?.Price}`,
      FixedPrice: item?.FixedPrice ? 'Yes' : 'No',
      TubeAvailable: item?.TubeAvailable ? 'Yes' : 'No',
      Quantity: item?.Quantity,
    };
    return transformedDataItems;
  }), [SystemPriceList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Price_list',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="PRICE LIST" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentPriceListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenPriceList;
