/* eslint-disable max-len */
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import { makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import {
  getDataForPrintPdfFailure,
  getDataForPrintPdfStart,
  getDataForPrintPdfSuccess,
  getProductDetailsByIdFailure,
  getProductDetailsByIdStart,
  getProductDetailsByIdSuccess,
  getSendRequestListByIdFailure, getSendRequestListByIdStart, getSendRequestListByIdSuccess, SendEquipmentRequestActions, sendEquipmentStart, sendEquipmentSuccess,
} from './sendEquipmentScreenSlice';
import {
  DataForPrintPdfByRequestIdInterface,
  GetDataForPrintPdfPayload,
  GetRequestDataByIdPayload, GetSendRequestListByIdData, ProductDetailsDataByIdResponse, SendEquipmentPayload, SendEquipmentPayloadFields, SendEquipmentResponse,
} from './type';
import { sendAndReceiveEquipmentType } from '../utils/constants';

async function getRequestDataByIdApiCall(data: GetRequestDataByIdPayload): Promise<GetSendRequestListByIdData> {
  const url = `${Config.inventoryEquipmentRequest.sendReceiveEquipmentList}?Type=${data?.typeId}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<GetSendRequestListByIdData>(url);
  return result?.data;
}

// GET SEND REQUEST DATA
export const getRequestDataByIdEpic = (action$: Observable<SendEquipmentRequestActions>) => action$.pipe(
  filter(getSendRequestListByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetRequestDataByIdPayload) => from(getRequestDataByIdApiCall(data)).pipe(
    map((res: GetSendRequestListByIdData) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getSendRequestListByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getSendRequestListByIdFailure(false);
    }),
    takeUntil(action$.pipe(filter(getSendRequestListByIdStart.match))),
    catchError((error: AxiosError<GetSendRequestListByIdData>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getSendRequestListByIdFailure(false));
    }),
  )),
);

async function sendEquipmentApiCall(data: SendEquipmentPayloadFields, type: number): Promise<SendEquipmentResponse> {
  if (Number(type) === sendAndReceiveEquipmentType.MARK_AS_SEND || Number(type) === sendAndReceiveEquipmentType.MARK_AS_RECEIVE) {
    const url = `${Config.inventoryEquipmentRequest.SendRecieveEquipment}?Type=${type}&UnitId=${data?.unitId}&RequestId=${data?.requestId}`;
    const result = await makePostRequest<SendEquipmentResponse>(url);
    return result?.data;
  }
  const payload = {
    RequestId: data?.requestId,
    ProductNumber: data?.productId,
  };
  const url = Config.inventoryEquipmentRequest.UnlinkEquipment;
  const result = await makePostRequest<SendEquipmentResponse>(url, payload);
  return result?.data;
}

// CREATE SEND REQUEST
export const sendEquipmentEpic = (action$: Observable<SendEquipmentRequestActions>) => action$.pipe(
  filter(sendEquipmentStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ payload, type, callback }: SendEquipmentPayload) => from(sendEquipmentApiCall(payload, type)).pipe(
    map((res: SendEquipmentResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return sendEquipmentSuccess(false);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return sendEquipmentSuccess(false);
    }),
    takeUntil(action$.pipe(filter(sendEquipmentStart.match))),
    catchError((error: AxiosError<SendEquipmentResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(sendEquipmentSuccess(false));
    }),
  )),
);

async function getProductDetailsByIdApiCall(data: string): Promise<ProductDetailsDataByIdResponse> {
  const url = `${Config.inventoryEquipmentRequest.InventoryDetailsByProductNumber}?productNumber=${data}`;
  const result = await makeGetRequest<ProductDetailsDataByIdResponse>(url);
  return result?.data;
}

// GET INVENTORY DETAILS BY ID DATA
export const getProductDetailsByIdEpic = (action$: Observable<SendEquipmentRequestActions>) => action$.pipe(
  filter(getProductDetailsByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getProductDetailsByIdApiCall(data)).pipe(
    map((res: ProductDetailsDataByIdResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getProductDetailsByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getProductDetailsByIdFailure(false);
    }),
    takeUntil(action$.pipe(filter(getProductDetailsByIdStart.match))),
    catchError((error: AxiosError<GetSendRequestListByIdData>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getProductDetailsByIdFailure(false));
    }),
  )),
);

async function getDataForPrintPdfApiCall(requestId: string, instruction: string): Promise<DataForPrintPdfByRequestIdInterface> {
  const url = `${Config.inventoryEquipmentRequest.DataForPrintPdf}?RequestId=${requestId}&Instructions=${instruction}`;
  const result = await makeGetRequest<DataForPrintPdfByRequestIdInterface>(url);
  return result?.data;
}

// GET DATA FOR PRINT PDF
export const getDataForPrintPdfEpic = (action$: Observable<SendEquipmentRequestActions>) => action$.pipe(
  filter(getDataForPrintPdfStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ requestId, instructions }: GetDataForPrintPdfPayload) => from(getDataForPrintPdfApiCall(requestId, instructions)).pipe(
    map((res: DataForPrintPdfByRequestIdInterface) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDataForPrintPdfSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getDataForPrintPdfFailure(false);
    }),
    takeUntil(action$.pipe(filter(getDataForPrintPdfStart.match))),
    catchError((error: AxiosError<GetSendRequestListByIdData>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getDataForPrintPdfFailure(false));
    }),
  )),
);
