import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import {
  DropdownArray, EquipmentDetailsByRequestIdResult, EquipmentHistoryDataInterface, EquipmentNeededListing, EquipmentRequestInitialStateInterface,
  EquipmentRequestListing, InventoryGetEquipmentMasterData,
} from './type';
import { REQUEST } from '../../utils/constants';

const initialState: EquipmentRequestInitialStateInterface = {
  isLoading: false,
  inventoryEquipmentMasterData: {} as InventoryGetEquipmentMasterData,
  isMasterDataGot: false,
  selectedRowId: '',
  comment: EditorState.createEmpty(),
  equipmentRequestData: {} as EquipmentRequestListing,
  inventoryEquipmentMasterStatusData: [],
  equipmentNeededLoading: false,
  equipmentNeededData: {} as EquipmentNeededListing,
  isEquipmentAdded: false,
  isEquipmentDelete: false,
  equipmentHistoryData: [],
  equipmentHistoryLoading: false,
  equipmentDetailsLoading: false,
  equipmentDetailsByRequestIdData: {} as EquipmentDetailsByRequestIdResult,
  adminNote: '',
  equipmentRequestTab: REQUEST,
  isEquipmentRequestRejected: false,
};

export const inventoryEquipmentRequestSlice = createSlice({
  name: 'inventoryEquipmentRequest',
  initialState,
  reducers: {
    setEquipmentRequestTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        equipmentRequestTab: action.payload,
      };
    },
    setAdminNote(state, action: PayloadAction<string>) {
      return {
        ...state,
        adminNote: action.payload,
      };
    },
    resetInventoryEquipmentRequest() {
      return initialState;
    },
    getEquipmentMasterStart(state, _action) {
      return {
        ...state,
        isMasterDataGot: false,
      };
    },
    setSelectedRowId(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedRowId: action?.payload,
      };
    },
    setComment(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        comment: action?.payload,
      };
    },
    getEquipmentMasterSuccess(state, action: PayloadAction<InventoryGetEquipmentMasterData>) {
      return {
        ...state,
        inventoryEquipmentMasterData: action.payload,
      };
    },
    getEquipmentMasterFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isMasterDataGot: action.payload,
      };
    },
    createEquipmentRequestStart(state, _action) {
      return {
        ...state,
        isMasterDataGot: false,
      };
    },
    createEquipmentRequestSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isMasterDataGot: action.payload,
      };
    },
    createEquipmentRequestFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isMasterDataGot: action.payload,
      };
    },
    getEquipmentRequestDataStart(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getEquipmentRequestDataSuccess(state, action: PayloadAction<EquipmentRequestListing>) {
      return {
        ...state,
        isLoading: false,
        equipmentRequestData: action.payload,
      };
    },
    getEquipmentRequestDataFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action?.payload,
      };
    },
    getEquipmentMasterStatusStart(state) {
      return {
        ...state,
        isMasterDataGot: false,
      };
    },
    getEquipmentMasterStatusSuccess(state, action: PayloadAction<DropdownArray[]>) {
      return {
        ...state,
        inventoryEquipmentMasterStatusData: action.payload,
      };
    },
    getEquipmentMasterStatusFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isMasterDataGot: action.payload,
      };
    },
    getEquipmentNeededDataStart(state, _action) {
      return {
        ...state,
        equipmentNeededLoading: true,
      };
    },
    getEquipmentNeededDataSuccess(state, action: PayloadAction<EquipmentNeededListing>) {
      return {
        ...state,
        equipmentNeededLoading: false,
        equipmentNeededData: action.payload,
      };
    },
    getEquipmentNeededDataFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        equipmentNeededLoading: action?.payload || false,
      };
    },
    addEquipmentByAdminStart(state, _action) {
      return {
        ...state,
        isEquipmentAdded: false,
      };
    },
    addEquipmentByAdminSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEquipmentAdded: action?.payload || false,
      };
    },
    addEquipmentByAdminFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEquipmentAdded: action?.payload || false,
      };
    },
    onDeleteEquipmentByAdmin(state, _action) {
      return {
        ...state,
        isEquipmentDelete: false,
      };
    },
    onDeleteEquipmentByAdminSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEquipmentDelete: action?.payload || false,
      };
    },
    getEquipmentHistoryDataStart(state, _action) {
      return {
        ...state,
        equipmentHistoryLoading: true,
        equipmentHistoryData: [],
      };
    },
    getEquipmentHistoryDataSuccess(state, action: PayloadAction<EquipmentHistoryDataInterface[]>) {
      return {
        ...state,
        equipmentHistoryLoading: false,
        equipmentHistoryData: action?.payload || [],
      };
    },
    getEquipmentHistoryDataFailure(state, action: PayloadAction<EquipmentHistoryDataInterface[]>) {
      return {
        ...state,
        equipmentHistoryLoading: false,
        equipmentHistoryData: action?.payload || [],
      };
    },
    getEquipmentDetailsByRequestIdStart(state, _action) {
      return {
        ...state,
        equipmentDetailsLoading: true,
      };
    },
    getEquipmentDetailsByRequestIdSuccess(state, action: PayloadAction<EquipmentDetailsByRequestIdResult>) {
      return {
        ...state,
        equipmentDetailsLoading: false,
        equipmentDetailsByRequestIdData: action?.payload || [],
      };
    },
    getEquipmentDetailsByRequestIdFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        equipmentDetailsLoading: action.payload,
      };
    },
    equipmentRequestRejected(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isEquipmentRequestRejected: action.payload,
      };
    },
  },
});

export const {
  getEquipmentMasterStart, getEquipmentMasterSuccess, getEquipmentMasterFailure,
  resetInventoryEquipmentRequest, createEquipmentRequestStart, createEquipmentRequestSuccess,
  setSelectedRowId, setComment, getEquipmentRequestDataStart, getEquipmentRequestDataSuccess,
  getEquipmentRequestDataFailure, getEquipmentMasterStatusStart, getEquipmentMasterStatusSuccess,
  getEquipmentMasterStatusFailure, getEquipmentNeededDataStart, getEquipmentNeededDataSuccess,
  getEquipmentNeededDataFailure, addEquipmentByAdminStart, addEquipmentByAdminSuccess,
  addEquipmentByAdminFailure, onDeleteEquipmentByAdmin, onDeleteEquipmentByAdminSuccess,
  getEquipmentHistoryDataStart, getEquipmentHistoryDataSuccess, getEquipmentHistoryDataFailure,
  getEquipmentDetailsByRequestIdStart, getEquipmentDetailsByRequestIdSuccess, getEquipmentDetailsByRequestIdFailure,
  setAdminNote, setEquipmentRequestTab, equipmentRequestRejected,
} = inventoryEquipmentRequestSlice.actions;
export const inventoryEquipmentRequestReducer = inventoryEquipmentRequestSlice.reducer;

export type InventoryEquipmentRequestSliceActions =
  | ReturnType<typeof getEquipmentMasterStart>
  | ReturnType<typeof getEquipmentMasterSuccess>
  | ReturnType<typeof getEquipmentMasterFailure>
  | ReturnType<typeof resetInventoryEquipmentRequest>
  | ReturnType<typeof createEquipmentRequestStart>
  | ReturnType<typeof createEquipmentRequestSuccess>
  | ReturnType<typeof setSelectedRowId>
  | ReturnType<typeof setComment>
  | ReturnType<typeof getEquipmentMasterStatusStart>
  | ReturnType<typeof getEquipmentMasterStatusSuccess>
  | ReturnType<typeof getEquipmentMasterStatusFailure>
  | ReturnType<typeof getEquipmentNeededDataStart>
  | ReturnType<typeof getEquipmentNeededDataSuccess>
  | ReturnType<typeof getEquipmentNeededDataFailure>
  | ReturnType<typeof addEquipmentByAdminStart>
  | ReturnType<typeof addEquipmentByAdminSuccess>
  | ReturnType<typeof addEquipmentByAdminFailure>
  | ReturnType<typeof onDeleteEquipmentByAdmin>
  | ReturnType<typeof onDeleteEquipmentByAdminSuccess>
  | ReturnType<typeof getEquipmentHistoryDataStart>
  | ReturnType<typeof getEquipmentHistoryDataSuccess>
  | ReturnType<typeof getEquipmentHistoryDataFailure>
  | ReturnType<typeof getEquipmentDetailsByRequestIdStart>
  | ReturnType<typeof getEquipmentDetailsByRequestIdSuccess>
  | ReturnType<typeof getEquipmentDetailsByRequestIdFailure>
  | ReturnType<typeof setAdminNote>
  | ReturnType<typeof setEquipmentRequestTab>
  | ReturnType<typeof equipmentRequestRejected>;
