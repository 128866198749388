import { Grid, Card, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import { useParams } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import htmlToDraft from 'html-to-draftjs';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import { RequestForm, RequestInformation } from '../../utils/types';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import { getEquipmentStart, getUnitMasterStart } from '../../redux/sliceTestEquipments';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RootState } from '../../../../../redux/rootState';
import { ACCEPT_REQUEST, VIEW_REQUEST } from '../../../ScreenUnitInformation/utils/constants';
import { customOptions } from '../../../../../Common/TextEditorOptions';
import RequiredFiled from '../../../../../utils/RequiredField';
import { ADMIN_ROLE_ID } from '../../../../../Routes/constant';
import localStorageConstants from '../../../../../utils/LocalStorageConstants';
import { NWTRegex, roundTripMilesRegex } from '../../../../../utils/regex';

export default function ComponentRequest({
  control, errors, setComment, comment, setValue, watch,
}: RequestInformation): JSX.Element {
  const dispatch = useDispatch();
  const { unitId, statusId, reqId } = useParams();
  const {
    vesselsData, materialData, tubeTypesData, manufacturerData,
  } = useSelector((state: RootState) => state.equipmentReducer);
  const vesselID = watch(RequestForm?.vessel);
  const {
    inventoryEquipmentMasterData,
    equipmentDetailsByRequestIdData,
  } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const { Status } = equipmentDetailsByRequestIdData;

  useEffect(() => {
    dispatch(getUnitMasterStart());
    dispatch(getEquipmentStart(unitId));
  }, []);

  const viewOnly = Number(statusId) === VIEW_REQUEST;

  const acceptedRequest = Number(statusId) === ACCEPT_REQUEST;
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const handleTubeType = (value: string) => {
    const tubeData = vesselsData?.find((item) => Number(item.Value) === Number(value));
    if (tubeData?.TubeCount && tubeData?.TubeLength) {
      setValue(RequestForm.tubeLength, Number(tubeData?.TubeLength) ?? '');
      setValue(RequestForm.tubeCount, Number(tubeData?.TubeCount) ?? '');
    }
  };

  useEffect(() => {
    if (vesselID) {
      const tubeData = vesselsData?.find((item) => Number(item?.Value) === Number(vesselID));
      if (tubeData?.TubeCount && tubeData?.TubeLength) {
        setValue(RequestForm.tubeLength, Number(tubeData?.TubeLength) ?? '');
        setValue(RequestForm.tubeCount, Number(tubeData?.TubeCount) ?? '');
      }
    }
  }, [vesselID]);

  useEffect(() => {
    if (equipmentDetailsByRequestIdData?.RequestId && inventoryEquipmentMasterData?.Vessel?.length > 0) {
      const convertedNote = htmlToEditorState(equipmentDetailsByRequestIdData?.Notes);
      setComment(convertedNote);
    }
  }, [equipmentDetailsByRequestIdData, inventoryEquipmentMasterData?.Vessel]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="inline_checkbox_in_a_row">
              <CustomCheckbox
                isDisable={viewOnly || acceptedRequest}
                name={RequestForm.vertical}
                control={control}
                label="Vertical"
              />
              <CustomCheckbox isDisable name={RequestForm.safetyEquipmentRequired} control={control} label="Safety Equipment Required" />
              <CustomCheckbox isDisable name={RequestForm.outside} control={control} label="Outside" />
              <CustomCheckbox isDisable name={RequestForm.flux} control={control} label="Flux" />
              <CustomCheckbox isDisable name={RequestForm.absDiff} control={control} label="Abs/Diff" />
              <CustomCheckbox isDisable name={RequestForm.crsDiff} control={control} label="Crs/Diff" />
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Job Order #</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    errors={errors}
                    name={RequestForm.jobId}
                    id={RequestForm.jobId}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Manufacturer</label>
                  <ComponentSelectInput
                    name={RequestForm.manufacturer}
                    control={control}
                    disabled
                    size="small"
                    entries={manufacturerData}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Serial #</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    isDisable
                    inputProps={{ maxLength: 255 }}
                    name={RequestForm.serialId}
                    id={RequestForm.serialId}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Model</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    isDisable
                    inputProps={{ maxLength: 255 }}
                    name={RequestForm.model}
                    id={RequestForm.model}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Vessel Configuration
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Vessel
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    name={RequestForm.vessel}
                    control={control}
                    size="small"
                    entries={vesselsData}
                    handleChange={handleTubeType}
                    disabled={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Tube Type
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    name={RequestForm.tubeType}
                    control={control}
                    size="small"
                    entries={tubeTypesData}
                    disabled={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Work Hrs</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    inputProps={{ maxLength: 3 }}
                    regEx={/^(?!.*\..*\.)[0-9]*(?:\.[0-9]*)?$/}
                    name={RequestForm.workHrs}
                    id={RequestForm.workHrs}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Material
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    name={RequestForm.material}
                    control={control}
                    size="small"
                    entries={materialData}
                    disabled={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">OD</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    regEx={roundTripMilesRegex}
                    name={RequestForm.OD}
                    id={RequestForm.OD}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">ID</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    regEx={roundTripMilesRegex}
                    name={RequestForm.ID}
                    id={RequestForm.ID}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Retest of</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    inputProps={{ maxLength: 30 }}
                    name={RequestForm.retestOf}
                    id={RequestForm.retestOf}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Tube Length</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    isDisable
                    name={RequestForm.tubeLength}
                    id={RequestForm.tubeLength}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Tube Count</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    isDisable
                    name={RequestForm.tubeCount}
                    id={RequestForm.tubeCount}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={8} sm={12} xs={12} />
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">NWT</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    regEx={NWTRegex}
                    type="number"
                    inputProps={{ maxLength: 30 }}
                    name={RequestForm.NWT}
                    id={RequestForm.NWT}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">NWT / Bell / Land</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    regEx={NWTRegex}
                    type="number"
                    inputProps={{ maxLength: 30 }}
                    name={RequestForm.NWTBellLand}
                    id={RequestForm.NWTBellLand}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">NWT Under Fins</label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    regEx={NWTRegex}
                    type="number"
                    inputProps={{ maxLength: 30 }}
                    name={RequestForm.NWTUnderFins}
                    id={RequestForm.NWTUnderFins}
                    size="small"
                    isDisable={viewOnly || acceptedRequest}
                  />
                </div>
              </Grid>
              {((!reqId && Number(roleId) !== ADMIN_ROLE_ID) || (reqId && Number(roleId) !== ADMIN_ROLE_ID))
                && (
                  <Grid item md={12} sm={12} xs={12}>
                    <Card className="comment-card">
                      <div className="customerInfo_container pl-4">
                        <div className="customerInfo">
                          Notes
                        </div>
                      </div>
                      <div className="customerInfo_main_container">
                        <Grid container spacing={2}>
                          <Grid item md={12} sm={12} xs={12} className="p-0">
                            <div>
                              <Editor
                                editorState={comment}
                                onEditorStateChange={handleComment}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                localization={{ locale: 'en' }}
                                toolbar={customOptions}
                                readOnly={viewOnly || acceptedRequest}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                )}
              {((Number(roleId) === ADMIN_ROLE_ID) || Status > 1) ? (
                <Grid item md={12} sm={12} xs={12}>
                  <div className="customerInfo_container pl-4">
                    <div className="customerInfo">
                      Admin Notes
                      <RequiredFiled />
                    </div>
                  </div>
                  <Controller
                    control={control}
                    name={RequestForm.adminNote}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        fullWidth
                        disabled={!!reqId || Number(roleId) !== ADMIN_ROLE_ID}
                        onChange={onChange}
                        value={value}
                        inputProps={{ maxLength: 255 }}
                        multiline
                        rows={3}
                        maxRows={3}
                      />
                    )}
                  />
                </Grid>
              )
                : <div />}
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
