import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BidItemsInterface, BidPdfInitialState, BidsItems } from '../types';
import { BidPdfDetailsResponseData } from './types';

const initialState: BidPdfInitialState = {
  bidFieldsValue: {} as BidItemsInterface,
  bidTableData: [],
  isLoading: true,
  bidPdfDetailsData: {} as BidPdfDetailsResponseData,
};

export const bidPdfSlice = createSlice({
  name: 'bidPdf',
  initialState,
  reducers: {
    resetBidPdfSlice() {
      return initialState;
    },
    setBidPdfValues(state, action: PayloadAction<BidItemsInterface>) {
      return {
        ...state,
        bidFieldsValue: action.payload,
      };
    },
    setBidPdfTableData(state, action: PayloadAction<BidsItems[]>) {
      return {
        ...state,
        bidTableData: action.payload,
      };
    },
    getBidPdfDetailsData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getBidPdfDetailsDataSuccess(state, action: PayloadAction<BidPdfDetailsResponseData>) {
      return {
        ...state,
        bidPdfDetailsData: action.payload,
        isLoading: false,
      };
    },
    getBidPdfDetailsDataFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
  },
});

export const {
  setBidPdfValues, setBidPdfTableData,
  getBidPdfDetailsData, getBidPdfDetailsDataSuccess, getBidPdfDetailsDataFailure, resetBidPdfSlice,
} = bidPdfSlice.actions;
export const bidPdfSliceReducer = bidPdfSlice.reducer;

export type BidPdfActions =
  | ReturnType<typeof setBidPdfValues>
  | ReturnType<typeof setBidPdfTableData>
  | ReturnType<typeof getBidPdfDetailsData>
  | ReturnType<typeof getBidPdfDetailsDataSuccess>
  | ReturnType<typeof getBidPdfDetailsDataFailure>
  | ReturnType<typeof resetBidPdfSlice>;
