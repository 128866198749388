/* eslint-disable max-len */
import React from 'react';

export function ViewIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 823">
        <path id="73 Eye" d="M15.8548 8.48931C15.7379 8.34636 13.0023 5 9.5 5C5.99769 5 3.26212 8.34636 3.14516 8.48931C3.05125 8.60508 3 8.74961 3 8.89867C3 9.04774 3.05125 9.19227 3.14516 9.30803C3.26212 9.45098 5.99769 12.7973 9.5 12.7973C13.0023 12.7973 15.7379 9.45098 15.8548 9.30803C15.9487 9.19227 16 9.04774 16 8.89867C16 8.74961 15.9487 8.60508 15.8548 8.48931ZM9.5 11.4978C7.27776 11.4978 5.31542 9.72389 4.52919 8.89867C5.31542 8.07345 7.27776 6.29956 9.5 6.29956C11.7222 6.29956 13.6846 8.07345 14.4708 8.89867C13.6846 9.72389 11.7222 11.4978 9.5 11.4978ZM9.5 6.94934C9.11446 6.94934 8.73757 7.06366 8.41701 7.27786C8.09644 7.49205 7.84659 7.7965 7.69905 8.15269C7.55151 8.50889 7.5129 8.90084 7.58812 9.27897C7.66333 9.6571 7.84899 10.0044 8.12161 10.2771C8.39423 10.5497 8.74157 10.7353 9.1197 10.8106C9.49784 10.8858 9.88978 10.8472 10.246 10.6996C10.6022 10.5521 10.9066 10.3022 11.1208 9.98167C11.335 9.6611 11.4493 9.28421 11.4493 8.89867C11.4493 8.38168 11.244 7.88585 10.8784 7.52028C10.5128 7.15471 10.017 6.94934 9.5 6.94934ZM9.5 9.54845C9.37149 9.54845 9.24586 9.51034 9.139 9.43894C9.03215 9.36755 8.94886 9.26606 8.89968 9.14733C8.8505 9.0286 8.83763 8.89795 8.86271 8.77191C8.88778 8.64586 8.94966 8.53008 9.04054 8.43921C9.13141 8.34834 9.24719 8.28645 9.37323 8.26138C9.49928 8.23631 9.62993 8.24918 9.74866 8.29836C9.86739 8.34754 9.96887 8.43082 10.0403 8.53767C10.1117 8.64453 10.1498 8.77016 10.1498 8.89867C10.1498 9.071 10.0813 9.23628 9.95946 9.35814C9.83761 9.47999 9.67233 9.54845 9.5 9.54845Z" fill="#299CDB" />
      </g>
    </svg>
  );
}
