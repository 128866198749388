import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card, Grid,
} from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import { useForm } from 'react-hook-form';
import Arrow from '../../assets/arrowUp.svg';
import { RootState } from '../../redux/rootState';
import DataTable from '../../Common/DataTable';
import { setLoader } from '../../redux/loaderSlice';
import FileIcon from '../../assets/FileIcon.svg';
import FolderIcon from '../../assets/NewFolderIcon.svg';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { ReportingDataType, ReportingFormValues } from './utils/types';
import { ReportingFormNames, TreeData } from './utils/constants';
import style from './utils/reportingStyle.module.scss';
import ComponentTextInput from '../../Common/ComponentTextinput/ComponentTextInput';
import { Empty } from '../ScreenAddSmc/Utils/TypeSmc';
import EndPoints from '../../Routes/EndPoints';
import {
  getReportWorkspaceListStart, getWorkSpaceByIdStart, resetWorkspaceByIdState, resetWorkspaceState,
} from './redux/sliceReportWorkspace';
import { formatDateString } from '../../utils/CommonFunctions';

export default function ReportingSystem() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [jobOrderId, setJobOrderId] = useState('');
  const navigate = useNavigate();

  const {
    control, setValue,
  } = useForm<ReportingFormValues>({
    // resolver: yupResolver(unitInfoValidationSchema),
  });
  const dispatch = useDispatch();
  const {
    reportWorkspaceList, totalEntries, reportWorkspaceDataById, isLoading,
  } = useSelector((state: RootState) => state.reportWorkspaceReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getReportWorkspaceListStart(params));
  };

  useEffect(() => {
    handleData();
    return () => {
      setJobOrderId('');
      dispatch(resetWorkspaceState());
    };
  }, []);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Report', label: 'Report' },
      { key: 'Site', label: 'Site' },
      { key: 'EndDate', label: 'End Date' },
    ],
    [],
  );

  const data: ReportingDataType[] = useMemo(() => reportWorkspaceList?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      Report: item?.Report,
      Site: item?.Site,
      EndDate: formatDateString(item?.EndDate),
      action: 'edit&sendMail',
      id: `${item?.JobOrder}/${item.CustomerId}`,
    };
    return transformedDataItem;
  }), [reportWorkspaceList]);

  const handleSendEmail = (id: string) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (reportWorkspaceDataById) {
      setValue(ReportingFormNames.SerialNumber, reportWorkspaceDataById?.SerialNo);
      setValue(ReportingFormNames.InspectedBy, reportWorkspaceDataById?.InspectedBy);
      setValue(ReportingFormNames.Site, reportWorkspaceDataById?.Site);
      setValue(ReportingFormNames.Delivery, reportWorkspaceDataById?.Delivery);
    }
  }, [reportWorkspaceDataById]);

  const handleEdit = (id: string) => {
    const jobOrder = id.split('/');
    dispatch(resetWorkspaceByIdState());
    setJobOrderId(jobOrder[0]);
    dispatch(getWorkSpaceByIdStart({ jobOrderId: jobOrder[0] }));
  };

  const excelData = useMemo(() => reportWorkspaceList?.map((item) => {
    const transformedDataItem = {
      Report: item?.Report,
      Site: item?.Site,
      EndDate: item?.EndDate,
    };
    return transformedDataItem;
  }), [reportWorkspaceList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Reporting_System_list',
    );
  };

  const renderTree = (nodes: Empty) => nodes?.length > 0
  && nodes.map((node: Empty) => (
    <div
      key={node?.Id}
      className="mainTest"
      onClick={node?.TypeId === 104 ? () => window.open(node?.Url) : undefined}
    >
      <TreeItem
        // onClick={() => handleOnClick(node)}
        nodeId={String(node?.Id)}
        disabled={false}
        label={(
          <span style={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px' }}>
              <img src={node?.TypeId === 104 ? FileIcon : FolderIcon} alt="folder" />
            </span>
            {(node?.TypeId === 104 || node?.TypeId === 109)
              ? <span className={style.textUnderLine} onClick={() => window.open(node?.Url)}>{node?.Name}</span>
              : <span style={{ width: '100%' }}>{node?.Name}</span>}
          </span>
        )}
      >
        {node?.Children && renderTree(node?.Children)}
      </TreeItem>
    </div>
  ));

  return (
    <div>
      <ComponentPageHeader subHeading="REPORTING WORKSPACE" downloadExcel={downloadExcel} />
      <div style={{ padding: '20px', height: '87vh', overflow: 'auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={Object.keys(reportWorkspaceDataById).length === 0 ? 12 : 7}>
            <div className={style.tableHeading}>
              <span className={style.tableHeadingText}>
                PDF in Box
              </span>
              <Button
                className="button_save_and_next"
                onClick={() => navigate(`/${EndPoints.REPORT_WORKSPACE}/${EndPoints.COMBINE_REPORTS}`)}
              >
                Combine Reports
              </Button>
            </div>
            <DataTable<ReportingDataType>
              data={data}
              columns={columns}
              totalRecords={totalEntries}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
              handleOilReportEmailModal={handleSendEmail}
              handleEdit={handleEdit}
            />
          </Grid>
          { Object.keys(reportWorkspaceDataById).length !== 0 && (
          <Grid item xs={12} sm={12} md={5}>
            <Card>
              <div className="customerInfo_main_container">
                <div className={style.tableHeading}>
                  <span className={style.tableHeadingText}>
                    Report Section
                  </span>
                </div>
                <div style={{ height: '68vh', overflow: 'auto' }}>
                  <TreeView
                    aria-label="multi-select"
                    defaultCollapseIcon={<img src={Arrow} alt="Edit folder" />}
                    defaultExpandIcon={<img src={Arrow} alt="Edit folder" />}
                    multiSelect
                    defaultExpanded={['4', '179', '158']}
                    className="mainTreeStructure"
                  >
                    {renderTree(TreeData(reportWorkspaceDataById, jobOrderId))}
                  </TreeView>
                </div>
                <div className={style.checkListButton}>
                  <Button
                    className="button_save_and_next"
                    onClick={() => navigate(`/${EndPoints.REPORT_WORKSPACE}/${EndPoints.GENERATE_PDF}/${jobOrderId}`)}
                  >
                    Generate PDF
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>
          )}
        </Grid>
        <Card style={{ marginTop: '20px' }}>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">
                    Serial Number
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={ReportingFormNames.SerialNumber}
                    id={ReportingFormNames.SerialNumber}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">
                    Inspected By
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={ReportingFormNames.InspectedBy}
                    id={ReportingFormNames.InspectedBy}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">
                    Site
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={ReportingFormNames.Site}
                    id={ReportingFormNames.Site}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">
                    Delivery
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={ReportingFormNames.Delivery}
                    id={ReportingFormNames.Delivery}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
            <div className={style.checkListButton}>
              <Button className="button_save_and_next" onClick={() => navigate(`/${EndPoints.REPORT_WORKSPACE}/${EndPoints.REPORT_CHECKLIST}`)}>
                Checklist
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
