import dayjs from 'dayjs';

export const MAIN_TAB = 0;

export const MainDefaultValue = {
  sirName: 1,
  firstName: '',
  lastName: '',
  address: '',
  zip: '',
  state: '',
  city: '',
  country: 'US',
  code: '+1',
  phone: '',
  cell: '',
  email: '',
  initials: '',
  territory: '1',
  hireDate: dayjs(),
  lsInitials: '',
  boZip: '',
  boState: '',
  boCity: '',
  boCountry: 'US',
  sbZip: '',
  sbState: '',
  sbCity: '',
  sbCountry: 'US',
};

export const analystBody = {
  SalutationId: 1,
  FirstName: '',
  LastName: '',
  Address: '',
  Pincode: '',
  StateName: '',
  CityName: '',
  CountryName: 'US',
  PhoneCode: '+1',
  Phone: '',
  Cell: '',
  Email: '',
  Initials: '',
  TerritoryId: 1,
  HireDate: '',
  LsInitials: '',
  BasePincode: '',
  BaseStateName: '',
  BaseCityName: '',
  BaseCountryName: 'US',
  SecondaryPincode: '',
  SecondaryStateName: '',
  SecondaryCityName: '',
  SecondaryCountryName: 'US',
};

export const ANALYST_INFO_KEY = 'analystInfo';
export const ANALYST_BASE_OUT_INFO_KEY = 'analystBaseOutInfo';
export const CUSTOMER_BILLING_INFO_KEY = 'customerBillingInfoKey';

export const TableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'from', label: 'From' },
  { key: 'to', label: 'To' },
];
export const ANALYST_SECONDARY_BASE_INFO_KEY = 'analystSecondaryBaseInfo';

export const AddLeaveFormDefaultValues = {
  leaveType: '',
  description: '',
};

export const addLeaveType = {
  MAIN_LEAVE_ADD_UPDATE: 1,
  BALANCE_LEAVE_ADD_UPDATE: 2,
};

export const FYE_CONSTANT = 9;

export const VACATION_CONFIRMATION_MSG = 'has no remaining leaves, are you sure you want to proceed adding the leave?';
