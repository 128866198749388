import { Card, Grid } from '@mui/material';
import React from 'react';
import CustomRadioButton from '../../../../Common/ComponentRadioButton';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../../utils/RequiredField';
import { TubeChartEnum, TubeDetailsProps } from '../../utils/tubeChartTypes';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';

export default function TubeDetail({ control }:TubeDetailsProps) {
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Test End
                <RequiredFiled />
              </label>
              <div style={{
                padding: '2px 10px',
                border: '1px solid #CED4DA',
                borderRadius: '4px',
              }}
              >
                <CustomRadioButton
                  control={control}
                  // isDisable={Boolean(roleId === ANALYST_ROLE_ID)}
                  name={TubeChartEnum.originalTestEnd}
                  defaultValue=""
                  isDisable
                  options={[
                    { value: 'original', label: 'Original Test End' },
                    { value: 'reverse', label: 'Reverse Test End' },
                  ]}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>Vessel Top</label>
              {/* <ComponentSelectInput
                name={TubeChartEnum.tubeSize}
                control={control}
                disabled={Boolean(String(roleId) === ANALYST_ROLE_ID)}
                size="small"
                entries={[]}
              />  */}
              <ComponentTextInput
                name={TubeChartEnum.vesselTop}
                id={TubeChartEnum.vesselTop}
                control={control}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>Total Tubes</label>
              <ComponentTextInput
                name={TubeChartEnum.totalTubes}
                id={TubeChartEnum.totalTubes}
                control={control}
                isDisable
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
