import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PriceLIstData, PriceListingstData } from '../utils/TypesPriceList';
import ComponentPriceEditDialog from './ComponentPriceEditDialog';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { getSystemPriceStart } from '../Redux/slicePriceList';
import { setLoader } from '../../../../redux/loaderSlice';

function ComponentPriceListTable() {
  const dispatch = useDispatch();

  const { SystemPriceList, isLoading } = useSelector((state: RootState) => state.SystemPriceListReducer);

  const [rowwId, setRowwId] = useState('');

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const [open, setOpen] = useState(false);

  const handleAction = (id: string) => {
    setOpen(!open);
    setRowwId(id);
  };

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getSystemPriceStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'KitDescription', label: 'Description' },
      { key: 'MeasurementType', label: 'Parameter for Measurement' },
      { key: 'Price', label: 'Price' },
      { key: 'FixedPrice', label: 'Fixed Price' },
    ],
    [],
  );
  const tableData: PriceLIstData[] = useMemo(() => SystemPriceList?.Data?.map((item: PriceListingstData, index: number) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit',
      KitDescription: item?.KitDescription,
      MeasurementType: item?.MeasurementType,
      Price: `$ ${item?.Price}`,
      FixedPrice: item?.FixedPrice,

      TubeAvailable: item?.TubeAvailable,
      Quantity: item?.Quantity,

      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [SystemPriceList]);

  return (
    <>

      <DataTable<PriceLIstData>
        checkBoxColumns={['FixedPrice']}
        data={tableData}
        columns={TableColumns}
        totalRecords={SystemPriceList?.TotalRecords}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        tableHeader="Variables for Price List"
        handleEdit={handleAction}
      />
      <ComponentPriceEditDialog show={open} setOpenFrom={() => setOpen(false)} rowId={rowwId} />
    </>
  );
}

export default ComponentPriceListTable;
