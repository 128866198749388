import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetUserProfileData, UpdateProfileData, UserProfileState } from '../Utils/TypeProfile';

const initialState: UserProfileState = {
  userProfile: {} as GetUserProfileData,
  isLoading: false,
  error: '',

  profileUpdateData: {},
  responseMessage: '',
  isSuccess: false,
  isImageSuccess: false,
  loading: false,

  userImage: '',
};

const getUserProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    getUserProfileStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetUserProfile(state, action: PayloadAction<GetUserProfileData >) {
      return {
        ...state,
        isLoading: false,
        userProfile: action.payload,
      };
    },
    failureGetUserProfile(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    updateUserProfile(state, action: PayloadAction<UpdateProfileData>) {
      return {
        ...state,
        loading: true,
        profileUpdateData: action.payload,
      };
    },
    successUpdateProfile(state) {
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    },
    failureUpdateProfile(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    updateProfileImage(state, action) {
      return {
        ...state,
        isLoading: true,
        userImage: action.payload,
      };
    },
    updateImageSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        isImageSuccess: true,
        isLoading: false,
        responseMessage: action.payload,
      };
    },
    updateImageFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isImageSuccess: false,
        isLoading: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isSuccess: false,
        isImageSuccess: false,
        responseMessage: '',
      };
    },
  },
});

export const {
  getUserProfileStart, successGetUserProfile, failureGetUserProfile, updateUserProfile, successUpdateProfile, failureUpdateProfile,
  updateProfileImage, updateImageSuccess, updateImageFailure, resetState,
} = getUserProfileSlice.actions;
export const userProfileReducer = getUserProfileSlice.reducer;

export type GetUserProfileActions =
    | ReturnType<typeof getUserProfileStart>
    | ReturnType<typeof successGetUserProfile>
    | ReturnType<typeof failureGetUserProfile>
    | ReturnType<typeof updateUserProfile>
    | ReturnType<typeof successUpdateProfile>
    | ReturnType<typeof failureUpdateProfile>
    | ReturnType<typeof updateProfileImage>
    | ReturnType<typeof updateImageSuccess>
    | ReturnType<typeof resetState>
    | ReturnType<typeof updateImageFailure>;
