import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import DataTable from '../../../../../Common/DataTable';
import { TableColumns } from '../utils/DataFreonCylinderList';
import { FreonCylinderListData } from '../utils/TypesFreonCylinderList';
import EndPoints from '../../../../../Routes/EndPoints';
import ComponentDeleteModal from '../../../../../Common/DeleteModal/ComponentDeleteModal';
import { deleteFreonStart, getFreonList, resetData } from '../redux/sliceListFreonCylinder';
import { RootState } from '../../../../../redux/rootState';
import { formatTableData } from '../utils/helper';
import { setLoader } from '../../../../../redux/loaderSlice';
import { resetStateFreon } from '../../AddFreonCylinder/redux/sliceAddFreonCylinder';
import { removeSelectedCustomer } from '../../../../addCustomer/redux/addCustomerSlice';
import { onGenerateInvoiceStart } from '../../../../../redux/masters/masterSlice';
import { generateInvoiceType } from '../../../../../utils/Constant';

export function ComponentFreonCylinderTable() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const today = new Date();
  const previousMonth = new Date(today);
  previousMonth.setMonth(today.getMonth() - 1);
  const [dateRangeData, setDateRange] = useState<DateRange>([previousMonth, today]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [fromDate, setFromDate] = useState(format(dateRangeData[0], 'dd-MM-yyyy'));
  const [toDate, setToDate] = useState(format(dateRangeData[1], 'dd-MM-yyyy'));
  const [data, setData] = useState<FreonCylinderListData[]>([]);
  const dispatch = useDispatch();

  const {
    freonList, freonTotalEntries, totalNumberOfKit, totalBillAmount, isLoading,
  } = useSelector((state: RootState) => state.FreonListReducer);

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      fromDate,
      toDate,
    };
    dispatch(getFreonList(payload));
  };

  useEffect(() => {
    handleData();
  }, [fromDate]);

  const handleButtonClick = () => {
    navigate(EndPoints.ADD_FREON_CYLINDER);
    dispatch(resetStateFreon());
    dispatch(removeSelectedCustomer());
    dispatch(resetData());
  };

  const handleDelete = (kitId: string) => {
    setDeleteModal(true);
    setSelectedId(kitId);
  };

  const handelDeleteModal = () => {
    const payload = {
      kitId: selectedId,
      page,
      rowsPerPage,
      searchQuery,
      fromDate,
      toDate,
    };
    dispatch(deleteFreonStart(payload));
    setDeleteModal(false);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (dateRangeData !== null) {
      const fromDatePicker = dateRangeData[0];
      const toDatePicker = dateRangeData[1];
      const validFromDate = format(fromDatePicker, 'dd-MM-yyyy');
      const validToDate = format(toDatePicker, 'dd-MM-yyyy');
      setFromDate(validFromDate);
      setToDate(validToDate);
    }
  }, [dateRangeData]);

  const handleDateRangeChange = (value: DateRange) => {
    if (!value) {
      return setDateRange([previousMonth, today]);
    }
    return setDateRange(value);
  };

  const handleEdit = (KitId: string) => {
    navigate(`${KitId}`);
  };

  const handleSearchButtonClick = () => {
    const KitIds: string[] = [];
    data?.forEach(((element: FreonCylinderListData) => {
      if (element?.isChecked) {
        KitIds.push(element?.KitId);
      }
    }));
    const payload = {
      type: 1,
      selectedIds: KitIds,
    };
    dispatch(onGenerateInvoiceStart(payload));
  };

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: FreonCylinderListData) => {
      if (element?.KitId === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (freonList) {
      setData(formatTableData(freonList));
    }
  }, [freonList]);

  const freonSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  const handleGenerateInvoice = (id: string) => {
    const payload = {
      type: generateInvoiceType.FREON_CYLINDER_TYPE,
      selectedIds: [id],
    };
    dispatch(onGenerateInvoiceStart(payload));
  };

  return (
    <>
      <DataTable<FreonCylinderListData>
        handleButtonClick={handleButtonClick}
        data={data?.length > 0 ? data : []}
        checkBoxColumns={['billed']}
        columns={TableColumns}
        totalRecords={freonTotalEntries}
        buttonText="Add Freon Cyl."
        searchButton={data?.length === 0 ? '' : 'Generate Invoice'}
        handleSearchButtonClick={handleSearchButtonClick}
        searchButtonDisable={freonSelected}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        handleDelete={handleDelete}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleDateRangeChange={handleDateRangeChange}
        dateRangeData={dateRangeData}
        labelOne="Blue Cylinder Assigned -"
        valueOne={totalNumberOfKit}
        labelTwo="Total Bill Amt -"
        valueTwo={`$${totalBillAmount}`}
        dateRange="date"
        handleEdit={handleEdit}
        checkBoxWithKey={['sn']}
        handleCheckBoxWithKey={handleCheckBoxWithKey}
        handleGenerateInvoice={handleGenerateInvoice}
      />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}
