import dayjs from 'dayjs';
import { FreonMainFormValues } from './types';

export const MainDefaultValue:FreonMainFormValues = {
  customerSearch: '',
  customer: '',
  contact: '',
  shipDate: dayjs(),
  shipVia: '',
  blueCylinders: '',
  cylindersPrice: 1,
  additionalAmount: 0,
  billAmount: 0,
  invoice: '',
  invoiceDate: dayjs(),
  PO: '',
};
