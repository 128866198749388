import { convertDateFormat, convertDateFormatDDMMYYYY } from '../../../utils/CommonFunctions';
import { ExpenseFormEnum, ExpenseMainFormValues, ResultDataById } from './types.d';

export function setValues(ExpenseReportDataById:ResultDataById, setValue: SetValue) {
  setValue(ExpenseFormEnum.analyst, ExpenseReportDataById?.AnalystName);
  setValue(ExpenseFormEnum.incomplete, ExpenseReportDataById?.Incomplete);
  setValue(ExpenseFormEnum.jobOrder, ExpenseReportDataById?.JobOrder);
  setValue(ExpenseFormEnum.miscDescription, ExpenseReportDataById?.MiscDesc);
  setValue(ExpenseFormEnum.date, convertDateFormat(ExpenseReportDataById?.ExpDate));
  setValue(ExpenseFormEnum.CashLodge, ExpenseReportDataById?.CashLodge);
  setValue(ExpenseFormEnum.ChargeLodge, ExpenseReportDataById?.ChequeLodge);
  setValue(ExpenseFormEnum.CashBreak, ExpenseReportDataById?.CashBreak);
  setValue(ExpenseFormEnum.ChargeBreak, ExpenseReportDataById?.ChequeBreak);
  setValue(ExpenseFormEnum.CashLunch, ExpenseReportDataById?.CashLunch);
  setValue(ExpenseFormEnum.ChargeLunch, ExpenseReportDataById?.ChequeLunch);
  setValue(ExpenseFormEnum.CashDinner, ExpenseReportDataById?.CashDinner);
  setValue(ExpenseFormEnum.ChargeDinner, ExpenseReportDataById?.ChequeDinner);
  setValue(ExpenseFormEnum.CashCar, ExpenseReportDataById?.CashCar);
  setValue(ExpenseFormEnum.ChargeCar, ExpenseReportDataById?.ChequeCar);
  setValue(ExpenseFormEnum.CashPark, ExpenseReportDataById?.CashPark);
  setValue(ExpenseFormEnum.ChargePark, ExpenseReportDataById?.ChequePark);
  setValue(ExpenseFormEnum.CashGas, ExpenseReportDataById?.CashGas);
  setValue(ExpenseFormEnum.ChargeGas, ExpenseReportDataById?.ChequeGas);
  setValue(ExpenseFormEnum.CashAir, ExpenseReportDataById?.CashAir);
  setValue(ExpenseFormEnum.ChargeAir, ExpenseReportDataById?.ChequeAir);
  setValue(ExpenseFormEnum.CashSkycap, ExpenseReportDataById?.CashSkycap);
  setValue(ExpenseFormEnum.ChargeSkycap, ExpenseReportDataById?.ChequeSkycap);
  setValue(ExpenseFormEnum.CashTaxi, ExpenseReportDataById?.CashTaxi);
  setValue(ExpenseFormEnum.ChargeTaxi, ExpenseReportDataById?.ChequeTaxi);
  setValue(ExpenseFormEnum.CashEnter, ExpenseReportDataById?.CashEnter);
  setValue(ExpenseFormEnum.ChargeEnter, ExpenseReportDataById?.ChequeEnter);
  setValue(ExpenseFormEnum.CashPhone, ExpenseReportDataById?.CashPhone);
  setValue(ExpenseFormEnum.ChargePhone, ExpenseReportDataById?.ChequePhone);
  setValue(ExpenseFormEnum.CashMisc, ExpenseReportDataById?.CashMisc);
  setValue(ExpenseFormEnum.ChargeMisc, ExpenseReportDataById?.ChequeMisc);
  setValue(ExpenseFormEnum.TotalLodge, ExpenseReportDataById.CashLodge + ExpenseReportDataById.ChequeLodge);
  setValue(ExpenseFormEnum.TotalBreak, ExpenseReportDataById.CashBreak + ExpenseReportDataById.ChequeBreak);
  setValue(ExpenseFormEnum.TotalLunch, ExpenseReportDataById.CashLunch + ExpenseReportDataById.ChequeLunch);
  setValue(ExpenseFormEnum.TotalDinner, ExpenseReportDataById.CashDinner + ExpenseReportDataById.ChequeDinner);
  setValue(ExpenseFormEnum.TotalCar, ExpenseReportDataById.CashCar + ExpenseReportDataById.ChequeCar);
  setValue(ExpenseFormEnum.TotalPark, ExpenseReportDataById.CashPark + ExpenseReportDataById.ChequePark);
  setValue(ExpenseFormEnum.TotalGas, ExpenseReportDataById.CashGas + ExpenseReportDataById.ChequeGas);
  setValue(ExpenseFormEnum.TotalAir, ExpenseReportDataById.CashAir + ExpenseReportDataById.ChequeAir);
  setValue(ExpenseFormEnum.TotalSkycap, ExpenseReportDataById.CashSkycap + ExpenseReportDataById.ChequeSkycap);
  setValue(ExpenseFormEnum.TotalTaxi, ExpenseReportDataById.CashTaxi + ExpenseReportDataById.ChequeTaxi);
  setValue(ExpenseFormEnum.TotalEnter, ExpenseReportDataById.CashEnter + ExpenseReportDataById.ChequeEnter);
  setValue(ExpenseFormEnum.TotalPhone, ExpenseReportDataById.CashPhone + ExpenseReportDataById.ChequePhone);
  setValue(ExpenseFormEnum.TotalMisc, ExpenseReportDataById.CashMisc + ExpenseReportDataById.ChequeMisc);
}

export const expenseData = () => [
  {
    id: 1,
    cashName: ExpenseFormEnum.CashLodge,
    chargeName: ExpenseFormEnum.ChargeLodge,
    totalName: ExpenseFormEnum.TotalLodge,
    vesselName: 'Lodging',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 2,
    cashName: ExpenseFormEnum.CashBreak,
    chargeName: ExpenseFormEnum.ChargeBreak,
    totalName: ExpenseFormEnum.TotalBreak,
    vesselName: 'Breakfast',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 3,
    cashName: ExpenseFormEnum.CashLunch,
    chargeName: ExpenseFormEnum.ChargeLunch,
    totalName: ExpenseFormEnum.TotalLunch,
    vesselName: 'Lunch',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 4,
    cashName: ExpenseFormEnum.CashDinner,
    chargeName: ExpenseFormEnum.ChargeDinner,
    totalName: ExpenseFormEnum.TotalDinner,
    vesselName: 'Dinner',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 5,
    cashName: ExpenseFormEnum.CashCar,
    chargeName: ExpenseFormEnum.ChargeCar,
    totalName: ExpenseFormEnum.TotalCar,
    vesselName: 'Rental Car',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 6,
    cashName: ExpenseFormEnum.CashPark,
    chargeName: ExpenseFormEnum.ChargePark,
    totalName: ExpenseFormEnum.TotalPark,
    vesselName: 'Parking / Tolls',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 7,
    cashName: ExpenseFormEnum.CashGas,
    chargeName: ExpenseFormEnum.ChargeGas,
    totalName: ExpenseFormEnum.TotalGas,
    vesselName: 'Gas',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 8,
    cashName: ExpenseFormEnum.CashAir,
    chargeName: ExpenseFormEnum.ChargeAir,
    totalName: ExpenseFormEnum.TotalAir,
    vesselName: 'Air Fare',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 9,
    cashName: ExpenseFormEnum.CashSkycap,
    chargeName: ExpenseFormEnum.ChargeSkycap,
    totalName: ExpenseFormEnum.TotalSkycap,
    vesselName: 'Sky Caps',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 10,
    cashName: ExpenseFormEnum.CashTaxi,
    chargeName: ExpenseFormEnum.ChargeTaxi,
    totalName: ExpenseFormEnum.TotalTaxi,
    vesselName: 'Taxi',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 11,
    cashName: ExpenseFormEnum.CashEnter,
    chargeName: ExpenseFormEnum.ChargeEnter,
    totalName: ExpenseFormEnum.TotalEnter,
    vesselName: 'Entertainment',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 12,
    cashName: ExpenseFormEnum.CashPhone,
    chargeName: ExpenseFormEnum.ChargePhone,
    totalName: ExpenseFormEnum.TotalPhone,
    vesselName: 'Phone',
    cash: 0,
    charge: 0,
    total: 0,
  },
  {
    id: 13,
    cashName: ExpenseFormEnum.CashMisc,
    chargeName: ExpenseFormEnum.ChargeMisc,
    totalName: ExpenseFormEnum.TotalMisc,
    vesselName: 'Misc',
    cash: 0,
    charge: 0,
    total: 0,
  },
];

export function getRequestPayload(data: ExpenseMainFormValues, expenseReportDataById: ResultDataById) {
  return {
    CashLodge: data.CashLodge,
    ChequeLodge: data?.ChargeLodge,
    CashBreak: data?.CashBreak,
    ChequeBreak: data?.ChargeBreak,
    CashLunch: data?.CashLunch,
    ChequeLunch: data?.ChargeLunch,
    CashDinner: data.CashDinner,
    ChequeDinner: data?.ChargeDinner,
    CashCar: data?.CashCar,
    ChequeCar: data?.ChargeCar,
    CashPark: data?.CashPark,
    ChequePark: data?.ChargePark,
    CashGas: data?.CashGas,
    ChequeGas: data?.ChargeGas,
    CashAir: data?.CashAir,
    ChequeAir: data?.ChargeAir,
    CashSkycap: data?.CashSkycap,
    ChequeSkycap: data?.ChargeSkycap,
    CashTaxi: data?.CashTaxi,
    ChequeTaxi: data.ChargeTaxi,
    CashEnter: data?.CashEnter,
    ChequeEnter: data?.ChargeEnter,
    CashPhone: data?.CashPhone,
    ChequePhone: data?.ChargePhone,
    CashMisc: data.CashMisc,
    ChequeMisc: data?.ChargeMisc,

    Incomplete: data.incomplete,
    AnalystId: expenseReportDataById.AnalystId,
    MiscDesc: data?.miscDescription,
    JobOrder: data.jobOrder,
    ExpDate: convertDateFormatDDMMYYYY(data.date),
    Id: expenseReportDataById?.Id,
  };
}

export const cashFieldNames = [ExpenseFormEnum.CashLodge, ExpenseFormEnum.CashBreak, ExpenseFormEnum.CashLunch, ExpenseFormEnum.CashDinner,
  ExpenseFormEnum.CashCar, ExpenseFormEnum.CashPark, ExpenseFormEnum.CashGas, ExpenseFormEnum.CashAir, ExpenseFormEnum.CashSkycap,
  ExpenseFormEnum.CashTaxi, ExpenseFormEnum.CashEnter, ExpenseFormEnum.CashPhone, ExpenseFormEnum.CashMisc,
];
export const chargeFieldNames = [ExpenseFormEnum.ChargeLodge, ExpenseFormEnum.ChargeBreak, ExpenseFormEnum.ChargeLunch, ExpenseFormEnum.ChargeDinner,
  ExpenseFormEnum.ChargeCar, ExpenseFormEnum.ChargePark, ExpenseFormEnum.ChargeGas, ExpenseFormEnum.ChargeAir, ExpenseFormEnum.ChargeSkycap,
  ExpenseFormEnum.ChargeTaxi, ExpenseFormEnum.ChargeEnter, ExpenseFormEnum.ChargePhone, ExpenseFormEnum.ChargeMisc,
];
