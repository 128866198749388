/* eslint-disable max-len */
import React from 'react';

export function ZoneIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3389 13.2143C15.4671 9.87469 15.1996 10.2913 15.2609 10.2043C16.0358 9.11137 16.4453 7.82508 16.4453 6.48438C16.4453 2.92891 13.5601 0 10 0C6.45148 0 3.55469 2.92313 3.55469 6.48438C3.55469 7.82422 3.97281 9.14418 4.77305 10.2518L6.66102 13.2143C4.64246 13.5245 1.21094 14.4489 1.21094 16.4844C1.21094 17.2264 1.69523 18.2838 4.00242 19.1078C5.61344 19.6831 7.7434 20 10 20C14.2197 20 18.7891 18.8097 18.7891 16.4844C18.7891 14.4486 15.3616 13.5251 13.3389 13.2143ZM5.75191 9.60723C5.74547 9.59715 5.73875 9.5873 5.73172 9.57762C5.06582 8.66152 4.72656 7.57582 4.72656 6.48438C4.72656 3.55398 7.08617 1.17188 10 1.17188C12.9078 1.17188 15.2734 3.55504 15.2734 6.48438C15.2734 7.57758 14.9406 8.62645 14.3107 9.5184C14.2543 9.59285 14.5487 9.13535 10 16.273L5.75191 9.60723ZM10 18.8281C5.39086 18.8281 2.38281 17.4733 2.38281 16.4844C2.38281 15.8197 3.92844 14.7268 7.35344 14.3007L9.50586 17.6782C9.61344 17.847 9.79977 17.9492 9.99996 17.9492C10.2002 17.9492 10.3865 17.847 10.4941 17.6782L12.6464 14.3007C16.0715 14.7268 17.6172 15.8197 17.6172 16.4844C17.6172 17.4649 14.6362 18.8281 10 18.8281Z" fill="black" />
      <path d="M10 3.55469C8.38457 3.55469 7.07031 4.86895 7.07031 6.48438C7.07031 8.0998 8.38457 9.41406 10 9.41406C11.6154 9.41406 12.9297 8.0998 12.9297 6.48438C12.9297 4.86895 11.6154 3.55469 10 3.55469ZM10 8.24219C9.03074 8.24219 8.24219 7.45363 8.24219 6.48438C8.24219 5.51512 9.03074 4.72656 10 4.72656C10.9693 4.72656 11.7578 5.51512 11.7578 6.48438C11.7578 7.45363 10.9693 8.24219 10 8.24219Z" fill="black" />
    </svg>
  );
}
