import React, { useEffect, useMemo } from 'react';
import {
  Button, Select, MenuItem, Stack, Pagination,
} from '@mui/material';
import { ModalPaginationProps } from '../../utils/types';

export function ModalPagination({
  rowsPerPage, page, setRowsPerPage, setPage, totalEntries,
  handleData,
}: ModalPaginationProps) {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handlePreviousClick = (event: React.ChangeEvent<unknown>) => {
    handleChange(event, page - 1);
  };

  const handleNextClick = (event: React.ChangeEvent<unknown>) => {
    handleChange(event, page + 1);
  };

  useEffect(() => {
    handleData();
  }, [page, rowsPerPage]);

  const totalPages = useMemo(() => Math.ceil(totalEntries / (rowsPerPage || 1)), [rowsPerPage, totalEntries]);
  return (
    <div className="table-pagination-style">
      <div className="table-pagination-number-per-page">
        <span className="font-size">Show</span>
        <span className="select-style">
          <Select
            value={rowsPerPage}
            className="table-select-style"
            onChange={(e) => {
              if (setPage) {
                setPage(Number(1));
              }
              setRowsPerPage(Number(e.target.value));
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
          </Select>
        </span>
        <span className="font-size">entries</span>
      </div>
      <Stack spacing={2} className="table-pagination-number">
        <Button
          variant="outlined"
          color="primary"
          disabled={page === 1}
          onClick={handlePreviousClick}
          className="previousBtnPagination"
        >
          Previous
        </Button>
        <Pagination
          count={Math.ceil(totalEntries / rowsPerPage)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          shape="rounded"
          className="pagination"
          hidePrevButton
          hideNextButton
        />
        <Button
          variant="outlined"
          color="primary"
          disabled={page === totalPages}
          onClick={handleNextClick}
          className="nextBtnPagination"
        >
          Next
        </Button>

      </Stack>
    </div>
  );
}
