import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TypesUnlinkComponentModal } from './TypeUnlinkComponentModal';
import styles from './UnlinkComponentModalStyle.module.scss';
import { UnlinkIcon } from '../../assets/Icons/InventoryIcons/UnlinkIcon';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function UnlinkComponentModal({ show, setOpenFrom, handleUnlink }: TypesUnlinkComponentModal): JSX.Element {
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.deleteIcon}>
          <UnlinkIcon />
        </div>
        <div className={styles.modalModalHeading}>
          Are you sure you want to Unlink?
        </div>
        <div className={styles.divButtons}>
          <button
            type="button"
            className={`btn ${styles.deleteBtn}`}
            onClick={() => {
              if (handleUnlink) {
                handleUnlink();
              }
            }}
          >
            Yes, Unlink!
          </button>
          <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>Cancel</button>
        </div>
      </Box>
    </Modal>
  );
}
