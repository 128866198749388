import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DataForPrintPdfByRequestIdResultInterface, GetSendRequestListByIdResult, ProductDetailsDataByIdInterface, SendEquipmentRequestInitialStateInterface,
} from './type.d';

const initialState: SendEquipmentRequestInitialStateInterface = {
  isLoading: false,
  sendRequestListByIdResult: {} as GetSendRequestListByIdResult,
  equipmentSended: false,
  productDetailsLoading: false,
  productDetailsDataById: {} as ProductDetailsDataByIdInterface,
  isPdfLoading: false,
  dataForPrintPdfByRequestId: {} as DataForPrintPdfByRequestIdResultInterface,
  idPdfDataGet: false,
};

export const sendEquipmentRequestSlice = createSlice({
  name: 'sendEquipmentRequestSlice',
  initialState,
  reducers: {
    resetSendEquipmentRequestSlice() {
      return initialState;
    },
    getSendRequestListByIdStart(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSendRequestListByIdSuccess(state, action: PayloadAction<GetSendRequestListByIdResult>) {
      return {
        ...state,
        isLoading: false,
        sendRequestListByIdResult: action.payload,
      };
    },
    getSendRequestListByIdFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    sendEquipmentStart(state, _action) {
      return {
        ...state,
        equipmentSended: false,
      };
    },
    sendEquipmentSuccess(state, _action) {
      return {
        ...state,
        equipmentSended: true,
      };
    },
    getProductDetailsByIdStart(state, _action) {
      return {
        ...state,
        productDetailsLoading: true,
      };
    },
    getProductDetailsByIdSuccess(state, action: PayloadAction<ProductDetailsDataByIdInterface>) {
      return {
        ...state,
        productDetailsLoading: false,
        productDetailsDataById: action.payload,
      };
    },
    getProductDetailsByIdFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        productDetailsLoading: action.payload,
      };
    },
    getDataForPrintPdfStart(state, _action) {
      return {
        ...state,
        isPdfLoading: true,
      };
    },
    getDataForPrintPdfSuccess(state, action: PayloadAction<DataForPrintPdfByRequestIdResultInterface>) {
      return {
        ...state,
        isPdfLoading: false,
        dataForPrintPdfByRequestId: action.payload,
        idPdfDataGet: true,
      };
    },
    getDataForPrintPdfFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isPdfLoading: action.payload,
      };
    },
    resetPdfValues(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        idPdfDataGet: action.payload,
      };
    },
  },
});

export const {
  resetSendEquipmentRequestSlice, sendEquipmentStart, sendEquipmentSuccess,
  getSendRequestListByIdStart, getSendRequestListByIdSuccess, getSendRequestListByIdFailure,
  getProductDetailsByIdStart, getProductDetailsByIdSuccess, getProductDetailsByIdFailure,
  getDataForPrintPdfStart, getDataForPrintPdfSuccess, getDataForPrintPdfFailure, resetPdfValues,
} = sendEquipmentRequestSlice.actions;
export const sendEquipmentRequestReducer = sendEquipmentRequestSlice.reducer;

export type SendEquipmentRequestActions =
  | ReturnType<typeof resetSendEquipmentRequestSlice>
  | ReturnType<typeof getSendRequestListByIdStart>
  | ReturnType<typeof getSendRequestListByIdSuccess>
  | ReturnType<typeof getSendRequestListByIdFailure>
  | ReturnType<typeof sendEquipmentStart>
  | ReturnType<typeof sendEquipmentSuccess>
  | ReturnType<typeof getProductDetailsByIdStart>
  | ReturnType<typeof getProductDetailsByIdSuccess>
  | ReturnType<typeof getProductDetailsByIdFailure>
  | ReturnType<typeof getDataForPrintPdfStart>
  | ReturnType<typeof getDataForPrintPdfSuccess>
  | ReturnType<typeof getDataForPrintPdfFailure>
  | ReturnType<typeof resetPdfValues>;
