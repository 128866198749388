/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import { Button, Grid } from '@mui/material';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useReactToPrint } from 'react-to-print';
import './style.scss';
import { TypesBidPdfModal } from './types';
import { RootState } from '../../redux/rootState';
import ComponentToPrint from './components/ComponentToPrint';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 3,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};

const getPageMargins = () => `
  @media print {
    @page {
      margin: 0;
      size: A4;
    }
  }
`;

function PrintBidScreenComponent({ show, setOpenFrom, comment }: TypesBidPdfModal) {
  const { bidFieldsValue, bidTableData, bidPdfDetailsData } = useSelector((state: RootState) => state.bidPdfSliceReducer);
  const { userProfile } = useSelector((state: RootState) => state.userProfileReducer);
  const printRef = useRef(null);
  const pageMarginsCSS = getPageMargins();
  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    removeAfterPrint: true,
    copyStyles: true,
  });
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="div_job_container_info">
          <style>{pageMarginsCSS}</style>
          <ComponentToPrint
            comment={comment}
            userProfile={userProfile}
            bidFieldsValue={bidFieldsValue}
            bidTableData={bidTableData}
            ref={printRef}
            bidPdfDetailsData={bidPdfDetailsData}
          />
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div mt-2 me-3 ms-3">
              <div>
                <Button
                  onClick={setOpenFrom}
                  className="button_cancel"
                >
                  Cancel
                </Button>
              </div>
              <div className="button_margin_left">
                <Button
                  onClick={() => {
                    handlePrint();
                  }}
                  className="printButton"
                >
                  Print
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

export default PrintBidScreenComponent;
