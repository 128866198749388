/* eslint-disable max-len */
import React from 'react';

export function SidebarClosedIcon() {
  return (
    <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.14062 5.9816H19.9844C20.2213 5.9816 20.4461 5.93053 20.619 5.83868C20.7825 5.75176 20.95 5.59729 20.95 5.37469C20.95 5.15209 20.7825 4.99762 20.619 4.9107C20.4461 4.81885 20.2213 4.76777 19.9844 4.76777H3.14062C2.90372 4.76777 2.67886 4.81885 2.50604 4.9107C2.34249 4.99762 2.175 5.15209 2.175 5.37469C2.175 5.59729 2.34249 5.75176 2.50604 5.83868C2.67886 5.93053 2.90372 5.9816 3.14062 5.9816Z" fill="#092245" stroke="#092245" strokeWidth="0.4" />
      <path d="M3.44179 6.39074L1.91188 5.37617L3.43837 4.36386C3.73179 4.18471 3.77437 3.83222 3.61525 3.56702C3.43841 3.27229 3.06229 3.22136 2.80781 3.39101L0.55781 4.89101C0.387051 5.00485 0.3 5.18126 0.3 5.37617C0.3 5.57108 0.387051 5.74749 0.55781 5.86133L2.80781 7.36133C2.90423 7.42561 3.02033 7.45117 3.125 7.45117C3.29576 7.45117 3.49353 7.3683 3.61016 7.19336C3.7878 6.9269 3.69693 6.56084 3.44219 6.39101L3.44179 6.39074Z" fill="#092245" stroke="#092245" strokeWidth="0.4" />
      <path d="M6.875 10.45H20.375C20.6917 10.45 20.95 10.1917 20.95 9.875C20.95 9.55829 20.6917 9.3 20.375 9.3H6.875C6.55829 9.3 6.3 9.55829 6.3 9.875C6.3 10.1917 6.55829 10.45 6.875 10.45ZM6.875 1.45H20.375C20.6917 1.45 20.95 1.19171 20.95 0.875C20.95 0.558293 20.6917 0.3 20.375 0.3H6.875C6.55829 0.3 6.3 0.558293 6.3 0.875C6.3 1.19171 6.55829 1.45 6.875 1.45Z" fill="#002646" stroke="#092245" strokeWidth="0.4" />
    </svg>
  );
}
