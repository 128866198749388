export const TableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'kits', label: 'Kits' },
  { key: 'cust', label: 'Cust #' },
  { key: 'customer', label: 'Customer' },
  { key: 'pd', label: 'PO#' },
  { key: 'shipDate', label: 'Ship Date' },
  { key: 'billAmt', label: 'Bill Amt' },
  { key: 'billed', label: 'Billed' },
  { key: 'createdBy', label: 'Created By' },
];

export const ViewOilKitsColumns = [
  { key: 'kitId', label: 'SN' },
  { key: 'kitno', label: 'Kit #' },
];

export const OilKitsSelectorColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'customer', label: 'Customer' },
  { key: 'shipDate', label: 'Ship Date' },
];
