import { Button, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { IconList } from '../../utils/StaticIcons';
import './style.scss';
import RequiredFiled from '../../utils/RequiredField';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import ComponentTextInput from '../../Common/ComponentTextinput/ComponentTextInput';
import { BidFields, BidItemsInterface, BidsItems } from './types';
import { getBidPdfDetailsData, setBidPdfTableData, setBidPdfValues } from './redux/bidPdfSlice';
import { RootState } from '../../redux/rootState';
import { customOptions } from '../../Common/TextEditorOptions';
import PrintBidScreenComponent from './PrintBidScreen';
import DynamicInputFields from './components/DynamicInputFields';
import FieldInputComponent from './components/FieldInputComponent';

function ScreenBidPdf() {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const currentDate = new Date();
  const dateFormat = 'MMMM dd, yyyy';
  const formattedDate = format(currentDate, dateFormat);
  const {
    handleSubmit, control, setValue, formState: { errors },
  } = useForm<BidItemsInterface>({});
  const [show, setShow] = useState(false);
  const [bidsItems, setBidsItems] = useState<BidsItems[]>([]);
  const dispatch = useDispatch();
  const { bidPdfDetailsData } = useSelector((state: RootState) => state.bidPdfSliceReducer);
  const { userProfile } = useSelector((state: RootState) => state.userProfileReducer);
  const [comment, setComment] = useState(EditorState.createEmpty());
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const htmlTags = useMemo(() => {
    // eslint-disable-next-line max-len
    const fixedNotes = '<p>SCHEDULING AND FINAL PRICING ARE SUBJECT TO JOB SITE REQUIREMENTS WITH REGARD TO PUBLIC HEALTH.  CUSTOMER MUST PROVIDE ALL REQUIREMENTS TO TAI AS FAR IN ADVANCE OF TESTING AS POSSIBLE.</p><p>NOTE:  HEADS MUST BE DOWN AND TUBES CLEANED AND BRUSHED BEFORE ANALYST ARRIVES. UNIT MUST BE READY FOR TESTING WITH NO WAITING TIME.</p>';
    const notesValue = `${fixedNotes} ${bidPdfDetailsData.Notes}`;
    return notesValue;
  }, [bidPdfDetailsData.Notes]);

  useEffect(() => {
    if (bidPdfDetailsData) {
      const convertedComment = htmlToEditorState(htmlTags);
      setComment(convertedComment);
      setValue(BidFields.contactName, bidPdfDetailsData.ContactName);
      setValue(BidFields.customer, bidPdfDetailsData.CustomerName);
      setValue(BidFields.address, bidPdfDetailsData.CustomerAddress);
      setValue(BidFields.city, bidPdfDetailsData.CustomerZipCityState);
      setValue(BidFields.quoteNumber, bidPdfDetailsData.QuoteNumber);
      setValue(BidFields.customerNumber, bidPdfDetailsData.CustomerId);
      setValue(BidFields.paymentTerms, 'Net 30 Days');
      setValue(BidFields.effectiveDate, formattedDate);
      setValue(BidFields.jobLocation, bidPdfDetailsData.JobLocation);
    }
  }, [bidPdfDetailsData]);
  const handleInputChange = (index: number, field: keyof BidsItems, value: string) => {
    const newInputFields = [...bidsItems]; // Shallow copy
    const updatedItem = { ...newInputFields[index] }; // Shallow copy of the item to be modified
    updatedItem[field] = value;
    newInputFields[index] = updatedItem; // Update the copied array
    setBidsItems(newInputFields);
  };

  const removeKit = (index: number) => {
    const tempData = [...bidsItems];
    tempData.splice(index, 1);
    setBidsItems(tempData);
  };

  const onSubmit = (data: BidItemsInterface) => {
    const bidNotes = { ...data, note: htmlTags };
    dispatch(setBidPdfValues(bidNotes));
    dispatch(setBidPdfTableData(bidsItems));
    setShow(true);
  };

  useEffect(() => {
    dispatch(getBidPdfDetailsData(jobId));
  }, []);

  const renderHeader = () => (
    <div
      className="bidPdfHeaderLogoContainer"
    >
      <div>
        <img src={IconList.bidEditIcon} width={250} height={80} alt="" />
      </div>
      <div>
        <div className="firstText">1000 Cobb Place Blud. NW, Bldg. 300, Ste. 300</div>
        <div className="firstText">300 Kennesaw, GA 30144</div>
        <div className="firstText">(770)763-5930 | (800)554-4127</div>
      </div>
    </div>
  );

  const renderFieldsInputs = () => (
    <FieldInputComponent control={control} errors={errors} />
  );

  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const renderMiddleMenu = () => (
    <div style={{
      display: 'flex', flexDirection: 'column', padding: '30px 30px',
    }}
    >
      <div className="secondText">
        JOB Location :
        <ComponentTextInput
          control={control}
          name={BidFields.jobLocation}
          id={BidFields.jobLocation}
          errors={errors}
          size="small"
          className="customJobLocation"
        />
      </div>
      <div className="divider dividerMargin" />
      <Grid item xs={12} sm={12} md={12}>
        <div className="div_label_text">
          <label className="label_Style_Customer">
            Notes
            <RequiredFiled />
          </label>
          <div className="editorContainer">
            <Editor
              editorState={comment}
              onEditorStateChange={handleComment}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              localization={{ locale: 'en' }}
              toolbar={customOptions}
            />
          </div>
        </div>
      </Grid>
    </div>
  );

  const renderDynamicFields = () => (
    <DynamicInputFields
      handleInputChange={handleInputChange}
      bidsItems={bidsItems}
      removeKit={removeKit}
      setBidsItems={setBidsItems}
    />
  );

  return (
    <>
      <ComponentPageHeader subHeading="BID EDIT" />
      <div className="div_job_container_info backgroundWrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {renderHeader()}
            <div className="divider" />
            {renderFieldsInputs()}
            {renderMiddleMenu()}
            {renderDynamicFields()}
            <div className="divider" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '30px 30px',
                gap: '30px',
              }}
              className="gap"
            >
              <div className="agreementText">
                If this quote is accepted and work performed hereunder by TAI,
                TAI’s total liability in connection with the work is limited to
                the amount paid to TAI for the work. IN NO EVENT WILL TAI BE
                LIABLE FOR ANY INDIRECT OR CONSEQUENTIAL LOSS, DAMAGE,
                COST OR EXPENSE OF ANY KIND WHATEVER AND HOWEVER CAUSED,
                EVEN IF TAI HAS BEEN ADVISED OF THEIR POSSIBILITY.
                No action arising out of TAI’s work, regardless of
                the form of action, may be brought more than one year
                after the cause of action accrued. Exclusive jurisdiction
                and venue for any such action against TAI shall be
                in the Courts of Cobb County, Georgia.
              </div>
              <div>
                THIS BID INCLUDES A WRITTEN REPORT PLUS ALL
                KNOWN EXPENSES AND IS VALID FOR A PERIOD OF THIRTY DAYS.
              </div>
              <div className="agreementClassName">
                <div className="thankYouText">
                  THANK YOU !
                </div>
                <div className="thankYouText">
                  ......................................................................................
                </div>
                <div className="thankYouText">
                  {userProfile?.FirstName}
                  {userProfile?.LastName}
                  &nbsp;
                  (
                  {userProfile?.Email}
                  )
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              className="bottomWrapperMain"
            >
              <div className="bottomTextClass">Non-destructive Inspections</div>
              <div className="bottomTextClass">Eddy Current | Flux Leakage | Oil Analysis</div>
            </div>
            <div
              className="mt"
            >
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button onClick={() => navigate(-1)} className="button_cancel">Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="button" onClick={handleSubmit(onSubmit)} className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <PrintBidScreenComponent
        comment={getHtmlFromEditorHTMLtoString(comment)}
        show={show}
        setOpenFrom={() => setShow(false)}
      />
    </>

  );
}

export default ScreenBidPdf;
