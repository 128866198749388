import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { makeGetRequest, makePostRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import {
  getDueListDataFailure, getDueListDataStart, getDueListDataSuccess, InspectionLetterActions,
  updateStatusForPasDueStart,
  updateStatusForPasDueStartFailure,
  updateStatusForPasDueStartSuccess,
} from './sliceInspectionLetter';
import {
  InspectionLetterResponse, ListPayload, UpdateStatusInspectionLetterResponse, UpdateStatusPayload,
} from '../utils/types';

async function getDueListData(data: ListPayload): Promise<InspectionLetterResponse> {
  const {
    page, rowsPerPage, searchQuery, siteId, customerId, fromDate, toDate, type,
  } = data;

  let url = `${Config.inspectionLetter.GetDueListDataUrl}?searchItem=${searchQuery}&pageSize=${rowsPerPage}&pageNumber=${page}&Type=${type}`;

  if (customerId) {
    url += `&CustId=${customerId}`;
  }

  if (siteId) {
    url += `&SiteId=${siteId}`;
  }

  if (fromDate) {
    url += `&InspectionDate=${fromDate}`;
  }

  if (toDate) {
    url += `&ReTestDate=${toDate}`;
  }
  const result = await makeGetRequest<InspectionLetterResponse>(url);
  return result.data;
}

export const getDueListDataEpic = (action$: Observable<InspectionLetterActions>) => action$.pipe(
  ofType(getDueListDataStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: ListPayload) => from(getDueListData(data)).pipe(
    map((res: InspectionLetterResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDueListDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getDueListDataFailure();
    }),
    takeUntil(action$.pipe(filter(getDueListDataStart.match))),
    catchError((error) => of(getDueListDataFailure(error))),
  )),
);

async function updateStatus(data: { jobOrderId: string;
  vesselId: string; }): Promise<UpdateStatusInspectionLetterResponse> {
  const url = `${Config.inspectionLetter.UpdateStatusDataUrl}?JobOrder=${data.jobOrderId}&VesselId=${data.vesselId}`;
  const result = await makePostRequest<UpdateStatusInspectionLetterResponse>(url, {});
  return result.data;
}

export const updateStatusEpic = (action$ : Observable<InspectionLetterActions>) => action$.pipe(
  ofType(updateStatusForPasDueStart.type),
  map((x) => x.payload),
  mergeMap(({ payload, callback }:UpdateStatusPayload) => from(updateStatus(payload)).pipe(
    mergeMap((res: UpdateStatusInspectionLetterResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        if (callback) {
          callback();
        }
        return of(updateStatusForPasDueStartSuccess());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateStatusForPasDueStartFailure());
    }),
    takeUntil(action$.pipe(filter(updateStatusForPasDueStart.match))),
    catchError((error) => of(updateStatusForPasDueStartFailure(error))),
  )),
);
