import { SelectedCustomerDetails } from '../../../../addCustomer/utils/types';
import { CustomerSiteFormFields } from './constants';
import { BidsData, GetAssociatedBidsResponse, SelectedSite } from './types';

export function assignCustomerValues(setValue:SetValue, details:SelectedCustomerDetails) {
  [
    { name: CustomerSiteFormFields.CustomerName, value: details?.CompanyInformation?.CustomerName },
    { name: CustomerSiteFormFields.CustomerId, value: details?.CustomerId },
    // eslint-disable-next-line max-len
    { name: CustomerSiteFormFields.CustomerContact, value: `${details?.PrimaryContactInformation?.FirstName} ${details?.PrimaryContactInformation?.LastName}` },
    { name: CustomerSiteFormFields.CustomerAddress, value: details?.CompanyInformation?.Address },
    { name: CustomerSiteFormFields.CustomerOptionalAdress, value: details?.CompanyInformation?.OptionalAddress },
    { name: CustomerSiteFormFields.CustomerZip, value: details?.CompanyInformation?.ZipCode },
    { name: CustomerSiteFormFields.CustomerCountry, value: details?.CompanyInformation?.CountryCode },
    { name: CustomerSiteFormFields.CustomerState, value: details?.CompanyInformation?.StateName },
    { name: CustomerSiteFormFields.CustomerCity, value: details?.CompanyInformation?.CityName },
    { name: CustomerSiteFormFields.CustomerPhoneCode, value: details?.CompanyInformation?.PhoneCode },
    { name: CustomerSiteFormFields.CustomerPhone, value: details?.CompanyInformation?.Phone },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function assignSiteValues(setValue:SetValue, details:SelectedSite) {
  [
    { name: CustomerSiteFormFields.SiteId, value: details.SiteId },
    { name: CustomerSiteFormFields.SiteName, value: details.SiteName },
    { name: CustomerSiteFormFields.SiteContact, value: details.Contact },
    { name: CustomerSiteFormFields.SiteAddress, value: details.Address },
    { name: CustomerSiteFormFields.SiteOptionalAddress, value: details.OptionalAddress },
    { name: CustomerSiteFormFields.SiteZip, value: details.Pincode },
    { name: CustomerSiteFormFields.SiteState, value: details.State },
    { name: CustomerSiteFormFields.SiteCity, value: details.City },
    { name: CustomerSiteFormFields.SiteCountry, value: details.CountryCode },
    { name: CustomerSiteFormFields.SitePhoneCode, value: details.PhoneCode },
    { name: CustomerSiteFormFields.SitePhone, value: details.Phone },
    { name: CustomerSiteFormFields.SiteCell, value: details.Cell },
    { name: CustomerSiteFormFields.SiteType, value: details.SiteTypeId },
    { name: CustomerSiteFormFields.SiteSMC, value: details.SmcName },
    { name: CustomerSiteFormFields.SiteNote, value: details.Note },
  ].forEach(({ name, value }) => setValue(name, value));
}

export function formatBidListingData(data:GetAssociatedBidsResponse[]) {
  const res:BidsData[] = [];

  if (data.length === 0) return [];

  data.forEach((col:GetAssociatedBidsResponse, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      bidId: '',
      bidName: '',
      id: '',
      bidDate: '',
    };

    obj.bidId = col.BidId;
    obj.bidName = col.BidName;
    obj.id = col.BidId;
    obj.bidDate = col.BidCreationDate;

    res.push(obj);
  });

  return res;
}
