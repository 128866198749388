/* eslint-disable max-len */
import React from 'react';

export function LinkOff() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3877_120474)">
        <path d="M16.4522 8.425C16.1747 6.5725 14.4872 5.2525 12.6122 5.2525H10.4597C10.0697 5.2525 9.74722 5.575 9.74722 5.965C9.74722 6.355 10.0697 6.6775 10.4597 6.6775H12.6347C13.8347 6.6775 14.9147 7.5325 15.0497 8.725C15.1772 9.7975 14.5697 10.7425 13.6622 11.14L14.7122 12.19C15.9347 11.425 16.6922 10.0075 16.4522 8.425ZM3.08722 2.6725C2.79472 2.38 2.32222 2.38 2.02972 2.6725C1.73722 2.965 1.73722 3.4375 2.02972 3.73L3.82972 5.53C2.37472 6.13 1.37722 7.6075 1.51222 9.31C1.66972 11.29 3.43972 12.7525 5.41972 12.7525H7.53472C7.92472 12.7525 8.24722 12.43 8.24722 12.04C8.24722 11.65 7.92472 11.3275 7.53472 11.3275H5.36722C4.14472 11.3275 3.04222 10.435 2.92972 9.2125C2.81722 7.9225 3.76222 6.835 4.99222 6.7L6.56722 8.275C6.24472 8.3425 5.99722 8.62 5.99722 8.965V9.04C5.99722 9.43 6.31972 9.7525 6.70972 9.7525H8.04472L9.74722 11.455V12.7525H11.0447L13.5197 15.2275C13.8122 15.52 14.2847 15.52 14.5772 15.2275C14.8697 14.935 14.8697 14.4625 14.5772 14.17L3.08722 2.6725ZM11.9972 8.965C11.9972 8.575 11.6747 8.2525 11.2847 8.2525H10.7897L11.9072 9.37C11.9597 9.2725 11.9972 9.16 11.9972 9.04V8.965Z" fill="#299CDB" />
      </g>
      <defs>
        <clipPath id="clip0_3877_120474">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
