import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddTubeListData, TubeListPayloadListing, PayloadTypeDeleteTubeList, PayloadTypeGetTubeList, TubeListState, UpdateTubeListData,
} from '../utils/types';

const initialState: TubeListState = {
  TubeListData: {} as TubeListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const tubeListSlice = createSlice({
  name: 'TubeList',
  initialState,
  reducers: {
    getTubeListStart(state, _action: PayloadAction<PayloadTypeGetTubeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successTubeList(state, action: PayloadAction<TubeListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        TubeListData: action?.payload as TubeListPayloadListing,
      };
    },
    failureTubeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateTubeList(state, _action: PayloadAction<UpdateTubeListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateTubeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateTubeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addTubeListStart(state, _action: PayloadAction<AddTubeListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddTubeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddTubeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteTubeListStart(state, _action: PayloadAction<PayloadTypeDeleteTubeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteTubeList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteTubeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getTubeListStart, successTubeList, failureTubeList, updateTubeList,
  successUpdateTubeList, failureUpdateTubeList, resetState, deleteTubeListStart,
  successDeleteTubeList, failureDeleteTubeList,
  addTubeListStart, successAddTubeList, failureAddTubeList,
} = tubeListSlice.actions;
export const ListTubeReducer = tubeListSlice.reducer;

export type TubeTypeActions =
  | ReturnType<typeof getTubeListStart>
  | ReturnType<typeof successTubeList>
  | ReturnType<typeof failureTubeList>

  | ReturnType<typeof updateTubeList>
  | ReturnType<typeof successUpdateTubeList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateTubeList>

  | ReturnType<typeof deleteTubeListStart>
  | ReturnType<typeof successDeleteTubeList>
  | ReturnType<typeof failureDeleteTubeList>

  | ReturnType<typeof addTubeListStart>
  | ReturnType<typeof successAddTubeList>
  | ReturnType<typeof failureAddTubeList>;
