import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import { Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import DataTable from '../../../Common/DataTable';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { SMCSubscribedCustomerData } from '../Utils/TypeSmc';
import '../Style/StyleSmc.scss';
import { getSmcCustomerListStart } from '../Redux/sliceAddSmc';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { CustomerApiData } from '../../customer/utils/TypeCustomer';
import { DEFAULT_RECORD_COUNT, DEFAULT_ROW_PER_PAGE } from './constant';

interface TypeTableCell {
  subscribedCustomer: CustomerApiData[];
  setSubscribedCustomer: (data: CustomerApiData[]) => void;
}

export default function ComponentSubscribedCustomer({ subscribedCustomer, setSubscribedCustomer }: TypeTableCell) {
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(DEFAULT_RECORD_COUNT);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCustomerId, setSelectedCustomerId] = useState(''); // having concern here, how to declare it
  const dispatch = useDispatch();
  const { smcId } = useParams();
  const handleData = () => {
    const params = {
      page,
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      smcId: String(smcId),
      searchQuery,
    };
    if (params.smcId) {
      dispatch(getSmcCustomerListStart(params));
    }
  };

  useEffect(() => {
    if (smcId) {
      handleData();
    }
  }, []);

  const handleLinkOff = (CustomerId: string) => {
    setShowModal(true);
    setSelectedCustomerId(CustomerId);
  };

  const handleDeleteData = () => {
    const newArr = subscribedCustomer.filter((customer:CustomerApiData) => customer.Custid !== selectedCustomerId);
    setSubscribedCustomer(newArr);
    setShowModal(false);
  };

  const columns = useMemo(
    () => [
      { key: 'sn', label: translations.sn },
      { key: 'action', label: translations.action },
      { key: 'markUp', label: translations.markup },
      { key: 'cust', label: translations.CustHash },
      { key: 'company', label: translations.Company },
      { key: 'address', label: translations.address },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
      { key: 'phone', label: translations.phone },
    ],
    [],
  );

  const data: SMCSubscribedCustomerData[] = subscribedCustomer?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'linkOff',
      markUp: item?.Markup,
      cust: item?.Custid,
      company: item?.Customername,
      address: item?.Address,
      city: item?.CityName,
      state: item?.StateName,
      phone: item?.Phone,
      id: item?.Custid,
      customerId: item?.Custid,
    };
    return transformedDataItem;
  });

  const seeMoreClick = () => {
    setRowCount(rowCount + DEFAULT_RECORD_COUNT);
  };

  const seeLessClick = () => {
    setRowCount(rowCount - DEFAULT_RECORD_COUNT);
  };

  return (
    <Card>
      <div className="separate_section">
        <div className="customerInfo_container">
          <div className="customerInfo">
            {translations.subscribedCustomers}
          </div>
        </div>

        <DataTable<SMCSubscribedCustomerData>
          data={data?.slice(0, rowCount)}
          columns={columns}
          totalRecords={50}
          page={page}
          setPage={setPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleLinkOff={handleLinkOff}
        />
        <div className="alignArrow">
          {rowCount < data.length && (
          <div onClick={seeMoreClick} className="customerSeeMore">
            <ArrowDownward />
          </div>
          )}
          {rowCount > DEFAULT_RECORD_COUNT && (
          <div onClick={seeLessClick} className="customerSeeMore">
            <ArrowUpward />
          </div>
          )}
        </div>
        <ComponentDeleteModal
          show={showModal}
          setOpenFrom={() => setShowModal(false)}
          handleDelete={handleDeleteData}
        />
      </div>
    </Card>
  );
}
