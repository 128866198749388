import {
  Observable, catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { ofType } from 'redux-observable';
import { AxiosError } from 'axios';
import Config from '../../../Common/Config';
import { makeGetRequest, makePatchRequest } from '../../../Common/NetworkOps';
import { GetListingPayload, ListingResponse } from '../../../utils/type';
import {
  GetInsSummaryResponse, InspectionSummaryDataById, InspectionSummaryPayload, PayloadInspectionSummary, UpdateSummaryApiResponse,
} from '../utils/types';
import {
  InspectionSummaryActions, getInspectionSummaryData, getInspectionSummaryDataById, getInspectionSummaryDataFailure,
  getInspectionSummaryDataFailureById, getInspectionSummaryDataSuccess, getInspectionSummaryDataSuccessById,
  updateInspectionSummaryDataById,
  updateInspectionSummaryDataFailureById,
  updateInspectionSummaryDataSuccessById,
} from './sliceInspectionSummary';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

async function getInspectionSummary(data: GetListingPayload): Promise<ListingResponse<GetInsSummaryResponse>> {
  const url = `${Config.inspectionSummary.getInspectionSummary}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetInsSummaryResponse>>(url);
  return result.data;
}

export const getInspectionSummaryEpic = (action$ : Observable<InspectionSummaryActions>) => action$.pipe(
  ofType(getInspectionSummaryData.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetListingPayload) => from(getInspectionSummary(data)).pipe(
    map((res: ListingResponse<GetInsSummaryResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getInspectionSummaryDataSuccess(res.BMT.Result);
      }
      return getInspectionSummaryDataFailure();
    }),
    takeUntil(action$.pipe(filter(getInspectionSummaryData.match))),
    catchError(() => of(getInspectionSummaryDataFailure())),
  )),
);

async function getInspectionSummaryById(data: PayloadInspectionSummary): Promise<InspectionSummaryDataById> {
  const url = `${Config.inspectionSummary.getInspectionSummaryById}/${data?.summaryId}?vesselId=${data?.vesselId}&defectCode=${data?.defectCode}`;
  const result = await makeGetRequest<InspectionSummaryDataById>(url);
  return result.data;
}

export const getInspectionSummaryByIdEpic = (action$ : Observable<InspectionSummaryActions>) => action$.pipe(
  ofType(getInspectionSummaryDataById.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadInspectionSummary) => from(getInspectionSummaryById(data)).pipe(
    map((res: InspectionSummaryDataById) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getInspectionSummaryDataSuccessById(res?.BMT?.Result);
      }
      showErrorToaster(res.BMT?.ResponseMessage);
      return getInspectionSummaryDataFailureById(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getInspectionSummaryDataById.match))),
    catchError(() => of(getInspectionSummaryDataFailureById(''))),
  )),
);

// Update Summary
async function updateInspection(data: InspectionSummaryPayload): Promise<UpdateSummaryApiResponse> {
  const url = `${Config.inspectionSummary.UpdateInspectionSummary}`;
  const result = await makePatchRequest<UpdateSummaryApiResponse>(url, data);
  return result.data;
}

export const updateInspectionSummaryEpic = (action$: Observable<InspectionSummaryActions>) => action$.pipe(
  ofType(updateInspectionSummaryDataById.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: InspectionSummaryPayload) => from(updateInspection(data)).pipe(
    map((res: UpdateSummaryApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updateInspectionSummaryDataSuccessById();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateInspectionSummaryDataFailureById();
    }),
    takeUntil(action$.pipe(filter(updateInspectionSummaryDataById.match))),
    catchError((error: AxiosError<UpdateSummaryApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      showErrorToaster(errorMessage);
      return of(updateInspectionSummaryDataFailureById());
    }),
  )),
);
