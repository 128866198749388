import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoardPositionListDataInterface, FilteredAnalystDataInterface, InitialStateBoardPosition } from './type';

const initialState: InitialStateBoardPosition = {
  isLoading: false,
  BoardPositionListData: [] as BoardPositionListDataInterface[],
  zoneCreated: false,
  FilteredAnalystData: {} as FilteredAnalystDataInterface,
  isBoardAnalystLoading: false,
};
const boardPositionSlice = createSlice({
  name: 'boardPositionSlice',
  initialState,
  reducers: {
    // GET ZONE LIST DATA
    onGetBoardPositionData(state) {
      return {
        ...state,
        isBoardAnalystLoading: true,
      };
    },
    onGotBoardPositionData(state, action: PayloadAction<BoardPositionListDataInterface[]>) {
      return {
        ...state,
        isBoardAnalystLoading: false,
        BoardPositionListData: action.payload,
      };
    },
    onFailBoardPositionData(state) {
      return {
        ...state,
        isBoardAnalystLoading: false,
        BoardPositionListData: [] as BoardPositionListDataInterface[],
      };
    },
    // CREATE ZONE
    onCreateZone(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onCreateZoneSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onCreateZoneFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // GET FILTERED ANALYST DATA
    getFilteredAnalystData(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getFilteredAnalystDataSuccess(state, action: PayloadAction<FilteredAnalystDataInterface>) {
      return {
        ...state,
        isLoading: false,
        FilteredAnalystData: action.payload,
      };
    },
    getFilteredAnalystDataFailure(state) {
      return {
        ...state,
        isLoading: false,
        FilteredAnalystData: {} as FilteredAnalystDataInterface,
      };
    },
    // Remove Analyst From Zone
    onRemoveAnalyst(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onRemoveAnalystSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onRemoveAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onRowOrderingAnalyst(state, _action) {
      return {
        ...state,
        isAnalystLoading: false,
      };
    },
  },
});

export const {
  onGetBoardPositionData, onGotBoardPositionData, onFailBoardPositionData, onCreateZone,
  onCreateZoneSuccess, onCreateZoneFailure, getFilteredAnalystData, getFilteredAnalystDataSuccess,
  getFilteredAnalystDataFailure, onRemoveAnalyst, onRemoveAnalystSuccess, onRemoveAnalystFailure,
  onRowOrderingAnalyst,
} = boardPositionSlice.actions;
export const boardPositionReducer = boardPositionSlice.reducer;

export type BoardPositionActions =
  | ReturnType<typeof onGetBoardPositionData>
  | ReturnType<typeof onGotBoardPositionData>
  | ReturnType<typeof onFailBoardPositionData>
  | ReturnType<typeof onCreateZone>
  | ReturnType<typeof onCreateZoneSuccess>
  | ReturnType<typeof onCreateZoneFailure>
  | ReturnType<typeof getFilteredAnalystData>
  | ReturnType<typeof getFilteredAnalystDataSuccess>
  | ReturnType<typeof getFilteredAnalystDataFailure>
  | ReturnType<typeof onRemoveAnalyst>
  | ReturnType<typeof onRemoveAnalystSuccess>
  | ReturnType<typeof onRemoveAnalystFailure>
  | ReturnType<typeof onRowOrderingAnalyst>;
