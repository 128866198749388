import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddAnalystBody, AddAnalystInitialState, AnalystLeaveDataResult, AnalystLeaveMasterDataInterface, PinCodeDetails,
} from '../utils/types';
import { PinCodeDetailsQuery } from '../../../../utils/type';

const initialState: AddAnalystInitialState = {
  isLoading: false,
  analystDataById: {} as AddAnalystBody,
  analystPinCodeDetails: {} as PinCodeDetails,
  isPinError: false,
  analystBaseOutPinCodeDetails: {} as PinCodeDetails,
  isBaseOutPinError: false,
  selectedTab: 0,
  analystSecondaryBasePinCodeDetails: {} as PinCodeDetails,
  isSecondaryBasePinError: false,
  isAnalystLeaveDataLoading: false,
  analystLeaveData: {} as AnalystLeaveDataResult,
  deletedLeave: false,
  analystLeaveMasterData: {} as AnalystLeaveMasterDataInterface,
};

export const addAnalystSlice = createSlice({
  name: 'addAnalyst',
  initialState,
  reducers: {
    resetAddAnalystState() {
      return initialState;
    },
    createAnalyst(state, _action) {
      return {
        ...state,
        isLoading: false,
      };
    },
    createAnalystSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    createAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateAnalyst(state, _action) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateAnalystSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getAnalystDataById(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAnalystDataByIdSuccess(state, action: PayloadAction<AddAnalystBody>) {
      return {
        ...state,
        isLoading: false,
        analystDataById: action.payload,
      };
    },
    clearCache(state, action: PayloadAction<AddAnalystBody>) {
      return {
        ...state,
        isLoading: false,
        analystDataById: action.payload,
      };
    },
    getAnalystDataByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    analystPinCodeDetailsStart(state, _action: PayloadAction<PinCodeDetailsQuery>) {
      return {
        ...state,
        isLoading: true,
        isPinError: false,
      };
    },
    analystPinCodeDetailsSuccess(state, action: PayloadAction<PinCodeDetails>) {
      return {
        ...state,
        isLoading: false,
        analystPinCodeDetails: action.payload,
        isPinError: false,
      };
    },
    analystPinCodeDetailsFailure(state) {
      return {
        ...state,
        isLoading: false,
        isPinError: true,
      };
    },
    removeAnalystPinCodeDetails(state) {
      return {
        ...state,
        isLoading: false,
        analystPinCodeDetails: null,
        isPinError: false,
      };
    },
    analystBaseOutPinCodeDetailsStart(state, _action: PayloadAction<PinCodeDetailsQuery>) {
      return {
        ...state,
        isLoading: true,
        isBaseOutPinError: false,
      };
    },
    analystBaseOutPinCodeDetailsSuccess(state, action: PayloadAction<PinCodeDetails>) {
      return {
        ...state,
        isLoading: false,
        analystBaseOutPinCodeDetails: action.payload,
        isBaseOutPinError: false,
      };
    },
    analystBaseOutPinCodeDetailsFailure(state) {
      return {
        ...state,
        isLoading: false,
        isBaseOutPinError: true,
      };
    },
    removeAnalystBaseOutPinCodeDetails(state) {
      return {
        ...state,
        isLoading: false,
        analystBaseOutPinCodeDetails: null,
        isBaseOutPinError: false,
      };
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    analystSecondaryBasePinCodeDetailsStart(state, _action: PayloadAction<PinCodeDetailsQuery>) {
      return {
        ...state,
        isLoading: true,
        isSecondaryBasePinError: false,
      };
    },
    analystSecondaryBasePinCodeDetailsSuccess(state, action: PayloadAction<PinCodeDetails>) {
      return {
        ...state,
        isLoading: false,
        analystSecondaryBasePinCodeDetails: action.payload,
        isSecondaryBasePinError: false,
      };
    },
    analystSecondaryBasePinCodeDetailsFailure(state) {
      return {
        ...state,
        isLoading: false,
        isSecondaryBasePinError: true,
      };
    },
    removeAnalystSecondaryBasePinCodeDetails(state) {
      return {
        ...state,
        isLoading: false,
        analystSecondaryBasePinCodeDetails: null,
        isSecondaryBasePinError: false,
      };
    },
    getAnalystLeaveData(state, _action) {
      return {
        ...state,
        isAnalystLeaveDataLoading: true,
      };
    },
    getAnalystLeaveDataSuccess(state, action: PayloadAction<AnalystLeaveDataResult>) {
      return {
        ...state,
        analystLeaveData: action.payload,
        isAnalystLeaveDataLoading: false,
      };
    },
    getAnalystLeaveDataFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isAnalystLeaveDataLoading: action.payload,
      };
    },
    deleteAnalystLeave(state, _action) {
      return {
        ...state,
        isAnalystLeaveDataLoading: false,
      };
    },
    deleteAnalystLeaveSuccess(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        deletedLeave: action.payload,
      };
    },
    addAnalystLeave(state, _action) {
      return {
        ...state,
        deletedLeave: true,
      };
    },
    getAnalystLeaveMasterData(state) {
      return {
        ...state,
        deletedLeave: true,
      };
    },
    getAnalystLeaveMasterDataSuccess(state, action: PayloadAction<AnalystLeaveMasterDataInterface>) {
      return {
        ...state,
        analystLeaveMasterData: action.payload,
        isAnalystLeaveDataLoading: false,
      };
    },
  },
});

export const {
  createAnalyst, createAnalystSuccess, createAnalystFailure, updateAnalyst,
  updateAnalystSuccess, updateAnalystFailure, getAnalystDataById, getAnalystDataByIdSuccess, getAnalystDataByIdFailure, clearCache,
  analystPinCodeDetailsStart, analystPinCodeDetailsSuccess, analystPinCodeDetailsFailure, removeAnalystPinCodeDetails,
  analystBaseOutPinCodeDetailsStart, analystBaseOutPinCodeDetailsSuccess, analystBaseOutPinCodeDetailsFailure, removeAnalystBaseOutPinCodeDetails,
  setSelectedTab,
  // eslint-disable-next-line max-len
  analystSecondaryBasePinCodeDetailsStart, analystSecondaryBasePinCodeDetailsSuccess, analystSecondaryBasePinCodeDetailsFailure, removeAnalystSecondaryBasePinCodeDetails,
  resetAddAnalystState, getAnalystLeaveData, getAnalystLeaveDataSuccess, getAnalystLeaveDataFailure,
  deleteAnalystLeave, deleteAnalystLeaveSuccess, addAnalystLeave, getAnalystLeaveMasterData, getAnalystLeaveMasterDataSuccess,
} = addAnalystSlice.actions;
export const AddAnalystSliceReducer = addAnalystSlice.reducer;

export type AddAnalystSliceActions =
  | ReturnType<typeof createAnalyst>
  | ReturnType<typeof createAnalystSuccess>
  | ReturnType<typeof createAnalystFailure>
  | ReturnType<typeof updateAnalyst>
  | ReturnType<typeof updateAnalystSuccess>
  | ReturnType<typeof updateAnalystFailure>
  | ReturnType<typeof getAnalystDataById>
  | ReturnType<typeof getAnalystDataByIdSuccess>
  | ReturnType<typeof getAnalystDataByIdFailure>
  | ReturnType<typeof clearCache>

  | ReturnType<typeof analystPinCodeDetailsStart>
  | ReturnType<typeof analystPinCodeDetailsSuccess>
  | ReturnType<typeof analystPinCodeDetailsFailure>
  | ReturnType<typeof removeAnalystPinCodeDetails>

  | ReturnType<typeof analystBaseOutPinCodeDetailsStart>
  | ReturnType<typeof analystBaseOutPinCodeDetailsSuccess>
  | ReturnType<typeof analystBaseOutPinCodeDetailsFailure>
  | ReturnType<typeof removeAnalystBaseOutPinCodeDetails>

  | ReturnType<typeof setSelectedTab>

  | ReturnType<typeof analystSecondaryBasePinCodeDetailsStart>
  | ReturnType<typeof analystSecondaryBasePinCodeDetailsSuccess>
  | ReturnType<typeof analystSecondaryBasePinCodeDetailsFailure>
  | ReturnType<typeof removeAnalystSecondaryBasePinCodeDetails>

  | ReturnType<typeof resetAddAnalystState>
  | ReturnType<typeof getAnalystLeaveData>
  | ReturnType<typeof getAnalystLeaveDataSuccess>
  | ReturnType<typeof getAnalystLeaveDataFailure>
  | ReturnType<typeof deleteAnalystLeave>
  | ReturnType<typeof deleteAnalystLeaveSuccess>
  | ReturnType<typeof addAnalystLeave>
  | ReturnType<typeof getAnalystLeaveMasterData>
  | ReturnType<typeof getAnalystLeaveMasterDataSuccess>;
