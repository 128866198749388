import { AddSmcFormInput } from './TypeSmc';

export const DETAILS_TAB = 0;
export const USERS_TAB = 1;
export const SUBSCRIBED_CUSTOMER = 2;
export const SUBSCRIBED_SITE = 3;

export const LABEL_DETAILS_TAB = 'Details';
export const LABEL_USERS_TAB = 'Users';
export const LABEL_SUBSCRIBED_CUSTOMER = 'Subscribed Customer';
export const LABEL_SUBSCRIBED_SITE = 'Subscribed Site';

export enum AddSmcFormValue {
  SMC_NAME = 'smcName',
  TAI_ID_NUMBER = 'taiIdNo',
  PHONE_CODE = 'phoneCode',
  PHONE_NUMBER = 'phoneNumber',
  URL = 'url',
  ADDRESS = 'address',
  ADDRESS_OPTIONAL = 'addressOptional',
  SMC_SITES = 'smcSites',
  SMC_CUSTOMERS = ' smcCustomers',
  TEMP_CUSTOMERS = 'tempCustomers',
  TEMP_SMC_CUSTOMER = 'tempSmcCustomer',
  TEMP_SMC_SITE = 'tempSmcSites',
  ZIP = 'zip',
  STATE = 'state',
  CITY = 'city',
  COUNTRY = 'country',
  OTHER_CITY = 'otherCity',
}

export const defaultValuesSmcForm: AddSmcFormInput = {
  smcName: '',
  taiIdNo: '',
  phoneNumber: '',
  phoneCode: '+1',
  url: '',
  address: '',
  addressOptional: '',
  country: 'US',
  zip: '',
  state: '',
  city: '',
  otherCity: '',
  smcSites: [],
  smcCustomers: [],
  tempSmcCustomer: '',
  tempSmcSites: '',
  tempSmcSiteCustomer: '',
  smcSiteCustomers: '',
  tempCustomers: '',
};
