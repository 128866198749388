import { TableCell } from '@mui/material';
import React from 'react';
import { ArrowUpDownIcon } from '../../assets/TableIcons';
import { DataHeaderCellType } from '../../utils/interface';

export default function ComponentTableHeader({ colWidth, header }: DataHeaderCellType) {
  return (
    <TableCell className={colWidth}>
      <div className="tableCell-div">
        <span>{header}</span>
        <ArrowUpDownIcon />
      </div>
    </TableCell>
  );
}
