import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaginationResponse } from '../../../../utils/type';
import { GetListingPayload } from '../addJob/utils/types';
import { DeleteApiPayload, JobData, JobInitialState } from '../utils/types';

const initialState: JobInitialState = {
  jobs: [] as JobData[],
  totalEntries: 0,
  isLoading: false,
};

export const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // Get Jobs
    getJobsStart(state, _action:PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getJobsSuccess(state, action:PayloadAction<PaginationResponse<JobData>>) {
      return {
        ...state,
        isLoading: false,
        totalEntries: action.payload.TotalRecords,
        jobs: action.payload.Data,
      };
    },
    getJobsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Delete Job
    deleteJobStart(state, _action:PayloadAction<DeleteApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteJobSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteJobFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getJobsFailure, getJobsStart, getJobsSuccess, deleteJobFailure, deleteJobStart, deleteJobSuccess,
} = jobSlice.actions;
export const jobsReducer = jobSlice.reducer;

export type JobsActions =
    | ReturnType<typeof getJobsFailure>
    | ReturnType<typeof getJobsStart>
    | ReturnType<typeof getJobsSuccess>
    | ReturnType<typeof deleteJobFailure>
    | ReturnType<typeof deleteJobStart>
    | ReturnType<typeof deleteJobSuccess>;
