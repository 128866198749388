export const MAIN_TAB = 0;
export const CUSTOMER_TAB = 1;
export const DESCRIPTION_TAB = 2;
export const ENTRY_TAB = 3;
export const WORKSHEET_TAB = 4;

export const LABEL_MAIN_TAB = 'Main';
export const LABEL_CUSTOMER_TAB = 'Customer';
export const LABEL_DESCRIPTION_TAB = 'Description';
export const LABEL_ENTRY_TAB = 'Entry';
export const LABEL_WORKSHEET_TAB = 'Worksheet';
