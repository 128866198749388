import React from 'react';
import BarChartSection from './BarChartSection';

function BartChartNewSection() {
  return (
    <div className="p30">
      <BarChartSection />
    </div>
  );
}

export default BartChartNewSection;
