import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddStyleListApiResponse, AddStyleListData, DeleteStyleListResponse, GetAllStyleListApiResponse,
  PayloadTypeDeleteStyleList, PayloadTypeGetStyleList, UpdateStyleListApiResponse, UpdateStyleListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addStyleListStart, deleteStyleListStart, failureAddStyleList, failureDeleteStyleList, failureStyleList,
  failureUpdateStyleList, getStyleListStart,
  StyleTypeActions, successAddStyleList, successDeleteStyleList, successStyleList, successUpdateStyleList, updateStyleList,
} from './sliceStyleList';

async function GetStyleList(data: PayloadTypeGetStyleList): Promise<GetAllStyleListApiResponse> {
  const url = `${Config.Lists.getStyleList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllStyleListApiResponse>(url);
  return result?.data;
}

export const epicGetStyleList = (action$: Observable<StyleTypeActions>) => action$.pipe(
  filter(getStyleListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetStyleList) => from(GetStyleList(data)).pipe(
    map((res: GetAllStyleListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successStyleList(res?.BMT?.Result);
      }
      return failureStyleList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getStyleListStart.match))),
    catchError((error: AxiosError<GetAllStyleListApiResponse>) => of(failureStyleList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateStyleList(data: UpdateStyleListData): Promise<UpdateStyleListApiResponse> {
  const url = `${Config.Lists.updateStyleList}`;
  const result = await makePostRequest<UpdateStyleListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateStyleList = (action$: Observable<StyleTypeActions>) => action$.pipe(
  filter(updateStyleList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateStyleList(data)).pipe(
    mergeMap((res: UpdateStyleListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateStyleList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateStyleList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateStyleList.match))),
    catchError((error: AxiosError<UpdateStyleListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateStyleList(errorMessage));
    }),
  )),
);

async function AddStyleList(data: AddStyleListData): Promise<AddStyleListApiResponse> {
  const url = `${Config.Lists.addStyleList}`;
  const result = await makePostRequest<AddStyleListApiResponse>(url, data);
  return result?.data;
}

export const epicAddStyleList = (action$: Observable<StyleTypeActions>) => action$.pipe(
  filter(addStyleListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddStyleList(data)).pipe(
    mergeMap((res: AddStyleListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddStyleList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddStyleList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addStyleListStart.match))),
    catchError((error: AxiosError<AddStyleListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddStyleList(errorMessage));
    }),
  )),
);

async function DeleteStyleList(data: PayloadTypeDeleteStyleList): Promise<DeleteStyleListResponse> {
  const url = `${Config.Lists.deleteStyleList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteStyleListResponse>(url);
  return result?.data;
}

export const epicDeleteStyleList = (action$: Observable<StyleTypeActions>) => action$.pipe(
  filter(deleteStyleListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteStyleList) => from(DeleteStyleList(data)).pipe(
    mergeMap((res: DeleteStyleListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteStyleList(), getStyleListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteStyleList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteStyleListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteStyleListResponse>) => of(failureDeleteStyleList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
