import React from 'react';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import ComponentCard from './Components/ComponentCard';
import style from './Utils/RBACStyle.module.scss';

function ScreenRBAC() {
  return (
    <>
      <ComponentPageHeader subHeading="Role Base Access Control" />
      <Grid container spacing={2} className={style.MainGrid}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ComponentCard name="Administrator" />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ComponentCard name="Front Office" />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ComponentCard name="Inventory Manager" />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <ComponentCard name="Analyst" />
        </Grid>
      </Grid>
    </>
  );
}

export default ScreenRBAC;
