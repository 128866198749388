import { Card, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../../Common/RequiredField';
import { FirstPropsType, GetDropDownList, VesselInformationMainEnum } from '../../utils/mainTypes.d';
import { RootState } from '../../../../redux/rootState';
import { getMainFieldsDropdown } from '../../utils/mainConstant';
import localStorageConstants from '../../../../utils/LocalStorageConstants';

function FirstComponent({
  control, errors, jobOrderId, watch, setValue,
}:FirstPropsType) {
  const {
    tubeTypeData, testEndData, materialData, supportData, vesselData, vesselMainData,
  } = useSelector((state: RootState) => state.VesselMainReducer);

  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const [isNWTTubeTypeEnabled, setIsNWTTubeTypeEnabled] = useState<GetDropDownList>(
    {
      Text: '',
      Value: '',
      Description: false,
    },
  );

  const tubeType = watch(VesselInformationMainEnum.tubeType);

  useEffect(() => {
    setValue(VesselInformationMainEnum.NWT, vesselMainData.Nwt);
    setValue(VesselInformationMainEnum.nwtBellLand, vesselMainData.NwtBellLand);
    setValue(VesselInformationMainEnum.nwtUnderFins, vesselMainData.NwtUnderFills);
  }, [tubeType]);

  useEffect(() => {
    if (tubeType && tubeTypeData.length > 0) {
      const data = tubeTypeData?.filter((item) => String(item.Value) === String(tubeType));
      if (data && data.length > 0) {
        const newData = {
          Text: data[0].Text,
          Value: data[0].Value,
          Description: data[0]?.Description === 'True',
        };
        setIsNWTTubeTypeEnabled(newData);
      } else {
        setIsNWTTubeTypeEnabled({
          Text: '',
          Value: '',
          Description: false,
        });
      }
    }
  }, [tubeType, tubeTypeData]);

  const MainDropdownFields = useMemo(
    () => getMainFieldsDropdown({
      tubeTypeData, testEndData, materialData, supportData, vesselData, errors, vesselMainData, isNWTTubeTypeEnabled, roleId,
    }),
    [tubeTypeData, testEndData, materialData, supportData, vesselData, vesselMainData.IsMultiOffset, isNWTTubeTypeEnabled, roleId],
  );

  return (
    <Card>
      <div className="customerInfo_container" id="edit_vessel_defect_search_row">
        <div className="job_order_right_sec">
          <span>Job Order #</span>
          <span className="job_order_id_txt">{jobOrderId}</span>
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          {MainDropdownFields.map(({
            type, label, name, entries, regEx, required, disabled,
          }) => (
            <Grid item md={3} sm={6} xs={12} key={label}>
              <div className="div_label_text">
                <label className={disabled ? 'label_Disable' : 'label_Style_Customer'}>
                  {label}
                  {required && <RequiredFiled />}
                </label>
                {type === 'select' ? (
                  <ComponentSelectInput
                    name={name}
                    control={control}
                    size="small"
                    errors={errors}
                    disabled={Boolean(disabled)}
                    entries={Array.isArray(entries) ? entries : []}
                  />
                ) : (
                  <ComponentTextInput
                    control={control}
                    regEx={regEx}
                    name={name}
                    id={name}
                    size="small"
                    errors={errors}
                    isDisable={Boolean(disabled)}
                  />
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Card>
  );
}

export default FirstComponent;
