import { Control, FieldErrors } from 'react-hook-form';
import { VesselInformationMainEnum } from './mainTypes.d';

export interface VesselInformationListingInterface {
  sn: number,
  action: string,
  jobOrder: string,
  vessel: string;
  material: string;
  testEnd: string;
  tubeType: string;
  analyst: string;
  inspectionDate: string;
  id: string;
}

export interface DefectListingInterface {
  sn: number,
  action: string,
  list: string,
  row: string;
  tube: string;
  code: string;
  location: string;
  date: string;
  time: string;
  defect: string;
  id: string;
}

export interface ASMESectionVFields {
  maximumScanSpeedDuringRecording: string,
  NSerialNumberOfEddyCurrentInstrument: string,
  probeCableLength: string,
  probeCableType: string,
  extensionCableLength: string,
  extensionCableType: string,
  probeMfg: string,
  probeSerialNumber: string,
  probeScanDirection: string,
}

export interface BayLengthListingInterface {
  position: number,
  length: string;
  id: string
}

export interface PhaseListingInterface {
  sn: number,
  action: string,
  chart: string,
  frequency: string;
  material: string;
  id: string;
}
export interface StripListingInterface {
  sn: number,
  action: string,
  zone: string,
  row: string;
  tube: string;
  loc: string;
  section: string;
  title: string;
  id: string;
}

export interface ImportFromLibraryListingInterface {
  action: string,
  mfg: string,
  model: string;
  vessel: string;
  tubeCount: string;
  id: string;
}

export interface VesselInformationFields {
  [VesselInformationMainEnum.vessel]: string,
  [VesselInformationMainEnum.testEnd]: string,
  [VesselInformationMainEnum.tubeType]: string,
  [VesselInformationMainEnum.material]: string,
  [VesselInformationMainEnum.support]: string,
  [VesselInformationMainEnum.NWT]: string,
  [VesselInformationMainEnum.nwtBellLand]: string,
  [VesselInformationMainEnum.nwtUnderFins]: string,
  [VesselInformationMainEnum.probeDiameter]: string,
  [VesselInformationMainEnum.OD]: string,
  [VesselInformationMainEnum.OD1]: string,
  [VesselInformationMainEnum.OD2]: string,
  [VesselInformationMainEnum.OD3]: string,

  [VesselInformationMainEnum.tubeCount]: string,
  [VesselInformationMainEnum.tubeLength]: string,

  [VesselInformationMainEnum.analyst]: string,
  [VesselInformationMainEnum.inspectionDate]: Date | null,
  [VesselInformationMainEnum.retestDate]: Date | null,
}

export enum DefectInformationEnum {
  section = 'section',
  area = 'area',
  code = 'code',
  location = 'location',
  date = 'date',
  time = 'time',
  row = 'row',
  tube = 'tube',
}

export enum ReplaceEnum {
  parameter = 'parameter',
  section = 'section',
  search = 'search',
  searchArea = 'searchArea',
  replaceWith = 'replaceWith',
  replaceWithArea = 'replaceWithArea',
}

export type FirstCompProps = {
  control: Control<ASMESectionVFields>;
  errors: FieldErrors<ASMESectionVFields>;
};

export type SecondCompProps = {
  control: Control<ASMESectionVFields>;
  errors: FieldErrors<ASMESectionVFields>;
};

export type ExtensionCableProps = {
  control: Control<ASMESectionVFields>;
  errors: FieldErrors<ASMESectionVFields>;
};

export type FourthComponentProps = {
  control: Control<ASMESectionVFields>;
  errors: FieldErrors<ASMESectionVFields>;
};

export enum BayLengthEnum {
  support = 'support',
  tubeCount = 'tubeCount',
  tubeLength = 'tubeLength',
}

export interface BaylengthResponse {
  BayLengthId: string;
  JobOrder: string;
  TubeLength: string;
  Support: string;
  Configuration: number[];
  VesselId?: string;
}

export interface BaylengthPayload {
  jobId: string;
  vesselId: string;
}

export interface BayLengthInterface {
  support: string,
  tubeLength: string,
  [key:string]: string;
}

export enum ASMESectionVEnum {
  maximumScanSpeedDuringRecording = 'maximumScanSpeedDuringRecording',
  NSerialNumberOfEddyCurrentInstrument = 'NSerialNumberOfEddyCurrentInstrument',
  probeCableLength = 'probeCableLength',
  probeCableType = 'probeCableType',
  extensionCableLength = 'extensionCableLength',
  extensionCableType = 'extensionCableType',
  probeMfg = 'probeMfg',
  probeSerialNumber = 'probeSerialNumber',
  probeScanDirection = 'probeScanDirection',
}

export enum StripChartEnum {
  zone = 'zone',
  row = 'row',
  tube = 'tube',
  section = 'section',
  title = 'title',
}

export enum AddStripChartEnum {
  section = 'section',
}
export interface StripChartFields {
  row: string,
  tube: string,
  zone: string,
  section: string,
  title: string,
}

export interface AddStripChartFields {
  section: string,
}

export interface AsmeByIdResponse {
  Id: number;
  AsmeId: string;
  VesselId: string;
  JobOrder: string;
  MaxScanSpeed: number;
  SerialNum: string;
  LengthProbe: number;
  TypeProbe: string;
  LengthExtention: number;
  TypeExtention: string;
  ProbeManufacture: string;
  ProbeSerialNumber: string;
  ProbeScanDirection: string;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedBy: null;
  CreatedOn: string;
  ModifiedBy: null;
  ModifiedOn: string;
}

export interface VesselInformationRes {
  Id: number;
  VesselId: number;
  VesselName: string;
  JobOrder: string;
  MaterialId: number;
  MaterialType: string;
  TubeTypeId: number;
  TubeType: string;
  InspectionDate: string;
  SerialNumber: string;
  SiteName: string;
  Model: string;
  CreatedBy: string;
  CreatedOn: string;
  ModifiedBy: string;
  ModifiedOn: string | null;
  Analyst: string;
  AnalystId: string;
  TestEnd: string;
}

export interface StripChartData {
  Id: number,
  StripChartId: string,
  JobOrder: string,
  VesselId: string,
  VesselName: string,
  ImageName: string,
  Zone: string,
  Row: string,
  Tube: string,
  Section: string,
  Location: string,
  Title: string,
  ImageFile: string,
  ImageConfig: string
}

export interface UpdateStripChartType {
  JobOrderNumber: string;
  VesselId: number;
  VesselName: string;
  MaterialName: string;
}
export interface DataItem {
  id: number;
  percentage: string | number;
  phase: string | number;
}

export interface Configuration {
  DataList: DataItem[];
}

export interface BayValuesSet {
  bayValue: number;
  supportNumber: number;
  bayNumber: number;
  parentLeftMargin: number;
  childLeftMargin: number;
  childWidth: number;
  childMargin:string;
}

export interface PhaseChart {
  Id: number;
  PhaseChartId: string;
  JobOrder: string;
  VesselId: string;
  VesselName: string;
  MaterialId: string;
  MaterialName: string;
  Frequency: string;
  Nwt?: string;
  ProbeDiameter: number;
  Configuration: Configuration;
  TestType: string;
  TubeType: string;
}

export interface BayLengthFormDetails {
  bayLengthSupport: string;
  bayLengthTubes: string;
  spacingValues: number[];
}

export interface PipeStructureProps {
  setOpenFrom: () => void;
  show: boolean;
}

export interface InitialStateVesselInformation {
  isLoading: boolean,
  selectedTab: number,
  vesselInformation: VesselInformationRes[];
  stripChartData: StripChartData[];
  stripChartDataById: StripChartData;
  phaseChartList: PhaseChart[];
  phaseChartDataById: DataItem[];
  phaseChartAllData: PhaseChart;
  totalPhaseChart: number;
  totalVesselInfo: number;
  totalStripChart: number;
  asmeById: AsmeByIdResponse;
  imageFile: File | undefined;
}

export interface UpperGridProps {
  control: Control<BayLengthInterface>;
  errors: FieldErrors<BayLengthInterface>;
  setShowPipeStructureComponent: (val:boolean) => void;
}

export interface RequestData {
  JobOrder: string;
  VesselId: string;
  VesselName: string;
  ImageName: string;
  Zone: string;
  Row: string | number;
  Tube: string | number;
  ImageConfig: string | null;
  Section: string;
}

export interface GetStripChartListingPayload {
  jobOrder: string;
  vesselId: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface CreateStripChartType {
  payLoad: RequestData;
  getListPayload: GetStripChartListingPayload;
}

export interface UpdateStripChart {
  payLoad: StripChartData;
  getListPayload: GetStripChartListingPayload;
}

export interface UpdateAsme {
  AsmeId: string;
  VesselId: string;
  JobOrder: string;
  MaxScanSpeed: number;
  SerialNum: string;
  LengthProbe: number;
  TypeProbe: string;
  LengthExtention: number;
  TypeExtention: string;
  ProbeManufacture: string;
  ProbeSerialNumber: string;
  ProbeScanDirection: string;
}

export interface DeleteStripChartPayload {
  id: string;
  jobOrder: string;
  vesselId: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface DeletePhaseChartPayload {
  id: string;
  jobOrder: string;
  vesselId: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface UpdatePhaseChartPayload {
  jobOrder: string;
  vesselId: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface UpdatePhaseChartType {
  payLoad: PhaseChart,
  getListPayload: UpdatePhaseChartPayload,
  setOpenFrom?: () => void;
}

export interface BayLengthInitialValues {
  baylengthData: BaylengthResponse;
  bayLengthDetails: BayLengthFormDetails;
  isBayLengthChanged: boolean;
  jobId: string;
  isLoading: boolean;
  vesselId: string;
}

export interface AnalystReportsListPayload {
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface AnalystData {
  AnalystId: string;
  JobOrder: string;
  VesselId: string;
}

export interface AnalystDataPayload {
  data: AnalystData[];
}

export interface AnalystReportsType {
  payLoad: AnalystDataPayload,
  handleData: () => void,
}
