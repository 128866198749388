import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddSupportListApiResponse, AddSupportListData, DeleteSupportListResponse, GetAllSupportListApiResponse,
  PayloadTypeDeleteSupportList, PayloadTypeGetSupportList, UpdateSupportListApiResponse, UpdateSupportListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addSupportListStart, deleteSupportListStart, failureAddSupportList, failureDeleteSupportList, failureSupportList,
  failureUpdateSupportList, getSupportListStart,
  SupportTypeActions, successAddSupportList, successDeleteSupportList, successSupportList, successUpdateSupportList, updateSupportList,
} from './sliceSupportList';

async function GetSupportList(data: PayloadTypeGetSupportList): Promise<GetAllSupportListApiResponse> {
  const url = `${Config.Lists.getSupportList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllSupportListApiResponse>(url);
  return result?.data;
}

export const epicGetSupportList = (action$: Observable<SupportTypeActions>) => action$.pipe(
  filter(getSupportListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSupportList) => from(GetSupportList(data)).pipe(
    map((res: GetAllSupportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successSupportList(res?.BMT?.Result);
      }
      return failureSupportList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSupportListStart.match))),
    catchError((error: AxiosError<GetAllSupportListApiResponse>) => of(failureSupportList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateSupportList(data: UpdateSupportListData): Promise<UpdateSupportListApiResponse> {
  const url = `${Config.Lists.updateSupportList}`;
  const result = await makePostRequest<UpdateSupportListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateSupportList = (action$: Observable<SupportTypeActions>) => action$.pipe(
  filter(updateSupportList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateSupportList(data)).pipe(
    mergeMap((res: UpdateSupportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateSupportList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateSupportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateSupportList.match))),
    catchError((error: AxiosError<UpdateSupportListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateSupportList(errorMessage));
    }),
  )),
);

async function AddSupportList(data: AddSupportListData): Promise<AddSupportListApiResponse> {
  const url = `${Config.Lists.addSupportList}`;
  const result = await makePostRequest<AddSupportListApiResponse>(url, data);
  return result?.data;
}

export const epicAddSupportList = (action$: Observable<SupportTypeActions>) => action$.pipe(
  filter(addSupportListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddSupportList(data)).pipe(
    mergeMap((res: AddSupportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddSupportList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddSupportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addSupportListStart.match))),
    catchError((error: AxiosError<AddSupportListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddSupportList(errorMessage));
    }),
  )),
);

async function DeleteSupportList(data: PayloadTypeDeleteSupportList): Promise<DeleteSupportListResponse> {
  const url = `${Config.Lists.deleteSupportList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteSupportListResponse>(url);
  return result?.data;
}

export const epicDeleteSupportList = (action$: Observable<SupportTypeActions>) => action$.pipe(
  filter(deleteSupportListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteSupportList) => from(DeleteSupportList(data)).pipe(
    mergeMap((res: DeleteSupportListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteSupportList(), getSupportListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteSupportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteSupportListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteSupportListResponse>) => of(failureDeleteSupportList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
