import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { TableColumns } from './utils/DataAnalystHeaderCell';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { AnalystData, DeleteAnalyticPayload } from './utils/TypeAnalyst';
import EndPoints from '../../../Routes/EndPoints';
import {
  deleteAnalyst, getAnalystData, resetAnalystListState,
} from './redux/AnalystListSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

export default function AnalystListScreen() {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const dispatch = useDispatch();
  const { isLoading, analystData } = useSelector((state: RootState) => state.AnalystListSliceReducer);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };
  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDeleteModal = () => {
    const payload: DeleteAnalyticPayload = {
      analyticId: selectedId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteAnalyst(payload));
    setDeleteModal(false);
  };

  const handleEdit = (id: string) => {
    navigate(`${id}`);
  };

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getAnalystData(payload));
  };

  useEffect(() => {
    handleData();
    return () => {
      dispatch(resetAnalystListState());
    };
  }, []);

  const handleButtonClick = () => {
    navigate(EndPoints.ADD_ANALYST);
  };

  const tableData: AnalystData[] = useMemo(() => analystData?.Data?.map((item, index) => {
    const transformedDataItem = {

      sn: index,
      action: 'edit&delete',
      firstName: item.FirstName,
      lastName: item.LastName,
      baseCity: item.BaseCityName,
      phone: item.Phone,
      city: item.CityName,
      state: item.State,
      zip: item.Zip,
      id: String(item?.AnalystId),
    };
    return transformedDataItem;
  }), [analystData]);

  const excelData = useMemo(() => analystData?.Data?.map((item) => {
    const transformedDataItems = {
      FirstName: item.FirstName,
      LastName: item.LastName,
      BaseCity: item.BaseCityName,
      Phone: item.Phone,
      City: item.CityName,
      State: item.State,
      Zip: item.Zip,
    };
    return transformedDataItems;
  }), [analystData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Analyst_list',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={translations.analystHeading} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<AnalystData>
            handleButtonClick={handleButtonClick}
            data={tableData}
            columns={TableColumns}
            totalRecords={analystData?.TotalRecords}
            buttonText="Add Analyst"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />

    </>
  );
}
