import { Search } from '@mui/icons-material';
import {
  Button, Grid, InputAdornment, TextField,
} from '@mui/material';
import React, { useState } from 'react';
import ComponentBidEntryModal from './ComponentBidEntryModal';
import './StyleEntryDataTable.scss';

function ComponentHeader() {
  const [searchValue, setSearchValue] = useState('');
  const changeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const [open, setOpen] = useState(false); // Add Bid modal
  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="main-div">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <TextField
              className="subHeaderTextfield"
              size="small"
              placeholder="Search......."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={changeInputValue}
              value={searchValue}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div-style-button">
              <Button className="add-btn" onClick={handleModal}>
                Add Entry
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <ComponentBidEntryModal show={open} setOpenFrom={handleModal} />
    </>
  );
}

export default ComponentHeader;
