import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentVesselListDialog from './ComponentVesselListDialog';
import { deleteVesselListStart, getVesselListStart } from '../redux/sliceVesselList';
import { VesselList } from '../utils/types';

function ComponentVesselListTable() {
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVesselListId, setSelectedVesselListId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const { isLoading, VesselListData } = useSelector((state: RootState) => state.ListVesselReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getVesselListStart(params));
  };

  const handleAction = (id: string) => {
    setOpen(true);
    setRowId(id);
  };

  const handleButtonClick = () => {
    setRowId('');
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Description', label: 'Description' },
    ],
    [],
  );

  const tableData: VesselList[] = useMemo(() => VesselListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      Description: item.Description,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [VesselListData]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedVesselListId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedVesselListId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteVesselListStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<VesselList>
        handleButtonClick={handleButtonClick}
        data={tableData}
        columns={TableColumns}
        totalRecords={VesselListData?.TotalRecords}
        buttonText="Add"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentVesselListDialog show={open} setOpenFrom={handleButtonClick} rowId={rowId} handleData={handleData} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentVesselListTable;
