import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import english from '../../assets/lang/english';
import { RootState } from '../../redux/rootState';
import DataTable from '../../Common/DataTable';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../redux/loaderSlice';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { ExpenseReportsData } from './utils/types.d';
// import { tempDataArray } from './utils/constants';
import { deleteExpenseReportStart, getExpenseReportData } from './redux/expenseReportSlice';
import EndPoints from '../../Routes/EndPoints';

export default function ScreenExpenseReports() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { jobOrder } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState('');

  const { isLoading, expenseReportData } = useSelector((state: RootState) => state.expenseReportReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getExpenseReportData(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (jobOrder) {
      setSearchQuery(jobOrder);
    } else setSearchQuery('');
  }, [jobOrder]);

  const handleDelete = (id: string) => {
    const ids = id.split('/');
    setShowDeleteModal(true);
    setSelectedExpenseId(ids[1]);
  };
  const handelDeleteModal = () => {
    const paramData = {
      id: selectedExpenseId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteExpenseReportStart(paramData));
    setShowDeleteModal(false);
  };
  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'JobOrder', label: 'Job Order #' },
      { key: 'Date', label: 'Date' },
      { key: 'Analyst', label: 'Analyst' },
      { key: 'Check', label: 'Check #' },
      { key: 'CheckDate', label: 'Check Date #' },
    ],
    [],
  );
  const data: ExpenseReportsData[] = useMemo(() => expenseReportData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      JobOrder: item?.JobOrder,
      Date: item?.ExpDate,
      Analyst: item?.AnalystName,
      Check: item?.CheckNo,
      CheckDate: item?.CheckDate,
      id: `${item.JobOrder}/${item?.Id}`,
    };
    return transformedDataItem;
  }), [expenseReportData]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id:string) => {
    Navigate(`/${EndPoints.EXPENSE_REPORTS}/${id}`);
  };

  const excelData = useMemo(() => expenseReportData?.Data?.map((item) => {
    const transformedDataItem = {
      JobOrder: item?.JobOrder,
      Date: item?.ExpDate,
      Analyst: item?.AnalystName,
      Check: item?.CheckNo,
      CheckDate: item?.CheckDate,
    };
    return transformedDataItem;
  }), [expenseReportData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Expense_report',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.expenseReports)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<ExpenseReportsData>
            data={data}
            columns={columns}
            totalRecords={expenseReportData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}
