/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../../Common/DataTable';
import { AddEquipmentColumns } from '../../utils/constants';
import { EquipmentNeededKeyData, TypesSelectJobModal } from '../../utils/types';
import { formatEquipmentNeededData } from '../../utils/helper';
import { addEquipmentByAdminStart, getEquipmentNeededDataStart } from '../../redux/equipmentRequestSlice';
import { RootState } from '../../../../../redux/rootState';
import { getInventoryStart } from '../../../InventoryLandingPage/redux/inventorySlice';
import ConfirmationModal from '../../../../../Common/ConfirmationModal/ConfirmationModal';
import { EquipmentMarkAsSendIcon } from '../../../../../assets/Icons/InventoryIcons/EquipmentMarkAsSendIcon';
import { RECEIVE_EQUIPMENT_CONFIRMATION_INFO_TEXT } from '../../../SendEquipmentScreen/utils/constants';
import { getAnalystMasterStart } from '../../../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function AddEquipmentModal({
  show, setOpenFrom, selectedId, setSelectedId, callbackFunction, isRedirectFormReceiveEquipment = false,
  testEquipmentReqId = '', equipmentPage, equipmentRowsPerPage, equipmentSearchQuery,
  selectedRowIdWithColor, typeId,
}: TypesSelectJobModal): JSX.Element {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { requestId } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<EquipmentNeededKeyData[]>([]);
  const { inventoryData } = useSelector((state: RootState) => state.inventoryReducer);
  const [showModal, setShowModal] = useState(false);
  const { sendRequestListByIdResult } = useSelector((state: RootState) => state.sendEquipmentRequestReducer);

  // eslint-disable-next-line consistent-return
  const sendRequestRowData = useMemo(() => {
    if (selectedRowIdWithColor !== '') {
      return sendRequestListByIdResult?.Data?.find((ele) => ele?.RequestId === selectedRowIdWithColor);
    }
  }, [selectedRowIdWithColor]);

  const getAddedEquipmentList = () => {
    setOpenFrom();
    if (testEquipmentReqId && testEquipmentReqId !== '') {
      const params = {
        requestId: testEquipmentReqId,
        page: equipmentPage,
        rowsPerPage: equipmentRowsPerPage,
        searchQuery: equipmentSearchQuery,
      };
      dispatch(getEquipmentNeededDataStart(params));
      return;
    }
    const params = {
      requestId,
      page: equipmentPage,
      rowsPerPage: equipmentRowsPerPage,
      searchQuery: equipmentSearchQuery,
    };
    dispatch(getEquipmentNeededDataStart(params));
  };

  const onSelect = () => {
    const checkedProductNumber: string[] = [];
    data?.forEach((ele) => {
      if (ele?.isChecked) {
        checkedProductNumber.push(ele?.product);
      }
    });
    // TEST EQUIPMENT SCREEN
    if (testEquipmentReqId && testEquipmentReqId !== '') {
      const payload = {
        RequestId: testEquipmentReqId,
        ProductNumbers: checkedProductNumber,
        Type: typeId,
        AnalystId: sendRequestRowData?.AnalystId ?? '',
      };
      dispatch(addEquipmentByAdminStart({ payload, callback: getAddedEquipmentList }));
      return;
    }

    // SEND EQUIPMENT
    if (callbackFunction) {
      const payload = {
        RequestId: selectedRowIdWithColor,
        ProductNumbers: checkedProductNumber,
        Type: typeId,
        AnalystId: sendRequestRowData?.AnalystId ?? '',
      };
      dispatch(addEquipmentByAdminStart({ payload, callback: callbackFunction }));
      return;
    }
    // Inventory
    const payload = {
      RequestId: requestId,
      ProductNumbers: checkedProductNumber,
      Type: typeId,
      AnalystId: sendRequestRowData?.AnalystId ?? '',
    };
    dispatch(addEquipmentByAdminStart({ payload, callback: getAddedEquipmentList }));
  };

  const handleData = () => {
    const addingEquipmentFromInventory = requestId && requestId !== '';
    const addingEquipmentFromFO = testEquipmentReqId && testEquipmentReqId !== '';
    if (addingEquipmentFromInventory || addingEquipmentFromFO) {
      const payload = {
        page,
        rowsPerPage,
        searchQuery,
        equipmentType: '1',
        inventoryType: '3',
      };
      dispatch(getInventoryStart(payload));
      return;
    }
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      equipmentType: '1',
      inventoryType: '1',
    };
    dispatch(getInventoryStart(payload));
  };

  useEffect(() => {
    if (show) {
      handleData();
      dispatch(getAnalystMasterStart());
    }
  }, [show]);

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: EquipmentNeededKeyData) => {
      if (element?.product === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (inventoryData?.Data) {
      setData(formatEquipmentNeededData(inventoryData?.Data));
    }
  }, [inventoryData?.Data]);

  const onClickSelect = () => {
    if (isRedirectFormReceiveEquipment) {
      setShowModal(true);
      return;
    }
    onSelect();
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          Add Equipment
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DataTable<EquipmentNeededKeyData>
                data={data?.length > 0 ? data : []}
                columns={AddEquipmentColumns}
                totalRecords={inventoryData?.TotalRecords}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                selectedRadio={selectedId}
                setSelectedRadio={setSelectedId}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
                checkBoxWithKey={['sn']}
                handleCheckBoxWithKey={handleCheckBoxWithKey}
                statusColumnsWithKey={['status']}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div style={{ marginRight: '20px' }}>
                  <Button onClick={setOpenFrom} className="button_cancel">Cancel</Button>
                </div>
                <div>
                  <Button className="button_save_and_next" onClick={onClickSelect}>Select</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {showModal && (
        <ConfirmationModal
          confirmationIcon={<EquipmentMarkAsSendIcon />}
          show={showModal}
          setOpenFrom={() => setShowModal(false)}
          handleConfirmation={onSelect}
          text={RECEIVE_EQUIPMENT_CONFIRMATION_INFO_TEXT}
        />
      )}
    </div>
  );
}

export default AddEquipmentModal;
