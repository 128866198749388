import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../Common/TabsStyles';
import './StyleAddBid.scss';
import {
  MAIN_TAB, CUSTOMER_TAB, DESCRIPTION_TAB, ENTRY_TAB, WORKSHEET_TAB, LABEL_MAIN_TAB, LABEL_CUSTOMER_TAB,
  LABEL_DESCRIPTION_TAB, LABEL_ENTRY_TAB, LABEL_WORKSHEET_TAB,
} from './Utils/ConstantBidEdit';
import ComponentBidEditMainTab from './Component/ComponentMain/ComponentBidEditMainTab';
import ComponentBidEditCustomerTab from './Component/ComponentCustomer/ComponentBidEditCustomerTab';
import ComponentBidEditDescriptionTab from './Component/ComponentDescription/ComponentBidEditDescriptionTab';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import ComponentEntryDataTable from './Component/ComponentEntry/Component/ComponentEntryDataTable';

export default function ScreenAddBid() {
  const [open, setOpen] = useState(MAIN_TAB);
  const changeEventStatus = (data: number): void => {
    setOpen(data);
  };

  const [selectedTab, setSelectedTab] = useState(MAIN_TAB);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  function showSelectedTabComponent(): JSX.Element {
    if (open === MAIN_TAB) {
      return (
        <ComponentBidEditMainTab />
      );
    }
    if (open === CUSTOMER_TAB) {
      return (
        <ComponentBidEditCustomerTab />
      );
    }
    if (open === DESCRIPTION_TAB) {
      return (
        <ComponentBidEditDescriptionTab />
      );
    }
    if (open === ENTRY_TAB) {
      return (
        <ComponentEntryDataTable />
      );
    }
    return (
      <ComponentBidEditMainTab />
    );
  }

  return (
    <>
      <ComponentPageHeader subHeading="BID EDIT" />
      <div className="container_job">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label={LABEL_MAIN_TAB}
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            onClick={() => changeEventStatus(MAIN_TAB)}
          />
          <Tab
            label={LABEL_CUSTOMER_TAB}
            style={selectedTab === CUSTOMER_TAB ? selectedTabStyles : tabStyles}
            onClick={() => changeEventStatus(CUSTOMER_TAB)}
          />
          <Tab
            label={LABEL_DESCRIPTION_TAB}
            style={selectedTab === DESCRIPTION_TAB ? selectedTabStyles : tabStyles}
            onClick={() => changeEventStatus(DESCRIPTION_TAB)}
          />
          <Tab
            label={LABEL_ENTRY_TAB}
            style={selectedTab === ENTRY_TAB ? selectedTabStyles : tabStyles}
            onClick={() => changeEventStatus(ENTRY_TAB)}
          />
          <Tab
            label={LABEL_WORKSHEET_TAB}
            style={selectedTab === WORKSHEET_TAB ? selectedTabStyles : tabStyles}
            onClick={() => changeEventStatus(WORKSHEET_TAB)}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>

  );
}
