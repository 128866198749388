import React from 'react';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoutes';
import localStorageConstants from '../utils/LocalStorageConstants';

function RouteManager() {
  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userId = localStorage.getItem(localStorageConstants.USER_ID);

  if (!(token && userId)) {
    return <PublicRoute />;
  }
  return (
    <PrivateRoute />
  );
}

export default RouteManager;
