import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAIN_TAB } from '../utils/constants';
import { GetListingPayload, PaginationResponse } from '../../../utils/type';
import {
  GetInsSummaryResponse, InspectionInititalState, InspectionSummaryPayload, PayloadInspectionSummary,
} from '../utils/types';

const initialState: InspectionInititalState = {
  selectedTab: MAIN_TAB,
  isLoading: false,
  inspectionSummaryData: [],
  totalData: 0,
  inspectionSummaryDataById: {} as GetInsSummaryResponse,
  mainFormValue: '',
  isSuccess: false,
};

export const sliceInspectionSummary = createSlice({
  name: 'InspectionSummary',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    removeSelectedInspectionSummary() {
      return initialState;
    },
    getInspectionSummaryData(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getInspectionSummaryDataSuccess(state, action: PayloadAction<PaginationResponse<GetInsSummaryResponse>>) {
      return {
        ...state,
        isLoading: false,
        inspectionSummaryData: action.payload.Data,
        totalData: action.payload.TotalRecords,
      };
    },
    getInspectionSummaryDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getInspectionSummaryDataById(state, _action: PayloadAction<PayloadInspectionSummary>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getInspectionSummaryDataSuccessById(state, action: PayloadAction<GetInsSummaryResponse>) {
      return {
        ...state,
        isLoading: false,
        inspectionSummaryDataById: action.payload,
      };
    },
    getInspectionSummaryDataFailureById(state, _action) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateInspectionSummaryDataById(state, _action: PayloadAction<InspectionSummaryPayload>) {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    },
    updateInspectionSummaryDataSuccessById(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    updateInspectionSummaryDataFailureById(state) {
      return {
        ...state,
        isSuccess: false,
        isLoading: false,
      };
    },
    setInspectionFormValue(state, action) {
      return {
        ...state,
        mainFormValue: action.payload,
      };
    },
    resetSuccessState(state, action) {
      return {
        ...state,
        isSuccess: action.payload,
      };
    },
  },
});
export const {
  setSelectedTab, removeSelectedInspectionSummary, getInspectionSummaryData, getInspectionSummaryDataSuccess, getInspectionSummaryDataFailure,
  getInspectionSummaryDataById, getInspectionSummaryDataSuccessById, getInspectionSummaryDataFailureById, setInspectionFormValue,
  updateInspectionSummaryDataById, updateInspectionSummaryDataSuccessById, updateInspectionSummaryDataFailureById, resetSuccessState,
} = sliceInspectionSummary.actions;
export const inspectionSummaryReducer = sliceInspectionSummary.reducer;

export type InspectionSummaryActions =
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof removeSelectedInspectionSummary>
  | ReturnType<typeof getInspectionSummaryData>
  | ReturnType<typeof getInspectionSummaryDataSuccess>
  | ReturnType<typeof getInspectionSummaryDataFailure>
  | ReturnType<typeof getInspectionSummaryDataById>
  | ReturnType<typeof getInspectionSummaryDataSuccessById>
  | ReturnType<typeof getInspectionSummaryDataFailureById>
  | ReturnType<typeof setInspectionFormValue>
  | ReturnType<typeof updateInspectionSummaryDataById>
  | ReturnType<typeof updateInspectionSummaryDataSuccessById>
  | ReturnType<typeof updateInspectionSummaryDataFailureById>
  | ReturnType<typeof resetSuccessState>;
