/* eslint-disable max-len */
import * as React from 'react';

export function AddModalIcon() {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.1615 57.5026L35.2031 48.5443C34.4392 47.7804 33.467 47.3984 32.2865 47.3984C31.1059 47.3984 30.1337 47.7804 29.3698 48.5443C28.6059 49.3082 28.224 50.2804 28.224 51.4609C28.224 52.6415 28.6059 53.6137 29.3698 54.3776L41.2448 66.2526C42.0781 67.0859 43.0504 67.5026 44.1615 67.5026C45.2726 67.5026 46.2448 67.0859 47.0781 66.2526L70.6198 42.7109C71.3837 41.947 71.7656 40.9748 71.7656 39.7943C71.7656 38.6137 71.3837 37.6415 70.6198 36.8776C69.8559 36.1137 68.8837 35.7318 67.7031 35.7318C66.5226 35.7318 65.5504 36.1137 64.7865 36.8776L44.1615 57.5026ZM49.9948 91.6693C44.2309 91.6693 38.8142 90.5748 33.7448 88.3859C28.6753 86.1971 24.2656 83.229 20.5156 79.4818C16.7656 75.7318 13.7976 71.322 11.6115 66.2526C9.42535 61.1832 8.3309 55.7665 8.32812 50.0026C8.32812 44.2387 9.42257 38.822 11.6115 33.7526C13.8003 28.6832 16.7684 24.2734 20.5156 20.5234C24.2656 16.7734 28.6753 13.8054 33.7448 11.6193C38.8142 9.43316 44.2309 8.33872 49.9948 8.33594C55.7587 8.33594 61.1753 9.43038 66.2448 11.6193C71.3142 13.8082 75.724 16.7762 79.474 20.5234C83.224 24.2734 86.1934 28.6832 88.3823 33.7526C90.5712 38.822 91.6642 44.2387 91.6615 50.0026C91.6615 55.7665 90.567 61.1832 88.3781 66.2526C86.1892 71.322 83.2212 75.7318 79.474 79.4818C75.724 83.2318 71.3142 86.2012 66.2448 88.3901C61.1753 90.579 55.7587 91.672 49.9948 91.6693ZM49.9948 83.3359C59.3003 83.3359 67.1823 80.1068 73.6406 73.6484C80.099 67.1901 83.3281 59.3082 83.3281 50.0026C83.3281 40.697 80.099 32.8151 73.6406 26.3568C67.1823 19.8984 59.3003 16.6693 49.9948 16.6693C40.6892 16.6693 32.8073 19.8984 26.349 26.3568C19.8906 32.8151 16.6615 40.697 16.6615 50.0026C16.6615 59.3082 19.8906 67.1901 26.349 73.6484C32.8073 80.1068 40.6892 83.3359 49.9948 83.3359Z" fill="#00A751" />
    </svg>

  );
}
