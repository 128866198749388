import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../../Common/DataTable';
import AddEquipmentModal from '../AddEquipmentModal/AddEquipmentModal';
import { getEquipmentNeededDataStart } from '../../redux/equipmentRequestSlice';
import { RootState } from '../../../../../redux/rootState';
import { setLoader } from '../../../../../redux/loaderSlice';
import { EquipmentNeededKeyData } from '../../utils/types';
import { formatEquipmentNeededData } from '../../utils/helper';
import UnlinkComponentModal from '../../../../../Common/UnlinkComponentModal/UnlinkComponentModal';
import { sendAndReceiveEquipmentType } from '../../../SendEquipmentScreen/utils/constants';
import { sendEquipmentStart } from '../../../SendEquipmentScreen/redux/sendEquipmentScreenSlice';

export default function ComponentEquipmentNeed() {
  const { requestId } = useParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [linkOfId, setLinkOfId] = useState('');
  const { equipmentNeededData, equipmentNeededLoading } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const handleData = () => {
    const params = {
      requestId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getEquipmentNeededDataStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'status', label: 'Status' },
      { key: 'product', label: 'Product #' },
      { key: 'category', label: 'Category' },
      { key: 'description', label: 'Description' },
      { key: 'serial', label: 'Serial #' },
    ],
    [],
  );

  useEffect(() => {
    dispatch(setLoader(equipmentNeededLoading));
  }, [equipmentNeededLoading]);

  const handleLinkOff = (id: string) => {
    setLinkOfId(id);
    setShowUnlinkModal(true);
  };

  const unlinkCallback = () => {
    setShowUnlinkModal(false);
    handleData();
  };

  const handleUnlink = () => {
    dispatch(sendEquipmentStart({
      type: sendAndReceiveEquipmentType.UNLINK,
      callback: unlinkCallback,
      payload: {
        requestId,
        productId: linkOfId,
      },
    }));
  };

  return (
    <>
      <DataTable<EquipmentNeededKeyData>
        data={equipmentNeededData?.Data?.length > 0 ? formatEquipmentNeededData(equipmentNeededData?.Data) : []}
        columns={columns}
        totalRecords={equipmentNeededData?.TotalRecords}
        page={page}
        buttonText="Add"
        handleButtonClick={() => setShowAddEquipmentModal(true)}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        handleLinkOff={handleLinkOff}
        handleData={handleData}
        setSearchQuery={setSearchQuery}
        statusColumnsWithKey={['status']}
      />
      {showAddEquipmentModal && (
        <AddEquipmentModal
          show={showAddEquipmentModal}
          setOpenFrom={() => setShowAddEquipmentModal(false)}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          typeId={1}
          equipmentPage={page}
          equipmentRowsPerPage={rowsPerPage}
          equipmentSearchQuery={searchQuery}
        />
      )}
      {showUnlinkModal && (
        <UnlinkComponentModal
          handleUnlink={handleUnlink}
          show={showUnlinkModal}
          setOpenFrom={() => setShowUnlinkModal(false)}
        />
      )}
    </>
  );
}
