import localStorageConstants from './LocalStorageConstants';
import { OtpDataSuccess } from './type';

function LocalStorageUtils(otpDataSuccess: OtpDataSuccess) {
  localStorage.setItem(localStorageConstants.USER_ID, otpDataSuccess.UserId);
  localStorage.setItem(localStorageConstants.TOKEN, otpDataSuccess.Token);
  localStorage.setItem(localStorageConstants.REFRESH_TOKEN, otpDataSuccess.Refresh_Token);
  localStorage.setItem(localStorageConstants.FIRST_NAME, otpDataSuccess.FirstName);
  localStorage.setItem(localStorageConstants.LAST_NAME, otpDataSuccess.LastName);
  localStorage.setItem(localStorageConstants.ROLE_ID, otpDataSuccess?.RoleId);
}

export default LocalStorageUtils;
