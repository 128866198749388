import { SitesData } from '../../Job/jobStarter/addJob/components/customerSiteInfo/addSiteModal/utils/Type';
import { SiteListingDetails } from '../../Job/jobStarter/addJob/utils/types';

export function formatInspectionSitesTableData(data: SiteListingDetails[]) {
  if (data.length === 0) {
    return [];
  }

  const res: SitesData[] = [];

  data.forEach((col: SiteListingDetails) => {
    const obj = {
      select: '',
      siteID: '',
      site: '',
      address: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      id: '',
    };

    obj.siteID = col?.SiteId;
    obj.site = col.SiteName;
    obj.address = col.Address;
    obj.city = col.City;
    obj.state = col.State;
    obj.id = col.SiteId;
    obj.country = col.Country;
    obj.zip = col?.Pincode;
    res.push(obj);
  });

  return res;
}
