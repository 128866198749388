import * as Yup from 'yup';
import { LangTypes } from '../../assets/lang/Types/langTypes';

export const loginValidationSchema = (translations: LangTypes) => Yup.object({
  Email: Yup.string().email(translations.invalidEmailFormat).required(translations.emailRequired),
  Password: Yup.string()
    .required(translations.passwordRequired),
});

export const ChangePwdValidationSchema = (translations: LangTypes) => Yup.object({
  OldPassword: Yup.string()
    .required('Current password is required'),

  NewPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])/,
      translations.LowercaseCharacter,
    )
    .matches(
      /^(?=.*[A-Z])/,
      translations.uppercaseCharacter,
    )
    .matches(
      /^(?=.*[0-9])/,
      translations.numberCharacter,
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      translations.specialCaseCharacter,
    )
    .min(8, translations.mustContainCharacters),

  ConfirmPassword: Yup.string()
    .required('Confirm password is required')
    .matches(
      /^(?=.*[a-z])/,
      translations.LowercaseCharacter,
    )
    .matches(
      /^(?=.*[A-Z])/,
      translations.uppercaseCharacter,
    )
    .matches(
      /^(?=.*[0-9])/,
      translations.numberCharacter,
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      translations.specialCaseCharacter,
    )
    .min(8, translations.mustContainCharacters),
});

export const resetValidationSchema = (translations: LangTypes) => Yup.object({
  code: Yup.string().required(String(translations.otpRequired)).max(6, String(translations.otpMustHave)),
  password: Yup.string()
    .required(String(translations.passwordRequired))
    .matches(
      /^(?=.*[a-z])/,
      String(translations.LowercaseCharacter),
    )
    .matches(
      /^(?=.*[A-Z])/,
      String(translations.uppercaseCharacter),
    )
    .matches(
      /^(?=.*[0-9])/,
      String(translations.numberCharacter),
    )
    .matches(
      /^(?=.*[!@#$%^&*])/,
      String(translations.specialCaseCharacter),
    )
    .min(8, String(translations.mustContainCharacters))
    .max(16, String(translations.maxCharacters)),
});
export const forgotValidationSchema = (translations: LangTypes) => Yup.object({
  email: Yup.string().email(String(translations.invalidEmailFormat)).required(String(translations.emailRequired)),
});
