/* eslint-disable max-len */
import React from 'react';

export function ViewOnlyIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7924_49568)">
        <path d="M9 3.9375C3.375 3.9375 1.125 9.00055 1.125 9.00055C1.125 9.00055 3.375 14.0625 9 14.0625C14.625 14.0625 16.875 9.00055 16.875 9.00055C16.875 9.00055 14.625 3.9375 9 3.9375Z" stroke="#4094F2" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 11.8125C10.5533 11.8125 11.8125 10.5533 11.8125 9C11.8125 7.4467 10.5533 6.1875 9 6.1875C7.4467 6.1875 6.1875 7.4467 6.1875 9C6.1875 10.5533 7.4467 11.8125 9 11.8125Z" stroke="#4094F2" strokeWidth="1.1" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7924_49568">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
