import React from 'react';
import circularBg from '../../assets/loginCircularBg.svg';
import girlImage from '../../assets/login-girl-pointing.png';
import planningIcon from '../../assets/Icons/planningIcon.svg';
import absDiffTestIcon from '../../assets/Icons/absDiffTestIcon.svg';
import soundWaveIcon from '../../assets/Icons/soundWaveIcon.svg';

export default function ComponentLeftVector() {
  return (
    <div className="login_page_left_sec">
      <div className="rotateBgImage">
        <img
          className="rotateCenter"
          src={circularBg}
          alt="VectorImage"
          width={100}
          height={100}
        />
      </div>
      <div className="loginBannerImage">
        <img
          className="girlImage"
          src={girlImage}
          alt="VectorImage"
          width={100}
          height={100}
        />
      </div>
      <div className="loginBannerTestMenu">
        <div className="testMenuContainer mr-100">
          <div className="testMenuImage">
            <img
              src={planningIcon}
              alt="icons"
              width={100}
              height={100}
            />
          </div>
          <div className="testMenuLabel">FLUX LEAKAGE TEST</div>
        </div>

        <div className="testMenuContainer oppositeMotion">
          <div className="testMenuImage">
            <img
              src={absDiffTestIcon}
              alt="icons"
              width={100}
              height={100}
            />
          </div>
          <div className="testMenuLabel">ABS/DIFF TEST</div>
        </div>

        <div className="testMenuContainer mr-100">
          <div className="testMenuImage">
            <img
              src={soundWaveIcon}
              alt="icons"
              width={100}
              height={100}
            />
          </div>
          <div className="testMenuLabel">EDDY CURRENT TEST</div>
        </div>

      </div>
    </div>
  );
}
