/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect, useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Zoom,
} from '@mui/material';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { customOptions } from '../../../../Common/TextEditorOptions';
import { RootState } from '../../../../redux/rootState';
import { createRecommendationCodesData } from '../../recommendationCodes/Redux/sliceRecommendationCodes';
import { DEFAULT_VALUE_TEXTS } from '../../constant';
import { RecommendationCodesApiData } from '../../recommendationCodes/Redux/Type';
import { TypesDialogBox } from '../utils/TypeRecommendationTexts';
import RequiredFiled from '../../../../utils/RequiredField';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentAddTextModal({ show, setOpenFrom, rowId }: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();
  const { recommendationCodesData } = useSelector((state: RootState) => state.RecommendationCodesReducer);

  // Function to update the editor state when content changes
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const handleSave = () => {
    const convertedComment = getHtmlFromEditorHTMLtoString(comment);
    const reqBody = {
      UtilityType: DEFAULT_VALUE_TEXTS,
      Definition: convertedComment,
      Description: description,
    };
    if (rowId !== '') {
      const updateReqBody = {
        ...reqBody,
        Id: rowId,
      };
      dispatch(createRecommendationCodesData(updateReqBody));
      setOpenFrom();
      return;
    }
    dispatch(createRecommendationCodesData(reqBody));
    setOpenFrom();
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    if (rowId) {
      const getRowDataById = recommendationCodesData?.Data?.find((ele: RecommendationCodesApiData) => ele?.Id === rowId);
      if (getRowDataById) {
        const convertedComment = htmlToEditorState(getRowDataById.Definition);
        setDescription(getRowDataById?.Description);
        setComment(convertedComment);
      }
      return;
    }
    setDescription('');
    setComment(EditorState.createEmpty());
  }, [rowId, show]);

  const getRecommendationTextHeading = () => {
    if (rowId) {
      return 'Edit Text';
    }
    return 'Add Text';
  };
  return (
    <div>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          {getRecommendationTextHeading()}
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">
                  {translations.description}
                  <RequiredFiled />
                </label>
                <TextField
                  size="small"
                  className="nonCapitalizeField"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="editorContainer">
                <Editor
                  editorState={comment}
                  onEditorStateChange={handleComment}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  localization={{ locale: 'en' }}
                  toolbar={customOptions}
                />
              </div>
            </Grid>

            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={setOpenFrom}>{translations.cancel}</Button>
                </div>
                <div className="button_margin_left">
                  <Button
                    onClick={handleSave}
                    disabled={description === ''}
                    type="submit"
                    className={description === '' ? 'disableViewOilKitsBtn' : 'button_save_and_next'}
                  >
                    {translations.save}
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentAddTextModal;
