import React, { useState } from 'react';
import { Grid, Card, Button } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
// eslint-disable-next-line import/no-relative-packages
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { customOptions } from '../../../../Common/TextEditorOptions';
import { createCalibrationProcedure, setAbsDiffDescription } from '../Redux/sliceCalibration';
import { RootState } from '../../../../redux/rootState';
import { DEFAULT_ADT, DEFAULT_VALUE_CALIBRATION } from '../../constant';
import { CalibrationProcedureApiData } from '../Redux/Type';

export default function AbsDiffComponent() {
  const dispatch = useDispatch();
  const [saveDisable, setSaveDisable] = useState(true);
  const { absDiffDescription, calibrationProcedureData } = useSelector((state: RootState) => state.CalibrationProcedureReducer);

  const handleComment = (newEditorState: EditorState) => {
    dispatch(setAbsDiffDescription(newEditorState));
  };

  //  Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const handleSave = () => {
    setSaveDisable(true);
    const eddyCurrentId = calibrationProcedureData?.find((ele) => ele?.TestType === DEFAULT_ADT);
    const convertedEddyCurrentDescription = getHtmlFromEditorHTMLtoString(absDiffDescription);
    const reqBody = {
      UtilityType: DEFAULT_VALUE_CALIBRATION,
      TestType: DEFAULT_ADT,
      Description: convertedEddyCurrentDescription,
    };
    if (eddyCurrentId?.Id) {
      const updateReqBody = {
        ...reqBody,
        Id: eddyCurrentId?.Id,
      };
      dispatch(createCalibrationProcedure(updateReqBody));
      return;
    }
    dispatch(createCalibrationProcedure(reqBody));
  };

  const handleEdit = () => {
    setSaveDisable(false);
  };

  const getDescriptionByType = (type: string) => calibrationProcedureData?.find((ele: CalibrationProcedureApiData) => ele?.TestType === type);

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const setInspectionProcedureText = () => {
    if (calibrationProcedureData?.length > 0) {
      const absTextDescription = getDescriptionByType(DEFAULT_ADT);
      if (absTextDescription?.Description) {
        const convertedAbsDiff = htmlToEditorState(absTextDescription?.Description);
        dispatch(setAbsDiffDescription(convertedAbsDiff));
      }
    }
  };

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="comment-card">
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="editorContainer editContainerWithEditBtn" style={{ position: 'relative' }}>
                        <div className="editorBtn">
                          <Button
                            disabled={!saveDisable}
                            className={!saveDisable ? 'disableViewOilKitsBtn' : 'button_save_and_next'}
                            onClick={handleEdit}
                          >
                            Edit
                          </Button>
                        </div>
                        <Editor
                          editorState={absDiffDescription}
                          onEditorStateChange={handleComment}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{ locale: 'en' }}
                          toolbar={customOptions}
                          readOnly={saveDisable}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <Button
              disabled={saveDisable}
              onClick={() => {
                setInspectionProcedureText();
                setSaveDisable(true);
              }}
              className={saveDisable ? 'disableViewOilKitsBtn' : 'button_cancel'}
            >
              Cancel
            </Button>
            <div className="button_margin_left">
              <Button
                disabled={saveDisable}
                className={saveDisable ? 'disableViewOilKitsBtn' : 'button_save_and_next'}
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
