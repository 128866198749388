import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentKitPriceListTable from './Components';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenKitPriceList() {
  const { kitPriceList } = useSelector((state: RootState) => state.KitPriceReducer);

  const excelData = useMemo(() => kitPriceList?.map((item) => {
    const transformedDataItem = {
      KitDescription: item?.KitDescriprion,
      UnitPrice: item?.UnitPrice,
    };
    return transformedDataItem;
  }), [kitPriceList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Kit_Price_list',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="KITS PRICE LIST" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentKitPriceListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenKitPriceList;
