import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { TableColumns } from './utils/DataCustomerHeaderCell';
import { OilKitsData } from './utils/TypeOilKits';
import EndPoints from '../../../../Routes/EndPoints';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import english from '../../../../assets/lang/english';
import DataTable from '../../../../Common/DataTable';
import {
  getOilKitsListData, onDeleteFailureListKit, onDeleteListKit, resetOilKitsState,
} from './redux/oilKitsSlice';
import { RootState } from '../../../../redux/rootState';
import { formatTableData } from './utils/helper';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import ViewOilKitSelectorComponent from '../ScreenAddOilKits/Components/OilKitSelectorComponent';
import { exportToExcelData } from '../../../../Common/PageHeader/helper';
import { onGenerateInvoiceStart } from '../../../../redux/masters/masterSlice';
import { generateInvoiceType } from '../../../../utils/Constant';

export default function ScreenOilKits() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const today = new Date();
  const previousMonth = new Date(today);
  previousMonth.setMonth(today.getMonth() - 1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [dateRangeData, setDateRange] = useState<DateRange>([previousMonth, today]);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>('');
  const [showOilKitSelector, setShowOilKitSelector] = useState(false);
  const { oilKitsListData, isLoading, isDeleteRecord } = useSelector((state: RootState) => state.oilKitsSliceReducer);
  const [fromDate, setFromDate] = useState(format(dateRangeData[0], 'dd-MM-yyyy'));
  const [toDate, setToDate] = useState(format(dateRangeData[1], 'dd-MM-yyyy'));
  const [data, setData] = useState<OilKitsData[]>([]);
  const handleButtonClick = () => {
    navigate(EndPoints.ADD_OIL_KITS);
  };

  useEffect(() => {
    if (dateRangeData !== null) {
      const fromDatePicker = dateRangeData[0];
      const toDatePicker = dateRangeData[1];
      const validFromDate = format(fromDatePicker, 'dd-MM-yyyy');
      const validToDate = format(toDatePicker, 'dd-MM-yyyy');
      setFromDate(validFromDate);
      setToDate(validToDate);
    }
  }, [dateRangeData]);

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      type: 1,
      fromDate,
      toDate,
      searchItem: searchQuery,
    };
    dispatch(getOilKitsListData(payload));
  };

  useEffect(() => {
    handleData();
  }, [fromDate]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    handleData();
    return () => {
      dispatch(resetOilKitsState());
    };
  }, []);

  const handleDelete = (customerId: string) => {
    setDeleteModal(true);
    setSelectedId(customerId);
    handleData();
  };

  const handleDeleteModal = () => {
    dispatch(onDeleteListKit(selectedId));
    setDeleteModal(false);
  };

  const handleEdit = (kitId: string) => {
    navigate(`${kitId}`);
  };

  const handleDateRangeChange = (value: DateRange) => {
    if (!value) {
      return setDateRange([previousMonth, today]);
    }
    return setDateRange(value);
  };

  const excelData = useMemo(() => oilKitsListData?.Data?.map((item) => {
    const transformedDataItems = {
      customer: item.CustomerName,
      pd: item.PurchaseOrderNumber,
      shipDate: item.ShipDate,
      billAmt: `$ ${item.BillAmount}`,
      billed: item.Billed ? 'Yes' : 'No',
      createdBy: item.CreatedBy,
    };
    return transformedDataItems;
  }), [oilKitsListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Oilkits_list',
    );
  };
  useEffect(() => {
    if (isDeleteRecord) {
      handleData();
    }
    dispatch(onDeleteFailureListKit());
  }, [isDeleteRecord]);

  const handleGenerateInvoiceButton = () => {
    const KitIds: string[] = [];
    data?.forEach(((element: OilKitsData) => {
      if (element?.isChecked) {
        KitIds.push(element?.kits);
      }
    }));
    const payload = {
      type: 2,
      selectedIds: KitIds,
    };
    dispatch(onGenerateInvoiceStart(payload));
  };

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: OilKitsData) => {
      if (element?.kits === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (oilKitsListData?.Data) {
      setData(formatTableData(oilKitsListData?.Data));
    }
  }, [oilKitsListData?.Data]);

  const oilKitsSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  const handleGenerateInvoice = (id: string) => {
    const payload = {
      type: generateInvoiceType.OIL_KITS_TYPE,
      selectedIds: [id],
    };
    dispatch(onGenerateInvoiceStart(payload));
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.oilKits)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<OilKitsData>
            handleButtonClick={handleButtonClick}
            data={data?.length > 0 ? data : []}
            columns={TableColumns}
            totalRecords={oilKitsListData?.TotalRecords || 0}
            buttonText="Add Oil Kits"
            searchButton="Kit Search"
            handleSearchButtonClick={() => setShowOilKitSelector(true)}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleDateRangeChange={handleDateRangeChange}
            handleEdit={handleEdit}
            labelOne="Kits Assigned -"
            valueOne={oilKitsListData?.TotalNumberOfKit}
            labelTwo="Total Bill Amt -"
            valueTwo={`$${oilKitsListData?.TotalBillAmount}`}
            dateRange="date"
            dateRangeData={dateRangeData}
            checkBoxColumns={['billed']}
            generateInvoiceButton={data?.length === 0 ? '' : 'Generate invoice'}
            handleGenerateInvoiceButton={handleGenerateInvoiceButton}
            generateInvoiceButtonDisabled={oilKitsSelected}
            checkBoxWithKey={['sn']}
            handleCheckBoxWithKey={handleCheckBoxWithKey}
            handleGenerateInvoice={handleGenerateInvoice}
          />
        </Grid>
      </Grid>
      {showOilKitSelector && (
        <ViewOilKitSelectorComponent
          setOpenFrom={() => setShowOilKitSelector(false)}
          show={showOilKitSelector}
        />
      )}
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </>
  );
}
