import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../Common/Config';
import {
  DashBoardActions, getDashboardData, getDashboardDataFailure, getDashboardDataSuccess,
} from './dashboardSlice';
import { makeGetRequest } from '../../../Common/NetworkOps';
import { GetDashboardApiResponse, GetDashboardPayload } from '../utils/TypeDashboard';

async function getDashBoardData(data: GetDashboardPayload): Promise<GetDashboardApiResponse> {
  const { type, fromDate, toDate } = data;
  const url = `${Config.dashboard.GetallInfo}?FromDate=${fromDate}&ToDate=${toDate}&Type=${type}`;
  const result = await makeGetRequest<GetDashboardApiResponse>(url);
  return result.data;
}

export const getDashboardEpic = (action$ : Observable<DashBoardActions>) => action$.pipe(
  ofType(getDashboardData.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetDashboardPayload) => from(getDashBoardData(data)).pipe(
    map((res: GetDashboardApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDashboardDataSuccess(res.BMT.Result);
      }
      return getDashboardDataSuccess(res.BMT.Result);
    }),
    takeUntil(action$.pipe(filter(getDashboardData.match))),
    catchError((error) => of(getDashboardDataFailure(error))),
  )),
);
