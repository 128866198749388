import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataSheetEditResult, DataSheetResponse, DataSheetResult } from '../utils/types';

const initialState = {
  isLoading: false,
  DataSheet: [] as DataSheetResponse[],
  TotalRecords: 0,
  DataSheetEdit: {} as DataSheetEditResult,
};

export const sliceDataSheet = createSlice({
  name: 'dataSheetSlice',
  initialState,
  reducers: {
    getDataSheet(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDataSheetSuccess(state, action: PayloadAction<DataSheetResult>) {
      return {
        ...state,
        isLoading: false,
        DataSheet: action.payload.Records,
        TotalRecords: action.payload.TotalRecords,
      };
    },
    getDataSheetFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getDataSheetEdit(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDataSheetEditSuccess(state, action: PayloadAction<DataSheetEditResult>) {
      return {
        ...state,
        isLoading: false,
        DataSheetEdit: action.payload,
      };
    },
    getDataSheetEditFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const {
  getDataSheet, getDataSheetSuccess, getDataSheetFailure, getDataSheetEdit, getDataSheetEditSuccess,
  getDataSheetEditFailure,
} = sliceDataSheet.actions;
export const dataSheetReducer = sliceDataSheet.reducer;

export type DataSheetActions =
  | ReturnType<typeof getDataSheet>
  | ReturnType<typeof getDataSheetSuccess>
  | ReturnType<typeof getDataSheetFailure>
  | ReturnType<typeof getDataSheetEdit>
  | ReturnType<typeof getDataSheetEditSuccess>
  | ReturnType<typeof getDataSheetEditFailure>;
