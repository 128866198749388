import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddStyleListData, StyleListPayloadListing, PayloadTypeDeleteStyleList,
  PayloadTypeGetStyleList, StyleListState, UpdateStyleListData,
} from '../utils/types';

const initialState: StyleListState = {
  StyleListData: {} as StyleListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const styleListSlice = createSlice({
  name: 'styleList',
  initialState,
  reducers: {
    getStyleListStart(state, _action: PayloadAction<PayloadTypeGetStyleList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successStyleList(state, action: PayloadAction<StyleListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        StyleListData: action?.payload as StyleListPayloadListing,
      };
    },
    failureStyleList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateStyleList(state, _action: PayloadAction<UpdateStyleListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateStyleList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateStyleList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addStyleListStart(state, _action: PayloadAction<AddStyleListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddStyleList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddStyleList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteStyleListStart(state, _action: PayloadAction<PayloadTypeDeleteStyleList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteStyleList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteStyleList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getStyleListStart, successStyleList, failureStyleList, updateStyleList,
  successUpdateStyleList, failureUpdateStyleList, resetState, deleteStyleListStart,
  successDeleteStyleList, failureDeleteStyleList,
  addStyleListStart, successAddStyleList, failureAddStyleList,
} = styleListSlice.actions;
export const ListStyleReducer = styleListSlice.reducer;

export type StyleTypeActions =
  | ReturnType<typeof getStyleListStart>
  | ReturnType<typeof successStyleList>
  | ReturnType<typeof failureStyleList>

  | ReturnType<typeof updateStyleList>
  | ReturnType<typeof successUpdateStyleList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateStyleList>

  | ReturnType<typeof deleteStyleListStart>
  | ReturnType<typeof successDeleteStyleList>
  | ReturnType<typeof failureDeleteStyleList>

  | ReturnType<typeof addStyleListStart>
  | ReturnType<typeof successAddStyleList>
  | ReturnType<typeof failureAddStyleList>;
