import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ExitIcon, KeyIcon, ProfileRoundIcon,
} from '../../assets/NavBarIcons';
import './StyleNotification.scss';
import DialogBoxLogout from '../Logout/Components/DialogBoxLogout';
import { errorHide } from '../Logout/redux/sliceLogout';
import { RootState } from '../../redux/rootState';

export function ScreenProfile() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state: RootState) => state.userProfileReducer);

  const handleAction = () => {
    setOpen(!open);
    dispatch(errorHide());
  };
  return (
    <>
      <div className="profile-dropdown-content">
        <div className="div-style-profile">
          Welcome
          {' '}
          {userProfile.FirstName}
          !
        </div>
        <ul className="ul-style">
          <li>
            <NavLink to="/profile">
              <div className="div2">
                <div>
                  <ProfileRoundIcon />
                </div>
                <div className="profile">Profile</div>
              </div>
            </NavLink>
            <NavLink to="/change-password">
              <div className="div2">
                <div>
                  <KeyIcon />
                </div>
                <div className="profile">Change Password</div>
              </div>
            </NavLink>
            <div className="div2" onClick={handleAction}>
              <div>
                <ExitIcon />
              </div>
              <div className="profile">Logout</div>
            </div>
          </li>
        </ul>
      </div>
      <DialogBoxLogout show={open} setOpenFrom={handleAction} />
    </>
  );
}
