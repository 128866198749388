import * as Yup from 'yup';

export const markupSchema = Yup.object({
  Percentage: Yup.number()
    .required('Percentage is required')
    .positive('Please enter a positive number')
    .typeError('Percentage must be a number')
    .max(99, 'Please enter a number less than or equal to 99'),
  Description: Yup.string().required('Description is required'),
});
