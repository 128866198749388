import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  onActivateDeactivateUser,
  onActivateDeactivateUserFailure,
  onActivateDeactivateUserSuccess,
  onCreatedUserSuccess,
  onCreateUser,
  onCreateUserFailure,
  onFailUserManagementData, onGetUserManagementData, onGotUserManagementData, UserManagementActions,
} from './sliceUserManagement';
import { makeGetRequest, makePostRequest, makePutRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import {
  ActivateDeactivateUserInterface, ActivateDeactivateUserPayloadInterface,
  CreateUserInterface, CreateUserPayloadInterface, UserManagementListDataResponse, UserManagementListingPayloadInterface,
} from './type';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { AddUpdateApiType } from '../utils/constant';

async function OnGetUserManagementDataApiCall(data: UserManagementListingPayloadInterface): Promise<UserManagementListDataResponse> {
  const url = `${Config.userManagement.getUserManagementList}?pageSize=${data.rowsPerPage}&pageNumber=${data.page}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<UserManagementListDataResponse>(url);
  return result?.data;
}

export const epicOnGetUserManagementData = (action$: Observable<UserManagementActions>) => action$.pipe(
  filter(onGetUserManagementData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(OnGetUserManagementDataApiCall(data)).pipe(
    map((res: UserManagementListDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onGotUserManagementData(res.BMT.Result);
      }
      return onFailUserManagementData();
    }),
    takeUntil(action$.pipe(filter(onGetUserManagementData.match))),
    catchError((error: AxiosError<UserManagementListDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onFailUserManagementData());
    }),
  )),
);

async function onCreateUserApiCall(type: number, payload: CreateUserPayloadInterface): Promise<UserManagementListDataResponse> {
  if (type === AddUpdateApiType.ADD) {
    const url = `${Config.userManagement.AddUser}`;
    const result = await makePostRequest<UserManagementListDataResponse>(url, payload);
    return result?.data;
  }
  const url = `${Config.userManagement.AddUser}`;
  const result = await makePostRequest<UserManagementListDataResponse>(url, payload);
  return result?.data;
}

export const epicOnCreateUserManagementData = (action$: Observable<UserManagementActions>) => action$.pipe(
  filter(onCreateUser.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ type, payload, callback }: CreateUserInterface) => from(onCreateUserApiCall(type, payload)).pipe(
    map((res: UserManagementListDataResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onCreatedUserSuccess();
      }
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onCreatedUserSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return onCreateUserFailure();
    }),
    takeUntil(action$.pipe(filter(onCreateUser.match))),
    catchError((error: AxiosError<UserManagementListDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      callback();
      return of(onCreateUserFailure());
    }),
  )),
);

async function onActivateDeactivateUserApiCall(payload: ActivateDeactivateUserPayloadInterface): Promise<UserManagementListDataResponse> {
  const url = `${Config.userManagement.activeInactiveUser}`;
  const result = await makePutRequest<UserManagementListDataResponse>(url, payload);
  return result?.data;
}

export const epicOnActivateDeactivateUserManagementData = (action$: Observable<UserManagementActions>) => action$.pipe(
  filter(onActivateDeactivateUser.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ payload, callback }: ActivateDeactivateUserInterface) => from(onActivateDeactivateUserApiCall(payload)).pipe(
    map((res: UserManagementListDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onActivateDeactivateUserSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return onActivateDeactivateUserFailure();
    }),
    takeUntil(action$.pipe(filter(onActivateDeactivateUser.match))),
    catchError((error: AxiosError<UserManagementListDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      callback();
      return of(onActivateDeactivateUserFailure());
    }),
  )),
);
