/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InsertTimeComponentPropTypes } from './utils/type';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { ReplaceEnum } from '../utils/type';
import { replaceFieldsSchema } from '../utils/validation';
import {
  ParameterOptions, ReplaceFieldsDefaultData, areaDisable, getReplaceAreaEntries, getSearchAreaEntries,
} from '../utils/constant';
import { ReplaceFields } from '../utils/defectType';
import { SearchReplaceData } from '../utils/helper';
import { getDefectDropdownStart, searchReplaceStart } from '../redux/DefectRedux/sliceVesselDefect';
import { RootState } from '../../../redux/rootState';
import RequiredFiled from '../../../Common/RequiredField';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ReplaceComponent({
  show, setOpenFrom, callback,
}: InsertTimeComponentPropTypes): JSX.Element {
  const {
    control, handleSubmit, formState: { errors }, watch, reset, getValues, setValue,
  } = useForm<ReplaceFields>({
    resolver: yupResolver(replaceFieldsSchema),
    defaultValues: ReplaceFieldsDefaultData as unknown as ReplaceFields,
  });

  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  const { allDefectDropdownData } = useSelector((state: RootState) => state.VesselDefectReducer);

  const watchParameter = watch(ReplaceEnum.parameter);
  const watchSearch = watch(ReplaceEnum.search);
  const watchReplaceWith = watch(ReplaceEnum.replaceWith);

  useEffect(() => {
    if (watchParameter) {
      const currentValues = getValues();
      reset({
        [ReplaceEnum.section]: '',
        [ReplaceEnum.search]: '',
        [ReplaceEnum.searchArea]: '',
        [ReplaceEnum.replaceWith]: '',
        [ReplaceEnum.replaceWithArea]: '',
        [ReplaceEnum.parameter]: currentValues[ReplaceEnum.parameter],
      });
    }
  }, [watchParameter, getValues, reset]);

  useEffect(() => {
    setValue(ReplaceEnum.searchArea, '');
  }, [watchSearch]);

  useEffect(() => {
    setValue(ReplaceEnum.replaceWithArea, '');
  }, [watchReplaceWith]);

  const onSubmit = (data: ReplaceFields) => {
    const payLoad = SearchReplaceData(data, jobOrderId || '', vesselId || '');
    dispatch(searchReplaceStart({ payLoad, callback }));
    setOpenFrom();
  };

  useEffect(() => {
    const payload = {
      param: 'abc', // ---- Temp  ------->>>>>>
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getDefectDropdownStart(payload));
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Search & Replace
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Parameter
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.parameter}
                    size="small"
                    errors={errors}
                    entries={ParameterOptions}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Section
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.section}
                    size="small"
                    errors={errors}
                    entries={allDefectDropdownData?.Section || []}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Search
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.search}
                    size="small"
                    errors={errors}
                    entries={watchParameter === '2' ? allDefectDropdownData?.LocationSearch || [] : allDefectDropdownData?.DefectCodeSearch || []}
                  />
                </div>
              </Grid>
              { watchParameter === '2'
              && (
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className={`${!areaDisable(watchSearch) ? 'label_Style_Customer' : 'label_Style_disabled'}`}>
                    Area
                    {!areaDisable(watchSearch) && <RequiredFiled />}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.searchArea}
                    size="small"
                    errors={errors}
                    disabled={areaDisable(watchSearch)}
                    entries={getSearchAreaEntries(watchSearch, allDefectDropdownData)}
                  />
                </div>
              </Grid>
              )}
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Replace with
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.replaceWith}
                    size="small"
                    errors={errors}
                    entries={watchParameter === '2' ? allDefectDropdownData?.LocationReplace || [] : allDefectDropdownData?.DefectCodeReplace || []}
                  />
                </div>
              </Grid>
              { watchParameter === '2'
              && (
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className={`${!areaDisable(watchReplaceWith) ? 'label_Style_Customer' : 'label_Style_disabled'}`}>
                    Area
                    {!areaDisable(watchReplaceWith) && <RequiredFiled />}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={ReplaceEnum.replaceWithArea}
                    size="small"
                    errors={errors}
                    disabled={areaDisable(watchReplaceWith)}
                    entries={getReplaceAreaEntries(watchReplaceWith, allDefectDropdownData)}
                  />
                </div>
              </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Replace</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReplaceComponent;
