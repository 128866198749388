/* eslint-disable no-plusplus */
import EndPoints from '../Routes/EndPoints';
import { Empty } from '../Screens/ScreenAddSmc/Utils/TypeSmc';
import { CUSTOMER_ID } from '../Screens/addCustomer/utils/constants';
import StorageUtils from './StorageUtils';

export const handleCustomerId = (path:string) => {
  if (!path?.includes(EndPoints.ADD_CUSTOMER)) {
    StorageUtils.removeString(CUSTOMER_ID);
  }
};

export function convertDateFormat(dateStr:string) {
  if (!dateStr) return dateStr;
  const [day, month, year] = dateStr.split('-');

  const newDateStr = `${month}-${day}-${year}`;
  return newDateStr;
}

export function convertDateFormatDDMMYYYY(dateStr:string) {
  if (!dateStr) return dateStr;
  const [month, day, year] = dateStr.split('-');

  const newDateStr = `${day}-${month}-${year}`;
  return newDateStr;
}

export const getItemIndex = (page: number, rowsPerPage: number, index: number) => (page - 1) * (rowsPerPage) + (index) + 1;

export const scrollToErrorField = (fieldId:string) => {
  const fieldRef = document.getElementById(fieldId);
  if (fieldRef) {
    fieldRef.scrollIntoView({ behavior: 'smooth' });
  }
};

export function capitalizeFirstLetter(inputString: string) {
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function getTotalDaysInMonth(year: number, month:number) {
  const date = new Date(year, month - 1, 1);

  date.setMonth(date.getMonth() + 1);
  date.setDate(date.getDate() - 1);

  return date.getDate();
}

export function generateRandomId(length = 8) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
}

export function convertDate(dateString: string) {
  const parts = dateString.split('-');
  const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
  return new Date(formattedDate);
}

export function convertText(originalText: string) {
  const convertedText = originalText?.toLowerCase()?.replace(/(?:^|\s)\S/g, (match) => match?.toUpperCase());
  return convertedText;
}

const removeHtmlTags = (input: Empty) => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = input;
  return tempElement.textContent || tempElement.innerText || '';
};

export const processData = (data: Empty) => data?.map((item: Empty) => ({
  ...item,
  Description: removeHtmlTags(item?.Description),
}));

export function formatDateString(dateString: string) {
  if (!dateString) {
    return '';
  }
  const datePart = dateString.split('T')[0];

  const date = new Date(datePart);

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date.getTime())) {
    return '';
  }

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

export function convertDateFormatTo(dateStr:string) {
  if (!dateStr) return dateStr;
  const [day, month, year] = dateStr.split('-');

  const newDateStr = `${month}/${day}/${year}`;
  return newDateStr;
}
