import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Grid, Card,
} from '@mui/material';
import { GetUnitPriceResponse, SideUnitDetails } from '../../utils/type';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import { SideUnitDetailsNames } from '../../utils/constants';
import { RootState } from '../../../../../redux/rootState';
import { assignUnitPriceValues } from '../../utils/helper';

export default function ComponentUnitDetails() {
  const { control, setValue } = useForm<SideUnitDetails>();
  const { unitPrice, selectedUnit } = useSelector((state:RootState) => state.unitInfoReducer);

  useEffect(() => {
    if (unitPrice?.TubeChargePrice) {
      assignUnitPriceValues(setValue, unitPrice);
    }
  }, [unitPrice]);

  useEffect(() => {
    if (selectedUnit) {
      const data:GetUnitPriceResponse = {
        TubeChargePrice: selectedUnit?.UnitPricingInfo?.TubeCharge,
        CalculatedDays: selectedUnit?.UnitPricingInfo?.CalculatedDays,
        FluxLekageDays: selectedUnit?.UnitPricingInfo?.FluxLeakageDays,
        FluxShipping: selectedUnit?.UnitPricingInfo?.FluxShipping,
        AbsDiffShipping: selectedUnit?.UnitPricingInfo?.AbsDiffShipping,
        SafetyChargePrice: selectedUnit?.UnitPricingInfo?.SafetyCharges,
        MinimumJob: selectedUnit?.UnitPricingInfo?.MinimumJob,
      };
      assignUnitPriceValues(setValue, data);
    }
  }, [selectedUnit]);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Unit Details
            </div>
          </div>
          <div className="unit_info_main_container">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Tube Charges ($)</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.tubeCharge}
                    id={SideUnitDetailsNames.tubeCharge}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Calculated Days</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.calculatedDays}
                    id={SideUnitDetailsNames.calculatedDays}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Flux Leakage Days</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.fluxLeakageDays}
                    id={SideUnitDetailsNames.fluxLeakageDays}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Flux Shipping ($)</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.fluxShipping}
                    id={SideUnitDetailsNames.fluxShipping}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Abs/Diff Shipping ($)</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.absShipping}
                    id={SideUnitDetailsNames.absShipping}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_disabled">Safety Charges ($)</label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={SideUnitDetailsNames.safetyCharges}
                    id={SideUnitDetailsNames.safetyCharges}
                    size="small"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
