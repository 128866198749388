import {
  Button, Card, Grid,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
// import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
// import { pctRegex } from '../../../utils/regex';
import { DefaultReportCheckListValues, ReportCheckListForm, ReportCheckListFormSchema } from '../utils/helper';
import { ReportCheckListData, TypesReportCheckListValues } from '../utils/types';
import DataTable from '../../../Common/DataTable';
import { tempTableData } from '../utils/constants';
import { convertDateFormat } from '../../../utils/CommonFunctions';
import { debugLog } from '../../../Common/Logger';

function ComponentReportChecklist() {
  const {
    control, formState: { errors }, handleSubmit,
  } = useForm<TypesReportCheckListValues>({
    defaultValues: DefaultReportCheckListValues,
    resolver: yupResolver(ReportCheckListFormSchema as ObjectSchema<TypesReportCheckListValues>),
  });

  //   const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const {
  //     unitMaster, machineType,
  //   } = useSelector((state: RootState) => state.unitInfoReducer);
  //   const { analystList } = useSelector((state: RootState) => state.bidsReducer);
  //   const { JobOrderMainData, isLoading } = useSelector((state: RootState) => state.jobOrderReducer);
  //   const testType = watch(UnitInfoOrderFormNames.testType);
  //   const spotTest = watch(UnitInfoOrderFormNames.spotTest);

  const onSubmit = (values: TypesReportCheckListValues) => {
    debugLog('🚀 ~ onSubmit ~ values:', values);
    // dispatch(jobOrderMainForm(values));
    // dispatch(setSelectedTab(CLIENT_SITE_TAB));
    // reset();
  };

  const CheckListColumns = useMemo(
    () => [
      { key: 'sn', label: 'SN' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'dateTest', label: 'Date Test' },
      { key: 'retestDate', label: 'Retest Date' },
    ],
    [],
  );

  // const handleChange = () => {
  //   const values = getValues();
  //   dispatch(jobOrderMainForm(values));
  // };

  //   useEffect(() => {
  //     dispatch(getUnitMasterStart());
  //     dispatch(getAnalystMasterStart());
  //     dispatch(getVesselMasterStart());
  //     return () => {
  //       dispatch(resetJobOrderMain());
  //     };
  //   }, []);

  //   const handleTestTypeChange = () => {
  //     setValue(UnitInfoOrderFormNames.odTubes, '');
  //     trigger(UnitInfoOrderFormNames.odTubes);
  //   };

  //   const handlePCTChange = () => {
  //     setValue(UnitInfoOrderFormNames.pct, '');
  //     trigger(UnitInfoOrderFormNames.pct);
  //   };

  //   useEffect(() => {
  //     dispatch(setLoader(isLoading));
  //   }, [isLoading]);

  //   useEffect(() => {
  //     dispatch(getJobOrderById({ id: orderId }));
  //   }, [orderId]);

  //   useEffect(() => {
  //     if (JobOrderMainData) {
  //       // dispatch(jobOrderMainForm(watch()));
  //       assignJobOrderMain(setValue, JobOrderMainData);
  //     }
  //   }, [JobOrderMainData]);

  //   const { fields, append } = useFieldArray<Empty>({
  //     control,
  //     name: UnitInfoOrderFormNames.inspectedBy,
  //   });

  const handleData = () => {
    // const payload = {
    //   page,
    //   searchQuery,
    //   rowsPerPage,
    // };
    // dispatch(getReportCheckListStart(payload));
  };

  const data: ReportCheckListData[] = useMemo(() => {
    if (tempTableData?.length > 0) {
      const transformedData = tempTableData?.map((item, index) => ({
        sn: index,
        vessel: item?.vessel,
        dateTest: convertDateFormat(item?.dateTest),
        retestDate: convertDateFormat(item?.retestDate),
        id: item?.sn,
      }));
      return transformedData;
    }
    return [];
  }, [tempTableData]);

  useEffect(() => {
    handleData();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ComponentPageHeader subHeading="Report Checklist" />
      <div style={{ padding: '20px', height: '87vh', overflow: 'auto' }}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_container">
                <div className="customerInfo">
                  Test
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <span className="customerID">
                      Processed By
                    </span>
                    <div className="job_input_div">
                      <div className="job_input_style">437435</div>
                    </div>
                  </div>
                  <div className="double_checkbox">
                    <CustomCheckbox
                      control={control}
                      errors={errors}
                      name={ReportCheckListForm.tubeCounts}
                  // handleChange={handleChange}
                      label={<div style={{ fontSize: '12px' }}>Ready To Send</div>}
                    />
                  </div>
                </div>
              </div>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Job</label>
                      <ComponentTextInput
                        control={control}
                        className="nonCapitalizeField"
                        name={ReportCheckListForm.job}
                        errors={errors}
                        id={ReportCheckListForm.job}
                      // handleChange={handleChange}
                    //   regEx={/^[A-Za-z ]{1,10}$/}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">
                        Summary Info
                      </label>
                      <ComponentTextInput
                        control={control}
                        name={ReportCheckListForm.summaryInfo}
                        id={ReportCheckListForm.summaryInfo}
                      // handleChange={handleChange}
                        errors={errors}
                        className="nonCapitalizeField"
                      //   regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,35}$/}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Drawing</label>
                      <ComponentTextInput
                        control={control}
                        errors={errors}
                        // handleChange={handleChange}
                        name={ReportCheckListForm.drawing}
                        id={ReportCheckListForm.drawing}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Vessel Info</label>
                      <ComponentTextInput
                        control={control}
                        errors={errors}
                        // handleChange={handleChange}
                        name={ReportCheckListForm.vesselInfo}
                        id={ReportCheckListForm.vesselInfo}
                        size="small"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_container">
                <div className="customerInfo">
                  Front Page of Report
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  <div className="double_checkbox">
                    <CustomCheckbox
                      control={control}
                      errors={errors}
                      name={ReportCheckListForm.sendReportTo}
                      // handleChange={handleChange}
                      label={<div style={{ fontSize: '12px' }}>Send Report to</div>}
                    />
                  </div>
                  <div className="job_input_div">
                    <div className="job_input_style">437435</div>
                  </div>
                </div>
              </div>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <ComponentTextInput
                        control={control}
                        className="nonCapitalizeField"
                        name={ReportCheckListForm.firstField}
                        id={ReportCheckListForm.firstField}
                        errors={errors}
                        isDisable
                      // handleChange={handleChange}
                    //   regEx={/^[A-Za-z ]{1,10}$/}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <ComponentTextInput
                        control={control}
                        name={ReportCheckListForm.secondField}
                        id={ReportCheckListForm.secondField}
                        errors={errors}
                        isDisable
                      // handleChange={handleChange}
                        className="nonCapitalizeField"
                    //   regEx={/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~\\-\\/ ]{1,35}$/}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <ComponentTextInput
                        control={control}
                        isDisable
                      // handleChange={handleChange}
                        errors={errors}
                        name={ReportCheckListForm.thirdField}
                        id={ReportCheckListForm.thirdField}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <div className="div_label_text">
                      <ComponentTextInput
                        control={control}
                        isDisable
                        errors={errors}
                      // handleChange={handleChange}
                        name={ReportCheckListForm.forthField}
                        id={ReportCheckListForm.forthField}
                        size="small"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <DataTable<ReportCheckListData>
              data={data}
              columns={CheckListColumns}
              totalRecords={tempTableData.length}
              handleData={handleData}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button type="submit" className="button_save_and_next">Save & Next</Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

export default ComponentReportChecklist;
