import * as Yup from 'yup';
import { maxDatePicker, minDatePicker } from '../../../../../utils/Constant';

export const MainFormSchema = Yup.object({
  blueCylinders: Yup.string()
    .required('# of blue cylinders is required'),
  customerSearch: Yup.string()
    .required('Customer ID is required'),
  shipDate: Yup.date().min(minDatePicker).max(maxDatePicker),
  invoiceDate: Yup.date().min(minDatePicker).max(maxDatePicker),
});
