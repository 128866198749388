/* eslint-disable no-plusplus */
import { VesselMainDataById } from '../../VesselInformation/utils/mainTypes';
import { DataItem, PhaseChart } from '../../VesselInformation/utils/type';
import { PhaseChartFormInput, Point } from './type';

export const PhaseChartDefaultValue = {
  jobOrderId: '',
  material: '',
  vessel: '',
  nwt: '',
  frequency: '',
  probeDiameter: '',
};

export enum PhaseChartFormValue {
  jobOrderId = 'jobOrderId',
  material = 'material',
  vessel = 'vessel',
  nwt = 'nwt',
  frequency = 'frequency',
  probeDiameter = 'probeDiameter',
}

export function setValues(phaseChartAllData: PhaseChart, setValue: SetValue, vesselMainData:VesselMainDataById) {
  const data = {
    [PhaseChartFormValue.jobOrderId]: phaseChartAllData?.JobOrder,
    [PhaseChartFormValue.material]: phaseChartAllData?.MaterialName,
    [PhaseChartFormValue.vessel]: phaseChartAllData?.VesselName,
    [PhaseChartFormValue.nwt]: vesselMainData?.Nwt,
    [PhaseChartFormValue.frequency]: phaseChartAllData?.Frequency,
    [PhaseChartFormValue.probeDiameter]: phaseChartAllData?.ProbeDiameter,
  };

  Object.keys(data).forEach((key) => {
    setValue(key as PhaseChartFormValue, data[key as keyof typeof data]);
  });

  return data;
}

export function setVesselPhaseValues(vesselMainData: VesselMainDataById, setValue: SetValue) {
  const data = {
    [PhaseChartFormValue.jobOrderId]: vesselMainData?.JobOrder,
    [PhaseChartFormValue.material]: vesselMainData?.MaterialType,
    [PhaseChartFormValue.vessel]: vesselMainData?.VesselName,
    [PhaseChartFormValue.nwt]: vesselMainData?.Nwt,
    [PhaseChartFormValue.frequency]: '',
    [PhaseChartFormValue.probeDiameter]: vesselMainData?.ProbeDiameter,
  };

  Object.keys(data).forEach((key) => {
    setValue(key as PhaseChartFormValue, data[key as keyof typeof data]);
  });

  return data;
}

export function formatMainRequest(values: PhaseChartFormInput, phaseChartAllData: PhaseChart, data: DataItem[]) {
  const newData = data?.map((item) => ({
    id: item.id,
    phase: String(item.phase),
    percentage: String(item.percentage),
  }));
  const req: PhaseChart = {
    Id: phaseChartAllData.Id,
    TestType: phaseChartAllData.TestType || '',
    TubeType: phaseChartAllData.TubeType || '',
    PhaseChartId: phaseChartAllData.PhaseChartId,
    JobOrder: phaseChartAllData.JobOrder,
    VesselId: phaseChartAllData.VesselId,
    VesselName: phaseChartAllData.VesselName,
    MaterialId: phaseChartAllData.MaterialId,
    MaterialName: phaseChartAllData.MaterialName || '',
    Frequency: values.frequency,
    ProbeDiameter: Number(values.probeDiameter),
    Configuration: {
      DataList: newData,
    },
  };
  return req;
}

export function formatADDMainRequest(values: PhaseChartFormInput, vesselMainData: VesselMainDataById, data: DataItem[]) {
  const newData = data?.map((item) => ({
    id: item.id,
    phase: String(item.phase),
    percentage: String(item.percentage),
  }));
  const req: PhaseChart = {
    Id: 0,
    PhaseChartId: '',
    TestType: vesselMainData.TestEnd,
    TubeType: vesselMainData.TubeType,
    JobOrder: vesselMainData.JobOrder,
    VesselId: String(vesselMainData.VesselId),
    VesselName: vesselMainData.VesselName,
    MaterialId: String(vesselMainData.MaterialId),
    MaterialName: vesselMainData.MaterialType || '',
    Frequency: values.frequency,
    ProbeDiameter: Number(values.probeDiameter),
    Configuration: {
      DataList: newData,
    },
  };
  return req;
}

export const fieldsData = [
  {
    label: 'Job #',
    name: PhaseChartFormValue.jobOrderId,
    disable: true,
    maxLength: 50,
  },
  {
    label: 'Material',
    name: PhaseChartFormValue.material,
    disable: true,
    maxLength: 50,
  },
  {
    label: 'Vessel',
    name: PhaseChartFormValue.vessel,
    disable: true,
    maxLength: 50,
  },
  {
    label: 'NWT',
    name: PhaseChartFormValue.nwt,
    disable: true,
    maxLength: 50,
  },
  {
    label: 'Frequency',
    name: PhaseChartFormValue.frequency,
    disable: false,
    maxLength: 10,
  },
  {
    label: 'Probe Diameter',
    name: PhaseChartFormValue.probeDiameter,
    disable: true,
    maxLength: 50,
  },
];

export function findPointsWithX(
  pointData: Point[],
  x: number,
): [Point | null, Point | null] {
  let point1: Point | null = null;
  let point2: Point | null = null;

  for (let i = 0; i < pointData.length; i++) {
    const currentPoint = pointData[i];

    if (currentPoint.x === x) {
      return [currentPoint, null];
    }

    const nextPoint = pointData[i + 1];

    if (nextPoint && currentPoint.x < x && x <= nextPoint.x) {
      point1 = { x: currentPoint.x, y: currentPoint.y, id: currentPoint.id };
      point2 = { x: nextPoint.x, y: nextPoint.y, id: nextPoint.id };
      return [point1, point2];
    }
  }

  return [point1, point2];
}
