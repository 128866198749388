import React, { useState } from 'react';
import {
  Button, Grid, Tab, Tabs,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ScreenCompletedBids from './Components/ComponentBids/ComponentCompletedBids';
import ComponentWorksheets from './Components/ComponentBids/ComponentWorksheets';
import ComponentClient from './Components/ComponentClientSite/ComponentClient';
import ComponentSite from './Components/ComponentClientSite/ComponentSite';
import ComponentUnits from './Components/ComponentUnits/ComponentUnits';
import ComponentDirections from './Components/ComponentDirections/ComponentDirections';
import ComponentAccommodation from './Components/ComponentAccommodation/ComponentAccommodation';
import ComponentFlightInfo from './Components/ComponentFlightInfo/ComponentFlightInfo';
import ComponentCustComments from './Components/ComponentCustComments/ComponentCustComments';
import ComponentCustInstructions from './Components/ComponentCustInstructions/ComponentCustInstructions';
import ComponentContacts from './Components/ComponentContacts/ComponentContacts';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';

export default function ScreenModifyJobTabs() {
  const bids = 1;
  const clientSite = 2;
  const units = 3;
  const directions = 4;
  const accommodation = 5;
  const flightInfo = 6;
  const custComments = 7;
  const custInstructions = 8;
  const contacts = 9;

  const { id } = useParams();
  const [open, setOpen] = useState(bids);
  const changeEventStatus = (data: number): void => {
    setOpen(data);
  };

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSave = () => {
    changeEventStatus(bids);
    setValue(1);
  };

  const bidsStyle = value === 0 ? selectedTabStyles : tabStyles;
  const clientSiteStyle = value === 1 ? selectedTabStyles : tabStyles;
  const unitsStyle = value === 2 ? selectedTabStyles : tabStyles;
  const directionsStyle = value === 3 ? selectedTabStyles : tabStyles;
  const accommodationStyle = value === 4 ? selectedTabStyles : tabStyles;
  const flightInfoStyle = value === 5 ? selectedTabStyles : tabStyles;
  const custCommentsStyle = value === 6 ? selectedTabStyles : tabStyles;
  const custInstructionsStyle = value === 7 ? selectedTabStyles : tabStyles;
  const contactsStyle = value === 8 ? selectedTabStyles : tabStyles;

  function showComponentListingDetails(): JSX.Element {
    if (open === bids) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <ComponentWorksheets />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ScreenCompletedBids />
            </Grid>
          </Grid>
        </div>
      );
    } if (open === clientSite) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <ComponentClient />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ComponentSite />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleSave}>Edit</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } if (open === units) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentUnits buttonText="Add Unit" />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={handleSave}>Cancel</Button>
                </div>
                {/* <div className='button_margin_left'>
                  <Button className='button_save_and_next' onClick={handleSave}>Ok</Button>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    } if (open === directions) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentDirections />
            </Grid>
          </Grid>
        </div>
      );
    }
    if (open === accommodation) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentAccommodation buttonText="Add Accommodation" />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={handleSave}>Cancel</Button>
                </div>
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleSave}>Ok</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (open === flightInfo) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentFlightInfo />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel">Clear All for Analyst </Button>
                </div>
                {/* <div className='button_margin_left'>
                  <Button className='button_save_and_next' onClick={handleSave}>Ok</Button>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (open === custComments) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentCustComments />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                {/* <div>
                  <Button className='button_cancel' onClick={handleSave}>Cancel</Button>
                </div> */}
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleSave}>Edit</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (open === custInstructions) {
      return (
        <div className="div_container_CustomerInfo">
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <ComponentCustInstructions />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                {/* <div>
                  <Button className='button_cancel' onClick={handleSave}>Cancel</Button>
                </div> */}
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleSave}>Edit</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className="div_container_CustomerInfo">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <ComponentContacts buttonText="Add Contact" />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={handleSave}>Close</Button>
              </div>
              {/* <div className='button_margin_left'>
                <Button className='button_save_and_next' onClick={handleSave}>Ok</Button>
              </div> */}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      <ComponentPageHeader subHeading={`Job #${id as string}`} />
      <div className="container_job">
        <Tabs
          value={value}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label="bids"
            style={bidsStyle}
            onClick={() => changeEventStatus(bids)}
          />
          <Tab
            label="Client / Site"
            style={clientSiteStyle}
            onClick={() => changeEventStatus(clientSite)}
          />
          <Tab
            label="Units"
            style={unitsStyle}
            onClick={() => changeEventStatus(units)}
          />
          <Tab
            label="Directions"
            style={directionsStyle}
            onClick={() => changeEventStatus(directions)}
          />
          <Tab
            label="Accom"
            style={accommodationStyle}
            onClick={() => changeEventStatus(accommodation)}
          />
          <Tab
            label="Flight Info"
            style={flightInfoStyle}
            onClick={() => changeEventStatus(flightInfo)}
          />
          <Tab
            label="Cust Comments"
            style={custCommentsStyle}
            onClick={() => changeEventStatus(custComments)}
          />
          <Tab
            label="Cust Instructions"
            style={custInstructionsStyle}
            onClick={() => changeEventStatus(custInstructions)}
          />
          <Tab
            label="Contacts"
            style={contactsStyle}
            onClick={() => changeEventStatus(contacts)}
          />
        </Tabs>
        <div className="container_job_search">
          <span className="customerID">Job#</span>
          <div className="job_input_div">
            <input className="job_input_style" />
          </div>
        </div>
      </div>
      <div className="div_job_container_info">
        {showComponentListingDetails()}
      </div>
    </>
  );
}
