import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  GetSmcUserActions, getSmcUserStart, successGetSmcUser, failureGetSmcUser, deleteSmcStart, successDeleteSmc, failureDeleteSmc,
} from './sliceSmc';
import Config from '../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../Common/NetworkOps';
import { DeleteSmcApiResponse, GetAllSmcUserApiResponse } from '../utils/TypeSmc';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

interface PayloadTypeGetSmcUserStart {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}
async function GetSmcUserList(data: PayloadTypeGetSmcUserStart): Promise<GetAllSmcUserApiResponse> {
  const url = `${Config.addSmc.getSmcList}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllSmcUserApiResponse>(url);
  return result?.data;
}

export const epicGetSmcUserList = (action$: Observable<GetSmcUserActions>) => action$.pipe(
  filter(getSmcUserStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSmcUserStart) => from(GetSmcUserList(data)).pipe(
    map((res: GetAllSmcUserApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSmcUser(res?.BMT.Result);
      }
      return failureGetSmcUser(res?.BMT?.ResponseMessage || '');
    }),
    takeUntil(action$.pipe(filter(getSmcUserStart.match))),
    catchError(() => of(failureGetSmcUser(''))),
  )),
);

interface PayloadTypeDeleteSmc {
  smcId: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

async function DeleteSmc(data: PayloadTypeDeleteSmc): Promise<DeleteSmcApiResponse> {
  const url = `${Config.addSmc.deleteSmcList}?&smcId=${data.smcId}`;
  const result = await makeDeleteRequest<DeleteSmcApiResponse>(url);
  return result?.data;
}

export const epicDeleteSmc = (action$: Observable<GetSmcUserActions>) => action$.pipe(
  filter(deleteSmcStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteSmc) => from(DeleteSmc(data)).pipe(
    mergeMap((res: DeleteSmcApiResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successDeleteSmc(), getSmcUserStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureDeleteSmc(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteSmcStart.match))),
    catchError((error: AxiosError<DeleteSmcApiResponse>) => of(failureDeleteSmc(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
