import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { InventoryTableColumns } from '../utils/constants';
import ComponentEquipmentIssue from './components/ComponentEquipmentIssue';
import ComponentEquipmentReturn from './components/ComponentEquipmentReturn';
import ComponentAddInventory from './components/ComponentAddInventory';
import ComponentIssueHistory from './components/ComponentIssueHistory';
import {
  getInventoryMasterStart, getInventoryStart, onInventoryDeleteStart, onReturnEquipment,
} from './redux/inventorySlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { InventoryData, InventoryListData } from '../utils/types';
import { formatInventoryData } from '../utils/helper';
import { getAnalystMasterStart } from '../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';

export default function InventoryLandingPage() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showEquipmentIssueModal, setShowEquipmentIssueModal] = useState(false);
  const [showEquipmentReturnModal, setShowEquipmentReturnModal] = useState(false);
  const [openInventoryForm, setOpenInventoryForm] = useState(false);
  const [openIssueHistory, setOpenIssueHistory] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [issueHistoryId, setIssueHistoryId] = useState('');
  const [productFormId, setProductFormId] = useState('');
  const [selectedEquipmentTypeValue, setSelectedEquipmentTypeValue] = useState('3');
  const [selectedInventoryValue, setSelectedInventoryValue] = useState('3');
  const dispatch = useDispatch();

  const { isLoadingInventory, inventoryData, inventoryMaster } = useSelector((state: RootState) => state.inventoryReducer);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      equipmentType: selectedEquipmentTypeValue,
      inventoryType: selectedInventoryValue,
    };
    dispatch(getInventoryStart(payload));
  };

  const handleDeleteModal = () => {
    dispatch(onInventoryDeleteStart({ productId: selectedId, callback: handleData }));
    setDeleteModal(false);
  };

  const handleEdit = (id: string) => {
    setProductFormId(id);
    setOpenInventoryForm(true);
  };

  useEffect(() => {
    dispatch(setLoader(isLoadingInventory));
  }, [isLoadingInventory]);

  useEffect(() => {
    handleData();
  }, [selectedEquipmentTypeValue, selectedInventoryValue]);

  useEffect(() => {
    dispatch(getInventoryMasterStart());
    dispatch(getAnalystMasterStart());
  }, []);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: inventoryData?.Data?.length > 0 ? inventoryData?.Data : [],
      },
      'Analyst_list',
    );
  };

  const handleButtonClick = () => {
    setProductFormId('');
    setOpenInventoryForm(true);
  };

  const onSelectEquipmentType = (value: string) => {
    setSelectedEquipmentTypeValue(value);
  };

  const onSelectInventory = (value: string) => {
    setSelectedInventoryValue(value);
  };

  const onClickYes = () => {
    const rowData = inventoryData?.Data?.find((ele: InventoryData) => ele?.ProductNumber === selectedRowId);
    setShowEquipmentReturnModal(false);
    dispatch(onReturnEquipment({ productId: selectedRowId, issueId: rowData?.IssueId, callback: handleData }));
  };

  const handleIssueHistory = (id: string) => {
    setIssueHistoryId(id);
    setOpenIssueHistory(true);
  };

  const handleIssueButtonDisable = () => {
    if (inventoryData?.Data?.length > 0) {
      const rowData = inventoryData?.Data?.find((ele: InventoryData) => ele?.ProductNumber === selectedRowId);
      if (rowData?.EquipmentTypeId === 2 && rowData?.StatusId === 1) {
        return false;
      }
    }
    return true;
  };

  const handleReturnButtonDisable = () => {
    if (inventoryData?.Data?.length > 0) {
      const rowData = inventoryData?.Data?.find((ele: InventoryData) => ele?.ProductNumber === selectedRowId);
      if (rowData?.EquipmentTypeId === 2 && rowData?.StatusId === 2) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="INVENTORY" downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<InventoryListData>
            data={inventoryData?.Data?.length > 0 ? formatInventoryData(inventoryData?.Data) : []}
            totalRecords={inventoryData?.TotalRecords}
            columns={InventoryTableColumns}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            buttonText="Add"
            handleButtonClick={handleButtonClick}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            searchButton="Return"
            issueButton="Issue"
            selectedRadioWithKey={selectedRowId}
            setSelectedRadioWithKey={setSelectedRowId}
            handleIssueButton={() => setShowEquipmentIssueModal(true)}
            issueButtonDisable={handleIssueButtonDisable()}
            searchButtonDisable={handleReturnButtonDisable()}
            handleSearchButtonClick={() => setShowEquipmentReturnModal(true)}
            equipmentTypeSelectLabel="Equipment Type"
            setSelectedEquipmentType={onSelectEquipmentType}
            selectedEquipmentType={inventoryMaster?.EquipmentTypes}
            selectedEquipmentTypeValue={selectedEquipmentTypeValue}
            inventoryTypeSelectLabel="Inventory"
            setSelectedInventory={onSelectInventory}
            selectedInventory={inventoryMaster?.Status}
            selectedInventoryValue={selectedInventoryValue}
            radioButtonColumnsWithKey={['sn']}
            handleIssueHistory={handleIssueHistory}
            statusColumnsWithKey={['status']}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
      <ComponentEquipmentIssue
        show={showEquipmentIssueModal}
        setOpenFrom={() => setShowEquipmentIssueModal(false)}
        rowId={selectedRowId}
        callback={handleData}
      />
      <ComponentEquipmentReturn
        show={showEquipmentReturnModal}
        setOpenFrom={() => setShowEquipmentReturnModal(false)}
        onClickYes={onClickYes}
        rowId={selectedRowId}
      />
      <ComponentAddInventory
        productFormId={productFormId}
        show={openInventoryForm}
        setOpenFrom={() => setOpenInventoryForm(false)}
        callback={handleData}
      />
      {openIssueHistory && (
      <ComponentIssueHistory
        show={openIssueHistory}
        setOpenFrom={() => setOpenIssueHistory(false)}
        rowId={issueHistoryId}
      />
      )}
    </>
  );
}
