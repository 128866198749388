import React, { Ref, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../../redux/rootState';
import { EquipmentNeededPdfComponentPropTypes } from '../../redux/type';
import './style.scss';
import taiLogo from '../../../../../assets/tailogo-eddyCurrent.png';
import { sendEquipmentType } from '../../../utils/constants';
import { sendAndReceivePdfType } from '../../utils/constants';

const EquipmentNeededPdfComponent = React.forwardRef(({ selectedRowIdWithColor }: EquipmentNeededPdfComponentPropTypes, ref: Ref<HTMLDivElement>) => {
  const { typeId } = useParams();
  const { dataForPrintPdfByRequestId, sendRequestListByIdResult } = useSelector((state: RootState) => state.sendEquipmentRequestReducer);

  const selectedRowData = useMemo(() => {
    const selectedRow = sendRequestListByIdResult?.Data?.find((ele) => String(ele?.RequestId) === String(selectedRowIdWithColor));
    return selectedRow;
  }, [dataForPrintPdfByRequestId?.Data]);

  const getHeader = useMemo(() => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      return sendAndReceivePdfType.SEND_HEADER;
    }
    return sendAndReceivePdfType.RECEIVE_HEADER;
  }, [typeId]);

  const getShippingDate = useMemo(() => {
    if (Number(typeId) === sendEquipmentType.SEND_EQUIPMENT) {
      return `Date Shipped: ${''}`;
    }
    return `Shipping Date: ${''}`;
  }, [typeId, selectedRowData]);

  return (
    <div ref={ref} className="tableContainerContent">
      <div
        style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px',
        }}
      >
        <div className="ms-3">
          <img src={taiLogo} style={{ width: '200px', height: 'auto' }} alt="" />
        </div>
        <div>
          <div className="firstText me-3">1000 Cobb Place Blud. NW, Bldg. 300, Ste. 300</div>
          <div className="firstText me-3">300 Kennesaw, GA 30144</div>
          <div className="firstText me-3">(770)763-5930 | (800)554-4127</div>
        </div>
      </div>
      <div className="shippingListClass">{getHeader}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <div>{`Job Number: ${selectedRowData?.JobNumber}`}</div>
          <div>{`Analyst: ${selectedRowData?.Analyst}`}</div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
          <div>{getShippingDate}</div>
          <div>{`Job Dates: ${selectedRowData?.JobDates}`}</div>
        </div>
      </div>
      <TableContainer sx={{ marginTop: '20px' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="tableRowClass">
              <TableCell>Product #</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataForPrintPdfByRequestId?.Data?.length > 0 && dataForPrintPdfByRequestId?.Data?.map((row) => (
              <TableRow
                key={row?.ProductNumber}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row?.ProductNumber}</TableCell>
                <TableCell>{row?.Description}</TableCell>
                <TableCell>1</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="instructionClass instructionHeading">
        Instructions :
      </div>
      <div className="instructionClass mt-0 pt-0">
        <div className="instructionClassSpan customBreakWords">{dataForPrintPdfByRequestId?.Instructions}</div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '45px',
          marginTop: '10px',
          marginBottom: '8px',
        }}
        className="bottomWrapper"
      >
        <div className="bottomTextClass">Nondestructive Inspections</div>
        <div className="bottomTextClass">Eddy Current | Flux Leakage | Oil Analysis</div>
      </div>
    </div>
  );
});

export default EquipmentNeededPdfComponent;
