/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import {
  Backdrop,
  CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip,
  Zoom,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { TypesAddReportModal } from './utils/Type';
import ReportListModalTabs from '../ReportListingModal/ReportListModalTabs';
import styles from './utils/styles.module.scss';
// import { AddReportSchema } from './utils/validations';
import {
  deleteReportByIdStart, getGeneralVesselsStart, getReportByIdStart, reSetAllVesselsList,
} from '../../redux/jobScheduleSlice';
import { RootState } from '../../../../../../redux/rootState';
import { ReportFormDefault, jobOrderPattern } from './utils/helper';
import { Empty } from '../../../../../ScreenAddSmc/Utils/TypeSmc';
import { DeleteIcon } from '../../../../../../assets/TableIcons';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

export default function AddReportModal({
  show, setOpenFrom, openReportModal, setOpenReportModal, unitEditData, callback,
}: TypesAddReportModal) {
  const [selectedSearch, setSelectedSearch] = useState<string>('');
  const dispatch = useDispatch();
  const {
    generalAllVesselsList, isLoading, reportDataById,
  } = useSelector((state:RootState) => state.jobScheduleReducer);

  // const dynamicSchema = useMemo(() => AddReportSchema(generalAllVesselsList), [generalAllVesselsList]);

  const {
    control, trigger, formState: { errors }, getValues, setValue, reset,
  } = useForm<Empty>(
    {
      // resolver: yupResolver(dynamicSchema as Empty),
      defaultValues: ReportFormDefault,
    },
  );

  // const onSubmitForm = (values: { [key: string]: string | undefined }) => {
  //   const payload: AddReportPayload[] = Object.entries(values).map(([unitVesselId, jobString]) => generatePayload(unitVesselId, jobString, unitEditData));
  //   const payloads = payload.filter((item) => item.JobOrder !== '');
  //   dispatch(addJobReport({
  //     payloads, setOpenFrom, callback, IsReportPresent: unitEditData.IsReportPresent,
  //   }));
  // };

  useEffect(() => {
    const payload = {
      jobOrderId: unitEditData?.UnitId,
    };
    dispatch(getGeneralVesselsStart(payload));
    return () => {
      reset();
      dispatch(reSetAllVesselsList());
    };
  }, [unitEditData?.UnitId]);

  const handleSearchClick = (id: string) => {
    setOpenReportModal(true);
    setSelectedSearch(id);
  };

  const handleClearClick = (VesselId: string) => {
    if (VesselId) {
      setValue(String(VesselId), '');
      trigger(VesselId);
    }
  };

  const handleDeleteClick = (VesselId: string) => {
    if (VesselId) {
      const fieldData = getValues(String(VesselId));
      const match = fieldData.match(jobOrderPattern);
      const [, jobOrder, , , id] = match;
      const payload = {
        id,
        vesselId: VesselId,
        jobOrder,
      };
      dispatch(deleteReportByIdStart({
        payload, jobOrderId: unitEditData?.UnitId, callback, setValue,
      }));
    }
  };

  const onClose = () => {
    setOpenFrom(false);
  };

  const modalHeaderText = useMemo(() => {
    if (unitEditData.IsReportPresent) {
      return 'Edit Reports';
    }
    return 'Add Reports';
  }, [show]);

  useEffect(() => {
    if (unitEditData.IsReportPresent) {
      const payload = {
        jobOrderId: unitEditData?.UnitId,
      };
      dispatch(getReportByIdStart(payload));
    }
  }, [unitEditData?.UnitId]);

  useEffect(() => {
    if (reportDataById && unitEditData.IsReportPresent) {
      reportDataById.forEach((item) => {
        setValue(String(item.UnitVesselId), `${item.JobOrder}(${item.VesselName})-(${item.VesselId})-(${item.Id})`);
        trigger(String(item.UnitVesselId));
      });
    }
  }, [reportDataById]);

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        className: 'paperStyle',
      }}
      open={show}
      transitionDuration={500}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onClose()}
    >
      {
        isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        )
        }
      <DialogTitle className="dialogTitle_style">
        <div className="dialogTitle_style_div">
          <div className="customerInfo">{modalHeaderText}</div>
        </div>
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialogContent_style">
        <div className="dialogContent_style_div p16 ">
          <form>
            {/* <form onSubmit={handleSubmit(onSubmitForm)} noValidate> */}
            <Grid container spacing={2} className="fullWidthBox">
              {generalAllVesselsList?.map((vessel, index) => (
                <React.Fragment key={`${vessel?.VesselId}-${index}`}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <label className="label_Style_Customer">
                      {vessel?.VesselName}
                    </label>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={11}>
                    <Controller
                      name={String(vessel.VesselId)}
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        const placeholderValue = value?.substring(0, value.lastIndexOf('-'));
                        return (
                          <TextField
                            className="nonCapitalizeField"
                            size="small"
                            disabled
                            // value={value}
                            fullWidth
                            onChange={(event) => {
                              onChange(event);
                              trigger(vessel.VesselName);
                            }}
                            placeholder={placeholderValue || 'Report...'}
                            InputProps={{
                              classes: {
                                input: styles.customPlaceholder,
                              },
                              endAdornment: (
                                reportDataById?.filter((item) => item.UnitVesselId === vessel?.VesselId).length !== 0
                                  ? (
                                    <Tooltip title="Disabled" className={styles.searchIconStyle} placement="left">
                                      <IconButton className="search_icon_btn" disabled>
                                        <SearchIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                  : (
                                    <Tooltip title="Search here.." className={styles.searchIconStyle} placement="left">
                                      <IconButton onClick={() => { handleSearchClick(String(vessel?.VesselId)); }} className="search_icon_btn">
                                        <SearchIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                              ),
                            }}
                            error={!!errors?.[vessel.VesselId]?.message}
                            helperText={errors?.[vessel.VesselId]?.message ? String(errors?.[vessel.VesselId]?.message) : ''}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    {reportDataById?.filter((item) => item.UnitVesselId === vessel?.VesselId).length !== 0
                      ? (
                        ''
                      ) : (
                        getValues()[vessel?.VesselId]
                          ? (
                            <Tooltip title="Clear" className={styles.clearIconStyle} placement="right">
                              <IconButton
                                onClick={() => handleClearClick(String(vessel?.VesselId))}
                                className="clear_icon_btn"
                              >
                                <ClearIcon />
                              </IconButton>
                            </Tooltip>
                          )
                          : (
                            <IconButton
                              className="clear_icon_btn"
                              disabled={!getValues()[vessel?.VesselId]}
                            >
                              <ClearIcon />
                            </IconButton>
                          )
                      )}
                  </Grid>
                  <Grid item lg={1} md={1} sm={1} xs={1}>
                    {reportDataById?.filter((item) => item.UnitVesselId === vessel?.VesselId).length !== 0
                      ? (
                        <div>
                          <Tooltip title="Delete" className={styles.clearIconStyle} placement="right">
                            <IconButton
                              onClick={() => handleDeleteClick(String(vessel?.VesselId))}
                              className="clear_icon_btn"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <div />
                      )}
                  </Grid>
                </React.Fragment>
              ))}
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button type="button" className="button_cancel" onClick={() => onClose()}>Cancel</Button>
                  </div>
                  {/* <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Ok</Button>
                  </div> */}
                </div>
              </Grid>
            </Grid>
            {openReportModal
          && (
          <ReportListModalTabs
            show={openReportModal}
            setOpenFrom={() => setOpenReportModal(false)}
            getValues={getValues}
            setValue={setValue}
            selectedSearch={selectedSearch}
            unitEditData={unitEditData}
            callback={callback}
            trigger={trigger}
          />
          )}
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
