/* eslint-disable max-len */
import * as React from 'react';

export function Cancel() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.81927 5.00065L9.49427 1.32565C9.7276 1.09232 9.7276 0.742318 9.49427 0.508984C9.26094 0.275651 8.91094 0.275651 8.6776 0.508984L5.0026 4.18398L1.3276 0.508984C1.09427 0.275651 0.744271 0.275651 0.510938 0.508984C0.277604 0.742318 0.277604 1.09232 0.510938 1.32565L4.18594 5.00065L0.510938 8.67565C0.394271 8.79232 0.335938 8.90899 0.335938 9.08399C0.335938 9.43398 0.569271 9.66732 0.919271 9.66732C1.09427 9.66732 1.21094 9.60899 1.3276 9.49232L5.0026 5.81732L8.6776 9.49232C8.79427 9.60899 8.91094 9.66732 9.08594 9.66732C9.26094 9.66732 9.37761 9.60899 9.49427 9.49232C9.7276 9.25899 9.7276 8.90898 9.49427 8.67565L5.81927 5.00065Z" fill="#212529" />
    </svg>
  );
}
