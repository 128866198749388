import {
  Observable,
  catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  sendLoginData, loginDataSendFailure, AuthActions, sendOtpVerifyData, OtpDataSendSuccess, OtpDataSendFailure,
} from './sliceLogin';
import { makePostRequest } from '../../../../Common/NetworkOps';
import { LoginApiResponse, LoginTypes, SendOtpTypes } from '../utils/types';
import StorageUtils from '../../../../utils/StorageUtils';
import constants from '../../../../utils/SessionConstants';
import Config from '../../../../Common/Config';

async function login(data: LoginTypes): Promise<LoginApiResponse> {
  const url = `${Config.auth.generateOTP}`;
  const result = await makePostRequest<LoginApiResponse>(url, data);
  return result.data;
}

export const epicLogin = (action$: Observable<AuthActions>) => action$.pipe(
  filter(sendLoginData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => {
    const email = data.Email;
    const OtpType = 'Login Authorization';
    return from(login(data)).pipe(
      map((res: LoginApiResponse) => {
        if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
          StorageUtils.setString(constants.EMAIL_ID, email);
          return sendOtpVerifyData({ EmailId: email, OtpType });
        }
        return loginDataSendFailure(res.BMT.ResponseMessage);
      }),
      takeUntil(action$.pipe(filter(sendLoginData.match))),
      catchError((error: AxiosError<LoginApiResponse>) => of(loginDataSendFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
    );
  }),
);

async function sendOtp(data: SendOtpTypes): Promise<LoginApiResponse> {
  const emailId = StorageUtils.getString(constants.EMAIL_ID);
  const body = {
    EmailId: data.EmailId || emailId,
    OtpType: 'Login Authorization',
  };

  const url = `${Config.auth.sendOtp}`;
  const result = await makePostRequest<LoginApiResponse>(url, body);
  return result.data;
}

export const epicSendOtp = (action$: Observable<AuthActions>) => action$.pipe(
  filter(sendOtpVerifyData.match),
  debounceTime(250),
  map((x:AuthActions) => x.payload),
  mergeMap((data) => from(sendOtp(data as SendOtpTypes)).pipe(
    map((res: LoginApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return OtpDataSendSuccess();
      }
      return OtpDataSendFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendOtpVerifyData.match))),
    catchError((error: AxiosError<LoginApiResponse>) => of(loginDataSendFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
