import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { TubeChartGrid } from './tubeChartGrid';
import {
  Circle,
  GetTubeChartPayload,
  PolygonInterface,
  TubeConfiguration,
} from '../../../utils/tubeChartTypes';
import ComponentPageHeader from '../../../../../Common/PageHeader/ComponentPageHeader';
import { Polygon } from './gridClass';
import TubeChartPanel from './tubeChartPanel';
import { RootState } from '../../../../../redux/rootState';
import {
  getTubeChartByIdStart, getTubeChartsStart,
  setSelectedSection,
  updateTubeChartStart,
} from '../../../redux/tubeChart/tubeChartSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import styles from './index.module.scss';
import { getUpdateTubePayload } from '../../../utils/helper/tubeChart';

export function TubeDiagram() {
  const [polygonList, setPolygonList] = useState<PolygonInterface[]>([]);
  const [polyCircles, setPolyCircles] = useState<Circle[][]>([]);
  const [totalTubeCount, setTotalTubeCount] = useState(0);

  const {
    chartList, tubeChartDefects,
    selectedSection, isLoading,
  } = useSelector((state:RootState) => state.TubeChartReducer);
  const { tubeId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const selectedSectionIndex = useMemo(() => {
    if (selectedSection) {
      return chartList.findIndex((details) => details.Panel === selectedSection);
    }
    return 0;
  }, [selectedSection, chartList?.length]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    const fetchType = searchParams.get('type');
    const jobOrder = searchParams.get('jobOrder');
    const vessel = searchParams.get('vessel');
    if (fetchType === 'all') {
      const payload:GetTubeChartPayload = {
        JobOrder: String(jobOrder),
        VesselId: String(vessel),
        Type: 2,
      };
      dispatch(getTubeChartsStart(payload));
    } else {
      dispatch(getTubeChartByIdStart(String(tubeId)));
    }
  }, [tubeId, searchParams]);

  useEffect(() => {
    if (chartList.length > 0) {
      const polyArr:PolygonInterface[] = [];

      chartList.forEach((chartDetails:TubeConfiguration) => {
        const tempPoly = new Polygon(chartDetails);
        polyArr.push(tempPoly);
      });

      setPolygonList([...polyArr]);
    }
  }, [chartList]);

  useEffect(() => {
    if (tubeChartDefects?.length > 0) {
      polygonList[Number(selectedSectionIndex)].displayDefects(tubeChartDefects);
      setPolyCircles([...polygonList[Number(selectedSectionIndex)].circles]);
    }
  }, [tubeChartDefects]);

  const handleUndo = () => {
    if (polygonList.length > 0) {
      polygonList[Number(selectedSectionIndex)].undo();
      setPolyCircles([...polygonList[Number(selectedSectionIndex)].circles]);
    }
  };

  const handleSectionChange = (value:string) => {
    dispatch(setSelectedSection(value));
    const ind = chartList.findIndex((details) => details.Panel === value);
    setPolyCircles([...polygonList[ind].circles]);
  };

  const handleSave = () => {
    const jobOrder = String(searchParams.get('jobOrder'));
    const vessel = String(searchParams.get('vessel'));
    const payload = getUpdateTubePayload(
      jobOrder,
      vessel,
      polygonList,
      chartList,
    );

    dispatch(updateTubeChartStart(payload));
  };

  useEffect(() => {
    const totalCount = polygonList?.reduce((acc, curr:PolygonInterface) => acc + curr.totalVisible, 0);
    setTotalTubeCount(totalCount);
  }, [polygonList?.[Number(selectedSectionIndex)]?.totalVisible]);

  return (
    <div style={{ height: '90vh', overflow: 'auto', overflowX: 'hidden' }}>
      <ComponentPageHeader subHeading="Tube Chart" />
      <TubeChartPanel
        handleUndo={handleUndo}
        handleSectionChange={handleSectionChange}
        tubeCount={polygonList?.[Number(selectedSectionIndex)]?.totalVisible}
        isUndoDisable={polygonList?.[Number(selectedSectionIndex)]?.history?.length === 0}
        totalTubeCount={totalTubeCount}
      />
      <div className={`${styles.vesselDiv} save_and_next_div`}>
        <div className="button_margin_left">
          <Button className="button_save_and_next" onClick={handleSave}>Save</Button>
        </div>
      </div>
      {polygonList?.length > 0 && (
      <TubeChartGrid
        polygon={polygonList[Number(selectedSectionIndex)]}
        polyCircles={polyCircles}
        setPolyCircles={setPolyCircles}
      />
      )}
    </div>
  );
}
