import { KonvaEventObject } from 'konva/lib/Node';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Stage, Layer, Rect as KonvaRect, Image as KonvaImage, Circle as KonvaCircle, Line as KonvaLine, Arrow as KonvaArrow, Text as KonvaText, Transformer,
} from 'react-konva';
import useImage from 'use-image';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Konva from 'konva';
import { DrawAction, PAINT_OPTIONS } from './PaintConstants';
import {
  Arrow, Circle, Rectangle, Scribble,
  TextElementType,
} from './PaintTypes';
import '../utils/StyleImageEditor.scss';
import ColorSketchPicker from './ComponentColor';
import { getPhotoById, resetPhotoData, updatePhotoEditStart } from '../redux/photosSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import {
  ExportData, onDelete, onStageMouseDown, onStageMouseMove, onTextClick, onTextDblClick,
} from '../utils/helper';
import { PaintOption } from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import ComponentLoader from '../../../Common/ComponentLoader/ComponentLoader';

const ImagePaint = React.memo(() => {
  const stageRef = useRef<Konva.Stage | null>(null);
  const transformerRef = useRef<Konva.Transformer | null>(null);
  const textRef = useRef<Konva.Text | null>(null);
  const [scribbles, setScribbles] = useState<Scribble[]>([]);
  const [rectangles, setRectangles] = useState<Rectangle[]>([]);
  const [circles, setCircles] = useState<Circle[]>([]);
  const [arrows, setArrows] = useState<Arrow[]>([]);
  const [texts, setTexts] = useState<TextElementType[]>([]);
  const [color, setColor] = useState('rgba(0, 0, 0, 1)');
  const [drawAction, setDrawAction] = useState<DrawAction>(DrawAction.Scribble);
  const dispatch = useDispatch();
  const { photoId } = useParams();
  const { photoDataById, isLoading } = useSelector((state: RootState) => state.photosReducer);
  const [isPaintRefs, setIsPaintRefs] = useState<boolean>(false);
  const [currentShapeRefs, setCurrentShapeRefs] = useState<string>('');
  const [buttonDisable, setButtonDisable] = useState(true);

  const onStageMouseUp = useCallback(() => {
    setIsPaintRefs(false);
  }, []);

  const diagramRef = useRef<null>(null);

  const onShapeClick = useCallback(
    (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (drawAction !== DrawAction.Select) return;
      const { currentTarget } = e;
      transformerRef?.current?.nodes([currentTarget]);
    },
    [drawAction],
  );
  const isDraggable = drawAction === DrawAction.Select;

  const onExportClick = useCallback(() => {
    const dataURL = stageRef?.current?.toDataURL({ pixelRatio: 1 });
    const formData = ExportData(photoDataById, dataURL);
    if (formData) {
      dispatch(updatePhotoEditStart(formData));
    }
  }, [photoDataById]);

  const onClear = useCallback(() => {
    setRectangles([]);
    setCircles([]);
    setArrows([]);
    setScribbles([]);
    setTexts([]);
  }, []);

  useEffect(() => {
    if (photoId) {
      const payload = {
        id: photoId,
      };
      dispatch(getPhotoById(payload));
    }
    return () => {
      dispatch(resetPhotoData());
    };
  }, [photoId]);

  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const ImageUrl = photoDataById?.PhotoPath;

  async function loadImage(url: string): Promise<string> {
    try {
      const response = await fetch(url, { mode: 'cors' });
      if (!response.ok) {
        throw new Error(`Failed to load image: ${response.statusText}`);
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      setButtonDisable(true);
      dispatch(setLoader(false));
      showErrorToaster('Failed to load image, Try again');
      return '';
    }
  }

  useEffect(() => {
    if (ImageUrl) {
      loadImage(ImageUrl).then((url) => {
        setBlobUrl(url);
      });
    }
  }, [ImageUrl]);

  const [image, status] = useImage(blobUrl || '');

  const loadingImage = (ImageStatus: string) => {
    if (ImageStatus === 'loading') {
      setButtonDisable(true);
      dispatch(setLoader(true));
    } else if (ImageStatus === 'failed') {
      setButtonDisable(true);
      dispatch(setLoader(false));
      showErrorToaster('Failed to load image, Try again');
    } else if (ImageStatus === 'loaded') {
      setButtonDisable(false);
      dispatch(setLoader(false));
      showSuccessToaster('Image loaded successfully');
    }
  };

  useEffect(() => {
    loadingImage(status);
  }, [status]);

  const handleDelete = () => {
    onDelete({
      transformerRef, setRectangles, setCircles, setScribbles, setArrows, setTexts,
    });
  };

  const handleMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    onStageMouseDown({
      e,
      drawAction,
      setIsPaintRefs,
      stageRef,
      setCurrentShapeRefs,
      setScribbles,
      setCircles,
      setRectangles,
      setArrows,
      setTexts,
      color,
      transformerRef,
    });
  };

  const handleMouseMove = () => {
    onStageMouseMove({
      drawAction, isPaintRefs, stageRef, currentShapeRefs, setScribbles, setCircles, setRectangles, setArrows,
    });
  };

  return (
    <>
      {isLoading
        && (
          <div className="loader_styles">
            <ComponentLoader />
          </div>
        )}
      <div className="EditorHeaderButtonDiv">
        <ButtonGroup variant="contained">
          {PAINT_OPTIONS.map(({ id, label, icon }: PaintOption) => (
            <div className="iconStyle" key={id}>
              <Button
                aria-label={label}
                onClick={() => setDrawAction(id)}
                startIcon={icon}
                className={id === drawAction ? 'outlinedColor' : 'containedColor'}
              />
            </div>
          ))}
          <ColorSketchPicker
            color={color}
            onChangeComplete={(selectedColor: string) => setColor(selectedColor)}
          />
          <IconButton aria-label="Clear" onClick={onClear}>
            <CloseTwoToneIcon />
          </IconButton>
        </ButtonGroup>
        <div className="EditorDeleteBtn">
          <Button
            startIcon={<DeleteForeverTwoToneIcon />}
            variant="contained"
            color="error"
            onClick={handleDelete}
            size="small"
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="konvaDiv">
        <div className="editorStateStyle">
          <Stage
            // width={WIDTH - 2}
            // height={HEIGHT - 2}
            width={image?.naturalWidth || 1 - 2}
            height={image?.naturalHeight || 1 - 2}
            ref={stageRef}
            onMouseUp={onStageMouseUp}
            onTouchEnd={onStageMouseUp}
            onMouseDown={(e) => handleMouseDown(e)}
            onTouchStart={(e) => handleMouseDown(e)}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
          >
            <Layer>
              <KonvaRect
                x={0}
                y={0}
                // height={HEIGHT - 2}
                // width={WIDTH - 2}
                height={image?.naturalHeight || 1 - 2}
                width={image?.naturalWidth || 1 - 2}
                fill="white"
                id="bg"
              />
              {image && (
                <KonvaImage
                  ref={diagramRef}
                  image={image}
                  x={0}
                  y={0}
                  // height={HEIGHT - 100}
                  // width={WIDTH - 100}
                  height={image?.naturalHeight || 1}
                  width={image?.naturalWidth || 1}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                // draggable={isDraggable}
                />
              )}
              {rectangles?.map((rectangle) => (
                <KonvaRect
                  key={rectangle.id}
                  x={rectangle?.x}
                  y={rectangle?.y}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  height={rectangle?.height}
                  width={rectangle?.width}
                  stroke={rectangle?.color}
                  id={rectangle?.id}
                  strokeWidth={4}
                  draggable={isDraggable}
                />
              ))}
              {circles?.map((circle) => (
                <KonvaCircle
                  key={circle.id}
                  id={circle.id}
                  x={circle?.x}
                  y={circle?.y}
                  radius={circle?.radius}
                  stroke={circle?.color}
                  strokeWidth={4}
                  draggable={isDraggable}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                />
              ))}
              {scribbles.map((scribble) => (
                <KonvaLine
                  key={scribble.id}
                  id={scribble.id}
                  lineCap="round"
                  lineJoin="round"
                  stroke={scribble?.color}
                  strokeWidth={4}
                  points={scribble.points}
                  name={DrawAction.Scribble}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  draggable={isDraggable}
                />
              ))}
              {arrows.map((arrow) => (
                <KonvaArrow
                  key={arrow.id}
                  id={arrow.id}
                  points={arrow.points}
                  fill={arrow.color}
                  stroke={arrow.color}
                  strokeWidth={4}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  draggable={isDraggable}
                />
              ))}
              {texts.map((text) => (
                <KonvaText
                  key={text.id}
                  id={text.id}
                  x={text.x}
                  y={text.y}
                  text={text.text}
                  fontSize={20}
                  fill={text.color}
                  draggable={isDraggable}
                  onDblClick={(e) => onTextDblClick(e, transformerRef, setTexts)}
                  onDblTap={(e) => onTextDblClick(e, transformerRef, setTexts)}
                  onClick={(e) => onTextClick(e, transformerRef)}
                  onTap={(e) => onTextClick(e, transformerRef)}
                  ref={textRef}
                />
              ))}
              <Transformer ref={transformerRef} />
            </Layer>
          </Stage>
        </div>
        <div className="editorSave">
          <Button
            startIcon={<SaveIcon />}
            color="secondary"
            disabled={buttonDisable}
            className="button_save_and_next"
            onClick={onExportClick}
            size="small"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
});

export default ImagePaint;
