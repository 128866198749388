/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../../../Common/NetworkOps';
import {
  GetAddOilKitResponseFormat, GetOilKitsListPayload, GetResponseFormat, GetSearchResponseFormat,
} from '../utils/TypeOilKits.d';
import {
  getOilKitsListData, getSearchOilKit, gotOilKitsListData, gotOilKitsListDataFailure, gotSearchOilKit, gotSearchOilKitFailure, OilKitsActions, onDeletedListKit, onDeleteListKit,
} from './oilKitsSlice';
import { showSuccessToaster, showErrorToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';

// Get Oil Kits
async function getOilKitsData(data: GetOilKitsListPayload): Promise<GetResponseFormat> {
  const {
    page,
    rowsPerPage,
    // type,
    fromDate,
    toDate,
    // searchQuery,
    searchItem,
  } = data;
  const url = `${Config.oilKits.getOilKitsList}?pageNumber=${page}&pageSize=${rowsPerPage}&Type=2&FromDate=${fromDate}&ToDate=${toDate}&searchItem=${searchItem}`;
  const result = await makeGetRequest<GetResponseFormat>(url);
  return result.data;
}

export const getOilKitsListEpic = (action$ : Observable<OilKitsActions>) => action$.pipe(
  ofType(getOilKitsListData.type),
  map((x) => x.payload),
  mergeMap((data: GetOilKitsListPayload) => from(getOilKitsData(data)).pipe(
    map((res: GetResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotOilKitsListData(res?.BMT?.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return gotOilKitsListDataFailure();
    }),
    takeUntil(action$.pipe(filter(getOilKitsListData.match))),
    catchError((error) => of(gotOilKitsListDataFailure(error))),
  )),
);

async function getSearchOilKitsData(data: string): Promise<GetSearchResponseFormat> {
  const url = `${Config.oilKits.searchKit}?Type=2&KitId=${data}`;
  const result = await makeGetRequest<GetSearchResponseFormat>(url);
  return result.data;
}

export const getOilKitsSearchEpic = (action$ : Observable<OilKitsActions>) => action$.pipe(
  ofType(getSearchOilKit.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getSearchOilKitsData(data)).pipe(
    map((res: GetSearchResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotSearchOilKit(res?.BMT?.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return gotSearchOilKitFailure();
    }),
    takeUntil(action$.pipe(filter(getSearchOilKit.match))),
    catchError((error) => of(gotSearchOilKitFailure(error))),
  )),
);

async function deleteOilKitsData(data: string | undefined): Promise<GetAddOilKitResponseFormat> {
  const url = `${Config.oilKits.deleteKit}?Type=2&KitId=${data}`;
  const result = await makeDeleteRequest<GetAddOilKitResponseFormat>(url);
  return result.data;
}

export const deleteOilKitsEpic = (action$ : Observable<OilKitsActions>) => action$.pipe(
  ofType(onDeleteListKit.type),
  map((x) => x.payload),
  mergeMap((data: string | undefined) => from(deleteOilKitsData(data)).pipe(
    map((res: GetAddOilKitResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return onDeletedListKit();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onDeletedListKit();
    }),
    takeUntil(action$.pipe(filter(onDeleteListKit.match))),
    catchError((error) => of(gotSearchOilKitFailure(error))),
  )),
);
