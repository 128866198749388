/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import {
  AddDefectCodeList, AddDefectCodeListData, DefectCodeAddData, UpdateDefectCodeListData,
} from '../utils/types';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { DefectCodeListFormValue } from '../utils/ConstantsDefectCode';
import { RootState } from '../../../../redux/rootState';
import {
  addDefectCodeListStart, resetDefectCodeStates, resetState, updateDefectCodeList,
} from '../redux/sliceDefectCodeList';
import RequiredFiled from '../../../../utils/RequiredField';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { getColorDataStart } from '../../AnalystDefectMenu/redux/sliceDefectCode';
import { DefectCodeValue, defectCodeSchema } from '../utils/helper';
import { Category } from '../../AnalystDefectMenu/utils/TypeAnalystMenu';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentDefectCodeDialog({
  show, setOpenFrom, rowId, handleData,
}: TypesDialogBox): JSX.Element {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const { isSuccess, DefectCodeListData } = useSelector((state: RootState) => state.ListDefectCodeReducer);
  const { categories } = useSelector((state: RootState) => state.defectCodeReducer);

  const dispatch = useDispatch();

  const {
    formState: { errors }, control, handleSubmit, setValue, reset, getValues,
  } = useForm<AddDefectCodeListData>({
    defaultValues: DefectCodeValue,
    resolver: yupResolver(defectCodeSchema as ObjectSchema<AddDefectCodeListData>),
  });

  const handleCategorySelect = (value: string) => {
    const category = categories?.find((item) => Number(item.Value) === Number(value));
    if (category?.SubCategories.length === 0) {
      setSelectedCategory(null);
      setValue(DefectCodeListFormValue.SubCategory, '');
    }
    setValue(DefectCodeListFormValue.SubCategory, '');
    setSelectedCategory(category || null);
  };

  const onSubmit = (data: AddDefectCodeListData) => {
    if (rowId) {
      const updateData: UpdateDefectCodeListData = {
        Type: 2,
        Id: rowId,
        ParentId: 0,
        TypeId: 0,
        Value: '',
        Code: data.Code,
        Color: data.Color,
        Description: data.Description,
        CategoryId: data.Category,
        SubCategoryId: data.SubCategory,
        ActionRequired: data.ActionCheckBox,
      };
      dispatch(updateDefectCodeList(updateData));
      setOpenFrom();
    } else {
      const bodyData: AddDefectCodeList = {
        Type: 2,
        Id: 0,
        ParentId: 0,
        TypeId: 0,
        Value: '',
        Code: data.Code,
        Color: data.Color,
        Description: data.Description,
        CategoryId: data.Category,
        SubCategoryId: data.SubCategory,
        ActionRequired: data.ActionCheckBox,
      };
      dispatch(addDefectCodeListStart(bodyData));
      setOpenFrom();
    }
  };

  const tableData: DefectCodeAddData[] = useMemo(() => DefectCodeListData?.Data?.map((item) => {
    const transformedDataItem = {
      code: item?.Code,
      categoryId: item?.CategoryId,
      sub_categoryId: item?.SubCategoryId,
      color: item?.Color,
      description: item?.Description,
      actionCheckBox: item?.ActionRequired,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [DefectCodeListData]);

  useEffect(() => {
    if (rowId) {
      tableData?.forEach((rowData) => {
        if (rowData?.id === rowId) {
          setValue(DefectCodeListFormValue.Code, rowData.code);
          setValue(DefectCodeListFormValue.Category, rowData.categoryId);
          setValue(DefectCodeListFormValue.SubCategory, rowData.sub_categoryId);
          setValue(DefectCodeListFormValue.Color, rowData?.color);
          setValue(DefectCodeListFormValue.ActionCheckBox, rowData.actionCheckBox);
          setValue(DefectCodeListFormValue.Description, rowData.description);
        }
      });
    }
    return () => {

    };
  }, [rowId, show]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      if (handleData) {
        handleData();
      }
      dispatch(resetState());
    }
  }, [isSuccess]);

  const handleClose = () => {
    setOpenFrom();
    setTimeout(() => {
      reset();
    }, 400);
  };

  useEffect(() => {
    dispatch(getColorDataStart());
    return () => {
      dispatch(resetDefectCodeStates());
    };
  }, [dispatch]);

  const getAnalystHeading = () => {
    if (rowId) {
      return 'Edit Defect Code';
    }
    return 'Add Defect Code';
  };

  useEffect(() => {
    const value = getValues(DefectCodeListFormValue.Category);
    const category = categories?.find((item) => Number(item.Value) === Number(value));
    if (category?.SubCategories.length === 0) {
      setSelectedCategory(null);
      setValue(DefectCodeListFormValue.SubCategory, '');
    }
    setSelectedCategory(category || null);
  }, [rowId, show]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle className="modalHeaderTitle">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {getAnalystHeading()}
              <div className="rightSideContent">
                <CustomCheckbox
                  name={DefectCodeListFormValue.ActionCheckBox}
                  control={control}
                  label="Action required"
                />
              </div>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent style={{ padding: '16px 24px' }}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Category
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    name={DefectCodeListFormValue.Category}
                    control={control}
                    handleChange={handleCategorySelect}
                    errors={errors}
                    size="small"
                    entries={categories}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Sub Category
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    name={DefectCodeListFormValue.SubCategory}
                    control={control}
                    errors={errors}
                    size="small"
                    entries={selectedCategory ? selectedCategory.SubCategories : []}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className={rowId ? 'label_Style_disabled' : 'label_Style_Customer'}>
                    Code
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    errors={errors}
                    isDisable={!!rowId}
                    inputProps={{ maxLength: 3 }}
                    className="nonCapitalizeField"
                    name={DefectCodeListFormValue.Code}
                    id={DefectCodeListFormValue.Code}
                    size="small"
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Color
                    <RequiredFiled />
                  </label>
                  <Controller
                    control={control}
                    name={DefectCodeListFormValue.Color}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="color"
                        onChange={(event) => {
                          onChange(event);
                        }}
                        value={value}
                        className="colorPickerStyle"
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Description
                    <RequiredFiled />
                  </label>
                  <Controller
                    control={control}
                    name={DefectCodeListFormValue.Description}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        value={value}
                        multiline
                        onChange={onChange}
                        error={Boolean(errors[DefectCodeListFormValue.Description])}
                        helperText={errors[DefectCodeListFormValue.Description]?.message}
                        rows={4}
                        maxRows={4}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}

export default ComponentDefectCodeDialog;
