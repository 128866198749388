/* eslint-disable max-len */
import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Grid,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styles from './graphStyle.module.scss';
import { AddPlaylist, DeleteIcon, EditIcon } from '../../assets/TableIcons';
import GraphInput from './components/GraphInput';
import { RootState } from '../../redux/rootState';
import { createPhaseChartStart, getPhaseChartByIdStart, updatePhaseChartStart } from '../VesselInformation/redux/sliceVesselInformation';
import {
  DataItem, LineGraphProps, PhaseChartFormInput, Point,
} from './utils/type';
import {
  PhaseChartDefaultValue, findPointsWithX, formatADDMainRequest, formatMainRequest, setValues,
  setVesselPhaseValues,
} from './utils/helper';
import { UpdatePhaseChartPayload } from '../VesselInformation/utils/type';
import ComponentGraphFields from './components/ComponentGraphFields';
import Chart from './components/ComponentChart';
import { showErrorToaster, showWarnToaster } from '../../Common/ComponentToast/ComponentSuccessToasts';

function LineChart({
  page, rowsPerPage, searchQuery, phaseId, setOpenFrom,
}: LineGraphProps) {
  const { phaseChartDataById, phaseChartAllData } = useSelector((state: RootState) => state.VesselInformationReducer);
  const { vesselMainData } = useSelector((state: RootState) => state.VesselMainReducer);

  const dispatch = useDispatch();
  const APIData = useMemo(() => phaseChartDataById?.map((item) => ({
    id: item.id,
    phase: Number(item.phase),
    percentage: Number(item.percentage),
  })), [phaseChartDataById]);
  const {
    handleSubmit, control,
    formState: { errors },
    setValue,
  } = useForm<PhaseChartFormInput>({
    defaultValues: PhaseChartDefaultValue,
  });
  const [verticalLineXValue, setVerticalLineXValue] = useState(0);
  const [data, setData] = useState<DataItem[]>([]);
  const [newPhase, setNewPhase] = useState<number>(0);
  const [newPercentage, setNewPercentage] = useState<number>(0);
  const [editId, setEditId] = useState<number | null>(null);

  const [addDataPhase, setAddDataPhase] = useState<number | string>('');
  const [addDataPercentage, setAddDataPercentage] = useState<number | string>('');
  const [maxCoordinates, setMaxCoordinates] = useState<Point | null>(null);
  const [lastPhase, setLastPhase] = useState<number>(0);
  const { jobOrderId, vesselId } = useParams();

  const newUpdatedData = [
    {
      id: 999999,
      phase: 0,
      percentage: 0,
    },
    ...data,
    {
      id: 99999999,
      phase: 180,
      percentage: 0,
    },
  ];

  const convertedData: Point[] = newUpdatedData?.map((item) => ({
    id: item.id,
    x: item.phase,
    y: item.percentage,
  }));

  useEffect(() => {
    if (APIData && phaseId) {
      setData(APIData);
      setValues(phaseChartAllData, setValue, vesselMainData);
    } if (phaseId === '') {
      setData([]);
      setVesselPhaseValues(vesselMainData, setValue);
    }
  }, [APIData, vesselMainData, phaseId]);

  useEffect(() => {
    if (phaseId) {
      const payload = {
        id: phaseId,
      };
      dispatch(getPhaseChartByIdStart(payload));
    }
  }, [phaseId]);

  useEffect(() => () => {
    setData([]);
    setVerticalLineXValue(0);
  }, []);

  const x = verticalLineXValue;
  const resultPoints = findPointsWithX(convertedData, x);

  const convertedPoints: { [key: string]: number }[] = resultPoints.map((point, index) => ({
    [`x${index + 1}`]: point?.x || 0,
    [`y${index + 1}`]: point?.y || 0,
  }));

  const x1 = convertedPoints[0]?.x1 || 0;
  const y1 = convertedPoints[0]?.y1 || 0;
  const x2 = convertedPoints[1]?.x2 || 0;
  const y2 = convertedPoints[1]?.y2 || 0;

  const calculatedY = y2 - (((x2 - x) * (y2 - y1)) / (x2 - x1));

  const updateLastPhase = () => {
    const maxPhase = data.reduce((max, item) => (item.phase > max ? item.phase : max), 0);
    setLastPhase(maxPhase);
  };

  useEffect(() => {
    updateLastPhase();
  }, [data]);

  const findPreviousAndNextPhases = (currentPhaseIndex: number) => {
    const previousPhase = currentPhaseIndex > 0 ? data[currentPhaseIndex - 1].phase : -Infinity;
    const nextPhase = currentPhaseIndex < data.length - 1 ? data[currentPhaseIndex + 1].phase : Infinity;
    return { previousPhase, nextPhase };
  };

  const handleAddData = () => {
    const newPhaseValue = Number(addDataPhase);
    if (newPhaseValue < lastPhase) {
      showErrorToaster('Phase value must be greater than the previous phase value.');
      return;
    }
    const newDataItem: DataItem = {
      id: data.length + 1,
      phase: newPhaseValue,
      percentage: Number(addDataPercentage),
    };
    setData([...data, newDataItem]);
    setAddDataPhase('');
    setAddDataPercentage('');
  };

  const handleSaveEdit = (id: number) => {
    const currentPhaseIndex = data.findIndex((item) => item.id === id);
    const { previousPhase, nextPhase } = findPreviousAndNextPhases(currentPhaseIndex);

    if (newPhase <= previousPhase || newPhase >= nextPhase) {
      showWarnToaster(`Phase value must be between ${previousPhase} and ${nextPhase}.`);
      return;
    }

    const updatedData = data.map((item) => (item.id === id ? { ...item, phase: newPhase, percentage: newPercentage } : item));
    setData(updatedData);
    setEditId(null);
  };

  const handleEdit = (id: number, phase: number, percentage: number) => {
    setEditId(id);
    setNewPhase(phase);
    setNewPercentage(percentage);
  };

  const handleDelete = (id: number) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
    setEditId(null);
  };

  const onSubmit = (values: PhaseChartFormInput) => {
    if (data.length === 0) {
      showWarnToaster('Please enter at least one coordinate.');
      return;
    }
    const getListPayload: UpdatePhaseChartPayload = {
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      rowsPerPage,
      searchQuery,
    };
    if (phaseId) {
      const payLoad = formatMainRequest(values, phaseChartAllData, data);
      dispatch(updatePhaseChartStart({ payLoad, getListPayload }));
      setOpenFrom();
    } else {
      const payLoad = formatADDMainRequest(values, vesselMainData, data);
      dispatch(createPhaseChartStart({ payLoad, getListPayload, setOpenFrom }));
      setOpenFrom();
    }
  };

  const handleInputChange = (newValue: string) => {
    setVerticalLineXValue(Number(newValue));
  };

  const handlePhaseInput = (newValue: string) => {
    setNewPhase(Number(newValue));
  };

  const handlePercentageChange = (newValue: string) => {
    setNewPercentage(Number(newValue));
  };

  const handleAddDataPhase = (newValue: string) => {
    setAddDataPhase(Number(newValue));
  };

  const handleAddDataPercentage = (newValue: string) => {
    setAddDataPercentage(Number(newValue));
  };

  const result = maxCoordinates
    && maxCoordinates?.y > calculatedY
    && maxCoordinates?.x > verticalLineXValue
    ? 'ID'
    : 'OD';

  return (
    <div className={styles.GraphContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <ComponentGraphFields control={control} errors={errors} />
          <Grid item xs={12} sm={6} md={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Defect Phase (degrees)</label>
              <GraphInput
                value={verticalLineXValue}
                onChange={handleInputChange}
                maxValue={180}
                regex="/[^0-9]/g"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Pct Wall Penetration
              </label>
              <div className={styles.pctWallStyle}>
                <span className={styles.pctWall}>{verticalLineXValue === 0 ? `${0}%` : `${parseFloat(calculatedY.toFixed(2))}%`}</span>
                <span>{result}</span>
              </div>
            </div>
          </Grid>
        </Grid>
        <Chart data={data} verticalLineXValue={verticalLineXValue} calculatedY={calculatedY} convertedData={convertedData} setMaxCoordinates={setMaxCoordinates} />
        <Table className={styles.GraphTableStyle}>
          <TableHead>
            <TableRow>
              <TableCell className={styles.wd_40}>Phase (degrees)</TableCell>
              <TableCell className={styles.wd_40}>% Wall Penetration</TableCell>
              <TableCell className={styles.wd_40}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(({ id, phase, percentage }) => (
              <TableRow key={id} className={styles.tableRowStyle}>
                <TableCell>
                  {id === editId ? (
                    <GraphInput
                      value={newPhase}
                      className={styles.otherInput}
                      onChange={handlePhaseInput}
                      maxValue={180}
                      regex="/[^0-9]/g"
                    />
                  ) : (
                    phase
                  )}
                </TableCell>
                <TableCell>
                  {id === editId ? (
                    <GraphInput
                      value={newPercentage}
                      className={styles.otherInput}
                      onChange={handlePercentageChange}
                      maxValue={100}
                      regex="/[^0-9]/g"
                    />
                  ) : (
                    percentage
                  )}
                </TableCell>
                <TableCell>
                  {id === editId ? (
                    <div className="smallDefectBtn">
                      <button className="themeBtnGreen" type="button" onClick={() => handleSaveEdit(id)}>Save</button>
                      <button className="themeBtnGray" type="button" onClick={() => setEditId(null)}>Cancel</button>
                    </div>
                  ) : (
                    <div className={styles.graphButtons}>
                      <span onClick={() => handleEdit(id, phase, percentage)} className={styles.cursorPointer}><EditIcon /></span>
                      <span className={styles.deleteButton} onClick={() => handleDelete(id)}><DeleteIcon /></span>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <GraphInput
                  value={addDataPhase}
                  className={styles.otherInput}
                  onChange={handleAddDataPhase}
                  maxValue={180}
                  regex="/[^0-9]/g"
                />
              </TableCell>
              <TableCell>
                <GraphInput
                  value={addDataPercentage}
                  className={styles.otherInput}
                  onChange={handleAddDataPercentage}
                  maxValue={100}
                  regex="/[^0-9]/g"
                />
              </TableCell>
              <TableCell>
                <span
                  className={styles.cursorPointer}
                  onClick={handleAddData}
                >
                  <AddPlaylist />
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <div className={styles.updateGraph}>
          <Button type="submit" className="button_save_and_next">{phaseId ? 'Update Graph' : 'Add Graph'}</Button>
        </div>
      </form>
    </div>
  );
}

export default LineChart;
