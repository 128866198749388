import { Control } from 'react-hook-form';
import { MasterData } from '../../../utils/type';

export interface RowDetails {
  isActive: boolean;
  x: number,
  y:number,
  colsVanished: number;
  rowsVanished: number;
}

export interface SelectedPoint {
  x: number;
  y: number;
}

export interface TubeChartDefects {
  Row: number;
  Tube: number;
  Color: string;
}

export enum TubeChartEnum {
  tubeSize = 'tubeSize',
  totalTubes = 'totalTubes',
  rowLength = 'rowLength',
  vesselTop = 'vesselTop',
  offset = 'offset',
  numberOfTube = 'numberOfTube',
  originalTestEnd = 'originalTestEnd',
  reserveTestEnd = 'reserveTestEnd',
}

export enum GridType {
  Normal = 'normal',
  LeftEccentric = 'leftEccentric',
  TopEccentric = 'topEccentric',
}

export interface TubeChartPanelValues {
  section: string;
  onClick: string;
  chartSize: string;
  tubeCount: string;
  size: string;
  zoom: boolean;
  totalTubeCount: string;
}

export enum VanishType {
  Left = 'Left',
  Right = 'Right',
  Top = 'Top',
  Down = 'Down',
  Individual = 'Individual',
}

export interface Circle {
  x: number;
  y: number;
  visible: boolean;
  undoAffected: number[];
  relX: number;
  relY: number;
  defectColor: string;
}

export enum RemoveType {
  Individual = 'Individual',
  Left = 'Left',
  Top = 'Top',
  Right = 'Right',
  Down = 'Down',
}

export enum TubeChartPanelFields {
  TubeSize = 'tubeSize',
  TubeCount = 'tubeCount',
  ChartSize = 'chartSize',
  DrawingSize = 'drawingSize',
  OnClick = 'onClick',
  VesselTop = 'vesselTop',
}

export interface ToggleAction {
  x: number;
  y: number;
  vanishType: VanishType;
}
export interface GetTubeChartPayload {
  VesselId: string;
  JobOrder: string;
  Type: number;
}

export interface PanelProps {
  handleUndo:() => void;
  handleSectionChange: (value:string) => void;
  tubeCount: number;
  totalTubeCount: number;
  isUndoDisable: boolean;
}

export interface TubeChartInterface {
  tubeSize: string,
  totalTubes: string,
  rowLength: string,
  vesselTop: string,
  offset: string,
  numberOfTube: string,
  originalTestEnd: string;
  reserveTestEnd: boolean,
}

export interface CircleInfo {
  Position: string;
  Stroke: string;
  Fill: string;
}

export interface RemovedConfigType {
  [key: string]: number[];
}

export interface TubeConfiguration {
  CircleInfos: CircleInfo[];
  IsOte: boolean;
  TubeSize: string;
  NumOfRows: string;
  RowLength: string;
  Panel: string;
  Group: string;
  Layout: string;
  IsTestEndChanges?: boolean;
  ChartId?: string;
  VesselId?: string;
  VesselTop: string;
  Offset: string;
  OriginalTestEnd: boolean;
  RemovedConfig: RemovedConfigType;
}

export interface UpdateTubeConfiguration {
  JobOrder: string;
  details: TubeConfiguration[];
}

export interface TubeChartConfiguration {
  Section: string;
  ChartId: string;
  Offset: string;
  NumberOfRows: string;
  RowLength: string;
  TubeCount: string;
  Group: string;
  VesselTop: string;
  OriginalTestEnd: boolean;
  TubeSize: string;
  Id: number;
}

export type CoOrdinates = {
  x: number;
  y: number;
};

export interface TubeDetailsProps {
  control: Control<TubeChartInterface>;
}

export interface TubeChartTableRow {
  id: string;
  section: string;
  offset: string;
  numberRows: string;
  rowLength: string;
  tubeCount: string;
}

export interface PolygonInterface {
  rows: number;
  columns: number;
  gridType: GridType;
  circles: Circle[][];
  history: ToggleAction[];
  totalVisible: number;
  isReverseEnd: boolean;

  generateNormalGrid(): Circle[][];
  getCircles(): Circle[][];
  toggleVisibility(x: number, y: number, vanishType: VanishType): void;
  shouldVanish(x: number, y: number, vanishType: VanishType, operationNumber:number, isUndo:boolean): void;
  getRelativeCoordinates(x: number, y: number): { relX: number, relY: number };
  undo(): Circle[][];
  populateData(tubeChartDetails:TubeConfiguration): void;
  assignRelativeValues(): void;
  displayDefects(defects:TubeChartDefects[]): void;
  // reverseEnd(): void;
}

export interface TubeChartGridProps {
  polygon: PolygonInterface;
  setPolyCircles: (val:Circle[][]) => void;
  polyCircles: Circle[][];
}

export interface TubeChartDefectsPayload {
  chartId: string;
  jobOrderId: string;
  vesselid: string;
}

export interface TubeChartInitialValues {
  tubeCharts: TubeChartConfiguration[];
  isLoading: boolean;
  vesselId: string;
  jobOrderId: string;
  gridType: GridType;
  vanishType: VanishType;
  relativeCord: CoOrdinates;
  chartList: TubeConfiguration[];
  sectionValues: MasterData[];
  selectedSection: string;
  scale: number;
  tubeChartDefects: TubeChartDefects[];
  selectedChartId: string;
}
