import React, { useEffect, useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../../../Common/DataTable';
import {
  Empty,
  InterfaceViewOilKitsData,
  TypesViewOilKitsComponentModal,
} from '../../utils/types';
import { ViewOilKitsColumns } from '../../../ScreenOilKits/utils/DataCustomerHeaderCell';
import { IconList } from '../../../../../../utils/StaticIcons';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import { getKitRangeList, onDeleteKit } from '../../redux/addOilKitsSlice';
import { RootState } from '../../../../../../redux/rootState';
import ComponentDeleteModal from '../../../../../../Common/DeleteModal/ComponentDeleteModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export default function ViewOilKitsComponent({
  kitId,
  show,
  setOpenFrom,
}: TypesViewOilKitsComponentModal): JSX.Element {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState<Empty>([]);
  const { translations } = useContext(LocalizationContext);
  const { kitRangeListData } = useSelector(
    (state: RootState) => state.addOilKitsReducer,
  );

  const handleData = () => {
    const reqBody = {
      page,
      searchQuery,
      kitId,
      rowsPerPage,
    };
    dispatch(getKitRangeList(reqBody));
  };

  useEffect(() => {
    if (show) {
      handleData();
    }
  }, [show]);

  useEffect(() => {
    if (kitRangeListData?.Data) {
      const viewOilData = kitRangeListData?.Data?.map((row: Empty, index: number) => ({
        ...row,
        kitId: index,
        id: row?.kitno,
        kitno: row?.kitno,
        isChecked: false,
      }));
      setData(viewOilData);
    }
  }, [kitRangeListData]);

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: Empty) => {
      if (element?.kitno === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  const handleDeleteModal = () => {
    const kitCheckedArray: Empty[] = [];
    data?.forEach((element: Empty) => {
      if (element?.isChecked) {
        kitCheckedArray.push(element?.kitno);
      }
    });
    const req = { KitNo: kitCheckedArray };
    dispatch(onDeleteKit(req));
    setDeleteModal(false);
    setOpenFrom();
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="viewOilKitsComponent">
            <div className="viewOilKitsComponent_heading">
              {translations.viewOilKIts}
            </div>
            <div
              className="viewOilKitsComponent_crossIcon"
              onClick={setOpenFrom}
            >
              <img src={IconList.closeIcon} alt="" />
            </div>
          </div>
          <div className="viewOilKitsComponent_dataTableStyle">
            <DataTable<InterfaceViewOilKitsData>
              data={data?.length > 0 ? data : []}
              columns={ViewOilKitsColumns}
              totalRecords={kitRangeListData.TotalRecords}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
              checkBoxWithKey={['kitId']}
              handleCheckBoxWithKey={handleCheckBoxWithKey}
            />
          </div>
          {data.length > 0 && (
            <div className="viewOilKitsComponent_saveAndNextDiv">
              <div>
                <Button
                  onClick={setOpenFrom}
                  className="viewOilKitsComponent_buttonCancel"
                >
                  {translations.cancel}
                </Button>
              </div>
              <div className="viewOilKitsComponent_buttonMarginLeft">
                <Button
                  onClick={() => setDeleteModal(true)}
                  className="viewOilKitsComponent_buttonDelete"
                >
                  {translations.delete}
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </div>
  );
}
