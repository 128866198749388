import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { ADD_INFO_TAB, MAIN_TAB } from '../utils/constants';
import MainInspectionSummary from './MainInspectionSummary';
import { RootState } from '../../../redux/rootState';
import { removeSelectedInspectionSummary, setSelectedTab } from '../redux/sliceInspectionSummary';
import AddInfoInspectionSummary from './AddInfoInspectionSummary';
import { setLoader } from '../../../redux/loaderSlice';
import { onGetCodeMasterData, onGetReportTypeMasterData } from '../../../redux/masters/masterSlice';

export default function TabsInspectionSummary() {
  const { selectedTab, isLoading } = useSelector((state:RootState) => state.inspectionSummaryReducer);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case MAIN_TAB:
        return <MainInspectionSummary />;
      default:
        return <AddInfoInspectionSummary />;
    }
  }

  useEffect(() => {
    dispatch(removeSelectedInspectionSummary());
    dispatch(onGetReportTypeMasterData());
    dispatch(onGetCodeMasterData());
  }, []);

  return (
    <>
      <ComponentPageHeader subHeading="Inspection Summary" />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          className="main_tabs"
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            className="tab_styles"
            label="Main"
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            value={MAIN_TAB}
          />
          <Tab
            className="tab_styles"
            label="Add’l Info"
            style={selectedTab === ADD_INFO_TAB ? selectedTabStyles : tabStyles}
            value={ADD_INFO_TAB}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>
  );
}
