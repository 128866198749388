import dayjs from 'dayjs';
import { InterfaceOilKitsMainFormValues } from './types';

export const MainDefaultValue: InterfaceOilKitsMainFormValues = {
  customerSearch: '',
  customer: '',
  contact: '',
  shipDate: dayjs(),
  shipVia: '',
  price: 0,
  additionalAmount: '',
  billAmount: '',
  invoice: '',
  pd: '',
  kitsNo: '',
  kitsStart: '',
  kitsEnd: '',
};
