import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { UserManagementTableColumns } from './utils/helper';
import DeactivateUserModal from './components/deactivateUserModalComponent/DeactivateUserModal';
import AddUserManagement from './components/addUserManagementComponent/AddUserManagement';
import { onActivateDeactivateUser, onGetUserManagementData } from './redux/sliceUserManagement';
import { setLoader } from '../../redux/loaderSlice';
import { RootState } from '../../redux/rootState';
import { UserManagementListingInterface } from './redux/type';
import { onGetRoleMasterData } from '../../redux/masters/masterSlice';

function UserManagement() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('');

  const { isLoading, userManagementListData } = useSelector((state: RootState) => state.userManagementReducer);

  const handleActiveDeactivate = (id: string) => {
    setSelectedId(id);
    setShowDeactivateModal(true);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(onGetUserManagementData(payload));
  };

  const handleCallback = () => {
    setShowDeactivateModal(false);
    handleData();
  };

  const handleDeleteModal = () => {
    if (selectedId) {
      const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === selectedId);
      const payload = {
        Id: selectedId,
        Status: selectedRow?.Status === 'Active' ? 'In-Active' : 'Active',
      };
      dispatch(onActivateDeactivateUser({ payload, callback: handleCallback }));
    }
  };

  const handleEdit = (id: string) => {
    setSelectedRowId(id);
    setShowUserModal(true);
  };

  useEffect(() => {
    handleData();
    dispatch(onGetRoleMasterData());
  }, []);

  const downloadExcel = () => {
    const exportedData = userManagementListData?.Data?.map((items, index) => ({
      SN: index + 1,
      UserId: items?.Email,
      Initials: items?.Initials,
      Role: items?.UserRole,
      Status: items?.Status,
    }));
    exportToExcelData(
      {
        data: exportedData?.length > 0 ? exportedData : [],
      },
      'USER MANAGEMENT',
    );
  };

  const tableData: UserManagementListingInterface[] = useMemo(() => {
    if (userManagementListData?.Data?.length > 0) {
      // eslint-disable-next-line arrow-body-style
      const transformedData = userManagementListData?.Data?.map((item, index) => {
        return {
          sn: index,
          action: 'edit&activeDeactivate',
          userId: item?.Email,
          designation: item?.Initials,
          role: item?.UserRole,
          status: item?.Status === 'Active' ? 'Active' : 'Inactive',
          id: item?.Id,
        };
      });
      return transformedData;
    }
    return [];
  }, [userManagementListData?.Data]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="USER MANAGEMENT" downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<UserManagementListingInterface>
            data={tableData}
            columns={UserManagementTableColumns}
            totalRecords={userManagementListData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleActiveDeactivate={handleActiveDeactivate}
            buttonText="Add new user"
            handleButtonClick={() => {
              setSelectedRowId('');
              setShowUserModal(true);
            }}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      {showDeactivateModal
        && (
          <DeactivateUserModal
            show={showDeactivateModal}
            setOpenFrom={() => setShowDeactivateModal(false)}
            handleDeactivate={handleDeleteModal}
            rowId={selectedId}
          />
        )}
      {showUserModal
        && (
          <AddUserManagement
            show={showUserModal}
            setOpenFrom={() => setShowUserModal(false)}
            rowId={selectedRowId}
            handleCallback={() => {
              setShowUserModal(false);
              handleData();
              dispatch(onGetRoleMasterData());
            }}
          />
        )}
    </>
  );
}

export default UserManagement;
