/* eslint-disable import/no-cycle */
import React from 'react';
import Modal from '@mui/material/Modal';
import { ComponentAllSites } from './ComponentAllSites';
import { SiteSelectModal } from '../utils/types';
// eslint-disable-next-line import/no-cycle

export default function ComponentSitesModel({
  show, setShow, handleTabCell,
}: SiteSelectModal) {
  return (
    <div>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ComponentAllSites handleClose={setShow} handleTabCell={handleTabCell} />
      </Modal>
    </div>
  );
}
