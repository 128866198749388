import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DefaultListPayloadListing, PayloadTypeDeleteDefaultList,
  PayloadTypeGetDefaultList, DefaultListState, UpdateDefaultListData, UnitMasterResponse, MasterData, AddDefaultListDataAPI,
} from '../utils/types';

const initialState: DefaultListState = {
  DefaultListData: {} as DefaultListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
  styleType: [] as MasterData[],
  types: [] as MasterData[],
};

const defaultListSlice = createSlice({
  name: 'defaultList',
  initialState,
  reducers: {
    getDefaultListStart(state, _action: PayloadAction<PayloadTypeGetDefaultList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDefaultList(state, action: PayloadAction<DefaultListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        DefaultListData: action?.payload as DefaultListPayloadListing,
      };
    },
    failureDefaultList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateDefaultList(state, _action: PayloadAction<UpdateDefaultListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateDefaultList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateDefaultList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addDefaultListStart(state, _action: PayloadAction<AddDefaultListDataAPI>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddDefaultList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddDefaultList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteDefaultListStart(state, _action: PayloadAction<PayloadTypeDeleteDefaultList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteDefaultList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteDefaultList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // Get Unit Master
    getUnitMasterStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getUnitMasterSuccess(state, action:PayloadAction<UnitMasterResponse>) {
      return {
        ...state,
        styleType: action.payload.Style,
        types: action.payload.Type,
        isLoading: false,
      };
    },
    getUnitMasterFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },

});

export const {
  getDefaultListStart, successDefaultList, failureDefaultList, updateDefaultList,
  successUpdateDefaultList, failureUpdateDefaultList, resetState, deleteDefaultListStart,
  successDeleteDefaultList, failureDeleteDefaultList,
  addDefaultListStart, successAddDefaultList, failureAddDefaultList, getUnitMasterStart, getUnitMasterSuccess, getUnitMasterFailure,
} = defaultListSlice.actions;
export const ListDefaultReducer = defaultListSlice.reducer;

export type DefaultTypeActions =
  | ReturnType<typeof getDefaultListStart>
  | ReturnType<typeof successDefaultList>
  | ReturnType<typeof failureDefaultList>

  | ReturnType<typeof updateDefaultList>
  | ReturnType<typeof successUpdateDefaultList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateDefaultList>

  | ReturnType<typeof deleteDefaultListStart>
  | ReturnType<typeof successDeleteDefaultList>
  | ReturnType<typeof failureDeleteDefaultList>

  | ReturnType<typeof addDefaultListStart>
  | ReturnType<typeof successAddDefaultList>
  | ReturnType<typeof failureAddDefaultList>

  | ReturnType<typeof getUnitMasterStart>
  | ReturnType<typeof failureAddDefaultList>
  | ReturnType<typeof failureAddDefaultList>;
