import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  getSysMarkupStart, successGetSysMarkup, failureGetSysMarkup,
  SytemMarkupActions, updateMarkups, successUpdateMarkups,
  failureUpdateMarkups, deleteMarkupsStart, successDeleteMarkup, failureDeleteMarkup, successAddMarkups, failureAdMarkups, addMarkupsStart,
} from './sliceMarkups';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest,
} from '../../../../Common/NetworkOps';
import {
  AddMarkupApiResponse,
  AddMarkupsData,
  DeleteMarkupApiResponse, GetAllSystemMarkupsApiRes, UpdateMarkupApiResponseTypes, UpdateMarkupsData,
} from '../utils/TypesMarkupsList';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';

interface PayloadTypeGetMarkups {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}
async function GetSystemMarkups(data: PayloadTypeGetMarkups): Promise<GetAllSystemMarkupsApiRes> {
  const url = `${Config.sysMarkups.getMarkups}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllSystemMarkupsApiRes>(url);
  return result?.data;
}

export const epicGetSystemMarkup = (action$: Observable<SytemMarkupActions>) => action$.pipe(
  filter(getSysMarkupStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetMarkups) => from(GetSystemMarkups(data)).pipe(
    map((res: GetAllSystemMarkupsApiRes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSysMarkup(res?.BMT.Result);
      }
      return failureGetSysMarkup(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSysMarkupStart.match))),
    catchError((error: AxiosError<GetAllSystemMarkupsApiRes>) => of(failureGetSysMarkup(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function UpdateSystemMarkups(data: UpdateMarkupsData): Promise<UpdateMarkupApiResponseTypes> {
  const url = `${Config.sysMarkups.updateMarkups}`;
  const result = await makePutRequest<UpdateMarkupApiResponseTypes>(url, data);
  return result?.data;
}

export const epicUpdateMarkups = (action$: Observable<SytemMarkupActions>) => action$.pipe(
  filter(updateMarkups.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateSystemMarkups(data)).pipe(
    mergeMap((res: UpdateMarkupApiResponseTypes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateMarkups());
      }
      return of(failureUpdateMarkups(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateMarkups.match))),
    catchError((error: AxiosError<UpdateMarkupApiResponseTypes>) => of(failureUpdateMarkups(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function AddSystemMarkups(data: AddMarkupsData): Promise<AddMarkupApiResponse> {
  const url = `${Config.sysMarkups.addMarkups}`;
  const result = await makePostRequest<AddMarkupApiResponse>(url, data);
  return result?.data;
}

export const epicAddMarkups = (action$: Observable<SytemMarkupActions>) => action$.pipe(
  filter(addMarkupsStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddSystemMarkups(data)).pipe(
    mergeMap((res: AddMarkupApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddMarkups());
      }
      return of(failureAdMarkups(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addMarkupsStart.match))),
    catchError((error: AxiosError<AddMarkupApiResponse>) => of(failureAdMarkups(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

interface PayloadTypeDeleteMarkup {
  Id: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

async function DeleteMarkup(data: PayloadTypeDeleteMarkup): Promise<DeleteMarkupApiResponse> {
  const url = `${Config.sysMarkups.deleteMarkups}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteMarkupApiResponse>(url);
  return result?.data;
}

export const epicDeleteMarkups = (action$: Observable<SytemMarkupActions>) => action$.pipe(
  filter(deleteMarkupsStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteMarkup) => from(DeleteMarkup(data)).pipe(
    mergeMap((res: DeleteMarkupApiResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteMarkup(), getSysMarkupStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteMarkup(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteMarkupsStart.match))),
    catchError((error: AxiosError<DeleteMarkupApiResponse>) => of(failureDeleteMarkup(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
