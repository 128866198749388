import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DefectCodeListPayloadListing, PayloadTypeDeleteDefectCodeList,
  PayloadTypeGetDefectCodeList, DefectCodeListState, UpdateDefectCodeListData, AddDefectCodeList,
} from '../utils/types';

const initialState: DefectCodeListState = {
  DefectCodeListData: {} as DefectCodeListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const defectCodeListSlice = createSlice({
  name: 'defectCodeList',
  initialState,
  reducers: {
    getDefectCodeListStart(state, _action: PayloadAction<PayloadTypeGetDefectCodeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDefectCodeList(state, action: PayloadAction<DefectCodeListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        DefectCodeListData: action?.payload as DefectCodeListPayloadListing,
      };
    },
    failureDefectCodeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateDefectCodeList(state, _action: PayloadAction<UpdateDefectCodeListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateDefectCodeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateDefectCodeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addDefectCodeListStart(state, _action: PayloadAction<AddDefectCodeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddDefectCodeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddDefectCodeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteDefectCodeListStart(state, _action: PayloadAction<PayloadTypeDeleteDefectCodeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteDefectCodeList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteDefectCodeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    resetDefectCodeStates(state) {
      return {
        ...state,
        DefectCodeListData: {} as DefectCodeListPayloadListing,
        isLoading: false,
        error: '',
        isSuccess: false,
      };
    },
  },

});

export const {
  getDefectCodeListStart, successDefectCodeList, failureDefectCodeList, updateDefectCodeList,
  successUpdateDefectCodeList, failureUpdateDefectCodeList, resetState, deleteDefectCodeListStart,
  successDeleteDefectCodeList, failureDeleteDefectCodeList,
  addDefectCodeListStart, successAddDefectCodeList, failureAddDefectCodeList, resetDefectCodeStates,
} = defectCodeListSlice.actions;
export const ListDefectCodeReducer = defectCodeListSlice.reducer;

export type DefectCodeTypeActions =
  | ReturnType<typeof getDefectCodeListStart>
  | ReturnType<typeof successDefectCodeList>
  | ReturnType<typeof failureDefectCodeList>

  | ReturnType<typeof updateDefectCodeList>
  | ReturnType<typeof successUpdateDefectCodeList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateDefectCodeList>

  | ReturnType<typeof deleteDefectCodeListStart>
  | ReturnType<typeof successDeleteDefectCodeList>
  | ReturnType<typeof failureDeleteDefectCodeList>

  | ReturnType<typeof addDefectCodeListStart>
  | ReturnType<typeof successAddDefectCodeList>
  | ReturnType<typeof failureAddDefectCodeList>
  | ReturnType<typeof resetDefectCodeStates>;
