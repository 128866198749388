import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasterData, PaginationResponse } from '../../../../../utils/type';
import {
  AddSitePayload,
  CreateJobPayload,
  DeleteBidPayload,
  GetAssocaitedBidPayload,
  GetAssociatedBidsResponse,
  GetAssociatedSites,
  GetJobByIdResponse,
  GetListingPayload, JobCommentsPayload, JobStarterInitialValue, SelectedSite, SiteListingDetails, UpdateJobPayload,
} from '../utils/types';
import { BidData, PayloadTypeJobBidsStart } from '../components/customerSiteInfo/addSiteModal/utils/Type';

const initialState: JobStarterInitialValue = {
  jobId: '',
  isLoading: false,
  associatedSites: [] as SiteListingDetails[],
  totalAssociatedSites: 0,
  totalgeneralSites: 0,
  generalSites: [] as SiteListingDetails[],
  selectedSite: {} as SelectedSite,
  siteTypes: [] as MasterData[],
  selectedtab: 1,
  selectedJob: {} as GetJobByIdResponse,
  bidList: [],
  totalBids: 0,
  jobBidsList: [] as BidData[],
  totalEntries: 0,
};

export const jobStarterSlice = createSlice({
  name: 'jobStarter',
  initialState,
  reducers: {
    resetJobStarterState() {
      return initialState;
    },
    // Set Selected Tab
    setSelectedTab(state, action:PayloadAction<number>) {
      return {
        ...state,
        selectedtab: action.payload,
      };
    },

    // Create Job
    createJobStart(state, _action:PayloadAction<CreateJobPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createJobSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        jobId: action.payload,
        selectedtab: 2,
      };
    },
    createJobFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Job By Id
    getJobByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getJobByIdSuccess(state, action:PayloadAction<GetJobByIdResponse>) {
      return {
        ...state,
        isLoading: false,
        selectedJob: action.payload,
        jobId: action.payload.JobInfo.JobId,
      };
    },
    getJobByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Update Job
    updateJobStart(state, _action:PayloadAction<UpdateJobPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateJobSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateJobFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Associated Sites
    getAssociatedSitesStart(state, _action:PayloadAction<GetAssociatedSites>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAssociatedSitesSuccess(state, action:PayloadAction<PaginationResponse<SiteListingDetails>>) {
      return {
        ...state,
        associatedSites: action.payload.Data,
        totalAssociatedSites: action.payload.TotalRecords,
        isLoading: false,
      };
    },
    getAssociatedSitesFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get General Sites
    getGeneralSitesStart(state, _action:PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getGeneralSitesSuccess(state, action:PayloadAction<PaginationResponse<SiteListingDetails>>) {
      return {
        ...state,
        generalSites: action.payload.Data,
        totalgeneralSites: action.payload.TotalRecords,
        isLoading: false,
      };
    },
    getGeneralSitesFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Site By Id
    getSiteByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getSiteByIdSuccess(state, action:PayloadAction<SelectedSite>) {
      return {
        ...state,
        isLoading: false,
        selectedSite: action.payload,
      };
    },

    getSiteByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Add Site
    addSiteStart(state, _action:PayloadAction<AddSitePayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addSiteSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addSiteFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Site Types
    getSiteTypesStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSiteTypesSuccess(state, action:PayloadAction<MasterData[]>) {
      return {
        ...state,
        siteTypes: action.payload,
        isLoading: false,
      };
    },
    getSiteTypesFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Add Comments
    addCommentStart(state, _action:PayloadAction<JobCommentsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addCommentSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addCommentFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Associated Bids
    getAssociatedBidsStart(state, _action:PayloadAction<GetAssocaitedBidPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAssociatedBidsSuccess(state, action:PayloadAction<PaginationResponse<GetAssociatedBidsResponse>>) {
      return {
        ...state,
        bidList: action.payload.Data,
        isLoading: false,
        totalBids: action.payload.TotalRecords,
      };
    },
    getAssociatedBidsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Delete Bid
    deleteBidStart(state, _action:PayloadAction<DeleteBidPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteBidSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteBidFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getJobBidsStart(state, _action: PayloadAction<PayloadTypeJobBidsStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successJobGetBids(state, action: PayloadAction<PaginationResponse<BidData>>) {
      return {
        ...state,
        isLoading: false,
        jobBidsList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureJobGetBids(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  createJobStart, createJobSuccess, createJobFailure, getAssociatedSitesFailure,
  getAssociatedSitesStart, getAssociatedSitesSuccess, getGeneralSitesFailure,
  getGeneralSitesSuccess, getGeneralSitesStart, getSiteByIdStart, getSiteByIdFailure,
  getSiteByIdSuccess, addSiteStart, addSiteSuccess, addSiteFailure, getSiteTypesStart,
  getSiteTypesSuccess, getSiteTypesFailure, addCommentStart, addCommentSuccess,
  addCommentFailure, setSelectedTab, getJobByIdFailure, getJobByIdStart, getJobByIdSuccess,
  updateJobFailure, updateJobStart, updateJobSuccess, resetJobStarterState, getAssociatedBidsStart,
  getAssociatedBidsSuccess, getAssociatedBidsFailure, deleteBidFailure, deleteBidStart, deleteBidSuccess,
  getJobBidsStart, successJobGetBids, failureJobGetBids,
} = jobStarterSlice.actions;
export const jobStarterReducer = jobStarterSlice.reducer;

export type JobStarterActions =
 ReturnType<typeof createJobStart> |
 ReturnType<typeof createJobSuccess> |
 ReturnType<typeof createJobFailure> |
 ReturnType<typeof getAssociatedSitesFailure> |
 ReturnType<typeof getAssociatedSitesStart> |
 ReturnType<typeof getAssociatedSitesSuccess> |
 ReturnType<typeof getGeneralSitesFailure> |
 ReturnType<typeof getGeneralSitesStart> |
 ReturnType<typeof getGeneralSitesSuccess> |
 ReturnType<typeof getSiteByIdFailure> |
 ReturnType<typeof getSiteByIdStart> |
 ReturnType<typeof getSiteByIdSuccess> |
 ReturnType<typeof addSiteFailure> |
 ReturnType<typeof addSiteStart> |
 ReturnType<typeof addSiteSuccess> |
 ReturnType<typeof getSiteTypesFailure> |
 ReturnType<typeof getSiteTypesStart> |
 ReturnType<typeof getSiteTypesSuccess> |
 ReturnType<typeof addCommentFailure> |
 ReturnType<typeof addCommentStart> |
 ReturnType<typeof addCommentSuccess> |
 ReturnType<typeof setSelectedTab> |
 ReturnType<typeof getJobByIdFailure> |
 ReturnType<typeof getJobByIdStart> |
 ReturnType<typeof getJobByIdSuccess> |
 ReturnType<typeof updateJobFailure> |
 ReturnType<typeof updateJobSuccess> |
 ReturnType<typeof updateJobStart> |
 ReturnType<typeof resetJobStarterState> |
 ReturnType<typeof getAssociatedBidsStart> |
 ReturnType<typeof getAssociatedBidsSuccess> |
 ReturnType<typeof getAssociatedBidsFailure> |
 ReturnType<typeof deleteBidFailure> |
 ReturnType<typeof deleteBidStart> |
 ReturnType<typeof deleteBidSuccess> |
 ReturnType<typeof getJobBidsStart> |
 ReturnType<typeof successJobGetBids> |
 ReturnType<typeof failureJobGetBids>;
