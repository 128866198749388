import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KitPriceListResult, UpdateKitPriceData } from '../utils/TypesKitPriceList';

interface KitPriceState {
  kitPriceList: KitPriceListResult[];
  isLoading: boolean;
  error: string;
}

const initialState: KitPriceState = {
  kitPriceList: [] as KitPriceListResult[],
  isLoading: false,
  error: '',
};

interface PayloadTypeDeleteKitPrice {
  Id: string;
  rowsPerPage: number;
  page: number;
}

const kitPriceListSlice = createSlice({
  name: 'kitPriceList',
  initialState,
  reducers: {
    getKitPriceStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetKitPrice(state, action: PayloadAction< KitPriceListResult[] >) {
      return {
        ...state,
        isLoading: false,
        kitPriceList: action?.payload as KitPriceListResult[],
      };
    },
    failureGetKitPrice(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateKitPrice(state, action: PayloadAction<UpdateKitPriceData>) {
      return {
        ...state,
        loading: true,
        KitPricesUpdateData: action.payload,
      };
    },
    successUpdateKitPrice(state) {
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    },
    failureUpdateKitPrice(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteKitPriceStart(state, _action: PayloadAction< PayloadTypeDeleteKitPrice >) {
      return {
        ...state,
        isLoading: true,
        deletingRow: _action.payload,
      };
    },
    successDeleteKitPrice(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteKitPrice(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  getKitPriceStart, successGetKitPrice, failureGetKitPrice, deleteKitPriceStart, successDeleteKitPrice, failureDeleteKitPrice,
  updateKitPrice, successUpdateKitPrice, failureUpdateKitPrice,
} = kitPriceListSlice.actions;
export const KitPriceReducer = kitPriceListSlice.reducer;

export type KitPriceListActions =
| ReturnType<typeof getKitPriceStart>
| ReturnType<typeof successGetKitPrice>
| ReturnType<typeof failureGetKitPrice>

| ReturnType<typeof updateKitPrice>
| ReturnType<typeof successUpdateKitPrice>
| ReturnType<typeof failureUpdateKitPrice>

| ReturnType<typeof deleteKitPriceStart>
| ReturnType<typeof successDeleteKitPrice>
| ReturnType<typeof failureDeleteKitPrice>;
