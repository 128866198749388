import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentMaterialListTable from './Components';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenMaterialList() {
  const { MaterialListData } = useSelector((state: RootState) => state.ListMaterialReducer);

  const excelData = useMemo(() => MaterialListData?.Data?.map((item) => {
    const transformedDataItems = {
      Description: item?.Description,
    };
    return transformedDataItems;
  }), [MaterialListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Unit_material_list',
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="UNIT MATERIAL LIST" downloadExcel={downloadExcel} />
      </Grid>
      <div className="whiteSpace" />
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentMaterialListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenMaterialList;
