import React, {
  forwardRef, useEffect, useMemo,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import RequiredFiled from '../../../../../../utils/RequiredField';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import CustomCheckbox from '../../../../../../Common/ComponentCustomCheckBox';
import { JobScheduleFormInfo, TypesDialogBox } from '../../../utils/types';
import { JobScheduleFields } from '../../../utils/constants';
import { getAnalystMasterStart } from '../../../../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import { RootState } from '../../../../../../redux/rootState';
import { onAddSchedule, onUpdateSchedule } from '../../redux/jobScheduleSlice';
import { CommonDateInput } from '../../../../../../Common/CommonDateInput';
import { addAnalystFormSchema } from '../../utils/validations';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogBoxAddAnalyst({
  show, setOpenFrom, unitId, unitEditData,
}: TypesDialogBox): JSX.Element {
  const {
    control, formState: { errors }, setValue, handleSubmit, watch,
  } = useForm<JobScheduleFormInfo>({
    resolver: yupResolver(addAnalystFormSchema as unknown as ObjectSchema<JobScheduleFormInfo>),
  });
  const dispatch = useDispatch();
  const { analystList } = useSelector((state: RootState) => state.bidsReducer);
  const onSubmit = (values: JobScheduleFormInfo) => {
    const formPayload = {
      AnalystId: values?.analyst || '',
      UnitId: unitId || '',
      StartDate: dayjs(values?.startDate).format('DD-MM-YYYY'),
      EndDate: dayjs(values?.endDate).format('DD-MM-YYYY'),
      PoNumber: values?.po || '',
      Notes: values?.notes || '',
      Equipment: !!values?.equipmentCheckBox,
      Flux: !!values?.fluxCheckBox,
      SmallJob: !!values?.smallJobCheckBox,
      DayBefore: !!values?.dayBeforeCheckBox,
      NightJob: !!values?.nightJobCheckBox,
      NextDay: !!values?.nextDayCheckBox,
      DoNotMove: !!values?.doNotMoveCheckBox,
    };
    if (unitEditData?.ScheduleDetails?.ScheduleId) {
      const updatePayload = {
        AnalystId: values?.analyst || '',
        UnitId: unitId || '',
        StartDate: dayjs(values?.startDate).format('DD-MM-YYYY'),
        EndDate: dayjs(values?.endDate).format('DD-MM-YYYY'),
        PoNumber: values?.po || '',
        Notes: values?.notes || '',
        Equipment: !!values?.equipmentCheckBox,
        Flux: !!values?.fluxCheckBox,
        SmallJob: !!values?.smallJobCheckBox,
        DayBefore: !!values?.dayBeforeCheckBox,
        NightJob: !!values?.nightJobCheckBox,
        NextDay: !!values?.nextDayCheckBox,
        DoNotMove: !!values?.doNotMoveCheckBox,
        ScheduleId: unitEditData?.ScheduleDetails?.ScheduleId ?? '',
      };
      // const updatePayload = {
      //   ...formPayload, ScheduleId: unitEditData?.ScheduleId || '',
      // };
      dispatch(onUpdateSchedule(updatePayload));
    } else {
      dispatch(onAddSchedule(formPayload));
    }
    setOpenFrom();
  };
  useEffect(() => {
    dispatch(getAnalystMasterStart());
  }, []);

  const resetValues = () => {
    setValue(JobScheduleFields.analyst, '');
    setValue(JobScheduleFields.po, '');
    setValue(JobScheduleFields.notes, '');
    setValue(JobScheduleFields.equipmentCheckBox, unitEditData?.IsEquipment);
    setValue(JobScheduleFields.fluxCheckBox, unitEditData?.IsFlux);
    setValue(JobScheduleFields.smallJobCheckBox, unitEditData?.IsSmallJob);
    setValue(JobScheduleFields.dayBeforeCheckBox, '');
    setValue(JobScheduleFields.nightJobCheckBox, '');
    setValue(JobScheduleFields.nextDayCheckBox, '');
    setValue(JobScheduleFields.doNotMoveCheckBox, '');
    setValue(JobScheduleFields.startDate, dayjs());
    setValue(JobScheduleFields.endDate, dayjs());
  };
  useEffect(() => {
    if (unitEditData?.ScheduleDetails?.AnalystId) {
      if (unitEditData?.ScheduleDetails.StartDate) {
        const StartDate = (dayjs(unitEditData?.ScheduleDetails.StartDate, 'DD-MM-YY'));
        if (StartDate) {
          setValue(JobScheduleFields.startDate, StartDate);
        }
      }
      if (unitEditData?.ScheduleDetails.EndDate) {
        const EndDate = (dayjs(unitEditData?.ScheduleDetails.EndDate, 'DD-MM-YY'));
        if (EndDate) {
          setValue(JobScheduleFields.endDate, EndDate);
        }
      }
      if (unitEditData?.ScheduleDetails?.AnalystId) {
        setValue(JobScheduleFields.analyst, unitEditData?.ScheduleDetails?.AnalystId);
      }
      setValue(JobScheduleFields.po, unitEditData?.ScheduleDetails?.PoNumber);
      setValue(JobScheduleFields.notes, unitEditData?.ScheduleDetails?.Notes);
      setValue(JobScheduleFields.dayBeforeCheckBox, unitEditData?.ScheduleDetails?.DayBefore);
      setValue(JobScheduleFields.nightJobCheckBox, unitEditData?.ScheduleDetails?.NightJob);
      setValue(JobScheduleFields.nextDayCheckBox, unitEditData?.ScheduleDetails?.NextDay);
      setValue(JobScheduleFields.doNotMoveCheckBox, unitEditData?.ScheduleDetails?.DoNotMove);
      setValue(JobScheduleFields.equipmentCheckBox, unitEditData?.ScheduleDetails?.Equipment);
      setValue(JobScheduleFields.fluxCheckBox, unitEditData?.ScheduleDetails?.Flux);
      setValue(JobScheduleFields.smallJobCheckBox, unitEditData?.ScheduleDetails?.SmallJob);
    } else {
      resetValues();
      if (unitEditData?.AnalystId) {
        setValue(JobScheduleFields.analyst, unitEditData?.AnalystId);
      }
    }
  }, [unitEditData, show]);

  const onCloseModal = () => {
    resetValues();
    setOpenFrom();
  };
  const startDate = watch(JobScheduleFields.startDate);
  const endDate = watch(JobScheduleFields.endDate);
  const buttonDisabled = endDate?.isBefore(startDate);

  const modalHeaderText = useMemo(() => {
    if (unitEditData?.ScheduleDetails?.AnalystId) {
      return 'Edit Schedule';
    }
    return 'Add Schedule';
  }, [show]);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={onCloseModal}
      >
        <DialogTitle className="dialogTitle_style">
          <div className="dialogTitle_style_div">
            <div className="customerInfo">{modalHeaderText}</div>
          </div>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent_style">
          <div className="dialogContent_style_div ">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={7} sm={12} xs={12}>
                  <Grid container spacing={2} className="rightBorder">
                    <Grid item md={10} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_Customer">
                          Analyst
                          <RequiredFiled />
                        </label>
                        <ComponentSelectInput
                          name={JobScheduleFields.analyst}
                          control={control}
                          errors={errors}
                          size="small"
                          required
                          entries={analystList}
                        />
                      </div>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          Start Date
                          <RequiredFiled />
                        </label>
                        <CommonDateInput
                          control={control}
                          errors={errors}
                          name={JobScheduleFields.startDate}
                        />
                      </div>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">
                          End Date
                          <RequiredFiled />
                        </label>
                        <CommonDateInput
                          control={control}
                          errors={errors}
                          name={JobScheduleFields.endDate}
                        />
                      </div>
                    </Grid>
                    <Grid item md={10} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">PO #</label>
                        <ComponentTextInput
                          control={control}
                          className="nonCapitalizeField"
                          name={JobScheduleFields.po}
                          size="small"
                          inputProps={{ maxLength: 10 }}
                          id={JobScheduleFields.po}
                          errors={errors}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={5} sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item md={8} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.equipmentCheckBox}
                        control={control}
                        label="Equipment"
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.nightJobCheckBox}
                        control={control}
                        label="Night Job"
                      />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.fluxCheckBox}
                        control={control}
                        label="Flux"
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.nextDayCheckBox}
                        control={control}
                        label="Next Day"
                      />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.smallJobCheckBox}
                        control={control}
                        label="Small job"
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.doNotMoveCheckBox}
                        control={control}
                        label="Do not Move"
                      />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12} className="rightSideContent">
                      <CustomCheckbox
                        name={JobScheduleFields.dayBeforeCheckBox}
                        control={control}
                        label="Day Before"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Notes</label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      name={JobScheduleFields.notes}
                      id={JobScheduleFields.notes}
                      multiline
                      inputProps={{ maxLength: 2056 }}
                      size="small"
                      rowSize={8}
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel" onClick={onCloseModal}>Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button disabled={buttonDisabled} type="submit" className="button_save_and_next">Save</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxAddAnalyst;
