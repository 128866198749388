/* eslint-disable max-len */
import React from 'react';

export function ArrowUpDownIcon() {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4.5L4 0.5L8 4.5H0Z" fill="#495057" />
      <path d="M0 7.5L4 11.5L8 7.5H0Z" fill="#495057" />
    </svg>
  );
}
