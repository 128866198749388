/* eslint-disable max-len */
import React from 'react';

export function PresentationIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8889 9.33333V4H20V9.33333H12.8889ZM4 12.8889V4H11.1111V12.8889H4ZM12.8889 20V11.1111H20V20H12.8889ZM4 20V14.6667H11.1111V20H4Z" fill="#092245" />
    </svg>
  );
}
