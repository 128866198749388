import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import axios, { AxiosError } from 'axios';
import Config from '../../../Common/Config';
import { makeDeleteRequest, makeGetRequest, makePutRequest } from '../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  AddScannedReceiptApiResponse,
  ExpenseDeleteApiPayload, ExpenseDeleteApiResponse, ExpenseMainFormAPIValues, ExpenseReportFormDataById,
  ExpenseReportQueryPayload, ExpenseReportResponseFormat,
  ExpenseUpdateApiResponse,
  // ResultDataById,
} from '../utils/types';
import {
  AddScannedReceiptsImage,
  AddScannedReceiptsImageFailure,
  AddScannedReceiptsImageSuccess,
  deleteExpenseReportFailure, deleteExpenseReportStart, deleteExpenseReportSuccess, deleteScannedReceiptsFailure,
  deleteScannedReceiptsStart, deleteScannedReceiptsSuccess, ExpenseReportActions,
  getExpenseReportById,
  getExpenseReportData, getExpenseReportFailure, getExpenseReportFailureById, getExpenseReportSuccess,
  getExpenseReportSuccessById,
  getScannedReceipts,
  getScannedReceiptsFailure,
  getScannedReceiptsSuccess,
  updateExpenseReportFailure,
  updateExpenseReportStart,
  updateExpenseReportSuccess,
} from './expenseReportSlice';
import localStorageConstants from '../../../utils/LocalStorageConstants';

// Get Expense Report Data

async function getExpenseReportListData(data: ExpenseReportQueryPayload): Promise<ExpenseReportResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.expenseReportURL.getExpenseReport}?pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<ExpenseReportResponseFormat>(url);
  return result.data;
}

export const getExpenseReportDataEpic = (action$: Observable<ExpenseReportActions>) => action$.pipe(
  ofType(getExpenseReportData.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: ExpenseReportQueryPayload) => from(getExpenseReportListData(data)).pipe(
    map((res: ExpenseReportResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getExpenseReportSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getExpenseReportFailure();
    }),
    takeUntil(action$.pipe(filter(getExpenseReportData.match))),
    catchError((error) => of(getExpenseReportFailure(error))),
  )),
);

// Get Scanned Receipts Data

async function getScannedReceiptsData(data: ExpenseReportQueryPayload): Promise<ExpenseReportResponseFormat> {
  const {
    page, rowsPerPage, searchQuery, jobOrderId,
  } = data;
  // eslint-disable-next-line max-len
  const url = `${Config.expenseReportURL.getExpenseReceiptList}?JobOrder=${jobOrderId}&pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery || ''}`;
  const result = await makeGetRequest<ExpenseReportResponseFormat>(url);
  return result.data;
}

export const getScannedReceiptsEpic = (action$: Observable<ExpenseReportActions>) => action$.pipe(
  ofType(getScannedReceipts.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: ExpenseReportQueryPayload) => from(getScannedReceiptsData(data)).pipe(
    map((res: ExpenseReportResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getScannedReceiptsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getScannedReceiptsFailure();
    }),
    takeUntil(action$.pipe(filter(getScannedReceipts.match))),
    catchError((error) => of(getScannedReceiptsFailure(error))),
  )),
);

// Delete Expense Report by ID

async function deleteExpenseReport(data: ExpenseDeleteApiPayload): Promise<ExpenseDeleteApiResponse> {
  const url = `${Config.expenseReportURL.deleteExpenseReport}/${data.id}`;
  const result = await makeDeleteRequest<ExpenseDeleteApiResponse>(url);
  return result.data;
}

export const deleteExpenseReportEpic = (action$ : Observable<ExpenseReportActions>) => action$.pipe(
  ofType(deleteExpenseReportStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: ExpenseDeleteApiPayload) => from(deleteExpenseReport(data)).pipe(
    mergeMap((res: ExpenseDeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
        };
        return of(deleteExpenseReportSuccess(), getExpenseReportData(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteExpenseReportFailure());
    }),
    takeUntil(action$.pipe(filter(deleteExpenseReportStart.match))),
    catchError((error) => of(deleteExpenseReportFailure(error))),
  )),
);

// Delete Scanned Receipts By ID

async function deleteScannedReceipts(data: ExpenseDeleteApiPayload): Promise<ExpenseDeleteApiResponse> {
  const url = `${Config.expenseReportURL.deleteExpenseReceipt}?id=${data.id}`;
  const result = await makeDeleteRequest<ExpenseDeleteApiResponse>(url);
  return result.data;
}

export const deleteScannedReceiptsEpic = (action$ : Observable<ExpenseReportActions>) => action$.pipe(
  ofType(deleteScannedReceiptsStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: ExpenseDeleteApiPayload) => from(deleteScannedReceipts(data)).pipe(
    mergeMap((res: ExpenseDeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data.page,
          jobOrderId: data.jobOrderId,
          searchQuery: data.searchQuery || '',
          rowsPerPage: data.rowsPerPage,
        };
        return of(deleteScannedReceiptsSuccess(), getScannedReceipts(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteScannedReceiptsFailure());
    }),
    takeUntil(action$.pipe(filter(deleteScannedReceiptsStart.match))),
    catchError((error) => of(deleteScannedReceiptsFailure(error))),
  )),
);

// Get Expense Report by Id

async function getExpenseReportDataById(data: { id: string }): Promise<ExpenseReportFormDataById> {
  const url = `${Config.expenseReportURL.getExpenseReportById}/${data.id}`;
  const result = await makeGetRequest<ExpenseReportFormDataById>(url);
  return result.data;
}

export const getExpenseReportByIdEpic = (action$: Observable<ExpenseReportActions>) => action$.pipe(
  ofType(getExpenseReportById.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: { id: string }) => from(getExpenseReportDataById(data)).pipe(
    map((res: ExpenseReportFormDataById) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getExpenseReportSuccessById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getExpenseReportFailureById();
    }),
    takeUntil(action$.pipe(filter(getExpenseReportById.match))),
    catchError((error) => of(getExpenseReportFailureById(error))),
  )),
);

// Update Expense Report

async function updateExpenseReport(data: ExpenseMainFormAPIValues): Promise<ExpenseUpdateApiResponse> {
  const url = `${Config.expenseReportURL.updateExpenseReport}`;
  const result = await makePutRequest<ExpenseUpdateApiResponse>(url, data);
  return result.data;
}
export const updateExpenseReportEpic = (action$ : Observable<ExpenseReportActions>) => action$.pipe(
  ofType(updateExpenseReportStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data:ExpenseMainFormAPIValues) => from(updateExpenseReport(data)).pipe(
    map((res: ExpenseUpdateApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updateExpenseReportSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateExpenseReportFailure();
    }),
    takeUntil(action$.pipe(filter(updateExpenseReportStart.match))),
    catchError((error) => of(updateExpenseReportFailure(error))),
  )),
);

async function addScannedReceiptsApi(data: FormData) {
  const imageFile = data.get('ImageFile');
  const jobOrder = data.get('JobOrder');
  const formData = new FormData();
  formData.append('ImageFile', imageFile || '');
  formData.append('JobOrder', jobOrder || '');
  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userID = localStorage.getItem(localStorageConstants.USER_ID);
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
      LoginUserId: userID,
    },
  };
  // const userId = await localStorage.getItem(localStorageConstants.USER_ID);
  const url = `${Config.expenseReportURL.addScannedReceiptsUrl}`;
  const result = await axios.post(url, formData, axiosConfig);
  return result.data;
}

export const epicAddScannedReceiptsData = (action$: Observable<ExpenseReportActions>) => action$.pipe(
  filter(AddScannedReceiptsImage.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(addScannedReceiptsApi(data)).pipe(
    mergeMap((res: AddScannedReceiptApiResponse) => {
      if (res?.BMT?.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data.get('page'),
          jobOrderId: data.get('JobOrder'),
          searchQuery: '',
          rowsPerPage: data.get('rowsPerPage'),
        };
        return of(AddScannedReceiptsImageSuccess(res?.BMT?.ResponseMessage), getScannedReceipts(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(AddScannedReceiptsImageFailure(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(AddScannedReceiptsImage.match))),
    catchError((error: AxiosError<AddScannedReceiptApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(AddScannedReceiptsImageFailure(errorMessage));
    }),
  )),
);
