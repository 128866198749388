import {
  Grid, Card,
} from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../../../redux/rootState';
import RequiredFiled from '../../../../../../utils/RequiredField';
import { EnumOilKitsCustomerInformationForm, TypeOilKitsIBillingInformation } from '../../utils/types';
import { setCustomerId } from '../../redux/addOilKitsSlice';

export default function BillingComponent({ control, setValue, errors }: TypeOilKitsIBillingInformation): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [price, setPrice] = useState(0);
  const dispatch = useDispatch();
  const [totalRange, setTotalRange] = useState<number>(0);
  const [totalKits, setTotalKits] = useState<number>(0);
  const [additionalAmount, setAdditionalAmount] = useState('');
  const { kitInputRange, kitDataById } = useSelector(
    (state: RootState) => state.addOilKitsReducer,
  );
  const { otherCustomerdetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const { oilKitPrice } = useSelector((state: RootState) => state.addOilKitsReducer);

  useEffect(() => {
    let range = 0;
    kitInputRange.forEach((item) => {
      const [start, end] = item.split('-').map((str) => parseInt(str, 10));
      range += Math.max(end - start + 1, 0);
    });
    const totalKitsRange = (totalKits + range) ?? 0;
    if (totalKitsRange) {
      setValue(EnumOilKitsCustomerInformationForm.kitsNo, (totalKits + range));
      setTotalRange(totalKits + range);
    }
  }, [kitInputRange]);

  useEffect(() => {
    if (oilKitPrice) {
      setValue(EnumOilKitsCustomerInformationForm.price, oilKitPrice);
      setPrice(oilKitPrice);
    }
  }, [oilKitPrice]);

  const totalBillAmount = Number(totalRange * Number(price)) + Number(additionalAmount);
  useEffect(() => {
    if (kitDataById?.NumberOfKits) {
      setTotalKits(kitDataById?.NumberOfKits);
    }
    if (totalRange) {
      setValue(EnumOilKitsCustomerInformationForm.kitsNo, totalRange);
    }
  }, [totalRange]);

  useEffect(() => {
    if (totalBillAmount) {
      setValue(EnumOilKitsCustomerInformationForm.billAmount, totalBillAmount);
    }
  }, [totalBillAmount]);

  useEffect(() => {
    if (otherCustomerdetails) {
      setValue(EnumOilKitsCustomerInformationForm.customer, otherCustomerdetails?.CompanyInformation?.CustomerName);
    }
  }, [otherCustomerdetails]);

  useEffect(() => {
    if (kitDataById?.CustId) {
      const oilKitShipDate = dayjs(new Date(kitDataById?.ShipDate));
      if (oilKitShipDate) {
        setValue(EnumOilKitsCustomerInformationForm.shipDate, oilKitShipDate);
      }
      dispatch(setCustomerId(kitDataById?.CustId));
      setTotalRange(kitDataById?.NumberOfKits);
      setAdditionalAmount(kitDataById?.AdditionalAmount);
      setValue(EnumOilKitsCustomerInformationForm.customerSearch, kitDataById?.CustId);
      setValue(EnumOilKitsCustomerInformationForm.price, kitDataById?.PerUnitPrice);
      setValue(EnumOilKitsCustomerInformationForm.invoice, kitDataById?.InvoiceNumber);
      setValue(EnumOilKitsCustomerInformationForm.additionalAmount, kitDataById?.AdditionalAmount);
      setValue(EnumOilKitsCustomerInformationForm.billAmount, kitDataById?.TotalAmount);
      setValue(EnumOilKitsCustomerInformationForm.pd, kitDataById?.PurchaseOrderNumber);
      setValue(EnumOilKitsCustomerInformationForm.shipVia, kitDataById?.ShipVia);
      setValue(EnumOilKitsCustomerInformationForm.contact, kitDataById?.ContactNumber);
      setValue(EnumOilKitsCustomerInformationForm.kitsNo, kitDataById?.NumberOfKits);
      setValue(EnumOilKitsCustomerInformationForm.customer, kitDataById?.CustId);
    }
    return () => {
      setValue(EnumOilKitsCustomerInformationForm.shipDate, dayjs());
    };
  }, [kitDataById]);

  return (
    <Card>
      <div className="billingInfo_container">
        <div className="billingInfo">
          {translations.billingOilKits}
        </div>
      </div>
      <div className="billingInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <div className="div_label">
                <label className="label_Disable">{translations.hashOilLits}</label>
                <RequiredFiled />
              </div>
              <ComponentTextInput
                control={control}
                isDisable
                name={EnumOilKitsCustomerInformationForm.kitsNo}
                id={EnumOilKitsCustomerInformationForm.kitsNo}
                size="small"
                inputProps={{ inputMode: 'numeric', min: 0, style: { textAlign: 'end' } }}
                required
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.priceOilKits}
                {' '}
                ($)
              </label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 5 }}
                name={EnumOilKitsCustomerInformationForm.price}
                id={EnumOilKitsCustomerInformationForm.price}
                size="small"
                regEx={/^[0-9]+$/}
                handleChange={(name, text) => setPrice(Number(text))}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.additionalAmtOilKits}
                {' '}
                ($)
              </label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 18 }}
                name={EnumOilKitsCustomerInformationForm.additionalAmount}
                id={EnumOilKitsCustomerInformationForm.additionalAmount}
                size="small"
                regEx={/^[0-9]+$/}
                handleChange={(name, text) => setAdditionalAmount(text)}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Disable">
                {translations.billAmountOilKits}
                {' '}
                ($)
              </label>
              <ComponentTextInput
                errors={errors}
                control={control}
                required
                isDisable
                regEx={/^[0-9]+$/}
                name={EnumOilKitsCustomerInformationForm.billAmount}
                id={EnumOilKitsCustomerInformationForm.billAmount}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.invoiceOilKits}
              </label>
              <ComponentTextInput
                errors={errors}
                control={control}
                inputProps={{ maxLength: 20 }}
                regEx={/^[A-Za-z0-9@#$%^&*()_+!-]+$/}
                name={EnumOilKitsCustomerInformationForm.invoice}
                id={EnumOilKitsCustomerInformationForm.invoice}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '5px' }} spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.PDOilKits}</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 20 }}
                name={EnumOilKitsCustomerInformationForm.pd}
                id={EnumOilKitsCustomerInformationForm.pd}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
