/* eslint-disable consistent-return */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';
import axios, { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import {
  TextField, InputAdornment, Grid, Button, Input,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { OilReportEmailResponse, TypeOilReportEmailModal } from './TypeOilReportEmailModal';
import './OilReportEmailModalStyle.scss';
import { DoubleArrowBlackIcon } from '../../assets/TableIcons/DoubleArrowBlackIcon';
import { DoubleArrowGreenIcon } from '../../assets/TableIcons/DoubleArrowGreenIcon';
import ComponentTextInput from '../ComponentTextinput/ComponentTextInput';
import { customOptions } from '../TextEditorOptions';
import { AttachmentIcon } from '../../assets/TableIcons/AttachmentIcon';
import { RootState } from '../../redux/rootState';
import { getCustomerContactList, getOilReportPdfDataStart } from '../../Screens/oilReport/redux/oilReportSlice';
import { CheckedIcon, SelectedCheckBoxIcon } from '../../assets/TableIcons';
import { ModalPagination } from '../../Screens/oilReport/components/pagination';
import localStorageConstants from '../../utils/LocalStorageConstants';
import Config from '../Config';
import { DEFAULT_DESCRIPTION } from './constant';
import { setLoader } from '../../redux/loaderSlice';
import { showErrorToaster, showSuccessToaster } from '../ComponentToast/ComponentSuccessToasts';

interface DataInterface {
  Name: string,
  Email: string,
  OilReport: boolean,
  Id: number,
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export default function ComponentOilReportEmailModal({
  show, setOpenFrom, onClickPreview, customerId, reportNumber = '',
}: TypeOilReportEmailModal): JSX.Element {
  const {
    control, setValue, getValues,
  } = useForm();
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<DataInterface[]>([]);
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [allReceiverEmails, setAllReceiverEmails] = useState<string[]>([]);
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const { CustomerContactList, isContactLoading } = useSelector((state: RootState) => state.oilReportReducer);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files && files.length > 0) {
      const selected = files[0];
      setSelectedFile(selected);
      showSuccessToaster('File attached successfully');
    }
  };

  // Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  useEffect(() => {
    dispatch(setLoader(isContactLoading || sendMailLoading));
  }, [isContactLoading, sendMailLoading]);

  const handleSendEmailError = (error: AxiosError<OilReportEmailResponse>) => {
    if (error?.response?.data?.BMT?.ResponseCode === 500) {
      setSendMailLoading(false);
      showErrorToaster(error?.response?.data?.BMT?.ResponseMessage);
    }
  };

  const onSendEmail = async () => {
    try {
      if (!selectedFile) {
        throw new Error('Selected file is required');
      }

      setSendMailLoading(true);

      const com = getHtmlFromEditorHTMLtoString(comment);
      const formData = new FormData();

      if (selectedFile) {
        formData.append('File', selectedFile);
      }

      formData.append('RecieverMail', getValues('to') ?? '');
      formData.append('Subject', getValues('Subject') ?? '');
      formData.append('Body', com ?? '');
      formData.append('CcMail', getValues('cc') ?? '');

      const token = localStorage.getItem(localStorageConstants.TOKEN);
      const userID = localStorage.getItem(localStorageConstants.USER_ID);

      if (!token || !userID) {
        throw new Error('Token or user ID is missing');
      }

      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
          LoginUserId: userID,
        },
      };

      const url = Config.oilReport.sendMail;

      const result = await axios.post(url, formData, axiosConfig);

      setSendMailLoading(false);
      showSuccessToaster(result?.data?.BMT?.ResponseMessage);

      return result.data;
    } catch (error) {
      handleSendEmailError(error as AxiosError<OilReportEmailResponse>);
    }
  };

  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const handleData = () => {
    const payload = {
      customerId, page, rowsPerPage, searchQuery,
    };
    dispatch(getCustomerContactList(payload));
  };

  useEffect(() => {
    handleData();
  }, [searchQuery]);

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    const contactData = CustomerContactList?.Data?.map((ele) => ({
      Name: `${ele?.FirstName} ${ele?.LastName}`,
      Email: ele?.Email,
      OilReport: ele?.OilReport,
      Id: ele?.ContactId,
    }));
    setData(contactData);
    if (CustomerContactList?.ToEmails?.length > 0) {
      setAllReceiverEmails(CustomerContactList?.ToEmails?.split(','));
    }
    if (CustomerContactList?.Data?.length > 0) {
      setValue('Subject', `Oil Report for ${CustomerContactList?.Data[0]?.CustomerName}`);
    }
    const convertedComment = htmlToEditorState(DEFAULT_DESCRIPTION);
    setComment(convertedComment);
  }, [CustomerContactList?.Data]);

  useEffect(() => {
    if (show) {
      if (allReceiverEmails?.length > 0) {
        setValue('to', allReceiverEmails?.join(', '));
        return;
      }
      setValue('to', '');
    }
  }, [allReceiverEmails, show]);

  const addReceiverEmail = (alreadyAdded = true, email: string, contactId: number) => {
    if (alreadyAdded) {
      return;
    }
    const checkedContactData = data?.map((ele) => {
      if (contactId && Number(ele?.Id) === contactId) {
        return {
          ...ele,
          OilReport: true,
        };
      }
      return ele;
    });
    setData(checkedContactData);
    setAllReceiverEmails([...allReceiverEmails, email]);
  };

  useEffect(() => {
    if (reportNumber !== '') {
      dispatch(getOilReportPdfDataStart(reportNumber));
    }
  }, [reportNumber]);

  return (
    <Modal
      open={!sendMailLoading && show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="customerInfo_container flex-row">
          <div className="customerInfo">
            Pdf Email System
          </div>
          <div onClick={setOpenFrom}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-fluid p-4 pb-0 customMobpad mob-pdfMailSystem">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="pdfEmailSystemContainer">
                <div className="CustomTableContainer wd-50">
                  <div className="CustomSearchTable">
                    <TextField
                      className="subHeaderTextfield"
                      size="small"
                      placeholder="Search..."
                      onChange={(e) => setSearchQuery(e?.target?.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div className="oilReportTableContainer">
                    <div className="oilReportTableHead mob-width-515">
                      <div className="headerTableCell wd-15 text-end">
                        Oil#
                      </div>
                      <div className="headerTableCell wd-25">
                        Name
                      </div>
                      <div className="headerTableCell wd-60">
                        Email
                      </div>
                    </div>
                    <div className="oilReportTableBody oilReportEmailBody noScroll mob-width-515">
                      <div className="oilReportTableList">
                        {data?.length > 0 && data?.map((element) => (
                          <div className="oilReportTableRowData">
                            <div className="oilReportTableCell wd-15 justify-content-end">
                              {element?.OilReport ? <SelectedCheckBoxIcon /> : <CheckedIcon />}
                            </div>
                            <div className="oilReportTableCell wd-25">
                              <div>{element?.Name}</div>
                            </div>
                            <div className="oilReportTableCell wd-60">
                              <div>{element?.Email}</div>
                              <div
                                className="actionButtons"
                                onClick={() => addReceiverEmail(element?.OilReport, element?.Email, element?.Id)}
                                style={{ cursor: element?.OilReport ? 'not-allowed' : 'pointer' }}
                              >
                                {element?.OilReport ? <DoubleArrowBlackIcon /> : <DoubleArrowGreenIcon />}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="customerInfo_main_container rightSideEmailBorderModal wd-50">
                  <div className="customScroll">
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className="label_Style_job">To</label>
                          <ComponentTextInput
                            control={control}
                            name="to"
                            id="to"
                            size="small"
                            className="nonCapitalizeField"
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className="label_Style_job">CC</label>
                          <ComponentTextInput
                            control={control}
                            name="cc"
                            id="cc"
                            className="nonCapitalizeField"
                            size="small"
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="div_label_text">
                          <label className="label_Style_job">Subject</label>
                          <ComponentTextInput
                            control={control}
                            name="Subject"
                            id="Subject"
                            size="small"
                            className="nonCapitalizeField"
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="editorContainer">
                          <div className="attachmentButton">
                            <Input
                              type="file"
                              inputProps={{ accept: '.pdf', style: { display: 'none' } }}
                              id="fileInputEmail"
                              onChange={handleFileChange}
                            />
                            <label htmlFor="fileInputEmail">
                              <Button variant="contained" component="span">
                                <AttachmentIcon />
                              </Button>
                            </label>
                          </div>
                          <Editor
                            editorState={comment}
                            onEditorStateChange={handleComment}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            localization={{ locale: 'en' }}
                            toolbar={customOptions}
                          />
                          <label>{selectedFile?.name}</label>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="save_and_next_div">
                        <div>
                          <Button onClick={onClickPreview} className="button_cancel">Preview</Button>
                        </div>
                        <div className="button_margin_left">
                          <Button onClick={onSendEmail} className={selectedFile ? 'button_save_and_next' : 'disableBtn'}>Send Email</Button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                </div>
              </div>
              <ModalPagination
                rowsPerPage={rowsPerPage}
                page={page}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                totalEntries={CustomerContactList?.TotalRecords}
                handleData={handleData}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
