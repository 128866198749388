import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import Config from '../../../../../Common/Config';
import { makeGetRequest, makePostRequest, makePutRequest } from '../../../../../Common/NetworkOps';
import {
  createCylinder, CreateFreonActions, editFreonFailure, editFreonStart, editFreonSuccess,
  failureAddFreon, failureUnitPrice, successAddFreon, successUnitPrice, unitPrice,
} from './sliceAddFreonCylinder';
import {
  BMTResponse, EditFreonResponseFormat, FreonDetails, GetFreonByIdPayload, PayloadAddFreonCylinderTypes, UpdateFreonApiResponse,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';
import { getFreonByIdStart } from '../../FreonCylinderList/redux/sliceListFreonCylinder';

async function sendFreonData(data: PayloadAddFreonCylinderTypes): Promise<UpdateFreonApiResponse> {
  const url = `${Config.freonCylinder.addFreon}`;
  const result = await makePostRequest<UpdateFreonApiResponse>(url, data);
  return result.data;
}

export const epicAddFreon = (action$: Observable<CreateFreonActions>) => action$.pipe(
  filter(createCylinder.match),
  debounceTime(250),
  map((x:CreateFreonActions) => x.payload),
  mergeMap((data) => from(sendFreonData(data as PayloadAddFreonCylinderTypes)).pipe(
    map((res: UpdateFreonApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return successAddFreon(res?.BMT?.ResponseMessage);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return failureAddFreon(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(createCylinder.match))),
    catchError((error: AxiosError<UpdateFreonApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      failureAddFreon(errorMessage);
      return of();
    }),
  )),
);

async function unitPriceData(): Promise<BMTResponse> {
  const url = `${Config.freonCylinder.unitPrice}`;
  const result = await makeGetRequest<BMTResponse>(url);
  return result.data;
}

export const epicUnitPrice = (action$: Observable<CreateFreonActions>) => action$.pipe(
  filter(unitPrice.match),
  debounceTime(250),
  map((x:CreateFreonActions) => x.payload),
  mergeMap(() => from(unitPriceData()).pipe(
    map((res: BMTResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successUnitPrice(res.BMT.Result.UnitPrice);
      }
      return failureUnitPrice(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(unitPrice.match))),
    catchError((error: AxiosError<BMTResponse>) => of(failureUnitPrice(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

// Edit Freon Cylinder
async function editFreon(data: FreonDetails): Promise<EditFreonResponseFormat> {
  const url = `${Config.freonCylinder.updateFreonCylinder}`;
  const result = await makePutRequest<EditFreonResponseFormat>(url, data);
  return result.data;
}

export const epicEditFreon = (action$ : Observable<CreateFreonActions>) => action$.pipe(
  ofType(editFreonStart.type),
  map((x) => x.payload),
  mergeMap((data: FreonDetails) => from(editFreon(data)).pipe(
    mergeMap((res: EditFreonResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:GetFreonByIdPayload = {
          KitId: data.KitId,
        };
        return of(editFreonSuccess(), getFreonByIdStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(editFreonFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(editFreonStart.match))),
    catchError((error) => of(editFreonFailure(error))),
  )),
);
