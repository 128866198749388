import React, { forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadTwoToneIcon from '@mui/icons-material/CloudDownloadTwoTone';
import { TypesScannedImageDialog } from '../utils/types';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogScannedImage({ show, setOpenImage, filePath }: TypesScannedImageDialog): JSX.Element {
  // Function to handle image download
  const [imgUrl, setImgUrl] = useState<string>('');

  const handleImageAction = () => {
    setOpenImage(!show);
    setTimeout(() => {
      setImgUrl('');
    }, 700);
  };

  useEffect(() => {
    if (show) {
      setImgUrl(filePath);
    }
  }, [filePath, show]);

  function toDataURL(url: string) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
  }

  async function downloadImage() {
    const a = document.createElement('a');
    a.href = await toDataURL(imgUrl);
    a.download = 'Image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <div className="ProfileImageDialog">
      <Dialog
        maxWidth="md"
        open={show}
        transitionDuration={600}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleImageAction}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="ProfileImageDialogButton">
          <DialogTitle>
            <IconButton
              aria-label="close"
              color="error"
              onClick={handleImageAction}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              color="success"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => downloadImage()}
              sx={{
                position: 'absolute',
                right: 8,
                bottom: 8,
              }}
            >
              <CloudDownloadTwoToneIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent className="image_container_style">
          <img src={imgUrl} alt="Profile Pic" width="100%" height="100%" />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogScannedImage;
