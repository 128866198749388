import {
  Grid, Card, TextField, Tooltip, IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { Controller } from 'react-hook-form';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import {
  BillingInfoForm, CustomerInformationForm, GetCustomerByIdPayload, IBillingInformation,
} from '../../utils/types';
import './StyleMainTab.scss';
import { RootState } from '../../../../redux/rootState';
import AddCustomerModal from '../../../../Common/AddCustomerModal';
import { getBillPinCodeDetailsStart, getCustomerByIdStart, removeBillPinCodeDetails } from '../../redux/addCustomerSlice';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import { RHFAutocompleteField } from '../../../../Common/ComponentAutoSelect';
import { CUSTOMER_BILLING_INFO_KEY } from '../../../AnalystScreen/AnalystAddScreen/utils/constants';

export default function BillingInformation({
  control, setValue, getValues, watch,
}: IBillingInformation): JSX.Element {
  const { countries, citySuggestions } = useSelector((state: RootState) => state.masterDataReducer);
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const { otherCustomerdetails, billingPinCodeDetails } = useSelector((state: RootState) => state.addCustomerReducer);
  const billingZip = watch(BillingInfoForm.billingZip);
  const billingCountry = watch(BillingInfoForm.billingCountry);

  const handleTabCell = (customerId: string) => {
    setShowModal(false);
    const payload: GetCustomerByIdPayload = {
      customerId,
      otherCustomer: true,
    };
    dispatch(getCustomerByIdStart(payload));
    setValue(BillingInfoForm.billToCorporate, customerId);
  };

  const sameAsAddress = watch(BillingInfoForm.sameCompanyAddress);

  useEffect(() => {
    setDisabled(sameAsAddress);
  }, [sameAsAddress]);

  useEffect(() => {
    if (sameAsAddress) {
      setValue(BillingInfoForm.billToCorporate, '');
    }
  }, [sameAsAddress]);

  useEffect(() => {
    if (billingZip && billingZip?.length >= 5) {
      dispatch(removeBillPinCodeDetails());
      setValue(BillingInfoForm.billingState, '');
      // setValue(BillingInfoForm.billingCity, '');
      const queryPayload = {
        pinCode: billingZip,
        countryCode: getValues(BillingInfoForm.billingCountry),
        key: CUSTOMER_BILLING_INFO_KEY,
      };
      dispatch(getBillPinCodeDetailsStart(queryPayload));
    } else if (billingZip?.length < 5) {
      dispatch(removeBillPinCodeDetails());
      setValue(BillingInfoForm.billingState, '');
    }
  }, [billingZip, billingCountry]);

  useEffect(() => {
    if (billingPinCodeDetails?.City && billingZip) {
      setValue(BillingInfoForm.billingState, billingPinCodeDetails.StateName);
    }
  }, [billingPinCodeDetails]);

  useEffect(() => {
    if (otherCustomerdetails?.CompanyInformation) {
      setValue(BillingInfoForm.billingAddress, otherCustomerdetails?.CompanyInformation?.Address);
      setValue(BillingInfoForm.billingOptionalAddress, otherCustomerdetails?.CompanyInformation?.OptionalAddress);
      setValue(BillingInfoForm.billingCountry, otherCustomerdetails?.CompanyInformation?.CountryCode);
      setValue(BillingInfoForm.billingZip, otherCustomerdetails?.CompanyInformation?.ZipCode);
      setValue(BillingInfoForm.billingState, otherCustomerdetails?.CompanyInformation?.StateName);
      setValue(BillingInfoForm.billingCity, otherCustomerdetails?.CompanyInformation?.CityName);
    }
  }, [otherCustomerdetails]);

  const handleChange = (checked: boolean) => {
    if (checked) {
      setValue(BillingInfoForm.billingAddress, getValues(CustomerInformationForm.address));
      setValue(BillingInfoForm.billingOptionalAddress, getValues(CustomerInformationForm.companyAddress));
      setValue(BillingInfoForm.billingCountry, getValues(CustomerInformationForm.companyCountry));
      setValue(BillingInfoForm.billingZip, getValues(CustomerInformationForm.companyZip));
      setValue(BillingInfoForm.billingState, getValues(CustomerInformationForm.companyState));
      setValue(BillingInfoForm.billingCity, getValues(CustomerInformationForm.companyCity));
    } else {
      setValue(BillingInfoForm.billingAddress, '');
      setValue(BillingInfoForm.billingOptionalAddress, '');
      setValue(BillingInfoForm.billingCountry, 'US');
      setValue(BillingInfoForm.billingZip, '');
      setValue(BillingInfoForm.billingState, '');
      setValue(BillingInfoForm.billingCity, '');
    }
  };

  const handleOnChange = (text: string, onChange: (event: string) => void, value: string) => {
    if (value?.length > 0) {
      return;
    }
    onChange('');
  };

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(BillingInfoForm.billingCity);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Billing Information
        </div>
        <div className="billing-checkbox customer_info_checkbox">
          <div className="customer_info_header_checkbox">
            <CustomCheckbox
              name={BillingInfoForm.sameCompanyAddress}
              control={control}
              handleChange={handleChange}
              label="Same as Company Address"
            />
            <CustomCheckbox
              name={BillingInfoForm.creditCardRequired}
              control={control}
              label="Credit Card Required"
            />
            <CustomCheckbox
              name={BillingInfoForm.approvedPO}
              control={control}
              label="Approved for PO"
            />
          </div>
          <div className="dropdown_container mob-pt-3">
            <div className="dropdown_side_label">
              <Controller
                name={BillingInfoForm.billToCorporate}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    size="small"
                    placeholder="Bill to Corporate"
                    value={value}
                    onClick={() => setShowModal(true)}
                    onChange={(text) => handleOnChange(text.currentTarget.value, onChange, value)}
                    disabled={disabled}
                    InputProps={{
                      endAdornment: !disabled ? (
                        <Tooltip title="Search here..">
                          <IconButton onClick={() => setShowModal(true)}>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ) : '',
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Address </label>
              <ComponentTextInput
                control={control}
                isDisable={disabled}
                inputProps={{ maxLength: 200 }}
                className="nonCapitalizeField"
                name={BillingInfoForm.billingAddress}
                id={BillingInfoForm.billingAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Address(Optional)</label>
              <ComponentTextInput
                control={control}
                isDisable={disabled}
                inputProps={{ maxLength: 200 }}
                className="nonCapitalizeField"
                name={BillingInfoForm.billingOptionalAddress}
                id={BillingInfoForm.billingOptionalAddress}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Country</label>
              <ComponentSelectInput
                name={BillingInfoForm.billingCountry}
                control={control}
                disabled={disabled}
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Zip</label>
              <ComponentTextInput
                control={control}
                isDisable={disabled}
                inputProps={{ maxLength: 10 }}
                className="nonCapitalizeField"
                name={BillingInfoForm.billingZip}
                id={BillingInfoForm.billingZip}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">State</label>
              <ComponentTextInput
                control={control}
                name={BillingInfoForm.billingState}
                id={BillingInfoForm.billingState}
                isDisable
                size="small"
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">City</label>
              {renderCityInput() ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  disabled={disabled}
                  name={BillingInfoForm.BILLING_OTHER_CITY}
                />
              )
                : (
                  <ComponentSelectInput
                    name={BillingInfoForm.billingCity}
                    defaultValue=""
                    control={control}
                    disabled={disabled}
                    size="small"
                    entries={billingPinCodeDetails?.City ? [...billingPinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>
        </Grid>
      </div>
      <AddCustomerModal
        show={showModal}
        setOpenFrom={() => setShowModal(false)}
        handleTabCell={handleTabCell}
      />
    </Card>
  );
}
