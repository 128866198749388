/* eslint-disable consistent-return */
import React, {
  forwardRef, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { JobScheduleFields } from '../../../utils/constants';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { JobScheduleFormInfo } from '../../../utils/types';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import CustomCheckbox from '../../../../../../Common/ComponentCustomCheckBox';
import { Empty } from '../../../../../ScreenAddSmc/Utils/TypeSmc';
import { RootState } from '../../../../../../redux/rootState';
import { onAddAccommodationSchedule, onUpdateAccommodationSchedule } from '../../redux/jobScheduleSlice';
import RequiredFiled from '../../../../../../utils/RequiredField';
import { TypesHotelEditBox } from '../ReportListingModal/Type';
import { getPinCodeDetailsStart, removePinCodeDetails } from '../../../../../../redux/masters/masterSlice';
import { setValuesForm } from '../../../utils/helper';
import { removeAnalystBaseOutPinCodeDetails } from '../../../../../AnalystScreen/AnalystAddScreen/redux/AddAnalystSlice';
import { extRegex, phoneRegex, RegexFiveWith2D } from '../../../../../../utils/regex';
import { RHFAutocompleteField } from '../../../../../../Common/ComponentAutoSelect';
import { CommonDateInput } from '../../../../../../Common/CommonDateInput';
import { hotelInfoSchema } from '../../utils/validations';
import { HotelDefaultData } from '../../utils/constants';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogBoxHotelEdit({
  show, setOpenFrom, onClickFind, hotelId, selectedRadio, setSelectedRadio,
}: TypesHotelEditBox): JSX.Element {
  const {
    control, handleSubmit, setValue, getValues, watch, trigger, formState: { errors },
  } = useForm<JobScheduleFormInfo>({
    resolver: yupResolver(hotelInfoSchema as Empty),
    defaultValues: HotelDefaultData as Empty,
  });
  const { countries, citySuggestions } = useSelector((state: RootState) => state.masterDataReducer);
  const { bidId, jobId } = useParams();
  const { hotelListData, jobTravelListData } = useSelector((state: RootState) => state.jobScheduleReducer);
  const { pinCodeDetails, isError } = useSelector((state: RootState) => state.masterDataReducer);
  const zipCode = watch(JobScheduleFields.hotelZip);
  const cCode = watch(JobScheduleFields.hotelCountry);
  const city = watch(JobScheduleFields.hotelCity);
  const dispatch = useDispatch();

  const dateIn = watch(JobScheduleFields.hotelDateIn);
  const dateOut = watch(JobScheduleFields.hotelDateOut);

  const buttonDisabled = dateOut?.isBefore(dateIn) || isError;

  const onSubmitHotelForm = (values: JobScheduleFormInfo) => {
    if (setSelectedRadio) {
      setSelectedRadio();
    }
    const payload = {
      JobCode: jobId,
      BidId: bidId,
      PhoneNumber: values?.phone || '',
      Hotel: values?.hotelMotel || '',
      Address: values?.hotelAddress || '',
      OptionalAddress: values?.hotelAddressOptional || '',
      ZipCode: values?.hotelZip || '',
      CityName: values.hotelCity === 'Other' ? values?.otherHotelCity : values.hotelCity,
      IsOtherCity: values?.hotelCity === 'Other',
      Countrycode: values?.hotelCountry || '',
      DateIn: dayjs(values?.hotelDateIn).format('DD-MM-YYYY') || '',
      DateOut: dayjs(values?.hotelDateOut).format('DD-MM-YYYY') || '',
      Price: values?.hotelPrice || 0,
      ConfirmationCode: values?.hotelConfirmation || '',
      Late: values?.late ?? false,
    };
    if (hotelId) {
      const updatePayload = {
        ...payload,
        HotelId: hotelId,
      };
      dispatch(onUpdateAccommodationSchedule(updatePayload));
    } else {
      dispatch(onAddAccommodationSchedule(payload));
    }
    setOpenFrom();
    setValuesForm(setValue, JobScheduleFields);
  };
  useEffect(() => {
    if (hotelId !== '' && selectedRadio === '') {
      const hotelTravelListData = jobTravelListData?.Data?.find((ele: Empty) => Number(ele?.HotelId) === Number(hotelId));
      if (hotelTravelListData && hotelTravelListData?.HotelName) {
        setValue(JobScheduleFields.hotelDateOut, dayjs(hotelTravelListData.DateOut, 'DD-MM-YY'));
        setValue(JobScheduleFields.hotelDateIn, dayjs(hotelTravelListData.DateIn, 'DD-MM-YY'));
        setValue(JobScheduleFields.hotelMotel, hotelTravelListData.HotelName);
        setValue(JobScheduleFields.hotelAddress, hotelTravelListData.Address);
        setValue(JobScheduleFields.hotelZip, hotelTravelListData.Zipcode);
        setValue(JobScheduleFields.phone, hotelTravelListData.PhoneNumber);
        setValue(JobScheduleFields.hotelCity, hotelTravelListData.CityName);
        setValue(JobScheduleFields.hotelState, hotelTravelListData.StateName);
        setValue(JobScheduleFields.hotelCountry, hotelTravelListData.CountryCode);
        setValue(JobScheduleFields.hotelAddressOptional, hotelTravelListData.OptionalAddress);
        setValue(JobScheduleFields.hotelConfirmation, hotelTravelListData.ConfirmationCode);
        setValue(JobScheduleFields.hotelPrice, hotelTravelListData.Price);
        setValue(JobScheduleFields.late, hotelTravelListData.Late);
      }
    } else if (selectedRadio) {
      const hotelRowData = hotelListData?.Data?.find((ele: Empty) => Number(ele?.HotelId) === Number(selectedRadio));
      if (hotelRowData && hotelRowData.HotelName) {
        setValue(JobScheduleFields.hotelMotel, hotelRowData.HotelName);
        setValue(JobScheduleFields.hotelAddress, hotelRowData.Address);
        setValue(JobScheduleFields.hotelZip, hotelRowData.Zipcode);
        setValue(JobScheduleFields.hotelCity, hotelRowData.CityName);
        setValue(JobScheduleFields.hotelState, hotelRowData.StateName);
        setValue(JobScheduleFields.hotelCountry, hotelRowData.CountryCode);
        setValue(JobScheduleFields.phone, hotelRowData.PhoneNumber);

        setValue(JobScheduleFields.hotelAddressOptional, hotelRowData?.OptionalAddress);
        setValue(JobScheduleFields.hotelConfirmation, hotelRowData?.ConfirmationCode);
        setValue(JobScheduleFields.hotelPrice, hotelRowData?.Price);
        setValue(JobScheduleFields.late, hotelRowData?.Late);
      }
    } else {
      setValue(JobScheduleFields.hotelMotel, '');
      setValue(JobScheduleFields.hotelAddress, '');
      setValue(JobScheduleFields.hotelZip, '');
      // setValue(JobScheduleFields.hotelCity, '');
      setValue(JobScheduleFields.hotelState, '');
      setValue(JobScheduleFields.phone, '');
      setValue(JobScheduleFields.hotelAddressOptional, '');
      setValue(JobScheduleFields.hotelConfirmation, '');
      setValue(JobScheduleFields.hotelPrice, '');
      setValue(JobScheduleFields.hotelDateOut, dayjs());
      setValue(JobScheduleFields.hotelDateIn, dayjs());
      setValue(JobScheduleFields.late, false);
    }
    if (jobId) {
      setValue(JobScheduleFields.hotelJobCode, jobId);
    }
  }, [selectedRadio, jobId, hotelId, show]);

  useEffect(() => {
    if (zipCode && zipCode?.length >= 5) {
      dispatch(removePinCodeDetails());
      setValue(JobScheduleFields.hotelState, '');
      // setValue(JobScheduleFields.hotelCity, '');
      const queryPayload = {
        pinCode: zipCode,
        countryCode: getValues(JobScheduleFields.hotelCountry),
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    }
    setValue(JobScheduleFields.hotelState, '');
    // setValue(JobScheduleFields.hotelCity, '');
  }, [zipCode, cCode]);

  const handleZipChange = () => {
    setValue(JobScheduleFields.hotelState, '');
    setValue(JobScheduleFields.hotelCity, '');
  };

  useEffect(() => {
    if (pinCodeDetails?.City) {
      setValue(JobScheduleFields.hotelState, pinCodeDetails.StateName);
    }
  }, [pinCodeDetails]);

  useEffect(() => {
    dispatch(removePinCodeDetails());
    dispatch(removeAnalystBaseOutPinCodeDetails());
  }, []);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="dialogTitle_style">
          <div className="dialogTitle_style_div">
            <div className="customerInfo">{hotelId ? 'Edit Hotel' : 'Add Hotel'}</div>
            <div className="mr-12">
              <CustomCheckbox
                name={JobScheduleFields.late}
                control={control}
                label="Late"
                defaultValue={false}
              />
            </div>
          </div>
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent_style">
          <div className="dialogContent_style_div ">
            <form onSubmit={handleSubmit(onSubmitHotelForm)}>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Job#</label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      name={JobScheduleFields.hotelJobCode}
                      id={JobScheduleFields.hotelJobCode}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Hotel / Motel
                      <RequiredFiled />
                    </label>
                    <Controller
                      name={JobScheduleFields.hotelMotel}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          className="nonCapitalizeField"
                          size="small"
                          value={value}
                          onChange={(event) => {
                            onChange(event.target.value);
                            trigger(JobScheduleFields.hotelMotel);
                          }}
                          placeholder=""
                          required
                          InputProps={{
                            endAdornment: (
                              <Tooltip title="Search here..">
                                <IconButton onClick={onClickFind} className="search_icon_btn">
                                  <SearchIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Address</label>
                    <ComponentTextInput
                      className="nonCapitalizeField"
                      control={control}
                      name={JobScheduleFields.hotelAddress}
                      id={JobScheduleFields.hotelAddress}
                      inputProps={{ maxLength: 255 }}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Address (Optional)</label>
                    <ComponentTextInput
                      className="nonCapitalizeField"
                      control={control}
                      inputProps={{ maxLength: 255 }}
                      name={JobScheduleFields.hotelAddressOptional}
                      id={JobScheduleFields.hotelAddressOptional}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Phone</label>
                    <ComponentTextInput
                      control={control}
                      name={JobScheduleFields.phone}
                      id={JobScheduleFields.phone}
                      regEx={phoneRegex}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Zip</label>
                    <ComponentTextInput
                      control={control}
                      regEx={extRegex}
                      handleChange={handleZipChange}
                      inputProps={{ maxLength: 10 }}
                      name={JobScheduleFields.hotelZip}
                      id={JobScheduleFields.hotelZip}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">State</label>
                    <ComponentTextInput
                      control={control}
                      name={JobScheduleFields.hotelState}
                      id={JobScheduleFields.hotelState}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">City</label>
                    {city === 'Other' ? (
                      <RHFAutocompleteField
                        options={citySuggestions || []}
                        control={control}
                        name={JobScheduleFields.otherHotelCity}
                      />
                    )
                      : (
                        <ComponentSelectInput
                          name={JobScheduleFields.hotelCity}
                          control={control}
                          size="small"
                          entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                        />
                      )}
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Country</label>
                    <ComponentSelectInput
                      name={JobScheduleFields.hotelCountry}
                      control={control}
                      defaultValue="US"
                      size="small"
                      entries={countries}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Date In</label>
                    <CommonDateInput
                      control={control}
                      errors={errors}
                      name={JobScheduleFields.hotelDateIn}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Date Out</label>
                    <CommonDateInput
                      control={control}
                      minDate={dateIn}
                      errors={errors}
                      name={JobScheduleFields.hotelDateOut}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Price ($)</label>
                    <ComponentTextInput
                      control={control}
                      name={JobScheduleFields.hotelPrice}
                      id={JobScheduleFields.hotelPrice}
                      regEx={RegexFiveWith2D}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Confirmation #</label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 10 }}
                      name={JobScheduleFields.hotelConfirmation}
                      id={JobScheduleFields.hotelConfirmation}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button onClick={setOpenFrom} className="button_cancel">Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button disabled={buttonDisabled} type="submit" className="button_save_and_next">Save</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxHotelEdit;
