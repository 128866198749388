import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import { Button, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { SMCUsersData } from '../Utils/TypeSmc';
import '../Style/StyleSmc.scss';
import { getSmcUserListStart, unlinkSmcUser } from '../Redux/sliceAddSmc';
import { RootState } from '../../../redux/rootState';
import AddUsers from './AddUsers';
import DataTable from '../../../Common/DataTable';
import { setLoader } from '../../../redux/loaderSlice';
import UnlinkComponentModal from '../../../Common/UnlinkComponentModal/UnlinkComponentModal';

export default function ComponentUsers() {
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedSmcUserId, setSelectedSmcUserId] = useState('');

  const dispatch = useDispatch();
  const { smcUsersList, isLoading, totalSmcUsers } = useSelector((state: RootState) => state.addSmcDetailsReducer);
  const [open, setOpen] = useState(false);
  const { smcId } = useSelector((state: RootState) => state.addSmcDetailsReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
      SmcId: smcId,
    };
    if (params.SmcId) {
      dispatch(getSmcUserListStart(params));
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleLinkOff = (id: string) => {
    setSelectedSmcUserId(id);
    setShowModal(true);
  };
  const handelModal = () => {
    const paramData = {
      SmcId: smcId,
      SmcUserId: selectedSmcUserId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(unlinkSmcUser(paramData));
    setShowModal(false);
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: translations.sn },
      { key: 'action', label: translations.action },
      { key: 'name', label: translations.name },
      { key: 'emailId', label: translations.emailId },
    ],
    [],
  );

  const data: SMCUsersData[] = smcUsersList.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'linkOff',
      name: item?.FirstName,
      emailId: item?.Email,
      id: item?.UserId,
      smcUserId: item?.UserId,
    };
    return transformedDataItem;
  });

  const handleModal = () => {
    setOpen(!open);
  };
  const handleOnChange = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <Card>
      <div className="separate_section">
        <div className="customerInfo_container">
          <div className="customerInfo">
            {translations.users}
          </div>
          <div>
            <Button className="button_save_and_next" onClick={handleModal}>{translations.addUsers}</Button>
          </div>
        </div>
        <DataTable<SMCUsersData>
          data={data}
          columns={columns}
          totalRecords={totalSmcUsers}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleLinkOff={handleLinkOff}
        />

        <UnlinkComponentModal
          show={showModal}
          setOpenFrom={() => setShowModal(false)}
          handleUnlink={handelModal}
        />
      </div>
      <AddUsers show={open} setOpenFrom={handleModal} handleChange={handleOnChange} />
    </Card>
  );
}
