import {
  Observable,
  catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import { makePutRequest } from '../../../../Common/NetworkOps';
import { ResetPasswordApiResponse, ResetTypes } from '../utils/types';
import {
  ResetPasswordActions, resetPasswordFailure, resetPasswordSuccess, sendResetPasswordData,
} from './sliceReset';

async function resetPassword(data: ResetTypes): Promise<ResetPasswordApiResponse> {
  const url = `${Config.auth.resetPassword}`;
  const result = await makePutRequest<ResetPasswordApiResponse>(url, data);
  return result.data;
}

export const epicResetPassword = (action$: Observable<ResetPasswordActions>) => action$.pipe(
  filter(sendResetPasswordData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: ResetTypes) => from(resetPassword(data)).pipe(
    map((res: ResetPasswordApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return resetPasswordSuccess();
      }
      return resetPasswordFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendResetPasswordData.match))),
    catchError((error: AxiosError<ResetPasswordApiResponse>) => of(resetPasswordFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
