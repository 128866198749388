import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddTypeListData, TypeListPayloadListing, PayloadTypeDeleteTypeList,
  PayloadTypeGetTypeList, TypeListState, UpdateTypeListData,
} from '../utils/types';

const initialState: TypeListState = {
  TypeListData: {} as TypeListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const typeListSlice = createSlice({
  name: 'typeList',
  initialState,
  reducers: {
    getTypeListStart(state, _action: PayloadAction<PayloadTypeGetTypeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successTypeList(state, action: PayloadAction<TypeListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        TypeListData: action?.payload as TypeListPayloadListing,
      };
    },
    failureTypeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateTypeList(state, _action: PayloadAction<UpdateTypeListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateTypeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateTypeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addTypeListStart(state, _action: PayloadAction<AddTypeListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddTypeList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddTypeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteTypeListStart(state, _action: PayloadAction<PayloadTypeDeleteTypeList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteTypeList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteTypeList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getTypeListStart, successTypeList, failureTypeList, updateTypeList,
  successUpdateTypeList, failureUpdateTypeList, resetState, deleteTypeListStart,
  successDeleteTypeList, failureDeleteTypeList,
  addTypeListStart, successAddTypeList, failureAddTypeList,
} = typeListSlice.actions;
export const ListTypeReducer = typeListSlice.reducer;

export type TypeListActions =
  | ReturnType<typeof getTypeListStart>
  | ReturnType<typeof successTypeList>
  | ReturnType<typeof failureTypeList>

  | ReturnType<typeof updateTypeList>
  | ReturnType<typeof successUpdateTypeList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateTypeList>

  | ReturnType<typeof deleteTypeListStart>
  | ReturnType<typeof successDeleteTypeList>
  | ReturnType<typeof failureDeleteTypeList>

  | ReturnType<typeof addTypeListStart>
  | ReturnType<typeof successAddTypeList>
  | ReturnType<typeof failureAddTypeList>;
