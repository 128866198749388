export const MAIN_TAB = 1;
export const ADD_INFO_TAB = 2;

export enum MainFormNames {
  reportType = 'reportType',
  Vessel = 'Vessel',
  testType = 'testType',
  Defect = 'Defect',
  orderCode = 'orderCode',
  spotTest = 'spotTest',
  Pct = 'Pct',
  tubeCount = 'tubeCount',
  tubesTested = 'tubesTested',
  numberOfTube = 'numberOfTube',
  percentOfBundle = 'percentOfBundle',
  previouslyPlugged = 'previouslyPlugged',
  Manufacturer = 'Manufacturer',
  Model = 'Model',
  serialNumber = 'serialNumber',
}

export enum AddInfoFormNames {
  Code = 'Code',
  comment = 'comment',
  infoManufacturer = 'infoManufacturer',
  infoModel = 'infoModel',
  infoSerialNumber = 'infoSerialNumber',
}
