import { DataHeaderCellType } from './TypeBidEntry';

export function getTableHeaderData(): DataHeaderCellType[] {
  const headerData = [
    {
      colWidth: 'wdth-3',
      header: 'SN.',
    },
    {
      colWidth: 'wdth-3',
      header: 'Action',
    },
    {
      colWidth: 'wdth-5',
      header: 'Description',
    },
    {
      colWidth: 'wdth-5',
      header: 'Amount',
    },
    {
      colWidth: 'wdth-5',
      header: 'Price Desc',
    },
  ];
  return headerData;
}
