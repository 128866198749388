import {
  Observable,
  catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  sendForgotPasswordData, forgotPasswordFailure, ForgotPasswordActions, sendOtpVerifyData, OtpDataSendSuccess, OtpDataSendFailure,
} from './sliceForgotPassword';
import Config from '../../../../Common/Config';
import { makePostRequest } from '../../../../Common/NetworkOps';
import { SendForgotOtpAPIResponse, ForgotPasswordApiResponse, ForgotPasswordData } from '../utils/types';
import StorageUtils from '../../../../utils/StorageUtils';
import constants from '../../../../utils/SessionConstants';

async function forgotPassword(data: ForgotPasswordData): Promise<ForgotPasswordApiResponse> {
  const url = `${Config.auth.forgotPassword}`;
  const result = await makePostRequest<ForgotPasswordApiResponse>(url, data);
  return result.data;
}

export const epicForgotPassword = (action$: Observable<ForgotPasswordActions>) => action$.pipe(
  filter(sendForgotPasswordData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: ForgotPasswordData) => {
    const { email } = data;
    return from(forgotPassword(data)).pipe(
      map((res: ForgotPasswordApiResponse) => {
        if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
          StorageUtils.setString(constants.EMAIL_ID, email);
          return sendOtpVerifyData();
        }
        return forgotPasswordFailure(res.BMT.ResponseMessage);
      }),
      takeUntil(action$.pipe(filter(sendForgotPasswordData.match))),
      catchError((error: AxiosError<ForgotPasswordApiResponse>) => of(forgotPasswordFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
    );
  }),
);

async function sendOtpForgot(): Promise<SendForgotOtpAPIResponse> {
  const emailId = StorageUtils.getString(constants.EMAIL_ID);
  const body = {
    EmailId: emailId,
    OtpType: 'Forget Password',
  };

  const url = `${Config.auth.sendOtp}`;
  const result = await makePostRequest<SendForgotOtpAPIResponse>(url, body);
  return result.data;
}

export const epicSendOtpForgot = (action$: Observable<ForgotPasswordActions>) => action$.pipe(
  filter(sendOtpVerifyData.match),
  debounceTime(250),
  map((x:ForgotPasswordActions) => x.payload),
  mergeMap(() => from(sendOtpForgot()).pipe(
    map((res: SendForgotOtpAPIResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return OtpDataSendSuccess();
      }
      return OtpDataSendFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendOtpVerifyData.match))),
    catchError((error: AxiosError<ForgotPasswordApiResponse>) => of(OtpDataSendFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
