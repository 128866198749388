import env from '../env';

const SUCCESS_CODE = 200;
const POST_SUCCESS_CODE = 201;
const NO_SUCH_RECORD = 214;

const AUTH_API = env.authBaseUrl;
const BASE_API = env.fetchBaseUrl;
const SYS_PRICE_API = env.fetchSysPriceUrl;
const SYS_MARKUPS_API = env.markupBaseUrl;
const FREON_BASE_API = env.fetchFreonBaseUrl;
const JOB_BASE_URL = env.jobBaseUrl;
const DASHBOARD_BASE_API = env.dashboardBaseurl;
const BASE_API_OIL_KITS = env.oilKitsBaseurl;
const ANALYST_BASE_API = env.analystBaseUrl;
const TEST_EQUIPMENT = env.EquipmentReqBaseurl;
const CALENDER_BASE = env.calenderBaseUrl;
const DEFECT_CODE = env.ColorMasterUrl;
const LISTS_BASE_URL = env.listsBaseUrl;
const SITES_API = env.SiteBaseurl;
const INVENTORY_API = env.InventoryBaseUrl;
const REPORT_TYPE_API = env.ReportTypeUrl;
const FREON_INVOICE_API = env.freonInvoiceBaseUrl;
const OIL_REPORT_API = env.oilReportBaseUrl;
const JOB_ORDER_BASE_URL = env.jobOrderBaseUrl;
const ADMIN_BASE_URL = env.AdminBaseUrl;
const PHOTOS_BASE_URL = env.PhotosUrl;

export default {
  example: '',
  SUCCESS_CODE,
  POST_SUCCESS_CODE,
  NO_SUCH_RECORD,
  auth: {
    generateOTP: `${AUTH_API}login`,
    refreshToken: `${AUTH_API}refreshtoken`,
    forgotPassword: `${AUTH_API}forgotpassword`,
    otpVerify: `${AUTH_API}loginnOtpVerification`,
    sendOtp: `${AUTH_API}sendotp`,
    resetPassword: `${AUTH_API}resetpassword`,
    addCustomer: `${BASE_API}CreateCustMain`,
    CustomerJobOrderURL: `${BASE_API}GetJobOrderByCustomer`,
    getCustomerById: `${BASE_API}GetCustById`,
    deleteCustomer: `${BASE_API}DeleteCustomer`,
    getCustomers: `${BASE_API}GetAllCustDetails`,
    addContact: `${BASE_API}CreateContacts`,
    getContacts: `${BASE_API}GetCustomerAllContacts`,
    editCustomer: `${BASE_API}UpdateCustMain`,
    editContact: `${BASE_API}UpdateContact`,
    deleteContact: `${BASE_API}DeleteContact`,
    getContactById: `${BASE_API}GetContactById`,
    logout: `${AUTH_API}logout`,
    getMasterData: `${BASE_API}GetCustMasters`,
    getPincodeDetails: `${BASE_API}GetPinCode`,
    addComment: `${BASE_API}CreateCustComments`,
    updateComment: `${BASE_API}UpdateCustComment`,
    changePassword: `${AUTH_API}UpdatePassword`,

  },
  addSmc: {
    addSmcDetails: `${BASE_API}SmcInsert`,
    getSmcList: `${BASE_API}SMCList`,
    getSmcCustomerList: `${BASE_API}SmcCustList`,
    getSmcSitesList: `${BASE_API}smcSiteList`,
    unlinkSmcCustomerList: `${BASE_API}unlinksmccustomer`,
    unlinkSmcSiteList: `${BASE_API}unlinksmcsite`,
    getSmcUserList: `${BASE_API}SMCUserList`,
    unlinkSmcUserList: `${BASE_API}unlinksmcuser`,
    deleteSmcList: `${BASE_API}SmcDelete`,
    getUserList: `${BASE_API}userList`,
    updateSmc: `${BASE_API}SmcUpdate`,
    addSmcUser: `${BASE_API}addsmcuser`,
    getSmcUserById: `${BASE_API}getsmcbyid`,
  },
  profile: {
    getUserProfile: `${AUTH_API}GetProfileById/`,
    updateUserProfile: `${AUTH_API}UpdateProfileDetails`,
    profileImageUpdate: `${AUTH_API}Profile/`,
  },

  system: {
    getKitPriceList: `${SYS_PRICE_API}GetAllPriceList`,
    updateKitPriceList: `${SYS_PRICE_API}UpdateKitprice`,

    getSystempriceList: `${SYS_PRICE_API}getsystemprice`,
    updateSystempriceList: `${SYS_PRICE_API}updateprice`,

  },

  sysMarkups: {
    getMarkups: `${SYS_MARKUPS_API}Getmarkups`,
    updateMarkups: `${SYS_MARKUPS_API}updatemarkups`,
    deleteMarkups: `${SYS_MARKUPS_API}DeleteMarkup`,
    addMarkups: `${SYS_MARKUPS_API}CreateMarkUps`,

  },

  jobs: {
    getGeneralSites: `${JOB_BASE_URL}GetSites`,
    associatedSITES: `${JOB_BASE_URL}GetSitesByCust`,
    addSite: `${JOB_BASE_URL}CreateSite`,
    getSiteTypes: `${JOB_BASE_URL}GetSite`,
    getJobs: `${JOB_BASE_URL}JobList`,
    deleteJob: `${JOB_BASE_URL}DeleteJob`,
    createJob: `${JOB_BASE_URL}CreateJob`,
    updateJob: `${JOB_BASE_URL}UpdateJob`,
    getBids: `${JOB_BASE_URL}GetBidsByJobId`,
    comment: `${JOB_BASE_URL}jobcomments`,
    getJobById: `${JOB_BASE_URL}JobById`,
  },

  freonCylinder: {
    addFreon: `${FREON_BASE_API}CreateKit`,
    unitPrice: `${FREON_BASE_API}GetKitUnitPrice/1?Type=1`,
    listFreon: `${FREON_BASE_API}GetAllKitDetails`,
    deleteFreon: `${FREON_BASE_API}DeleteKit`,
    getFreonById: `${FREON_BASE_API}GetKitDetailsById`,
    updateFreonCylinder: `${FREON_BASE_API}UpdateKit`,
  },
  dashboard: {
    GetallInfo: `${DASHBOARD_BASE_API}GetallInfo`,
  },
  analystMaster: {
    getAnalysts: `${ANALYST_BASE_API}AllAnalystList`,
    getAnalystCity: `${ANALYST_BASE_API}getAnalystcitybyid`,
    getCustomerAnalystDistance: `${ANALYST_BASE_API}customertoanalystdistance`,
    getNearestAnalyst: `${ANALYST_BASE_API}NearestAnalyst`,
  },
  calender: {
    getMasterEvents: `${CALENDER_BASE}msteventslist`,
    createEvent: `${CALENDER_BASE}event`,
    updateEvent: `${CALENDER_BASE}event`,
    getAllEvents: `${CALENDER_BASE}eventslist`,
    getAllBookmarks: `${CALENDER_BASE}bookmarklisting`,
    deleteEvent: `${CALENDER_BASE}deleteevent`,
    createBookMark: `${CALENDER_BASE}bookmark`,
    getJobEventDetails: `${CALENDER_BASE}unitjobeventsdetails`,
    createPublicHoliday: `${CALENDER_BASE}publicholiday`,
    getPublicHolidays: `${CALENDER_BASE}publicholidaylisting`,
  },
  bids: {
    createBid: `${JOB_BASE_URL}CreateBid`,
    updateBid: `${JOB_BASE_URL}UpdateBid`,
    getBidById: `${JOB_BASE_URL}BidDetailsById`,
    getDriverExpense: `${JOB_BASE_URL}CalculateDriveExpense`,
    getBidPricing: `${JOB_BASE_URL}BidPricingMaster`,
    deleteBid: `${JOB_BASE_URL}DeleteBid`,
    getBidGenerationData: `${JOB_BASE_URL}GetBidGenerationData`,
    listBid: `${JOB_BASE_URL}BidListingByCustSiteId`,
    deleteBidById: `${JOB_BASE_URL}DeleteBid`,
  },
  units: {
    createUnit: `${JOB_BASE_URL}CreateUnit`,
    updateUnit: `${JOB_BASE_URL}UpdateUnit`,
    vesselMaster: `${JOB_BASE_URL}UnitVesselList`,
    unitMaster: `${JOB_BASE_URL}UnitMasterList`,
    unitPrice: `${JOB_BASE_URL}GetUnitPrice`,
    unitListing: `${JOB_BASE_URL}UnitListByBidId`,
    getUnitById: `${JOB_BASE_URL}UnitDetailsById`,
    associateUnits: `${JOB_BASE_URL}UnitSelector`,
    deleteUnit: `${JOB_BASE_URL}DeleteUnit`,
  },
  oilKits: {
    getOilKitsList: `${BASE_API_OIL_KITS}GetAllKitDetails`,
    searchKit: `${BASE_API_OIL_KITS}serachKit`,
    createKit: `${BASE_API_OIL_KITS}CreateKit`,
    GetOilKitRange: `${BASE_API_OIL_KITS}GetOilKitRange`,
    deleteKitRange: `${BASE_API_OIL_KITS}DeleteKitRange`,
    deleteKit: `${BASE_API_OIL_KITS}DeleteKit`,
    getKitDetailsById: `${BASE_API_OIL_KITS}GetKitDetailsById`,
    updateKit: `${BASE_API_OIL_KITS}UpdateKit`,
    unitPrice: `${FREON_BASE_API}GetKitUnitPrice/2`,
  },

  PasswordChanges: {
    AddChangePwd: `${SYS_MARKUPS_API}Getmarkups`,
  },

  analyst: {
    analystlist: `${ANALYST_BASE_API}analystlist`,
    getCarrier: `${ANALYST_BASE_API}getcarrier`,
    getTeritory: `${ANALYST_BASE_API}getteritory`,
    createAnalyst: `${ANALYST_BASE_API}CreateAnalyst`,
    analystById: `${ANALYST_BASE_API}AnalystById`,
    deleteAnalyst: `${ANALYST_BASE_API}deleteanalyst`,
    updateAnalyst: `${ANALYST_BASE_API}UpdateAnalyst`,
    getAnalystLeavesList: `${ANALYST_BASE_API}AnalystLeavesList`,
    deleteAnalystLeave: `${SYS_MARKUPS_API}deleteevent`,
    addUpdateAnalystLeave: `${SYS_MARKUPS_API}event`,
    addAnalystBalanceLeave: `${ANALYST_BASE_API}AddAnalystBalanceLeave`,
    msteventslist: `${SYS_MARKUPS_API}msteventslist`,
  },
  jobsSchedule: {
    JobSchedulinglist: `${ANALYST_BASE_API}JobSchedulinglist`,
    SchedulingUnitList: `${ANALYST_BASE_API}SchedulingUnitList`,
    CreateJobSchedule: `${ANALYST_BASE_API}CreateJobSchedule`,
    UpdateJobSchedule: `${ANALYST_BASE_API}UpdateJobSchedule`,
    HotelAccomodation: `${ANALYST_BASE_API}HotelAccomodation`,
    DeleteHotelAccomodation: `${ANALYST_BASE_API}DeleteHotelAccomodation`,
    GetHotelListing: `${ANALYST_BASE_API}GetHotelListing`,
    GetInstructions: `${ANALYST_BASE_API}GetInstructions`,
    CreateMainJobScheduling: `${ANALYST_BASE_API}CreateMainJobScheduling`,
    updateSite: `${JOB_BASE_URL}updatesite`,
    JobSchedulingDetailsByBidId: `${ANALYST_BASE_API}JobSchedulingDetailsByBidId`,
    JobSchedulingReadyToSend: `${ANALYST_BASE_API}updateisreadytosend`,
    GetReportVesselsUrl: `${ADMIN_BASE_URL}`,
    AddReportVesselsUrl: `${ADMIN_BASE_URL}`,
    DeleteReportVesselsUrl: `${ADMIN_BASE_URL}Retest`,
  },
  testEquipment: {
    equipmentReqMaster: `${TEST_EQUIPMENT}GetEquipmentMaster`,
    unitMaster: `${TEST_EQUIPMENT}UnitMasterList`,
    createEquipment: `${TEST_EQUIPMENT}CreateEquipmentRequest`,
    listEquipment: `${TEST_EQUIPMENT}GetEquipmentRequest`,
  },
  Lists: {
    getTestEndList: `${LISTS_BASE_URL}msttestends`,
    addTestEndList: `${LISTS_BASE_URL}testends`,
    updateTestEndList: `${LISTS_BASE_URL}testends`,
    deleteTestEndList: `${LISTS_BASE_URL}deletetestends`,

    getSupportList: `${LISTS_BASE_URL}mstsupportlist`,
    addSupportList: `${LISTS_BASE_URL}support`,
    updateSupportList: `${LISTS_BASE_URL}support`,
    deleteSupportList: `${LISTS_BASE_URL}DeleteSupport`,

    getManufacturerList: `${LISTS_BASE_URL}mstmanufacturelist`,
    addManufacturerList: `${LISTS_BASE_URL}manufacture`,
    updateManufacturerList: `${LISTS_BASE_URL}manufacture`,
    deleteManufacturerList: `${LISTS_BASE_URL}DeleteManufacture`,

    getVesselList: `${LISTS_BASE_URL}mstvessellist`,
    addVesselList: `${LISTS_BASE_URL}vessel`,
    updateVesselList: `${LISTS_BASE_URL}vessel`,
    deleteVesselList: `${LISTS_BASE_URL}DeleteVessel`,

    getTubeList: `${LISTS_BASE_URL}msttubelist`,
    addTubeList: `${LISTS_BASE_URL}tube`,
    updateTubeList: `${LISTS_BASE_URL}tube`,
    deleteTubeList: `${LISTS_BASE_URL}DeleteTube`,

    getMaterialList: `${LISTS_BASE_URL}mstmateriallist`,
    addMaterialList: `${LISTS_BASE_URL}material`,
    updateMaterialList: `${LISTS_BASE_URL}material`,
    deleteMaterialList: `${LISTS_BASE_URL}DeleteMaterial`,

    getTypeList: `${LISTS_BASE_URL}mstunitlist`,
    addTypeList: `${LISTS_BASE_URL}unit`,
    updateTypeList: `${LISTS_BASE_URL}unit`,
    deleteTypeList: `${LISTS_BASE_URL}deleteunit`,

    getStyleList: `${LISTS_BASE_URL}mststylelist`,
    addStyleList: `${LISTS_BASE_URL}style`,
    updateStyleList: `${LISTS_BASE_URL}style`,
    deleteStyleList: `${LISTS_BASE_URL}deletestyle`,

    getReportList: `${LISTS_BASE_URL}mstreporttypelist`,
    addReportList: `${LISTS_BASE_URL}reporttype`,
    updateReportList: `${LISTS_BASE_URL}reporttype`,
    deleteReportList: `${LISTS_BASE_URL}deletereporttype`,

    getDefaultList: `${LISTS_BASE_URL}getdefaultstyleandtypes`,
    addDefaultList: `${LISTS_BASE_URL}defaultstyleandtube`,
    updateDefaultList: `${LISTS_BASE_URL}defaultstyleandtube`,
    deleteDefaultList: `${LISTS_BASE_URL}deletestyleandtype`,

    getEquipmentCategoryList: `${LISTS_BASE_URL}mstequipmentcategorylist`,
    addEquipmentCategoryList: `${LISTS_BASE_URL}equipmentcategory`,
    updateEquipmentCategoryList: `${LISTS_BASE_URL}equipmentcategory`,
    deleteEquipmentCategoryList: `${LISTS_BASE_URL}deleteequipmentcategory`,
  },
  sites: {
    getSitesList: `${SITES_API}GetSites`,
    DeleteSite: `${SITES_API}DeleteSite`,
    getSiteTypeData: `${SITES_API}GetSite`,
    getSiteDetailsById: `${SITES_API}GetSites`,
    updateSiteDetails: `${SITES_API}UpdateSite`,
    customersDetails: `${SITES_API}GetCustListBySite`,
  },
  utilities: {
    getCalibration: `${DASHBOARD_BASE_API}getutilitybyId`,
    createCalibration: `${DASHBOARD_BASE_API}utility`,
    deleteUtility: `${DASHBOARD_BASE_API}deleteutility`,
  },
  generateInvoice: {
    generateInvoiceExcel: `${AUTH_API}generateinvoice`,
    generateInvoiceExcelFreon: `${FREON_INVOICE_API}generateinvoiceforoil`,
  },
  inspectionSummary: {
    getInspectionSummary: `${INVENTORY_API}InspectionSummary/report`,
    getInspectionSummaryById: `${INVENTORY_API}InspectionSummary`,
    UpdateInspectionSummary: `${INVENTORY_API}InspectionSummary`,
  },
  inventory: {
    getInventoryMaster: `${INVENTORY_API}GetInventoryMaster`,
    createInventory: `${INVENTORY_API}CreateInventory`,
    GetInventoryList: `${INVENTORY_API}GetInventoryList`,
    deleteInventory: `${INVENTORY_API}DeleteInventory`,
    returnInventory: `${INVENTORY_API}ReturnInventory`,
    issueInventory: `${INVENTORY_API}IssueInventory`,
    getIssueHistory: `${INVENTORY_API}InventoryHistory`,
    getEquipmentMaster: `${SYS_PRICE_API}GetEquipmentMaster`,
    upcomingEquipmentNeededList: `${INVENTORY_API}UpcomingEquipmentNeededList`,
  },
  inventoryEquipmentRequest: {
    equipmentRequest: `${SYS_PRICE_API}EquipmentRequest`,
    getEquipmentRequest: `${SYS_PRICE_API}GetEquipmentRequest`,
    getEquipmentMasterStatus: `${SYS_PRICE_API}getequipmentmasterstatus`,
    equipmentNeededList: `${INVENTORY_API}EquipmentNeededList`,
    addEquipment: `${INVENTORY_API}AddEquipment`,
    deleteEquipmentRequest: `${SYS_PRICE_API}DeleteEquipmentRequest`,
    getActivityHistory: `${SYS_PRICE_API}GetActivityHistory`,
    getEquipmentRequestbyid: `${SYS_PRICE_API}GetEquipmentRequestbyid`,
    // SEND RECEIVE EQUIPMENT
    sendReceiveEquipmentList: `${INVENTORY_API}EquipmentList`,
    InventoryDetailsByProductNumber: `${INVENTORY_API}InventoryDetailsByProductNumber`,
    UnlinkEquipment: `${INVENTORY_API}UnlinkEquipment`,
    SendRecieveEquipment: `${INVENTORY_API}SendRecieveEquipment`,
    DataForPrintPdf: `${INVENTORY_API}DataForPrintPdf`,
  },
  defectCode: {
    colorMaster: `${DEFECT_CODE}GetDefectCodeMasterData`,
    treeData: `${DEFECT_CODE}GetAnalystDefectCode`,
    createDefect: `${DEFECT_CODE}CreateDefectCode`,
    deleteDefect: `${DEFECT_CODE}DeleteDefectCodeById`,
    getDefectCodeList: `${DEFECT_CODE}DefectCodeList`,
    updateDefectCodeList: `${DEFECT_CODE}CreateDefectCode`,
    addDefectCodeList: `${DEFECT_CODE}CreateDefectCode`,
    deleteDefectCodeList: `${DEFECT_CODE}DeleteDefectCodeById`,
  },
  common: {
    getCitySuggestions: `${SYS_PRICE_API}GetAllcitySuggestion`,
  },
  themes: {
    uploadThemeLogo: `${AUTH_API}Profile/`,
    createTheme: `${BASE_API}customertheme`,
  },
  oilReport: {
    getAllOilReports: `${OIL_REPORT_API}Getalloilreports`,
    getSelectedOilReport: `${OIL_REPORT_API}Getreportbyid`,
    updateOilReport: `${OIL_REPORT_API}UpdateOilReport`,
    getOilReportPdfData: `${OIL_REPORT_API}GetOilReportPdfData`,
    uploadExcel: `${OIL_REPORT_API}ImportOilReport`,
    getExcelData: `${OIL_REPORT_API}GetImportedExcelData`,
    allUnitSerialNumbers: `${OIL_REPORT_API}GetAllUnitSerialNumber`,
    sendMail: `${AUTH_API}sendmail`,
    allCompSerialNumbers: `${OIL_REPORT_API}GetComponentSerialNumberByUnit`,
    replaceUniComp: `${OIL_REPORT_API}ReplacingUnitAndComp`,
    getContactDetailsByCustID: `${BASE_API}GetCustomerAllContacts`,
    deleteOilReport: `${OIL_REPORT_API}Deleteoilreport`,
  },
  userManagement: {
    getRoleMasterData: `${INVENTORY_API}usermanagementmaster`,
    getUserManagementList: `${INVENTORY_API}userlisting`,
    AddUser: `${INVENTORY_API}usercreation`,
    activeInactiveUser: `${INVENTORY_API}userstatus`,
  },
  boardPosition: {
    getZone: `${INVENTORY_API}analystzonedetail`,
    addZone: `${INVENTORY_API}createzone`,
    analystSelector: `${INVENTORY_API}analystselector`,
    removeAnalyst: `${INVENTORY_API}removeanalyst`,
    analystList: `${INVENTORY_API}analystlist`,
    orderAnalyst: `${INVENTORY_API}analystreordering`,
  },
  jobOrder: {
    getJobOrder: `${JOB_ORDER_BASE_URL}getjoborderlist`,
    getJobOrderById: `${JOB_ORDER_BASE_URL}getjoborderbyid`,
    deleteJobOrder: `${JOB_ORDER_BASE_URL}deletejoborder`,
    UpdateJobOrder: `${JOB_ORDER_BASE_URL}updatejoborder`,
  },
  vesselInformation: {
    getVesselInformation: `${ADMIN_BASE_URL}VesselMain`,
    deleteVessel: `${ADMIN_BASE_URL}deletevesselbyid`,
    stripChartList: `${ADMIN_BASE_URL}StripChart`,
    stripChartById: `${ADMIN_BASE_URL}StripChart`,
    addStripChartUrl: `${ADMIN_BASE_URL}StripChart`,
    updateStripChartUrl: `${ADMIN_BASE_URL}StripChart`,
    deleteStripChartUrl: `${ADMIN_BASE_URL}StripChart`,
    getPhaseChart: `${ADMIN_BASE_URL}PhaseChart`,
    deletePhaseChart: `${ADMIN_BASE_URL}PhaseChart`,
    getPhaseChartById: `${ADMIN_BASE_URL}PhaseChart`,
    updatePhaseChart: `${ADMIN_BASE_URL}PhaseChart`,
    createPhaseChart: `${ADMIN_BASE_URL}PhaseChart`,
    getVesselDefect: `${ADMIN_BASE_URL}Defects`,
    deleteVesselDefect: `${ADMIN_BASE_URL}Defects`,
    getVesselDefectById: `${ADMIN_BASE_URL}Defects`,
    createVesselDefect: `${ADMIN_BASE_URL}Defects/AddOrUpdateDefect`,
    updateVesselDefect: `${ADMIN_BASE_URL}Defects/AddOrUpdateDefect`,
    startFinishDefectTimeUrl: `${ADMIN_BASE_URL}Defects/AddStartEndTime`,
    insertDefectTimeUrl: `${ADMIN_BASE_URL}Defects/AddCalibrationTime`,
    searchReplaceDefectUrl: `${ADMIN_BASE_URL}Defects/SearchAndReplace`,
    defectDropDownUrl: `${ADMIN_BASE_URL}Defects/GetDropDowns`,
    asme: {
      getAmseById: `${ADMIN_BASE_URL}AsmeSection`,
    },
    baylength: {
      getBayLength: `${ADMIN_BASE_URL}BayLength`,
    },
    vesselMain: {
      getVesselByIdUrl: `${ADMIN_BASE_URL}VesselMain/getVesselMainById`,
      updateVesselUrl: `${ADMIN_BASE_URL}VesselMain`,
      getTubeTypeListUrl: `${ADMIN_BASE_URL}master/GetTubeType`,
      getTestEndListUrl: `${ADMIN_BASE_URL}master/GetTestEnd`,
      getMaterialListUrl: `${ADMIN_BASE_URL}master/GetMaterial`,
      getSupportListUrl: `${ADMIN_BASE_URL}master/GetSupport`,
      getVesselListUrl: `${ADMIN_BASE_URL}master/GetVessels`,
    },
    tubeChart: {
      getTubeChart: `${ADMIN_BASE_URL}TubeChart`,
      getTubeChartDefects: `${ADMIN_BASE_URL}displaydefects`,
    },
    analystReports: `${ADMIN_BASE_URL}AssignReport`,
  },
  recommendations: {
    getRecommendations: `${INVENTORY_API}RecommendationsList`,
    getRecmById: `${INVENTORY_API}Recommendations`,
    getStartupText: `${INVENTORY_API}master/GetRecommendationText`,
    getFollowUpText: `${INVENTORY_API}GetFollowUpComment`,
  },
  reportTypeMaster: {
    getReportType: `${REPORT_TYPE_API}master/GetReportType`,
    getCode: `${REPORT_TYPE_API}master/GetCode`,
  },
  expenseReportURL: {
    getExpenseReport: `${ADMIN_BASE_URL}ExpenseReport`,
    deleteExpenseReceipt: `${ADMIN_BASE_URL}ExpenseReceipt`,
    deleteExpenseReport: `${ADMIN_BASE_URL}ExpenseReport`,
    getExpenseReportById: `${ADMIN_BASE_URL}ExpenseReport`,
    updateExpenseReport: `${ADMIN_BASE_URL}ExpenseReport`,
    addScannedReceiptsUrl: `${ADMIN_BASE_URL}ExpenseReceipt`,
    getExpenseReceiptList: `${ADMIN_BASE_URL}ExpenseReceiptList`,
  },
  dataSheetURl: {
    getDataSheet: `${ADMIN_BASE_URL}DataSheetList`,
    getDataSheetEdit: `${ADMIN_BASE_URL}DataSheet`,
  },
  photosBaseUrl: {
    getPhotosListDataUrl: `${PHOTOS_BASE_URL}photos`,
    getPhotoByIdUrl: `${PHOTOS_BASE_URL}photo`,
    updatePhotoUrl: `${PHOTOS_BASE_URL}photo`,
    updatePhotoEditUrl: `${PHOTOS_BASE_URL}photo`,
    vesselUrl: `${PHOTOS_BASE_URL}master/GetVessels`,
  },
  hazardAnalysisUrl: {
    getHazardAnalysisListUrl: `${ADMIN_BASE_URL}GetHazardAnalysisJobOrderList`,
    getHazardAnalysisByIdUrl: `${ADMIN_BASE_URL}GetHazardAnalysisById`,
    updateHazardAnalysisUrl: `${ADMIN_BASE_URL}UpdateHazardAnalysis`,
  },
  workspace: {
    reportPdf: `${ADMIN_BASE_URL}JobOrderReport`,
    GetAllReporsWorkspaceUrl: `${ADMIN_BASE_URL}GetListWorkspaceData`,
    GetReporsWorkspaceById: `${ADMIN_BASE_URL}GetWorkspaceData`,
    GetListContentURL: `${ADMIN_BASE_URL}JobOrderReport/ListOfContents`,
    GetSignatureList: `${ADMIN_BASE_URL}master/GetSignatures`,
    GetUserDetailsList: `${BASE_API}UnitDetail`,
  },
  inspectionLetter: {
    GetDueListDataUrl: `${ADMIN_BASE_URL}getlistforreinspectionletter`,
    UpdateStatusDataUrl: `${ADMIN_BASE_URL}updatestatusforpasdue`,
    GetSystemLogsURL: `${ADMIN_BASE_URL}`,
  },
};
