import { Grid, Card, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  EditorState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
// eslint-disable-next-line import/no-relative-packages
// import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './StyleCommentsTab.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/rootState';
import { JobCommentsPayload } from '../../utils/types';
import { addCommentStart, setSelectedTab } from '../../redux/addJobSlice';
import { customOptions } from '../../../../../../Common/TextEditorOptions';

export default function ComponentCommentsTab() {
  const [comment, setComment] = useState(EditorState.createEmpty());
  const { jobId, selectedJob } = useSelector((state:RootState) => state?.jobStarterReducer);
  const dispatch = useDispatch();

  // Function to update the editor state when content changes
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  // Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const handleSave = () => {
    const com = getHtmlFromEditorHTMLtoString(comment);
    const payload:JobCommentsPayload = {
      JobId: jobId,
      Comment: com,
    };

    // if (commentDetails || custId) {
    //   dispatch(updateCommentStart(payload));
    // } else {
    dispatch(addCommentStart(payload));
    // }

    // dispatch(setSelectedTab(CONTACT_TAB));
  };

  useEffect(() => {
    if (selectedJob?.Comments) {
      const convertedComment = htmlToEditorState(selectedJob?.Comments?.Comment);
      setComment(convertedComment);
    }
  }, [selectedJob]);

  const handleChangeTab = () => {
    dispatch(setSelectedTab(1));
  };

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="comment-card">
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Comments
                  </div>
                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="editorContainer">
                        <Editor
                          editorState={comment}
                          onEditorStateChange={handleComment}
                          wrapperClassName="demo-wrapper"
                          editorClassName="demo-editor"
                          localization={{ locale: 'en' }}
                          toolbar={customOptions}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>

            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={handleChangeTab}>Back</Button>
            </div>
            <div className="button_margin_left">
              <Button className="button_save_and_next" disabled={!comment} onClick={handleSave}>Save</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
