import { ofType } from 'redux-observable';
import {
  catchError, filter, from, map, mergeMap, Observable, of, switchMap, takeUntil,
} from 'rxjs';

import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePutRequest } from '../../../../Common/NetworkOps';
import { BaylengthPayload, BaylengthResponse } from '../../utils/type';
import { GetById } from '../../../../utils/type';
import {
  BayLengthActions,
  getBayLengthByIdFailure, getBayLengthByIdStart, getBayLengthByIdSuccess, updateBayLengthStart, updateBayLengthSuccess,
} from './baylengthSlice';

async function getBaylengthData(data: BaylengthPayload): Promise<GetById<BaylengthResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.vesselInformation.baylength.getBayLength}/${data.jobId}-${data.vesselId}`;
  const result = await makeGetRequest<GetById<BaylengthResponse>>(url);
  return result.data;
}

export const getBaylengthEpic = (action$: Observable<BayLengthActions>) => action$.pipe(
  ofType(getBayLengthByIdStart.type),
  map((x) => x.payload),
  switchMap((data: BaylengthPayload) => from(getBaylengthData(data)).pipe(
    map((res: GetById<BaylengthResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getBayLengthByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getBayLengthByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getBayLengthByIdStart.match))),
    catchError((error) => of(getBayLengthByIdFailure(error))),
  )),
);

async function updateBayLength(data: BaylengthResponse): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.baylength.getBayLength}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateBayLengthEpic = (action$: Observable<BayLengthActions>) => action$.pipe(
  ofType(updateBayLengthStart.type),
  map((x) => x.payload),
  switchMap((data: BaylengthResponse) => from(updateBayLength(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload:BaylengthPayload = {
          jobId: data.JobOrder,
          vesselId: data.VesselId || '',
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateBayLengthSuccess(), getBayLengthByIdStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getBayLengthByIdFailure());
    }),
    takeUntil(action$.pipe(filter(updateBayLengthStart.match))),
    catchError((error) => of(getBayLengthByIdFailure(error))),
  )),
);
