/* eslint-disable max-len */
import React from 'react';

export function UserManagementIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.2004 12.9V12.3C19.366 12.3 19.5004 12.1657 19.5004 12V10.2C19.5004 10.0343 19.366 9.9 19.2004 9.9H17.5804L17.5223 9.67465C17.3896 9.15926 17.1836 8.66555 16.9102 8.20887L16.7902 8.00812L17.9404 6.85594C18.0562 6.73992 18.0562 6.55195 17.9404 6.43594L16.6663 5.16C16.5487 5.04785 16.3639 5.04785 16.2462 5.16L15.0939 6.3123L14.8933 6.1923C14.4359 5.91879 13.9417 5.71277 13.4256 5.58L13.2004 5.52141V3.9C13.2004 3.7343 13.0661 3.6 12.9004 3.6H11.1004C10.9347 3.6 10.8004 3.7343 10.8004 3.9V5.52L10.575 5.57824C10.0596 5.71078 9.56594 5.9168 9.10902 6.18984L8.90828 6.30996L7.75633 5.16C7.63855 5.04691 7.45258 5.04691 7.3348 5.16L6.06039 6.43406C6.00437 6.48996 5.97285 6.5659 5.97285 6.645C5.97285 6.72422 6.00437 6.80004 6.06039 6.85594L7.2127 8.00812L7.0927 8.20887C6.81941 8.66555 6.61328 9.15926 6.48074 9.67465L6.42191 9.9H4.80039C4.63469 9.9 4.50039 10.0343 4.50039 10.2V12C4.50039 12.1657 4.63469 12.3 4.80039 12.3V12.9C4.3034 12.9 3.90039 12.497 3.90039 12V10.2C3.90039 9.70301 4.3034 9.3 4.80039 9.3H5.96113C6.08488 8.88516 6.25223 8.48461 6.46035 8.10516L5.63535 7.28016C5.4666 7.11176 5.3718 6.88324 5.3718 6.645C5.3718 6.40676 5.4666 6.17824 5.63535 6.00996L6.91035 4.73496C7.26555 4.395 7.82559 4.395 8.18078 4.73496L9.00555 5.55961C9.385 5.3516 9.78566 5.18461 10.2004 5.06098V3.9C10.2004 3.40301 10.6034 3 11.1004 3H12.9004C13.3974 3 13.8004 3.40301 13.8004 3.9V5.06098C14.2152 5.18461 14.6157 5.35195 14.9952 5.55996L15.8202 4.73496C16.1755 4.395 16.7355 4.395 17.0908 4.73496L18.3655 6.00961C18.5342 6.17801 18.6289 6.40629 18.6289 6.64465C18.6289 6.88301 18.5342 7.11152 18.3655 7.2798L17.5405 8.1048C17.7486 8.48449 17.9158 8.88516 18.0396 9.3H19.2004C19.6974 9.3 20.1004 9.70301 20.1004 10.2V12C20.1004 12.497 19.6974 12.9 19.2004 12.9Z" fill="#092245" />
      <path d="M16.2003 11.0994H15.6003C15.6003 9.11121 13.9885 7.49941 12.0003 7.49941C10.0121 7.49941 8.40029 9.11121 8.40029 11.0994H7.80029C7.80029 8.7798 9.68068 6.89941 12.0003 6.89941C14.3198 6.89941 16.2003 8.7798 16.2003 11.0994Z" fill="#092245" />
      <path d="M12.0002 13.4994C11.0061 13.4994 10.2002 12.6934 10.2002 11.6994C10.2002 10.7053 11.0061 9.89941 12.0002 9.89941C12.9944 9.89941 13.8002 10.7053 13.8002 11.6994C13.7991 12.6932 12.9939 13.4984 12.0002 13.4994ZM12.0002 10.4994C11.3375 10.4994 10.8002 11.0367 10.8002 11.6994C10.8002 12.3621 11.3375 12.8994 12.0002 12.8994C12.6629 12.8994 13.2002 12.3621 13.2002 11.6994C13.2002 11.0367 12.6629 10.4994 12.0002 10.4994Z" fill="#092245" />
      <path d="M17.1003 14.6996C16.1062 14.6996 15.3003 13.8936 15.3003 12.8996C15.3003 11.9055 16.1062 11.0996 17.1003 11.0996C18.0944 11.0996 18.9003 11.9055 18.9003 12.8996C18.8992 13.8934 18.094 14.6986 17.1003 14.6996ZM17.1003 11.6996C16.4376 11.6996 15.9003 12.2369 15.9003 12.8996C15.9003 13.5623 16.4376 14.0996 17.1003 14.0996C17.763 14.0996 18.3003 13.5623 18.3003 12.8996C18.3003 12.2369 17.763 11.6996 17.1003 11.6996Z" fill="#092245" />
      <path d="M6.9001 14.6996C5.906 14.6996 5.1001 13.8936 5.1001 12.8996C5.1001 11.9055 5.906 11.0996 6.9001 11.0996C7.89432 11.0996 8.7001 11.9055 8.7001 12.8996C8.69904 13.8934 7.89385 14.6986 6.9001 14.6996ZM6.9001 11.6996C6.2374 11.6996 5.7001 12.2369 5.7001 12.8996C5.7001 13.5623 6.2374 14.0996 6.9001 14.0996C7.56279 14.0996 8.1001 13.5623 8.1001 12.8996C8.1001 12.2369 7.56279 11.6996 6.9001 11.6996Z" fill="#092245" />
      <path d="M19.8197 15.4705C19.3726 15.1632 18.8426 14.9993 18.3 15.0008H15.9C15.7541 15.0021 15.6084 15.0153 15.4646 15.0403C15.2727 14.7401 15.023 14.4811 14.73 14.278C14.6861 14.2448 14.6399 14.2147 14.5917 14.1881C14.1721 13.9339 13.6905 13.8001 13.2 13.8008H10.8C9.88254 13.7975 9.02766 14.2657 8.53617 15.0403C8.39203 15.0153 8.24613 15.0021 8.1 15.0008H5.7C5.15789 14.9993 4.62797 15.163 4.18113 15.4699C3.44262 15.9724 3.00047 16.8076 3 17.7008V18.9008C3.00105 19.7199 3.47766 20.4638 4.22168 20.807C4.49637 20.9363 4.79648 21.0026 5.1 21.0008H8.1V20.4008H5.4V17.1008H4.8V20.3707C4.68703 20.3481 4.57734 20.3115 4.47328 20.2622C3.94184 20.0171 3.60117 19.4859 3.6 18.9008V17.7008C3.6007 17.0056 3.94512 16.3558 4.5198 15.9649C4.86703 15.7265 5.27871 15.5996 5.7 15.6008H8.1C8.15285 15.6008 8.20383 15.6064 8.25539 15.6099C8.15391 15.8961 8.10129 16.1973 8.1 16.5008V19.5008C8.10105 20.3288 8.77207 20.9997 9.6 21.0008H10.5V16.2008H9.9V20.4008H9.6C9.10301 20.4008 8.7 19.9978 8.7 19.5008V16.5008C8.7 15.3409 9.64031 14.4008 10.8 14.4008H13.2C13.586 14.401 13.9643 14.5079 14.2935 14.7095C14.3239 14.7261 14.3532 14.7449 14.381 14.7655C14.6621 14.958 14.8929 15.2153 15.0537 15.5155C15.2153 15.8188 15.3 16.1571 15.3 16.5008V19.5008C15.3 19.9978 14.897 20.4008 14.4 20.4008H14.1V16.2008H13.5V21.0008H14.4C15.228 20.9997 15.8989 20.3288 15.9 19.5008V16.5008C15.8986 16.1973 15.8462 15.8962 15.7448 15.6099C15.7965 15.6064 15.8475 15.6008 15.9 15.6008H18.3C18.7215 15.5996 19.1336 15.7269 19.481 15.9655C20.0552 16.3567 20.3992 17.006 20.4 17.7008V18.9008C20.3989 19.488 20.0557 20.0208 19.5217 20.2645C19.4191 20.313 19.3111 20.3486 19.2 20.3707V17.1008H18.6V20.4008H15.9V21.0008H18.9C19.2018 21.0027 19.5001 20.9373 19.7733 20.8093C20.5198 20.4672 20.9988 19.7219 21 18.9008V17.7008C20.9995 16.8079 20.5577 15.9731 19.8197 15.4705Z" fill="#092245" />
      <path d="M10.8003 20.3994H13.2003V20.9994H10.8003V20.3994Z" fill="#092245" />
    </svg>
  );
}
