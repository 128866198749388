export default {
  authBaseUrl: 'https://uatapi.taiservices.info/authservice/api/',
  fetchBaseUrl: 'https://uatapi.taiservices.info/customer/api/',
  fetchSysPriceUrl: 'https://uatapi.taiservices.info/inventory/api/',

  markupBaseUrl: 'https://uatapi.taiservices.info/report/api/',

  fetchFreonBaseUrl: 'https://uatapi.taiservices.info/inventory/api/',
  appVersion: '2.7.1',
  dashboardBaseurl: 'https://uatapi.taiservices.info/report/api/',
  jobBaseUrl: 'https://uatapi.taiservices.info/inventory/api/',
  oilKitsBaseurl: 'https://uatapi.taiservices.info/inventory/api/',
  analystBaseUrl: 'https://uatapi.taiservices.info/management/api/',
  EquipmentReqBaseurl: 'https://uatapi.taiservices.info/inventory/api/',
  analystBidBaseUrl: 'https://uatapi.taiservices.info/management/api/',
  listsBaseUrl: 'https://uatapi.taiservices.info/report/api/',
  ColorMasterUrl: 'https://uatapi.taiservices.info/report/api/',
  SiteBaseurl: 'https://uatapi.taiservices.info/inventory/api/',
  InventoryBaseUrl: 'https://uatapi.taiservices.info/admin/api/',
  freonInvoiceBaseUrl: 'https://uatapi.taiservices.info/management/api/',
  calenderBaseUrl: 'https://uatapi.taiservices.info/report/api/',
  oilReportBaseUrl: 'https://uatapi.taiservices.info/customer/api/',
  jobOrderBaseUrl: 'https://uatapi.taiservices.info/management/api/',
  ReportTypeUrl: 'https://uatapi.taiservices.info/admin/api/',
  AdminBaseUrl: 'https://uatapi.taiservices.info/admin/api/',
  PhotosUrl: 'https://uatapi.taiservices.info/admin/api/',
};

export const showBoardPosition = true;
export const showRoleBased = false;
export const showInspection = true;
export const showExpense = true;
export const showTubeChart = true;
export const vesselOption = true;
