import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function AbbreviationsTable() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText w45">
          Abbreviation
        </td>
        <td className="tableGreenHeading textCenter boldText w55">
          Explanation
        </td>
      </tr>
      {reportPdfResponse4?.Abbrevations?.map((data) => (
        <tr>
          <td className="textCenter">{data.Abbreviation}</td>
          <td className="textCenter">{data.Explanation}</td>
        </tr>
      ))}
    </table>
  );
}

export default AbbreviationsTable;
