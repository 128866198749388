/* eslint-disable no-restricted-syntax */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styles from '../graphStyle.module.scss';
import { DataItem } from '../../VesselInformation/utils/type';
import { Point } from '../utils/type';

interface ChartProps {
  data: DataItem[];
  verticalLineXValue: number;
  calculatedY: number;
  convertedData: Point[];
  setMaxCoordinates: (maxCoords: Point | null) => void;
}

const Chart: React.FC<ChartProps> = ({
  data, verticalLineXValue, calculatedY, convertedData, setMaxCoordinates,
}) => {
  const chartRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    let maxY = -Infinity;
    let maxCoords: Point | null = null;
    for (const labelValueData of convertedData) {
      if (labelValueData.y > maxY) {
        maxY = labelValueData.y;
        maxCoords = { x: labelValueData.x, y: labelValueData.y, id: labelValueData.id };
      }
    }
    setMaxCoordinates(maxCoords);
  }, [data]);

  useEffect(() => {
    if (!chartRef.current) return;

    chartRef.current.innerHTML = '';

    const margin = {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    };

    const width = 900 - (margin.left + margin.right);
    const height = 480 - (margin.left + margin.right);

    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('viewBox', '0 0 970 520')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .style('width', '100%')
      .style('height', '100%')
      .style('background-color', 'white')
      .style('border', '1px solid black')
      .style('border-radius', '20px');

    const group = svg
      .append('g')
      .attr('transform', `translate(75 ${margin.top})`);

    const xScale = d3.scaleLinear().domain([0, 180]).range([0, width]);
    const yScale = d3.scaleLinear().domain([0, 100]).range([height, 0]);

    const line = d3
      .line()
      .x(({ phase }: { phase: number }) => xScale(Number(phase)))
      .y(({ percentage }: { percentage: number }) => yScale(Number(percentage)));

    group
      .append('path')
      .attr('d', line(data) as string)
      .attr('fill', 'none')
      .attr('stroke', 'red');

    group
      .append('line')
      .attr('x1', xScale(verticalLineXValue))
      .attr('y1', yScale(calculatedY))
      .attr('x2', xScale(verticalLineXValue))
      .attr('y2', height)
      .attr('stroke', 'green');
    group
      .append('line')
      .attr('x1', 0)
      .attr('y1', yScale(calculatedY))
      .attr('x2', xScale(verticalLineXValue))
      .attr('y2', yScale(calculatedY))
      .attr('stroke', 'green');

    const xAxis = d3.axisBottom(xScale).tickValues(d3.range(0, 190, 10)).tickFormat(d3.format('d'));
    group.append('g').attr('transform', `translate(0 ${height})`).call(xAxis);

    group
      .append('text')
      .attr('x', width / 2)
      .attr('y', 470)
      .attr('dy', '1em')
      .style('font-size', '13px')
      .style('text-anchor', 'middle')
      .text('Phase (degrees)');

    const yAxis = d3.axisLeft(yScale);
    group.append('g').call(yAxis);

    group
      .append('text')
      .attr('x', -height / 2)
      .attr('y', -55)
      .attr('dy', '1em')
      .style('font-size', '13px')
      .style('text-anchor', 'middle')
      .attr('transform', 'rotate(-90)')
      .text('Pct Wall Penetration');
    const tickValuesX = d3.range(0, 190, 10);

    const xAxisLines = d3.axisBottom(xScale)
      .tickValues(tickValuesX)
      .tickFormat(d3.format('d'));
    group.append('g').attr('transform', `translate(0 ${height})`).call(xAxisLines);

    // Draw vertical grid lines
    group.selectAll('.vertical-grid-line')
      .data(tickValuesX)
      .enter().append('line')
      .attr('class', 'vertical-grid-line')
      .attr('x1', (d: number) => xScale(d))
      .attr('y1', 0)
      .attr('x2', (d: number) => xScale(d))
      .attr('y2', height)
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '3');

    // Draw horizontal grid lines
    const tickValuesY = d3.range(0, 110, 10);
    const yAxisLines = d3.axisLeft(yScale)
      .tickValues(tickValuesY);
    group.append('g').call(yAxisLines);

    group.selectAll('.horizontal-grid-line')
      .data(tickValuesY)
      .enter().append('line')
      .attr('class', 'horizontal-grid-line')
      .attr('x1', 0)
      .attr('y1', (d: number) => yScale(d))
      .attr('x2', width)
      .attr('y2', (d: number) => yScale(d))
      .attr('stroke', '#ccc')
      .attr('stroke-dasharray', '3');
  }, [data, verticalLineXValue, calculatedY]);

  return <div ref={chartRef as React.RefObject<HTMLDivElement>} className={styles.phaseChart_container} />;
};

export default Chart;
