import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { VesselInformationRes, VesselInformationListingInterface } from './utils/type';
import { VesselInformationTableColumns } from './utils/helper';
import { getVesselInformationStart } from './redux/sliceVesselInformation';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { convertDateFormat } from '../../utils/CommonFunctions';

function VesselInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tabId } = useParams();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { vesselInformation, totalVesselInfo, isLoading } = useSelector((state:RootState) => state.VesselInformationReducer);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getVesselInformationStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const tableData: VesselInformationListingInterface[] = useMemo(() => {
    if (vesselInformation?.length > 0) {
      const transformedData = vesselInformation?.map((ele, index) => ({
        sn: index,
        action: 'edit',
        jobOrder: ele.JobOrder,
        vessel: ele.VesselName,
        material: ele.MaterialType,
        testEnd: ele.TestEnd,
        tubeType: ele.TubeType,
        analyst: ele.Analyst,
        inspectionDate: convertDateFormat(ele.InspectionDate),
        id: `${ele.JobOrder}-${ele.VesselId}`,
      }));
      return transformedData;
    }
    return [];
  }, [vesselInformation]);

  const handleEdit = (id: string) => {
    const record = vesselInformation.find((ele:VesselInformationRes) => `${ele.JobOrder}-${ele.VesselId}` === id);
    if (record) {
      navigate(tabId ? `${record.JobOrder}/${record.VesselId}/${tabId}` : `${record.JobOrder}/${record.VesselId}`);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const excelData = useMemo(() => vesselInformation?.map((item) => {
    const transformedDataItems = {
      JobOrder: item.JobOrder,
      VesselName: item.VesselName,
      MaterialType: item.MaterialType,
      TestEnd: item.TestEnd,
      TubeType: item.TubeType,
      Analyst: item.Analyst,
      InspectionDate: convertDateFormat(item.InspectionDate),
    };
    return transformedDataItems;
  }), [vesselInformation]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'VESSEL INFORMATION',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="VESSEL INFORMATION" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<VesselInformationListingInterface>
          data={tableData?.length > 0 ? tableData : []}
          columns={VesselInformationTableColumns}
          totalRecords={totalVesselInfo}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleEdit={handleEdit}
        />
      </Grid>
    </Grid>
  );
}

export default VesselInformation;
