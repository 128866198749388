/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  useEffect,
  useMemo,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FinishTimeComponentPropTypes } from './utils/type';
import { CommonDateInput } from '../../../Common/CommonDateInput';
import Common24HTimeInput from '../../../Common/Common24HTimePicker';
import { FinishTimeDefaultData, FinishTimeFields } from '../utils/constant';
import { TimeDateFormInfo } from '../utils/defectType';
import { finishTimeSchema } from '../utils/validation';
import {
  dateTimeStart, getDefectByIdStart, resetDefectData, updateVesselDefectStart,
} from '../redux/DefectRedux/sliceVesselDefect';
import RequiredFiled from '../../../Common/RequiredField';
import { convertDateFormatTo } from '../../../utils/CommonFunctions';
import { RootState } from '../../../redux/rootState';
import { EditFinishTimePayload } from '../utils/helper/EditStartFinishTimeHelper';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function StartTimeComponent({
  show, setOpenFrom, label, callback, startTime, rowId, setRowId,
}: FinishTimeComponentPropTypes): JSX.Element {
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<TimeDateFormInfo>({
    resolver: yupResolver(finishTimeSchema),
    defaultValues: FinishTimeDefaultData as unknown as TimeDateFormInfo,
  });

  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  const { defectDataById } = useSelector((state: RootState) => state.VesselDefectReducer);

  const startDate = useMemo(() => dayjs(convertDateFormatTo(startTime?.Date || ''), 'MM/DD/YYYY'), [startTime?.Date]);
  const disableStartTime = useMemo(() => dayjs(startTime?.Time || '', 'HH:mm:ss').add(1, 's'), [startTime?.Time]);

  const handleSave = (values: TimeDateFormInfo) => {
    if (rowId) {
      const payLoad = EditFinishTimePayload(values, defectDataById);
      dispatch(updateVesselDefectStart({ payLoad, callback }));
      setOpenFrom();
    } else {
      const payLoad = {
        JobOrder: jobOrderId || '',
        VesselId: vesselId || '',
        DefectId: '',
        Date: dayjs(values.date).format('DD-MM-YYYY'),
        Time: dayjs(values.time).format('HH:mm:ss'),
        List: 'Finish',
        DefectType: 'Time Entry',
      };
      dispatch(dateTimeStart({ payLoad, callback }));
      setOpenFrom();
    }
  };

  useEffect(() => {
    if (rowId && show) {
      const payload = {
        id: rowId,
      };
      dispatch(getDefectByIdStart(payload));
    } return () => {
      setRowId('');
      dispatch(resetDefectData());
    };
  }, [rowId, show]);

  useEffect(() => {
    if (defectDataById) {
      if (defectDataById?.Date) {
        const parts = defectDataById?.Date.split('-');
        const formattedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
        setValue(FinishTimeFields.date, dayjs(formattedDate));
      }
      setValue(FinishTimeFields.time, dayjs(defectDataById?.Time, 'HH:mm:ss'));
    }
  }, [defectDataById]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {rowId ? 'Edit Finish Time' : label}
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Date
                    <RequiredFiled />
                  </label>
                  <CommonDateInput
                    minDate={startDate}
                    control={control}
                    errors={errors}
                    name={FinishTimeFields.date}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Time
                    <RequiredFiled />
                  </label>
                  <Common24HTimeInput
                    control={control}
                    errors={errors}
                    minTime={disableStartTime}
                    name={FinishTimeFields.time}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button type="button" className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StartTimeComponent;
