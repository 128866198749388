import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Card } from '@mui/material';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import '../../Style/StyleSmc.scss';
import { AddSmcFormValue } from '../../Utils/ConstantSmc';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { TypesAddSMC } from './TypesAddSmc';
import RequiredFiled from '../../../../Common/RequiredField';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../redux/rootState';
import { phoneRegex } from '../../../../utils/regex';
import { RHFAutocompleteField } from '../../../../Common/ComponentAutoSelect';

export default function ComponentAddSmc({
  control, errors, watch,
}: TypesAddSMC): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const {
    countries, phoneCodes, pinCodeDetails, citySuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(AddSmcFormValue.CITY);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {translations.addSmc}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Company Name
                <RequiredFiled />
              </label>
              <ComponentTextInput
                name={AddSmcFormValue.SMC_NAME}
                control={control}
                capitalizeFirst
                size="small"
                inputProps={{ maxLength: 30 }}
                id={AddSmcFormValue.SMC_NAME}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.taiIdNumber}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                name={AddSmcFormValue.TAI_ID_NUMBER}
                control={control}
                size="small"
                inputProps={{ maxLength: 10 }}
                id={AddSmcFormValue.TAI_ID_NUMBER}
                // regEx={/^.{1,50}$/}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.url}</label>
              <ComponentTextInput
                name={AddSmcFormValue.URL}
                inputProps={{ maxLength: 100 }}
                control={control}
                className="nonCapitalizeField"
                size="small"
                id={AddSmcFormValue.URL}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={1} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.code}</label>
              <ComponentSelectInput
                name={AddSmcFormValue.PHONE_CODE}
                control={control}
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.phone}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                name={AddSmcFormValue.PHONE_NUMBER}
                control={control}
                regEx={phoneRegex}
                size="small"
                id={AddSmcFormValue.PHONE_NUMBER}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.address}
              </label>
              <ComponentTextInput
                name={AddSmcFormValue.ADDRESS}
                inputProps={{ maxLength: 200 }}
                control={control}
                className="nonCapitalizeField"
                size="small"
                id={AddSmcFormValue.ADDRESS}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.addressOptional}</label>
              <ComponentTextInput
                name={AddSmcFormValue.ADDRESS_OPTIONAL}
                inputProps={{ maxLength: 200 }}
                control={control}
                className="nonCapitalizeField"
                size="small"
                id={AddSmcFormValue.ADDRESS_OPTIONAL}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.country}</label>
              <ComponentSelectInput
                name={AddSmcFormValue.COUNTRY}
                errors={errors}
                defaultValue=""
                control={control}
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.zip}</label>
              <ComponentTextInput
                name={AddSmcFormValue.ZIP}
                control={control}
                className="nonCapitalizeField"
                inputProps={{ maxLength: 10 }}
                size="small"
                id={AddSmcFormValue.ZIP}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.state}</label>
              <ComponentTextInput
                name={AddSmcFormValue.STATE}
                control={control}
                isDisable
                size="small"
                id={AddSmcFormValue.STATE}
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">City</label>
              {renderCityInput() ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  name={AddSmcFormValue.OTHER_CITY}
                  errors={errors}
                />
              )
                : (
                  <ComponentSelectInput
                    name={AddSmcFormValue.CITY}
                    errors={errors}
                    defaultValue=""
                    control={control}
                    size="small"
                    entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
