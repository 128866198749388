import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetListingPayload, PaginationResponse } from '../../../utils/type';
import {
  GetRecmByIdResponse, GetRecomByIdPayload, GetRecomendationsList, GetStartupTextListRes, RecomendationInitialState,
  SetRecomTextPayload,
  UpdateRecomPayload,
} from '../utils/types';

const initialState:RecomendationInitialState = {
  recommendations: [],
  totalRecommendations: 0,
  isLoading: false,
  selectedRecm: {} as GetRecmByIdResponse,
  startUpTextList: [],
  followUpText: '',
  recomText: '',
};

export const recommendationSlice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    clearRecomendationState() {
      return initialState;
    },

    setRecomText(state, action:PayloadAction<SetRecomTextPayload>) {
      const { value, replaceText, preAdd } = action.payload;
      if (replaceText) {
        return {
          ...state,
          recomText: value,
        };
      }
      return {
        ...state,
        recomText: preAdd ? `${value}\n${state.recomText}` : `${state.recomText}\n${value}`,
      };
    },

    getRecommendationsStart(state, _action:PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getRecommendationsSuccess(state, action:PayloadAction<PaginationResponse<GetRecomendationsList>>) {
      return {
        ...state,
        recommendations: action.payload.Data,
        totalRecommendations: action.payload.TotalRecords,
        isLoading: false,
      };
    },

    getRecommendationFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getRecommendationByIdStart(state, _action:PayloadAction<GetRecomByIdPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getRecommendationByIdSuccess(state, action:PayloadAction<GetRecmByIdResponse>) {
      return {
        ...state,
        isLoading: false,
        selectedRecm: action.payload,
      };
    },

    getRecommendationByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getStartupTextStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getStartupTextSuccess(state, action:PayloadAction<GetStartupTextListRes[]>) {
      return {
        ...state,
        isLoading: false,
        startUpTextList: action.payload,
      };
    },

    getStartupTextFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getFollowUpTextStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getFollowUpTextSuccess(state, action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        followUpText: action.payload,
      };
    },

    getFollowupTextFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateRecomStart(state, _action:PayloadAction<UpdateRecomPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    updateRecomSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateRecomFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  clearRecomendationState, getRecommendationsStart, getRecommendationsSuccess, getRecommendationFailure,
  getRecommendationByIdStart, getRecommendationByIdSuccess, getRecommendationByIdFailure,
  getStartupTextStart, getStartupTextSuccess, getStartupTextFailure, getFollowUpTextStart,
  getFollowUpTextSuccess, getFollowupTextFailure, setRecomText, updateRecomStart, updateRecomFailure,
  updateRecomSuccess,
} = recommendationSlice.actions;

export const recommendationReducer = recommendationSlice.reducer;

export type RecommendationAction =
    ReturnType<typeof clearRecomendationState>
    | ReturnType<typeof getRecommendationsStart>
    | ReturnType<typeof getRecommendationsSuccess>
    | ReturnType<typeof getRecommendationFailure>
    | ReturnType<typeof getRecommendationByIdStart>
    | ReturnType<typeof getRecommendationByIdSuccess>
    | ReturnType<typeof getRecommendationByIdFailure>
    | ReturnType<typeof getStartupTextStart>
    | ReturnType<typeof getStartupTextSuccess>
    | ReturnType<typeof getStartupTextFailure>
    | ReturnType<typeof getFollowUpTextStart>
    | ReturnType<typeof getFollowUpTextSuccess>
    | ReturnType<typeof getFollowupTextFailure>
    | ReturnType<typeof setRecomText>
    | ReturnType<typeof updateRecomStart>
    | ReturnType<typeof updateRecomFailure>
    | ReturnType<typeof updateRecomSuccess>;
