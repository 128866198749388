import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  GetUserProfileActions, getUserProfileStart,
  successGetUserProfile, failureGetUserProfile, updateUserProfile, successUpdateProfile,
  failureUpdateProfile, updateProfileImage, updateImageSuccess, updateImageFailure,
} from './sliceMyProfile';
import Config from '../../../Common/Config';
import {
  makeFormPostRequest, makeGetRequest, makePutRequest,
} from '../../../Common/NetworkOps';
import { UpdateProfileData, UserProfileApiResponseTypes } from '../Utils/TypeProfile';
import localStorageConstants from '../../../utils/LocalStorageConstants';

async function GetUserProfile(): Promise<UserProfileApiResponseTypes> {
  const userId = await localStorage.getItem(localStorageConstants.USER_ID);
  const url = `${Config.profile.getUserProfile}${userId}`;
  const result = await makeGetRequest<UserProfileApiResponseTypes>(url);
  return result?.data;
}

export const epicGetUserProfile = (action$: Observable<GetUserProfileActions>) => action$.pipe(
  filter(getUserProfileStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(() => from(GetUserProfile()).pipe(
    map((res: UserProfileApiResponseTypes) => {
      if (res.BMT?.ResponseCode === Config.SUCCESS_CODE) {
        return successGetUserProfile(res.BMT?.Result);
      }
      return failureGetUserProfile(res?.BMT?.ResponseMessage || '');
    }),
    takeUntil(action$.pipe(filter(getUserProfileStart.match))),
    catchError((error: AxiosError<UserProfileApiResponseTypes>) => of(failureGetUserProfile(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateUserProfile(data: UpdateProfileData): Promise<UserProfileApiResponseTypes> {
  const url = `${Config.profile.updateUserProfile}`;
  const result = await makePutRequest<UserProfileApiResponseTypes>(url, data);
  return result?.data;
}

export const epicUpdateUserProfile = (action$: Observable<GetUserProfileActions>) => action$.pipe(
  filter(updateUserProfile.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateUserProfile(data)).pipe(
    map((res: UserProfileApiResponseTypes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successUpdateProfile();
      }
      return failureUpdateProfile(res.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(updateUserProfile.match))),
    catchError((error: AxiosError<UserProfileApiResponseTypes>) => of(failureUpdateProfile(error.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function updateUserImageApi(data: File): Promise<UserProfileApiResponseTypes> {
  const userId = localStorage.getItem(localStorageConstants.USER_ID) || '';
  const url = `${Config.profile.profileImageUpdate}${userId}`;
  const result = await makeFormPostRequest<UserProfileApiResponseTypes>(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
}

export const epicUpdateImageData = (action$: Observable<GetUserProfileActions>) => action$.pipe(
  filter(updateProfileImage.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: File) => from(updateUserImageApi(data)).pipe(
    map((res: UserProfileApiResponseTypes) => {
      if (res?.BMT?.ResponseCode === Config.SUCCESS_CODE) {
        return updateImageSuccess(res?.BMT?.ResponseMessage);
      }
      return updateImageFailure(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(updateProfileImage.match))),
    catchError((error: AxiosError<UserProfileApiResponseTypes>) => of(updateImageFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
