import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import english from '../../../assets/lang/english';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { setLoader } from '../../../redux/loaderSlice';
import { RootState } from '../../../redux/rootState';
// import { setLoader } from '../../../redux/loaderSlice';
// import { RootState } from '../../../redux/rootState';
import EndPoints from '../../../Routes/EndPoints';
import { deleteJobStart, getJobsStart } from './redux/jobStarterSlice';
import { JobTableColumns } from './utils/constants';
import { formatJobsData } from './utils/helper';
import { JobsTableData } from './utils/types';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { resetJobStarterState } from './addJob/redux/addJobSlice';
import { removeSelectedCustomer } from '../../addCustomer/redux/addCustomerSlice';

export function JobStarterListing() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { totalEntries, isLoading, jobs } = useSelector((state: RootState) => state.jobsReducer);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(resetJobStarterState());
    dispatch(removeSelectedCustomer());
    navigate(EndPoints.ADD_JOB);
  };

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };

    dispatch(getJobsStart(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = (jobId: string) => {
    setDeleteModal(true);
    setSelectedId(jobId);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDeleteModal = () => {
    setDeleteModal(false);
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      id: selectedId,
    };
    dispatch(deleteJobStart(payload));
  };

  const handleEdit = (jobId: string) => {
    navigate(`${jobId}`);
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: jobs,
      },
      'Job_list',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.jobs)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<JobsTableData>
            handleButtonClick={handleButtonClick}
            data={formatJobsData(jobs)}
            columns={JobTableColumns}
            totalRecords={totalEntries}
            buttonText="Create New Job"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />

    </>
  );
}
