export const MainDefaultValue = {
  vertical: false,
  safetyEquipmentRequired: false,
  outside: false,
  flux: false,
  absDiff: false,
  crsDiff: false,
  jobId: '',
  manufacturer: '',
  serialId: '',
  model: '',
  vessel: '',
  tubeType: '',
  material: '',
  OD: '',
  ID: '',
  tubeLength: '',
  tubeCount: '',
  NWT: '',
  NWTBellLand: '',
  NWTUnderFins: '',
  note: '',
  workHrs: '',
  retestOf: '',
};
