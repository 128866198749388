import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import english from '../../assets/lang/english';
import { CustomerData, DeleteCustomer } from './utils/TypeCustomer';
import DataTable from '../../Common/DataTable';
import { TableColumns } from './utils/DataCustomerHeaderCell';
import EndPoints from '../../Routes/EndPoints';
import { deleteCustomerStart, getCustomersStart } from './redux/customerSlice';
import { RootState } from '../../redux/rootState';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
// import { removeSelectedCustomer } from '../addCustomer/redux/addCustomerSlice';
import { setLoader } from '../../redux/loaderSlice';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { removeSelectedCustomer } from '../addCustomer/redux/addCustomerSlice';

export default function ScreenCustomerDetail() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { customers, totalEntries, isLoading } = useSelector((state: RootState) => state.customerReducer);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedCustId, setSelectedCustId] = useState('');
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    // Cleaning addCustomerSlice States
    dispatch(removeSelectedCustomer());
    navigate(EndPoints.ADD_CUSTOMER);
  };

  const handleData = () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getCustomersStart(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleDelete = (customerId: string) => {
    setDeleteModal(true);
    setSelectedId(customerId);
  };

  const handleDeleteModal = () => {
    const payload: DeleteCustomer = {
      customerId: selectedId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteCustomerStart(payload));
    setDeleteModal(false);
  };

  const data: CustomerData[] = useMemo(() => customers.map((item, index) => {
    const transformedDataItem = {
      markUp: item?.Markup,
      cust: item?.Custid,
      company: item?.Customername,
      address: item?.Address,
      city: item?.CityName,
      state: item?.StateName,
      phone: item?.Phone,
      smc: item?.SmcName,
      action: 'edit&delete',
      sn: index,
      id: item?.Custid,
    };
    return transformedDataItem;
  }), [customers]);

  const handleEdit = (customerId: string) => {
    navigate(`${customerId}`);
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: customers,
      },
      'Customer_list',
    );
  };

  const openJobPage = () => {
    navigate(`${EndPoints.ADD_JOB_WITH_ID}/${selectedCustId}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.Customer)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <div className="customTableWidth">
            <DataTable<CustomerData>
              handleButtonClick={handleButtonClick}
              data={data}
              columns={TableColumns}
              totalRecords={totalEntries}
              buttonText="Add New Customer"
              searchButton="Add New Job"
              searchButtonDisable={selectedCustId === ''}
              handleSearchButtonClick={openJobPage}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              radioButtonColumnsWithKey={['sn']}
              selectedRadioWithKey={selectedCustId}
              setSelectedRadioWithKey={setSelectedCustId}
            />
          </div>
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />

    </>
  );
}
