/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Button, Card, TextField } from '@mui/material';
import {
  Field, FieldArray, Form, Formik,
} from 'formik';
import { AddPlaylist, DeleteSvg } from '../../../../../assets/TableIcons';
import { debugLog } from '../../../../../Common/Logger';

export default function ComponentDirections() {
  interface Types {
    trips: string[];
  }

  const initialValues: Types = {
    trips: [''],
  };

  const onSubmit = (values: Types) => {
    debugLog('Form data', values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      <Form>
        <Card>
          <div className="Card_div">
            <span>
              Direction
            </span>
            <Button className="button_save_and_next" type="submit">
              +
            </Button>
          </div>

          <FieldArray name="trips">
            {(fieldArrayProps) => {
              // eslint-disable-next-line @typescript-eslint/unbound-method
              const { push, remove, form } = fieldArrayProps;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const { values } = form;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const { trips }: Types = values;
              return (
                <div className="div_container_trip">
                  {trips.map((trip: string, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className="div_inner_trip">
                      <div className="textfield_div_trip">
                        <div>{`Trip #${index + 1}`}</div>
                        <Field name={`trips[${index}]`}>
                          {({ field }: any) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              variant="outlined"
                              fullWidth
                              size="small"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="div_button">
                        <button type="button" onClick={() => push('')} className="button_style_trip">
                          <AddPlaylist />
                        </button>
                        {index > 0 && (
                        <button type="button" onClick={() => remove(index)} className="button_style_trip">
                          <DeleteSvg />
                        </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              );
            }}
          </FieldArray>
        </Card>
      </Form>
    </Formik>
  );
}
