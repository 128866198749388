import
React, { useContext, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { Button } from 'rsuite';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@mui/material';
import styles from './style.module.scss';
import changePawdImgBg from '../../assets/changePasswordBg.svg';
import changePawdImg from '../../assets/changePasswordImg.png';
import LockIcon from '../../assets/Padlock.png';
import ComponentTextInput from '../../Common/ComponentTextinput/ComponentTextInput';
import { ChangePaswordFormVal, initialValuesChangePwd } from './utils/ConstantsChangePwd';
import { PasswordFormTypes } from './utils/ChangePassword';
import { changePasswordStart, setChangePasswordModal } from './Redux/sliceChangePwd';
import { ChangePwdValidationSchema } from '../../utils/Schema/ValidationSchema';
import { LocalizationContext } from '../../Common/Localization/LocalizationProvider';
import password_hide_icon from '../../assets/password_hide_icon.svg';
import password_show_icon from '../../assets/password_show_icon.svg';
import ComponentSuccessChangePasswordModal from './Components/ComponentSuccessChangePasswordModal';
import { RootState } from '../../redux/rootState';
import RequiredFiled from '../../Common/RequiredField';

function ChangePassword() {
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [error, setError] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isSuccess } = useSelector((state: RootState) => state.ChangePasswordReducer);
  const {
    handleSubmit, formState: { errors }, control, watch, reset,
  } = useForm<PasswordFormTypes>({
    defaultValues: initialValuesChangePwd,
    resolver: yupResolver(ChangePwdValidationSchema(translations)),
  });

  const closeModal = () => {
    dispatch(setChangePasswordModal(false));
  };

  const newPassword = watch(ChangePaswordFormVal.NEW_PASSWORD);
  const confirmPassword = watch(ChangePaswordFormVal.CONFIRM_PASSWORD);

  const onSubmit = (data: PasswordFormTypes) => {
    const uersIds = localStorage.getItem('userId') as string;
    const bodyData = {
      UserId: uersIds,
      OldPassword: data.OldPassword,
      NewPassword: data.NewPassword,
    };

    if (newPassword === confirmPassword) {
      dispatch(changePasswordStart(bodyData));
      reset();
    }
  };

  useEffect(() => {
    if (newPassword !== confirmPassword && confirmPassword) {
      setError(true);
    } if (newPassword === confirmPassword) {
      setError(false);
    }
  }, [newPassword, confirmPassword]);

  const endAdornmentCurrentPassword = {
    endAdornment: (
      <div className="password_show_hide">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowCurrentPassword(!showCurrentPassword);
          }}
        >
          {showCurrentPassword ? (
            <img
              src={password_show_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          ) : (
            <img
              src={password_hide_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          )}
        </IconButton>
      </div>
    ),
  };

  const endAdornmentNewPassword = {
    endAdornment: (
      <div className="password_show_hide">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowNewPassword(!showNewPassword);
          }}
        >
          {showNewPassword ? (
            <img
              src={password_show_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          ) : (
            <img
              src={password_hide_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          )}
        </IconButton>
      </div>
    ),
  };

  const endAdornmentConfirmPassword = {
    endAdornment: (
      <div className="password_show_hide">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => {
            setShowConfirmPassword(!showConfirmPassword);
          }}
        >
          {showConfirmPassword ? (
            <img
              src={password_show_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          ) : (
            <img
              src={password_hide_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          )}
        </IconButton>
      </div>
    ),
  };

  return (
    <>
      <Card className={styles.cards}>
        <Grid container className={styles.cardContainer}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={7}>
            <div className="changePasswordBanner">
              <div className="changePaswordBackground">
                <img src={changePawdImgBg} alt="Icon" width={100} height={100} className="rotateCenter" />
              </div>
              <div className="changePaswordImage">
                <img src={changePawdImg} alt="Icon" width={100} height={100} className="girlImageChangePassword" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={5} className={styles.allCenter}>
            <div className="changePasswordContainer ">
              <div className="lockIconImage">
                <img src={LockIcon} alt="Icon" width={100} height={100} />
              </div>
              <div className="changePasswordHeader"> Change Password</div>
              <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">
                        Current Password
                        <RequiredFiled />
                      </label>
                      <ComponentTextInput
                        control={control}
                        type={showCurrentPassword ? 'text' : 'password'}
                        inputProps={endAdornmentCurrentPassword}
                        name={ChangePaswordFormVal.CURRENT_PASSWORD}
                        size="small"
                        maxTextLength={16}
                        id={ChangePaswordFormVal.CURRENT_PASSWORD}
                        placeholder="Enter your current password"
                        errors={errors}
                        adornmentProps={false}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">
                        New Password
                        <RequiredFiled />
                      </label>
                      <ComponentTextInput
                        control={control}
                        maxTextLength={16}
                        type={showNewPassword ? 'text' : 'password'}
                        inputProps={endAdornmentNewPassword}
                        name={ChangePaswordFormVal.NEW_PASSWORD}
                        size="small"
                        id={ChangePaswordFormVal.NEW_PASSWORD}
                        errors={errors}
                        placeholder="Enter your new password"
                        adornmentProps={false}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">
                        Confirm Password
                        <RequiredFiled />
                      </label>
                      <ComponentTextInput
                        control={control}
                        maxTextLength={16}
                        type={showConfirmPassword ? 'text' : 'password'}
                        inputProps={endAdornmentConfirmPassword}
                        name={ChangePaswordFormVal.CONFIRM_PASSWORD}
                        size="small"
                        id={ChangePaswordFormVal.CONFIRM_PASSWORD}
                        errors={errors}
                        className="inputFieldText"
                        placeholder="Enter your confirm password"
                        adornmentProps={false}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <div className="errorMsg">{error ? 'Your new & confirmed password did not match' : ''}</div>
                    <div className="div_label_text">
                      <Button
                        className="button_save_and_next"
                        type="submit"
                      >
                        Update Password
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Card>
      <ComponentSuccessChangePasswordModal show={isSuccess} setOpenFrom={closeModal} />
    </>
  );
}

export default ChangePassword;
