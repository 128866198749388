import React from 'react';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { BidFields, FieldInputComponentProps } from '../types';

function FieldInputComponent({ control, errors }: FieldInputComponentProps) {
  return (
    <div
      className="quotaPdfContainer"
    >
      <div className="quotaPdfBox">
        <div className="secondText">
          QUOTE PREPARED FOR:
        </div>
        <div style={{
          display: 'flex', flexDirection: 'column', marginTop: '30px', gap: '30px',
        }}
        >
          <ComponentTextInput
            control={control}
            name={BidFields.contactName}
            id={BidFields.contactName}
            errors={errors}
            size="small"
            placeholder="Contact Name"
          />
          <ComponentTextInput
            control={control}
            name={BidFields.customer}
            id={BidFields.customer}
            errors={errors}
            size="small"
            placeholder="Customer"
          />
          <ComponentTextInput
            control={control}
            name={BidFields.address}
            id={BidFields.address}
            errors={errors}
            size="small"
            placeholder="Address"
          />
          <ComponentTextInput
            control={control}
            name={BidFields.city}
            id={BidFields.city}
            errors={errors}
            size="small"
            placeholder="ZIP,CITY,STATE"
          />
        </div>
      </div>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
          <div className="effectiveDateClass">
            <div className="effectiveDateClass_firstText">
              Effective Date
            </div>
            <div>
              <ComponentTextInput
                control={control}
                name={BidFields.effectiveDate}
                id={BidFields.effectiveDate}
                errors={errors}
                size="small"
                isDisable
              />
            </div>
          </div>
          <div className="effectiveDateClass">
            <div className="effectiveDateClass_firstText">
              Quote Number
            </div>
            <div>
              <ComponentTextInput
                control={control}
                name={BidFields.quoteNumber}
                id={BidFields.quoteNumber}
                errors={errors}
                size="small"
                isDisable
              />
            </div>
          </div>
          <div className="effectiveDateClass">
            <div className="effectiveDateClass_firstText">
              Customer Number
            </div>
            <div>
              <ComponentTextInput
                control={control}
                name={BidFields.customerNumber}
                id={BidFields.customerNumber}
                errors={errors}
                size="small"
                isDisable
              />
            </div>
          </div>
          <div className="effectiveDateClass">
            <div className="effectiveDateClass_firstText">
              Payment Terms
            </div>
            <div>
              <ComponentTextInput
                control={control}
                name={BidFields.paymentTerms}
                id={BidFields.paymentTerms}
                errors={errors}
                size="small"
                isDisable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FieldInputComponent;
