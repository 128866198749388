import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  SystemPriceListActions, getSystemPriceStart,
  successGetSystemPrice, failureGetSystemPriceList, updateSystemPriceList, successUpdateSystemPriceList, failureUpdateSystemPriceList,
} from './slicePriceList';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePutRequest } from '../../../../Common/NetworkOps';
import { GetAllSystemPriceListApiRes, UpdateSystemPriceApiResTypes, UpdatePriceListFormData } from '../utils/TypesPriceList';
import { showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';

interface PayloadTypeGetSystemPriceListStart {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}
async function GetSystemPriceList(data: PayloadTypeGetSystemPriceListStart): Promise<GetAllSystemPriceListApiRes> {
  const url = `${Config.system.getSystempriceList}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllSystemPriceListApiRes>(url);
  return result?.data;
}

export const epicGetSystemPriceList = (action$: Observable<SystemPriceListActions>) => action$.pipe(
  filter(getSystemPriceStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetSystemPriceListStart) => from(GetSystemPriceList(data)).pipe(
    map((res: GetAllSystemPriceListApiRes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetSystemPrice(res?.BMT.Result);
      }
      return failureGetSystemPriceList(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getSystemPriceStart.match))),
    catchError((error: AxiosError<GetAllSystemPriceListApiRes>) => of(failureGetSystemPriceList(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function UpdateSystemPrice(data: UpdatePriceListFormData): Promise<UpdateSystemPriceApiResTypes> {
  const url = `${Config.system.updateSystempriceList}`;
  const result = await makePutRequest<UpdateSystemPriceApiResTypes>(url, data);
  return result?.data;
}

export const epicUpdateSystemPriceLists = (action$: Observable<SystemPriceListActions>) => action$.pipe(
  filter(updateSystemPriceList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateSystemPrice(data)).pipe(
    mergeMap((res: UpdateSystemPriceApiResTypes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateSystemPriceList());
      }
      return of(failureUpdateSystemPriceList(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateSystemPriceList.match))),
    catchError((error: AxiosError<UpdateSystemPriceApiResTypes>) => of(failureUpdateSystemPriceList(
      error.response?.data.BMT.ResponseMessage as string,
    ))),
  )),
);
