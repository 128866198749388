import React from 'react';
import { useSelector } from 'react-redux';
import VesselBayLengthGraph from './vesselBayLengthGraph';
import { RootState } from '../../../redux/rootState';
import { BayLengthDetails } from '../types';

function VesselBayLengthInfoSection() {
  const { reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>Part II - Support Bay Length </div>
          <div>
            S/N
            {reportPdfResponse1?.JobOrderData?.SerialNumber}
          </div>
        </div>
        <div className="underlineHeadingText textCenter">Vessel Bay Length Information</div>
      </div>
      {reportPdfResponse1?.Support_Bay_Length?.map((data) => (
        data.map((bayDetails:BayLengthDetails) => (
          <>
            <VesselBayLengthGraph bayDetails={bayDetails} />
            <div className="page-break" />
          </>
        ))
      ))}
    </div>
  );
}

export default VesselBayLengthInfoSection;
