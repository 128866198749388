/* eslint-disable max-len */
import React from 'react';

export function SystemIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6073 18.7253C15.5292 18.844 15.4011 18.9159 15.2605 18.9159H8.73681C8.59621 18.9159 8.46499 18.844 8.39 18.7253C8.31502 18.6097 8.30564 18.4659 8.35876 18.341C8.5181 17.9848 8.87428 17.7567 9.26483 17.7567H10.0459V17.0693H13.9545V17.7567H14.7356C15.1262 17.7567 15.4792 17.9879 15.6417 18.341C15.6948 18.4659 15.6823 18.6128 15.6073 18.7253Z" fill="#092245" />
      <path d="M9.20829 8.87748C8.9927 9.68045 8.25535 10.2741 7.38677 10.2741C6.34634 10.271 5.49963 9.42737 5.49963 8.38695C5.49963 7.51524 6.09327 6.78101 6.89624 6.56543C6.87437 6.64979 6.88686 6.59355 6.88374 8.38695C6.88374 8.66189 7.1087 8.88685 7.38364 8.88685C9.16767 8.88373 9.1208 8.8931 9.20829 8.87748Z" fill="#092245" />
      <path d="M9.27097 8.38694C9.27097 8.47754 9.19599 8.55253 9.10538 8.55253H7.38697C7.29636 8.55253 7.22137 8.47754 7.22137 8.38694V6.66852C7.22137 6.57479 7.29636 6.50293 7.38697 6.50293C8.42739 6.50293 9.27097 7.34651 9.27097 8.38694Z" fill="#092245" />
      <path d="M9.10518 11.2833H5.66523C5.57462 11.2833 5.49963 11.2083 5.49963 11.1177C5.49963 11.0271 5.57462 10.9521 5.66523 10.9521H9.10518C9.19579 10.9521 9.27077 11.0271 9.27077 11.1177C9.27077 11.2083 9.19579 11.2833 9.10518 11.2833Z" fill="#092245" />
      <path d="M9.10518 12.264H5.66523C5.57462 12.264 5.49963 12.1891 5.49963 12.0985C5.49963 12.0078 5.57462 11.9329 5.66523 11.9329H9.10518C9.19579 11.9329 9.27077 12.0078 9.27077 12.0985C9.27077 12.1922 9.19579 12.264 9.10518 12.264Z" fill="#092245" />
      <path d="M9.10518 13.2482H5.66523C5.57462 13.2482 5.49963 13.1732 5.49963 13.0826C5.49963 12.992 5.57462 12.917 5.66523 12.917H9.10518C9.19579 12.917 9.27077 12.992 9.27077 13.0826C9.27077 13.1732 9.19579 13.2482 9.10518 13.2482Z" fill="#092245" />
      <path d="M12.3234 10.474V12.9141C12.3234 13.0984 12.1734 13.2484 11.9891 13.2484H11.2955C11.1112 13.2484 10.9612 13.0984 10.9612 12.9141V10.474C10.9612 10.2896 11.108 10.1396 11.2955 10.1396H11.9891C12.1734 10.1396 12.3234 10.2896 12.3234 10.474Z" fill="#092245" />
      <path d="M14.3826 8.75521V12.9138C14.3826 13.0981 14.2326 13.2481 14.0482 13.2481H13.3546C13.1703 13.2481 13.0203 13.0981 13.0203 12.9138V8.75521C13.0203 8.57087 13.1672 8.4209 13.3546 8.4209H14.0482C14.2326 8.4209 14.3826 8.57087 14.3826 8.75521Z" fill="#092245" />
      <path d="M16.4384 10.474V12.9141C16.4384 13.0984 16.2884 13.2484 16.1041 13.2484H15.4074C15.223 13.2484 15.0731 13.0984 15.0731 12.9141V10.474C15.0731 10.2896 15.223 10.1396 15.4074 10.1396H16.1041C16.2884 10.1396 16.4384 10.2896 16.4384 10.474Z" fill="#092245" />
      <path d="M18.4973 9.4554V12.9141C18.4973 13.0984 18.3473 13.2484 18.1629 13.2484H17.4662C17.2819 13.2484 17.1319 13.0984 17.1319 12.9141V9.4554C17.1319 9.27106 17.2819 9.12109 17.4662 9.12109H18.1629C18.3473 9.12109 18.4973 9.27106 18.4973 9.4554Z" fill="#092245" />
      <path d="M15.7573 9.28019C15.7104 9.28019 15.6635 9.25832 15.6292 9.22083L13.6858 6.90878L11.758 8.77091C11.6924 8.8334 11.5862 8.8334 11.5237 8.76779C11.4612 8.70218 11.4612 8.59595 11.5268 8.53346L13.5858 6.54635C13.6202 6.51511 13.6639 6.49636 13.7108 6.49948C13.7577 6.50261 13.8014 6.52448 13.8295 6.55885L15.7885 8.88964L17.7225 7.52428C17.7975 7.47117 17.9006 7.48992 17.9537 7.5649C18.0068 7.63989 17.9881 7.74299 17.9131 7.79611L15.8541 9.25207C15.826 9.27082 15.7916 9.28019 15.7573 9.28019Z" fill="#092245" />
      <path d="M11.9985 15.1826C11.8736 15.1826 11.7704 15.2857 11.7704 15.4107C11.7704 15.5357 11.8736 15.6388 11.9985 15.6388C12.1235 15.6388 12.2266 15.5357 12.2266 15.4107C12.2266 15.2857 12.1235 15.1826 11.9985 15.1826Z" fill="#092245" />
      <path d="M19.1627 5H4.83421C4.3718 5 4 5.3718 4 5.83109C4 6.09666 4 16.5415 4 15.9041C4 16.3634 4.3718 16.7383 4.83421 16.7383H19.1658C19.6251 16.7383 20 16.3634 20 15.9041C20 14.6325 20 7.09647 20 5.83109C19.9969 5.3718 19.6219 5 19.1627 5ZM11.9984 15.9729C11.6891 15.9729 11.436 15.7198 11.436 15.4105C11.436 15.1012 11.6891 14.8481 11.9984 14.8481C12.3078 14.8481 12.5608 15.1012 12.5608 15.4105C12.5608 15.7198 12.3078 15.9729 11.9984 15.9729ZM19.3314 14.0795H4.6655V5.83109C4.6655 5.74048 4.74048 5.6655 4.83109 5.6655H19.1627C19.2533 5.6655 19.3283 5.74048 19.3283 5.83109V14.0795H19.3314Z" fill="#092245" />
    </svg>

  );
}
