import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, LoginTypes, SendOtpTypes } from '../utils/types';

const initialState: AuthState = {
  loginData: {
    Email: '',
    Password: '',
  },
  otpData: {
    EmailId: '',
    OtpType: '',
  },
  isLoading: false,
  isError: false,
  errorMessage: '',
  otpSent: false,
  errorModalShow: false,
};

export const LoginSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    sendLoginData(state, action: PayloadAction<LoginTypes>) {
      return {
        ...state,
        isLoading: true,
        loginData: action.payload,
      };
    },
    loginDataSendFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    },
    sendOtpVerifyData(state, action: PayloadAction<SendOtpTypes>) {
      return {
        ...state,
        isLoading: true,
        isError: false,
        otpData: action.payload,
      };
    },
    OtpDataSendSuccess(state) {
      return {
        ...state,
        isError: false,
        isLoading: false,
        otpSent: true,
      };
    },
    OtpDataSendFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
        errorModalShow: true,
      };
    },
    hideErrorModal(state) {
      return {
        ...state,
        errorModalShow: false,
      };
    },
    OtpSendSuccess(state) {
      return {
        ...state,
        otpSent: false,
      };
    },
    resetLoginStates(state) {
      return {
        ...state,
        otpSent: false,
        errorMessage: '',
        isError: false,
      };
    },
    errorMessageState(state) {
      return {
        ...state,
        errorMessage: '',
        isError: false,
      };
    },
  },
});

export const {
  sendLoginData, loginDataSendFailure, sendOtpVerifyData, OtpDataSendSuccess,
  OtpDataSendFailure, OtpSendSuccess, resetLoginStates, errorMessageState,
} = LoginSlice.actions;
export const authReducer = LoginSlice.reducer;

export type AuthActions =
  | ReturnType<typeof sendLoginData>
  | ReturnType<typeof loginDataSendFailure>
  | ReturnType<typeof sendOtpVerifyData>
  | ReturnType<typeof OtpDataSendSuccess>
  | ReturnType<typeof OtpSendSuccess>
  | ReturnType<typeof resetLoginStates>
  | ReturnType<typeof errorMessageState>
  | ReturnType<typeof OtpDataSendFailure>;
