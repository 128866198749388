import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddManufacturerListData, ManufacturerListPayloadListing, PayloadTypeDeleteManufacturerList,
  PayloadTypeGetManufacturerList, ManufacturerListState, UpdateManufacturerListData,
} from '../utils/types';

const initialState: ManufacturerListState = {
  ManufacturerListData: {} as ManufacturerListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const manufacturerListSlice = createSlice({
  name: 'manufacturerList',
  initialState,
  reducers: {
    getManufacturerListStart(state, _action: PayloadAction<PayloadTypeGetManufacturerList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successManufacturerList(state, action: PayloadAction<ManufacturerListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        ManufacturerListData: action?.payload as ManufacturerListPayloadListing,
      };
    },
    failureManufacturerList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateManufacturerList(state, _action: PayloadAction<UpdateManufacturerListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateManufacturerList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateManufacturerList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addManufacturerListStart(state, _action: PayloadAction<AddManufacturerListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddManufacturerList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddManufacturerList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteManufacturerListStart(state, _action: PayloadAction<PayloadTypeDeleteManufacturerList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteManufacturerList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteManufacturerList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getManufacturerListStart, successManufacturerList, failureManufacturerList, updateManufacturerList,
  successUpdateManufacturerList, failureUpdateManufacturerList, resetState, deleteManufacturerListStart,
  successDeleteManufacturerList, failureDeleteManufacturerList,
  addManufacturerListStart, successAddManufacturerList, failureAddManufacturerList,
} = manufacturerListSlice.actions;
export const ListManufacturerReducer = manufacturerListSlice.reducer;

export type ManufacturerTypeActions =
  | ReturnType<typeof getManufacturerListStart>
  | ReturnType<typeof successManufacturerList>
  | ReturnType<typeof failureManufacturerList>

  | ReturnType<typeof updateManufacturerList>
  | ReturnType<typeof successUpdateManufacturerList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateManufacturerList>

  | ReturnType<typeof deleteManufacturerListStart>
  | ReturnType<typeof successDeleteManufacturerList>
  | ReturnType<typeof failureDeleteManufacturerList>

  | ReturnType<typeof addManufacturerListStart>
  | ReturnType<typeof successAddManufacturerList>
  | ReturnType<typeof failureAddManufacturerList>;
