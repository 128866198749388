import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import english from '../../assets/lang/english';
import { RootState } from '../../redux/rootState';
import { deleteSmcStart, getSmcUserStart } from './Redux/sliceSmc';
import DataTable from '../../Common/DataTable';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../redux/loaderSlice';
import EndPoints from '../../Routes/EndPoints';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { exportToExcelData } from '../../Common/PageHeader/helper';

interface SMCData {
  sn: number;
  SmcName: string;
  TaiIdNo: string;
  Subscriber: number;
  id: string;
}

export default function SMC() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSmcId, setSelectedSmcId] = useState('');

  const dispatch = useDispatch();
  const { smcList, isLoading, totalEntries } = useSelector((state: RootState) => state.getSmcUserReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getSmcUserStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedSmcId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      smcId: selectedSmcId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteSmcStart(paramData));
    setShowDeleteModal(false);
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'SmcName', label: 'SMC' },
      { key: 'TaiIdNo', label: 'TAI ID Number' },
      { key: 'Subscriber', label: 'Subscriber' },
    ],
    [],
  );

  const data: SMCData[] = useMemo(() => smcList.map((item, index) => {
    const transformedDataItem = {
      SmcName: item?.SmcName,
      TaiIdNo: item?.TaiIdNo,
      Subscriber: item?.Subscriber,
      action: 'edit&delete',
      sn: index,
      id: item?.SmcId,
    };
    return transformedDataItem;
  }), [smcList]);

  const handleButtonClick = () => {
    Navigate(EndPoints.SCREEN_ADD_SMC);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id:string) => {
    Navigate(`${id}`);
  };

  const excelData = useMemo(() => smcList.map((item) => {
    const transformedDataItem = {
      SmcName: item?.SmcName,
      TaiIdNo: item?.TaiIdNo,
      Subscriber: item?.Subscriber,

    };
    return transformedDataItem;
  }), [smcList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'SMC_list',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading={String(english.SMC)} downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<SMCData>
            handleButtonClick={handleButtonClick}
            data={data}
            columns={columns}
            totalRecords={totalEntries}
            buttonText="Add SMC"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />

    </>
  );
}
