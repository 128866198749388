export const ReportsInformationTableColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'jobOrder', label: 'Job Order #' },
  { key: 'vessel', label: 'Vessel' },
  { key: 'serialNumber', label: 'Serial Number' },
  { key: 'jobSite', label: 'Job Site' },
  { key: 'modelNumber', label: 'Model Number' },
  { key: 'analyst', label: 'Analyst' },
  { key: 'inspectionDate', label: 'Inspection Date' },
];
