import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetListingPayload, PaginationResponse } from '../../../utils/type';
import { MAIN_TAB } from '../utils/constant';
import {
  DeleteStripChartPayload,
  GetStripChartListingPayload, StripChartData, CreateStripChartType,
  DataItem, DeletePhaseChartPayload, InitialStateVesselInformation, PhaseChart, UpdatePhaseChartType, VesselInformationRes,
  UpdateStripChart, AsmeByIdResponse,
  UpdateAsme,
  AnalystReportsType,
} from '../utils/type';

const initialState: InitialStateVesselInformation = {
  isLoading: false,
  selectedTab: MAIN_TAB,
  vesselInformation: [],
  stripChartData: [],
  stripChartDataById: {} as StripChartData,
  phaseChartList: [],
  phaseChartDataById: [] as DataItem[],
  phaseChartAllData: {} as PhaseChart,
  totalPhaseChart: 0,
  totalVesselInfo: 0,
  totalStripChart: 0,
  asmeById: {} as AsmeByIdResponse,
  imageFile: undefined,
};

const VesselInformationSlice = createSlice({
  name: 'VesselInformationSlice',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },

    getVesselInformationStart(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getVesselInformationSuccess(state, action: PayloadAction<PaginationResponse<VesselInformationRes>>) {
      return {
        ...state,
        isLoading: false,
        vesselInformation: action.payload.Data,
        totalVesselInfo: action.payload.TotalRecords,
      };
    },

    getVesselInformationFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteVesselStart(state, _action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    deleteVesselSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteVesselFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getAsmeByIdStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getStripChartStart(state, _action:PayloadAction<GetStripChartListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    // -- Get Phase Chart List
    getPhaseChartStart(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getStripChartSuccess(state, action:PayloadAction<PaginationResponse<StripChartData>>) {
      return {
        ...state,
        isLoading: false,
        stripChartData: action.payload.Data,
        totalStripChart: action.payload.TotalRecords,
      };
    },

    getStripChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getPhaseChartSuccess(state, action: PayloadAction<PaginationResponse<PhaseChart>>) {
      return {
        ...state,
        isLoading: false,
        phaseChartList: action.payload.Data,
        totalPhaseChart: action.payload.TotalRecords,
      };
    },

    getPhaseChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // -- Get Phase Chart By ID
    getPhaseChartByIdStart(state, _action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getPhaseChartByIdSuccess(state, action: PayloadAction<PhaseChart>) {
      return {
        ...state,
        isLoading: false,
        phaseChartDataById: action.payload.Configuration.DataList,
        phaseChartAllData: action.payload,
      };
    },

    getPhaseChartByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetPhaseChartData(state) {
      return {
        ...state,
        phaseChartDataById: [],
        phaseChartAllData: {} as PhaseChart,
      };
    },
    // ----Delete Phase Chart
    deletePhaseChartStart(state, _action: PayloadAction<DeletePhaseChartPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    deletePhaseChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Strip Chart By ID
    getStripChartByIdStart(state, _action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deletePhaseChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // ----Update Phase Chart
    updatePhaseChartStart(state, _action: PayloadAction<UpdatePhaseChartType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getStripChartByIdSuccess(state, action: PayloadAction<StripChartData>) {
      return {
        ...state,
        isLoading: false,
        stripChartDataById: action.payload,
      };
    },
    getStripChartByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetStripChartData(state) {
      return {
        ...state,
        stripChartDataById: {} as StripChartData,
      };
    },
    // ----Update Strip Chart
    updateStripChartStart(state, _action: PayloadAction<UpdateStripChart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateStripChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateStripChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // ----Create Strip Chart
    createStripChartStart(state, _action: PayloadAction<CreateStripChartType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createStripChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    createStripChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Set Image State
    SetStripChartImage(state, action: PayloadAction<File | undefined>) {
      return {
        ...state,
        imageFile: action.payload,
      };
    },

    updatePhaseChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ----Delete Strip Chart
    deleteStripChartStart(state, _action: PayloadAction<DeleteStripChartPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updatePhaseChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ----Create Phase Chart
    createPhaseChartStart(state, _action: PayloadAction<UpdatePhaseChartType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteStripChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    createPhaseChartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteStripChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getAsmeByIdSuccess(state, action:PayloadAction<AsmeByIdResponse>) {
      return {
        ...state,
        isLoading: false,
        asmeById: action.payload,
      };
    },

    createPhaseChartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getAsmeByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateAsmeStart(state, _action:PayloadAction<UpdateAsme>) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateAsmeSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateAsmeFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Analyst Reports
    onCopyReportsStart(state, _action: PayloadAction<AnalystReportsType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onCopyReportsSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onCopyReportsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  setSelectedTab, getVesselInformationStart, getVesselInformationSuccess, getVesselInformationFailure,
  deleteVesselStart, deleteVesselSuccess, deleteVesselFailure,
  getStripChartStart, getStripChartSuccess, getStripChartFailure,
  getStripChartByIdStart, getStripChartByIdSuccess, getStripChartByIdFailure,
  updateStripChartStart, updateStripChartSuccess, updateStripChartFailure,
  createStripChartStart, createStripChartSuccess, createStripChartFailure,
  deleteStripChartStart, deleteStripChartSuccess, deleteStripChartFailure,
  getPhaseChartStart, getPhaseChartSuccess, getPhaseChartFailure,
  getPhaseChartByIdStart, getPhaseChartByIdSuccess, getPhaseChartByIdFailure, resetPhaseChartData,
  deletePhaseChartStart, deletePhaseChartSuccess, deletePhaseChartFailure,
  updatePhaseChartStart, updatePhaseChartSuccess, updatePhaseChartFailure,
  createPhaseChartStart, createPhaseChartSuccess, createPhaseChartFailure,
  getAsmeByIdStart, getAsmeByIdSuccess, getAsmeByIdFailure, updateAsmeStart,
  updateAsmeSuccess, updateAsmeFailure, resetStripChartData,
  onCopyReportsStart, onCopyReportsSuccess, onCopyReportsFailure,
  SetStripChartImage,
} = VesselInformationSlice.actions;
export const VesselInformationReducer = VesselInformationSlice.reducer;

export type VesselInformationActions =
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof getVesselInformationStart>
  | ReturnType<typeof getVesselInformationSuccess>
  | ReturnType<typeof getVesselInformationFailure>
  | ReturnType<typeof deleteVesselStart>
  | ReturnType<typeof deleteVesselSuccess>
  | ReturnType<typeof deleteVesselFailure>
  | ReturnType<typeof getStripChartStart>
  | ReturnType<typeof getStripChartSuccess>
  | ReturnType<typeof getStripChartFailure>
  | ReturnType<typeof getStripChartByIdStart>
  | ReturnType<typeof getStripChartByIdSuccess>
  | ReturnType<typeof getStripChartByIdFailure>
  | ReturnType<typeof updateStripChartStart>
  | ReturnType<typeof updateStripChartSuccess>
  | ReturnType<typeof updateStripChartFailure>
  | ReturnType<typeof createStripChartStart>
  | ReturnType<typeof createStripChartSuccess>
  | ReturnType<typeof createStripChartFailure>
  | ReturnType<typeof deleteStripChartStart>
  | ReturnType<typeof deleteStripChartSuccess>
  | ReturnType<typeof deleteStripChartFailure>
  | ReturnType<typeof getPhaseChartStart>
  | ReturnType<typeof getPhaseChartSuccess>
  | ReturnType<typeof getPhaseChartFailure>
  | ReturnType<typeof getPhaseChartByIdStart>
  | ReturnType<typeof getPhaseChartByIdSuccess>
  | ReturnType<typeof getPhaseChartByIdFailure>
  | ReturnType<typeof resetPhaseChartData>
  | ReturnType<typeof deletePhaseChartStart>
  | ReturnType<typeof deletePhaseChartSuccess>
  | ReturnType<typeof deletePhaseChartFailure>
  | ReturnType<typeof updatePhaseChartStart>
  | ReturnType<typeof updatePhaseChartSuccess>
  | ReturnType<typeof updatePhaseChartFailure>
  | ReturnType<typeof createPhaseChartStart>
  | ReturnType<typeof createPhaseChartSuccess>
  | ReturnType<typeof createPhaseChartFailure>
  | ReturnType<typeof getAsmeByIdStart>
  | ReturnType<typeof getAsmeByIdSuccess>
  | ReturnType<typeof getAsmeByIdFailure>
  | ReturnType<typeof updateAsmeStart>
  | ReturnType<typeof updateAsmeSuccess>
  | ReturnType<typeof updateAsmeFailure>
  | ReturnType<typeof resetStripChartData>
  | ReturnType<typeof onCopyReportsStart>
  | ReturnType<typeof onCopyReportsSuccess>
  | ReturnType<typeof onCopyReportsFailure>
  | ReturnType<typeof SetStripChartImage>;
