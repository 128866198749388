import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { TypesDialogBox } from '../../../utils/interface';
import { RootState } from '../../../redux/rootState';
import ProfileImage from '../../../assets/Images/ProfileImage/ProfileImage.png';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogProfileImage({ show, setOpenFrom }: TypesDialogBox): JSX.Element {
  const { userProfile } = useSelector((state: RootState) => state.userProfileReducer);
  return (
    <div className="ProfileImageDialog">
      <Dialog
        maxWidth="md"
        open={show}
        transitionDuration={700}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="ProfileImageDialogButton">
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={setOpenFrom}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </div>
        <DialogContent className="image_container_style">
          {
           userProfile?.ImageUrl?.length
             ? <img src={userProfile?.ImageUrl} alt="Profile Pic" width="100%" height="100%" />
             : <img src={ProfileImage} alt="" width="100%" height="100%" />
                  }
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogProfileImage;
