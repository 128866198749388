import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Cancel } from '../../../../../media/images/Cancel';
import './StyleBidEntryModal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 702,
  height: 400,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 4,
};
export interface TypeBidModal {
  show: boolean;
  setOpenFrom: () => void;
}

export default function ComponentBidEntryModal({ show, setOpenFrom }:TypeBidModal): JSX.Element {
  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Bids Entry
            </div>
            <div className="cancel-icon">
              <Cancel />
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={12} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">Description</label>
                  <TextField
                    placeholder=""
                    multiline
                    rows={2}
                    maxRows={4}
                  />
                </div>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">Amount</label>
                  <TextField size="small" />
                </div>
              </Grid>

              <Grid item md={6} sm={6} xs={6}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">Price Description</label>
                  <TextField size="small" />
                </div>
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel">Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Box>
      </Modal>
    </div>
  );
}
