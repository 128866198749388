import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import EndPoints from './EndPoints';
import ScreenLogin from '../Screens/Login/ScreenLogIn/ScreenLogin';
import ScreenForgotPassword from '../Screens/Login/ScreenForgotPassword/ScreenForgotPassword';
import ScreenReset from '../Screens/Login/ScreenReset/ScreenReset';
import ScreenLoginSuccess from '../Screens/Login/ScreenLoginSuccess/ScreenLoginSuccess';
import ScreenOtpVerify from '../Screens/Login/ScreenOtpVerify/ScreenOtpVerify';

function PublicRoute() {
  return (
    <Routes>
      <Route path={EndPoints.LOGIN_PAGE} element={<ScreenLogin />} />
      <Route path={EndPoints.SCREEN_FORGOT_PASSWORD} element={<ScreenForgotPassword />} />
      <Route path={EndPoints.SCREEN_OTP} element={<ScreenOtpVerify />} />
      <Route path={EndPoints.SCREEN_ACCOUNT_RESET} element={<ScreenReset />} />
      <Route path={EndPoints.SCREEN_SUCCESS} element={<ScreenLoginSuccess />} />
      <Route path="*" element={<Navigate to={EndPoints.LOGIN_PAGE} />} />
    </Routes>
  );
}

export default PublicRoute;
