import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import {
  addEquipmentFailure,
  addEquipmentStart,
  addEquipmentSuccess,
  failureEquipmentList,
  getEquipmentFailure, getEquipmentListStart, getEquipmentStart,
  getEquipmentSuccess, getUnitMasterFailure, getUnitMasterStart, getUnitMasterSuccess, successEquipmentList, TestEquActions,
} from './sliceTestEquipments';
import {
  CreateData, CreateDataPayload, CreateEquipmentResponse,
  EquipmentResponse, GetAllEquipmentApiResponse, PayloadTypeEquipmentListStart, UnitMasterResponse,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { ADMIN_REQUEST } from '../../ScreenUnitInformation/utils/constants';

// Create Equipment
async function createEquipmentMaster(data: CreateData): Promise<CreateEquipmentResponse> {
  const url = `${Config.inventoryEquipmentRequest.equipmentRequest}`;
  const result = await makePostRequest<CreateEquipmentResponse>(url, data);
  return result.data;
}

export const epicCreateEquipment = (action$: Observable<TestEquActions>) => action$.pipe(
  filter(addEquipmentStart.match),
  map((x) => x.payload),
  mergeMap(({
    payload, callback, isAdmin, navigate,
  }: CreateDataPayload) => from(createEquipmentMaster(payload)).pipe(
    map((res: CreateEquipmentResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        if (isAdmin) {
          navigate(`/test-request/${payload?.UnitId}/${res.BMT.Result}/${ADMIN_REQUEST}`);
          callback();
        }
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return addEquipmentSuccess();
      }
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return addEquipmentSuccess();
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return addEquipmentFailure();
    }),
    takeUntil(action$.pipe(filter(addEquipmentStart.match))),
    catchError((error: AxiosError<CreateEquipmentResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      addEquipmentFailure();
      return of();
    }),
  )),
);

// get EquipmentMaster
async function getEquipmentMaster(data: string): Promise<EquipmentResponse> {
  const url = `${Config.testEquipment.equipmentReqMaster}?unitid=${data}`;
  const result = await makeGetRequest<EquipmentResponse>(url);
  return result.data;
}

export const epicEquipmentMaster = (action$: Observable<TestEquActions>) => action$.pipe(
  filter(getEquipmentStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getEquipmentMaster(data)).pipe(
    map((res: EquipmentResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentSuccess(res.BMT.Result);
      }
      return getEquipmentFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getEquipmentStart.match))),
    catchError((error) => of(getEquipmentFailure(error))),
  )),
);

// get EquipmentMaster
async function getUnitMaster(): Promise<UnitMasterResponse> {
  const url = `${Config.testEquipment.unitMaster}`;
  const result = await makeGetRequest<UnitMasterResponse>(url);
  return result.data;
}

export const epicUnitMaster = (action$: Observable<TestEquActions>) => action$.pipe(
  filter(getUnitMasterStart.match),
  debounceTime(250),
  mergeMap(() => from(getUnitMaster()).pipe(
    map((res: UnitMasterResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitMasterSuccess(res.BMT.Result);
      }
      return getUnitMasterFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getUnitMasterStart.match))),
    catchError((error) => of(getUnitMasterFailure(error))),
  )),
);

// List Equipment
async function GetEquipmentList(data: PayloadTypeEquipmentListStart): Promise<GetAllEquipmentApiResponse> {
  const url = `${Config.testEquipment.listEquipment}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllEquipmentApiResponse>(url);
  return result?.data;
}

export const epicGetEquipmentList = (action$: Observable<TestEquActions>) => action$.pipe(
  filter(getEquipmentListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeEquipmentListStart) => from(GetEquipmentList(data)).pipe(
    map((res: GetAllEquipmentApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successEquipmentList(res?.BMT.Result);
      }
      return failureEquipmentList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getEquipmentListStart.match))),
    catchError((error: AxiosError<GetAllEquipmentApiResponse>) => of(failureEquipmentList(error.response?.data.BMT?.ResponseMessage as string))),
  )),
);
