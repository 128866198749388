/* eslint-disable react/no-unstable-nested-components */
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { InputAdornment } from '@mui/material';
import { CommonDateInputProps } from './types';
import { errorType, errorTypeMessage } from './constant';
import NewCalenderIcon from './NewCalenderIcon';

export function CommonDateInput({
  control, name, errors, handleChange, minDate, disabled, maxDate, format,
}: CommonDateInputProps) {
  const errorMessage = useMemo(() => {
    switch (errors[name]?.type) {
      case errorType.MIN_DATE: {
        return errorTypeMessage.MIN_DATE_ERROR_MESSAGE;
      }
      case errorType.INVALID_DATE: {
        return errorTypeMessage.INVALID_DATE_ERROR_MESSAGE;
      }
      case errorType.MAX_DATE: {
        return errorTypeMessage.MAX_DATE_ERROR_MESSAGE;
      }
      case errorType.OPTIONALITY: {
        return errorTypeMessage.OPTIONALITY_ERROR_MESSAGE;
      }
      case errorType.NULLABLE: {
        return errorTypeMessage.NULLABLE_ERROR_MESSAGE;
      }
      default: {
        return errors[name]?.message;
      }
    }
  }, [errors[name]?.type]);

  function OpenPickerIcon() {
    return <NewCalenderIcon errors={errors} name={name} />;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            format={format}
            onChange={(date) => {
              onChange(dayjs(date));
              if (handleChange) {
                handleChange(dayjs(date));
              }
            }}
              // slots={{
              //   openPickerIcon: OpenPickerIcon,
              // }}
            slotProps={{
              textField: {
                InputProps: { // Remove InputProps when use @mui/x-date-pickers/DatePicker
                  endAdornment: (
                    <InputAdornment position="end">
                      <OpenPickerIcon />
                    </InputAdornment>
                  ),
                },
                size: 'small',
                fullWidth: true,
                error: Boolean(errors[name]),
                helperText: errorMessage,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
