import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function CommonTableWithMultipleColumn() {
  const { reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Manufacturer
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Model
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Style
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Serial Number
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Type
        </td>
      </tr>
      <tr>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Manufacturer}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Model}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.Style}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.SerialNumber}</td>
        <td className="textCenter">{reportPdfResponse1?.JobOrderData?.MachineType}</td>
      </tr>
    </table>
  );
}

export default CommonTableWithMultipleColumn;
