import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExpenseDeleteApiPayload, ExpenseMainFormAPIValues, ExpenseReportResult, ResultDataById,
} from '../utils/types';

const initialState = {
  isLoading: false,
  expenseReportData: {} as ExpenseReportResult,
  expenseReportDataById: {} as ResultDataById,
  ScannedReceiptsData: {} as ExpenseReportResult,
};

export const sliceExpenseReport = createSlice({
  name: 'expenseReportEpic',
  initialState,
  reducers: {
    getExpenseReportData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getExpenseReportSuccess(state, action: PayloadAction<ExpenseReportResult>) {
      return {
        ...state,
        isLoading: false,
        expenseReportData: action.payload,
      };
    },
    getExpenseReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteExpenseReportStart(state, _action: PayloadAction<ExpenseDeleteApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteExpenseReportSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteExpenseReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Expense Report By ID --------->>>>>>>>>>>>

    getExpenseReportById(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getExpenseReportSuccessById(state, action: PayloadAction<ResultDataById>) {
      return {
        ...state,
        isLoading: false,
        expenseReportDataById: action.payload,
      };
    },
    getExpenseReportFailureById(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    clearExpenseReportData(state) {
      return {
        ...state,
        expenseReportDataById: {} as ResultDataById,
      };
    },
    // Update Expense Report ----------->>>>>>>>>>>>>

    updateExpenseReportStart(state, _action:PayloadAction<ExpenseMainFormAPIValues>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateExpenseReportSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateExpenseReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getScannedReceipts(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getScannedReceiptsSuccess(state, action: PayloadAction<ExpenseReportResult>) {
      return {
        ...state,
        isLoading: false,
        ScannedReceiptsData: action.payload,
      };
    },
    getScannedReceiptsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Delete Scanned Receipts

    deleteScannedReceiptsStart(state, _action: PayloadAction<ExpenseDeleteApiPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteScannedReceiptsSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteScannedReceiptsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Add Scanned Receipts

    AddScannedReceiptsImage(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    AddScannedReceiptsImageSuccess(state, _action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
      };
    },
    AddScannedReceiptsImageFailure(state, _action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const {
  getExpenseReportData, getExpenseReportSuccess, getExpenseReportFailure,
  deleteExpenseReportStart, deleteExpenseReportSuccess, deleteExpenseReportFailure,
  getExpenseReportById, getExpenseReportSuccessById, getExpenseReportFailureById,
  updateExpenseReportStart, updateExpenseReportSuccess, updateExpenseReportFailure,
  getScannedReceipts, getScannedReceiptsSuccess, getScannedReceiptsFailure,
  deleteScannedReceiptsStart, deleteScannedReceiptsSuccess, deleteScannedReceiptsFailure,
  AddScannedReceiptsImage, AddScannedReceiptsImageSuccess, AddScannedReceiptsImageFailure,
  clearExpenseReportData,
} = sliceExpenseReport.actions;
export const expenseReportReducer = sliceExpenseReport.reducer;

export type ExpenseReportActions =
  | ReturnType<typeof getExpenseReportData>
  | ReturnType<typeof getExpenseReportSuccess>
  | ReturnType<typeof getExpenseReportFailure>
  | ReturnType<typeof deleteExpenseReportStart>
  | ReturnType<typeof deleteExpenseReportSuccess>
  | ReturnType<typeof deleteExpenseReportFailure>
  | ReturnType<typeof getExpenseReportById>
  | ReturnType<typeof getExpenseReportSuccessById>
  | ReturnType<typeof getExpenseReportFailureById>
  | ReturnType<typeof updateExpenseReportStart>
  | ReturnType<typeof updateExpenseReportSuccess>
  | ReturnType<typeof updateExpenseReportFailure>
  | ReturnType<typeof getScannedReceipts>
  | ReturnType<typeof getScannedReceiptsSuccess>
  | ReturnType<typeof getScannedReceiptsFailure>
  | ReturnType<typeof deleteScannedReceiptsStart>
  | ReturnType<typeof deleteScannedReceiptsSuccess>
  | ReturnType<typeof deleteScannedReceiptsFailure>
  | ReturnType<typeof AddScannedReceiptsImage>
  | ReturnType<typeof AddScannedReceiptsImageSuccess>
  | ReturnType<typeof AddScannedReceiptsImageFailure>
  | ReturnType<typeof clearExpenseReportData>;
