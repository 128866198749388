import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../../Common/NetworkOps';
import { GetById, ListingResponse } from '../../../../utils/type';
import { addCustomerFailure } from '../../../addCustomer/redux/addCustomerSlice';
import { GetListingPayload } from '../addJob/utils/types';
import { DeleteApiPayload, JobData } from '../utils/types';
import {
  deleteJobFailure,
  deleteJobStart,
  deleteJobSuccess,
  getJobsFailure, getJobsStart, getJobsSuccess, JobsActions,
} from './jobStarterSlice';

// Get Job Listing
async function getJobs(data: GetListingPayload): Promise<ListingResponse<JobData>> {
  const url = `${Config.jobs.getJobs}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<JobData>>(url);
  return result.data;
}

export const getJobsEpic = (action$ : Observable<JobsActions>) => action$.pipe(
  ofType(getJobsStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetListingPayload) => from(getJobs(data)).pipe(
    map((res: ListingResponse<JobData>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getJobsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getJobsFailure();
    }),
    takeUntil(action$.pipe(filter(getJobsStart.match))),
    catchError((error) => of(addCustomerFailure(error))),
  )),
);

// Delete Job
async function deleteJob(data: DeleteApiPayload): Promise<GetById<string>> {
  const url = `${Config.jobs.deleteJob}?JobId=${data.id}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}

export const deleteJobEpic = (action$ : Observable<JobsActions>) => action$.pipe(
  ofType(deleteJobStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteApiPayload) => from(deleteJob(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
        };
        return of(deleteJobSuccess(), getJobsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteJobFailure());
    }),
    takeUntil(action$.pipe(filter(deleteJobStart.match))),
    catchError((error) => of(deleteJobStart(error))),
  )),
);
