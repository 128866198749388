/* eslint-disable max-len */
import React from 'react';

export function JobIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2774_8426)">
        <path d="M21.4057 12.3749C20.7704 11.8513 19.7663 11.8064 19.1073 12.5137L17.4712 14.27C17.2597 13.5902 16.6248 13.0952 15.8764 13.0952H12.9568C12.7352 13.0952 12.6611 13.0105 12.2762 12.7141C10.809 11.435 8.60522 11.4391 7.14245 12.7263L6.06932 13.6711C5.58693 13.4157 5.02365 13.4094 4.53863 13.6518L3.30779 14.267C3.0328 14.4047 2.92151 14.739 3.05878 15.014L6.39844 21.6932C6.53612 21.9682 6.87043 22.0795 7.14541 21.9422L8.37626 21.3266C8.98296 21.0235 9.32062 20.4045 9.29872 19.7745H15.8763C17.096 19.7745 18.2616 19.1919 18.9933 18.216L21.6654 14.653C22.1838 13.9624 22.1018 12.9497 21.4057 12.3749ZM7.87825 20.331L7.14538 20.6976L4.30336 15.014L5.0366 14.6474C5.31158 14.5101 5.64553 14.621 5.7832 14.8964L8.12725 19.5845C8.26456 19.8594 8.15323 20.1934 7.87825 20.331ZM20.7748 13.985L18.1028 17.548C17.5799 18.2453 16.7476 18.6612 15.8764 18.6612H8.91022L6.82442 14.4893L7.87788 13.562C8.92614 12.6399 10.5069 12.6399 11.5552 13.562C12.1727 14.1056 12.5567 14.2084 12.9567 14.2084H15.8763C16.1832 14.2084 16.4329 14.4581 16.4329 14.765C16.4329 15.0719 16.1832 15.3216 15.8763 15.3216H13.0336C12.7263 15.3216 12.477 15.571 12.477 15.8782C12.477 16.1854 12.7263 16.4348 13.0336 16.4348H16.2493C16.7112 16.4348 17.1566 16.2411 17.4712 15.9031L19.9218 13.2725C20.1459 13.0321 20.4895 13.0521 20.7003 13.2365C20.9207 13.4295 20.9445 13.7594 20.7748 13.985Z" fill="#092245" />
        <path d="M16.3333 4.85001H14.8167V4.66667C14.8167 4.21616 14.4505 3.85 14 3.85H12C11.5495 3.85 11.1833 4.21614 11.1833 4.66667V4.85H9.66667L9.66666 4.85C9.21614 4.85002 8.85 5.21615 8.85 5.66667V9.99999C8.85 10.4505 9.21614 10.8167 9.66667 10.8167H16.3333C16.7838 10.8167 17.15 10.4505 17.15 9.99999V5.66667C17.15 5.21615 16.7838 4.85001 16.3333 4.85001ZM16.5167 9.85H16.5167V10C16.5167 10.1009 16.4342 10.1833 16.3333 10.1833H9.66667C9.56576 10.1833 9.48334 10.1009 9.48334 10V7.79487C9.54183 7.80895 9.60311 7.81668 9.66667 7.81668H12.1833V8.16668C12.1833 8.34165 12.325 8.48335 12.5 8.48335H13.5C13.675 8.48335 13.8167 8.34165 13.8167 8.16668V7.81668H16.3333C16.3969 7.81668 16.4582 7.80895 16.5167 7.79488V9.85ZM11.8167 4.66667C11.8167 4.56576 11.8991 4.48334 12 4.48334H14C14.1009 4.48334 14.1833 4.56576 14.1833 4.66667V4.85H11.8167V4.66667ZM12.8167 7.85001V7.48334H13.1833V7.85001H12.8167ZM16.5167 7.00001C16.5167 7.10092 16.4342 7.18334 16.3333 7.18334H13.8167V7.16667C13.8167 6.9917 13.675 6.84999 13.5 6.84999H12.5C12.325 6.84999 12.1833 6.9917 12.1833 7.16667V7.18334H9.66667C9.56576 7.18334 9.48334 7.10092 9.48334 7.00001V5.66668C9.48334 5.56578 9.56576 5.48336 9.66667 5.48336H16.3333C16.4342 5.48336 16.5167 5.56578 16.5167 5.66668V7.00001Z" fill="#092245" stroke="#092245" strokeWidth="0.3" />
      </g>
      <defs>
        <clipPath id="clip0_2774_8426">
          <rect width="19" height="19" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>

  );
}
