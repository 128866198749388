export enum DefaultListFormValue {
  Model = 'Model',
  TypeId = 'TypeId',
  StyleId = 'StyleId',
  Notes = 'Notes',
}

export const DefaultValues = {
  Model: '',
  TypeId: '',
  StyleId: '',
  Notes: '',
};
