/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import ComponentStripsChart from './ComponentStripsChart';
import StripChartTable from './stripChartTable';
import { StripChartSectionProps } from '../types';

function StripChartSection({ showTable, data }:StripChartSectionProps) {
  return (
    <>
      {showTable && (
      <div>
        <StripChartTable />
      </div>
      )}
      {
      data?.map((item) => (
        item?.map((details, index) => (
          <div key={details.Id}>
            { index > 0 && <div className="page-break" />}
            <ComponentStripsChart newDetails={details} />
          </div>
        ))
      ))
    }
    </>
  );
}

export default StripChartSection;
