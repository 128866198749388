import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from '../../lineGraph/components/ComponentChart';
import { LinePointPDF, Vessel } from '../types';
import phaseChart from '../../../assets/phase-chart.svg';
import PhaseChartTable from './phaseChartTable';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';
import { RootState } from '../../../redux/rootState';

function ComponentLineChart({ newDetails: newDeta̵ils }: { newDetails:Vessel }) {
  const [maxCoordinates, setMaxCoordinates] = useState<LinePointPDF | null>(null);
  const [lineData, setLineData] = useState([]);
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const tempData = [
    {
      id: 1,
      phase: 0,
      percentage: 0,
    },
    {
      id: 2,
      phase: 40,
      percentage: 100,
    },
    {
      id: 3,
      phase: 170,
      percentage: 20,
    },
  ];

  useEffect(() => {
    setLineData(newDeta̵ils?.Configuration?.DataList as Empty);
  }, [newDeta̵ils.Id]);

  const convertedData: LinePointPDF[] = tempData?.map((item) => ({
    id: item.id,
    x: item.phase,
    y: item.percentage,
  }));

  useEffect(() => {
    const maxCoords: LinePointPDF | null = null;
    setMaxCoordinates(maxCoords);
  }, [maxCoordinates]);

  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>Part X - Phase Charts</div>
          <div>{`S/N ${reportPdfResponse3?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">{`Phase Chart - ${newDeta̵ils.VesselName}`}</div>
      </div>
      <div>
        <PhaseChartTable tableDetails={newDeta̵ils} />
      </div>

      <div className="phaseChartImageContainer">
        <img src={phaseChart} alt="Phase chart" />
      </div>
      <Chart
        data={lineData}
        verticalLineXValue={0}
        calculatedY={0}
        convertedData={convertedData}
        setMaxCoordinates={setMaxCoordinates}
      />
    </div>
  );
}

export default ComponentLineChart;
