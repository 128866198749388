/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect, useMemo,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { AddTubeListData, TubeListDialog } from '../utils/types';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { TubeListFormValue } from '../utils/ConstantsTubeList';
import { RootState } from '../../../../redux/rootState';
import { tubeListSchema } from '../utils/validations';
import {
  addTubeListStart, resetState, updateTubeList,
} from '../redux/sliceTubeList';
import { IOSSwitch } from '../utils/style';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentTubeListDialog({
  show, setOpenFrom, rowId, handleData,
}: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const { isSuccess, TubeListData } = useSelector((state: RootState) => state.ListTubeReducer);

  const dispatch = useDispatch();

  const {
    formState: { errors }, control, handleSubmit, setValue, reset,
  } = useForm<AddTubeListData>({
    defaultValues: {
      Description: '',
      NwtOnly: true,
    },
    resolver: yupResolver(tubeListSchema as ObjectSchema<AddTubeListData>),
  });

  const onSubmit = (data: AddTubeListData) => {
    if (rowId) {
      const updateData = {
        Id: rowId,
        Description: data.Description,
        NwtOnly: data.NwtOnly,
      };
      dispatch(updateTubeList(updateData));
      reset();
      setOpenFrom();
    } else {
      const bodyData = {
        Description: data.Description,
        NwtOnly: data.NwtOnly,
      };
      dispatch(addTubeListStart(bodyData));
      reset();
      setOpenFrom();
    }
  };

  const tableData: TubeListDialog[] = useMemo(() => TubeListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      Description: item.Description,
      NwtOnly: item.NwtOnly,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [TubeListData]);

  useEffect(() => {
    if (rowId) {
      tableData?.forEach((rowData) => {
        if (rowData?.id === rowId) {
          setValue(TubeListFormValue.DESCRIPTION, rowData.Description);
          setValue(TubeListFormValue.NwtOnly, rowData.NwtOnly);
        }
      });
    }
  }, [rowId, show, setValue]);

  useEffect(() => {
    if (isSuccess) {
      reset();
      if (handleData) {
        handleData();
      }
      dispatch(resetState());
    }
  }, [isSuccess]);

  const handleClose = () => {
    setOpenFrom();
    reset();
    setValue(TubeListFormValue.DESCRIPTION, '');
  };

  const getAnalystHeading = () => {
    if (rowId) {
      return 'Edit Description';
    }
    return 'Add Description';
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {getAnalystHeading()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={8} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">{translations.description}</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 50 }}
                    name={TubeListFormValue.DESCRIPTION}
                    size="small"
                    id={TubeListFormValue.DESCRIPTION}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className="IOSSwitchStyle">
                <Controller
                  name={TubeListFormValue.NwtOnly}
                  control={control}
                  defaultValue
                  render={({ field }) => (
                    <FormControlLabel
                      control={<IOSSwitch {...field} />}
                      label={<span className="IOSSwitchLabel">NWT Only</span>}
                      labelPlacement="start"
                      checked={field.value}
                    />
                  )}
                />
                {/* </div> */}
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentTubeListDialog;
