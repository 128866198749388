import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { showSuccessToaster, showErrorToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  CalibrationProcedureActions, createCalibrationProcedure,
  getCalibrationProcedureData, onFailureCalibrationProcedureData, onSuccessCalibrationProcedureData,
} from './sliceCalibration';
import {
  CalibrationProcedureApiResponse,
  CalibrationProcedureSaveApiResponse, CreateCalibrationProcedurePayload,
} from './Type';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';

async function GetCalibrationProcedureList(data: number): Promise<CalibrationProcedureApiResponse> {
  const url = `${Config.utilities.getCalibration}?Type=${data}`;
  const result = await makeGetRequest<CalibrationProcedureApiResponse>(url);
  return result?.data;
}

export const epicGetCalibrationProcedureData = (action$: Observable<CalibrationProcedureActions>) => action$.pipe(
  filter(getCalibrationProcedureData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(GetCalibrationProcedureList(data)).pipe(
    map((res: CalibrationProcedureApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onSuccessCalibrationProcedureData(res?.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onFailureCalibrationProcedureData(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getCalibrationProcedureData.match))),
    catchError((error: AxiosError<CalibrationProcedureApiResponse>) => of(onFailureCalibrationProcedureData(
      error.response?.data.BMT.ResponseMessage as string,
    ))),
  )),
);

async function createCalibrationProcedureApiCall(data: CreateCalibrationProcedurePayload): Promise<CalibrationProcedureSaveApiResponse> {
  const url = `${Config.utilities.createCalibration}`;
  const result = await makePostRequest<CalibrationProcedureSaveApiResponse>(url, data);
  return result?.data;
}

export const epicCreateCalibrationProcedure = (action$: Observable<CalibrationProcedureActions>) => action$.pipe(
  filter(createCalibrationProcedure.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(createCalibrationProcedureApiCall(data)).pipe(
    mergeMap((res: CalibrationProcedureSaveApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE || res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(getCalibrationProcedureData(data?.UtilityType));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(getCalibrationProcedureData(data?.UtilityType));
    }),
    takeUntil(action$.pipe(filter(createCalibrationProcedure.match))),
    catchError((error: AxiosError<CalibrationProcedureSaveApiResponse>) => of(onFailureCalibrationProcedureData(
      error.response?.data.BMT.ResponseMessage as string,
    ))),
  )),
);
