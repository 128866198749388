import {
  Button, Grid, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import SearchIcon from '@mui/icons-material/Search';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../assets/search_icon.svg';
import styles from './index.module.scss';
import { FilterFields, predefinedRanges } from './utils/DatePickerRange';
import { Entries } from '../../Screens/InventoryManagement/InventoryLandingPage/redux/type';
import { PrintIcon } from '../../assets/TableIcons';
import { FiltersLinesIcon } from '../../assets/TableIcons/Filterslines';
import { EmailIcon } from '../../assets/TableIcons/EmailIcon';
// eslint-disable-next-line import/no-cycle
import AddCustomerModal from '../AddCustomerModal';
import { setFiltersData } from '../../Screens/inspectionLetter/redux/sliceInspectionLetter';
import { RootState } from '../../redux/rootState';
import ComponentSitesModel from '../../Screens/inspectionLetter/InspectionComponents/ComponentSitesModel';
import useOutsideClick from '../../utils/useOutsideClick';

interface ISearchHeaderProps {
  buttonName?: string;
  searchQuery?: string;
  setSearchQuery?: (str: string) => void;
  handleButtonClick?: () => void;
  labelOne?: string;
  labelTwo?: string;
  valueOne?: number;
  valueTwo?: string;
  dateRange?: string;
  handleDateRangeChange?: (value: DateRange) => void;
  dateRangeData?: DateRange | null;
  numberOfUnits?: string;
  setNumberOfUnits?: (units: string) => void;
  searchButton?: string;
  handleSearchButtonClick?: () => void;
  searchButtonDisable?: boolean;
  issueButton?: string;
  handleIssueButton?: () => void;
  issueButtonDisable?: boolean;
  equipmentTypeSelectLabel?: string;
  setSelectedEquipmentType?: (value: string) => void;
  selectedEquipmentType?: Entries[];
  inventoryTypeSelectLabel?: string;
  setSelectedInventory?: (value: string) => void;
  selectedInventory?: Entries[];
  selectedEquipmentTypeValue?: string;
  selectedInventoryValue?: string;
  rowId?: string;
  textButtonDisabled?: boolean;
  generateInvoiceButton?: string;
  handleGenerateInvoiceButton?: () => void;
  generateInvoiceButtonDisabled?: boolean;
  handlePrintIcon?: () => void;
  disableHandlePrintIcon?: boolean;
  handleButtonClickFifth?: () => void;
  buttonNameFifth?: string;
  filter?: boolean;
  handleEmail?: () => void;
}

function SearchHeader({
  setSearchQuery, handleDateRangeChange, setNumberOfUnits, searchButtonDisable, issueButton,
  handleIssueButton, issueButtonDisable, equipmentTypeSelectLabel, setSelectedEquipmentType, selectedEquipmentType,
  inventoryTypeSelectLabel, setSelectedInventory, selectedInventory, selectedEquipmentTypeValue, selectedInventoryValue,
  textButtonDisabled, handleGenerateInvoiceButton, generateInvoiceButton, generateInvoiceButtonDisabled,
  rowId, handlePrintIcon, disableHandlePrintIcon, filter = false, handleEmail, ...props
}: ISearchHeaderProps) {
  const today = new Date();
  const previousMonth = new Date(today);
  previousMonth.setMonth(today.getMonth() - 1);
  const { filersData } = useSelector((state: RootState) => state.inspectionLetterReducer);
  const filterRef = useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [open, setOpen] = useState(false);
  const [openSite, setOpenSite] = useState(false);
  const [dateOpen, setDateOpen] = useState(false);

  const filterBox = useOutsideClick(() => {
    if (!open && !openSite && !dateOpen) {
      setShowFilter(false);
      setOpen(false);
      setOpenSite(false);
      setDateOpen(false);
    }
  }, filterRef);

  const {
    control, setValue, handleSubmit,
  } = useForm<FilterFields>({
    // resolver: yupResolver(customerSiteFormSchema as ObjectSchema<CustomerSiteFormInfo>),
  });
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const changeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSearchQuery) {
      setSearch(event.target.value);
      setSearchQuery(encodeURIComponent(event.target.value));
    }
  };

  const changeUnitsValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setNumberOfUnits) {
      setNumberOfUnits(event.target.value);
    }
  };

  const getSearchButtonClass = () => {
    if (searchButtonDisable) {
      return `${styles.disableBtn} leftBtn`;
    }
    return `${styles.addBtn} leftBtn`;
  };

  const getIssueButtonClass = () => {
    if (issueButtonDisable) {
      return `${styles.disableBtn} leftBtn`;
    }
    return `${styles.addBtn} leftBtn`;
  };

  const getGenerateInvoiceButtonClass = () => {
    if (generateInvoiceButtonDisabled) {
      return `${styles.disableBtn} leftBtn`;
    }
    return `${styles.addBtn} leftBtn`;
  };
  const getTextButtonClass = () => {
    if (textButtonDisabled) {
      return `${styles.disableBtn} leftBtn`;
    }
    return `${styles.addBtn} leftBtn`;
  };

  const getKitFields = () => {
    if (!props?.labelOne && !props?.labelTwo) {
      return null;
    }
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={5.5}
        lg={7.5}
        xl={3}
        order={{
          lg: 3, xl: 2, md: 3,
        }}
      >
        {
          props?.labelOne
          && (
            <div className={styles.main_styles}>
              <div className={styles.label_style}>{props?.labelOne}</div>
              <div className={styles.value_style}>
                &nbsp;
                {props?.valueOne}
              </div>
            </div>
          )
        }
        {
          props?.labelTwo
          && (
            <div className={styles.main_styles}>
              <div className={styles.label_style}>
                {' '}
                {props?.labelTwo}
              </div>
              <div className={styles.value_style}>
                &nbsp;
                {props?.valueTwo}
              </div>
            </div>
          )
        }
      </Grid>
    );
  };

  const getLg = () => {
    if (equipmentTypeSelectLabel || inventoryTypeSelectLabel) {
      return 4;
    }
    if (props?.dateRange || setNumberOfUnits) {
      return 2;
    }
    return 6;
  };

  const getMd = () => {
    if (props?.dateRange || setNumberOfUnits) {
      return 6;
    }
    return 6;
  };

  const handleFilter = () => {
    setShowFilter(true);
  };

  const handleDateChange = (value: DateRange | null) => {
    setDateRange(value);
  };

  const handleAction = () => {
    setOpen(!open);
  };

  const handleTabClick = (custId: string) => {
    setValue('customer', custId);
  };

  const handleSetSiteClick = (siteId: string) => {
    setValue('site', siteId);
  };

  const onSubmit = (values: FilterFields) => {
    const filtersData = {
      fromDate: dateRange ? format(dateRange[0], 'dd-MM-yyyy') : null,
      toDate: dateRange ? format(dateRange[1], 'dd-MM-yyyy') : null,
      customerId: values.customer || '',
      siteId: values.site || '',
      dateRangeData: dateRange,
    };
    dispatch(setFiltersData(filtersData));
  };

  const handleFilterClear = () => {
    const filtersData = {
      fromDate: null,
      toDate: null,
      customerId: '',
      siteId: '',
      dateRangeData: null,
    };
    dispatch(setFiltersData(filtersData));
  };

  useEffect(() => {
    setValue('customer', filersData.customerId || '');
    setValue('site', filersData.siteId || '');
    setDateRange(filersData.dateRangeData);
  }, [showFilter, filersData]);

  const handleSiteAction = () => {
    setOpenSite(!open);
  };

  return (
    <div className={styles.mainDiv}>
      <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
        <Grid item sm={12} xs={12} md={getMd()} lg={getLg()}>
          {setSearchQuery && (
            <TextField
              className={`${styles.subHeaderTextfield} nonCapitalizeField`}
              size="small"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="" />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={changeInputValue}
              value={search}
            />
          )}
        </Grid>
        {equipmentTypeSelectLabel && (
          <Grid item sm={12} xs={12} md={6} lg={2}>
            <div className={styles.alignText}>
              <div className={styles.textStyle}>{equipmentTypeSelectLabel}</div>
              <Select
                style={{ width: '100%', height: '40px' }}
                size="medium"
                value={selectedEquipmentTypeValue}
                onChange={(event: SelectChangeEvent<string | number>) => {
                  if (setSelectedEquipmentType) {
                    setSelectedEquipmentType(String(event.target.value));
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '220px', // Adjust the max height value as needed
                      maxWidth: '220px',
                    },
                  },
                }}
              >
                {selectedEquipmentType?.map((item: Entries) => (
                  <MenuItem className="menu-item-enter" value={item.Value} key={item.Value}>
                    {item.Text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        )}
        {inventoryTypeSelectLabel && (
          <Grid item sm={12} xs={12} md={6} lg={2}>
            <div className={styles.alignText}>
              <div className={styles.textStyle}>{inventoryTypeSelectLabel}</div>
              <Select
                style={{ width: '100%', height: '40px' }}
                size="medium"
                value={selectedInventoryValue}
                onChange={(event: SelectChangeEvent<string | number>) => {
                  if (setSelectedInventory) {
                    setSelectedInventory(String(event.target.value));
                  }
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '220px', // Adjust the max height value as needed
                      maxWidth: '220px',
                    },
                  },
                }}
              >
                {selectedInventory?.map((item: Entries) => (
                  <MenuItem className="menu-item-enter" value={item.Value} key={item.Value}>
                    {item.Text}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>
        )}
        {
          setNumberOfUnits && (
            <Grid item xs={12} sm={6} md={7}>
              <div className="textStyle">
                <TextField
                  className={styles.textStyle}
                  size="small"
                  placeholder="# of Units"
                  value={props?.numberOfUnits}
                  onChange={changeUnitsValue}
                />
              </div>
            </Grid>
          )
        }

        {getKitFields()}

        {
          props?.dateRange
          && (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4}
              xl={3}
              order={{
                xl: 3, lg: 2, md: 2,
              }}
            >
              <div className="rangeDatePicker">
                <DateRangePicker
                  // value={dateRangeData}
                  onChange={handleDateRangeChange}
                  ranges={predefinedRanges}
                  placeholder="Select Range"
                  className="customDateRangeCalender"
                  character=" - "
                  showCleanButton
                  format="MM-dd-yyyy"
                  placement="bottomEnd"
                />
              </div>
            </Grid>
          )
        }
        <Grid
          item
          xs={12}
          sm={12}
          md={props.buttonNameFifth ? 12 : 6.5}
          lg={props.buttonNameFifth ? 12 : 4.5}
          xl={props.buttonNameFifth ? 6 : 4}
          order={{
            xl: 4, lg: 4, md: 4,
          }}
        >
          <div className={styles.divStyleButton}>
            {rowId
              && (
                <div className={styles.rightTextClassWrapper}>
                  <div className={styles.rightHeadingClass}>
                    Product #
                  </div>
                  <div className={styles.rightTextClass}>
                    {rowId}
                  </div>
                </div>
              )}
            {generateInvoiceButton
              && (
                <Button disabled={generateInvoiceButtonDisabled} onClick={handleGenerateInvoiceButton} className={getGenerateInvoiceButtonClass()}>
                  {generateInvoiceButton}
                </Button>
              )}
            {props?.searchButton
              && (
                <Button disabled={searchButtonDisable} onClick={props.handleSearchButtonClick} className={getSearchButtonClass()}>
                  {props.searchButton}
                </Button>
              )}
            {issueButton
              && (
                <Button disabled={issueButtonDisable} onClick={handleIssueButton} className={getIssueButtonClass()}>
                  {issueButton}
                </Button>
              )}
            {props?.buttonName
              && (
                <Button disabled={textButtonDisabled} onClick={props.handleButtonClick} className={getTextButtonClass()}>
                  {props.buttonName}
                </Button>
              )}
            {props?.buttonNameFifth
              && (
                <Button disabled={textButtonDisabled} onClick={props.handleButtonClickFifth} className={getTextButtonClass()}>
                  {props.buttonNameFifth}
                </Button>
              )}
            {handlePrintIcon && !disableHandlePrintIcon
              && (
                <div className={styles.printShippingClass}>
                  <div className={styles.printShippingTextClass}>Print Shipping List</div>
                  <div style={{ cursor: 'pointer' }} onClick={handlePrintIcon}>
                    <PrintIcon />
                  </div>
                </div>
              )}
          </div>
        </Grid>
        { filter && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
            <div style={{
              display: 'flex', justifyContent: 'flex-end', gap: '8px', alignItems: 'center', position: 'relative',
            }}
            >
              <Button
                sx={{
                  color: '#344054', fontSize: '12px', padding: '8px 13px', border: '0.82px solid #EFEFEF',
                }}
                ref={filterRef}
                onClick={handleFilter}
                variant="outlined"
                startIcon={<FiltersLinesIcon />}
              >
                Filter
              </Button>
              <IconButton className={styles.email_icon_btn} onClick={handleEmail}>
                <EmailIcon />
              </IconButton>
            </div>
          </Grid>
          { showFilter && (
          <form>
            <div className={styles.Filter_div} ref={filterBox}>
              <div className={styles.FilterLabel}>
                Filter
              </div>
              <div style={{
                padding: '15px 25px', display: 'flex', flexDirection: 'column', gap: 15,
              }}
              >
                <Controller
                  control={control}
                  name="site"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      value={value}
                      className="nonCapitalizeField"
                      fullWidth
                      autoComplete="off"
                      onClick={handleSiteAction}
                      onChange={onChange}
                      id="site"
                      placeholder="Site"
                        // error={!!errors.siteId}
                        // helperText={errors?.siteId?.message}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Search here.." className={styles.filterSearchIconStyle} placement="left">
                            <IconButton className="search_icon_btn">
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="customer"
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      size="small"
                      value={value}
                      className="nonCapitalizeField"
                      fullWidth
                      onClick={handleAction}
                      onChange={onChange}
                      id="customer"
                      placeholder="Customer"
                        // error={!!errors.siteId}
                        // helperText={errors?.siteId?.message}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Search here.." className={styles.filterSearchIconStyle} placement="left">
                            <IconButton className="search_icon_btn">
                              <SearchIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  )}
                />
                <div className={styles.filterRangeDatePicker}>
                  <DateRangePicker
                    name="Range"
                    open={dateOpen}
                    onClick={() => setDateOpen(true)}
                    onOk={() => setDateOpen(false)}
                    value={dateRange}
                    onChange={handleDateChange}
                    ranges={predefinedRanges}
                    placeholder="Date Range"
                    className={styles.FilterCustomDateRangeCalender}
                    character=" - "
                    showCleanButton
                    format="MM-dd-yyyy"
                    placement="bottomEnd"
                  />
                </div>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleFilterClear}>Clear All</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      className="button_save_and_next"
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <AddCustomerModal
              show={open}
              setOpenFrom={handleAction}
              handleTabCell={handleTabClick}
            />
            <ComponentSitesModel show={openSite} setShow={() => setOpenSite(false)} handleTabCell={handleSetSiteClick} />
          </form>
          )}
        </>
        )}
      </Grid>
    </div>
  );
}

export default SearchHeader;
