import { Grid, Card, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { customOptions } from '../../../Common/TextEditorOptions';
import {
  createCalibrationProcedure,
  getCalibrationProcedureData, setInspectionProcedureDescription,
} from '../calibrationProcedure/Redux/sliceCalibration';
import { DEFAULT_VALUE_INSPECTION, INSPECTION_UTILITY_TYPE } from '../constant';
import { CalibrationProcedureApiData } from '../calibrationProcedure/Redux/Type';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';

export default function InspectionProcedureComponent() {
  const [saveDisable, setSaveDisable] = useState(true);
  const dispatch = useDispatch();

  const {
    inspectionProcedureDescription,
    calibrationProcedureData, isLoading,
  } = useSelector((state: RootState) => state.CalibrationProcedureReducer);

  // Function to update the editor state when content changes
  const handleComment = (newEditorState: EditorState) => {
    dispatch(setInspectionProcedureDescription(newEditorState));
  };

  useEffect(() => {
    dispatch(getCalibrationProcedureData(DEFAULT_VALUE_INSPECTION));
  }, []);

  // Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const getDescriptionByType = (type: string) => calibrationProcedureData?.find((ele: CalibrationProcedureApiData) => ele?.UtilityType === type);

  const setInspectionProcedureText = () => {
    if (calibrationProcedureData?.length > 0) {
      const inspectionDescription = getDescriptionByType(INSPECTION_UTILITY_TYPE);
      if (inspectionDescription?.Description) {
        const convertedEddyCurrent = htmlToEditorState(inspectionDescription?.Description);
        dispatch(setInspectionProcedureDescription(convertedEddyCurrent));
      }
    }
  };

  useEffect(() => {
    setInspectionProcedureText();
  }, [calibrationProcedureData]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleSave = () => {
    setSaveDisable(true);
    const eddyCurrentId = calibrationProcedureData?.find((ele) => ele?.UtilityType === INSPECTION_UTILITY_TYPE);
    const convertedEddyCurrentDescription = getHtmlFromEditorHTMLtoString(inspectionProcedureDescription);
    const reqBody = {
      UtilityType: DEFAULT_VALUE_INSPECTION,
      TestType: '',
      Description: convertedEddyCurrentDescription,
    };
    if (eddyCurrentId?.Id) {
      const updateReqBody = {
        ...reqBody,
        Id: eddyCurrentId?.Id,
      };
      dispatch(createCalibrationProcedure(updateReqBody));
      return;
    }
    dispatch(createCalibrationProcedure(reqBody));
  };

  const handleEdit = () => {
    setSaveDisable(false);
  };

  return (
    <>
      <ComponentPageHeader subHeading="INSPECTION PROCEDURE" />
      <div className="container_job">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Card className="comment-card">
                  <div className="customerInfo_main_container">
                    <Grid container spacing={2}>
                      <Grid item md={12} sm={12} xs={12}>
                        <div className="editorContainer editContainerWithEditBtn" style={{ position: 'relative' }}>
                          <div className="editorBtn">
                            <Button
                              disabled={!saveDisable}
                              className={!saveDisable ? 'disableViewOilKitsBtn' : 'button_save_and_next'}
                              onClick={handleEdit}
                            >
                              Edit
                            </Button>
                          </div>
                          <Editor
                            editorState={inspectionProcedureDescription}
                            onEditorStateChange={handleComment}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            localization={{ locale: 'en' }}
                            toolbar={customOptions}
                            readOnly={saveDisable}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button
                  disabled={saveDisable}
                  onClick={() => {
                    setInspectionProcedureText();
                    setSaveDisable(true);
                  }}
                  className={saveDisable ? 'disableViewOilKitsBtn' : 'button_cancel'}
                >
                  Cancel
                </Button>
              </div>
              <div className="button_margin_left">
                <Button
                  disabled={saveDisable}
                  className={saveDisable ? 'disableViewOilKitsBtn' : 'button_save_and_next'}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
