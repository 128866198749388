import { AddSmcFormValue } from './ConstantSmc';
import { GetSmcCustomerListResult, SMCSubscribedCustomerData, SmcUserListByIdResult } from './TypeSmc';

export function formatTableData(data: GetSmcCustomerListResult[]) {
  const res: SMCSubscribedCustomerData[] = [];

  data.forEach((col: GetSmcCustomerListResult, index) => {
    const obj = {
      sn: index + 1,
      action: 'edit&delete',
      markUp: '',
      cust: '',
      company: '',
      address: '',
      city: '',
      state: '',
      phone: '',
      id: '',
    };

    obj.markUp = col.Markup;
    obj.cust = col.Custid;
    obj.company = col.Customername;
    obj.address = col.Address;
    obj.city = col.CityName;
    obj.state = col.StateName;
    obj.phone = col.Phone;
    obj.id = col.Custid;

    res.push(obj);
  });

  return res;
}

export const assignSmcValues = (selectedSmcDetails: SmcUserListByIdResult, setValue: SetValue) => {
  setValue(AddSmcFormValue.ADDRESS, selectedSmcDetails.Address);
  setValue(AddSmcFormValue.SMC_NAME, selectedSmcDetails.SmcName);
  setValue(AddSmcFormValue.TAI_ID_NUMBER, selectedSmcDetails.TaiIdNo);
  setValue(AddSmcFormValue.URL, selectedSmcDetails.Url);
  setValue(AddSmcFormValue.PHONE_CODE, selectedSmcDetails.PhoneCode);
  setValue(AddSmcFormValue.ADDRESS_OPTIONAL, selectedSmcDetails.AddressOptional);
  setValue(AddSmcFormValue.PHONE_NUMBER, selectedSmcDetails.PhoneNumber);
  setValue(AddSmcFormValue.COUNTRY, selectedSmcDetails.CountryCode);
  setValue(AddSmcFormValue.STATE, selectedSmcDetails.StateName);
  setValue(AddSmcFormValue.OTHER_CITY, selectedSmcDetails.IsOtherCity ? selectedSmcDetails.CityName : '');
  setValue(AddSmcFormValue.CITY, selectedSmcDetails.CityName);
  setValue(AddSmcFormValue.ZIP, selectedSmcDetails.ZipCode);
};
