import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../Common/NetworkOps';
import { addCustomerFailure } from '../../addCustomer/redux/addCustomerSlice';
import { DeleteApiResponse } from '../../addCustomer/utils/types';
import { DeleteCustomer, GetCustomerApiResponse, GetCustomerPayload } from '../utils/TypeCustomer';
import {
  CustomerActions, deleteCustomerFailure, deleteCustomerStart, deleteCustomerSuccess, getCustomersFailure, getCustomersStart, getCustomersSuccess,
} from './customerSlice';

async function getCustomers(data: GetCustomerPayload): Promise<GetCustomerApiResponse> {
  const url = `${Config.auth.getCustomers}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetCustomerApiResponse>(url);
  return result.data;
}

export const getCustomersEpic = (action$ : Observable<CustomerActions>) => action$.pipe(
  ofType(getCustomersStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetCustomerPayload) => from(getCustomers(data)).pipe(
    map((res: GetCustomerApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getCustomersSuccess(res.BMT.Result);
      }
      return getCustomersFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getCustomersStart.match))),
    catchError((error) => of(addCustomerFailure(error))),
  )),
);

// Delete Customer
async function deleteCustomer(data: DeleteCustomer): Promise<DeleteApiResponse> {
  const url = `${Config.auth.deleteCustomer}?custId=${data.customerId}`;
  const result = await makeDeleteRequest<DeleteApiResponse>(url);
  return result.data;
}

export const deleteCustomerEpic = (action$ : Observable<CustomerActions>) => action$.pipe(
  ofType(deleteCustomerStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteCustomer) => from(deleteCustomer(data)).pipe(
    mergeMap((res: DeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data.page,
          searchQuery: data.searchQuery,
          rowsPerPage: data.rowsPerPage,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteCustomerSuccess(), getCustomersStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteCustomerFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteCustomerStart.match))),
    catchError((error) => of(deleteCustomerFailure(error))),
  )),
);
