import React from 'react';
import { useSelector } from 'react-redux';
import SummaryInspectionTable from './summaryInspectionTable';
import { RootState } from '../../../redux/rootState';
import { reportHeading } from '../helper';

function SummaryInspectionSection() {
  const { reportPdfResponse2, reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>Part IV - Summary of Inspection</div>
          <div>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">Summary of Inspection</div>
        <div className="headingDescriptionText">
          {`An ${reportHeading(reportPdfResponse1?.FrontPage?.TestType)} inspection was performed due to a known/suspected tube leak with the
          following results.`}
        </div>
      </div>
      {reportPdfResponse2?.Summary_of_Inspection?.map((details) => (
        <SummaryInspectionTable summaryDetails={details} />
      ))}
    </div>
  );
}

export default SummaryInspectionSection;
