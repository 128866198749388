import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InventoryInitialStateInterface, InventoryMasterResponseData, InventoryResponseData, IssueHistoryResponseData, UpcomingJobsDataResult,
} from './type';

const initialState: InventoryInitialStateInterface = {
  inventoryData: {} as InventoryResponseData,
  isLoading: false,
  isLoadingInventory: false,
  isIssueHistoryLoading: false,
  isInventoryDeleted: false,
  issueHistoryData: {} as IssueHistoryResponseData,
  inventoryMaster: {} as InventoryMasterResponseData,
  isMasterDataGot: false,
  upcomingJobsData: {} as UpcomingJobsDataResult,
  isLoadingUpcomingJobs: false,
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    getInventoryMasterStart(state) {
      return {
        ...state,
        isMasterDataGot: false,
      };
    },
    getInventoryMasterSuccess(state, action: PayloadAction<InventoryMasterResponseData>) {
      return {
        ...state,
        inventoryMaster: action.payload,
      };
    },
    getInventoryMasterFailure(state) {
      return {
        ...state,
        isMasterDataGot: false,
      };
    },
    getInventoryStart(state, _action) {
      return {
        ...state,
        isLoadingInventory: true,
      };
    },
    getInventorySuccess(state, action: PayloadAction<InventoryResponseData>) {
      return {
        ...state,
        isLoadingInventory: false,
        inventoryData: action.payload,
      };
    },
    getInventoryFailure(state) {
      return {
        ...state,
        isLoadingInventory: false,
      };
    },
    onInventoryDeleteStart(state, _action) {
      return {
        ...state,
        isInventoryDeleted: false,
      };
    },
    onInventoryDeleteSuccess(state) {
      return {
        ...state,
        isInventoryDeleted: true,
      };
    },
    onInventoryDeleteFailure(state) {
      return {
        ...state,
        isInventoryDeleted: false,
      };
    },
    getIssueHistoryStart(state, _action) {
      return {
        ...state,
        isIssueHistoryLoading: true,
      };
    },
    getIssueHistorySuccess(state, action: PayloadAction<IssueHistoryResponseData>) {
      return {
        ...state,
        isIssueHistoryLoading: false,
        issueHistoryData: action.payload,
      };
    },
    getIssueHistoryFailure(state) {
      return {
        ...state,
        isIssueHistoryLoading: false,
      };
    },
    onReturnEquipment(state, _action) {
      return {
        ...state,
        isIssueHistoryLoading: false,
      };
    },
    onIssueEquipment(state, _action) {
      return {
        ...state,
        isIssueHistoryLoading: false,
      };
    },
    addEquipmentStart(state, _action) {
      return {
        ...state,
        isIssueHistoryLoading: false,
      };
    },
    addEquipmentStartSuccess(state) {
      return {
        ...state,
        isIssueHistoryLoading: false,
      };
    },
    getUpcomingJobsStart(state, _action) {
      return {
        ...state,
        isLoadingUpcomingJobs: true,
      };
    },
    getUpcomingJobsSuccess(state, action: PayloadAction<UpcomingJobsDataResult>) {
      return {
        ...state,
        upcomingJobsData: action.payload,
        isLoadingUpcomingJobs: false,
      };
    },
    getUpcomingJobsFailure(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isLoadingUpcomingJobs: action.payload,
      };
    },
  },
});

export const {
  getInventoryStart, getInventorySuccess, getInventoryFailure, onInventoryDeleteStart,
  onInventoryDeleteSuccess, onInventoryDeleteFailure, getIssueHistoryStart, getIssueHistorySuccess,
  getIssueHistoryFailure, onReturnEquipment, onIssueEquipment, addEquipmentStart, getInventoryMasterStart,
  getInventoryMasterSuccess, getInventoryMasterFailure, addEquipmentStartSuccess, getUpcomingJobsStart,
  getUpcomingJobsSuccess, getUpcomingJobsFailure,
} = inventorySlice.actions;
export const inventoryReducer = inventorySlice.reducer;

export type InventoryActions =
  | ReturnType<typeof getInventoryStart>
  | ReturnType<typeof getInventorySuccess>
  | ReturnType<typeof getInventoryFailure>
  | ReturnType<typeof onInventoryDeleteStart>
  | ReturnType<typeof onInventoryDeleteSuccess>
  | ReturnType<typeof onInventoryDeleteFailure>
  | ReturnType<typeof getIssueHistoryStart>
  | ReturnType<typeof getIssueHistorySuccess>
  | ReturnType<typeof getIssueHistoryFailure>
  | ReturnType<typeof onReturnEquipment>
  | ReturnType<typeof onIssueEquipment>
  | ReturnType<typeof addEquipmentStart>
  | ReturnType<typeof getInventoryMasterStart>
  | ReturnType<typeof getInventoryMasterSuccess>
  | ReturnType<typeof getInventoryMasterFailure>
  | ReturnType<typeof addEquipmentStartSuccess>
  | ReturnType<typeof getUpcomingJobsStart>
  | ReturnType<typeof getUpcomingJobsSuccess>
  | ReturnType<typeof getUpcomingJobsFailure>;
