import {
  Circle,
  CircleInfo, PolygonInterface, RemovedConfigType, TubeConfiguration, UpdateTubeConfiguration,
} from '../tubeChartTypes';

export function getUpdateTubePayload(
  jobOrder:string,
  vesselId: string,
  polygonList:PolygonInterface[],
  chartList:TubeConfiguration[],
) {
  const detailsPayload:TubeConfiguration[] = polygonList.map((poly:PolygonInterface, index:number) => {
    const circleArray:CircleInfo[] = [];
    const removedConfig:RemovedConfigType = {};
    poly.circles.forEach((circleArr:Circle[]) => {
      circleArr.forEach((value:Circle) => {
        const circle:CircleInfo = {
          Position: `${value.x},${value.y}`,
          Fill: value.visible ? '#00FFFFFF' : '#00FFFFFF',
          Stroke: value.visible ? '#FF000000' : '#00FFFFFF',
        };

        if (!value.visible) {
          if (removedConfig[value.x]) {
            removedConfig[value.x].push(value.y);
          } else {
            removedConfig[value.x] = [value.y];
          }
        }
        circleArray.push(circle);
      });
    });

    const {
      IsOte,
      TubeSize,
      NumOfRows,
      RowLength,
      Panel,
      Group,
      Layout,
      IsTestEndChanges,
      ChartId,
      VesselTop,
      Offset,
      OriginalTestEnd,
    } = chartList[index];

    return {
      CircleInfos: circleArray,
      IsOte,
      TubeSize,
      NumOfRows,
      RowLength,
      Panel,
      Group,
      Layout,
      IsTestEndChanges,
      ChartId,
      VesselId: vesselId,
      VesselTop,
      Offset,
      OriginalTestEnd,
      RemovedConfig: removedConfig,
    };
  });

  const payload:UpdateTubeConfiguration = {
    JobOrder: jobOrder,
    details: detailsPayload,
  };

  return payload;
}
