import { Button, Card, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { MainFormFields, MainFormLabel } from '../../utils/constant';
import { MainFields, MainFormProps } from '../../utils/types';
import { CommonDateInput } from '../../../../Common/CommonDateInput';
import { setSelectedTab } from '../../redux/oilReportSlice';

export function Main({
  control, errors, trigger, isValid,
}:MainFormProps) {
  const requiredFields:string[] = useMemo(() => [], []);
  const dateFields:string[] = useMemo(() => [MainFormFields.ReportDate,
    MainFormFields.DateReceived, MainFormFields.DateDrawn, MainFormFields.DateTested], []);

  const mainFields:MainFields[] = useMemo(() => Object.entries(MainFormLabel).slice(1).map(([field, label]) => ({
    field,
    label,
    isRequired: requiredFields.includes(field),
    isDateField: dateFields.includes(field),
  })), []);

  const dispatch = useDispatch();

  const handleNext = () => {
    trigger();
    dispatch(setSelectedTab(1));
  };

  const handleChange = () => {
    trigger();
  };

  return (
    <>
      <Card>
        <div className="customerInfo_container">
          <div className="customerInfo" />
          <div className="customer_info_checkbox dropdown_side_label" />
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            {mainFields.map((entry:MainFields) => (
              <Grid key={entry.field} item xs={12} sm={12} md={6} lg={5} xl={4}>
                {!entry.isDateField ? (
                  <div className="div_label_text">
                    <label className="label_Style_Customer">{entry.label}</label>
                    <ComponentTextInput
                      control={control}
                      required={entry.isRequired}
                      capitalizeFirst
                      errors={errors}
                      inputProps={{ maxLength: 30 }}
                      name={entry.field}
                      id={entry.field}
                      size="small"
                    />
                  </div>
                ) : (
                  <div className="div_label_text">
                    <label className="label_Style_Customer">{entry.label}</label>
                    <CommonDateInput
                      control={control}
                      errors={errors}
                      name={entry.field}
                      handleChange={handleChange}
                    />
                  </div>
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
      <br />
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div className="button_margin_left">
            <Button className="button_save_and_next" disabled={!isValid} onClick={handleNext}>Next</Button>
          </div>
        </div>
      </Grid>
    </>
  );
}
