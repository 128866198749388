import React from 'react';
import ArrowOutwardTwoToneIcon from '@mui/icons-material/ArrowOutwardTwoTone';
import PinchIcon from '@mui/icons-material/Pinch';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import DrawIcon from '@mui/icons-material/Draw';
import CropDinIcon from '@mui/icons-material/CropDin';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

export enum DrawAction {
  Select = 'select',
  Rectangle = 'rectangle',
  Circle = 'circle',
  Scribble = 'freedraw',
  Arrow = 'arrow',
  Text = 'text',
}

export const PAINT_OPTIONS = [
  {
    id: DrawAction.Select,
    label: 'Select Shapes',
    icon: <PinchIcon />,
  },
  { id: DrawAction.Rectangle, label: 'Draw Rectangle Shape', icon: <CropDinIcon /> },
  { id: DrawAction.Circle, label: 'Draw Cirle Shape', icon: <PanoramaFishEyeIcon /> },
  { id: DrawAction.Arrow, label: 'Draw Arrow Shape', icon: <ArrowOutwardTwoToneIcon /> },
  { id: DrawAction.Scribble, label: 'Scribble', icon: <DrawIcon /> },
  { id: DrawAction.Text, label: 'Add Text', icon: <FormatColorTextIcon /> },
];
