import React, { forwardRef, useContext } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import warningLogo from '../../../assets/warning.svg';
import styles from '../utils/styles.module.scss';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

interface DialogBoxConfirmationProps {
  show: boolean;
  setOpenFrom: () => void;
  confirmAction: () => void;
}

function DialogBoxConfirmation({ show, setOpenFrom, confirmAction }: DialogBoxConfirmationProps): JSX.Element {
  const { translations } = useContext(LocalizationContext);

  const handleProceed = () => {
    confirmAction();
    // setOpenFrom();
  };

  return (
    <Dialog
      maxWidth="md"
      open={show}
      transitionDuration={500}
      TransitionComponent={Transition}
      keepMounted
      onClose={setOpenFrom}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={setOpenFrom}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialogContent_style">
        <div className={styles.main_container}>
          <div className={styles.first_div}>
            <img src={warningLogo} alt="Warning" height={100} width={100} />
            <h2 className="logoutHeading">
              Changing additional info here would change it in other summaries related to this Job Order
            </h2>
          </div>
          <div className={styles.second_div}>
            <div>
              <LoadingButton
                variant="contained"
                className={styles.logout_btn}
                onClick={handleProceed}
              >
                Proceed
              </LoadingButton>
            </div>
            <div className="button_margin_left">
              <Button className={styles.logout_cancel_btn} onClick={setOpenFrom}>{translations.cancel}</Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DialogBoxConfirmation;
