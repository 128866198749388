/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import ComponentLineChart from './ComponentLineChart';

function LineChartSection() {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <>
      {
      reportPdfResponse3?.Phase_Charts?.map((item) => (
        item?.map((details, index) => (
          <div key={details.Id}>
            { index > 0 && <div className="page-break" />}
            <ComponentLineChart newDetails={details} />
          </div>
        ))
      ))
    }
    </>
  );
}

export default LineChartSection;
