import { RecommendationCodesApiData } from '../recommendationCodes/Redux/Type';
import { RecommendationTextData } from './utils/TypeRecommendationTexts';

export function formatTableData(data: RecommendationCodesApiData[]) {
  const res: RecommendationTextData[] = [];
  data.forEach((col: RecommendationCodesApiData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      description: '',
      id: '',
    };

    obj.description = col.Description;
    obj.id = col.Id;

    res.push(obj);
  });

  return res;
}
