import { Card, Grid } from '@mui/material';
import React from 'react';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { ASMESectionVEnum, SecondCompProps } from '../../utils/type';
import { TypeDropDownValues } from '../../utils/constant';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';

export default function ProbeCabel({ control, errors }: SecondCompProps) {
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Probe Cable
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Length (Ft.)
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                isDisable={roleId === ANALYST_ROLE_ID}
                name={ASMESectionVEnum.probeCableLength}
                id="AnalystForm.firstName"
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Type
              </label>
              <ComponentSelectInput
                name={ASMESectionVEnum.probeCableType}
                control={control}
                size="small"
                disabled={roleId === ANALYST_ROLE_ID}
                entries={TypeDropDownValues}
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
