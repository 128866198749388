import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function DataSheetTopTable() {
  const { reportPdfResponse2 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Location
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Model
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Serial Number
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Date
        </td>
      </tr>
      <tr>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Location}</td>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Model}</td>
        <td className="textCenter">{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</td>
        <td className="textCenter">{reportPdfResponse2?.Data_Sheets?.DataSheetCommon?.Date}</td>
      </tr>
    </table>
  );
}

export default DataSheetTopTable;
