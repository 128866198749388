/* eslint-disable max-len */
import React from 'react';

export function PhotosIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13196_5322)">
        <path d="M12.1154 9.68066C11.1014 9.68066 10.2793 10.5027 10.2793 11.5168C10.2793 12.5308 11.1013 13.3529 12.1154 13.3529C13.1294 13.3529 13.9515 12.5308 13.9515 11.5168C13.9515 10.5027 13.1295 9.68066 12.1154 9.68066ZM12.1154 12.4785C11.5842 12.4785 11.1536 12.0479 11.1536 11.5167C11.1536 10.9856 11.5842 10.555 12.1154 10.555C12.6466 10.555 13.0772 10.9856 13.0772 11.5167C13.0772 12.0479 12.6466 12.4785 12.1154 12.4785Z" fill="#092245" />
        <path d="M19.438 5.48381L7.50341 4.12863C7.04023 4.06278 6.57093 4.198 6.2138 4.50024C5.85671 4.77712 5.62781 5.18755 5.57991 5.63685L5.36135 7.42924H4.68369C3.72192 7.42924 3.00059 8.28169 3.00059 9.24346V18.1835C2.97637 19.1006 3.70021 19.8638 4.61738 19.888C4.63947 19.8886 4.6616 19.8887 4.68369 19.8884H16.6839C17.6456 19.8884 18.52 19.1452 18.52 18.1835V17.8337C18.8181 17.7761 19.101 17.657 19.3506 17.484C19.7047 17.1858 19.9315 16.7636 19.9845 16.3037L20.99 7.42924C21.0925 6.46525 20.4007 5.59807 19.438 5.48381ZM17.6456 18.1835C17.6456 18.6644 17.1647 19.0141 16.6839 19.0141H4.68369C4.24929 19.0268 3.8868 18.685 3.87404 18.2506C3.87337 18.2282 3.87367 18.2059 3.87492 18.1835V16.566L7.26297 14.0741C7.66997 13.7616 8.24324 13.7894 8.61819 14.1397L11.0007 16.2381C11.3626 16.5419 11.818 16.7117 12.2904 16.719C12.6597 16.7235 13.023 16.6251 13.3396 16.4348L17.6457 13.943V18.1835H17.6456ZM17.6456 12.9156L12.8805 15.6916C12.4714 15.934 11.9534 15.8902 11.5909 15.5824L9.18648 13.4621C8.49736 12.8699 7.49015 12.8336 6.7602 13.3746L3.87492 15.473V9.24346C3.87492 8.76258 4.20281 8.30357 4.68369 8.30357H16.6839C17.1976 8.32487 17.6125 8.73036 17.6456 9.24346V12.9156ZM20.1165 7.31121C20.1162 7.3141 20.1159 7.31703 20.1156 7.31992L19.0883 16.1944C19.09 16.4245 18.9851 16.6424 18.8041 16.7845C18.7166 16.872 18.5199 16.9157 18.5199 16.9594V9.24346C18.4854 8.24768 17.6799 7.45179 16.6838 7.42924H6.23564L6.43236 5.7243C6.47503 5.50352 6.59047 5.30345 6.76024 5.15597C6.95195 5.02342 7.18373 4.96163 7.41602 4.98108L19.3288 6.35814C19.8094 6.40379 20.1621 6.83049 20.1165 7.31121Z" fill="#092245" />
      </g>
      <defs>
        <clipPath id="clip0_13196_5322">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
