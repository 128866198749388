import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';

function ComponentHazardCheckBox() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const entries: [string, boolean | string][] = Object.entries(reportPdfResponse4?.Hazard_Analysis?.[0] || {});

  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        {entries?.map(([key, value]: [string, boolean | string]) => (
          <tr>
            <td className=" tableGreenHeading textCenter boldText" style={{ height: '45px' }}>{key}</td>
            <td style={{
              display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '45px',
            }}
            >
              <input
                type="checkbox"
                disabled
                checked={!!(value)}
              />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ComponentHazardCheckBox;
