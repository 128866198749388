import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddVesselListData, VesselListPayloadListing, PayloadTypeDeleteVesselList, PayloadTypeGetVesselList, VesselListState, UpdateVesselListData,
} from '../utils/types';

const initialState: VesselListState = {
  VesselListData: {} as VesselListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const vesselListSlice = createSlice({
  name: 'vesselList',
  initialState,
  reducers: {
    getVesselListStart(state, _action: PayloadAction<PayloadTypeGetVesselList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successVesselList(state, action: PayloadAction<VesselListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        VesselListData: action?.payload as VesselListPayloadListing,
      };
    },
    failureVesselList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateVesselList(state, _action: PayloadAction<UpdateVesselListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateVesselList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateVesselList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addVesselListStart(state, _action: PayloadAction<AddVesselListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddVesselList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddVesselList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteVesselListStart(state, _action: PayloadAction<PayloadTypeDeleteVesselList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteVesselList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteVesselList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getVesselListStart, successVesselList, failureVesselList, updateVesselList,
  successUpdateVesselList, failureUpdateVesselList, resetState, deleteVesselListStart,
  successDeleteVesselList, failureDeleteVesselList,
  addVesselListStart, successAddVesselList, failureAddVesselList,
} = vesselListSlice.actions;
export const ListVesselReducer = vesselListSlice.reducer;

export type VesselTypeActions =
  | ReturnType<typeof getVesselListStart>
  | ReturnType<typeof successVesselList>
  | ReturnType<typeof failureVesselList>

  | ReturnType<typeof updateVesselList>
  | ReturnType<typeof successUpdateVesselList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateVesselList>

  | ReturnType<typeof deleteVesselListStart>
  | ReturnType<typeof successDeleteVesselList>
  | ReturnType<typeof failureDeleteVesselList>

  | ReturnType<typeof addVesselListStart>
  | ReturnType<typeof successAddVesselList>
  | ReturnType<typeof failureAddVesselList>;
