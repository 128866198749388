import React from 'react';
import { Vessel } from '../types';

function PhaseChartTable({ tableDetails }: { tableDetails: Vessel }) {
  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Material
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Tube Type
        </td>
        <td className="tableGreenHeading textCenter boldText">
          OD
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Wall
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Test Type
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Frequency
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Probe Diameter
        </td>
      </tr>
      <tr>
        <td className="textCenter">{tableDetails.MaterialName}</td>
        <td className="textCenter">{tableDetails.TubeType}</td>
        <td className="textCenter">{tableDetails.Od}</td>
        <td className="textCenter">{tableDetails.Wall}</td>
        <td className="textCenter">{tableDetails.TestType}</td>
        <td className="textCenter">{tableDetails.Frequency}</td>
        <td className="textCenter">{tableDetails.ProbeDiameter}</td>
      </tr>
    </table>
  );
}

export default PhaseChartTable;
