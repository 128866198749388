import React, {
  forwardRef,
} from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import ComponentToPrint from './components/ComponentToPrint';
import { getPageMargins } from './constant';
import { RootState } from '../../redux/rootState';
import { ReportPdfProps } from '../taiReportingSystem/utils/types';

function ReportPdf({ printRef }:ReportPdfProps) {
  const pageMarginsCSS = getPageMargins;

  const { reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  if (!reportPdfResponse1) {
    return null;
  }

  return (
    <>
      <style>{pageMarginsCSS}</style>
      <div style={{
        display: 'none', width: '900px', margin: '0 auto', backgroundColor: '#FFFFFF',
      }}
      >
        <ComponentToPrint ref={printRef} />
      </div>
    </>
  );
}

const ScreenReportPdf = forwardRef(ReportPdf);

export default ScreenReportPdf;
