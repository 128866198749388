import React, { useContext, useEffect } from 'react';
import {
  Grid, Card, TextField, IconButton, Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import '../../Style/StyleSmc.scss';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AddPlaylist } from '../../../../assets/TableIcons/AddPlaylist';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { AddSmcFormValue } from '../../Utils/ConstantSmc';
import { TypesAddSites } from './TypesAddSites';
import AddCustomerModal from '../../../../Common/AddCustomerModal';
import EndPoints from '../../../../Routes/EndPoints';
import ComponentSiteSelectorModal from '../ComponentSiteSelectorModal ';
import { setSelectedSiteId } from '../../Redux/sliceAddSmc';

export default function ComponentAddSite({
  setValue,
  control,
  watch,
  open,
  onAddCustomerList,
  handleTabCell,
  setOpen,
  setOpenSite,
  openSite,
  filteredSubscribedSite,
  customerId,
}: TypesAddSites): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const handleModal = () => {
    setOpen(!open);
  };

  const handleSiteModal = () => {
    setOpenSite(!openSite);
  };

  const watchSite = watch(AddSmcFormValue.TEMP_SMC_SITE);
  const watchCustomer = watch(AddSmcFormValue.TEMP_SMC_CUSTOMER);

  useEffect(() => {
    if (watchCustomer) {
      setValue(AddSmcFormValue.TEMP_SMC_SITE, '');
    }
    return () => {
      dispatch(setSelectedSiteId(''));
    };
  }, [watchCustomer]);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">{translations.addSite}</div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.customer}
              </label>
              <Controller
                name={AddSmcFormValue.TEMP_SMC_CUSTOMER}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    size="small"
                    placeholder=""
                    value={value}
                    onClick={handleModal}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Search here..">
                          <IconButton onClick={handleModal} className="search_icon_btn">
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.site}
              </label>
              <Controller
                name={AddSmcFormValue.TEMP_SMC_SITE}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    size="small"
                    placeholder=""
                    value={value}
                    onClick={handleSiteModal}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Search here..">
                          <IconButton onClick={handleSiteModal} className="search_icon_btn">
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid>
            <button
              type="button"
              className={`Style_icon ${!(watchSite?.length > 0) ? 'disabled' : 'active'}`}
              onClick={onAddCustomerList}
              disabled={!(watchSite?.length > 0)}
            >
              <AddPlaylist />
            </button>
          </Grid>
        </Grid>
      </div>
      <ComponentSiteSelectorModal
        customerId={customerId}
        filteredSubscribedSite={filteredSubscribedSite}
        watchCustomer={watchCustomer}
        show={openSite}
        setValue={setValue}
        setOpenFrom={() => setOpenSite(false)}
      />
      <AddCustomerModal
        handleTabCell={handleTabCell}
        show={open}
        setOpenFrom={handleModal}
        buttonText="Add New Customer"
        redirectLink={EndPoints.ADD_CUSTOMER}
      />
    </Card>
  );
}
