import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateData, ResultType, UnitMasterType, EquipmentInitialState, EquipmentListResult, PayloadTypeEquipmentListStart, GetEquipmentListApiData,
} from '../utils/types';

const initialState: EquipmentInitialState = {
  addEquipmentData: {} as CreateData,
  isLoading: false,
  isSuccess: false,
  message: '',
  vesselsData: [],
  materialData: [],
  tubeTypesData: [],
  manufacturerData: [],
  equipmentList: [] as EquipmentListResult[],
  totalEntries: 0,
  isRequestCreated: false,
};

export const equipmentSlice = createSlice({
  name: 'addEquipmentRequest',
  initialState,
  reducers: {
    // AddEquipment
    addEquipmentStart(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addEquipmentSuccess(state) {
      return {
        ...state,
        isLoading: false,
        isRequestCreated: true,
      };
    },
    resetIsRequestCreated(state) {
      return {
        ...state,
        isRequestCreated: false,
      };
    },
    addEquipmentFailure(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
      };
    },
    // GetallEquipment
    getEquipmentStart(state, _action) {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    },
    getEquipmentSuccess(state, action: PayloadAction<ResultType>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        vesselsData: action.payload.Vessel,
        materialData: action.payload.Material,
        tubeTypesData: action.payload.Tube,
      };
    },
    getEquipmentFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    // Get Unit Master
    getUnitMasterStart(state) {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    },
    getUnitMasterSuccess(state, action: PayloadAction<UnitMasterType>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        manufacturerData: action.payload.Manufacture,
      };
    },
    getUnitMasterFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    getEquipmentListStart(state, _action: PayloadAction<PayloadTypeEquipmentListStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successEquipmentList(state, action: PayloadAction<GetEquipmentListApiData>) {
      return {
        ...state,
        isLoading: false,
        equipmentList: action.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureEquipmentList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },
});

export const {
  addEquipmentStart, addEquipmentSuccess, addEquipmentFailure, getEquipmentStart, getEquipmentSuccess,
  getEquipmentFailure, getUnitMasterStart, getUnitMasterSuccess, getUnitMasterFailure, getEquipmentListStart,
  successEquipmentList, failureEquipmentList, resetIsRequestCreated,
} = equipmentSlice.actions;
export const equipmentReducer = equipmentSlice.reducer;

export type TestEquActions =
  | ReturnType<typeof addEquipmentStart>
  | ReturnType<typeof addEquipmentSuccess>
  | ReturnType<typeof addEquipmentFailure>
  | ReturnType<typeof getEquipmentStart>
  | ReturnType<typeof getEquipmentSuccess>
  | ReturnType<typeof getEquipmentFailure>
  | ReturnType<typeof getUnitMasterStart>
  | ReturnType<typeof getUnitMasterSuccess>
  | ReturnType<typeof getUnitMasterFailure>
  | ReturnType<typeof getEquipmentListStart>
  | ReturnType<typeof successEquipmentList>
  | ReturnType<typeof failureEquipmentList>
  | ReturnType<typeof resetIsRequestCreated>;
