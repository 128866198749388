/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext,
  useEffect,
  useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PhotosFormValues, TypesDialogBox } from '../utils/types';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { PhotoDefaultValue, PhotoForm } from '../utils/constants';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import RequiredFiled from '../../../Common/RequiredField';
import {
  getPhotoById, getVesselById, resetPhotoData, updatePhotoStart,
} from '../redux/photosSlice';
import { RootState } from '../../../redux/rootState';
import { setPhotoValues } from '../utils/photohelper';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentPhotoModel({
  show, setOpenFrom, rowId, page, rowsPerPage, searchQuery,
}: TypesDialogBox): JSX.Element {
  const { vesselDataById, photoDataById, isLoading } = useSelector((state: RootState) => state.photosReducer);
  const dispatch = useDispatch();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const { translations } = useContext(LocalizationContext);
  const handleClose = () => {
    setOpenFrom();
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  const {
    control, formState: { errors }, handleSubmit, setValue,
  } = useForm<PhotosFormValues>({
    defaultValues: PhotoDefaultValue,
  });

  const onSubmit = (values: PhotosFormValues) => {
    const payload = {
      Id: String(photoDataById?.Id),
      PhotoId: photoDataById?.PhotoId,
      Comment: values?.description,
      PhotoPath: photoDataById?.PhotoPath,
      VesselName: values?.vessel,
      OldPhoto: '',
      FileName: photoDataById?.FileName,
      JobOrder: photoDataById?.JobOrder,
    };
    const listPayload = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(updatePhotoStart({ payload, listPayload }));
    handleClose();
  };

  function toDataURL(url: string) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
  }

  async function downloadImage() {
    try {
      const a = document.createElement('a');
      a.href = await toDataURL(photoDataById?.PhotoPath);
      a.download = 'Image.png';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      showSuccessToaster('Image downloaded successfully');
    } catch (error) {
      showErrorToaster('Failed to download image');
    }
  }

  useEffect(() => {
    if (show && photoDataById?.JobOrder) {
      const payload = {
        id: photoDataById?.JobOrder,
      };
      dispatch(getVesselById(payload));
    }
  }, [photoDataById.JobOrder, show]);

  useEffect(() => {
    if (rowId && show) {
      const payload = {
        id: rowId,
      };
      dispatch(getPhotoById(payload));
    }
  }, [rowId, show]);

  useEffect(() => {
    if (photoDataById && show) {
      setPhotoValues(photoDataById, setValue);
    }
  }, [show, photoDataById]);

  useEffect(() => () => {
    dispatch(resetPhotoData());
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        {
        isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress />
        </Backdrop>
        )
        }
        <DialogTitle className="modalHeaderTitle">
          Edit Photo Attachment
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item md={3} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Job Order #
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={PhotoForm.jobOrder}
                    size="small"
                    isDisable
                    id={PhotoForm.jobOrder}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Vessel</label>
                  <ComponentSelectInput
                    control={control}
                    name={PhotoForm.vessel}
                    size="small"
                    errors={errors}
                    entries={vesselDataById || []}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12} style={{ paddingBottom: '16px' }}>
                <img
                  src={photoDataById?.PhotoPath}
                  alt="Photo_Image"
                  style={{ width: '100%', height: '100%' }}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Button
                  className="button_save_and_next"
                  onClick={() => downloadImage()}
                  disabled={!isImageLoaded}
                >
                  Download Image
                </Button>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Description</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={PhotoForm.description}
                    size="small"
                    id={PhotoForm.description}
                    errors={errors}
                    inputProps={{ maxLength: 500 }}
                    multiline
                    rowSize={4}
                    maxLength={5}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </form>
  );
}

export default ComponentPhotoModel;
