import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import taiLogo from '../../../assets/tailogo.png';
import successLogo from '../../../assets/success.png';
import ComponentLeftVector from '../../../Common/LeftVector/ComponentLeftVector';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import EndPoints from '../../../Routes/EndPoints';

function ScreenLoginSuccess() {
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);

  const handleLogin = () => {
    navigate(EndPoints.LOGIN_PAGE);
  };

  return (
    <div className="div-password">
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <ComponentLeftVector />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <div className="mobStyle">
            <div className="rightContainer">
              <img src={taiLogo} alt="TAI Logo" className="taiLogo" />
              <br />
              <img src={successLogo} alt="Success Logo" height={62} width={62} className="successLogo" />
              <div className="loginContainer">
                <h2>{translations.changedSuccessfully}</h2>
                <Button variant="contained" fullWidth className="button-color" onClick={handleLogin}>
                  {translations.login}
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenLoginSuccess;
