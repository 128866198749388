import React from 'react';
import DataSheetTable from './dataSheetTable';

function DataSheetSection() {
  return (
    <div className="p30">
      <DataSheetTable />
    </div>
  );
}

export default DataSheetSection;
