import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LogoutState, LogoutTypes } from '../utils/types';
import { resetPasswordSuccess } from '../../Login/ScreenReset/redux/sliceReset';

const initialState: LogoutState = {
  logoutData: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
  isSuccess: false,
};

export const LogoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    sendLogoutData(state, action: PayloadAction<LogoutTypes>) {
      return {
        ...state,
        isLoading: true,
        logoutData: action.payload,
      };
    },
    logoutDataSendSuccess(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    logoutDataSendFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: action.payload,
      };
    },
    errorHide(state) {
      return {
        ...state,
        isError: false,
      };
    },
    logoutStateReset(state) {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorMessage: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPasswordSuccess, (state) => ({
      ...state,
      isLoading: false,
      isError: false,
      errorMessage: '',
      isSuccess: false,
    }));
  },
});

export const {
  sendLogoutData, logoutDataSendSuccess, logoutDataSendFailure, errorHide, logoutStateReset,
} = LogoutSlice.actions;
export const logoutReducer = LogoutSlice.reducer;

export type LogoutActions =
  | ReturnType<typeof sendLogoutData>
  | ReturnType<typeof logoutDataSendSuccess>
  | ReturnType<typeof logoutDataSendFailure>
  | ReturnType<typeof logoutStateReset>
  | ReturnType<typeof errorHide>;
