/* eslint-disable max-len */
import React from 'react';

export function DragIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 3 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="0.5" cy="0.5" r="0.5" fill="#00A751" />
      <circle cx="2.5" cy="0.5" r="0.5" fill="#00A751" />
      <circle cx="0.5" cy="2.5" r="0.5" fill="#00A751" />
      <circle cx="2.5" cy="2.5" r="0.5" fill="#00A751" />
      <circle cx="0.5" cy="4.5" r="0.5" fill="#00A751" />
      <circle cx="2.5" cy="4.5" r="0.5" fill="#00A751" />
    </svg>
  );
}
