/* eslint-disable max-len */
import React from 'react';

export function RoleBaseControlIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9021 11.6962C11.533 11.6962 11.229 11.4396 11.229 11.128C11.229 10.8164 11.533 10.5781 11.9021 10.5781C12.2712 10.5781 12.5752 10.8347 12.5752 11.1463C12.5752 11.4579 12.2712 11.6962 11.9021 11.6962ZM11.9021 10.8531C11.7067 10.8531 11.5547 10.9814 11.5547 11.1463C11.5547 11.3113 11.7067 11.4396 11.9021 11.4396C12.0975 11.4396 12.2495 11.3113 12.2495 11.1463C12.2495 10.9814 12.0758 10.8531 11.9021 10.8531Z" fill="#092245" />
      <path d="M12.4671 13.4201H11.3164C10.9907 13.4201 10.6867 13.2184 10.6216 12.9435C10.5998 12.8152 10.5781 12.7052 10.5781 12.6319C10.5781 11.8437 11.5118 11.8438 11.9026 11.8438C12.2717 11.8438 13.2271 11.8437 13.2271 12.6319C13.2271 12.7235 13.2054 12.7969 13.1837 12.8885V12.9252C13.0968 13.2184 12.7928 13.4201 12.4671 13.4201ZM11.9026 12.1004C11.1861 12.1004 10.9038 12.247 10.9038 12.6136C10.9038 12.6686 10.9255 12.7602 10.9472 12.8702C10.9907 13.0168 11.1644 13.1268 11.3381 13.1268H12.4671C12.6625 13.1268 12.8145 13.0168 12.858 12.8519V12.8152C12.8797 12.7419 12.8797 12.6686 12.8797 12.5952C12.8797 12.302 12.7277 12.1004 11.9026 12.1004Z" fill="#092245" />
      <path d="M4.76809 18.2706C4.27539 18.2706 3.86963 17.8648 3.86963 17.3721C3.86963 16.8794 4.27539 16.4736 4.76809 16.4736C5.2608 16.4736 5.66656 16.8794 5.66656 17.3721C5.66656 17.8648 5.2608 18.2706 4.76809 18.2706ZM4.76809 16.9084C4.50725 16.9084 4.30437 17.1113 4.30437 17.3721C4.30437 17.6329 4.50725 17.8358 4.76809 17.8358C5.02894 17.8358 5.23182 17.6329 5.23182 17.3721C5.23182 17.1113 4.99995 16.9084 4.76809 16.9084Z" fill="#092245" />
      <path d="M5.5215 20.9954H4.01439C3.57965 20.9954 3.1739 20.6766 3.08695 20.2419C3.02898 20.039 3 19.8651 3 19.7492C3 18.5029 4.24626 18.5029 4.76794 18.5029C5.26065 18.5029 6.53589 18.5029 6.53589 19.7492C6.53589 19.8941 6.50691 20.01 6.47792 20.1549V20.2419C6.36199 20.6766 5.98522 20.9954 5.5215 20.9954ZM4.76794 18.9087C3.81152 18.9087 3.43474 19.1405 3.43474 19.7202C3.43474 19.8072 3.46372 19.9521 3.49271 20.126C3.55067 20.3578 3.78253 20.5317 4.01439 20.5317H5.5215C5.78234 20.5317 5.98522 20.3578 6.04318 20.097V20.039C6.07217 19.9231 6.07217 19.8072 6.07217 19.6912C6.10115 19.2565 5.86929 18.9087 4.76794 18.9087Z" fill="#092245" />
      <path d="M9.57815 18.2706C9.08545 18.2706 8.67969 17.8648 8.67969 17.3721C8.67969 16.8794 9.08545 16.4736 9.57815 16.4736C10.0709 16.4736 10.4766 16.8794 10.4766 17.3721C10.4766 17.8648 10.0709 18.2706 9.57815 18.2706ZM9.57815 16.9084C9.31731 16.9084 9.11443 17.1113 9.11443 17.3721C9.11443 17.6329 9.31731 17.8358 9.57815 17.8358C9.839 17.8358 10.0419 17.6329 10.0419 17.3721C10.0419 17.1113 9.81001 16.9084 9.57815 16.9084Z" fill="#092245" />
      <path d="M10.3311 20.9954H8.79498C8.36024 20.9954 7.95448 20.6766 7.86754 20.2419C7.83855 20.039 7.80957 19.8651 7.80957 19.7492C7.80957 18.5029 9.05583 18.5029 9.57752 18.5029C10.0702 18.5029 11.3455 18.5029 11.3455 19.7492C11.3455 19.8941 11.3165 20.01 11.2875 20.1549V20.2129C11.1716 20.6766 10.7658 20.9954 10.3311 20.9954ZM9.57752 18.9087C8.62109 18.9087 8.24431 19.1405 8.24431 19.7202C8.24431 19.8072 8.27329 19.9521 8.30228 20.126C8.36024 20.3578 8.5921 20.5317 8.82397 20.5317H10.3311C10.5919 20.5317 10.7948 20.3578 10.8528 20.097V20.039C10.8817 19.9231 10.8817 19.8072 10.8817 19.6912C10.8817 19.2565 10.6789 18.9087 9.57752 18.9087Z" fill="#092245" />
      <path d="M14.3887 18.2706C13.896 18.2706 13.4902 17.8648 13.4902 17.3721C13.4902 16.8794 13.896 16.4736 14.3887 16.4736C14.8814 16.4736 15.2872 16.8794 15.2872 17.3721C15.2872 17.8648 14.8814 18.2706 14.3887 18.2706ZM14.3887 16.9084C14.1279 16.9084 13.925 17.1113 13.925 17.3721C13.925 17.6329 14.1279 17.8358 14.3887 17.8358C14.6495 17.8358 14.8524 17.6329 14.8524 17.3721C14.8524 17.1113 14.6495 16.9084 14.3887 16.9084Z" fill="#092245" />
      <path d="M15.1712 20.9954H13.6641C13.2294 20.9954 12.8236 20.6766 12.7367 20.2419C12.7077 20.097 12.6787 19.8941 12.6787 19.7492C12.6787 18.5029 13.925 18.5029 14.4467 18.5029C14.9394 18.5029 16.2146 18.5029 16.2146 19.7492C16.2146 19.8941 16.1856 20.01 16.1566 20.1549V20.2129C16.0117 20.6766 15.606 20.9954 15.1712 20.9954ZM14.3887 18.9087C13.4323 18.9087 13.0555 19.1405 13.0555 19.7202C13.0555 19.8072 13.0845 19.9521 13.1135 20.126C13.1714 20.3578 13.4033 20.5317 13.6351 20.5317H15.1712C15.4321 20.5317 15.6349 20.3578 15.6929 20.097V20.039C15.7219 19.9231 15.7219 19.8072 15.7219 19.6912C15.7219 19.2565 15.49 18.9087 14.3887 18.9087Z" fill="#092245" />
      <path d="M19.2027 18.2706C18.71 18.2706 18.3042 17.8648 18.3042 17.3721C18.3042 16.8794 18.71 16.4736 19.2027 16.4736C19.6954 16.4736 20.1011 16.8794 20.1011 17.3721C20.1011 17.8648 19.6954 18.2706 19.2027 18.2706ZM19.2027 16.9084C18.9418 16.9084 18.7389 17.1113 18.7389 17.3721C18.7389 17.6329 18.9418 17.8358 19.2027 17.8358C19.4635 17.8358 19.6664 17.6329 19.6664 17.3721C19.6664 17.1113 19.4635 16.9084 19.2027 16.9084Z" fill="#092245" />
      <path d="M19.9569 20.9954H18.4498C18.015 20.9954 17.6093 20.6766 17.5223 20.2419C17.4933 20.039 17.4644 19.8651 17.4644 19.7492C17.4644 18.5029 18.7106 18.5029 19.2323 18.5029C19.725 18.5029 21.0002 18.5029 21.0002 19.7492C21.0002 19.8941 20.9713 20.01 20.9423 20.1549V20.2419C20.7974 20.6766 20.4206 20.9954 19.9569 20.9954ZM19.2033 18.9087C18.2469 18.9087 17.8701 19.1405 17.8701 19.7202C17.8701 19.8072 17.8991 19.9521 17.9281 20.126C17.986 20.3578 18.2179 20.5317 18.4498 20.5317H19.9569C20.2177 20.5317 20.4206 20.3578 20.4786 20.097V20.039C20.5075 19.9231 20.5075 19.8072 20.5075 19.6912C20.5365 19.2565 20.3047 18.9087 19.2033 18.9087Z" fill="#092245" />
      <path d="M4.76809 4.76794C4.27539 4.76794 3.86963 4.39117 3.86963 3.89846C3.86963 3.40576 4.27539 3 4.76809 3C5.2608 3 5.66656 3.40576 5.66656 3.89846C5.66656 4.39117 5.2608 4.76794 4.76809 4.76794ZM4.76809 3.43474C4.50725 3.43474 4.30437 3.63762 4.30437 3.89846C4.30437 4.15931 4.50725 4.36219 4.76809 4.36219C5.02894 4.36219 5.23182 4.15931 5.23182 3.89846C5.23182 3.63762 4.99995 3.43474 4.76809 3.43474Z" fill="#092245" />
      <path d="M5.5215 7.4922H4.01439C3.57965 7.4922 3.1739 7.17339 3.08695 6.73865C3.02898 6.50679 3 6.36187 3 6.21696C3 4.9707 4.24626 4.9707 4.76794 4.9707C5.26065 4.9707 6.53589 4.9707 6.53589 6.21696C6.53589 6.36187 6.50691 6.4778 6.47792 6.62272V6.70967C6.36199 7.17339 5.98522 7.4922 5.5215 7.4922ZM4.76794 5.40544C3.81152 5.40544 3.43474 5.63731 3.43474 6.21696C3.43474 6.30391 3.46372 6.44882 3.49271 6.62272C3.55067 6.85458 3.78253 7.02848 4.01439 7.02848H5.5215C5.78234 7.02848 5.98522 6.85458 6.04318 6.59373V6.53577C6.07217 6.41984 6.07217 6.30391 6.07217 6.18798C6.10115 5.72425 5.86929 5.40544 4.76794 5.40544Z" fill="#092245" />
      <path d="M9.57913 4.76794C9.08642 4.76794 8.68066 4.36219 8.68066 3.86948C8.68066 3.37678 9.08642 3 9.57913 3C10.0718 3 10.4776 3.40576 10.4776 3.89846C10.4776 4.39117 10.0718 4.76794 9.57913 4.76794ZM9.57913 3.43474C9.31828 3.43474 9.1154 3.63762 9.1154 3.89846C9.1154 4.15931 9.31828 4.36219 9.57913 4.36219C9.83997 4.36219 10.0429 4.15931 10.0429 3.89846C10.0429 3.63762 9.81099 3.43474 9.57913 3.43474Z" fill="#092245" />
      <path d="M10.333 7.49251H8.79694C8.36219 7.49251 7.95644 7.1737 7.86949 6.73896C7.84051 6.53608 7.81152 6.36219 7.81152 6.24626C7.81152 5 9.05778 5 9.57947 5C10.0722 5 11.3474 5 11.3474 6.24626C11.3474 6.39117 11.3184 6.5071 11.2894 6.65201V6.70998C11.1735 7.1737 10.7678 7.49251 10.333 7.49251ZM9.57947 5.40576C8.62304 5.40576 8.24626 5.63762 8.24626 6.21727C8.24626 6.30422 8.27525 6.44914 8.30423 6.62303C8.36219 6.85489 8.59406 7.02879 8.82592 7.02879H10.333C10.5939 7.02879 10.7967 6.85489 10.8547 6.59405V6.53608C10.8837 6.42015 10.8837 6.30422 10.8837 6.18829C10.8837 5.72457 10.6808 5.40576 9.57947 5.40576Z" fill="#092245" />
      <path d="M14.3902 4.76794C13.8975 4.76794 13.4917 4.36219 13.4917 3.86948C13.4917 3.37678 13.8975 3 14.3902 3C14.8829 3 15.2886 3.40576 15.2886 3.89846C15.2886 4.39117 14.8829 4.76794 14.3902 4.76794ZM14.3902 3.43474C14.1293 3.43474 13.9264 3.63762 13.9264 3.89846C13.9264 4.15931 14.1293 4.36219 14.3902 4.36219C14.651 4.36219 14.8539 4.15931 14.8539 3.89846C14.8539 3.63762 14.651 3.43474 14.3902 3.43474Z" fill="#092245" />
      <path d="M15.1732 7.49251H13.6661C13.2313 7.49251 12.8256 7.1737 12.7386 6.73896C12.6807 6.5071 12.6807 6.36219 12.6807 6.24626C12.6807 5 13.9269 5 14.4486 5C14.9413 5 16.2166 5 16.2166 6.24626C16.2166 6.39117 16.1876 6.5071 16.1586 6.65201V6.70998C16.0137 7.1737 15.6079 7.49251 15.1732 7.49251ZM14.3906 5.40576C13.4342 5.40576 13.0574 5.63762 13.0574 6.21727C13.0574 6.30422 13.0864 6.44914 13.1154 6.62303C13.1734 6.85489 13.4052 7.02879 13.6371 7.02879H15.1732C15.434 7.02879 15.6369 6.85489 15.6949 6.59405V6.53608C15.7238 6.42015 15.7238 6.30422 15.7238 6.18829C15.7238 5.72457 15.492 5.40576 14.3906 5.40576Z" fill="#092245" />
      <path d="M19.2017 4.76794C18.709 4.76794 18.3032 4.36219 18.3032 3.86948C18.3032 3.37678 18.709 3 19.2017 3C19.6944 3 20.1002 3.40576 20.1002 3.89846C20.1002 4.39117 19.6944 4.76794 19.2017 4.76794ZM19.2017 3.43474C18.9408 3.43474 18.738 3.63762 18.738 3.89846C18.738 4.15931 18.9408 4.36219 19.2017 4.36219C19.4625 4.36219 19.6654 4.15931 19.6654 3.89846C19.6654 3.63762 19.4625 3.43474 19.2017 3.43474Z" fill="#092245" />
      <path d="M19.9549 7.49251H18.4478C18.0131 7.49251 17.6073 7.1737 17.5204 6.73896C17.4914 6.53608 17.4624 6.36219 17.4624 6.24626C17.4624 5 18.7087 5 19.2303 5C19.7231 5 20.9983 5 20.9983 6.24626C20.9983 6.39117 20.9693 6.5071 20.9403 6.65201V6.73896C20.7954 7.1737 20.4186 7.49251 19.9549 7.49251ZM19.2014 5.40576C18.2449 5.40576 17.8682 5.63762 17.8682 6.21727C17.8682 6.30422 17.8971 6.44914 17.9261 6.62303C17.9841 6.85489 18.216 7.02879 18.4478 7.02879H19.9549C20.2158 7.02879 20.4186 6.85489 20.4766 6.59405V6.53608C20.5056 6.42015 20.5056 6.30422 20.5056 6.18829C20.5346 5.72457 20.3027 5.40576 19.2014 5.40576Z" fill="#092245" />
      <path d="M4.76809 11.5176C4.27539 11.5176 3.86963 11.1119 3.86963 10.6192C3.86963 10.1265 4.27539 9.7207 4.76809 9.7207C5.2608 9.7207 5.66656 10.1265 5.66656 10.6192C5.66656 11.1119 5.2608 11.5176 4.76809 11.5176ZM4.76809 10.1844C4.50725 10.1844 4.30437 10.3873 4.30437 10.6481C4.30437 10.909 4.50725 11.1119 4.76809 11.1119C5.02894 11.1119 5.23182 10.909 5.23182 10.6481C5.23182 10.3873 4.99995 10.1844 4.76809 10.1844Z" fill="#092245" />
      <path d="M5.5215 14.2142H4.01439C3.57965 14.2142 3.1739 13.8954 3.08695 13.4606C3.02898 13.2578 3 13.0839 3 12.9679C3 11.7217 4.24626 11.7217 4.76794 11.7217C5.26065 11.7217 6.53589 11.7217 6.53589 12.9679C6.53589 13.1128 6.50691 13.2288 6.47792 13.3737V13.4606C6.36199 13.9244 5.98522 14.2142 5.5215 14.2142ZM4.76794 12.1564C3.81152 12.1564 3.43474 12.3883 3.43474 12.9679C3.43474 13.0549 3.46372 13.1998 3.49271 13.3737C3.55067 13.6056 3.78253 13.7795 4.01439 13.7795H5.5215C5.78234 13.7795 5.98522 13.6056 6.04318 13.3447V13.2867C6.07217 13.1708 6.07217 13.0549 6.07217 12.939C6.10115 12.4752 5.86929 12.1564 4.76794 12.1564Z" fill="#092245" />
      <path d="M19.2027 11.5176C18.71 11.5176 18.3042 11.1119 18.3042 10.6192C18.3042 10.1265 18.71 9.7207 19.2027 9.7207C19.6954 9.7207 20.1011 10.1265 20.1011 10.6192C20.1011 11.1119 19.6954 11.5176 19.2027 11.5176ZM19.2027 10.1844C18.9418 10.1844 18.7389 10.3873 18.7389 10.6481C18.7389 10.909 18.9418 11.1119 19.2027 11.1119C19.4635 11.1119 19.6664 10.909 19.6664 10.6481C19.6664 10.3873 19.4635 10.1844 19.2027 10.1844Z" fill="#092245" />
      <path d="M19.9564 14.2142H18.4493C18.0145 14.2142 17.6088 13.8954 17.5218 13.4606C17.4928 13.2578 17.4639 13.0839 17.4639 12.9679C17.4639 11.7217 18.7101 11.7217 19.2318 11.7217C19.7245 11.7217 20.9998 11.7217 20.9998 12.9679C20.9998 13.1128 20.9708 13.2288 20.9418 13.3737V13.4606C20.7969 13.9244 20.4201 14.2142 19.9564 14.2142ZM19.2028 12.1564C18.2464 12.1564 17.8696 12.3883 17.8696 12.9679C17.8696 13.0549 17.8986 13.1998 17.9276 13.3737C17.9856 13.6056 18.2174 13.7795 18.4493 13.7795H19.9564C20.2172 13.7795 20.4201 13.6056 20.4781 13.3447V13.2867C20.5071 13.1708 20.5071 13.0549 20.5071 12.939C20.536 12.4752 20.3042 12.1564 19.2028 12.1564Z" fill="#092245" />
      <path d="M11.9845 14.97C10.4484 14.97 9.20215 13.7237 9.20215 12.1876C9.20215 10.6515 10.4484 9.40527 11.9845 9.40527C13.5206 9.40527 14.7668 10.6515 14.7668 12.1876C14.7668 13.7237 13.5206 14.97 11.9845 14.97ZM11.9845 9.84001C10.6803 9.84001 9.63689 10.8834 9.63689 12.1876C9.63689 13.4918 10.6803 14.5352 11.9845 14.5352C13.2887 14.5352 14.3321 13.4918 14.3321 12.1876C14.3321 10.8834 13.2887 9.84001 11.9845 9.84001Z" fill="#092245" />
      <path d="M16.0424 10.8547C15.9265 10.8547 15.8105 10.7678 15.8105 10.6228V8.8549C15.8105 8.73897 15.8975 8.62304 16.0424 8.62304H18.9986V8.04338C18.9986 7.92745 19.0856 7.81152 19.2305 7.81152C19.3754 7.81152 19.4624 7.89847 19.4624 8.04338V8.82592C19.4624 8.94185 19.3754 9.05778 19.2305 9.05778H16.2453V10.6228C16.2453 10.7678 16.1583 10.8547 16.0424 10.8547Z" fill="#092245" />
      <path d="M8.01406 10.854C7.89813 10.854 7.7822 10.7671 7.7822 10.6222V9.0861H4.76799C4.65206 9.0861 4.53613 8.99915 4.53613 8.85424V8.07171C4.53613 7.95577 4.62308 7.83984 4.76799 7.83984C4.91291 7.83984 4.99986 7.92679 4.99986 8.07171V8.65136H8.04304C8.15897 8.65136 8.2749 8.73831 8.2749 8.88322V10.6512C8.24592 10.7671 8.12999 10.854 8.01406 10.854Z" fill="#092245" />
      <path d="M9.57903 9.0861C9.4631 9.0861 9.34717 8.99915 9.34717 8.85424V8.07171C9.34717 7.95577 9.43412 7.83984 9.57903 7.83984C9.72394 7.83984 9.81089 7.92679 9.81089 8.07171V8.85424C9.78191 8.99915 9.69496 9.0861 9.57903 9.0861Z" fill="#092245" />
      <path d="M14.3901 9.0861C14.2741 9.0861 14.1582 8.99915 14.1582 8.85424V8.07171C14.1582 7.95577 14.2452 7.83984 14.3901 7.83984C14.535 7.83984 14.6219 7.92679 14.6219 8.07171V8.85424C14.6219 8.99915 14.535 9.0861 14.3901 9.0861Z" fill="#092245" />
      <path d="M4.76799 16.1005C4.65206 16.1005 4.53613 16.0136 4.53613 15.8687V15.0861C4.53613 14.9702 4.62308 14.8543 4.76799 14.8543H7.72423V13.9848C7.72423 13.8689 7.81118 13.7529 7.95609 13.7529C8.10101 13.7529 8.18795 13.8399 8.18795 13.9848V15.0861C8.18795 15.2021 8.10101 15.318 7.95609 15.318H4.97087V15.8977C4.97087 16.0136 4.88393 16.1005 4.76799 16.1005Z" fill="#092245" />
      <path d="M19.2011 16.0999C19.0851 16.0999 18.9692 16.0129 18.9692 15.868V15.2884H15.955C15.8391 15.2884 15.7231 15.2014 15.7231 15.0565V14.0131C15.7231 13.8972 15.8101 13.7812 15.955 13.7812C16.0999 13.7812 16.1869 13.8682 16.1869 14.0131V14.8826H19.2301C19.346 14.8826 19.4619 14.9695 19.4619 15.1145V15.897C19.4329 16.0129 19.317 16.0999 19.2011 16.0999Z" fill="#092245" />
      <path d="M14.3901 16.1007C14.2741 16.1007 14.1582 16.0138 14.1582 15.8689V15.0864C14.1582 14.9704 14.2452 14.8545 14.3901 14.8545C14.535 14.8545 14.6219 14.9414 14.6219 15.0864V15.8689C14.6219 16.0138 14.535 16.1007 14.3901 16.1007Z" fill="#092245" />
      <path d="M9.57952 16.1007C9.46359 16.1007 9.34766 16.0138 9.34766 15.8689V15.0864C9.34766 14.9704 9.4346 14.8545 9.57952 14.8545C9.72443 14.8545 9.81138 14.9414 9.81138 15.0864V15.8689C9.7824 16.0138 9.69545 16.1007 9.57952 16.1007Z" fill="#092245" />
      <path d="M8.0146 12.6512H7.11614C7.00021 12.6512 6.88428 12.5643 6.88428 12.4194C6.88428 12.2744 6.97123 12.1875 7.11614 12.1875H8.0146C8.13053 12.1875 8.24646 12.2744 8.24646 12.4194C8.24646 12.5643 8.13053 12.6512 8.0146 12.6512Z" fill="#092245" />
      <path d="M16.8539 12.6512H16.0424C15.9265 12.6512 15.8105 12.5643 15.8105 12.4194C15.8105 12.2744 15.8975 12.1875 16.0424 12.1875H16.8539C16.9699 12.1875 17.0858 12.2744 17.0858 12.4194C17.0858 12.5643 16.9699 12.6512 16.8539 12.6512Z" fill="#092245" />
    </svg>
  );
}
