import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MarkupsLIstData } from '../utils/TypesMarkupsList';
import ComponentAddMarkupsDialog from './ComponentAddMarkupsDialog';
import DataTable from '../../../../Common/DataTable';
import ComponentEditMarkupsDialog from './ComponentEditMarkupsDialog';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import { deleteMarkupsStart, getSysMarkupStart } from '../Redux/sliceMarkups';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';

function ComponentMarkupsTable() {
  const dispatch = useDispatch();

  const { SystemMarkup, isLoading } = useSelector((state: RootState) => state.SystemMarkupReducer);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getSysMarkupStart(params));
  };

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [rowwId, setRowwId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMarupsId, setSelectedMarkupsId] = useState<string>('');

  const handleAction = (id: string) => {
    setOpenEdit(true);
    setRowwId(id);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Description', label: 'Description' },
      { key: 'Percentage', label: 'Percentage' },

    ],
    [],
  );
  const tableData: MarkupsLIstData[] = useMemo(() => SystemMarkup?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      Description: item.Description,
      Percentage: item.Percentage,

      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [SystemMarkup]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedMarkupsId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedMarupsId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteMarkupsStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<MarkupsLIstData>
        handleButtonClick={() => setOpen(true)}
        data={tableData}
        columns={TableColumns}
        totalRecords={SystemMarkup?.TotalRecords}
        buttonText="Add Markup"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentAddMarkupsDialog show={open} setOpenFrom={() => setOpen(false)} />
      <ComponentEditMarkupsDialog show={openEdit} setOpenFrom={() => setOpenEdit(false)} rowId={rowwId} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentMarkupsTable;
