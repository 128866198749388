import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  BoardPositionActions, getFilteredAnalystData, getFilteredAnalystDataFailure,
  getFilteredAnalystDataSuccess, onCreateZone, onCreateZoneFailure, onCreateZoneSuccess,
  onFailBoardPositionData,
  onGetBoardPositionData,
  onGotBoardPositionData,
  onRemoveAnalyst, onRemoveAnalystFailure, onRemoveAnalystSuccess, onRowOrderingAnalyst,
} from './sliceBoardPosition';
import {
  BoardPositionListDataResponse,
  CreateZoneApiResponse, CreateZoneInterface, CreateZonePayloadInterface,
  DeleteAnalystFromZoneInterface,
  DeleteAnalystFromZonePayloadInterface,
  FilteredAnalystDataResponse, RemoveAnalystDataResponse, RowOrderingAnalystInterface,
} from './type';
import { zoneApiMethod, zoneMessages } from '../utils/constant';

async function getFilteredAnalystDataApiCall(): Promise<FilteredAnalystDataResponse> {
  const url = Config.boardPosition.analystList;
  const result = await makeGetRequest<FilteredAnalystDataResponse>(url);
  return result?.data;
}

// GET FILTERED ANALYST DATA
export const epicGetFilteredAnalystData = (action$: Observable<BoardPositionActions>) => action$.pipe(
  filter(getFilteredAnalystData.match),
  debounceTime(250),
  mergeMap(() => from(getFilteredAnalystDataApiCall()).pipe(
    map((res: FilteredAnalystDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getFilteredAnalystDataSuccess(res.BMT.Result);
      }
      return getFilteredAnalystDataFailure();
    }),
    takeUntil(action$.pipe(filter(getFilteredAnalystData.match))),
    catchError((error: AxiosError<FilteredAnalystDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getFilteredAnalystDataFailure());
    }),
  )),
);

async function onGetBoardPositionDataApiCall(): Promise<BoardPositionListDataResponse> {
  const url = Config.boardPosition.getZone;
  const result = await makeGetRequest<BoardPositionListDataResponse>(url);
  return result?.data;
}

// GET ZONE DATA
export const epicGetBoardPositionData = (action$: Observable<BoardPositionActions>) => action$.pipe(
  filter(onGetBoardPositionData.match),
  mergeMap(() => from(onGetBoardPositionDataApiCall()).pipe(
    map((res: BoardPositionListDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onGotBoardPositionData(res.BMT.Result);
      }
      return onFailBoardPositionData();
    }),
    takeUntil(action$.pipe(filter(onGetBoardPositionData.match))),
    catchError((error: AxiosError<BoardPositionListDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(onFailBoardPositionData());
    }),
  )),
);

async function onCreateZoneApiCall(type: string, payload: CreateZonePayloadInterface): Promise<CreateZoneApiResponse> {
  let url: string;
  switch (type) {
    case zoneApiMethod.ADD:
      url = Config.boardPosition.addZone;
      break;
    case zoneApiMethod.SELECT:
      url = Config.boardPosition.analystSelector;
      break;
    default:
      url = Config.boardPosition.addZone;
      break;
  }

  const result = await makePostRequest<CreateZoneApiResponse>(url, payload);
  return result?.data;
}

// CREATE ZONE
export const epicOnCreateZone = (action$: Observable<BoardPositionActions>) => action$.pipe(
  filter(onCreateZone.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ type, payload, callback }: CreateZoneInterface) => from(onCreateZoneApiCall(type, payload)).pipe(
    map((res: CreateZoneApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE && type === zoneApiMethod.ADD) {
        showSuccessToaster(zoneMessages.ADD_ZONE);
        callback();
        return onCreateZoneSuccess();
      }
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE && type === zoneApiMethod.UPDATE) {
        showSuccessToaster(zoneMessages.EDIT_ZONE);
        callback();
        return onCreateZoneSuccess();
      }
      if (type === zoneApiMethod.SELECT) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onCreateZoneSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return onCreateZoneFailure();
    }),
    takeUntil(action$.pipe(filter(onCreateZone.match))),
    catchError((error: AxiosError<CreateZoneApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      callback();
      return of(onCreateZoneFailure());
    }),
  )),
);

async function onRemoveAnalystApiCall(payload: DeleteAnalystFromZonePayloadInterface): Promise<RemoveAnalystDataResponse> {
  const url = `${Config.boardPosition.removeAnalyst}?ZoneId=${payload.ZoneId}&AnalystId=${payload?.AnalystId}`;
  const result = await makeDeleteRequest<RemoveAnalystDataResponse>(url);
  return result?.data;
}

// REMOVE ANALYST
export const epicOnRemoveAnalyst = (action$: Observable<BoardPositionActions>) => action$.pipe(
  filter(onRemoveAnalyst.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ payload, callback }: DeleteAnalystFromZoneInterface) => from(onRemoveAnalystApiCall(payload)).pipe(
    map((res: RemoveAnalystDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onRemoveAnalystSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return onRemoveAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(onRemoveAnalyst.match))),
    catchError((error: AxiosError<RemoveAnalystDataResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      callback();
      return of(onRemoveAnalystFailure());
    }),
  )),
);

async function onRowOrderingAnalystApiCall(payload: RowOrderingAnalystInterface): Promise<RemoveAnalystDataResponse> {
  const url = Config.boardPosition.orderAnalyst;
  const result = await makePostRequest<RemoveAnalystDataResponse>(url, payload);
  return result?.data;
}

// ROW ORDERING ANALYST
export const epicOnRowOrderingAnalyst = (action$: Observable<BoardPositionActions>) => action$.pipe(
  filter(onRowOrderingAnalyst.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((payload: RowOrderingAnalystInterface) => from(onRowOrderingAnalystApiCall(payload)).pipe(
    map((res: RemoveAnalystDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onRemoveAnalystSuccess();
      }
      return onRemoveAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(onRowOrderingAnalyst.match))),
    catchError(() => of(onRemoveAnalystFailure())),
  )),
);
