import {
  Observable,
  catchError, debounceTime, filter, from, map, mergeMap, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  sendLogoutData, logoutDataSendSuccess, logoutDataSendFailure, LogoutActions,
} from './sliceLogout';
import { LogoutApiResponse, LogoutTypes } from '../utils/types';
import Config from '../../../Common/Config';
import { makePostRequest } from '../../../Common/NetworkOps';

async function logout(data: LogoutTypes): Promise<LogoutApiResponse> {
  const url = `${Config.auth.logout}`;
  const result = await makePostRequest<LogoutApiResponse>(url, data);
  return result.data;
}

export const epicLogout = (action$: Observable<LogoutActions>) => action$.pipe(
  filter(sendLogoutData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(logout(data)).pipe(
    map((res: LogoutApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return logoutDataSendSuccess();
      }
      return logoutDataSendFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(sendLogoutData.match))),
    catchError((error: AxiosError<LogoutApiResponse>) => of(logoutDataSendFailure(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
