/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../redux/rootState';
import { ComponentIssueInventoryInterface, InventoryData, IssueEquipmentFields } from '../../utils/types';
import { InventoryIssueEnum } from '../../utils/constants';
import { inventoryFormSchema, resetEquipmentIssueValues, setEquipmentIssueValues } from '../../utils/helper';
import { onIssueEquipment } from '../redux/inventorySlice';
import { CommonDateInput } from '../../../../Common/CommonDateInput';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentEquipmentIssue(
  {
    show, setOpenFrom, rowId, callback,
  }: ComponentIssueInventoryInterface,
): JSX.Element {
  const { analystList } = useSelector((state: RootState) => state.bidsReducer);
  const { inventoryData } = useSelector((state: RootState) => state.inventoryReducer);

  const dispatch = useDispatch();
  const {
    handleSubmit, control, formState: { errors }, setValue, watch,
  } = useForm<IssueEquipmentFields>({
    resolver: yupResolver(inventoryFormSchema as unknown as ObjectSchema<IssueEquipmentFields>),
    defaultValues: {
      issuedDate: dayjs(),
    } as Empty,
  });

  const watchAnalyst = watch(InventoryIssueEnum.analyst);
  const closeEquipmentIssueModal = () => {
    setOpenFrom();
    callback();
  };

  useEffect(() => {
    resetEquipmentIssueValues(setValue);
    const openedFormData = inventoryData?.Data?.find((row: InventoryData) => row.ProductNumber === rowId);
    if (openedFormData?.ProductNumber) {
      const issuedDate = dayjs(new Date(openedFormData?.IssueDate));
      setEquipmentIssueValues(setValue, openedFormData);
      setValue(InventoryIssueEnum.issuedDate, issuedDate);
    }
  }, [rowId, show]);

  const onSubmit = (data: IssueEquipmentFields) => {
    const payload = {
      ProductNumber: data?.product,
      AnalystId: data?.analyst,
      IssueDate: dayjs(data.issuedDate).format('DD-MM-YYYY'),
      Description: data?.description || '',
    };
    dispatch(onIssueEquipment({ payload, callback: closeEquipmentIssueModal }));
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          Equipment Issue
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Serial #</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={InventoryIssueEnum.serial}
                    size="small"
                    id={InventoryIssueEnum.serial}
                    errors={errors}
                    isDisable
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Analyst
                  </label>
                  <ComponentSelectInput
                    name={InventoryIssueEnum.analyst}
                    control={control}
                    required
                    errors={errors}
                    size="small"
                    entries={analystList}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Date Issued
                  </label>
                  <CommonDateInput
                    control={control}
                    errors={errors}
                    name={InventoryIssueEnum.issuedDate}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Product #
                  </label>
                  <ComponentTextInput
                    control={control}
                    isDisable
                    name={InventoryIssueEnum.product}
                    size="small"
                    id={InventoryIssueEnum.product}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={8} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Description</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={InventoryIssueEnum.description}
                    size="small"
                    id={InventoryIssueEnum.description}
                    errors={errors}
                    isDisable
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={setOpenFrom}>Close</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button disabled={!watchAnalyst} type="submit" className={!watchAnalyst ? 'disableBtn' : 'button_save_and_next'}>Issue</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentEquipmentIssue;
