import { ofType } from 'redux-observable';
import {
  catchError, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePostRequest, makePutRequest } from '../../../../Common/NetworkOps';
import { GetById } from '../../../../utils/type';
import {
  CreateUnitPayload, CreateUnitResponse, GetUnitByIdResponse, GetUnitPriceResponse,
  UnitMasterResponse, UnitPricePayload, UpdateUnitPayload, VesselList,
} from '../utils/type';
import {
  createUnitFailure, createUnitStart, createUnitSuccess, getUnitByIdFailure, getUnitByIdStart, getUnitByIdSuccess, getUnitMasterFailure,
  getUnitMasterStart, getUnitMasterSuccess, getUnitPriceFailure, getUnitPriceStart,
  getUnitPriceSuccess, getVesselMasterFailure, getVesselMasterStart, getVesselMasterSuccess,
  UnitInfoActions, updateUnitFailure, updateUnitStart, updateUnitSuccess,
} from './unitInfoSlice';

async function createUnit(data: CreateUnitPayload): Promise<GetById<CreateUnitResponse>> {
  const url = `${Config.units.createUnit}`;
  const result = await makePostRequest<GetById<CreateUnitResponse>>(url, data);
  return result.data;
}

export const createUnitEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(createUnitStart.type),
  map((x) => x.payload),
  mergeMap((data:CreateUnitPayload) => from(createUnit(data)).pipe(
    map((res: GetById<CreateUnitResponse>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return createUnitSuccess(res.BMT.Result.UnitId);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createUnitFailure();
    }),
    takeUntil(action$.pipe(filter(createUnitStart.match))),
    catchError((error) => of(createUnitFailure(error))),
  )),
);

async function getVesselsMaster(): Promise<GetById<VesselList[]>> {
  const url = `${Config.units.vesselMaster}`;
  const result = await makeGetRequest<GetById<VesselList[]>>(url);
  return result.data;
}

export const getVesselMasterEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(getVesselMasterStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getVesselsMaster()).pipe(
    map((res: GetById<VesselList[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getVesselMasterSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getVesselMasterFailure();
    }),
    takeUntil(action$.pipe(filter(getVesselMasterStart.match))),
    catchError((error) => of(getVesselMasterFailure(error))),
  )),
);

async function getUnitMaster(): Promise<GetById<UnitMasterResponse>> {
  const url = `${Config.units.unitMaster}`;
  const result = await makeGetRequest<GetById<UnitMasterResponse>>(url);
  return result.data;
}

export const getUnitMasterEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(getUnitMasterStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getUnitMaster()).pipe(
    map((res: GetById<UnitMasterResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitMasterSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUnitMasterFailure();
    }),
    takeUntil(action$.pipe(filter(getUnitMasterStart.match))),
    catchError((error) => of(getUnitMasterFailure(error))),
  )),
);

async function getUnitPrice(data:UnitPricePayload): Promise<GetById<GetUnitPriceResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.units.unitPrice}?TestType=${data.TestType}&MachineType=${data.MachineType}&TotalTubeQty=${data.TotalTubeQty}&TubeLength=${data.TubeLength}&OD=${data.OD}&SpotTest=${data.SpotTest}&SafetyCharge=${data.SafetyCharge}&SpotTestPercentage=${data.SpotTestPercentage}`;
  const result = await makeGetRequest<GetById<GetUnitPriceResponse>>(url);
  return result.data;
}

export const getUnitPriceEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(getUnitPriceStart.type),
  map((x) => x.payload),
  mergeMap((data:UnitPricePayload) => from(getUnitPrice(data)).pipe(
    map((res: GetById<GetUnitPriceResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitPriceSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUnitPriceFailure();
    }),
    takeUntil(action$.pipe(filter(getUnitPriceStart.match))),
    catchError((error) => of(getUnitPriceFailure(error))),
  )),
);

async function getUnitById(unitId:string): Promise<GetById<GetUnitByIdResponse>> {
  const url = `${Config.units.getUnitById}?UnitId=${unitId}`;
  const result = await makeGetRequest<GetById<GetUnitByIdResponse>>(url);
  return result.data;
}

export const getUnitByIdEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(getUnitByIdStart.type),
  map((x) => x.payload),
  mergeMap((data:string) => from(getUnitById(data)).pipe(
    map((res: GetById<GetUnitByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUnitByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUnitByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getUnitByIdStart.match))),
    catchError((error) => of(getUnitByIdFailure(error))),
  )),
);

async function updateUnit(data: UpdateUnitPayload): Promise<GetById<string>> {
  const url = `${Config.units.updateUnit}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}
export const updateUnitEpic = (action$ : Observable<UnitInfoActions>) => action$.pipe(
  ofType(updateUnitStart.type),
  map((x) => x.payload),
  mergeMap((data:UpdateUnitPayload) => from(updateUnit(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updateUnitSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateUnitFailure();
    }),
    takeUntil(action$.pipe(filter(updateUnitStart.match))),
    catchError((error) => of(updateUnitFailure(error))),
  )),
);
