/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../Common/Config';
import {
  instance, makeDeleteRequest, makeGetRequest, makePutRequest,
} from '../../../Common/NetworkOps';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { GetById, GetListingPayload, ListingResponse } from '../../../utils/type';
import {
  CustomerContactListResponseFormat,
  DeleteOilReportPayload,
  GetAllUnitSerialNumbers,
  GetComponentSerialNumbers,
  GetCustomerContactListPayload,
  GetExcelDataPayload, GetExcelResponse, OilReportPdfDataFormat, ReplaceUnitCompPayload, ReportData, ReportDetailsById, UploadExcelPayload,
} from '../utils/types';
import {
  deleteOilReportFailure,
  deleteOilReportStart,
  deleteOilReportSuccess,
  getAllComponentSerialNumbersFailure,
  getAllComponentSerialNumbersStart,
  getAllComponentSerialNumbersSuccess,
  getAllUnitSerialNumberFailure,
  getAllUnitSerialNumberStart,
  getAllUnitSerialNumberSuccess,
  getCustomerContactList,
  getCustomerContactListFailure,
  getCustomerContactListSuccess,
  getExcelDataFailure,
  getExcelDataStart,
  getExcelDataSuccess,
  getOilReportByIdFailure,
  getOilReportByIdStart,
  getOilReportByIdSuccess,
  getOilReportFailure, getOilReportPdfDataFailure, getOilReportPdfDataStart, getOilReportPdfDataSuccess, getOilReportsStart, getOilReportSuccess,
  OilReportActions, replaceUnitCompFailure, replaceUnitCompStart, replaceUnitCompSuccess,
  updateOilReportFailure, updateOilReportStart, updateOilReportSuccess, uploadExcelFailure, uploadExcelStart, uploadExcelSuccess,
} from './oilReportSlice';

async function getOilReports(data: GetListingPayload): Promise<ListingResponse<ReportData>> {
  const url = `${Config.oilReport.getAllOilReports}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<ReportData>>(url);
  return result.data;
}

export const getOilReportsEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getOilReportsStart.type),
  map((x) => x.payload),
  mergeMap((data: GetListingPayload) => from(getOilReports(data)).pipe(
    map((res: ListingResponse<ReportData>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getOilReportSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getOilReportFailure();
    }),
    takeUntil(action$.pipe(filter(getOilReportsStart.match))),
    catchError((error) => of(getOilReportFailure(error))),
  )),
);

async function getOilReportById(data: string): Promise<GetById<ReportDetailsById>> {
  const url = `${Config.oilReport.getSelectedOilReport}?ReportId=${data}`;
  const result = await makeGetRequest<GetById<ReportDetailsById>>(url);
  return result.data;
}

export const getOilReportByIdEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getOilReportByIdStart.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getOilReportById(data)).pipe(
    map((res: GetById<ReportDetailsById>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getOilReportByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getOilReportByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getOilReportByIdStart.match))),
    catchError((error) => of(getOilReportByIdFailure(error))),
  )),
);

async function updateOilReport(data: ReportDetailsById): Promise<GetById<string>> {
  const url = `${Config.oilReport.updateOilReport}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateOilReportEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(updateOilReportStart.type),
  map((x) => x.payload),
  mergeMap((data: ReportDetailsById) => from(updateOilReport(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(updateOilReportSuccess(), getOilReportByIdStart(data.ReportId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateOilReportFailure());
    }),
    takeUntil(action$.pipe(filter(updateOilReportStart.match))),
    catchError((error) => of(updateOilReportFailure(error))),
  )),
);

async function getOilReportPdfApiCall(data: string): Promise<OilReportPdfDataFormat> {
  const url = `${Config.oilReport.getOilReportPdfData}?ReportId=${data}`;
  const result = await makeGetRequest<OilReportPdfDataFormat>(url);
  return result.data;
}

export const getOilReportPdfEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getOilReportPdfDataStart.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getOilReportPdfApiCall(data)).pipe(
    map((res: OilReportPdfDataFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getOilReportPdfDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getOilReportPdfDataFailure();
    }),
    takeUntil(action$.pipe(filter(getOilReportPdfDataStart.match))),
    catchError((error: AxiosError<OilReportPdfDataFormat>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getOilReportPdfDataFailure());
    }),
  )),
);

async function uploadExcel(data: UploadExcelPayload): Promise<GetById<string>> {
  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userID = localStorage.getItem(localStorageConstants.USER_ID);
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
      LoginUserId: userID,
    },
  };
  const url = `${Config.oilReport.uploadExcel}`;
  const result = await instance.post(url, data, axiosConfig);
  return result.data;
}

export const uploadExcelEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(uploadExcelStart.type),
  map((x) => x.payload),
  mergeMap((data: UploadExcelPayload) => from(uploadExcel(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        return of(uploadExcelSuccess(res.BMT.Result), getExcelDataStart({
          documentId: res.BMT.Result,
          page: 1,
          rowsPerPage: 10,
          searchQuery: '',
        }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(uploadExcelFailure());
    }),
    takeUntil(action$.pipe(filter(uploadExcelStart.match))),
    catchError((error) => of(uploadExcelFailure(error))),
  )),
);

async function getExcelData(data: GetExcelDataPayload): Promise<ListingResponse<GetExcelResponse>> {
  // eslint-disable-next-line max-len
  const url = `${Config.oilReport.getExcelData}?documentId=${data.documentId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetExcelResponse>>(url);
  return result.data;
}

export const getExcelDataEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getExcelDataStart.type),
  map((x) => x.payload),
  mergeMap((data: GetExcelDataPayload) => from(getExcelData(data)).pipe(
    map((res: ListingResponse<GetExcelResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getExcelDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getExcelDataFailure();
    }),
    takeUntil(action$.pipe(filter(getExcelDataStart.match))),
    catchError((error) => of(getExcelDataFailure(error))),
  )),
);

async function getAllUnitsSerialNumber(data: GetListingPayload): Promise<ListingResponse<GetAllUnitSerialNumbers>> {
  const url = `${Config.oilReport.allUnitSerialNumbers}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetAllUnitSerialNumbers>>(url);
  return result.data;
}

export const getAllUnitsSerialNumberEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getAllUnitSerialNumberStart.type),
  map((x) => x.payload),
  mergeMap((data: GetListingPayload) => from(getAllUnitsSerialNumber(data)).pipe(
    map((res: ListingResponse<GetAllUnitSerialNumbers>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAllUnitSerialNumberSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAllUnitSerialNumberFailure();
    }),
    takeUntil(action$.pipe(filter(getAllUnitSerialNumberStart.match))),
    catchError((error) => of(getAllUnitSerialNumberFailure(error))),
  )),
);

async function getAllComponentSerialNumber(data: GetListingPayload): Promise<ListingResponse<GetComponentSerialNumbers>> {
  const url = `${Config.oilReport.allCompSerialNumbers}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetComponentSerialNumbers>>(url);
  return result.data;
}

export const getAllComponentSerialNumberEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getAllComponentSerialNumbersStart.type),
  map((x) => x.payload),
  mergeMap((data: GetListingPayload) => from(getAllComponentSerialNumber(data)).pipe(
    map((res: ListingResponse<GetComponentSerialNumbers>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAllComponentSerialNumbersSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAllComponentSerialNumbersFailure();
    }),
    takeUntil(action$.pipe(filter(getAllComponentSerialNumbersStart.match))),
    catchError((error) => of(getAllComponentSerialNumbersFailure(error))),
  )),
);

async function replaceUnitComp(data: ReplaceUnitCompPayload): Promise<GetById<string>> {
  const url = `${Config.oilReport.replaceUniComp}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}

export const replaceUnitCompEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(replaceUnitCompStart.type),
  map((x) => x.payload),
  mergeMap((data: ReplaceUnitCompPayload) => from(replaceUnitComp(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(replaceUnitCompSuccess(), getOilReportsStart({
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(replaceUnitCompFailure());
    }),
    takeUntil(action$.pipe(filter(replaceUnitCompStart.match))),
    catchError((error) => of(replaceUnitCompFailure(error))),
  )),
);

async function deleteOilReport(data: DeleteOilReportPayload): Promise<GetById<string>> {
  const url = `${Config.oilReport.deleteOilReport}?ReportId=${data.reportId}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}

export const deleteOilReportEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(deleteOilReportStart.type),
  map((x) => x.payload),
  mergeMap((data: DeleteOilReportPayload) => from(deleteOilReport(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteOilReportSuccess(), getOilReportsStart({
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        }));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteOilReportFailure());
    }),
    takeUntil(action$.pipe(filter(deleteOilReportStart.match))),
    catchError((error) => of(deleteOilReportFailure(error))),
  )),
);

// GET CONTACT LIST BY CUSTOMER ID
async function getCustomerContactListApiCall(data: GetCustomerContactListPayload): Promise<CustomerContactListResponseFormat> {
  const url = `${Config.oilReport.getContactDetailsByCustID}?CustomerId=${data?.customerId}&searchItem=${data.searchQuery}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&Type=2`;
  const result = await makeGetRequest<CustomerContactListResponseFormat>(url);
  return result.data;
}

export const getCustomerContactListEpic = (action$: Observable<OilReportActions>) => action$.pipe(
  ofType(getCustomerContactList.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: GetCustomerContactListPayload) => from(getCustomerContactListApiCall(data)).pipe(
    map((res: CustomerContactListResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getCustomerContactListSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getCustomerContactListFailure();
    }),
    takeUntil(action$.pipe(filter(getCustomerContactList.match))),
    catchError((error) => of(getCustomerContactListFailure(error))),
  )),
);
