/* eslint-disable consistent-return */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import RequiredFiled from '../../../../utils/RequiredField';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { ComponentAddInventoryFormInterface, ComponentAddInventoryInterface } from '../../utils/types';
import { InventoryFormFields } from '../../utils/constants';
import { RootState } from '../../../../redux/rootState';
import { resetSetInventoryValues, setInventoryValues } from '../../utils/helper';
import { addEquipmentStart } from '../redux/inventorySlice';
import { Entries } from '../redux/type';
import { getProductDetailsByIdStart } from '../../SendEquipmentScreen/redux/sendEquipmentScreenSlice';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function ComponentAddInventory({
  show, setOpenFrom, productFormId, callback, viewOnly = false,
}: ComponentAddInventoryInterface): JSX.Element {
  const {
    control, handleSubmit, setValue, watch,
  } = useForm<ComponentAddInventoryFormInterface>();
  const { productDetailsDataById } = useSelector((state: RootState) => state.sendEquipmentRequestReducer);
  const { inventoryMaster, inventoryData } = useSelector((state: RootState) => state.inventoryReducer);

  const dispatch = useDispatch();
  const equipmentType = watch(InventoryFormFields.equipmentType);
  const closeAddModal = () => {
    setOpenFrom();
    callback();
  };

  const isSerialisedChecked = watch(InventoryFormFields.serialised);

  const onSubmitHotelForm = (data: ComponentAddInventoryFormInterface) => {
    setOpenFrom();
    const statusId = inventoryMaster?.Status?.find((ele: Entries) => ele.Text === data?.status);
    if (productFormId) {
      const payload = {
        ProductNumber: productFormId,
        StatusId: statusId?.Value,
        AssignedFor: data?.assignedFor || '',
        MaterialId: data?.material || null,
        Description: data?.description || '',
        UnsNumber: data?.uns || '',
        OnHand: data?.onHand || '',
        SerialNumber: data?.serial || '',
        CategoryId: data?.category !== '' ? Number(data?.category) : null,
        EquipmentTypeId: Number(data?.equipmentType),
        Location: data?.location || '',
        Specification: data?.specification || '',
        IsSerialized: data?.serialised || false,
        IsDiscarded: data?.discarded || false,
      };
      dispatch(addEquipmentStart({ payload, callback: closeAddModal }));
      return;
    }
    const payload = {
      ProductNumber: '',
      StatusId: statusId?.Value,
      AssignedFor: data?.assignedFor || '',
      MaterialId: data?.material || null,
      Description: data?.description || '',
      UnsNumber: data?.uns || '',
      OnHand: data?.onHand || '',
      SerialNumber: data?.serial || '',
      CategoryId: data?.category !== '' ? Number(data?.category) : null,
      EquipmentTypeId: Number(data?.equipmentType),
      Location: data?.location || '',
      Specification: data?.specification || '',
      IsSerialized: data?.serialised || false,
      IsDiscarded: data?.discarded || false,
    };
    dispatch(addEquipmentStart({ payload, callback: closeAddModal }));
  };

  useEffect(() => {
    if (isSerialisedChecked) {
      const productName = watch(InventoryFormFields.product);
      setValue(InventoryFormFields.serial, productName);
      return;
    }
    setValue(InventoryFormFields.serial, '');
  }, [isSerialisedChecked]);

  useEffect(() => {
    if (show) {
      setValue(InventoryFormFields.product, inventoryData?.NewProductNumber ?? '');
    }
    if (productFormId !== '') {
      dispatch(getProductDetailsByIdStart(productFormId));
    }
    return () => {
      resetSetInventoryValues(setValue);
    };
  }, [show, productFormId]);

  useEffect(() => {
    if (productDetailsDataById?.ProductNumber) {
      const inventoryFormFieldsStatus = inventoryMaster?.Status?.find((ele: Entries) => ele?.Value === productDetailsDataById.StatusId);
      setInventoryValues(setValue, productDetailsDataById);
      setValue(InventoryFormFields.status, String(inventoryFormFieldsStatus?.Text));
    }
  }, [productDetailsDataById]);

  const getEquipmentType = () => {
    if (inventoryMaster?.EquipmentTypes?.length > 0) {
      let allEquipmentType = inventoryMaster?.EquipmentTypes;
      allEquipmentType = allEquipmentType.slice(-2);
      return allEquipmentType?.length > 0 ? allEquipmentType : [];
    }
    return [];
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="dialogTitle_style">
          <div className="dialogTitle_style_div mob_dialogTitle_style_div">
            <div className="customerInfo">{productFormId ? 'Edit Item' : 'Add Item'}</div>
            <div style={{ display: 'flex' }}>
              <div className="mr-12">
                <CustomCheckbox
                  name={InventoryFormFields.discarded}
                  control={control}
                  label="Discarded"
                  isDisable={!productFormId || viewOnly}
                  defaultValue={false}
                />
              </div>
              <div className="mr-12">
                <CustomCheckbox
                  name={InventoryFormFields.serialised}
                  control={control}
                  label="Serialised"
                  defaultValue={false}
                  isDisable={viewOnly}
                />
              </div>
            </div>
          </div>
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent_style">
          <div className="dialogContent_style_div ">
            <form onSubmit={handleSubmit(onSubmitHotelForm)}>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Status</label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      name={InventoryFormFields.status}
                      id={InventoryFormFields.status}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Assigned For</label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      name={InventoryFormFields.assignedFor}
                      id={InventoryFormFields.assignedFor}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12} />
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Product #</label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      name={InventoryFormFields.product}
                      id={InventoryFormFields.product}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Material</label>
                    <ComponentSelectInput
                      name={InventoryFormFields.material}
                      control={control}
                      defaultValue="US"
                      size="small"
                      disabled={viewOnly}
                      entries={inventoryMaster?.Materials?.length > 0 ? inventoryMaster?.Materials : []}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Description
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 50 }}
                      required
                      isDisable={viewOnly}
                      name={InventoryFormFields.description}
                      id={InventoryFormFields.description}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      UNS #
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 15 }}
                      name={InventoryFormFields.uns}
                      id={InventoryFormFields.uns}
                      size="small"
                      isDisable={viewOnly}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      On Hand
                    </label>
                    <ComponentTextInput
                      control={control}
                      regEx={/^(?:[1-9]\d{0,2}|999)$/}
                      name={InventoryFormFields.onHand}
                      id={InventoryFormFields.onHand}
                      size="small"
                      isDisable={viewOnly}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Serial #
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 15 }}
                      isDisable={!!isSerialisedChecked || viewOnly}
                      name={InventoryFormFields.serial}
                      id={InventoryFormFields.serial}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Category
                    </label>
                    <ComponentSelectInput
                      name={InventoryFormFields.category}
                      control={control}
                      defaultValue="US"
                      disabled={viewOnly}
                      size="small"
                      entries={inventoryMaster?.Categories?.length > 0 ? inventoryMaster?.Categories : []}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Equipment Type
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInput
                      name={InventoryFormFields.equipmentType}
                      control={control}
                      defaultValue="US"
                      disabled={viewOnly || !!productFormId}
                      required
                      size="small"
                      entries={getEquipmentType()}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Location
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 100 }}
                      name={InventoryFormFields.location}
                      id={InventoryFormFields.location}
                      size="small"
                      isDisable={viewOnly}
                      multiline
                      rowSize={3}
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Specification
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 50 }}
                      name={InventoryFormFields.specification}
                      id={InventoryFormFields.specification}
                      size="small"
                      isDisable={viewOnly}
                      multiline
                      rowSize={3}
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button onClick={setOpenFrom} className="button_cancel">Close</Button>
                    </div>
                    {!viewOnly
                      && (
                        <div className="button_margin_left">
                          <Button
                            disabled={!equipmentType}
                            type="submit"
                            className={!equipmentType ? 'disableBtn' : 'button_save_and_next'}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentAddInventory;
