import dayjs from 'dayjs';
import { AddAnalystBody, AnalystForm, MainFormValues } from '../../AnalystAddScreen/utils/types';

export function setValues(analystDataById: AddAnalystBody, setValue: SetValue) {
  if (analystDataById?.HireDate) {
    const hireDate = dayjs(new Date(analystDataById?.HireDate));
    if (hireDate) {
      setValue(AnalystForm.hireDate, hireDate);
    }
  }
  setValue(AnalystForm.sirName, analystDataById?.SalutationId);
  setValue(AnalystForm.firstName, analystDataById?.FirstName);
  setValue(AnalystForm.lastName, analystDataById?.LastName);
  setValue(AnalystForm.address, analystDataById?.Address);
  setValue(AnalystForm.zip, analystDataById?.Pincode);
  setValue(AnalystForm.analystState, analystDataById?.StateName);
  setValue(AnalystForm.analystCity, analystDataById?.CityName);
  setValue(AnalystForm.country, analystDataById?.CountryName);
  setValue(AnalystForm.code, analystDataById?.PhoneCode);
  setValue(AnalystForm.phone, analystDataById?.Phone);
  setValue(AnalystForm.cell, analystDataById?.Cell);
  setValue(AnalystForm.email, analystDataById?.Email);
  setValue(AnalystForm.initials, analystDataById?.Initials);
  setValue(AnalystForm.territory, analystDataById?.TerritoryId);
  setValue(AnalystForm.lsInitials, analystDataById?.LsInitials);
  setValue(AnalystForm.boZip, analystDataById?.BasePincode);
  setValue(AnalystForm.boState, analystDataById?.BaseStateName);
  setValue(AnalystForm.boCity, analystDataById?.BaseCityName);
  setValue(AnalystForm.boCountry, analystDataById?.BaseCountryName);
  setValue(AnalystForm.sbZip, analystDataById?.SecondaryPincode);
  setValue(AnalystForm.sbState, analystDataById?.SecondaryStateName);
  setValue(AnalystForm.sbCity, analystDataById?.SecondaryCityName);
  setValue(AnalystForm.sbCountry, analystDataById?.SecondaryCountryName);
}

export function getRequestPayload(data: MainFormValues) {
  return {
    SalutationId: Number(data?.sirName),
    FirstName: data?.firstName,
    LastName: data?.lastName,
    Address: data?.address,
    Pincode: data?.zip,
    StateName: data?.state,
    CityName: data.city === 'Other' ? data?.otherAnalystCity : data.city,
    IsOtherCityMain: data?.city === 'Other',
    CountryName: data?.country,
    PhoneCode: data?.code,
    Phone: data?.phone,
    Cell: data?.cell,
    Email: data?.email,
    Initials: data?.initials,
    TerritoryId: Number(data?.territory),
    HireDate: dayjs(data?.hireDate).format('DD-MM-YYYY'),
    LsInitials: data?.lsInitials,
    BasePincode: data?.boZip,
    BaseStateName: data?.boState,
    BaseCityName: data.boCity === 'Other' ? data?.otherBOCity : data.boCity,
    IsOtherCityBase: data?.boCity === 'Other',
    BaseCountryName: data?.boCountry,
    SecondaryPincode: data?.sbZip,
    SecondaryStateName: data?.sbState,
    SecondaryCityName: data.sbCity === 'Other' ? data?.otherSBCity : data.sbCity,
    IsOtherCitySecondary: data?.sbCity === 'Other',
    SecondaryCountryName: data?.sbCountry,
  };
}
