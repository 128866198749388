import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styles from './ConfirmationModalStyle.module.scss';
import { TypesConfirmationModal } from './TypeConfirmationModal';

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function ConfirmationModal({
  show, setOpenFrom, handleConfirmation, text, confirmationIcon, readOnly = false,
}: TypesConfirmationModal): JSX.Element {
  const getButtons = () => {
    if (readOnly) {
      return (
        <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>Close</button>
      );
    }
    return (
      <div className={styles.divButtons}>
        <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>No</button>
        <button
          type="button"
          className={`btn ${styles.yesBtn}`}
          onClick={() => {
            if (handleConfirmation) {
              handleConfirmation();
            }
          }}
        >
          Yes
        </button>
      </div>
    );
  };
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.confirmationIcon}>
          {confirmationIcon}
        </div>
        <div className={styles.modalModalHeading}>
          {text}
        </div>
        {getButtons()}
      </Box>
    </Modal>
  );
}
