import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  IconButton, Grid, Button,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import './StyleContactModal.scss';
import { ObjectSchema } from 'yup';
import {
  AddContactModalFields, ContactDetails, IAddContactModal, TypesAddContactModal,
} from '../../../utils/types';
import { ContactDefaultValue } from '../../../utils/constants';
import { addContactModalSchema } from '../../../utils/validations';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import { addContactStart, editContactStart } from '../../../redux/addCustomerSlice';
import { RootState } from '../../../../../redux/rootState';
import { assignContactValues } from '../../../utils/helper';
import { getPinCodeDetailsStart, removePinCodeDetails } from '../../../../../redux/masters/masterSlice';
import RequiredFiled from '../../../../../utils/RequiredField';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import { extRegex, phoneRegex } from '../../../../../utils/regex';
import { RHFAutocompleteField } from '../../../../../Common/ComponentAutoSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export default function AddContactModal({
  show, handleClose, mode, page, searchQuery, rowsPerPage,
}: TypesAddContactModal): JSX.Element {
  const { selectedContact } = useSelector((state: RootState) => state.addCustomerReducer);
  const {
    countries, phoneCodes, salutations, isError, citySuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const {
    handleSubmit, control, formState: { errors }, setValue, reset,
    watch,
  } = useForm<IAddContactModal>({
    defaultValues: ContactDefaultValue,
    resolver: yupResolver(addContactModalSchema as ObjectSchema<IAddContactModal>),
  });
  const dispatch = useDispatch();

  const { customerId } = useSelector((state: RootState) => state.addCustomerReducer);
  const { pinCodeDetails } = useSelector((state: RootState) => state.masterDataReducer);

  useEffect(() => {
    if (selectedContact) {
      assignContactValues(selectedContact, setValue);
    } else {
      reset();
    }
  }, [selectedContact]);

  const onSubmit = (data: IAddContactModal) => {
    const req: ContactDetails = {
      CustomerId: customerId || 'Cust1',
      SaluationId: data.title,
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.emailId,
      Office: data.office,
      Address: data.address,
      ZipCode: data.zip,
      StateCode: data.state,
      CityName: data.city === 'Other' ? data?.contactOtherCity : data.city,
      IsOtherCity: data?.city === 'Other',
      CountryCode: data.country,
      PhoneCode: data.code,
      Phone: data.phone,
      Extension: data.ext,
      Cell: data.cell,
      RecieveMonthlyReport: data.monthlyReport,
      EctReport: data.ECTReport,
      OilReport: data.oilReport,
      page,
      rowsPerPage,
      searchQuery,
    };

    if (selectedContact?.ContactId) {
      dispatch(editContactStart({ ...req, ContactId: selectedContact.ContactId }));
    } else {
      dispatch(addContactStart(req));
    }

    reset();
    handleClose();
  };

  const zip = watch(AddContactModalFields.zip);
  const country = watch(AddContactModalFields.country);

  useEffect(() => {
    if (zip && zip?.length >= 5) {
      dispatch(removePinCodeDetails());
      setValue(AddContactModalFields.state, '');
      const queryPayload = {
        pinCode: zip,
        countryCode: country,
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (zip?.length < 5) {
      dispatch(removePinCodeDetails());
      setValue(AddContactModalFields.state, '');
    }
  }, [zip, country]);

  useEffect(() => {
    if (pinCodeDetails) {
      setValue(AddContactModalFields.state, pinCodeDetails.StateName);
    }
  }, [pinCodeDetails]);

  useEffect(() => () => {
    dispatch(removePinCodeDetails());
  }, []);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(AddContactModalFields.city);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  const handleZipChange = () => {
    setValue(AddContactModalFields.city, '');
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">
              {mode}
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="customerInfo_main_container div_job_container_info customModalScroll mob_pd-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item md={1.3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="no_label_Style" />
                    <ComponentSelectInput
                      name={AddContactModalFields.title}
                      control={control}
                      errors={errors}
                      size="small"
                      entries={salutations}
                    />
                  </div>
                </Grid>

                <Grid item md={4.7} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      First Name
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.firstName}
                      id={AddContactModalFields.firstName}
                      regEx={/^[A-Za-z ]+$/}
                      inputProps={{ maxLength: 25 }}
                      errors={errors}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Last Name   </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 25 }}
                      name={AddContactModalFields.lastName}
                      id={AddContactModalFields.lastName}
                      regEx={/^[A-Za-z ]+$/}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Email ID   </label>
                    <ComponentTextInput
                      control={control}
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 255 }}
                      name={AddContactModalFields.emailId}
                      id={AddContactModalFields.emailId}
                      size="small"
                      errors={errors}
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Office </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 200 }}
                      className="nonCapitalizeField"
                      name={AddContactModalFields.office}
                      id={AddContactModalFields.office}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Address  </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 200 }}
                      className="nonCapitalizeField"
                      name={AddContactModalFields.address}
                      id={AddContactModalFields.address}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Country
                    </label>
                    <ComponentSelectInput
                      name={AddContactModalFields.country}
                      control={control}
                      errors={errors}
                      size="small"
                      entries={countries}
                    />
                  </div>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Zip
                    </label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.zip}
                      inputProps={{ maxLength: 10 }}
                      className="nonCapitalizeField"
                      handleChange={handleZipChange}
                      id={AddContactModalFields.zip}
                      errors={errors}
                      size="small"
                    />
                  </div>
                  {isError ? (<span className="errorMessage">Invalid zip code</span>) : ''}
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      State
                    </label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.state}
                      errors={errors}
                      isDisable
                      id={AddContactModalFields.state}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      City
                    </label>
                    {renderCityInput() ? (
                      <RHFAutocompleteField
                        options={citySuggestions || []}
                        control={control}
                        name={AddContactModalFields.CONTACT_OTHER_CITY}
                      />
                    )
                      : (
                        <ComponentSelectInput
                          name={AddContactModalFields.city}
                          defaultValue=""
                          control={control}
                          size="small"
                          entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                        />
                      )}
                  </div>
                </Grid>

                <Grid item md={1.5} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Code
                    </label>
                    <ComponentSelectInput
                      name={AddContactModalFields.code}
                      control={control}
                      errors={errors}
                      size="small"
                      entries={phoneCodes}
                    />
                  </div>
                </Grid>
                <Grid item md={3.5} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Phone
                    </label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.phone}
                      id={AddContactModalFields.phone}
                      errors={errors}
                      regEx={phoneRegex}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={1} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Ext</label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.ext}
                      regEx={extRegex}
                      id={AddContactModalFields.ext}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Cell</label>
                    <ComponentTextInput
                      control={control}
                      name={AddContactModalFields.cell}
                      id={AddContactModalFields.cell}
                      regEx={phoneRegex}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_checkbox mobStyleCheckbox">
                    <CustomCheckbox
                      name={AddContactModalFields.monthlyReport}
                      control={control}
                      label="Receive Monthly Report"
                    />
                    <CustomCheckbox
                      name={AddContactModalFields.ECTReport}
                      control={control}
                      label="EC Report (in PDF)"
                    />
                    <CustomCheckbox
                      name={AddContactModalFields.oilReport}
                      control={control}
                      label="OIL Report (in PDF)"
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel" onClick={handleClose}> Cancel </Button>
                    </div>
                    <div className="button_margin_left">
                      <Button type="submit" className="button_save_and_next" disabled={isError}> Save  </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </div>

  );
}
