/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect, useState } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { InventoryIssueHistoryColumns } from '../../utils/constants';
import DataTable from '../../../../Common/DataTable';
import { TypesDialogBox } from '../../../../utils/interface';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import { getIssueHistoryStart } from '../redux/inventorySlice';
import { formatIssueHistoryData } from '../../utils/helper';
import { InventoryIssueHistoryData } from '../../utils/types';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentIssueHistory({ show, setOpenFrom, rowId }: TypesDialogBox): JSX.Element {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const { isIssueHistoryLoading, issueHistoryData } = useSelector((state: RootState) => state.inventoryReducer);

  const handleData = () => {
    const payload = {
      productId: rowId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getIssueHistoryStart(payload));
  };

  useEffect(() => {
    if (show) {
      handleData();
    }
  }, [show]);

  useEffect(() => {
    dispatch(setLoader(isIssueHistoryLoading));
  }, [isIssueHistoryLoading]);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          Issue History
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DataTable<InventoryIssueHistoryData>
                data={issueHistoryData?.Data?.length > 0 ? formatIssueHistoryData(issueHistoryData?.Data) : []}
                columns={InventoryIssueHistoryColumns}
                totalRecords={issueHistoryData?.TotalRecords}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleData={handleData}
                rowId={rowId}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentIssueHistory;
