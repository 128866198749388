export enum PhotoForm {
  jobOrder = 'jobOrder',
  vessel = 'vessel',
  description = 'description',
}

export const PhotoDefaultValue = {
  jobOrder: '',
  vessel: '',
  description: '',
};
