import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AuthState, OtpTypes, Result,
} from '../utils/types';
import localStorageConstants from '../../../../utils/LocalStorageConstants';

const initialState: AuthState = {
  otpData: {},
  otpDataSuccess: {
    UserId: '',
    Token: '',
    Refresh_Token: '',
    FirstName: '',
    LastName: '',
    Email: '',
    RoleId: '',
  },
  isLoading: false,
  isSuccess: false,
  errorMessage: '',
  isError: false,
  userId: localStorage.getItem(localStorageConstants.USER_ID),
};

export const authSlice = createSlice({
  name: 'otp',
  initialState,
  reducers: {
    sendOtpData(state, action: PayloadAction<OtpTypes>) {
      return {
        ...state,
        isLoading: true,
        otpData: action.payload,
      };
    },
    otpDataSendSuccess(state, action: PayloadAction<Result>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        otpDataSuccess: action.payload,
        userId: action.payload.UserId,
      };
    },
    otpDataSendFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isSuccess: false,
        isError: true,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    stateReset(state) {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorMessage: '',
        // isSuccess: false,
      };
    },
    errorSet(state) {
      return {
        ...state,
        isError: false,
      };
    },
  },
});

export const {
  sendOtpData, otpDataSendSuccess, otpDataSendFailure, stateReset, errorSet,
} = authSlice.actions;
export const otpReducer = authSlice.reducer;

export type OtpActions =
  | ReturnType<typeof sendOtpData>
  | ReturnType<typeof otpDataSendSuccess>
  | ReturnType<typeof stateReset>
  | ReturnType<typeof errorSet>
  | ReturnType<typeof otpDataSendFailure>;
