import React, { useEffect, useState } from 'react';
import { Grid, Card, Button } from '@mui/material';
import {
  EditorState,
  ContentState,
} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import { useDispatch, useSelector } from 'react-redux';
import { customOptions } from '../../../../Common/TextEditorOptions';
import { RootState } from '../../../../redux/rootState';
import { setSelectedTab } from '../../redux/sliceJobOrder';
import { COMMENTS_TAB, VESSELS_TAB } from '../../utils/constants';

function ScreenAnalystNote() {
  const { AnalystNoteData } = useSelector((state: RootState) => state?.jobOrderReducer);
  const dispatch = useDispatch();
  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };
  const [comment, setComment] = useState<EditorState[]>([]);

  useEffect(() => {
    if (AnalystNoteData) {
      const convertedComments = AnalystNoteData?.map((data: string) => htmlToEditorState(data));
      setComment(convertedComments);
    }
  }, [AnalystNoteData]);

  const handleComment = (newEditorState: EditorState, index: number) => {
    const updatedComments = [...comment];
    updatedComments[index] = newEditorState;
    setComment(updatedComments);
  };

  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <Card className="comment-card">
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Analyst Notes
                  </div>
                </div>
                {
                  comment?.map((editorState, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="customerInfo_main_container" key={index}>
                      <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                          <div className="editorContainer">
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={(newEditorState: EditorState) => handleComment(newEditorState, index)}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              localization={{ locale: 'en' }}
                              toolbar={customOptions}
                              readOnly
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))
                }
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={() => dispatch(setSelectedTab(VESSELS_TAB))}>Back</Button>
            </div>
            <div className="button_margin_left">
              <Button className="button_save_and_next" onClick={() => dispatch(setSelectedTab(COMMENTS_TAB))}>Save & Next</Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenAnalystNote;
