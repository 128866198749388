import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddEquipmentCategoryListApiResponse, AddEquipmentCategoryListData, DeleteEquipmentCategoryListResponse, GetAllEquipmentCategoryListApiResponse,
  PayloadTypeDeleteEquipmentCategoryList, PayloadTypeGetEquipmentCategoryList, UpdateEquipmentCategoryListApiResponse,
  UpdateEquipmentCategoryListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addEquipmentCategoryListStart, deleteEquipmentCategoryListStart, failureAddEquipmentCategoryList, failureDeleteEquipmentCategoryList,
  failureEquipmentCategoryList, failureUpdateEquipmentCategoryList, getEquipmentCategoryListStart,
  EquipmentCategoryTypeActions, successAddEquipmentCategoryList, successDeleteEquipmentCategoryList, successEquipmentCategoryList,
  successUpdateEquipmentCategoryList, updateEquipmentCategoryList,
} from './sliceEquipmentCategoryList';

async function GetEquipmentCategoryList(data: PayloadTypeGetEquipmentCategoryList): Promise<GetAllEquipmentCategoryListApiResponse> {
  const url = `${Config.Lists.getEquipmentCategoryList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllEquipmentCategoryListApiResponse>(url);
  return result?.data;
}

export const epicGetEquipmentCategoryList = (action$: Observable<EquipmentCategoryTypeActions>) => action$.pipe(
  filter(getEquipmentCategoryListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetEquipmentCategoryList) => from(GetEquipmentCategoryList(data)).pipe(
    map((res: GetAllEquipmentCategoryListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successEquipmentCategoryList(res?.BMT?.Result);
      }
      return failureEquipmentCategoryList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getEquipmentCategoryListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetAllEquipmentCategoryListApiResponse>) => of(failureEquipmentCategoryList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateEquipmentCategoryList(data: UpdateEquipmentCategoryListData): Promise<UpdateEquipmentCategoryListApiResponse> {
  const url = `${Config.Lists.updateEquipmentCategoryList}`;
  const result = await makePostRequest<UpdateEquipmentCategoryListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateEquipmentCategoryList = (action$: Observable<EquipmentCategoryTypeActions>) => action$.pipe(
  filter(updateEquipmentCategoryList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateEquipmentCategoryList(data)).pipe(
    mergeMap((res: UpdateEquipmentCategoryListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateEquipmentCategoryList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateEquipmentCategoryList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateEquipmentCategoryList.match))),
    catchError((error: AxiosError<UpdateEquipmentCategoryListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateEquipmentCategoryList(errorMessage));
    }),
  )),
);

async function AddEquipmentCategoryList(data: AddEquipmentCategoryListData): Promise<AddEquipmentCategoryListApiResponse> {
  const url = `${Config.Lists.addEquipmentCategoryList}`;
  const result = await makePostRequest<AddEquipmentCategoryListApiResponse>(url, data);
  return result?.data;
}

export const epicAddEquipmentCategoryList = (action$: Observable<EquipmentCategoryTypeActions>) => action$.pipe(
  filter(addEquipmentCategoryListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddEquipmentCategoryList(data)).pipe(
    mergeMap((res: AddEquipmentCategoryListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddEquipmentCategoryList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddEquipmentCategoryList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addEquipmentCategoryListStart.match))),
    catchError((error: AxiosError<AddEquipmentCategoryListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddEquipmentCategoryList(errorMessage));
    }),
  )),
);

async function DeleteEquipmentCategoryList(data: PayloadTypeDeleteEquipmentCategoryList): Promise<DeleteEquipmentCategoryListResponse> {
  const url = `${Config.Lists.deleteEquipmentCategoryList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteEquipmentCategoryListResponse>(url);
  return result?.data;
}

export const epicDeleteEquipmentCategoryList = (action$: Observable<EquipmentCategoryTypeActions>) => action$.pipe(
  filter(deleteEquipmentCategoryListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteEquipmentCategoryList) => from(DeleteEquipmentCategoryList(data)).pipe(
    mergeMap((res: DeleteEquipmentCategoryListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteEquipmentCategoryList(), getEquipmentCategoryListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteEquipmentCategoryList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteEquipmentCategoryListStart.match))),
    catchError((error: AxiosError<DeleteEquipmentCategoryListResponse>) => of(
      failureDeleteEquipmentCategoryList(error?.response?.data?.BMT?.ResponseMessage as string),
    )),
  )),
);
