import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../Common/Config';
import { makeFormPostRequest, makeGetRequest, makePutRequest } from '../../../Common/NetworkOps';
import {
  getPhotoById, getPhotoFailureById, getPhotosData, getPhotosFailure, getPhotosSuccess,
  getPhotoSuccessById, getVesselById, getVesselFailureById, getVesselSuccessById, PhotosActions,
  updatePhotoEditFailure,
  updatePhotoEditStart,
  updatePhotoEditSuccess,
  updatePhotoFailure, updatePhotoStart, updatePhotoSuccess,
} from './photosSlice';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  PhotoFormAPIValues, PhotoFormDataById, PhotosQueryPayload, PhotosResponseFormat, PhotoUpdateApiResponse,
  UpdatePhotoPayload,
  VesselResultDataById,
} from '../utils/types';
import { GetById } from '../../../utils/type';

// Get Expense Report Data
async function getPhotosListData(data: PhotosQueryPayload): Promise<PhotosResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.photosBaseUrl.getPhotosListDataUrl}?pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<PhotosResponseFormat>(url);
  return result.data;
}
export const getPhotosDataEpic = (action$: Observable<PhotosActions>) => action$.pipe(
  ofType(getPhotosData.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: PhotosQueryPayload) => from(getPhotosListData(data)).pipe(
    map((res: PhotosResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getPhotosSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getPhotosFailure();
    }),
    takeUntil(action$.pipe(filter(getPhotosData.match))),
    catchError((error) => of(getPhotosFailure(error))),
  )),
);

// Get Expense Report by Id
async function getPhotoDataById(data: { id: string }): Promise<PhotoFormDataById> {
  const url = `${Config.photosBaseUrl.getPhotoByIdUrl}?id=${data.id}`;
  const result = await makeGetRequest<PhotoFormDataById>(url);
  return result.data;
}
export const getPhotoByIdEpic = (action$: Observable<PhotosActions>) => action$.pipe(
  ofType(getPhotoById.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: { id: string }) => from(getPhotoDataById(data)).pipe(
    map((res: PhotoFormDataById) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getPhotoSuccessById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getPhotoFailureById();
    }),
    takeUntil(action$.pipe(filter(getPhotoById.match))),
    catchError((error) => of(getPhotoFailureById(error))),
  )),
);

// Update Photo Data
async function updatePhoto(data: PhotoFormAPIValues): Promise<PhotoUpdateApiResponse> {
  const url = `${Config.photosBaseUrl.updatePhotoUrl}`;
  const result = await makePutRequest<PhotoUpdateApiResponse>(url, data);
  return result.data;
}
export const updatePhotoEpic = (action$ : Observable<PhotosActions>) => action$.pipe(
  filter(updatePhotoStart.match),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap(({ payload, listPayload }:UpdatePhotoPayload) => from(updatePhoto(payload)).pipe(
    map((res: PhotoUpdateApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payloadList = {
          page: listPayload.page,
          searchQuery: listPayload.searchQuery || '',
          rowsPerPage: listPayload.rowsPerPage,
        };
        return (updatePhotoSuccess(), getPhotosData(payloadList));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return (updatePhotoFailure());
    }),
    takeUntil(action$.pipe(filter(updatePhotoStart.match))),
    catchError((error) => of(updatePhotoFailure(error))),
  )),
);

// Get Expense Report by Id
async function getAllVessel(data: { id: string }): Promise<GetById<VesselResultDataById[]>> {
  const url = `${Config.photosBaseUrl.vesselUrl}?jobOrder=${data.id}`;
  const result = await makeGetRequest<GetById<VesselResultDataById[]>>(url);
  return result.data;
}
export const getAllVesselEpic = (action$: Observable<PhotosActions>) => action$.pipe(
  ofType(getVesselById.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: { id: string }) => from(getAllVessel(data)).pipe(
    map((res: GetById<VesselResultDataById[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getVesselSuccessById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getVesselFailureById();
    }),
    takeUntil(action$.pipe(filter(getVesselById.match))),
    catchError((error) => of(getVesselFailureById(error))),
  )),
);

// Update Photo
async function updatePhotoEdit(data: FormData): Promise<PhotoUpdateApiResponse> {
  const url = `${Config.photosBaseUrl.updatePhotoEditUrl}`;
  const result = await makeFormPostRequest<PhotoUpdateApiResponse>(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
}
export const updatePhotoEditEpic = (action$ : Observable<PhotosActions>) => action$.pipe(
  filter(updatePhotoEditStart.match),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((payload :FormData) => from(updatePhotoEdit(payload)).pipe(
    map((res: PhotoUpdateApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updatePhotoEditSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return (updatePhotoEditFailure());
    }),
    takeUntil(action$.pipe(filter(updatePhotoEditStart.match))),
    catchError((error) => of(updatePhotoEditFailure(error))),
  )),
);
