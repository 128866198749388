import * as Yup from 'yup';
import english from '../../../assets/lang/english';

export const mainFormSchema = Yup.object({
  customerAlert: Yup.boolean(),
  smc: Yup.boolean(),
  bidAlert: Yup.boolean(),
  markup: Yup.string().required(english.markupError),
  customerName: Yup.string().required(english.customerNameValidation),
  address: Yup.string().required(english.addressValidation),
  companyCountryCode: Yup.string(),
  companyPhone: Yup.string(),
  companyExt: Yup.string(),
  companyAddress: Yup.string(),
  companyZip: Yup.string().required(english.zipValidation),
  companyState: Yup.string().required(english.stateValidation),
  companyCity: Yup.string().required(english.cityValidation),
  companyOtherCity: Yup.string(),
  companyCountry: Yup.string().required(english.countryValidation),
  companyName: Yup.string().test({
    name: 'required-with-smc',
    test(value) {
      const { smc } = this.parent;
      if (smc) {
        return !!value || this.createError();
      }
      return true;
    },
  }),
  customerNote: Yup.string().test({
    name: 'required-with-customer-alert',
    test(value) {
      const { customerAlert } = this.parent;
      if (customerAlert) {
        return !!value || this.createError();
      }
      return true;
    },
  }),
  bidAlertNote: Yup.string().test({
    name: 'required-with-bid-alert',
    test(value) {
      const { bidAlert } = this.parent;
      if (bidAlert) {
        return !!value || this.createError();
      }
      return true;
    },
  }),
  title: Yup.number().required(english.titleValidation),
  firstName: Yup.string().required(english.firstNameValidation),
  lastName: Yup.string(),
  primaryEmailId: Yup.string().email('Invalid email format'),
  contactCode: Yup.string(),
  phone: Yup.string(),
  alternativePhone: Yup.string(),
  sameCompanyAddress: Yup.boolean(),
  creditCardRequired: Yup.boolean(),
  approvedPO: Yup.boolean(),
  billToCorporate: Yup.string(),
  billingAddress: Yup.string(),
  billingOptionalAddress: Yup.string(),
  billingZip: Yup.string(),
  billingState: Yup.string(),
  billingCity: Yup.string(),
  billingOtherCity: Yup.string(),
  billingCountry: Yup.string(),
  contactTitle: Yup.number(),
  contactFirstName: Yup.string(),
  contactLastName: Yup.string(),
  contactEmailId: Yup.string().email('Invalid email format'),
  contactCountryCode: Yup.string(),
  contactPhone: Yup.string(),
  contactAlternativePhone: Yup.string(),
  smcId: Yup.string(),
});

export const addContactModalSchema = Yup.object({
  title: Yup.number().required(english.titleValidation),
  firstName: Yup.string().required(english.firstNameValidation),
  lastName: Yup.string(),
  emailId: Yup.string().email(english.invalidEmailFormat),
  office: Yup.string(),
  address: Yup.string(),
  zip: Yup.string(),
  state: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  code: Yup.string(),
  phone: Yup.string(),
  ext: Yup.string(),
  cell: Yup.string(),
  monthlyReport: Yup.boolean(),
  ECTReport: Yup.boolean(),
  oilReport: Yup.boolean(),
});
