import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentManufacturerListTable from './Components';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenManufacturerList() {
  const { ManufacturerListData } = useSelector((state: RootState) => state.ListManufacturerReducer);

  const excelData = useMemo(() => ManufacturerListData?.Data?.map((item) => {
    const transformedDataItems = {
      Description: item?.Description,
    };
    return transformedDataItems;
  }), [ManufacturerListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Unit_manufacturer_list',
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="UNIT MANUFACTURER LIST" downloadExcel={downloadExcel} />
      </Grid>
      <div className="whiteSpace" />
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentManufacturerListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenManufacturerList;
