/* eslint-disable max-len */
import React, {
  forwardRef,
  useEffect,
} from 'react';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LineChart from '../../../lineGraph';
import { getVesselMainByIdStart } from '../../redux/VesselMainRedux/vesselMainSlice';

interface PipeStructureProps {
  setOpenFrom: () => void;
  show: boolean;
  phaseId: string;
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

const Transition = forwardRef((
  props: TransitionProps & { children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function PhaseChart({
  setOpenFrom, show, phaseId, page, rowsPerPage, searchQuery,
}: PipeStructureProps) {
  const { jobOrderId, vesselId } = useParams();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (phaseId) {
  //     const payload = {
  //       id: phaseId,
  //     };
  //     dispatch(getPhaseChartByIdStart(payload));
  //   }
  // }, [phaseId]);

  useEffect(() => {
    const payload = {
      jobId: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getVesselMainByIdStart(payload));
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="xl"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={800}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Phase Chart
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <LineChart phaseId={phaseId} page={page} rowsPerPage={rowsPerPage} searchQuery={searchQuery} setOpenFrom={setOpenFrom} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
export default PhaseChart;
