import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { RecommendationsData } from './utils/types';
import { RootState } from '../../redux/rootState';
import { getRecommendationsStart } from './redux/recommendationSlice';
import { setLoader } from '../../redux/loaderSlice';

function ScreenRecommendations() {
  const Navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const { recommendations, totalRecommendations, isLoading } = useSelector((state:RootState) => state.recommendationReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getRecommendationsStart(params));
  };

  const data: RecommendationsData[] = useMemo(() => recommendations?.map((item, index) => {
    const transformedDataItem = {
      jobOrder: item.JobOrder,
      action: 'edit',
      sn: index,
      id: item.JobOrder,
    };
    return transformedDataItem;
  }), [recommendations]);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'jobOrder', label: 'Job Order' },
    ],
    [],
  );

  const handleEdit = (id: string) => {
    Navigate(`${id}`);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Recommendations" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<RecommendationsData>
          data={data}
          columns={columns}
          totalRecords={totalRecommendations}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleEdit={handleEdit}
        />
      </Grid>
    </Grid>
  );
}

export default ScreenRecommendations;
