import React from 'react';
import ComponentHazardTable from './ComponentHazardTable';
import ComponentHazardCheckBox from './ComponentHazardCheckBox';

function HazardAnalysis() {
  return (
    <div className="ExpenseReportPDFDiv">
      <ComponentHazardTable />
      <ComponentHazardCheckBox />
    </div>
  );
}

export default HazardAnalysis;
