import React, { useContext } from 'react';
import {
  Grid, Card, TextField, IconButton, Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import '../../Style/StyleSmc.scss';
import { Controller } from 'react-hook-form';
import { AddPlaylist } from '../../../../assets/TableIcons/AddPlaylist';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { AddSmcFormValue } from '../../Utils/ConstantSmc';
import { TypesAddCustomers } from './Types';
import EndPoints from '../../../../Routes/EndPoints';
import SMCCustomerModal from '../../../../Common/SmcCustomerModal';

export default function ComponentAddCustomer({
  control, handleTabCell, open, setOpen, onAddCustomerList, watch, subscribedCustomer,
}: TypesAddCustomers): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  // open modal
  const handleModal = () => {
    setOpen(!open);
  };

  const watchCustomerName = watch(AddSmcFormValue.TEMP_CUSTOMERS);
  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {translations.addCustomer}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations.customer}</label>
              <Controller
                name={AddSmcFormValue.TEMP_CUSTOMERS}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    size="small"
                    placeholder=""
                    value={value}
                    onClick={handleModal}
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Search here..">
                          <IconButton onClick={handleModal} className="search_icon_btn">
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid>
            <button
              type="button"
              className={`Style_icon ${!watchCustomerName ? 'disabled' : 'active'}`}
              onClick={onAddCustomerList}
              disabled={!watchCustomerName}
            >
              <AddPlaylist />
            </button>
          </Grid>
        </Grid>
      </div>
      <SMCCustomerModal
        handleTabCell={handleTabCell}
        show={open}
        setOpenFrom={handleModal}
        buttonText="Add New Customer"
        redirectLink={EndPoints.ADD_CUSTOMER}
        subscribedCustomer={subscribedCustomer}
      />
    </Card>
  );
}
