/* eslint-disable max-len */
import React from 'react';

export function RecommendationsIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3.55146V8.51612C3 9.37252 3.69505 10.0676 4.55146 10.0676H6.7235V12.2396C6.7235 12.3792 6.81658 12.5003 6.94691 12.5375C7.08033 12.5747 7.22307 12.522 7.29443 12.4041L8.7559 10.0676H10.8504L11.7068 12.3482C11.7533 12.4692 11.8681 12.5499 11.9984 12.5499C12.1288 12.5499 12.2436 12.4692 12.2901 12.3482L13.1465 10.0676H15.241L16.7025 12.4041C16.7614 12.4972 16.8607 12.5499 16.9662 12.5499C16.9941 12.5499 17.0221 12.5468 17.0531 12.5375C17.1865 12.5003 17.2765 12.3761 17.2765 12.2396V10.0676H19.4485C20.3049 10.0676 21 9.37252 21 8.51612V3.55146C21 2.69505 20.3049 2 19.4485 2H4.55146C3.69505 2 3 2.69505 3 3.55146ZM3.62058 3.55146C3.62058 3.03948 4.03948 2.62058 4.55146 2.62058H19.4454C19.9574 2.62058 20.3763 3.03948 20.3763 3.55146V8.51612C20.3763 9.0281 19.9574 9.44699 19.4454 9.44699H16.9631C16.7925 9.44699 16.6528 9.58662 16.6528 9.75728V11.1567L15.6754 9.59283C15.6195 9.50284 15.5203 9.44699 15.4117 9.44699H12.9293C12.799 9.44699 12.6842 9.52767 12.6376 9.64868L11.9984 11.3553L11.3592 9.64868C11.3127 9.52767 11.1979 9.44699 11.0676 9.44699H8.58524C8.47975 9.44699 8.37735 9.50284 8.3215 9.59283L7.34408 11.1567V9.75728C7.34408 9.58662 7.20445 9.44699 7.03379 9.44699H4.55146C4.03948 9.44699 3.62058 9.0281 3.62058 8.51612V3.55146Z" fill="#092245" />
      <path d="M13.8602 15.3422C13.8602 14.3152 13.0255 13.4805 11.9985 13.4805C10.9714 13.4805 10.1367 14.3152 10.1367 15.3422C10.1367 16.3693 10.9714 17.204 11.9985 17.204C13.0255 17.204 13.8602 16.3693 13.8602 15.3422ZM10.7573 15.3422C10.7573 14.6565 11.3127 14.1011 11.9985 14.1011C12.6842 14.1011 13.2396 14.6565 13.2396 15.3422C13.2396 16.028 12.6842 16.5834 11.9985 16.5834C11.3127 16.5834 10.7573 16.028 10.7573 15.3422Z" fill="#092245" />
      <path d="M12.619 17.8252H11.0675C9.86982 17.8252 8.89551 18.7995 8.89551 19.9972V21.2384H9.51609V19.9972C9.51609 19.1408 10.2111 18.4458 11.0675 18.4458H12.619C13.4754 18.4458 14.1705 19.1408 14.1705 19.9972V21.2384H14.791V19.9972C14.791 18.7995 13.8167 17.8252 12.619 17.8252Z" fill="#092245" />
      <path d="M7.03362 13.4805C6.00656 13.4805 5.17188 14.3152 5.17188 15.3422C5.17188 16.3693 6.00656 17.204 7.03362 17.204C8.06069 17.204 8.89537 16.3693 8.89537 15.3422C8.89537 14.3152 8.06069 13.4805 7.03362 13.4805ZM7.03362 16.5834C6.34788 16.5834 5.79246 16.028 5.79246 15.3422C5.79246 14.6565 6.34788 14.1011 7.03362 14.1011C7.71937 14.1011 8.27479 14.6565 8.27479 15.3422C8.27479 16.028 7.71937 16.5834 7.03362 16.5834Z" fill="#092245" />
      <path d="M4.55142 19.9972C4.55142 19.1408 5.24647 18.4458 6.10288 18.4458H7.65433C8.03599 18.4458 8.40524 18.5854 8.6876 18.8398L9.10029 18.3775C8.70312 18.0207 8.18803 17.8252 7.65123 17.8252H6.09977C4.90205 17.8252 3.92773 18.7995 3.92773 19.9972V21.2384H4.54832V19.9972H4.55142Z" fill="#092245" />
      <path d="M15.1016 15.3422C15.1016 16.3693 15.9362 17.204 16.9633 17.204C17.9904 17.204 18.8251 16.3693 18.8251 15.3422C18.8251 14.3152 17.9904 13.4805 16.9633 13.4805C15.9362 13.4805 15.1016 14.3152 15.1016 15.3422ZM18.2045 15.3422C18.2045 16.028 17.6491 16.5834 16.9633 16.5834C16.2776 16.5834 15.7221 16.028 15.7221 15.3422C15.7221 14.6565 16.2776 14.1011 16.9633 14.1011C17.6491 14.1011 18.2045 14.6565 18.2045 15.3422Z" fill="#092245" />
      <path d="M17.5835 18.4458C18.4399 18.4458 19.135 19.1408 19.135 19.9972V21.2384H19.7556V19.9972C19.7556 18.7995 18.7812 17.8252 17.5835 17.8252H16.0321C15.4953 17.8252 14.9833 18.0207 14.583 18.3775L14.9957 18.8398C15.2812 18.5854 15.6473 18.4458 16.029 18.4458H17.5804H17.5835Z" fill="#092245" />
      <path d="M12.9292 6.34375H14.1704V6.96433H12.9292V6.34375Z" fill="#092245" />
      <path d="M7.03369 6.34375H12.3086V6.96433H7.03369V6.34375Z" fill="#092245" />
      <path d="M7.03369 4.79297H11.3778V5.41355H7.03369V4.79297Z" fill="#092245" />
    </svg>
  );
}
