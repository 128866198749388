import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './theme.module.scss';
import { ColorPickerProps } from '../../utils/themeTypes';

function ColorPicker({
  colors, onColorChange, control, name, colorHeading, selectedColorAll, setColor,
}: ColorPickerProps) {
  const handleColorClick = (color: string) => {
    onColorChange(color);
  };

  return (
    <div className={styles.colorsDiv}>
      <div className={styles.update_logo}>
        {colorHeading}
      </div>
      <div className={styles.color_main_container}>
        {colors.map((value) => (
          <div
            key={value.id}
            className={selectedColorAll === value.color ? styles.color_picker_container : styles.color_picker_container_no_icon}
            onClick={() => handleColorClick(value.color)}
          >
            <div
              className={selectedColorAll === value.color ? styles.circular_div : styles.circular_input_no_icon}
              style={{ backgroundColor: value.color }}
            />
          </div>
        ))}
        <div className={styles.color_picker_container}>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
              <input
                type="color"
                onChange={(event) => {
                  onChange(event);
                  setColor(event.target.value);
                }}
                value={value}
                className={styles.circular_input}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default ColorPicker;
