/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ComponentTable from '../../../../../ComponentTable/ComponentTable';
import { TypesDialogBox } from '../../../../../../utils/interface';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function DialogBoxTubeChart({ show, setOpenFrom }: TypesDialogBox): JSX.Element {
  return (
    <div>
      <Dialog
        maxWidth="xl"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle style={{ borderBottom: '1px solid #E9EBEC' }}>
          Tube Chart Library
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <ComponentTable buttonText="" />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxTubeChart;
