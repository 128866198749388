// import { Dayjs } from 'dayjs';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Empty = any;
export interface InterfaceOilKitsMainFormValues {
  // Customer Information
  customerSearch: string,
  customer: string;
  contact: string;
  shipDate: Empty;
  shipVia: string | undefined;

  // Billing
  price: number;
  additionalAmount: string;
  billAmount: string;
  invoice: string;
  pd: string,
  kitsNo: string,
  kitsStart: string,
  kitsEnd: string
}

export enum EnumOilKitsCustomerInformationForm {
  customerSearch = 'customerSearch',
  customer = 'customer',
  contact = 'contact',
  shipDate = 'shipDate',
  shipVia = 'shipVia',
  price = 'price',
  additionalAmount = 'additionalAmount',
  billAmount = 'billAmount',
  invoice = 'invoice',
  pd = 'pd',
  kitsNo = 'kitsNo',
  kitsStart = 'kitsStart',
  kitsEnd = 'kitsEnd',
}

export interface TypeOilKitsICustomerInformation {
  control: Control<InterfaceOilKitsMainFormValues> | Empty;
  errors?: FieldErrors<InterfaceOilKitsMainFormValues> | Empty;
  watch?: UseFormWatch<InterfaceOilKitsMainFormValues> | Empty;
  setValue?: SetValue | Empty;
  setShowModal?: () => void;
}

export interface TypeOilKitsInformation {
  kitId: string | undefined;
}
export interface TypeOilKitsIBillingInformation {
  setValue: SetValue | Empty;
  control: Control<InterfaceOilKitsMainFormValues | Empty>
  errors?: FieldErrors<InterfaceOilKitsMainFormValues | Empty>;
}

export interface InterfaceViewOilKitsData {
  id: Empty;
  kitId: string;
  kitno: string;
  isChecked: boolean;
}

export interface InterfaceOilKitsSelectorData {
  id: string;
  sn: number;
  customer: string;
  shipDate: Empty;
}

export interface TypesViewOilKitsComponentModal {
  show: boolean;
  kitId?: string | undefined
  setOpenFrom: () => void;
  customerId?: string;
}

export interface OilKitsRangeResponseData {
  kitId: number,
  kitno: number
}

export interface OilKitsRangePayload {
  page: string,
  searchQuery: string,
  kitId: string,
  rowsPerPage: string,
}

export interface OilKitsDetails {
  kitsNo: string;
  type: number,
  CustId: string,
  PurchaseOrderNumber: string,
  ContactNumber: '',
  ShipDate: Empty,
  ShipVia: string,
  NumberOfKits: number,
  PerUnitPrice: number,
  AdditionalAmount: number,
  TotalAmount: number,
  InVoiceNumber: string,
  InvoiceDate: string,
  KitRanges: string[]
}

export interface KitInfo {
  kitsStart: string;
  kitsEnd: string;
}

export interface AddOilKitsResult {
  Data: OilKitsRangeResponseData[];
  TotalRecords: number;
}
export interface AddOilKitsInitialState {
  isLoading: boolean;
  openViewKitsModal: boolean;
  oilKitCreated: boolean;
  kitEndCount: string | null,
  kitStartCount: string | null,
  isKitAdded: boolean,
  kitDeleted: boolean,
  isKitRangeLoading: boolean,
  kitRangeListData: AddOilKitsResult,
  kitDataById: Empty,
  editOilKit: boolean,
  kitInputRange: string[],
  customerId: string,
  isFilled: boolean,
  oilKitPrice: number,
}

export interface OilKitsAddDetails {
  type: number,
  CustId: string,
  KitRanges: Empty
}

export interface OilKitsAddDetailsPayload {
  req: OilKitsAddDetails,
  createKit: boolean,
}
export interface GetAddOilKitResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface GetKitRangeResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: OilKitsRangeResponseData;
  }
}

export interface GetDetailsOilKitsResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: OilKitsDetails;
  }
}
