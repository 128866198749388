import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DefectTableColumns } from '../utils/helper';
import DataTable from '../../../Common/DataTable';
import StartTimeComponent from '../commonModal/StartTimeComponent';
import AddDefectComponent from '../commonModal/AddDefectComponent';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import InsertTimeComponent from '../commonModal/InsertTimeComponent';
import ReplaceComponent from '../commonModal/ReplaceComponent';
import { deleteVesselDefectStart, getVesselDefectStart } from '../redux/DefectRedux/sliceVesselDefect';
import FinishTimeComponent from '../commonModal/FinishTimeComponent';
import { RootState } from '../../../redux/rootState';
import { DeleteDefectPayload, TypeDefectList } from '../utils/defectType';
import { setLoader } from '../../../redux/loaderSlice';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../Routes/constant';

function DefectComponent() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showStartTimeModal, setShowStartTimeModal] = useState(false);
  const [showFinishTimeModal, setShowFinishTimeModal] = useState(false);
  const [showDefectModal, setShowDefectModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [selectedRowId, setSelectedRowId] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showInsertTimeModal, setShowInsertTimeModal] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);

  const { jobOrderId, vesselId } = useParams();

  const {
    vesselDefectList, isLoading, totalVesselDefect, startTime, endTime,
  } = useSelector((state: RootState) => state.VesselDefectReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedRowId(id);
  };

  const handleDeleteModal = () => {
    const payload: DeleteDefectPayload = {
      id: selectedRowId,
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      rowsPerPage,
      searchQuery,
      vesselDefectData: vesselDefectList,
    };
    dispatch(deleteVesselDefectStart(payload));
    setDeleteModal(false);
    setSelectedRowId('');
  };

  const handleEdit = (id: string) => {
    const disableInsert = vesselDefectList.filter((item) => String(item.Id) === id);
    if (disableInsert[0].List === 'Start') {
      setSelectedRowId(id);
      setShowStartTimeModal(true);
    } else if (disableInsert[0].List === 'Finish') {
      setSelectedRowId(id);
      setShowFinishTimeModal(true);
    } else {
      setSelectedRowId(id);
      setShowDefectModal(true);
    }
  };

  const handleButtonClick = () => {
    setSelectedRowId('');
    setShowDefectModal(true);
  };

  const tableData: TypeDefectList[] = useMemo(() => {
    if (vesselDefectList?.length > 0) {
      const transformedData = vesselDefectList?.map((ele, index) => ({
        sn: index,
        action: `${ele.DefectType === 'Calibration Time' || ele.DefectType === 'Time Entry' ? 'delete' : 'edit&delete'}`,
        row: ele?.Row,
        tube: ele?.Tube,
        code: ele?.Code,
        location: ele?.Location,
        date: ele?.Date,
        time: ele?.Time,
        defect: ele?.Description,
        section: ele?.Section,
        status: ele?.Status,
        id: String(ele?.Id),
      }));
      return transformedData;
    }
    return [];
  }, [vesselDefectList]);

  const handleData = () => {
    const payload = {
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getVesselDefectStart(payload));
    setSelectedId('');
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const disableInsert = vesselDefectList.filter((item) => String(item.Id) === selectedId);

  return (
    <>
      <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                {(roleId === ANALYST_ROLE_ID) ? (
                  <DataTable<TypeDefectList>
                    data={tableData?.length > 0 ? tableData : []}
                    columns={DefectTableColumns}
                    totalRecords={totalVesselDefect}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    handleData={handleData}
                  />
                ) : (
                  <DataTable<TypeDefectList>
                    data={tableData?.length > 0 ? tableData : []}
                    columns={DefectTableColumns}
                    totalRecords={totalVesselDefect}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    handleData={handleData}
                    buttonText="Add"
                    handleButtonClick={handleButtonClick}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    handleIssueButton={() => setShowFinishTimeModal(true)}
                    handleSearchButtonClick={() => setShowStartTimeModal(true)}
                    issueButton="Finish Time"
                    searchButton="Start Time"
                    issueButtonDisable={endTime !== null}
                    searchButtonDisable={startTime !== null}
                    radioButtonColumnsWithKey={['sn']}
                    selectedRadioWithKey={selectedId}
                    setSelectedRadioWithKey={setSelectedId}
                    generateInvoiceButton="Insert Time"
                    handleGenerateInvoiceButton={() => setShowInsertTimeModal(true)}
                    buttonNameFifth="Search & Replace"
                    generateInvoiceButtonDisabled={selectedId === ''
                      || disableInsert[0].DefectType === 'Time Entry' || disableInsert[0].DefectType === 'Calibration Time'}
                    handleButtonClickFifth={() => setShowReplaceModal(true)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {!(roleId === ANALYST_ROLE_ID) && (
        <>
          { showStartTimeModal && (
          <StartTimeComponent
            show={showStartTimeModal}
            setOpenFrom={() => setShowStartTimeModal(false)}
            rowId={selectedRowId}
            setRowId={setSelectedRowId}
            callback={handleData}
            label="Add Start Time"
          />
          )}
          { showFinishTimeModal && (
          <FinishTimeComponent
            show={showFinishTimeModal}
            setOpenFrom={() => setShowFinishTimeModal(false)}
            rowId={selectedRowId}
            setRowId={setSelectedRowId}
            callback={handleData}
            label="Add Finish Time"
            startTime={startTime}
          />
          )}
          {showDefectModal
        && (
          <AddDefectComponent
            show={showDefectModal}
            setOpenFrom={() => {
              setShowDefectModal(false);
            }}
            rowId={selectedRowId}
            setRowId={setSelectedRowId}
            callback={handleData}
          />
        )}
          {showDeleteModal
        && (
          <ComponentDeleteModal
            show={showDeleteModal}
            setOpenFrom={() => setDeleteModal(false)}
            handleDelete={handleDeleteModal}
          />
        )}
          {showInsertTimeModal
      && (
      <InsertTimeComponent
        show={showInsertTimeModal}
        setOpenFrom={() => setShowInsertTimeModal(false)}
        rowId={selectedId}
        callback={handleData}
        startTime={startTime}
        endTime={endTime}
      />
      )}
          {showReplaceModal
        && (
        <ReplaceComponent
          show={showReplaceModal}
          setOpenFrom={() => setShowReplaceModal(false)}
          rowId={selectedRowId}
          callback={handleData}
        />
        )}
        </>
      )}
    </>
  );
}

export default DefectComponent;
