/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  addEquipmentStart,
  addEquipmentStartSuccess,
  getInventoryFailure, getInventoryMasterFailure, getInventoryMasterStart, getInventoryMasterSuccess, getInventoryStart, getInventorySuccess,
  getIssueHistoryFailure,
  getIssueHistoryStart,
  getIssueHistorySuccess,
  getUpcomingJobsFailure,
  getUpcomingJobsStart,
  getUpcomingJobsSuccess,
  InventoryActions, onInventoryDeleteFailure, onInventoryDeleteStart,
  onInventoryDeleteSuccess,
  onIssueEquipment,
  onReturnEquipment,
} from './inventorySlice';
import Config from '../../../../Common/Config';
import {
  DeleteApiResponse, GetInventoryPayload, InventoryAddEquipmentInterface, InventoryAddEquipmentPayload,
  InventoryAddEquipmentResponse, InventoryDeletePayload,
  InventoryIssueHistoryPayload,
  InventoryIssueHistoryResponse,
  InventoryIssueInterface, InventoryIssueInterfaceResponse, InventoryMasterResponse, InventoryResponse,
  InventoryReturnInterface, InventoryReturnInterfaceResponse, InventoryReturnPayloadInterface, UpcomingJobsDataResponse, UpcomingJobsPayload,
} from './type';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import { AddAnalystLeaveResponseFormat } from '../../../AnalystScreen/AnalystAddScreen/utils/types';

async function getInventoryMaster(): Promise<InventoryMasterResponse> {
  const url = `${Config.inventory.getInventoryMaster}`;
  const result = await makeGetRequest<InventoryMasterResponse>(url);
  return result.data;
}

// GET INVENTORY MASTER DATA
export const getInventoryMasterDataEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(getInventoryMasterStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(() => from(getInventoryMaster()).pipe(
    map((res: InventoryMasterResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getInventoryMasterSuccess(res.BMT.Result);
      }
      return getInventoryMasterFailure();
    }),
    takeUntil(action$.pipe(filter(getInventoryMasterStart.match))),
    catchError((error) => of(getInventoryMasterFailure(error))),
  )),
);

async function getInventory(data: GetInventoryPayload): Promise<InventoryResponse> {
  const url = `${Config.inventory.GetInventoryList}?EquipmentTypeId=${data?.equipmentType}&StatusId=${data?.inventoryType}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<InventoryResponse>(url);
  return result?.data;
}

// GET INVENTORY DATA
export const getInventoryDataEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(getInventoryStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetInventoryPayload) => from(getInventory(data)).pipe(
    map((res: InventoryResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getInventorySuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getInventoryFailure();
    }),
    takeUntil(action$.pipe(filter(getInventoryStart.match))),
    catchError((error) => of(getInventoryFailure(error))),
  )),
);

async function deleteInventory(data: string): Promise<DeleteApiResponse> {
  const url = `${Config.inventory.deleteInventory}?ProductNumber=${data}`;
  const result = await makeDeleteRequest<DeleteApiResponse>(url);
  return result.data;
}

// DELETE INVENTORY
export const deleteInventoryEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(onInventoryDeleteStart.type),
  map((x) => x.payload),
  mergeMap(({ productId, callback }: InventoryDeletePayload) => from(deleteInventory(productId)).pipe(
    map((res: DeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return onInventoryDeleteSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onInventoryDeleteFailure();
    }),
    takeUntil(action$.pipe(filter(onInventoryDeleteStart.match))),
    catchError((error) => of(onInventoryDeleteFailure(error))),
  )),
);

async function getIssueHistory(data: InventoryIssueHistoryPayload): Promise<InventoryIssueHistoryResponse> {
  const url = `${Config.inventory.getIssueHistory}?ProductNumber=${data?.productId}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<InventoryIssueHistoryResponse>(url);
  return result.data;
}

// ISSUE HISTORY
export const getInventoryIssueHistoryEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(getIssueHistoryStart.type),
  map((x) => x.payload),
  mergeMap((data: InventoryIssueHistoryPayload) => from(getIssueHistory(data)).pipe(
    map((res: InventoryIssueHistoryResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getIssueHistorySuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getIssueHistoryFailure();
    }),
    takeUntil(action$.pipe(filter(getIssueHistoryStart.match))),
    catchError((error) => of(getIssueHistoryFailure(error))),
  )),
);

async function onReturnProduct(productId: string, issueId: string): Promise<InventoryReturnInterfaceResponse> {
  const url = `${Config.inventory.returnInventory}?ProductNumber=${productId}&IssueId=${issueId}`;
  const result = await makePostRequest<InventoryReturnInterfaceResponse>(url);
  return result.data;
}

// RETURN EQUIPMENT
export const returnProductEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(onReturnEquipment.type),
  map((x) => x.payload),
  mergeMap(({ productId, issueId, callback }: InventoryReturnInterface) => from(onReturnProduct(productId, issueId)).pipe(
    map((res: InventoryReturnInterfaceResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return addEquipmentStartSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return addEquipmentStartSuccess();
    }),
    takeUntil(action$.pipe(filter(onReturnEquipment.match))),
    catchError((error) => of(getIssueHistoryFailure(error))),
  )),
);

async function onIssueProduct(data: InventoryReturnPayloadInterface): Promise<InventoryIssueInterfaceResponse> {
  const url = Config.inventory.issueInventory;
  const result = await makePostRequest<InventoryIssueInterfaceResponse>(url, data);
  return result.data;
}

// ISSUE EQUIPMENT
export const issueProductEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(onIssueEquipment.type),
  map((x) => x.payload),
  mergeMap(({ payload, callback }: InventoryIssueInterface) => from(onIssueProduct(payload)).pipe(
    map((res: InventoryIssueInterfaceResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return addEquipmentStartSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return addEquipmentStartSuccess();
    }),
    takeUntil(action$.pipe(filter(onIssueEquipment.match))),
    catchError((error) => of(callback(), addEquipmentStartSuccess(), showErrorToaster(error?.res.BMT.ResponseMessage))),
  )),
);

async function onAddEquipment(data: InventoryAddEquipmentPayload): Promise<InventoryAddEquipmentResponse> {
  const url = Config.inventory.createInventory;
  const result = await makePostRequest<InventoryAddEquipmentResponse>(url, data);
  return result.data;
}

// ADD EQUIPMENT
export const addEquipmentEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(addEquipmentStart.type),
  map((x) => x.payload),
  mergeMap(({ payload, callback }: InventoryAddEquipmentInterface) => from(onAddEquipment(payload)).pipe(
    map((res: InventoryAddEquipmentResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return addEquipmentStartSuccess();
      }
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        const displayToastMessage = `${res.BMT.Result} Product Created`;
        showSuccessToaster(displayToastMessage);
        callback();
        return addEquipmentStartSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      callback();
      return addEquipmentStartSuccess();
    }),
    takeUntil(action$.pipe(filter(addEquipmentStart.match))),
    catchError((error) => of(callback(), addEquipmentStartSuccess(), showErrorToaster(error?.res.BMT.ResponseMessage))),
  )),
);

async function getUpcomingJobsApiCall(data: UpcomingJobsPayload): Promise<UpcomingJobsDataResponse> {
  const url = `${Config.inventory.upcomingEquipmentNeededList}?pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<UpcomingJobsDataResponse>(url);
  return result?.data;
}

// GET UPCOMING JOBS EQUIPMENT
export const getUpcomingJobsEpic = (action$: Observable<InventoryActions>) => action$.pipe(
  ofType(getUpcomingJobsStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: UpcomingJobsPayload) => from(getUpcomingJobsApiCall(data)).pipe(
    map((res: UpcomingJobsDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getUpcomingJobsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getUpcomingJobsFailure(false);
    }),
    takeUntil(action$.pipe(filter(getUpcomingJobsStart.match))),
    catchError((error: AxiosError<AddAnalystLeaveResponseFormat>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(getUpcomingJobsFailure(false));
    }),
  )),
);
