import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddMarkupsData, MarkupPayloadListing, UpdateMarkupsData } from '../utils/TypesMarkupsList';

interface PayloadTypeGetSysMarkups {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

interface PayloadTypeDeleteMarkup {
  Id: string;
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

interface SystemMarkupState {
  SystemMarkup: MarkupPayloadListing;
  isLoading: boolean;
  error: string;
  isSuccess: boolean;
  tableStaticData: PayloadTypeGetSysMarkups;
}

const initialState: SystemMarkupState = {
  SystemMarkup: {} as MarkupPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
  tableStaticData: {} as PayloadTypeGetSysMarkups,
};

const sysMarkupSlice = createSlice({
  name: 'systemMarkups',
  initialState,
  reducers: {
    getSysMarkupStart(state, action: PayloadAction<PayloadTypeGetSysMarkups>) {
      return {
        ...state,
        isLoading: true,
        tableStaticData: action.payload,
      };
    },
    successGetSysMarkup(state, action: PayloadAction<MarkupPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        SystemMarkup: action?.payload as MarkupPayloadListing,
      };
    },
    failureGetSysMarkup(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateMarkups(state, action: PayloadAction<UpdateMarkupsData>) {
      return {
        ...state,
        isLoading: true,
        MarkupsUpdateData: action.payload,
      };
    },
    successUpdateMarkups(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateMarkups(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addMarkupsStart(state, action: PayloadAction<AddMarkupsData>) {
      return {
        ...state,
        isLoading: true,
        MarkupsAddData: action.payload,
      };
    },
    successAddMarkups(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAdMarkups(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteMarkupsStart(state, _action: PayloadAction< PayloadTypeDeleteMarkup >) {
      return {
        ...state,
        isLoading: true,
        deletingRow: _action.payload,
      };
    },
    successDeleteMarkup(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteMarkup(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getSysMarkupStart, successGetSysMarkup, failureGetSysMarkup, updateMarkups,
  successUpdateMarkups, failureUpdateMarkups, resetState, deleteMarkupsStart,
  successDeleteMarkup, failureDeleteMarkup,
  addMarkupsStart, successAddMarkups, failureAdMarkups,
} = sysMarkupSlice.actions;
export const SystemMarkupReducer = sysMarkupSlice.reducer;

export type SytemMarkupActions =
  | ReturnType<typeof getSysMarkupStart>
  | ReturnType<typeof successGetSysMarkup>
  | ReturnType<typeof failureGetSysMarkup>

  | ReturnType<typeof updateMarkups>
  | ReturnType<typeof successUpdateMarkups>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateMarkups>

  | ReturnType<typeof deleteMarkupsStart>
  | ReturnType<typeof successDeleteMarkup>
  | ReturnType<typeof failureDeleteMarkup>

  | ReturnType<typeof addMarkupsStart>
  | ReturnType<typeof successAddMarkups>
  | ReturnType<typeof failureAdMarkups>;
