/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextFieldProps } from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { CommonTimeInputProps } from './types';
import NewTimeIcon from './NewTimeIcon';

function Common24HTimeInput({
  control, name, errors, handleTimeChange, className, minTime, maxTime,
}: CommonTimeInputProps) {
  const onHandleChange = (newValue: Date | null, onChange: (value: Date | null) => void) => {
    onChange(newValue);
    if (handleTimeChange) {
      handleTimeChange(newValue);
    }
  };

  const errorMessage = useMemo(() => {
    switch (errors[name]?.type) {
      case 'typeError': {
        return 'Your time is not valid';
      }
      default: {
        return errors[name]?.message;
      }
    }
  }, [errors[name]?.type]);

  const OpenPickerIcon = useCallback(() => <NewTimeIcon errors={errors} name={name} />, [errors]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MobileTimePicker
          {...field}
          value={field.value}
          ampm={false}
          openTo="hours"
          minTime={minTime}
          maxTime={maxTime}
          // thresholdToRenderTimeInASingleColumn={24}
          onChange={(newValue: Date | null) => onHandleChange(newValue, field.onChange)}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          views={['hours', 'minutes', 'seconds']}
          // slots={{
          //   openPickerIcon: OpenPickerIcon,
          // }}
          slotProps={{
            textField: {
              InputProps: { // Remove InputProps when use @mui/x-date-pickers/TimePicker
                endAdornment: (
                  <InputAdornment position="end">
                    <OpenPickerIcon />
                  </InputAdornment>
                ),
              },
              size: 'small',
              fullWidth: true,
              error: Boolean(errors[name]),
              helperText: errorMessage ? String(errorMessage) : '',
            } as TextFieldProps,
          }}
          format="HH:mm:ss"
          className={`timerInput nonCapitalizeField ${className || ''}`}
        />
      )}
    />
  );
}

export default Common24HTimeInput;
