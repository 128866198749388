import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  FreonListInitialState, GetFreonByIdPayload, GetFreonCylinderQuery, GetFreonDetailsById, GetFreonListApiData,
} from '../utils/TypesFreonCylinderList';

const initialState: FreonListInitialState = {
  isLoading: false,
  errorMessage: '',
  freonList: [],
  getFreonListQuery: {} as GetFreonCylinderQuery,
  freonTotalEntries: 0,
  totalNumberOfKit: 0,
  totalBillAmount: 0,
  kitId: '',
  getKitDetailsById: {} as GetFreonDetailsById,
};

export const listFreonSlice = createSlice({
  name: 'getFreonList',
  initialState,
  reducers: {
    // GetFreon
    getFreonList(state, action: PayloadAction<GetFreonCylinderQuery>) {
      return {
        ...state,
        isLoading: true,
        getFreonListQuery: action.payload,
      };
    },
    getFreonListSuccess(state, action: PayloadAction<GetFreonListApiData>) {
      return {
        ...state,
        isLoading: false,
        freonList: action.payload.Data,
        freonTotalEntries: action.payload.TotalRecords,
        totalNumberOfKit: action.payload.TotalNumberOfKit,
        totalBillAmount: action.payload.TotalBillAmount,
      };
    },
    getFreonListFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    deleteFreonStart(state, _action: PayloadAction<GetFreonCylinderQuery>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteFreonSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteFreonFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Get Freon by ID
    getFreonByIdStart(state, action: PayloadAction<GetFreonByIdPayload>) {
      return {
        ...state,
        isLoading: true,
        kitId: action.payload.KitId,
      };
    },
    getFreonByIdSuccess(state, action: PayloadAction<GetFreonDetailsById>) {
      return {
        ...state,
        isLoading: false,
        getKitDetailsById: action.payload,
      };
    },
    getFreonByIdFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    resetData(state) {
      return {
        ...state,
        getKitDetailsById: {} as GetFreonDetailsById,
      };
    },
  },
});
export const {
  getFreonList, getFreonListSuccess, getFreonListFailure, deleteFreonStart, deleteFreonSuccess, deleteFreonFailure,
  getFreonByIdStart, getFreonByIdSuccess, getFreonByIdFailure, resetData,
} = listFreonSlice.actions;

export const FreonListReducer = listFreonSlice.reducer;

export type FreonActions =
  | ReturnType<typeof getFreonList>
  | ReturnType<typeof getFreonListSuccess>
  | ReturnType<typeof getFreonListFailure>
  | ReturnType<typeof deleteFreonStart>
  | ReturnType<typeof deleteFreonSuccess>
  | ReturnType<typeof deleteFreonFailure>
  | ReturnType<typeof getFreonByIdStart>
  | ReturnType<typeof getFreonByIdSuccess>
  | ReturnType<typeof resetData>
  | ReturnType<typeof getFreonByIdFailure>;
