import {
  Grid, Button,
} from '@mui/material';
// import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../../../redux/rootState';
import '../../index.module.scss';
import {
  changeMachineTyoe,
  getUnitMasterStart, getVesselMasterStart, setNumberOfTubes, setSelectedTab, setTubeLength, setUnitInfo,
} from '../../redux/unitInfoSlice';
import { UnitInfoFormNames } from '../../utils/constants';
import { assignUnitInfo, assignUnitValues } from '../../utils/helper';
import { UnitInfoFormValues, ScreenGoToProps } from '../../utils/type';
import { MasterValue } from '../../../ScreenBidWorkSheetJob/utils/types';
// import { unitInfoValidationSchema } from '../../utils/validation';
import UnitFormComponent from './formComponent';

function ComponentUnitTest({ scrollTop }: ScreenGoToProps) {
  const {
    control, watch, setValue, getValues,
  } = useForm<UnitInfoFormValues>({
    // resolver: yupResolver(unitInfoValidationSchema),
  });
  const {
    unitMaster, unitInfo, selectedUnit, machineType, unitId,
  } = useSelector((state: RootState) => state.unitInfoReducer);
  const { historyStack } = useSelector((state: RootState) => state.masterDataReducer);

  const testType = watch(UnitInfoFormNames.testType);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!testType && !unitId) {
      setValue(UnitInfoFormNames.testType, UnitInfoFormNames.eddyCurrentTest);
    }
  }, []);

  useEffect(() => {
    if ((testType === 'ECT' || testType === 'ADT') && unitMaster.Machine?.length > 0) {
      if (getValues(UnitInfoFormNames.machineType) === 7) {
        setValue(UnitInfoFormNames.machineType, 0);
      }
      let data = JSON.parse(JSON.stringify(machineType));
      data = data.filter((ele: MasterValue) => ele.Value !== '7');
      dispatch(changeMachineTyoe(data));
    } else if (testType === 'FLT' && unitMaster.Machine?.length > 0) {
      dispatch(changeMachineTyoe(unitMaster?.Machine));
    }
  }, [testType, unitMaster]);

  const handleChange = () => {
    const values = getValues();
    dispatch(setUnitInfo(values));
  };

  useEffect(() => {
    dispatch(getUnitMasterStart());
    dispatch(getVesselMasterStart());
  }, []);

  useEffect(() => {
    const prevUrl = historyStack[historyStack.length - 1] || '';

    if (prevUrl.includes('test-request')) {
      dispatch(setSelectedTab(3));
      return;
    }
    dispatch(setSelectedTab(1));
  }, []);

  const handleNext = () => {
    dispatch(setSelectedTab(2));
    scrollTop();
  };

  useEffect(() => {
    if (Object.keys(unitInfo).length > 0) {
      assignUnitInfo(setValue, unitInfo);
    }
  }, [unitInfo]);

  useEffect(() => {
    if (selectedUnit?.UnitId && !unitInfo?.machineType) {
      assignUnitValues(setValue, selectedUnit);
      dispatch(setUnitInfo(watch()));
      dispatch(setNumberOfTubes(selectedUnit.UnitPricingInfo.TotalTubeQty));
      dispatch(setTubeLength(selectedUnit.UnitPricingInfo.TotalTubeLength));
    }
  }, [selectedUnit]);

  return (
    <Grid container spacing={2}>
      <UnitFormComponent control={control} watch={watch} handleChange={handleChange} setValue={setValue} />
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div className="button_margin_left">
            <Button onClick={handleNext} className="button_save_and_next">Next</Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
export default ComponentUnitTest;
