import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnalystInitialState, AnalystResult, CarrierResultData, TeritoryResultData,
} from '../utils/TypeAnalyst';

const initialState: AnalystInitialState = {
  isLoading: true,
  analystData: {} as AnalystResult,
  carriersData: [],
  teritoryData: [],
};

export const analystListSlice = createSlice({
  name: 'analystList',
  initialState,
  reducers: {
    resetAnalystListState() {
      return initialState;
    },
    getAnalystData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAnalystDataSuccess(state, action: PayloadAction<AnalystResult>) {
      return {
        ...state,
        isLoading: false,
        analystData: action.payload,
      };
    },
    getAnalystDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteAnalyst(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteAnalystSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getCarrierData(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    gotCarrierData(state, action: PayloadAction<CarrierResultData[]>) {
      return {
        ...state,
        carriersData: action.payload,
      };
    },
    getTeritoryData(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    gotTeritoryData(state, action: PayloadAction<TeritoryResultData[]>) {
      return {
        ...state,
        teritoryData: action.payload,
      };
    },
  },
});

export const {
  getAnalystData, getAnalystDataSuccess, getAnalystDataFailure, deleteAnalyst, deleteAnalystSuccess,
  deleteAnalystFailure, gotCarrierData, getCarrierData, gotTeritoryData, getTeritoryData,
  resetAnalystListState,
} = analystListSlice.actions;
export const AnalystListSliceReducer = analystListSlice.reducer;

export type AnalystListSliceActions =
  | ReturnType<typeof getAnalystData>
  | ReturnType<typeof getAnalystDataSuccess>
  | ReturnType<typeof getAnalystDataFailure>
  | ReturnType<typeof deleteAnalyst>
  | ReturnType<typeof deleteAnalystSuccess>
  | ReturnType<typeof deleteAnalystFailure>
  | ReturnType<typeof gotCarrierData>
  | ReturnType<typeof getCarrierData>
  | ReturnType<typeof gotTeritoryData>
  | ReturnType<typeof resetAnalystListState>
  | ReturnType<typeof getTeritoryData>;
