import { ofType } from 'redux-observable';
import {
  catchError, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  AnalystListSliceActions, deleteAnalyst,
  deleteAnalystFailure, deleteAnalystSuccess,
  getAnalystData, getAnalystDataFailure, getAnalystDataSuccess,
  getCarrierData, getTeritoryData, gotCarrierData, gotTeritoryData,
} from './AnalystListSlice';
import {
  AnalystQueryPayload, AnalystResponseFormat, CarrierResponseFormat, DeleteAnalyticPayload, DeleteResponseFormat, TeritoryResponseFormat,
} from '../utils/TypeAnalyst';
import Config from '../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest } from '../../../../Common/NetworkOps';

async function getAnalystListData(data: AnalystQueryPayload): Promise<AnalystResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.analyst.analystlist}?PageNumber=${page}&PageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<AnalystResponseFormat>(url);
  return result.data;
}

export const getAnalystDataEpic = (action$: Observable<AnalystListSliceActions>) => action$.pipe(
  ofType(getAnalystData.type),
  map((x) => x.payload),
  mergeMap((data: AnalystQueryPayload) => from(getAnalystListData(data)).pipe(
    map((res: AnalystResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAnalystDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystDataFailure();
    }),
    takeUntil(action$.pipe(filter(getAnalystData.match))),
    catchError((error) => of(getAnalystDataFailure(error))),
  )),
);

async function deleteAnalystFromList(data: DeleteAnalyticPayload): Promise<DeleteResponseFormat> {
  const url = `${Config.analyst.deleteAnalyst}?AnalystId=${data.analyticId}`;
  const result = await makeDeleteRequest<DeleteResponseFormat>(url);
  return result.data;
}

export const deleteAnalystEpic = (action$: Observable<AnalystListSliceActions>) => action$.pipe(
  ofType(deleteAnalyst.type),
  map((x) => x.payload),
  mergeMap((data: DeleteAnalyticPayload) => from(deleteAnalystFromList(data)).pipe(
    mergeMap((res: DeleteResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data?.page,
          rowsPerPage: data?.rowsPerPage,
          searchQuery: data?.searchQuery,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteAnalystSuccess(), getAnalystData(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteAnalystFailure());
    }),
    takeUntil(action$.pipe(filter(deleteAnalyst.match))),
    catchError((error) => of(deleteAnalystFailure(error))),
  )),
);

async function getCarrierListData(): Promise<CarrierResponseFormat> {
  const url = `${Config.analyst.getCarrier}`;
  const result = await makeGetRequest<CarrierResponseFormat>(url);
  return result.data;
}

export const getCarrierDataEpic = (action$: Observable<AnalystListSliceActions>) => action$.pipe(
  ofType(getCarrierData.type),
  map((x) => x.payload),
  mergeMap(() => from(getCarrierListData()).pipe(
    map((res: CarrierResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotCarrierData(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystDataFailure();
    }),
    takeUntil(action$.pipe(filter(getCarrierData.match))),
    catchError((error) => of(getAnalystDataFailure(error))),
  )),
);

async function getTeritoryListData(): Promise<TeritoryResponseFormat> {
  const url = `${Config.analyst.getTeritory}`;
  const result = await makeGetRequest<TeritoryResponseFormat>(url);
  return result.data;
}

export const getTeritoryDataEpic = (action$: Observable<AnalystListSliceActions>) => action$.pipe(
  ofType(getTeritoryData.type),
  map((x) => x.payload),
  mergeMap(() => from(getTeritoryListData()).pipe(
    map((res: TeritoryResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return gotTeritoryData(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getAnalystDataFailure());
    }),
    takeUntil(action$.pipe(filter(getTeritoryData.match))),
    catchError((error) => of(getAnalystDataFailure(error))),
  )),
);
