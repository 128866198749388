import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResultPriceListApiRes, UpdatePriceListFormData } from '../utils/TypesPriceList';

export interface PayloadTypeGetSystemStart {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}
interface SystemListState {
  SystemPriceList: ResultPriceListApiRes;
  isLoading: boolean;
  error: string;
  isSuccess:boolean;
  tableStaticData: PayloadTypeGetSystemStart;
}

const initialState: SystemListState = {
  SystemPriceList: {} as ResultPriceListApiRes,
  isLoading: false,
  error: '',
  isSuccess: false,
  tableStaticData: {} as PayloadTypeGetSystemStart,
};

const SystemListSlice = createSlice({
  name: 'systemPriceList',
  initialState,
  reducers: {
    getSystemPriceStart(state, action: PayloadAction<PayloadTypeGetSystemStart>) {
      return {
        ...state,
        isLoading: true,
        tableStaticData: action.payload,
      };
    },
    successGetSystemPrice(state, action: PayloadAction<ResultPriceListApiRes>) {
      return {
        ...state,
        isLoading: false,
        SystemPriceList: action?.payload as ResultPriceListApiRes,
      };
    },
    failureGetSystemPriceList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateSystemPriceList(state, action: PayloadAction<UpdatePriceListFormData>) {
      return {
        ...state,
        loading: true,
        SystemPricesUpdateData: action.payload,
      };
    },
    successUpdateSystemPriceList(state) {
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    },
    failureUpdateSystemPriceList(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    resetStateData(state) {
      return {
        ...state,
        isSuccess: false,
        responseMessage: '',
      };
    },
  },
});

export const {
  getSystemPriceStart, successGetSystemPrice, failureGetSystemPriceList,
  updateSystemPriceList, successUpdateSystemPriceList, failureUpdateSystemPriceList,
  resetStateData,
} = SystemListSlice.actions;
export const SystemPriceListReducer = SystemListSlice.reducer;

export type SystemPriceListActions =
  | ReturnType<typeof getSystemPriceStart>
  | ReturnType<typeof successGetSystemPrice>
  | ReturnType<typeof failureGetSystemPriceList>
  | ReturnType<typeof updateSystemPriceList>
  | ReturnType<typeof successUpdateSystemPriceList>
  | ReturnType<typeof failureUpdateSystemPriceList>;
