import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ReportWorkspaceListResult, PaginationReportResponse, PayloadReportWorkspaceStart,
  PayloadWorkspaceDetailsById, WorkspaceDetailsByIdResult,
  ContentType,
  GetUserDetailsPayload,
  UserDetailsData,
} from '../utils/types';
import { MasterData } from '../../../utils/type';

const initialState = {
  reportWorkspaceList: [] as ReportWorkspaceListResult[],
  totalEntries: 0,
  isLoading: false,
  reportWorkspaceDataById: {} as WorkspaceDetailsByIdResult,
  allListContents: [] as ContentType[],
  signatureData: [] as MasterData[],
  UserDetailsData: {} as UserDetailsData,
};

const reportWorkspaceSlice = createSlice({
  name: 'reportWorkspaceModule',
  initialState,
  reducers: {
    // Get All WorkSpace Data List
    getReportWorkspaceListStart(state, _action: PayloadAction<PayloadReportWorkspaceStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetReportWorkspaceList(state, action: PayloadAction<PaginationReportResponse<ReportWorkspaceListResult>>) {
      return {
        ...state,
        isLoading: false,
        reportWorkspaceList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureGetReportWorkspaceList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get By Id Workspace
    getWorkSpaceByIdStart(state, _action: PayloadAction<PayloadWorkspaceDetailsById>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetWorkspaceById(state, action: PayloadAction<WorkspaceDetailsByIdResult>) {
      return {
        ...state,
        isLoading: false,
        reportWorkspaceDataById: action?.payload as WorkspaceDetailsByIdResult,
      };
    },
    failureGetWorkspaceById(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    resetWorkspaceState(state) {
      return {
        ...state,
        reportWorkspaceList: [] as ReportWorkspaceListResult[],
        reportWorkspaceDataById: {} as WorkspaceDetailsByIdResult,
      };
    },
    resetWorkspaceByIdState(state) {
      return {
        ...state,
        reportWorkspaceDataById: {} as WorkspaceDetailsByIdResult,
      };
    },
    sendEmailStart(state, _action: PayloadAction<FormData>) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get All List Contents Data List
    getListContentsListStart(state, _action:PayloadAction<boolean>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    sendEmailSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    sendEmailFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    successGetListContentsList(state, action: PayloadAction<ContentType[]>) {
      return {
        ...state,
        isLoading: false,
        allListContents: action?.payload,
      };
    },
    failureGetListContentsList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getSignatureStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSignatureSuccess(state, action:PayloadAction<MasterData[]>) {
      return {
        ...state,
        isLoading: false,
        signatureData: action.payload,
      };
    },
    getSignatureFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Use Details Data List
    getUserDetailsListStart(state, _action: PayloadAction<GetUserDetailsPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUserDetailsList(state, action: PayloadAction<UserDetailsData>) {
      return {
        ...state,
        isLoading: false,
        UserDetailsData: action?.payload,
      };
    },
    failureGetUserDetailsList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getReportWorkspaceListStart, successGetReportWorkspaceList, failureGetReportWorkspaceList,
  getWorkSpaceByIdStart, successGetWorkspaceById, failureGetWorkspaceById, resetWorkspaceState,
  sendEmailStart, sendEmailSuccess, sendEmailFailure,
  resetWorkspaceByIdState, getListContentsListStart, successGetListContentsList, failureGetListContentsList,
  getSignatureStart, getSignatureSuccess, getSignatureFailure,
  getUserDetailsListStart, successUserDetailsList, failureGetUserDetailsList,
} = reportWorkspaceSlice.actions;
export const reportWorkspaceReducer = reportWorkspaceSlice.reducer;

export type ReportWorkspaceActions =
  | ReturnType<typeof getReportWorkspaceListStart>
  | ReturnType<typeof successGetReportWorkspaceList>
  | ReturnType<typeof failureGetReportWorkspaceList>
  | ReturnType<typeof getWorkSpaceByIdStart>
  | ReturnType<typeof successGetWorkspaceById>
  | ReturnType<typeof failureGetWorkspaceById>
  | ReturnType<typeof resetWorkspaceState>
  | ReturnType<typeof resetWorkspaceByIdState>
  | ReturnType<typeof sendEmailStart>
  | ReturnType<typeof sendEmailSuccess>
  | ReturnType<typeof sendEmailFailure>
  | ReturnType<typeof getListContentsListStart>
  | ReturnType<typeof successGetListContentsList>
  | ReturnType<typeof failureGetListContentsList>
  | ReturnType<typeof getSignatureStart>
  | ReturnType<typeof getSignatureSuccess>
  | ReturnType<typeof getSignatureFailure>
  | ReturnType<typeof getUserDetailsListStart>
  | ReturnType<typeof successUserDetailsList>
  | ReturnType<typeof failureGetUserDetailsList>;
