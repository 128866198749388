import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Legend,
} from 'recharts';

interface ChartComponentProps<T> {
  data: T[] | undefined;
  xKey: string;
  bar1Key?: string;
  bar2Key?: string;
  bar3Key?: string;
  bar1Color?: string;
  bar2Color?: string;
  bar3Color?: string;
}

function ChartComponent<T>({
  data, xKey, bar1Key, bar2Key, bar3Key, bar1Color, bar2Color, bar3Color,
}: ChartComponentProps<T>) {
  if (data && !(data?.length > 0)) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <ResponsiveContainer width="75%" height={300}>
        <BarChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xKey} />
          <YAxis />
          <Legend />
          {bar1Key && <Bar dataKey={bar1Key} fill={bar1Color} barSize={20} />}
          {bar2Key && <Bar dataKey={bar2Key} fill={bar2Color} barSize={20} />}
          {bar3Key && <Bar dataKey={bar3Key} fill={bar3Color} barSize={20} />}
        </BarChart>
      </ResponsiveContainer>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '2px 2px',
        border: '1px solid #000000',
        borderRadius: '.5px',
        position: 'absolute',
        padding: '5px 5px',
        top: '0px',
        right: '10px',
        gap: '8px',
      }}
      >
        <div style={{ fontSize: '15px', color: '#000000', fontWeight: '600' }}>Legend Title</div>
        {bar1Key && (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div style={{ width: '12px', height: '12px', background: bar1Color }} />
          <div style={{ fontSize: '13px', color: '#000000', fontWeight: '500' }}>{bar1Key}</div>
        </div>
        )}
        {bar2Key && (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div style={{ width: '12px', height: '12px', background: bar2Color }} />
          <div style={{ fontSize: '13px', color: '#000000', fontWeight: '500' }}>{bar2Key}</div>
        </div>
        )}
        {bar3Key && (
        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          <div style={{ width: '12px', height: '12px', background: bar3Color }} />
          <div style={{ fontSize: '13px', color: '#000000', fontWeight: '500' }}>{bar3Key}</div>
        </div>
        )}
      </div>
    </div>
  );
}

export default ChartComponent;
