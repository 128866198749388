import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

interface Crumb {
  label: string;
  url: string;
  icon: JSX.Element;
}

interface BreadcrumbProps {
  crumbs: Crumb[];
}

function ComponentBreadcrumb({ crumbs }: BreadcrumbProps) {
  return (
    <div className="breadcrumb_container">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {crumbs.map((crumb, index) => (
            <li
              key={crumb.label}
              className={`breadcrumb-item ${index === crumbs.length - 1 ? 'active' : ''}`}
            >
              {index === crumbs.length - 1 ? (
                <>
                  {crumb.icon}
                  {' '}
                  {crumb.label}
                </>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {crumb.icon}
                  <Link to={crumb.url}>{crumb.label}</Link>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

export default ComponentBreadcrumb;
