import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import Config from '../../../Common/Config';
import { makeGetRequest, makePutRequest } from '../../../Common/NetworkOps';
import {
  getHazardAnalysisById, getHazardAnalysisData, getHazardAnalysisFailure, getHazardAnalysisFailureById,
  getHazardAnalysisSuccess, getHazardAnalysisSuccessById, HazardAnalysisActions, updateHazardAnalysisFailure,
  updateHazardAnalysisStart, updateHazardAnalysisSuccess,
} from './hazardAnalysisSlice';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  UpdateHazardAnalysisDataById, HazardAnalysisResponseData, HazardAnalysisQueryPayload, HazardAnalysisResponseFormat,
  UpdateHazardAnalystPayload,
} from '../utils/types';

// Get Hazard Analysis Data
async function getHazardAnalysisListData(data: HazardAnalysisQueryPayload): Promise<HazardAnalysisResponseFormat> {
  const { page, rowsPerPage, searchQuery } = data;
  const url = `${Config.hazardAnalysisUrl.getHazardAnalysisListUrl}?pageNumber=${page}&pageSize=${rowsPerPage}&searchItem=${searchQuery}`;
  const result = await makeGetRequest<HazardAnalysisResponseFormat>(url);
  return result.data;
}
export const getHazardAnalysisDataEpic = (action$: Observable<HazardAnalysisActions>) => action$.pipe(
  ofType(getHazardAnalysisData.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: HazardAnalysisQueryPayload) => from(getHazardAnalysisListData(data)).pipe(
    map((res: HazardAnalysisResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getHazardAnalysisSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getHazardAnalysisFailure();
    }),
    takeUntil(action$.pipe(filter(getHazardAnalysisData.match))),
    catchError((error) => of(getHazardAnalysisFailure(error))),
  )),
);

// Get hazard Analysis by Id
async function getHazardAnalysisDataById(data: { id: string }): Promise<HazardAnalysisResponseData> {
  const url = `${Config.hazardAnalysisUrl.getHazardAnalysisByIdUrl}?JobOrder=${data.id}`;
  const result = await makeGetRequest<HazardAnalysisResponseData>(url);
  return result.data;
}
export const getHazardAnalysisByIdEpic = (action$: Observable<HazardAnalysisActions>) => action$.pipe(
  ofType(getHazardAnalysisById.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: { id: string }) => from(getHazardAnalysisDataById(data)).pipe(
    map((res: HazardAnalysisResponseData) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getHazardAnalysisSuccessById(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getHazardAnalysisFailureById();
    }),
    takeUntil(action$.pipe(filter(getHazardAnalysisById.match))),
    catchError((error) => of(getHazardAnalysisFailureById(error))),
  )),
);

// Update Hazard Analysis
async function updateHazardAnalysis(data: UpdateHazardAnalysisDataById): Promise<HazardAnalysisResponseData> {
  const url = `${Config.hazardAnalysisUrl.updateHazardAnalysisUrl}`;
  const result = await makePutRequest<HazardAnalysisResponseData>(url, data);
  return result.data;
}
export const updateHazardAnalysisEpic = (action$ : Observable<HazardAnalysisActions>) => action$.pipe(
  filter(updateHazardAnalysisStart.match),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap(({ payload, navigate }:UpdateHazardAnalystPayload) => from(updateHazardAnalysis(payload)).pipe(
    map((res: HazardAnalysisResponseData) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        if (navigate) {
          navigate(-1);
        }
        return (updateHazardAnalysisSuccess());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return (updateHazardAnalysisFailure());
    }),
    takeUntil(action$.pipe(filter(updateHazardAnalysisStart.match))),
    catchError((error) => of(updateHazardAnalysisFailure(error))),
  )),
);
