import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { DeleteIcon, EditIcon } from '../../../../../assets/TableIcons';
import { TypesTableRow } from '../Utils/TypeBidEntry.d';
import ComponentDeleteModal from '../../../../../Common/DeleteModal/ComponentDeleteModal';
import './StyleEntryDataTable.scss';

export default function ComponentCustomerTableRow({
  sno, description, amount, priceDes,
}:TypesTableRow): JSX.Element {
  const [open, setOpen] = useState(false); // delete modal
  const handleModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        className="table-row-height"
      >
        <TableCell className="wdth-1">{sno}</TableCell>
        <TableCell className="wdth-3">
          <div className="tableCell-edit">
            <div><EditIcon /></div>
            <div onClick={handleModal}><DeleteIcon /></div>
          </div>
        </TableCell>
        <TableCell className="wdth-5">{description}</TableCell>
        <TableCell className="wdth-6">{amount}</TableCell>
        <TableCell className="wdth-5">{priceDes}</TableCell>
      </TableRow>
      <ComponentDeleteModal show={open} setOpenFrom={handleModal} />
    </>

  );
}
