/* eslint-disable max-len */
import React from 'react';
import ListIcon from '@mui/icons-material/List';
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import DocumentScannerTwoToneIcon from '@mui/icons-material/DocumentScannerTwoTone';
import {
  CustomersIcon, JobIcon, KitsIcon, PresentationIcon, ReportsIcon,
  JobSchedulingIcon, SystemIcon, AnalystIcon, BlackArrowIcon, CalendarIcon,
} from '../../assets/NavBarIcons';
import EndPoints from '../../Routes/EndPoints';
import {
  showBoardPosition, showExpense, showInspection, showRoleBased,
  vesselOption,
} from '../../env';
import { ANALYST_ROLE_ID } from '../../Routes/constant';
import { InventoryManagementIcon } from '../../assets/NavBarIcons/InventoryManagementIcon';
import { OilReportIconNew } from '../../assets/NavBarIcons/OilReportIconNew';
import { BoardPositionIcon } from '../../assets/NavBarIcons/BoardPositionIcon';
import { UserManagementIcon } from '../../assets/NavBarIcons/UserManagementIcon';
import { JobOrderIcon } from '../../assets/NavBarIcons/JobOrderIcon';
import { RoleBaseControlIcon } from '../../assets/NavBarIcons/RoleBaseControlIcon';
import { InspectionSummeryIcon } from '../../assets/NavBarIcons/InspectionSummeryIcon';
import { ExpenseReportsIcon } from '../../assets/NavBarIcons/ExpenseReportsIcon';
import { ReportingWorkSpaceIcon } from '../../assets/NavBarIcons/ReportingWorkSpaceIcon';
import { RecommendationsIcon } from '../../assets/NavBarIcons/RecommendationsIcon';
import { PhotosIcon } from '../../assets/NavBarIcons/PhotosIcon';
import { DataSheetIcon } from '../../assets/NavBarIcons/DataSheetIcon';
import { VesselsIcon } from '../../assets/NavBarIcons/VesselsIcon';
import { HazardAnalysisIcon } from '../../assets/NavBarIcons/HazardAnalysisIcon';

const menuItems = (roleId: string) => (roleId === ANALYST_ROLE_ID ? [
  {
    id: 1,
    label: 'Dashboard',
    icon: <PresentationIcon />,
    link: EndPoints.DASHBOARD,
    show: true,
  },
  {
    id: 1,
    label: 'Reports',
    icon: <DocumentScannerTwoToneIcon />,
    link: EndPoints.REPORTS,
    show: true,
  },
]
  : [
    {
      id: 1,
      label: 'Dashboard',
      icon: <PresentationIcon />,
      link: EndPoints.DASHBOARD,
      show: true,
    },
    {
      id: 2,
      label: 'SMC',
      icon: <ReportsIcon />,
      link: 'smc',
      show: true,
    },
    {
      id: 3,
      label: 'Customers',
      icon: <CustomersIcon />,
      link: 'customers',
      show: true,
    },
    {
      id: 4,
      label: 'Jobs',
      icon: <JobIcon />,
      link: EndPoints.JOBS,
      show: true,
    },
    {
      id: 5,
      label: 'Job Scheduling',
      icon: <JobSchedulingIcon />,
      activeSubmenuId: 1,
      show: true,
      subMenu: [
        {
          id: 1,
          label: 'Unscheduled Jobs',
          icon: <BlackArrowIcon />,
          link: 'unscheduled-jobs',
        },
        {
          id: 2,
          label: 'Scheduled Jobs',
          icon: <BlackArrowIcon />,
          link: 'scheduled-jobs',
        },
      ],
    },
    {
      id: 6,
      label: 'Kits',
      icon: <KitsIcon />,
      activeSubmenuId: 2,
      show: true,
      subMenu: [
        {
          id: 1,
          label: 'Freon Cylinder',
          icon: <BlackArrowIcon />,
          link: EndPoints.FREON_CYLINDER,
        },
        {
          id: 2,
          label: 'Oil Kits',
          icon: <BlackArrowIcon />,
          link: 'oilKits',
        },
      ],
    },
    {
      id: 7,
      label: 'System',
      icon: <SystemIcon />,
      activeSubmenuId: 3,
      show: true,
      subMenu: [
        {
          id: 1,
          label: 'Price List',
          icon: <BlackArrowIcon />,
          link: 'price-list',
        },
        {
          id: 2,
          label: 'Kit Prices',
          icon: <BlackArrowIcon />,
          link: 'kit-prices',
        },
        {
          id: 3,
          label: 'Markups',
          icon: <BlackArrowIcon />,
          link: 'markups',
        },
      ],
    },
    {
      id: 8,
      label: 'Analyst',
      icon: <AnalystIcon />,
      link: 'analyst',
      show: true,
    },
    {
      id: 9,
      label: 'Lists',
      icon: <ListIcon />,
      activeSubmenuId: 4,
      show: true,
      subMenu: [
        {
          id: 1,
          label: 'Unit Type List',
          icon: <BlackArrowIcon />,
          link: 'type-list',
        },
        {
          id: 2,
          label: 'Unit Style List',
          icon: <BlackArrowIcon />,
          link: 'style-list',
        },
        {
          id: 3,
          label: 'Unit Manufacturer List',
          icon: <BlackArrowIcon />,
          link: 'manufacturer-list',
        },
        {
          id: 4,
          label: 'Unit Material List',
          icon: <BlackArrowIcon />,
          link: 'material-list',
        },
        {
          id: 5,
          label: 'Vessel List',
          icon: <BlackArrowIcon />,
          link: 'vessel-list',
        },
        {
          id: 6,
          label: 'Tube Type List',
          icon: <BlackArrowIcon />,
          link: 'tube-list',
        },
        {
          id: 7,
          label: 'Test End List',
          icon: <BlackArrowIcon />,
          link: 'test-end-list',
        },
        {
          id: 8,
          label: 'Support List',
          icon: <BlackArrowIcon />,
          link: 'support-list',
        },
        {
          id: 9,
          label: 'Report Type List',
          icon: <BlackArrowIcon />,
          link: 'report-list',
        },
        {
          id: 10,
          label: 'Default Type And Style List',
          icon: <BlackArrowIcon />,
          link: 'default-list',
        },
        {
          id: 11,
          label: 'Equipment Category list',
          icon: <BlackArrowIcon />,
          link: 'equipment-category-list',
        },
      ],
    },
    {
      id: 10,
      label: 'Sites',
      icon: <RoomTwoToneIcon />,
      link: 'sites',
      show: true,
    },
    {
      id: 11,
      label: 'Utilities',
      icon: <JobIcon />,
      activeSubmenuId: 5,
      show: true,
      subMenu: [
        {
          id: 1,
          label: 'Recommendation Codes',
          icon: <BlackArrowIcon />,
          link: 'recommendation-codes',
        },
        {
          id: 2,
          label: 'Recommendation Text',
          icon: <BlackArrowIcon />,
          link: 'recommendation-text',
        },
        {
          id: 3,
          label: 'Inspection Procedure',
          icon: <BlackArrowIcon />,
          link: 'inspection-procedure',
        },
        {
          id: 4,
          label: 'Calibration Procedure',
          icon: <BlackArrowIcon />,
          link: 'calibration-procedure',
        },
        {
          id: 5,
          label: 'Defect Code',
          icon: <BlackArrowIcon />,
          link: 'defect-code',
        },
        {
          id: 6,
          label: 'Analyst Defect Code',
          icon: <BlackArrowIcon />,
          link: 'analyst-defect',
        },
      ],
    },
    {
      id: 12,
      label: 'Calendar',
      icon: <CalendarIcon />,
      link: 'calender',
      show: true,
    },
    {
      id: 13,
      label: 'Inventory Management',
      icon: <InventoryManagementIcon />,
      link: 'inventory-management',
      roleId: '1',
      show: true,
    },
    {
      id: 14,
      label: 'Oil Report',
      icon: <OilReportIconNew />,
      link: 'oil-report',
      show: true,
    },
    {
      id: 15,
      label: 'Board Position',
      icon: <BoardPositionIcon />,
      link: 'board-position',
      show: showBoardPosition,
    },
    {
      id: 16,
      label: 'User Management',
      icon: <UserManagementIcon />,
      link: 'user-management',
      show: true,
    },
    {
      id: 18,
      label: 'Job Order',
      icon: <JobOrderIcon />,
      link: EndPoints.JOB_ORDER,
      show: true,
    },
    {
      id: 19,
      label: 'Role Base Access Control',
      icon: <RoleBaseControlIcon />,
      link: 'RBAC',
      show: showRoleBased,
    },
    {
      id: 20,
      label: 'Inspection Summary',
      icon: <InspectionSummeryIcon />,
      link: 'inspection-summary',
      show: showInspection,
    },
    {
      id: 21,
      label: 'Expense Reports',
      icon: <ExpenseReportsIcon />,
      link: 'expense-reports',
      show: showExpense,
    },
    {
      id: 22,
      label: 'Reporting Workspace',
      icon: <ReportingWorkSpaceIcon />,
      link: EndPoints.REPORT_WORKSPACE,
      show: true,
    },
    {
      id: 23,
      label: 'Recommendations',
      icon: <RecommendationsIcon />,
      link: 'recommendations',
      show: true,
    },
    {
      id: 24,
      label: 'Photos',
      icon: <PhotosIcon />,
      link: 'photos',
      show: true,
    },
    {
      id: 25,
      label: 'Data Sheet',
      icon: <DataSheetIcon />,
      link: 'datasheets',
      show: true,
    },
    {
      id: 26,
      label: 'Vessels',
      icon: <VesselsIcon />,
      link: 'vessel-information',
      show: vesselOption,
    },
    {
      id: 27,
      label: 'Hazard Analysis',
      icon: <HazardAnalysisIcon />,
      link: EndPoints.HAZARD_ANALYSIS,
      show: true,
    },
    {
      id: 28,
      label: 'Re - Inspection Letter',
      icon: <AllInboxIcon />,
      link: EndPoints.INSPECTION_LETTER,
      show: true,
    },
  ]);

export default menuItems;
