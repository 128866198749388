/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DroppableProvided,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { onRemoveAnalyst, onRowOrderingAnalyst } from '../../Screens/boardPosition/redux/sliceBoardPosition';
import { IconList } from '../../utils/StaticIcons';

interface DraggableTableProps {
  AnalystId: string,
  AnalystName: string;
}

interface DraggableProps {
  data: DraggableTableProps[];
  handleZonePageData: () => void;
  zoneId: number;
}

function DraggableTable({
  data, handleZonePageData, zoneId,
}: DraggableProps) {
  const [items, setItems] = useState<DraggableTableProps[]>();
  const [hoveredRow, setHoveredRow] = useState<string>('');
  const dispatch = useDispatch();

  const handleMouseEnter = (analyst: string) => {
    setHoveredRow(analyst);
  };

  const handleMouseLeave = () => {
    setHoveredRow('');
  };

  const removeAnalyst = (analyst: string) => {
    const payload = {
      ZoneId: zoneId,
      AnalystId: analyst,
    };
    dispatch(onRemoveAnalyst({ payload, callback: handleZonePageData }));
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    // Reorder items based on the drag and drop result
    if (items) {
      const reorderedItems = Array.from(items);
      const [removed] = reorderedItems.splice(result.source.index, 1);
      reorderedItems.splice(result.destination.index, 0, removed);

      // Update the state with the reordered items
      setItems(reorderedItems);
      const newAnalystOrder: string[] = reorderedItems?.map((ele) => ele.AnalystId) || [];
      const payload = {
        ZoneId: zoneId,
        AnalystIds: newAnalystOrder,
      };
      dispatch(onRowOrderingAnalyst(payload));
    }
  };
  useEffect(() => {
    if (data?.length >= 0) {
      setItems(data);
    }
  }, [data]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable" direction="vertical">
        {(droppableProvided: DroppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {items?.map((item, index: number) => (
              <Draggable key={item.AnalystId} draggableId={item.AnalystId} index={index}>
                {(
                  draggableProvided: DraggableProvided,
                  //   snapshot: DraggableStateSnapshot,
                ) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <div
                      style={{
                        border: '1px solid #E9EBEC',
                        borderRadius: '4px',
                        padding: '20px 10px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#495057',
                      }}
                      onMouseEnter={() => handleMouseEnter(item?.AnalystId)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div style={{
                        fontWeight: '400',
                        fontSize: '14px',
                        color: '#495057',
                      }}
                      >
                        {item?.AnalystName}

                      </div>
                      {
                        hoveredRow === item?.AnalystId
                        && (
                          <img
                            onClick={() => removeAnalyst(item?.AnalystId)}
                            style={{ cursor: 'pointer' }}
                            src={IconList.closeIcon}
                            alt="a"
                          />
                        )
                      }
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableTable;
