/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest,
} from '../../../../Common/NetworkOps';
import { GetById, ListingResponse } from '../../../../utils/type';
import {
  AnalystDetails, AssociateUnitPayload, CreateBidPayload, CreateBidResponse, DeleteUnitPayload, DriveExpenseResponse, DriverExpensePayload, GetAnalystCitypayload, GetBidByIdResponse, GetBidPricingPayload, GetBidPricingResponse, GetCustomerAnalystPayload, GetCustomerAnalystResponse, GetNearestAnalystResponse, MasterValue, UnitListing, UnitListingPayload, UpdateBidPayload,
} from '../utils/types';
import {
  associatedUnitsFailure,
  associatedUnitsSuccess,
  associateUnitsStart,
  BidsActions, createBidFailure, createBidStart, createBidSuccess,
  deleteUnitFailure,
  deleteUnitStart,
  deleteUnitSuccess,
  getAnalystCityFailure, getAnalystCityStart, getAnalystCitySuccess,
  getAnalystmasterFailure, getAnalystMasterStart, getAnalystMasterSuccess,
  getAssociatedUnitsFailure, getAssociatedUnitsStart, getAssociatedUnitsSuccess, getBidByIdFailure, getBidByIdStart, getBidByIdSuccess, getBidPricingStart, getBidPricingSuccess, getCustomerAnalystFailure, getCustomerAnalystStart, getCustomerAnalystSuccess, getDriverExpenseFailure, getDriverExpenseStart, getDriverExpenseSuccess, getGeneralUnitsSuccess, getNearestAnalystFailure, getNearestAnalystStart, getNearestAnalystSuccess, setSelectedTab, updateBidFailure, updateBidStart, updateBidSuccess,
} from './bidWorksheetSlice';
import { getJobByIdStart } from '../../jobStarter/addJob/redux/addJobSlice';

async function getAnalysts(): Promise<GetById<MasterValue[]>> {
  const url = `${Config.analystMaster.getAnalysts}`;
  const result = await makeGetRequest<GetById<MasterValue[]>>(url);
  return result.data;
}

export const getAnalystsEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getAnalystMasterStart.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap(() => from(getAnalysts()).pipe(
    map((res: GetById<MasterValue[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAnalystMasterSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystmasterFailure();
    }),
    takeUntil(action$.pipe(filter(getAnalystMasterStart.match))),
    catchError((error) => of(getAnalystmasterFailure(error))),
  )),
);

async function createBid(data: CreateBidPayload): Promise<GetById<CreateBidResponse>> {
  const url = `${Config.bids.createBid}`;
  const result = await makePostRequest<GetById<CreateBidResponse>>(url, data);
  return result.data;
}

export const createBidEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(createBidStart.type),
  map((x) => x.payload),
  mergeMap((data:CreateBidPayload) => from(createBid(data)).pipe(
    mergeMap((res: GetById<CreateBidResponse>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(createBidSuccess(res.BMT.Result.BidId), setSelectedTab(2), getBidByIdStart(res.BMT.Result.BidId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(createBidFailure());
    }),
    takeUntil(action$.pipe(filter(createBidStart.match))),
    catchError((error) => of(createBidFailure(error))),
  )),
);

async function getAnalystCity(data: GetAnalystCitypayload): Promise<GetById<AnalystDetails>> {
  const url = `${Config.analystMaster.getAnalystCity}?AnalystId=${data.analystId}`;
  const result = await makeGetRequest<GetById<AnalystDetails>>(url);
  return result.data;
}

export const getAnalystCityEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getAnalystCityStart.type),
  map((x) => x.payload),
  mergeMap((data:GetAnalystCitypayload) => from(getAnalystCity(data)).pipe(
    mergeMap((res: GetById<AnalystDetails>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload:GetCustomerAnalystPayload = {
          userCity: data?.siteCity,
          analystCity: res.BMT.Result.BaseCity.City,
        };

        if (data.calculateDistance) {
          return of(getAnalystCitySuccess(res.BMT.Result));
        }

        return of(getAnalystCitySuccess(res.BMT.Result), getCustomerAnalystStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getAnalystCityFailure());
    }),
    takeUntil(action$.pipe(filter(getAnalystCityStart.match))),
    catchError((error) => of(getAnalystCityFailure(error))),
  )),
);

async function getBidById(bidId: string): Promise<GetById<GetBidByIdResponse>> {
  const url = `${Config.bids.getBidById}?BidId=${bidId}`;
  const result = await makeGetRequest<GetById<GetBidByIdResponse>>(url);
  return result.data;
}

export const getBidByIdEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getBidByIdStart.type),
  map((x) => x.payload),
  mergeMap((data:string) => from(getBidById(data)).pipe(
    mergeMap((res: GetById<GetBidByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return of(getBidByIdSuccess(res.BMT.Result), getJobByIdStart(res.BMT.Result?.BidDetails?.JobId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getBidByIdFailure());
    }),
    takeUntil(action$.pipe(filter(getBidByIdStart.match))),
    catchError((error) => of(getBidByIdFailure(error))),
  )),
);

async function getUnitListing(data: UnitListingPayload): Promise<ListingResponse<UnitListing>> {
  let url = `${Config.units.unitListing}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  if (data.bidId) {
    url += `&BidId=${data.bidId}`;
  }
  const result = await makeGetRequest<ListingResponse<UnitListing>>(url);
  return result.data;
}

export const getUnitListingEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getAssociatedUnitsStart.type),
  map((x) => x.payload),
  mergeMap((data:UnitListingPayload) => from(getUnitListing(data)).pipe(
    map((res: ListingResponse<UnitListing>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        if (data.bidId) {
          return getAssociatedUnitsSuccess(res.BMT.Result);
        }
        return getGeneralUnitsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAssociatedUnitsFailure();
    }),
    takeUntil(action$.pipe(filter(getAssociatedUnitsStart.match))),
    catchError((error) => of(getAssociatedUnitsFailure(error))),
  )),
);

async function getDriverExpense(data: DriverExpensePayload): Promise<GetById<DriveExpenseResponse>> {
  const url = `${Config.bids.getDriverExpense}?roundTripMiles=${data.roundTripMiles}&siteId=${data.siteId}&calculatedDays=${data.calculatedDays}&overridedays=${data.overridedays}&gulfCoastFL=${data.gulfCoastFL}&airfare=${data.airfare}`;
  const result = await makeGetRequest<GetById<DriveExpenseResponse>>(url);
  return result.data;
}

export const getDriverExpenseEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getDriverExpenseStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:DriverExpensePayload) => from(getDriverExpense(data)).pipe(
    map((res: GetById<DriveExpenseResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getDriverExpenseSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getDriverExpenseFailure();
    }),
    takeUntil(action$.pipe(filter(getDriverExpenseStart.match))),
    catchError((error) => of(getDriverExpenseFailure(error))),
  )),
);

async function getBidPricing(data: GetBidPricingPayload): Promise<GetById<GetBidPricingResponse>> {
  const url = `${Config.bids.getBidPricing}?custId=${data.custId}&siteId=${data.siteId}`;
  const result = await makeGetRequest<GetById<GetBidPricingResponse>>(url);
  return result.data;
}

export const getBidPricingEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getBidPricingStart.type),
  map((x) => x.payload),
  mergeMap((data:GetBidPricingPayload) => from(getBidPricing(data)).pipe(
    map((res: GetById<GetBidPricingResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getBidPricingSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getDriverExpenseFailure();
    }),
    takeUntil(action$.pipe(filter(getBidPricingStart.match))),
    catchError((error) => of(getDriverExpenseFailure(error))),
  )),
);

async function updateBid(data: UpdateBidPayload): Promise<GetById<GetBidByIdResponse>> {
  const url = `${Config.bids.updateBid}`;
  const result = await makePutRequest<GetById<GetBidByIdResponse>>(url, data);
  return result.data;
}
export const updateBidEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(updateBidStart.type),
  map((x) => x.payload),
  mergeMap((data:UpdateBidPayload) => from(updateBid(data)).pipe(
    map((res: GetById<GetBidByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updateBidSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateBidFailure();
    }),
    takeUntil(action$.pipe(filter(updateBidStart.match))),
    catchError((error) => of(updateBidFailure(error))),
  )),
);

async function getCustomerAnalyst(data: GetCustomerAnalystPayload): Promise<GetById<GetCustomerAnalystResponse>> {
  const url = `${Config.analystMaster.getCustomerAnalystDistance}?usercity=${data.userCity}&analystcity=${data.analystCity}`;
  const result = await makeGetRequest<GetById<GetCustomerAnalystResponse>>(url);
  return result.data;
}
export const getCustomerAnalystEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getCustomerAnalystStart.type),
  map((x) => x.payload),
  mergeMap((data:GetCustomerAnalystPayload) => from(getCustomerAnalyst(data)).pipe(
    map((res: GetById<GetCustomerAnalystResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getCustomerAnalystSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getCustomerAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(getCustomerAnalystStart.match))),
    catchError((error) => of(getCustomerAnalystFailure(error))),
  )),
);

async function getNearestAnalyst(city: string): Promise<GetById<GetNearestAnalystResponse>> {
  const url = `${Config.analystMaster.getNearestAnalyst}?city=${city}`;
  const result = await makeGetRequest<GetById<GetNearestAnalystResponse>>(url);
  return result.data;
}
export const getNearestAnalystEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(getNearestAnalystStart.type),
  map((x) => x.payload),
  mergeMap((data:string) => from(getNearestAnalyst(data)).pipe(
    mergeMap((res: GetById<GetNearestAnalystResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          analystId: res.BMT.Result.NearestAnalyst,
          siteCity: data,
        };

        return of(getNearestAnalystSuccess(res.BMT.Result), getAnalystCityStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getNearestAnalystFailure());
    }),
    takeUntil(action$.pipe(filter(getNearestAnalystStart.match))),
    catchError((error) => of(getNearestAnalystFailure(error))),
  )),
);

async function associateUnit(data: AssociateUnitPayload): Promise<GetById<string>> {
  const url = `${Config.units.associateUnits}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const associateUnitsEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(associateUnitsStart.type),
  map((x) => x.payload),
  mergeMap((data:AssociateUnitPayload) => from(associateUnit(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:UnitListingPayload = {
          page: 1,
          rowsPerPage: 10,
          searchQuery: '',
          bidId: data.BidId,
        };
        const updateBidStartPayload: UpdateBidPayload = {
          BidId: data.BidId,
          BidDetails: data.BidDetails,
          BidPriceDetails: data.BidPriceDetails,
        };
        return of(associatedUnitsSuccess(), getAssociatedUnitsStart(payload), getBidByIdStart(data.BidId), updateBidStart(updateBidStartPayload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(associatedUnitsFailure());
    }),
    takeUntil(action$.pipe(filter(associateUnitsStart.match))),
    catchError((error) => of(associatedUnitsFailure(error))),
  )),
);

async function deleteUnit(data: DeleteUnitPayload): Promise<GetById<string>> {
  const url = `${Config.units.deleteUnit}?Unitid=${data.unitId}`;
  const result = await makeDeleteRequest<GetById<string>>(url);
  return result.data;
}
export const deleteUnitEpic = (action$ : Observable<BidsActions>) => action$.pipe(
  ofType(deleteUnitStart.type),
  map((x) => x.payload),
  mergeMap((data:DeleteUnitPayload) => from(deleteUnit(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:UnitListingPayload = {
          page: 1,
          rowsPerPage: 10,
          searchQuery: '',
          bidId: data.bidId,
        };
        return of(deleteUnitSuccess(), getAssociatedUnitsStart(payload), getBidByIdStart(data.bidId));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteUnitFailure());
    }),
    takeUntil(action$.pipe(filter(deleteUnitStart.match))),
    catchError((error) => of(deleteUnitFailure(error))),
  )),
);
