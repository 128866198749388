/* eslint-disable no-mixed-operators */
import * as Yup from 'yup';
// eslint-disable-next-line import/no-cycle
import { HazardAnalysisDataById } from './types';

export enum SafetyChecklist {
  // JobOrder = 'JobOrder',

  Outside = 'Outside',
  FireResistantCloths = 'FireResistantCloths',
  HardHat = 'HardHat',
  NoContactLenses = 'NoContactLenses',
  SafetyGlasses = 'SafetyGlasses',
  Gloves = 'Gloves',
  Harness = 'Harness',
  RubberGloves = 'RubberGloves',
  HearingProtection = 'HearingProtection',
  LockoutTag = 'LockoutTag',
  SteelToedBoots = 'SteelToedBoots',
  SafetyClassVideo = 'SafetyClassVideo',
  Respirator = 'Respirator',

  TightSpaceLess24in = 'TightSpaceLess24in',
  ConfirmedSpace = 'ConfirmedSpace',
  DeepWaterBox = 'DeepWaterBox',

  VerticalBundleTopDown = 'VerticalBundleTopDown',
  VerticalBundleBottomUp = 'VerticalBundleBottomUp',

  OfficeBuilding = 'OfficeBuilding',
  School = 'School',
  Factory = 'Factory',
  Hospital = 'Hospital',
  TextileMill = 'TextileMill',
  Ship = 'Ship',
  ChemicalPlant = 'ChemicalPlant',
  EthanolPlant = 'EthanolPlant',
  FossilPower = 'FossilPower',
  NukePower = 'NukePower',

  DoubleLanyard = 'DoubleLanyard',
  RubberBoots = 'RubberBoots',
  FaceShield = 'FaceShield',
  OtherExplainInComments = 'OtherExplainInComments',
  Comments = 'Comments',
}

export const checkboxSections = [
  {
    section: 'Main',
    checkboxes: [
      { label: 'Outside', name: SafetyChecklist.Outside },
      { label: 'Fire Resistant Cloths', name: SafetyChecklist.FireResistantCloths },
      { label: 'Hard Hat', name: SafetyChecklist.HardHat },
      { label: 'No Contact Lenses', name: SafetyChecklist.NoContactLenses },
      { label: 'Safety Glasses', name: SafetyChecklist.SafetyGlasses },
      { label: 'Gloves', name: SafetyChecklist.Gloves },
      { label: 'Harness', name: SafetyChecklist.Harness },
      { label: 'Rubber Gloves', name: SafetyChecklist.RubberGloves },
      { label: 'Hearing Protection', name: SafetyChecklist.HearingProtection },
      { label: 'Lock-out Tag', name: SafetyChecklist.LockoutTag },
      { label: 'Steel Toed Boots', name: SafetyChecklist.SteelToedBoots },
      { label: 'Safety Class/Video', name: SafetyChecklist.SafetyClassVideo },
      { label: 'Respirator', name: SafetyChecklist.Respirator },
    ],
  },
  {
    section: 'Work Space',
    checkboxes: [
      { label: 'Tight Space less 24 in', name: SafetyChecklist.TightSpaceLess24in },
      { label: 'Confirmed Space', name: SafetyChecklist.ConfirmedSpace },
      { label: 'Deep Water Box', name: SafetyChecklist.DeepWaterBox },
    ],
  },
  {
    section: 'Vertical Bundle',
    checkboxes: [
      { label: 'Vertical Bundle Top Down', name: SafetyChecklist.VerticalBundleTopDown },
      { label: 'Vertical Bundle Bottom Up', name: SafetyChecklist.VerticalBundleBottomUp },
    ],
  },
  {
    section: 'Site',
    checkboxes: [
      { label: 'Office Building', name: SafetyChecklist.OfficeBuilding },
      { label: 'School', name: SafetyChecklist.School },
      { label: 'Factory', name: SafetyChecklist.Factory },
      { label: 'Hospital', name: SafetyChecklist.Hospital },
      { label: 'Textile Mill', name: SafetyChecklist.TextileMill },
      { label: 'Ship', name: SafetyChecklist.Ship },
      { label: 'Chemical Plant', name: SafetyChecklist.ChemicalPlant },
      { label: 'Ethanol Plant', name: SafetyChecklist.EthanolPlant },
      { label: 'Fossil Power', name: SafetyChecklist.FossilPower },
      { label: 'Nuke Power', name: SafetyChecklist.NukePower },
    ],
  },
  {
    section: 'Other',
    checkboxes: [
      { label: 'Double Lanyard', name: SafetyChecklist.DoubleLanyard },
      { label: 'Rubber Boots', name: SafetyChecklist.RubberBoots },
      { label: 'Face Shield', name: SafetyChecklist.FaceShield },
      { label: 'Other Explain in Comments', name: SafetyChecklist.OtherExplainInComments },
      { label: 'Comments', name: SafetyChecklist.Comments, type: 'textField' },
    ],
  },
];

export const DefaultHazardAnalysisValues = {
  [SafetyChecklist.Outside]: false,
  [SafetyChecklist.FireResistantCloths]: false,
  [SafetyChecklist.HardHat]: false,
  [SafetyChecklist.NoContactLenses]: false,
  [SafetyChecklist.SafetyGlasses]: false,
  [SafetyChecklist.Gloves]: false,
  [SafetyChecklist.Harness]: false,
  [SafetyChecklist.RubberGloves]: false,
  [SafetyChecklist.HearingProtection]: false,
  [SafetyChecklist.LockoutTag]: false,
  [SafetyChecklist.SteelToedBoots]: false,
  [SafetyChecklist.SafetyClassVideo]: false,
  [SafetyChecklist.Respirator]: false,

  [SafetyChecklist.TightSpaceLess24in]: false,
  [SafetyChecklist.ConfirmedSpace]: false,
  [SafetyChecklist.DeepWaterBox]: false,

  [SafetyChecklist.VerticalBundleTopDown]: false,
  [SafetyChecklist.VerticalBundleBottomUp]: false,

  [SafetyChecklist.OfficeBuilding]: false,
  [SafetyChecklist.School]: false,
  [SafetyChecklist.Factory]: false,
  [SafetyChecklist.Hospital]: false,
  [SafetyChecklist.TextileMill]: false,
  [SafetyChecklist.Ship]: false,
  [SafetyChecklist.ChemicalPlant]: false,
  [SafetyChecklist.EthanolPlant]: false,
  [SafetyChecklist.FossilPower]: false,
  [SafetyChecklist.NukePower]: false,

  [SafetyChecklist.DoubleLanyard]: false,
  [SafetyChecklist.RubberBoots]: false,
  [SafetyChecklist.FaceShield]: false,
  // [SafetyChecklist.OtherExplainInComments]: true,
  [SafetyChecklist.Comments]: '',
};

export const HazardAnalysisFormSchema = Yup.object({
  // [SafetyChecklist.JobOrder]: Yup.boolean(),

  [SafetyChecklist.Outside]: Yup.boolean(),
  [SafetyChecklist.FireResistantCloths]: Yup.boolean(),
  [SafetyChecklist.HardHat]: Yup.boolean(),
  [SafetyChecklist.NoContactLenses]: Yup.boolean(),
  [SafetyChecklist.SafetyGlasses]: Yup.boolean(),
  [SafetyChecklist.Gloves]: Yup.boolean(),
  [SafetyChecklist.Harness]: Yup.boolean(),
  [SafetyChecklist.RubberGloves]: Yup.boolean(),
  [SafetyChecklist.HearingProtection]: Yup.boolean(),
  [SafetyChecklist.LockoutTag]: Yup.boolean(),
  [SafetyChecklist.SteelToedBoots]: Yup.boolean(),
  [SafetyChecklist.SafetyClassVideo]: Yup.boolean(),
  [SafetyChecklist.Respirator]: Yup.boolean(),

  [SafetyChecklist.TightSpaceLess24in]: Yup.boolean(),
  [SafetyChecklist.ConfirmedSpace]: Yup.boolean(),
  [SafetyChecklist.DeepWaterBox]: Yup.boolean(),

  [SafetyChecklist.VerticalBundleTopDown]: Yup.boolean(),
  [SafetyChecklist.VerticalBundleBottomUp]: Yup.boolean(),

  [SafetyChecklist.OfficeBuilding]: Yup.boolean(),
  [SafetyChecklist.School]: Yup.boolean(),
  [SafetyChecklist.Factory]: Yup.boolean(),
  [SafetyChecklist.Hospital]: Yup.boolean(),
  [SafetyChecklist.TextileMill]: Yup.boolean(),
  [SafetyChecklist.Ship]: Yup.boolean(),
  [SafetyChecklist.ChemicalPlant]: Yup.boolean(),
  [SafetyChecklist.EthanolPlant]: Yup.boolean(),
  [SafetyChecklist.FossilPower]: Yup.boolean(),
  [SafetyChecklist.NukePower]: Yup.boolean(),

  [SafetyChecklist.DoubleLanyard]: Yup.boolean(),
  [SafetyChecklist.RubberBoots]: Yup.boolean(),
  [SafetyChecklist.FaceShield]: Yup.boolean(),
  [SafetyChecklist.OtherExplainInComments]: Yup.boolean(),
  [SafetyChecklist.Comments]: Yup.string().test({
    name: 'required-with-OtherExplainInComments',
    test(value) {
      const { OtherExplainInComments } = this.parent;
      if (OtherExplainInComments) {
        return !!value && parseFloat(value) !== 0 || this.createError({
          message: 'Comments is required',
        });
      }
      return true;
    },
  }),
});

export function setHazardValues(analystDataById: HazardAnalysisDataById, setValue: SetValue) {
  // setValue(SafetyChecklist.JobOrder, analystDataById?.JobOrder);

  setValue(SafetyChecklist.Outside, analystDataById?.Outside);
  setValue(SafetyChecklist.FireResistantCloths, analystDataById?.FireResistantCloths);
  setValue(SafetyChecklist.HardHat, analystDataById?.HardHat);
  setValue(SafetyChecklist.NoContactLenses, analystDataById?.NoContactLenses);
  setValue(SafetyChecklist.SafetyGlasses, analystDataById?.SafteyGlases);
  setValue(SafetyChecklist.Gloves, analystDataById?.Gloves);
  setValue(SafetyChecklist.Harness, analystDataById?.Harness);
  setValue(SafetyChecklist.RubberGloves, analystDataById?.RubberGloves);
  setValue(SafetyChecklist.HearingProtection, analystDataById?.HearingProtection);
  setValue(SafetyChecklist.LockoutTag, analystDataById?.LockOutTag);
  setValue(SafetyChecklist.SteelToedBoots, analystDataById?.SteelToedBoots);
  setValue(SafetyChecklist.SafetyClassVideo, analystDataById?.SafteyClassVideo);
  setValue(SafetyChecklist.Respirator, analystDataById?.Resporator);

  setValue(SafetyChecklist.TightSpaceLess24in, analystDataById?.TightSpace);
  setValue(SafetyChecklist.ConfirmedSpace, analystDataById?.ConfirmedSpace);
  setValue(SafetyChecklist.DeepWaterBox, analystDataById?.DeepWaterBox);

  setValue(SafetyChecklist.VerticalBundleTopDown, analystDataById?.VerticalBundleTopDown);
  setValue(SafetyChecklist.VerticalBundleBottomUp, analystDataById?.VerticalBundleBottomUp);

  setValue(SafetyChecklist.OfficeBuilding, analystDataById?.OfficeBuilding);
  setValue(SafetyChecklist.School, analystDataById?.School);
  setValue(SafetyChecklist.Factory, analystDataById?.Factory);
  setValue(SafetyChecklist.Hospital, analystDataById?.Hospital);
  setValue(SafetyChecklist.TextileMill, analystDataById?.TextileMill);
  setValue(SafetyChecklist.Ship, analystDataById?.Ship);
  setValue(SafetyChecklist.ChemicalPlant, analystDataById?.ChemicalPlant);
  setValue(SafetyChecklist.EthanolPlant, analystDataById?.EthanolPlant);
  setValue(SafetyChecklist.FossilPower, analystDataById?.FossilPower);
  setValue(SafetyChecklist.NukePower, analystDataById?.NukePower);

  setValue(SafetyChecklist.DoubleLanyard, analystDataById?.DoubleLanyard);
  setValue(SafetyChecklist.RubberBoots, analystDataById?.RubberBoots);
  setValue(SafetyChecklist.FaceShield, analystDataById?.FaceShield);
  setValue(SafetyChecklist.OtherExplainInComments, analystDataById?.Other);
  setValue(SafetyChecklist.Comments, analystDataById?.Comment);
}
