import { Grid, Card, Button } from '@mui/material';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../StyleBidComponents.scss';

export default function ComponentBidEditDescriptionTab() {
  return (
    <div className="div_container_CustomerInfo">

      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>

          <Card style={{ minHeight: '300px', marginBottom: '20px' }}>
            <div className="customerInfo_container">
              <div className="customerInfo">
                Description
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="editorContainer">
                    <Editor
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      localization={{ locale: 'en' }}
                      toolbar={{
                        blockType: { className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' },
                      }}
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div button_div">
                    <div>
                      <Button className="button_cancel">Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button className="button_save_and_next">Ok</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
