import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { ReportsWorkspaceListingInterface } from './utils/types';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { getVesselInformationStart } from '../VesselInformation/redux/sliceVesselInformation';
import { ReportWorkspaceTableColumns } from './utils/helpers';
import { showWarnToaster } from '../../Common/ComponentToast/ComponentSuccessToasts';
import { debugLog } from '../../Common/Logger';
// import Styles from './utils/styles.module.scss';
// import { AnalystData, AnalystDataPayload } from '../VesselInformation/utils/type';

function ScreenReportWorkspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { vesselInformation, totalVesselInfo, isLoading } = useSelector((state:RootState) => state.VesselInformationReducer);
  const [data, setData] = useState<ReportsWorkspaceListingInterface[]>([]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getVesselInformationStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const tableData: ReportsWorkspaceListingInterface[] = useMemo(() => {
    if (vesselInformation?.length > 0) {
      const transformedData = vesselInformation?.map((ele, index) => ({
        sn: index,
        jobOrder: ele.JobOrder,
        id: `${ele.JobOrder}`,
      }));
      return transformedData;
    }
    return [];
  }, [vesselInformation]);

  useEffect(() => {
    if (vesselInformation) {
      setData(tableData);
    }
  }, [vesselInformation]);

  // const handleClickViews = (id: string) => {
  //   const record = vesselInformation.find((ele:ReportsInformationRes) => `${ele.JobOrder}_${ele.VesselId}_${ele.AnalystId}` === id);
  //   if (record) {
  //     navigate(`${record.JobOrder}/${record.VesselId}/${record.AnalystId}`);
  //   }
  // };

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: ReportsWorkspaceListingInterface) => {
      if (element?.id === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    handleData();
  }, []);

  const excelData = useMemo(() => vesselInformation?.map((item) => {
    const transformedDataItems = {
      JobOrder: item.JobOrder,
    };
    return transformedDataItems;
  }), [vesselInformation]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'REPORT WORKSPACE',
    );
  };

  const handleButtonClick = () => {
    const jobIds: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        jobIds.push(element?.id);
      }
    });
    if (jobIds.length < 2 || jobIds.length > 3) {
      showWarnToaster('Please select a minimum of 2 and a maximum of 3 job orders.');
      return;
    }
    const payload = {
      selectedIds: jobIds,
    };
    debugLog('🚀 ~ handleButtonClick ~ payload:', payload);
    navigate(`${payload.selectedIds}`);
    // const payLoad: AnalystDataPayload = {
    //   data: payload?.selectedIds?.map((item): AnalystData => {
    //     const parts = item.split('_');
    //     return {
    //       JobOrder: `${parts[0]}`,
    //       VesselId: parts[1],
    //       AnalystId: parts[2],
    //     };
    //   }),
    // };
    // dispatch(onCopyReportsStart({ payLoad, handleData }));
  };

  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="REPORTING WORKSPACE" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<ReportsWorkspaceListingInterface>
          data={data?.length > 0 ? data : []}
          columns={ReportWorkspaceTableColumns}
          totalRecords={totalVesselInfo}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          tableHeader="Job Order"
          customClassName="reportWorkspaceTableStyle"
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          checkBoxWithKey={['sn']}
          handleCheckBoxWithKey={handleCheckBoxWithKey}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12} className="dataTableCustomMargin">
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button
              type="button"
              // type="submit"
              onClick={() => handleButtonClick()}
              disabled={Boolean(jobSelected)}
              className="button_save_and_next"
            >
              Select Job Order
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default ScreenReportWorkspace;
