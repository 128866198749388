import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStateTypes, PayloadAddFreonCylinderTypes, PayloadTypes } from '../utils/types';

const initialState: InitialStateTypes = {
  freonCylinderData: {} as PayloadTypes,
  isLoading: false,
  responseMessage: '',
  unitPriceInitial: 0,
  isSuccess: false,
};

const addFreonSlice = createSlice({
  name: 'freonCylinder',
  initialState,
  reducers: {
    createCylinder(state, action: PayloadAction<PayloadAddFreonCylinderTypes>) {
      return {
        ...state,
        isLoading: true,
        freonCylinderData: action.payload,
      };
    },
    successAddFreon(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        responseMessage: action.payload,
        isSuccess: true,
      };
    },
    failureAddFreon(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        responseMessage: action.payload,
        isSuccess: false,
      };
    },
    unitPrice(state) {
      return {
        ...state,
        isLoading: true,
        unitPriceInitial: 0,
      };
    },
    successUnitPrice(state, action: PayloadAction<number>) {
      return {
        ...state,
        isLoading: false,
        unitPriceInitial: action.payload,
      };
    },
    failureUnitPrice(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        responseMessage: action.payload,
      };
    },
    // EditFreon
    editFreonStart(state, action: PayloadAction<PayloadTypes>) {
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        freonCylinderData: action.payload,
      };
    },
    editFreonSuccess(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    editFreonFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    resetStateFreon(state) {
      return {
        ...state,
        isSuccess: false,
      };
    },
  },
});

export const {
  createCylinder, successAddFreon, failureAddFreon, unitPrice, successUnitPrice,
  failureUnitPrice, editFreonStart, editFreonSuccess, editFreonFailure,
  resetStateFreon,
} = addFreonSlice.actions;
export const createFreonReducer = addFreonSlice.reducer;

export type CreateFreonActions =
  | ReturnType<typeof createCylinder>
  | ReturnType<typeof successAddFreon>
  | ReturnType<typeof unitPrice>
  | ReturnType<typeof successUnitPrice>
  | ReturnType<typeof failureUnitPrice>
  | ReturnType<typeof failureAddFreon>
  | ReturnType<typeof editFreonStart>
  | ReturnType<typeof editFreonSuccess>
  | ReturnType<typeof editFreonFailure>
  | ReturnType<typeof resetStateFreon>;
