import dayjs from 'dayjs';
import { VesselInformationMainEnum, VesselMainDataById, VesselMainPayloadAPI } from '../mainTypes.d';
import { VesselInformationFields } from '../type';
import { convertDate } from '../../../../utils/CommonFunctions';

export function setValues(vesselMainData:VesselMainDataById, setValue: SetValue) {
  setValue(VesselInformationMainEnum.vessel, vesselMainData?.VesselId);
  setValue(VesselInformationMainEnum.testEnd, vesselMainData?.TestEndId);
  setValue(VesselInformationMainEnum.tubeType, vesselMainData?.TubeTypeId);
  setValue(VesselInformationMainEnum.material, vesselMainData?.MaterialId);
  setValue(VesselInformationMainEnum.support, vesselMainData?.SupportId);
  setValue(VesselInformationMainEnum.NWT, vesselMainData?.Nwt);
  setValue(VesselInformationMainEnum.nwtBellLand, vesselMainData?.NwtBellLand);
  setValue(VesselInformationMainEnum.nwtUnderFins, vesselMainData?.NwtUnderFills);
  setValue(VesselInformationMainEnum.probeDiameter, vesselMainData?.ProbeDiameter);
  setValue(VesselInformationMainEnum.OD, vesselMainData?.Od);
  setValue(VesselInformationMainEnum.OD1, vesselMainData?.Od);
  setValue(VesselInformationMainEnum.OD2, vesselMainData?.Od2);
  setValue(VesselInformationMainEnum.OD3, vesselMainData?.Od3);
  setValue(VesselInformationMainEnum.tubeCount, vesselMainData?.TubeCount);
  setValue(VesselInformationMainEnum.tubeLength, vesselMainData?.TubeLength);
  setValue(VesselInformationMainEnum.analyst, vesselMainData?.AnalystId);
  setValue(VesselInformationMainEnum.inspectionDate, dayjs(convertDate(vesselMainData?.InspectionDate || '')));
  setValue(VesselInformationMainEnum.retestDate, dayjs(convertDate(vesselMainData?.RetestDate || '')));
}

export function getPayload(data: VesselInformationFields, vesselMainData: VesselMainDataById): VesselMainPayloadAPI {
  return {
    Id: vesselMainData.Id,
    VesselId: Number(data.vessel),
    JobOrder: vesselMainData.JobOrder,
    RecordId: String(data.vessel),
    TestEndId: Number(data.testEnd),
    TubeTypeId: Number(data.tubeType),
    MaterialId: Number(data.material),
    SupportId: Number(data.support),
    Od: vesselMainData.IsMultiOffset ? Number(data.OD1) : Number(data.OD),
    Od2: String(data.OD2),
    Od3: String(data.OD3),
    Nwt: Number(data.NWT),
    NwtBellLand: Number(data.NWTBellLand),
    NwtUnderFills: Number(data.NWTUnderFins),
    AnalystId: data.analyst,
    InspectionDate: dayjs(data.inspectionDate).format('DD-MM-YYYY'),
    RetestDate: dayjs(data.retestDate).format('DD-MM-YYYY'),
    ProbeDiameter: Number(data.probeDiameter),
    TubeCount: String(data.tubeCount),
    TubeLength: Number(data.tubeLength),
    TubeNumbering: true,
  };
}
