import React, { ChangeEvent, useState } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button, TextField, InputAdornment,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import {
  ChangeIcon, ChangeGreenIcon, RefreshIcon,
} from '../../../../assets/TableIcons';
import { RootState } from '../../../../redux/rootState';
import {
  GetExcelDataPayload,
  GetExcelResponse, OilReportImportModal, ReplaceUnitCompPayload, UnitData, UpdateExcelPayloadAction,
} from '../../utils/types';
import { SelectedUnitComp } from './selectedUnitComp';
import {
  getExcelDataStart,
  removeCompData,
  removeUnitData, replaceUnitCompStart, setCompData, setSelectedUnitCompartment, setSelectedUnitSerialNumber,
  setUnitData, updateExcelDataComp, updateExcelDataUnit,
} from '../../redux/oilReportSlice';
import { ModalPagination } from '../pagination';
import NoData from '../../../../assets/NoData.svg';
import { getItemIndex } from '../../../../utils/CommonFunctions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export default function ComponentOilReportImportModal({
  show, setOpenFrom, rowsPerPage, page, searchQuery,
}: OilReportImportModal): JSX.Element {
  const {
    excelData, selectedUnitSerialNumber, unitData, selectedCompartment, compData, totalExcelData,
    selectedDocumentId,
  } = useSelector((state:RootState) => state.oilReportReducer);
  const dispatch = useDispatch();

  const [currPage, setPage] = useState(1);
  const [currRowsPerPage, setRowsPerPage] = useState(10);
  const [currSearchQuery, setSearchQuery] = useState('');
  const [editReportId, setEditReportId] = useState('');
  const [editMode, setEditMode] = useState('');

  const handleReplaceUnit = (reportId: string) => {
    dispatch(setSelectedUnitSerialNumber(reportId));
    dispatch(setSelectedUnitCompartment(''));
  };

  const handleReplaceComp = (reportId: string) => {
    dispatch(setSelectedUnitCompartment(reportId));
    dispatch(setSelectedUnitSerialNumber(''));
  };

  const handleUndoUnit = (reportId: string) => {
    if (reportId !== selectedUnitSerialNumber) {
      dispatch(setSelectedUnitSerialNumber(reportId));
    }
    const info = unitData.find((entry:UnitData) => entry.ReportId === reportId);
    const payload:UpdateExcelPayloadAction = {
      unitSerialNumber: info?.ReplacedValue || '',
      isReplaced: false,
    };

    dispatch(updateExcelDataUnit(payload));
    dispatch(removeUnitData(reportId));
  };

  const handleUndoComp = (reportId: string) => {
    if (reportId !== selectedCompartment) {
      dispatch(setSelectedUnitCompartment(reportId));
    }
    const info = compData.find((entry:UnitData) => entry.ReportId === reportId);
    const payload:UpdateExcelPayloadAction = {
      unitSerialNumber: info?.ReplacedValue || '',
      isReplaced: false,
    };

    dispatch(updateExcelDataComp(payload));
    dispatch(removeCompData(reportId));
  };

  const handleProceed = () => {
    const payload:ReplaceUnitCompPayload = {
      DocumentId: selectedDocumentId,
      UnitData: unitData,
      ComponentData: compData,
      page,
      rowsPerPage,
      searchQuery,
    };

    dispatch(replaceUnitCompStart(payload));
    setOpenFrom();
  };

  const handleData = () => {
    if (selectedDocumentId) {
      const payload:GetExcelDataPayload = {
        documentId: selectedDocumentId,
        page: currPage,
        rowsPerPage: currRowsPerPage,
        searchQuery: currSearchQuery,
      };
      dispatch(getExcelDataStart(payload));
    }
  };

  const handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    const payload:GetExcelDataPayload = {
      documentId: selectedDocumentId,
      page: currPage,
      rowsPerPage: currRowsPerPage,
      searchQuery: event.target.value,
    };
    dispatch(getExcelDataStart(payload));
  };

  const handleUnitClick = (val:string, mode: string) => {
    setEditReportId(val);
    setEditMode(mode);
    if (mode === 'unit') {
      dispatch(setSelectedUnitSerialNumber(editReportId));
    } else {
      dispatch(setSelectedUnitCompartment(editReportId));
    }
  };

  const handleUnitSerialEdit = (val: string, replacedValue: string) => {
    const updateExcelPayload:UpdateExcelPayloadAction = {
      unitSerialNumber: val,
      isReplaced: false,
    };
    const obj:UnitData = {
      ReportId: editReportId,
      Value: val,
      ReplacedValue: replacedValue,
    };

    dispatch(setUnitData(obj));
    dispatch(updateExcelDataUnit(updateExcelPayload));
  };

  const handleCompEdit = (val: string, replacedValue: string) => {
    const obj:UnitData = {
      ReportId: selectedCompartment,
      Value: val,
      ReplacedValue: replacedValue,
    };

    const updateExcelPayload:UpdateExcelPayloadAction = {
      unitSerialNumber: val,
      isReplaced: false,
    };

    dispatch(setCompData(obj));
    dispatch(updateExcelDataComp(updateExcelPayload));
  };

  const handleBlur = (event:ChangeEvent<HTMLInputElement>, replacedValue: string, mode: string) => {
    const newValue = event.target.value;

    if (newValue !== replacedValue) {
      if (mode === 'unit') {
        handleUnitSerialEdit(newValue, replacedValue);
      } else {
        handleCompEdit(newValue, replacedValue);
      }
    }

    setEditMode('');
    setEditReportId('');
  };

  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="customerInfo_container flex-row">
          <div className="customerInfo">
            Import Overview
          </div>
          <div onClick={setOpenFrom}>
            <CloseIcon />
          </div>
        </div>
        <div className="container-fluid p-4 pb-0">
          <div className="row">
            <div className="col col-md-6">
              <div className="CustomTableContainer mt-4">
                <div className="CustomSearchTable">
                  <TextField
                    className="subHeaderTextfield"
                    size="small"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="oilReportTableContainer">
                  <div className="oilReportTableHead">
                    <div className="headerTableCell wd-5">
                      SN
                    </div>
                    <div className="headerTableCell wd-45">
                      Unit Serial Number
                    </div>
                    <div className="headerTableCell wd-45">
                      Compartment Serial Number
                    </div>
                  </div>
                  {excelData?.length > 0 ? (
                    <div className="oilReportTableBody">
                      {excelData?.map((data:GetExcelResponse, index:number) => (
                        <div className="oilReportTableList">
                          <div className="oilReportTableCell wd-5">{getItemIndex(currPage, currRowsPerPage, index)}</div>
                          <div className="oilReportTableCell wd-45">
                            <div onClick={() => handleUnitClick(data.ReportId, 'unit')}>
                              {(editReportId === data.ReportId) && (editMode === 'unit')
                                ? (
                                  <input
                                    type="text"
                                    defaultValue={data.UnitSerialNumber}
                                    onBlur={(e) => handleBlur(e, data.UnitSerialNumber, 'unit')}
                                  />
                                )
                                : data.UnitSerialNumber}
                            </div>
                            <div className="actionButtons">
                              {data?.isUnitReplaced
                                ? (<div className="actionBtn" onClick={() => handleUndoUnit(data.ReportId)}><RefreshIcon /></div>)
                                : (
                                  <div className="actionBtn" onClick={() => handleReplaceUnit(data.ReportId)}>
                                    {selectedUnitSerialNumber === data.ReportId ? <ChangeGreenIcon /> : <ChangeIcon />}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="oilReportTableCell wd-45">
                            <div onClick={() => handleUnitClick(data.ReportId, 'comp')}>
                              {(editReportId === data.ReportId) && (editMode === 'comp')
                                ? (
                                  <input
                                    type="text"
                                    defaultValue={data.CompartmentSerialNumber}
                                    onBlur={(e) => handleBlur(e, data.CompartmentSerialNumber, 'comp')}
                                  />
                                )
                                : data.CompartmentSerialNumber}
                            </div>
                            <div className="actionButtons">
                              {data?.isCompReplaced
                            && <div className="actionBtn" onClick={() => handleUndoComp(data.ReportId)}><RefreshIcon /></div>}
                              <div className="actionBtn" onClick={() => handleReplaceComp(data.ReportId)}>
                                {selectedCompartment === data.ReportId ? <ChangeGreenIcon /> : <ChangeIcon />}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="CustomTableContainer CustomTableBorderedContainer allCentered">
                      <div className=""><img src={NoData} alt="No data found" /></div>
                    </div>
                  )}
                </div>
              </div>
              <ModalPagination
                rowsPerPage={currRowsPerPage}
                page={currPage}
                setRowsPerPage={setRowsPerPage}
                setPage={setPage}
                totalEntries={totalExcelData}
                handleData={handleData}
              />
            </div>
            <SelectedUnitComp />
            <div className="col col-md-12">
              <div className="save_and_next_div mt-4">
                <div>
                  <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                </div>
                <div className="button_margin_left">
                  <Button className="button_save_and_next" onClick={handleProceed}>Proceed</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
