import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddVesselListApiResponse, AddVesselListData, DeleteVesselListResponse, GetAllVesselListApiResponse,
  PayloadTypeDeleteVesselList, PayloadTypeGetVesselList, UpdateVesselListApiResponse, UpdateVesselListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addVesselListStart, deleteVesselListStart, failureAddVesselList, failureDeleteVesselList, failureVesselList,
  failureUpdateVesselList, getVesselListStart,
  VesselTypeActions, successAddVesselList, successDeleteVesselList, successVesselList, successUpdateVesselList, updateVesselList,
} from './sliceVesselList';

async function GetVesselList(data: PayloadTypeGetVesselList): Promise<GetAllVesselListApiResponse> {
  const url = `${Config.Lists.getVesselList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllVesselListApiResponse>(url);
  return result?.data;
}

export const epicGetVesselList = (action$: Observable<VesselTypeActions>) => action$.pipe(
  filter(getVesselListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetVesselList) => from(GetVesselList(data)).pipe(
    map((res: GetAllVesselListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successVesselList(res?.BMT?.Result);
      }
      return failureVesselList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getVesselListStart.match))),
    catchError((error: AxiosError<GetAllVesselListApiResponse>) => of(failureVesselList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateVesselList(data: UpdateVesselListData): Promise<UpdateVesselListApiResponse> {
  const url = `${Config.Lists.updateVesselList}`;
  const result = await makePostRequest<UpdateVesselListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateVesselList = (action$: Observable<VesselTypeActions>) => action$.pipe(
  filter(updateVesselList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateVesselList(data)).pipe(
    mergeMap((res: UpdateVesselListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateVesselList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateVesselList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateVesselList.match))),
    catchError((error: AxiosError<UpdateVesselListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateVesselList(errorMessage));
    }),
  )),
);

async function AddVesselList(data: AddVesselListData): Promise<AddVesselListApiResponse> {
  const url = `${Config.Lists.addVesselList}`;
  const result = await makePostRequest<AddVesselListApiResponse>(url, data);
  return result?.data;
}

export const epicAddVesselList = (action$: Observable<VesselTypeActions>) => action$.pipe(
  filter(addVesselListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddVesselList(data)).pipe(
    mergeMap((res: AddVesselListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddVesselList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddVesselList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addVesselListStart.match))),
    catchError((error: AxiosError<AddVesselListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddVesselList(errorMessage));
    }),
  )),
);

async function DeleteVesselList(data: PayloadTypeDeleteVesselList): Promise<DeleteVesselListResponse> {
  const url = `${Config.Lists.deleteVesselList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteVesselListResponse>(url);
  return result?.data;
}

export const epicDeleteVesselList = (action$: Observable<VesselTypeActions>) => action$.pipe(
  filter(deleteVesselListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteVesselList) => from(DeleteVesselList(data)).pipe(
    mergeMap((res: DeleteVesselListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteVesselList(), getVesselListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteVesselList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteVesselListStart.match))),
    catchError((error: AxiosError<DeleteVesselListResponse>) => of(failureDeleteVesselList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
