import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentReportListDialog from './ComponentReportListDialog';
import { deleteReportListStart, getReportListStart } from '../redux/sliceReportList';
import { ReportList } from '../utils/types';

function ComponentReportListTable() {
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReportListId, setSelectedReportListId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const { isLoading, ReportListData } = useSelector((state: RootState) => state.ListReportReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getReportListStart(params));
  };

  const handleAction = (id: string) => {
    setOpen(true);
    setRowId(id);
  };

  const handleButtonClick = () => {
    setRowId('');
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Description', label: 'Description' },
    ],
    [],
  );

  const tableData: ReportList[] = useMemo(() => ReportListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      Description: item.Description,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [ReportListData]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedReportListId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedReportListId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteReportListStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<ReportList>
        handleButtonClick={handleButtonClick}
        data={tableData}
        columns={TableColumns}
        totalRecords={ReportListData?.TotalRecords}
        buttonText="Add"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentReportListDialog show={open} setOpenFrom={handleButtonClick} rowId={rowId} handleData={handleData} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentReportListTable;
