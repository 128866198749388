import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KitPriceListShowData } from '../utils/TypesKitPriceList';
import ComponentKitPriceEditDialog from './ComponentKitPriceEditDialog';
import DataTable from '../../../../Common/DataTable';
import { getKitPriceStart } from '../Redux/sliceKitPrice';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';

function ComponentKitPriceListTable() {
  const dispatch = useDispatch();

  const { kitPriceList, isLoading } = useSelector((state: RootState) => state.KitPriceReducer);

  const [rowwId, setRowId] = useState('');

  const handleData = () => { };

  const [open, setOpen] = useState(false);

  const handleAction = (id: string) => {
    setOpen(!open);
    setRowId(id);
  };

  const fetchKitPriceData = () => {
    dispatch(getKitPriceStart());
  };

  useEffect(() => {
    fetchKitPriceData();
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'KitDescriprion', label: 'Description' },
      { key: 'UnitPrice', label: 'Price' },
    ],
    [],
  );

  const tableData: KitPriceListShowData[] = useMemo(() => kitPriceList?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit',
      KitDescriprion: item?.KitDescriprion,
      UnitPrice: `$ ${item?.UnitPrice}`,
      id: item?.Id,
    };
    return transformedDataItem;
  }), [kitPriceList]);

  return (
    <>
      <DataTable<KitPriceListShowData>
        data={tableData}
        columns={TableColumns}
        totalRecords={100}
        handleData={handleData}
        tableHeader="Variables for Kit Prices"
        handleEdit={handleAction}
      />
      <ComponentKitPriceEditDialog show={open} setOpenFrom={() => setOpen(false)} rowId={rowwId} />
    </>
  );
}

export default ComponentKitPriceListTable;
