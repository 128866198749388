import {
  Button, Grid, Tab, Tabs, Dialog, Zoom, IconButton, DialogTitle, DialogContent,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../../../../../Common/DataTable';
import {
  selectedTabStyles, stylesTabIndicator, tabsStyles, tabStyles,
} from '../../../../../../Common/TabsStyles';
import styles from './utils/ReportStyles.module.scss';
import { RootState } from '../../../../../../redux/rootState';
import { TypesReportModal } from './Type';
import { formatVesselsTableData } from '../../utils/helper';
import { VesselsData } from '../../utils/types';
import {
  addJobReport, getAssociatedVesselsStart,
  reSetReportDataById,
} from '../../redux/jobScheduleSlice';
import { jobOrderPattern } from '../AddReportModal/utils/helper';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

export default function ReportListModalTabs({
  show, setOpenFrom, selectedSearch, unitEditData, callback, setValue, trigger,
}: TypesReportModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<VesselsData[] | []>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeTab, setActiveTab] = React.useState(1);
  const [selectedRadio, setSelectedRadio] = useState('');

  const {
    associatedVessels, totalAssociatedVessels, isLoading, generalAllVesselsList, reportDataById,
  } = useSelector((state:RootState) => state.jobScheduleReducer);

  const dispatch = useDispatch();
  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    if (activeTab === 1) {
      dispatch(getAssociatedVesselsStart({ ...payload, jobOrderId: unitEditData.UnitId || '', type: '1' }));
    } else {
      dispatch(getAssociatedVesselsStart({ ...payload, jobOrderId: unitEditData.UnitId || '', type: '2' }));
    }
  };
  const columns = useMemo(() => {
    const columnsData = [
      { key: 'select', label: 'Select' },
      { key: 'jobOrder', label: 'Job Order' },
      { key: 'date', label: 'Date' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'model', label: 'Model' },
      { key: 'tubeCount', label: 'Tube Count' },
      { key: 'siteName', label: 'Site Name' },
    ];

    if (activeTab === 2) {
      const jobOrderIndex = columnsData.findIndex((column) => column.key === 'jobOrder');
      columnsData.splice(jobOrderIndex + 1, 0, { key: 'serial', label: 'Serial #' });
    }

    return columnsData;
  }, [activeTab]);

  const handleSave = () => {
    const values = generalAllVesselsList.filter((item) => String(item.VesselId) === String(selectedSearch));
    if (values) {
      const match = selectedRadio.match(jobOrderPattern);
      if (match) {
        const [, jobOrder, , vesselId, id] = match;
        const payloads = [{
          Id: Number(id) || 0,
          JobOrder: jobOrder,
          VesselId: vesselId,
          UnitId: unitEditData?.UnitId || '',
          UnitVesselId: selectedSearch,
        }];
        dispatch(addJobReport({
          payloads, setOpenFrom, callback, IsReportPresent: unitEditData.IsReportPresent,
        }));
      }
    }
    return {
      Id: 0,
      JobOrder: '',
      VesselId: '',
      UnitId: unitEditData?.UnitId || '',
      UnitVesselId: selectedSearch,
    };
  };

  const TABS = [
    { id: 1, label: 'Associated Vessels' },
    { id: 2, label: 'General Vessels' },
  ];

  const handleChangeTab = (tabId: number) => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    if (tabId === 2) {
      dispatch(getAssociatedVesselsStart({ ...payload, jobOrderId: unitEditData.UnitId || '', type: '2' }));
    } else if (tabId === 1) {
      dispatch(getAssociatedVesselsStart({ ...payload, jobOrderId: unitEditData.UnitId || '', type: '1' }));
    }
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (activeTab === 1) {
      setData(formatVesselsTableData(associatedVessels));
      setTotalRecords(totalAssociatedVessels);
    } else {
      setData(formatVesselsTableData(associatedVessels));
      setTotalRecords(totalAssociatedVessels);
    }
  }, [associatedVessels, activeTab]);

  useEffect(() => {
    dispatch(reSetReportDataById());
  }, []);

  useEffect(() => () => {
    reportDataById.forEach((item) => {
      setValue(String(item.UnitVesselId), `${item.JobOrder}(${item.VesselName})-(${item.VesselId})-(${item.Id})`);
      trigger(String(item.UnitVesselId));
    });
  }, [show, reportDataById]);

  return (
    <Dialog
      maxWidth="lg"
      PaperProps={{
        className: 'paperStyle',
      }}
      open={show}
      transitionDuration={600}
      TransitionComponent={Transition}
      keepMounted
      onClose={setOpenFrom}
    >
      <DialogTitle className="modalHeaderTitle">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Add Reports
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '16px 24px' }}>
        {
          isLoading && (
            <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
              <CircularProgress />
            </Backdrop>
          )
        }
        <Tabs
          variant="scrollable"
          value={activeTab - 1}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              style={activeTab === tab.id ? selectedTabStyles : tabStyles}
              className="tab_styles"
            />
          ))}
        </Tabs>
        {activeTab === 1
              && (
              <div className={styles.rightTextClassWrapper}>
                <span className="customerID">
                  Serial #
                </span>
                <div className="job_input_div">
                  <div className="job_input_style">{data.length > 0 ? data[0]?.serial : ''}</div>
                </div>
              </div>
              )}
        <DataTable<VesselsData>
          data={data}
          columns={columns}
          totalRecords={totalRecords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          radioButtonColumns={['select']}
          customClassName="ReportTableListStyle"
        />
        {
          data?.length > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                </div>
                <div className="button_margin_left">
                  <Button className="button_save_and_next" disabled={!selectedRadio} onClick={handleSave}>Select</Button>
                </div>
              </div>
            </Grid>
          )
        }
      </DialogContent>
    </Dialog>
  );
}
