import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddTypeListApiResponse, AddTypeListData, DeleteTypeListResponse, GetAllTypeListApiResponse,
  PayloadTypeDeleteTypeList, PayloadTypeGetTypeList, UpdateTypeListApiResponse, UpdateTypeListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addTypeListStart, deleteTypeListStart, failureAddTypeList, failureDeleteTypeList, failureTypeList,
  failureUpdateTypeList, getTypeListStart,
  TypeListActions, successAddTypeList, successDeleteTypeList, successTypeList, successUpdateTypeList, updateTypeList,
} from './sliceTypeList';

async function GetTypeList(data: PayloadTypeGetTypeList): Promise<GetAllTypeListApiResponse> {
  const url = `${Config.Lists.getTypeList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllTypeListApiResponse>(url);
  return result?.data;
}

export const epicGetTypeList = (action$: Observable<TypeListActions>) => action$.pipe(
  filter(getTypeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetTypeList) => from(GetTypeList(data)).pipe(
    map((res: GetAllTypeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successTypeList(res?.BMT?.Result);
      }
      return failureTypeList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getTypeListStart.match))),
    catchError((error: AxiosError<GetAllTypeListApiResponse>) => of(failureTypeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateTypeList(data: UpdateTypeListData): Promise<UpdateTypeListApiResponse> {
  const url = `${Config.Lists.updateTypeList}`;
  const result = await makePostRequest<UpdateTypeListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateTypeList = (action$: Observable<TypeListActions>) => action$.pipe(
  filter(updateTypeList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateTypeList(data)).pipe(
    mergeMap((res: UpdateTypeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateTypeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateTypeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateTypeList.match))),
    catchError((error: AxiosError<UpdateTypeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateTypeList(errorMessage));
    }),
  )),
);

async function AddTypeList(data: AddTypeListData): Promise<AddTypeListApiResponse> {
  const url = `${Config.Lists.addTypeList}`;
  const result = await makePostRequest<AddTypeListApiResponse>(url, data);
  return result?.data;
}

export const epicAddTypeList = (action$: Observable<TypeListActions>) => action$.pipe(
  filter(addTypeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddTypeList(data)).pipe(
    mergeMap((res: AddTypeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddTypeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddTypeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addTypeListStart.match))),
    catchError((error: AxiosError<AddTypeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddTypeList(errorMessage));
    }),
  )),
);

async function DeleteTypeList(data: PayloadTypeDeleteTypeList): Promise<DeleteTypeListResponse> {
  const url = `${Config.Lists.deleteTypeList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteTypeListResponse>(url);
  return result?.data;
}

export const epicDeleteTypeList = (action$: Observable<TypeListActions>) => action$.pipe(
  filter(deleteTypeListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteTypeList) => from(DeleteTypeList(data)).pipe(
    mergeMap((res: DeleteTypeListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteTypeList(), getTypeListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteTypeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteTypeListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteTypeListResponse>) => of(failureDeleteTypeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
