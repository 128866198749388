import { DataHeaderCellType } from '../../../../../../utils/interface';

export function getTableHeaderData(): DataHeaderCellType[] {
  const headerData = [
    {
      colWidth: 'wdth-3',
      header: 'SN.',
    },
    {
      colWidth: 'wdth-3',
      header: 'Action',
    },
    {
      colWidth: 'wdth-3',
      header: 'Mfg',
    },
    {
      colWidth: 'wdth-3',
      header: 'Model #',
    },
    {
      colWidth: 'wdth-3',
      header: 'Serial #',
    },
    {
      colWidth: 'wdth-3',
      header: '# Tubes',
    },
    {
      colWidth: 'wdth-3',
      header: 'Analyst',
    },
    {
      colWidth: 'wdth-5',
      header: 'Job Start',
    },
    {
      colWidth: 'wdth-5',
      header: 'Job End',
    },
    {
      colWidth: 'wdth-3',
      header: 'Re Test',
    },
    {
      colWidth: 'wdth-3',
      header: 'Job Order',
    },
    {
      colWidth: 'wdth-3',
      header: 'Vess',
    },
    {
      colWidth: 'wdth-3',
      header: 'Equip',
    },
    {
      colWidth: 'wdth-3',
      header: 'Price',
    },
  ];
  return headerData;
}
