import { OilKitsResponseData, OilKitsData } from './TypeOilKits';

export function formatTableData(data: OilKitsResponseData[]) {
  const res: OilKitsData[] = [];

  data.forEach((col: OilKitsResponseData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete&invoice',
      kits: '',
      cust: '',
      customer: '',
      pd: '',
      shipDate: '',
      billAmt: '',
      billed: false,
      createdBy: '',
      id: '',
      isChecked: false,
    };

    obj.kits = col.KitId;
    obj.id = col.KitId;
    obj.cust = col.CustId;
    obj.customer = col.CustomerName;
    obj.pd = col.PurchaseOrderNumber;
    obj.shipDate = col.ShipDate;
    obj.billAmt = `$ ${col.BillAmount}`;
    obj.billed = col.Billed;
    obj.createdBy = col.CreatedBy;
    res.push(obj);
  });

  return res;
}
