import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddDefectCodeListApiResponse, AddDefectCodeList, DeleteDefectCodeListResponse, GetAllDefectCodeListApiResponse,
  PayloadTypeDeleteDefectCodeList, PayloadTypeGetDefectCodeList, UpdateDefectCodeListApiResponse, UpdateDefectCodeListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addDefectCodeListStart, deleteDefectCodeListStart, failureAddDefectCodeList, failureDeleteDefectCodeList, failureDefectCodeList,
  failureUpdateDefectCodeList, getDefectCodeListStart,
  DefectCodeTypeActions, successAddDefectCodeList, successDeleteDefectCodeList, successDefectCodeList,
  successUpdateDefectCodeList, updateDefectCodeList,
} from './sliceDefectCodeList';

async function GetDefectCodeList(data: PayloadTypeGetDefectCodeList): Promise<GetAllDefectCodeListApiResponse> {
  const url = `${Config.defectCode.getDefectCodeList}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllDefectCodeListApiResponse>(url);
  return result?.data;
}

export const epicGetDefectCodeList = (action$: Observable<DefectCodeTypeActions>) => action$.pipe(
  filter(getDefectCodeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetDefectCodeList) => from(GetDefectCodeList(data)).pipe(
    map((res: GetAllDefectCodeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successDefectCodeList(res?.BMT?.Result);
      }
      return failureDefectCodeList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getDefectCodeListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetAllDefectCodeListApiResponse>) => of(failureDefectCodeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateDefectCodeList(data: UpdateDefectCodeListData): Promise<UpdateDefectCodeListApiResponse> {
  const url = `${Config.defectCode.updateDefectCodeList}`;
  const result = await makePostRequest<UpdateDefectCodeListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateDefectCodeList = (action$: Observable<DefectCodeTypeActions>) => action$.pipe(
  filter(updateDefectCodeList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateDefectCodeList(data)).pipe(
    mergeMap((res: UpdateDefectCodeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateDefectCodeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateDefectCodeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateDefectCodeList.match))),
    catchError((error: AxiosError<UpdateDefectCodeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateDefectCodeList(errorMessage));
    }),
  )),
);

async function AddDefectCodeListApi(data: AddDefectCodeList): Promise<AddDefectCodeListApiResponse> {
  const url = `${Config.defectCode.addDefectCodeList}`;
  const result = await makePostRequest<AddDefectCodeListApiResponse>(url, data);
  return result?.data;
}

export const epicAddDefectCodeList = (action$: Observable<DefectCodeTypeActions>) => action$.pipe(
  filter(addDefectCodeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddDefectCodeListApi(data)).pipe(
    mergeMap((res: AddDefectCodeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddDefectCodeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddDefectCodeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addDefectCodeListStart.match))),
    catchError((error: AxiosError<AddDefectCodeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddDefectCodeList(errorMessage));
    }),
  )),
);

async function DeleteDefectCodeList(data: PayloadTypeDeleteDefectCodeList): Promise<DeleteDefectCodeListResponse> {
  const url = `${Config.defectCode.deleteDefectCodeList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteDefectCodeListResponse>(url);
  return result?.data;
}

export const epicDeleteDefectCodeList = (action$: Observable<DefectCodeTypeActions>) => action$.pipe(
  filter(deleteDefectCodeListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteDefectCodeList) => from(DeleteDefectCodeList(data)).pipe(
    mergeMap((res: DeleteDefectCodeListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteDefectCodeList(), getDefectCodeListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteDefectCodeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteDefectCodeListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteDefectCodeListResponse>) => of(failureDeleteDefectCodeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
