import { AddInfoFormNames, MainFormNames } from './constants';
import { GetInsSummaryResponse } from './types';

export function setValues(inspectionSummaryData:GetInsSummaryResponse, setValue: SetValue) {
  setValue(MainFormNames.reportType, inspectionSummaryData?.ReportType);
  setValue(MainFormNames.Vessel, inspectionSummaryData?.VesselName);
  setValue(MainFormNames.testType, inspectionSummaryData?.TestType);
  setValue(MainFormNames.Defect, inspectionSummaryData?.Defect);
  setValue(MainFormNames.spotTest, inspectionSummaryData?.SpotTest);
  setValue(MainFormNames.Pct, inspectionSummaryData?.Pct);
  setValue(MainFormNames.tubeCount, inspectionSummaryData?.TubeCount);
  setValue(MainFormNames.tubesTested, inspectionSummaryData?.TubesTested);
  setValue(MainFormNames.numberOfTube, inspectionSummaryData?.NoOfTubes);
  setValue(MainFormNames.percentOfBundle, inspectionSummaryData?.PercentOfBundle);
  setValue(MainFormNames.previouslyPlugged, inspectionSummaryData?.PreviouslyPlugged);
  setValue(MainFormNames.Manufacturer, inspectionSummaryData?.Manufacturer);
  setValue(MainFormNames.Model, inspectionSummaryData?.Model);
  setValue(MainFormNames.serialNumber, inspectionSummaryData?.SerialNumber);
  setValue(AddInfoFormNames.Code, inspectionSummaryData?.DefectCode);
  setValue(AddInfoFormNames.comment, inspectionSummaryData?.AdditionalInfo);
  setValue(AddInfoFormNames.infoManufacturer, inspectionSummaryData?.Manufacturer);
  setValue(AddInfoFormNames.infoModel, inspectionSummaryData?.Model);
  setValue(AddInfoFormNames.infoSerialNumber, inspectionSummaryData?.SerialNumber);
}
