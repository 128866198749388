import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';

function ComponentExpenseAnalystTable() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const expenseResponse = reportPdfResponse4?.Expense_Report;
  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        <tr>
          <td className="tableGreenHeading textCenter boldText">
            Analyst
          </td>
          <td className="tableGreenHeading textCenter boldText">
            Total Cash Due
          </td>
          <td className="tableGreenHeading textCenter boldText">
            Tot. Charges
          </td>
          <td className="tableGreenHeading textCenter boldText">
            Tot. Expenses
          </td>
        </tr>
        <tr>
          <td className="textCenter">{reportPdfResponse4?.JobOrderData?.AnalystName}</td>
          <td className="textCenter">{expenseResponse?.TotalCashFields?.TotalCash}</td>
          <td className="textCenter">{expenseResponse?.TotalChequeFields?.TotalCheque}</td>
          <td className="textCenter">
            {Number(expenseResponse?.TotalCashFields?.TotalCash) + Number(expenseResponse?.TotalChequeFields?.TotalCheque)}
          </td>
        </tr>
      </table>
    </div>
  );
}

export default ComponentExpenseAnalystTable;
