import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../redux/rootState';
import { PayloadTypeUnlinkSmc, SMCSubscribedCustomerData } from '../../Utils/TypeSmc';
import { getSmcCustomerListStart, unlinkSmcCustomer } from '../../Redux/sliceAddSmc';
import UnlinkComponentModal from '../../../../Common/UnlinkComponentModal/UnlinkComponentModal';

function SubscribedCustomer() {
  const { smcCustomerList, smcId, totalSubscribedCustomers } = useSelector((state:RootState) => state?.addSmcDetailsReducer);
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      smcId: String(smcId),
      searchQuery,
    };
    if (params.smcId) {
      dispatch(getSmcCustomerListStart(params));
    }
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: translations.sn },
      { key: 'action', label: translations.action },
      { key: 'markUp', label: translations.markup },
      { key: 'cust', label: translations.CustHash },
      { key: 'customer', label: translations.customer },
      { key: 'address', label: translations.address },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
      { key: 'phone', label: translations.phone },
    ],
    [],
  );

  const handleLinkOff = (CustomerId: string) => {
    setShowModal(true);
    setSelectedCustomerId(CustomerId);
  };

  const handleDeleteData = () => {
    const payload:PayloadTypeUnlinkSmc = {
      SmcId: smcId,
      CustomerId: selectedCustomerId,
      page,
      rowsPerPage,
      searchQuery,
    };
    setShowModal(false);
    dispatch(unlinkSmcCustomer(payload));
  };

  const data: SMCSubscribedCustomerData[] = useMemo(() => smcCustomerList?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'linkOff',
      markUp: item?.Markup,
      cust: item?.Custid,
      customer: item?.Customername,
      address: item?.Address,
      city: item?.CityName,
      state: item?.StateName,
      phone: item?.Phone,
      id: item?.Custid,
      customerId: item?.Custid,
    };
    return transformedDataItem;
  }), [smcCustomerList]);

  return (
    <>
      <DataTable<SMCSubscribedCustomerData>
        data={data}
        columns={columns}
        totalRecords={totalSubscribedCustomers}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleLinkOff={handleLinkOff}
      />

      <UnlinkComponentModal
        show={showModal}
        setOpenFrom={() => setShowModal(false)}
        handleUnlink={handleDeleteData}
      />
    </>

  );
}

export default SubscribedCustomer;
