import { DataHeaderCellType } from '../../../utils/interface';

export function getTableHeaderData(): DataHeaderCellType[] {
  const headerData = [
    {
      colWidth: 'wdth-3',
      header: 'SN.',
    },
    {
      colWidth: 'wdth-3',
      header: 'Action',
    },
    {
      colWidth: 'wdth-5',
      header: 'Mark Up',
    },
    {
      colWidth: 'wdth-6',
      header: 'Cust #',
    },
    {
      colWidth: 'wdth-10',
      header: 'Company',
    },
    {
      colWidth: 'wdth-10',
      header: 'Address',
    },
    {
      colWidth: 'wdth-10',
      header: 'City',
    },
    {
      colWidth: 'wdth-5',
      header: 'State',
    },
    {
      colWidth: 'wdth-6',
      header: 'Phone',
    },
    {
      colWidth: 'wdth-5',
      header: 'SMC',
    },
  ];
  return headerData;
}
