import { DataHeaderCellType } from '../../../../../../utils/interface';

export function getTableHeaderData(): DataHeaderCellType[] {
  const headerData = [
    {
      colWidth: 'wdth-3',
      header: 'SN.',
    },
    {
      colWidth: 'wdth-3',
      header: 'Action',
    },
    {
      colWidth: 'wdth-3',
      header: 'Accommodation',
    },
    {
      colWidth: 'wdth-3',
      header: 'City',
    },
    {
      colWidth: 'wdth-3',
      header: 'Phone',
    },
    {
      colWidth: 'wdth-3',
      header: 'Confirmation',
    },
    {
      colWidth: 'wdth-3',
      header: 'Trip #',
    },
  ];
  return headerData;
}
