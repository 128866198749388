import { JobData, JobsTableData } from './types';

export function formatJobsData(data: JobData[]) {
  const res:JobsTableData[] = [];

  if (data.length === 0) return [];

  data.forEach((col:JobData, index) => {
    const obj = {
      sn: index,
      action: 'edit&delete',
      jobId: '',
      custId: '',
      customer: '',
      site: '',
      state: '',
      city: '',
      id: '',
    };

    obj.jobId = col.JobId;
    obj.custId = col.CustId;
    obj.customer = col.CustomerName;
    obj.site = col.SiteName;
    obj.city = col.CityName;
    obj.state = col.StateName;
    obj.id = col.JobId;

    res.push(obj);
  });

  return res;
}
