const localStorageConstants = {
  USER_ID: 'userId',
  TOKEN: 'token',
  REFRESH_TOKEN: 'refreshToken',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE_ID: 'roleId',
};

export const sessionStorageConstants = {
  SubMenuKeyID: 'SubMenuKeyID',
};

export default localStorageConstants;
