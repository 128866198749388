/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import RequiredFiled from '../../../../Common/RequiredField';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import password_hide_icon from '../../../../assets/password_hide_icon.svg';
import password_show_icon from '../../../../assets/password_show_icon.svg';
import { RootState } from '../../../../redux/rootState';
import { AddUserManagementFields, AddUserManagementFieldsEnum, AddUserManagementTypes } from '../../utils/types';
import { addUserManagementSchema } from '../../utils/validation';
import { onCreateUser } from '../../redux/sliceUserManagement';
import { AddUpdateApiType, initialAddUserManagementFields } from '../../utils/constant';
import { MasterData } from '../../../../utils/type';
import { alphabeticCharactersRegex } from '../../../../utils/regex';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function AddUserManagement({
  show, setOpenFrom, rowId, handleCallback,
}: AddUserManagementTypes): JSX.Element {
  const {
    control, formState: { errors }, reset, watch, handleSubmit, setValue, trigger,
  } = useForm<AddUserManagementFields>({
    defaultValues: initialAddUserManagementFields,
    resolver: yupResolver(addUserManagementSchema as ObjectSchema<AddUserManagementFields>),
  });
  const dispatch = useDispatch();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [analystList, setAnalystList] = useState<MasterData[]>([]);
  const { rolesList } = useSelector((state: RootState) => state.masterDataReducer);
  const { userManagementListData } = useSelector((state: RootState) => state.userManagementReducer);
  const watchRole = watch(AddUserManagementFieldsEnum.role);

  const endAdornmentNewPassword = {
    endAdornment: (
      <div className="password_show_hide">
        <IconButton
          onClick={() => {
            setShowNewPassword(!showNewPassword);
          }}
        >
          {showNewPassword ? (
            <img
              src={password_show_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          ) : (
            <img
              src={password_hide_icon}
              alt="Icon"
              width={100}
              height={100}
            />
          )}
        </IconButton>
      </div>
    ),
  };
  const handleClose = () => {
    setOpenFrom();
    reset();
  };
  const setFormValues = () => {
    const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
    if (selectedRow?.UserId) {
      setValue(AddUserManagementFieldsEnum.userId, selectedRow.Email);
      setValue(AddUserManagementFieldsEnum.initials, selectedRow.Initials);
      setValue(AddUserManagementFieldsEnum.password, selectedRow.Password);
      setValue(AddUserManagementFieldsEnum.role, String(selectedRow?.RoleId));
      setValue(AddUserManagementFieldsEnum.analyst, selectedRow.AnalystId);
      trigger(AddUserManagementFieldsEnum.role);
    }
  };
  useEffect(() => {
    if (show) {
      if (rowId && rowId !== '') {
        setFormValues();
        return;
      }
      reset();
    }
  }, [show]);

  useEffect(() => {
    if (rolesList?.AnalystMaster?.length > 0) {
      if (show && rowId && rowId !== '') {
        const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
        if (selectedRow?.UserId) {
          const obj = {
            Value: selectedRow?.AnalystId,
            Text: selectedRow?.AnalystName || selectedRow?.AnalystId,
          };
          const arr: MasterData[] = [obj, ...rolesList.AnalystMaster];
          setAnalystList(arr);
        }
        return;
      }
      setAnalystList(rolesList.AnalystMaster);
    }
  }, [rolesList?.AnalystMaster]);
  const onSubmit = (data: AddUserManagementFields) => {
    const isAnalyst = rolesList?.RoleMaster?.length > 0 && rolesList?.RoleMaster?.find((ele) => ele?.Value?.toString() === watchRole?.toString() && ele?.Text === 'Analyst');
    const payload = {
      Email: data?.userId ?? '',
      Initials: data?.initials ?? '',
      Password: isAnalyst ? data?.password : '',
      RoleId: data?.role ?? '',
      AnalystId: isAnalyst ? (data?.analyst ?? '') : '',
    };
    if (rowId) {
      const updatePayload = {
        ...payload,
        Id: rowId,
      };
      dispatch(onCreateUser({ type: AddUpdateApiType.UPDATE, payload: updatePayload, callback: handleCallback }));
      return;
    }
    const creationPayload = {
      ...payload,
      Id: 0,
    };
    dispatch(onCreateUser({ type: AddUpdateApiType.ADD, payload: creationPayload, callback: handleCallback }));
  };

  const getRoles = useMemo(() => {
    if (rolesList?.RoleMaster?.length > 0) {
      const allRoles = rolesList?.RoleMaster?.map((element) => ({
        Value: element?.Value,
        Text: element?.Text,
      }));
      return allRoles;
    }
    return [];
  }, [rolesList]);

  const showAnalyst = useMemo(() => {
    const isAnalyst = rolesList?.RoleMaster?.length > 0 && rolesList?.RoleMaster?.find((ele) => ele?.Value?.toString() === watchRole?.toString() && ele?.Text === 'Analyst');
    if (rowId) {
      const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
      if (selectedRow?.AnalystId && selectedRow?.AnalystId !== '' && isAnalyst) {
        return true;
      }
    }
    return isAnalyst;
  }, [watchRole]);

  // const disableAnalystDropdown = useMemo(() => {
  //   if (rowId && rowId !== '') {
  //     return true;
  //   }
  //   return false;
  // }, [rowId]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          {rowId ? 'Edit User' : 'Add User'}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    User ID (Email)
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={AddUserManagementFieldsEnum.userId}
                    size="small"
                    id={AddUserManagementFieldsEnum.userId}
                    errors={errors}
                    inputProps={{ maxLength: 64 }}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Initials
                    <RequiredFiled />
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 3 }}
                    name={AddUserManagementFieldsEnum.initials}
                    size="small"
                    id={AddUserManagementFieldsEnum.initials}
                    errors={errors}
                    regEx={alphabeticCharactersRegex}
                  />
                </div>
              </Grid>
              {showAnalyst && (
                <Grid item md={6} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Password
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      className="nonCapitalizeField"
                      control={control}
                      name={AddUserManagementFieldsEnum.password}
                      size="small"
                      id={AddUserManagementFieldsEnum.password}
                      errors={errors}
                      maxTextLength={16}
                      type={showNewPassword ? 'text' : 'password'}
                      inputProps={endAdornmentNewPassword}
                      adornmentProps={false}
                      autoComplete={showNewPassword ? 'off' : 'new-password'}
                    />
                  </div>
                </Grid>
              )}
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Role
                    <RequiredFiled />
                  </label>
                  <ComponentSelectInput
                    control={control}
                    entries={getRoles}
                    name={AddUserManagementFieldsEnum.role}
                    size="small"
                    errors={errors}
                  />
                </div>
              </Grid>
              {showAnalyst && (
                <Grid item md={6} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">
                      Analyst
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInput
                      control={control}
                      entries={analystList?.length > 0 ? analystList : []}
                      name={AddUserManagementFieldsEnum.analyst}
                      size="small"
                      errors={errors}
                      // disabled={disableAnalystDropdown}
                    />
                  </div>
                </Grid>
              )}
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>

            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddUserManagement;
