/* eslint-disable max-len */
import React from 'react';

export function ShuffleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
      <g clipPath="url(#clip0_5988_19007)">
        <path d="M10.885 4.04476L8.13495 1.29471C7.9815 1.14131 7.73276 1.14135 7.57935 1.2948C7.50572 1.36846 7.46435 1.46832 7.46433 1.57248V2.75107H4.71427C4.49729 2.75107 4.3214 2.92697 4.3214 3.14395C4.3214 3.36093 4.49729 3.53682 4.71427 3.53682H7.8572C8.07418 3.53682 8.25007 3.36093 8.25007 3.14395V2.52083L10.0517 4.32251L8.25007 6.12419V5.5011C8.25007 5.28412 8.07418 5.10823 7.8572 5.10823H3.53568V3.92964C3.53564 3.71266 3.3597 3.53682 3.14274 3.53686C3.0386 3.53689 2.93872 3.57825 2.86506 3.65189L0.115033 6.40194C-0.0383445 6.55537 -0.0383445 6.80404 0.115033 6.95745L2.86509 9.7075C2.93875 9.78118 3.03865 9.82259 3.14284 9.82262C3.19447 9.82278 3.24564 9.81263 3.29329 9.79276C3.44006 9.7319 3.53573 9.58865 3.53568 9.42975V8.25116H6.28574C6.50272 8.25116 6.67861 8.07526 6.67861 7.85828C6.67861 7.6413 6.50272 7.46541 6.28574 7.46541H3.14284C2.92586 7.46541 2.74997 7.6413 2.74997 7.85828V8.48137L0.948288 6.67969L2.74997 4.87802V5.5011C2.74997 5.71808 2.92586 5.89397 3.14284 5.89397H7.46433V7.07257C7.46437 7.28955 7.64031 7.46539 7.85727 7.46534C7.96141 7.46532 8.06129 7.42396 8.13495 7.35032L10.885 4.60026C11.0384 4.44684 11.0384 4.19816 10.885 4.04476Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5988_19007">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
