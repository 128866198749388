/* eslint-disable max-len */
import React, {
  useState, ChangeEvent, forwardRef,
  useMemo,
} from 'react';
import {
  Button,
  Dialog, DialogContent, DialogTitle, IconButton, TextField, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'react-redux';
import styles from './pipeStyle.module.scss';
import { BayValuesSet, BaylengthResponse, PipeStructureProps } from '../../utils/type';
import { RootState } from '../../../../redux/rootState';
import ConfirmationModal from '../../../../Common/ConfirmationModal/ConfirmationModal';
import { ConfirmationIcon } from '../../../../assets/Icons/AnalystIcons/ConfirmationIcon';
import { updateBayLengthStart } from '../../redux/baylength/baylengthSlice';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

export default function PipeStructure({
  setOpenFrom,
  show,
}:PipeStructureProps) {
  const {
    bayLengthDetails, jobId, vesselId,
  } = useSelector((state:RootState) => state.BayLengthReducer);

  const [spacingValues, setSpacingValues] = useState(bayLengthDetails.spacingValues);
  const [finalSpacing, setFinalSpacing] = useState(bayLengthDetails.spacingValues);
  const [total, setTotal] = useState(Number(bayLengthDetails?.bayLengthTubes));
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isBayLengthChanged) {
  //     const len = (Number(bayLengthDetails?.bayLengthSupport) || 0) + 1;
  //     const value = (Number(bayLengthDetails?.bayLengthTubes) / len);
  //     const temp = Array(len).fill(value);
  //     setSpacingValues(temp);
  //     setFinalSpacing(temp);
  //   } else {
  //     setSpacingValues(bayLengthDetails?.spacingValues || []);
  //     setFinalSpacing(bayLengthDetails?.spacingValues || []);
  //   }
  //   setTotal(Number(bayLengthDetails?.bayLengthTubes));
  // }, [isBayLengthChanged, bayLengthDetails]);

  const handleBayValueSet = ():BayValuesSet[] => {
    const data:BayValuesSet[] = [];
    let totalSum = 0;
    const n = finalSpacing?.length;

    finalSpacing?.slice(0, n - 1).forEach((value, index) => {
      totalSum += Number(value);
      const obj:BayValuesSet = {
        bayValue: Number(value),
        parentLeftMargin: totalSum,
        childLeftMargin: totalSum - Number(value),
        bayNumber: n - index,
        supportNumber: n - index - 1,
        childWidth: index >= 1 ? Number(value) - 5 : Number(value),
        childMargin: index >= 1 ? '5px' : '',
      };
      data.push(obj);
    });

    return data;
  };

  const bayValuesSet:BayValuesSet[] = useMemo(() => handleBayValueSet(), [finalSpacing]);

  const lastTextField = useMemo(() => Number(finalSpacing?.[finalSpacing.length - 1]), [finalSpacing]);

  const handleSpacingValue = (value:string, index: number) => {
    const temp = [...spacingValues];
    setTotal((prev) => prev - (temp[index]) + Number(value));
    temp[index] = Number(value);
    setSpacingValues(temp);
  };

  const handleGenerate = () => {
    if (total === Number(bayLengthDetails.bayLengthTubes)) {
      setFinalSpacing(spacingValues);
    } else {
      setShowConfirmationModal(true);
    }
  };

  const handleSave = () => {
    if (total !== Number(bayLengthDetails.bayLengthTubes)) {
      setShowConfirmationModal(true);
      return;
    }
    const payload:BaylengthResponse = {
      BayLengthId: `${jobId}-${vesselId}`,
      JobOrder: jobId,
      TubeLength: bayLengthDetails.bayLengthTubes,
      Support: bayLengthDetails.bayLengthSupport,
      Configuration: finalSpacing,
      VesselId: vesselId,
    };

    dispatch(updateBayLengthStart(payload));
    setOpenFrom();
  };

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Bay Lengths
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <div className={styles.divContainer}>
            <div className={styles.innerDiv}>
              <div style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '780px',
              }}
              >
                <div className={styles.testTextStyle}>Opposite Test End</div>
                <div className={styles.container}>
                  <div style={{
                    width: Number(bayLengthDetails.bayLengthTubes), textAlign: 'center', marginBottom: '45px', borderBottom: '3px solid black',
                  }}
                  >
                    {`${Number(bayLengthDetails.bayLengthTubes)}"`}
                  </div>
                  <div className={`${styles.pipe} ${styles.horizontal}`} style={{ width: Number(bayLengthDetails.bayLengthTubes) }}>
                    {bayValuesSet.map((valueSet:BayValuesSet) => (
                      <>
                        <div
                          key={`${valueSet.bayValue}-${valueSet.supportNumber}`}
                          className={styles.verticalLine}
                          style={{ left: valueSet.parentLeftMargin }}
                        />
                        <span style={{ width: valueSet.childWidth, left: valueSet.childLeftMargin, marginLeft: valueSet.childMargin }} className={styles.AllText}>
                          {valueSet.bayValue.toFixed(2)}
                        </span>
                        <span style={{ width: valueSet.childWidth, left: valueSet.childLeftMargin, marginLeft: valueSet.childMargin }} className={styles.bayLabel}>{`Bay ${valueSet.bayNumber}`}</span>
                        <span style={{ width: valueSet.childWidth, left: valueSet.childLeftMargin, marginLeft: valueSet.childMargin }} className={styles.SupportLabel}>{`Support #${valueSet.supportNumber}-->`}</span>
                      </>
                    ))}
                  </div>
                  <span className={styles.lastText} style={{ width: lastTextField - 21 }}>{`${lastTextField?.toFixed(2)}"`}</span>
                  <span className={styles.lastBayLabel} style={{ width: lastTextField - 21 }}>Bay 1</span>
                </div>
                <div className={styles.testTextStyle}>Test End</div>
              </div>
              <div style={{ marginTop: '100px', display: 'flex', width: '819px' }}>
                {spacingValues?.map((value, index) => (
                  <div key={`${value}-${index + 1}`} style={{ paddingRight: '10px' }}>
                    <TextField
                      autoFocus
                      size="small"
                      type="number"
                      InputProps={{ inputProps: { min: 0 } }}
                      style={{ backgroundColor: 'white', width: '110px' }}
                      value={spacingValues?.[index]}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleSpacingValue((e.target.value), index);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.BayDiv}>
              <TextField
                size="small"
                style={{ backgroundColor: 'white', width: '110px' }}
                value={total}
              />
              <div className={styles.BayLengthBtn}>
                <div className="save_and_next_div">
                  <Button onClick={handleSave} className="button_save_and_next">Save & Next</Button>
                </div>
                <div className={styles.BayGenBtn}>
                  <Button onClick={handleGenerate} className="button_save_and_next">Generate</Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {
        showConfirmationModal && (
          <ConfirmationModal
            confirmationIcon={<ConfirmationIcon />}
            show={showConfirmationModal}
            setOpenFrom={() => setShowConfirmationModal(false)}
            readOnly
            text="Total sum is not equal to tube length, Please check."
          />
        )
      }
    </div>
  );
}
