/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useContext, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../redux/rootState';
import { MarkupsLIstData, UpdateMarkupsData } from '../utils/TypesMarkupsList';
import { setLoader } from '../../../../redux/loaderSlice';
import { getSysMarkupStart, resetState, updateMarkups } from '../Redux/sliceMarkups';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { MarkupFormValue } from '../utils/ConstantsMarkup';
import { markupSchema } from '../utils/validation';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);
function ComponentEditMarkupsDialog({ show, setOpenFrom, rowId }: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);

  const dispatch = useDispatch();

  const {
    handleSubmit, control, setValue, formState: { errors }, reset,
  } = useForm<UpdateMarkupsData>({
    resolver: yupResolver(markupSchema as ObjectSchema<UpdateMarkupsData>),
  });

  const {
    SystemMarkup, isLoading, isSuccess, tableStaticData,
  } = useSelector((state: RootState) => state.SystemMarkupReducer);

  const tableData: MarkupsLIstData[] = SystemMarkup?.Data?.map((item, index) => {
    const itemIndex = index;
    const transformedDataItem = {
      sn: itemIndex + 1,
      action: 'edit&delete',
      Description: item.Description,
      Percentage: item.Percentage,

      id: String(item?.Id),
    };
    return transformedDataItem;
  });

  useEffect(() => {
    if (isSuccess) {
      const params = {
        page: tableStaticData?.page,
        rowsPerPage: tableStaticData?.rowsPerPage,
        searchQuery: tableStaticData?.searchQuery,
      };
      dispatch(getSysMarkupStart(params));
      dispatch(resetState());
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (rowId) {
      tableData?.forEach((rowData) => {
        if (rowData?.id === rowId) {
          setValue(MarkupFormValue.DESCRIPTION, rowData.Description);
          setValue(MarkupFormValue.PERCENTAGE, rowData.Percentage);
        }
      });
    }
  }, [rowId, show]);

  const onSubmit = (data: UpdateMarkupsData) => {
    const bodyData = {
      Id: rowId,
      Description: data.Description,
      Percentage: data.Percentage,
    };

    dispatch(updateMarkups(bodyData));
    setOpenFrom();
  };

  const handleClose = () => {
    setOpenFrom();
    reset();
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          {translations.editMarkup}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">{translations.description}</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    name={MarkupFormValue.DESCRIPTION}
                    size="small"
                    id={MarkupFormValue.DESCRIPTION}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">{translations.percentage}</label>
                  <div className="percentage_div">
                    <ComponentTextInput
                      type="number"
                      control={control}
                      name={MarkupFormValue.PERCENTAGE}
                      size="small"
                      id={MarkupFormValue.PERCENTAGE}
                      errors={errors}
                      className="width-100"
                    />
                    <div className="percent">%</div>
                  </div>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentEditMarkupsDialog;
