import React from 'react';
import AbbreviationsTable from './abbreviationsTable';

function AbbreviationsSection() {
  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="underlineHeadingText textCenter">Explanation of Abbreviations</div>
      </div>
      <AbbreviationsTable />
    </div>
  );
}

export default AbbreviationsSection;
