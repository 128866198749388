import React, { forwardRef, useContext, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { TypesDialogBox } from '../../../utils/interface';
import warningLogo from '../../../assets/warning.svg';
import styles from '../styles.module.scss';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { logoutStateReset, sendLogoutData } from '../redux/sliceLogout';
import { RootState } from '../../../redux/rootState';
import EndPoints from '../../../Routes/EndPoints';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogBoxLogout({ show, setOpenFrom }: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const {
    isLoading, isError, isSuccess, errorMessage,
  } = useSelector((state: RootState) => state.logoutReducer);
  const dispatch = useDispatch();
  const handleLogout = () => {
    const userId = localStorage.getItem('userId');
    const data = {
      UserId: userId,
    };
    dispatch(sendLogoutData(data));
  };

  useEffect(() => {
    if (isSuccess) {
      localStorage.clear();
      navigate(EndPoints.LOGIN_PAGE);
      dispatch(logoutStateReset());
      setOpenFrom();
    }
  }, [isSuccess]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent_style">
          <div className={styles.main_container}>
            <div className={styles.first_div}>
              <img src={warningLogo} alt="Warning" height={100} width={100} />
              <h2 className="logoutHeading">{translations.wantLogout}</h2>
              <div className="errorMessage">{isError ? errorMessage : ''}</div>
            </div>
            <div className={styles.second_div}>
              <div>
                <LoadingButton
                  variant="contained"
                  className={styles.logout_btn}
                  onClick={handleLogout}
                  loading={isLoading}
                >
                  {translations.yesLogout}
                </LoadingButton>
              </div>
              <div className="button_margin_left">
                <Button className={styles.logout_cancel_btn} onClick={setOpenFrom}>{translations.cancel}</Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxLogout;
