import React, { useEffect, useMemo, useRef } from 'react';
import {
  Grid, Tab, Tabs,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentUnitDetails from './Components/unitDetailsSideBar/ComponentUnitDetails';
import ScreenVessels from './Components/vessels';
// import ComponentTable from '../../ComponentTable/ComponentTable';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import './index.module.scss';
import ComponentUnitTest from './Components/unitInfo';
import { RootState } from '../../../redux/rootState';
import {
  getUnitByIdStart, getUnitPriceStart, resetUnitInfo, setSelectedTab,
} from './redux/unitInfoSlice';
import ComponentEquipemtDelails from './Components/equipmentdetails/ComponentEquipmentDetails';
import { UnitPricePayload } from './utils/type';
import { setLoader } from '../../../redux/loaderSlice';
import { MasterData } from '../../../utils/type';
import { JobIcon } from '../../../assets/NavBarIcons';
import ComponentBreadcrumb from '../../../Common/ComponentBreadcrumb/ComponentBreadcrumb';

export default function ScreenUnitInformationTabs() {
  const {
    selectedTab, numberOfTubes, unitInfo, tubeLength, isLoading, unitId: UnitID, unitMaster,
  } = useSelector((state: RootState) => state?.unitInfoReducer);
  const { selectedUnit } = useSelector((state: RootState) => state.unitInfoReducer);

  const bidID = selectedUnit?.UnitInfo?.BidId;
  const fullString = selectedUnit?.UnitId || '';
  const parts = fullString.split('-');
  const jobID = parts[0];

  const targetDivRef = useRef<HTMLDivElement | null>(null);
  const goToTop = () => {
    const targetDiv = targetDivRef.current;
    targetDiv?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const breadcrumbs = [
    { label: 'Job', url: `/jobs/${jobID || ''}`, icon: <JobIcon /> },
    { label: 'Bids', url: `/jobs/bid-worksheet/${bidID || ''}`, icon: <JobIcon /> },
    { label: 'Units', url: '/unit', icon: <JobIcon /> },
  ];

  const TABS = useMemo(() => [
    {
      id: 1, label: 'Unit Info', component: <ComponentUnitTest scrollTop={() => goToTop()} />, disabled: false,
    },
    {
      id: 2, label: 'Vessels', component: <ScreenVessels />, disabled: false,
    },
    {
      id: 3, label: 'Req Equipment', component: <ComponentEquipemtDelails />, disabled: !UnitID,
    },
  ], [UnitID, unitInfo]);

  const dispatch = useDispatch();
  const { unitId } = useParams();

  useEffect(() => () => {
    dispatch(resetUnitInfo());
  }, []);

  useEffect(() => {
    if (unitId) {
      dispatch(getUnitByIdStart(String(unitId)));
    }
  }, [unitId]);

  const handleChangeTab = (val: number) => {
    dispatch(setSelectedTab(val));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (numberOfTubes > 0) {
      const machine = unitMaster?.Machine?.find((ele: MasterData) => ele.Value === String(unitInfo.machineType));
      const payload: UnitPricePayload = {
        TestType: unitInfo.testType || '0',
        MachineType: machine?.Text || '',
        TotalTubeQty: numberOfTubes || 0,
        TubeLength: tubeLength || 0,
        OD: unitInfo.odTubes || 0,
        SpotTest: unitInfo.pct > 0 ? unitInfo.spotTest : false,
        SafetyCharge: unitInfo.safetyCharges || false,
        SpotTestPercentage: unitInfo.pct || 0,
      };
      dispatch(getUnitPriceStart(payload));
    }
  }, [numberOfTubes, tubeLength, unitInfo.testType, unitInfo.machineType,
    unitInfo.odTubes, unitInfo.spotTest, unitInfo.safetyCharges, unitInfo.pct]);

  return (
    <>
      <ComponentPageHeader subHeading="UNIT INFORMATION" fieldId={UnitID || ''} fieldName="Unit ID" />
      <ComponentBreadcrumb crumbs={breadcrumbs} />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab - 1}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              disabled={tab.disabled}
              style={selectedTab === tab.id ? selectedTabStyles : tabStyles}
              className="tab_styles"
              onClick={goToTop}
            />
          ))}
        </Tabs>
      </div>
      <div className="div_job_new" ref={targetDivRef}>
        <div className="div_container_CustomerInfo mt-2">
          <Grid container spacing={2}>
            <Grid item md={8} sm={12} xs={12}>
              {TABS[selectedTab - 1]?.component}
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <ComponentUnitDetails />
            </Grid>
          </Grid>

        </div>
      </div>
    </>
  );
}
