import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import axios, { AxiosError } from 'axios';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../Common/Config';
import {
  makeDeleteRequest,
  makeGetRequest, makePostRequest, makePutRequest,
} from '../../../Common/NetworkOps';
import StorageUtils from '../../../utils/StorageUtils';
import { COMMENT_TAB, CUSTOMER_ID } from '../utils/constants';
import {
  AddCustomerApiResponse,
  BidsResponse,
  CommentApiPayload,
  ContactDetails, CustomerDetails, DeleteApiResponse,
  DeleteBidApiResponse,
  EditCustomerResponseFormat, GetContactByIdApiRes, GetContactByIdPayload, GetContactsQuery,
  GetCustomerByIdPayload,
  GetCustomerByIdResponse, GetJobOrderListingPayload, GetJobOrderResponse, GetResponseFormat, PayloadTypeDeleteBid, PayloadTypeGetBidsStart,
  ResponseFormat, ThemeLogoApiResponseTypes, ThemePayload, UpdateCommentApiResponse,
} from '../utils/types';
import {
  addCommentFailure,
  addCommentStart,
  addCommentSuccess,
  addContactFailure,
  addContactStart,
  addContactSuccess,
  AddCustomerActions, addCustomerFailure, addCustomerStart,
  addCustomerSuccess, deleteBidStart, deleteContactFailure, deleteContactStart, deleteContactSuccess,
  editContactFailure, editContactStart, editContactSuccess, editCustomerFailure, editCustomerStart,
  editCustomerSuccess, failureCreateTheme, failureDeleteBid, failureGetBids, failureUploadLogo, getBidsStart,
  getBillPinCodeDetailsFailure,
  getBillPinCodeDetailsStart,
  getBillPinCodeDetailsSuccess,
  getContactByIdFailure, getContactByIdStart,
  getContactByIdSuccess, getContactsStart,
  getContactsSuccess, getCustomerByIdFailure, getCustomerByIdStart, getCustomerByIdSuccess,
  getCustomerJobOrderData,
  getCustomerJobOrderDataFailure,
  getCustomerJobOrderDataSuccess,
  getOtherCustomerByIdSuccess,
  setSelectedTab,
  startCreateTheme,
  startUploadLogo,
  successCreateTheme,
  successDeleteBid,
  successGetBids,
  successUploadLogo,
  updateCommentFailure, updateCommentStart, updateCommentSuccess,
} from './addCustomerSlice';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { PinCodeApiResponse, PinCodeDetailsQuery } from '../../../utils/type';
import { createFetchDataEpic } from '../../../utils/CommonEpic';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';

// Add Customer
async function addCustomer(data: CustomerDetails): Promise<AddCustomerApiResponse> {
  const url = `${Config.auth.addCustomer}`;
  const result = await makePostRequest<AddCustomerApiResponse>(url, data);
  return result.data;
}

export const addCustomersEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(addCustomerStart.type),
  map((x) => x.payload),
  mergeMap((data: CustomerDetails) => from(addCustomer(data)).pipe(
    mergeMap((res: AddCustomerApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        const payload:GetCustomerByIdPayload = {
          customerId: res.BMT.Result.CustomerId,
          otherCustomer: false,
        };
        StorageUtils.setString(CUSTOMER_ID, res.BMT.Result.CustomerId);
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(addCustomerSuccess(res.BMT.Result), getCustomerByIdStart(payload), setSelectedTab(COMMENT_TAB));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(addCustomerFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addCustomerStart.match))),
    catchError((error) => of(addCustomerFailure(error))),
  )),
);

// Get Customer
async function getCustomerById(data: GetCustomerByIdPayload): Promise<GetCustomerByIdResponse> {
  const url = `${Config.auth.getCustomerById}/${data.customerId}`;
  const result = await makeGetRequest<GetCustomerByIdResponse>(url);
  return result.data;
}

export const getCustomerByIdEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(getCustomerByIdStart.type),
  map((x) => x.payload),
  mergeMap((data: GetCustomerByIdPayload) => from(getCustomerById(data)).pipe(
    map((res: GetCustomerByIdResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        if (data.otherCustomer) {
          return getOtherCustomerByIdSuccess(res.BMT.Result);
        }
        return getCustomerByIdSuccess(res.BMT.Result);
      }
      return getCustomerByIdFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getCustomerByIdStart.match))),
    catchError((error) => of(getCustomerByIdFailure(error))),
  )),
);

// Edit Customer
async function editCustomer(data: CustomerDetails): Promise<EditCustomerResponseFormat> {
  const url = `${Config.auth.editCustomer}`;
  const result = await makePutRequest<EditCustomerResponseFormat>(url, data);
  return result.data;
}

export const editCustomersEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(editCustomerStart.type),
  map((x) => x.payload),
  mergeMap((data: CustomerDetails) => from(editCustomer(data)).pipe(
    mergeMap((res: EditCustomerResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:GetCustomerByIdPayload = {
          customerId: data.CustomerId,
          otherCustomer: false,
        };
        return of(editCustomerSuccess(), getCustomerByIdStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(editCustomerFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(editCustomerStart.match))),
    catchError((error) => of(editCustomerFailure(error))),
  )),
);

// Add Contact
async function addContact(data: ContactDetails): Promise<ResponseFormat> {
  const url = `${Config.auth.addContact}`;
  const result = await makePostRequest<ResponseFormat>(url, data);
  return result.data;
}

export const addContactEpic = (action$: Observable<AddCustomerActions>) => action$.pipe(
  ofType(addContactStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: ContactDetails) => from(addContact(data)).pipe(
    mergeMap((res: ResponseFormat) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        const payload = {
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
          customerId: data.CustomerId,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(addContactSuccess(), getContactsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(addContactFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addContactStart.match))),
    catchError((error) => of(addContactFailure(error))),
  )),
);

// get Contacts
async function getContacts(data:GetContactsQuery): Promise<GetResponseFormat> {
  // eslint-disable-next-line max-len
  const url = `${Config.auth.getContacts}?searchItem=${data.searchQuery}&CustomerId=${data.customerId}&PageNumber=${data.page}&PageSize=${data.rowsPerPage}&Type=1`;
  const result = await makeGetRequest<GetResponseFormat>(url);
  return result.data;
}

export const getContactsEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(getContactsStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:GetContactsQuery) => from(getContacts(data)).pipe(
    map((res: GetResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getContactsSuccess(res.BMT.Result);
      }
      return addContactFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(addContactStart.match))),
    catchError((error) => of(addContactFailure(error))),
  )),
);

// Edit contact
async function editContact(data: ContactDetails): Promise<ResponseFormat> {
  const url = `${Config.auth.editContact}`;
  const result = await makePutRequest<ResponseFormat>(url, data);
  return result.data;
}

export const editContactEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(editContactStart.type),
  map((x) => x.payload),
  mergeMap((data: ContactDetails) => from(editContact(data)).pipe(
    mergeMap((res: ResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
          customerId: data.CustomerId,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(editContactSuccess(), getContactsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(editContactFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(editContactStart.match))),
    catchError((error) => of(editContactFailure(error))),
  )),
);

// Delete Contact
async function deleteContact(data: GetContactByIdPayload): Promise<DeleteApiResponse> {
  const url = `${Config.auth.deleteContact}?ContactId=${data.contactId}&Custid=${data.customerId}`;
  const result = await makeDeleteRequest<DeleteApiResponse>(url);
  return result.data;
}

export const deleteContactEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(deleteContactStart.type),
  map((x) => x.payload),
  mergeMap((data:GetContactByIdPayload) => from(deleteContact(data)).pipe(
    mergeMap((res: DeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload = {
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
          customerId: data.customerId,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(deleteContactSuccess(), getContactsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(deleteContactFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteContactStart.match))),
    catchError((error) => of(deleteContactFailure(error))),
  )),
);

// Get Contact By Id
async function getContactById(contactId: string): Promise<GetContactByIdApiRes> {
  const url = `${Config.auth.getContactById}/${contactId}`;
  const result = await makeGetRequest<GetContactByIdApiRes>(url);
  return result.data;
}

export const getContactByIdEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(getContactByIdStart.type),
  map((x) => x.payload),
  mergeMap((data:string) => from(getContactById(data)).pipe(
    map((res: GetContactByIdApiRes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getContactByIdSuccess(res.BMT.Result);
      }
      return getContactByIdFailure(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getContactByIdStart.match))),
    catchError((error) => of(getContactByIdFailure(error))),
  )),
);

// Add Comment
async function addComment(data: CommentApiPayload): Promise<DeleteApiResponse> {
  const url = `${Config.auth.addComment}`;
  const result = await makePostRequest<DeleteApiResponse>(url, data);
  return result.data;
}

export const addCommentEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(addCommentStart.type),
  map((x) => x.payload),
  mergeMap((data:CommentApiPayload) => from(addComment(data)).pipe(
    mergeMap((res: DeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:GetCustomerByIdPayload = {
          customerId: data.CustomerId,
          otherCustomer: false,
        };
        return of(addCommentSuccess(), getCustomerByIdStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(addCommentFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addCommentStart.match))),
    catchError((error) => of(addCommentFailure(error))),
  )),
);

// Update Comment
async function updateComment(data: CommentApiPayload): Promise<UpdateCommentApiResponse> {
  const url = `${Config.auth.updateComment}`;
  const result = await makePutRequest<UpdateCommentApiResponse>(url, data);
  return result.data;
}

export const updateCommentEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(updateCommentStart.type),
  map((x) => x.payload),
  mergeMap((data:CommentApiPayload) => from(updateComment(data)).pipe(
    mergeMap((res: UpdateCommentApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payload:GetCustomerByIdPayload = {
          customerId: data.CustomerId,
          otherCustomer: false,
        };
        return of(updateCommentSuccess(res.BMT.Result), getCustomerByIdStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(updateCommentFailure(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateCommentStart.match))),
    catchError((error) => of(updateCommentFailure(error))),
  )),
);

async function GetBidsList(data: PayloadTypeGetBidsStart): Promise<BidsResponse> {
  const url = `${Config.bids.listBid}?CustId=${data.customerId}&pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<BidsResponse>(url);
  return result?.data;
}

export const epicGetBidsList = (action$: Observable<AddCustomerActions>) => action$.pipe(
  filter(getBidsStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetBidsStart) => from(GetBidsList(data)).pipe(
    map((res: BidsResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetBids(res?.BMT.Result);
      }
      return failureGetBids(res.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getBidsStart.match))),
    catchError((error: AxiosError<BidsResponse>) => of(failureGetBids(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function DeleteSmc(data: PayloadTypeDeleteBid): Promise<DeleteBidApiResponse> {
  const url = `${Config.bids.deleteBidById}?&BidId=${data.bidId}`;
  const result = await makeDeleteRequest<DeleteBidApiResponse>(url);
  return result?.data;
}

export const epicDeleteBid = (action$: Observable<AddCustomerActions>) => action$.pipe(
  filter(deleteBidStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteBid) => from(DeleteSmc(data)).pipe(
    mergeMap((res: DeleteBidApiResponse) => {
      const payload = {
        customerId: data?.customerId,
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successDeleteBid(), getBidsStart(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureDeleteBid(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteBidStart.match))),
    catchError((error: AxiosError<DeleteBidApiResponse>) => of(failureDeleteBid(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function uploadThemeLogoApi(data:FormData) {
  const customerId = data.get('CustomerId');
  const token = localStorage.getItem(localStorageConstants.TOKEN);
  const userID = localStorage.getItem(localStorageConstants.USER_ID);
  const axiosConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `bearer ${token}`,
      LoginUserId: userID,
    },
  };
  const url = `${Config.themes.uploadThemeLogo}${customerId}`;
  const result = await axios.post(url, data, axiosConfig);
  return result.data;
}

export const epicUpdateLogoData = (action$: Observable<AddCustomerActions>) => action$.pipe(
  filter(startUploadLogo.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(uploadThemeLogoApi(data)).pipe(
    map((res: ThemeLogoApiResponseTypes) => {
      if (res?.BMT?.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster('Theme logo is successfully uploaded');
        return successUploadLogo(res?.BMT?.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return failureUploadLogo();
    }),
    takeUntil(action$.pipe(filter(startUploadLogo.match))),
    catchError((error: AxiosError<ThemeLogoApiResponseTypes>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      showErrorToaster(errorMessage);
      return of(failureUploadLogo());
    }),
  )),
);

async function addTheme(data: ThemePayload): Promise<AddCustomerApiResponse> {
  const url = `${Config.themes.createTheme}`;
  const result = await makePostRequest<AddCustomerApiResponse>(url, data);
  return result.data;
}

export const addThemeEpic = (action$ : Observable<AddCustomerActions>) => action$.pipe(
  ofType(startCreateTheme.type),
  map((x) => x.payload),
  mergeMap((data: ThemePayload) => from(addTheme(data)).pipe(
    mergeMap((res: AddCustomerApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        const payload:GetCustomerByIdPayload = {
          customerId: data.CustId,
          otherCustomer: false,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successCreateTheme(), getCustomerByIdStart(payload));
      } if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const createPayload:GetCustomerByIdPayload = {
          customerId: data.CustId,
          otherCustomer: false,
        };
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(successCreateTheme(), getCustomerByIdStart(createPayload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureCreateTheme());
    }),
    takeUntil(action$.pipe(filter(startCreateTheme.match))),
    catchError((error: AxiosError<ThemeLogoApiResponseTypes>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      showErrorToaster(errorMessage);
      return of(failureCreateTheme());
    }),
  )),
);

async function getBillPinCodeDetails(data: PinCodeDetailsQuery): Promise<PinCodeApiResponse> {
  const url = `${Config.auth.getPincodeDetails}?PinCode=${data.pinCode}&CountryCode=${data.countryCode}`;
  const result = await makeGetRequest<PinCodeApiResponse>(url);
  return result.data;
}

export const getBillingPinCodeDetailsEpic = (action$: Observable<AddCustomerActions>) => action$.pipe(
  ofType(getBillPinCodeDetailsStart.type),
  debounceTime(500),
  map((x) => x.payload),
  mergeMap((data: PinCodeDetailsQuery) => from(getBillPinCodeDetails(data)).pipe(
    map((res: PinCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getBillPinCodeDetailsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getBillPinCodeDetailsFailure();
    }),
    takeUntil(action$.pipe(filter(getBillPinCodeDetailsStart.match))),
    catchError((error) => of(getBillPinCodeDetailsFailure(error))),
  )),
);

function CustomerJobOrderListUrl(payload: GetJobOrderListingPayload): string {
  // eslint-disable-next-line max-len
  return `${Config.auth.CustomerJobOrderURL}?CustomerId=${payload.custId}&pageNumber=${payload.page}&pageSize=${payload.rowsPerPage}&searchItem=${payload.searchQuery}`;
}

// Defect Listing Epic
export const getCustomerJobOrderEpic = createFetchDataEpic<GetJobOrderResponse, GetJobOrderListingPayload>(
  getCustomerJobOrderData.type,
  CustomerJobOrderListUrl,
  getCustomerJobOrderDataSuccess as Empty,
  getCustomerJobOrderDataFailure,
);
