import React, {
  useMemo, useContext, useState,
} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Cancel } from '../../media/images/Cancel';
import { LocalizationContext } from '../Localization/LocalizationProvider';
import './Style.scss';
import DataTable from '../DataTable';
import EndPoints from '../../Routes/EndPoints';
import { AddStartupTextModalProps, StartTextData } from './type';
import { GetStartupTextListRes } from '../../Screens/ScreenRecommendations/utils/types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  // height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  height: 'auto',
  '@media (max-width: 768px)': {
    p: '6px 6px 15px',
    height: 'auto',
  },
};
export default function AddStartupTextModal({
  show, handleClose, handleTabCell, data,
}: AddStartupTextModalProps) {
  const [selectedRadio, setSelectedRadio] = useState('');
  const navigate = useNavigate();

  const { translations } = useContext(LocalizationContext);
  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'text', label: 'Start Text' },
    ],
    [],
  );
  const handleButtonClick = () => {
    navigate(EndPoints.ADD_CUSTOMER);
  };

  const tableData:StartTextData[] = useMemo(() => data.map((entry:GetStartupTextListRes) => (
    {
      id: entry.Value,
      text: entry.Text,
    }
  )), [data]);

  const handleSave = () => {
    handleTabCell(selectedRadio);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">
              Add Start Text
            </div>
            <div className="cancel-icon" onClick={handleClose}>
              <Cancel />
            </div>
          </div>
          <DataTable<StartTextData>
            handleButtonClick={handleButtonClick}
            data={tableData}
            columns={columns}
            totalRecords={data.length}
            handleData={() => {}}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            radioButtonColumns={['select']}
          />
          <br />
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
              </div>
              <div className="button_margin_left">
                <Button className="button_save_and_next" onClick={handleSave}>{translations.select}</Button>
              </div>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
