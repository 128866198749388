import {
  Button, Card, Grid, TextField,
} from '@mui/material';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import EndPoints from '../../../../Routes/EndPoints';
import { ResultFieldLabel, ResultFields } from '../../utils/constant';
import { MainFields, ResultFormProps } from '../../utils/types';

export function Result({
  control, errors, handleSubmit, onSubmit,
}: ResultFormProps) {
  const requiredFields:string[] = useMemo(() => [], []);

  const resultFields:MainFields[] = useMemo(() => {
    const filteredArray = Object.entries(ResultFieldLabel).filter((_, index) => index !== 0 && index !== 10);
    return filteredArray.map(([field, label]) => ({
      field,
      label,
      isRequired: requiredFields.includes(field),
    }));
  }, []);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(`/${EndPoints.OIL_REPORT}`);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div className="customerInfo_container">
          <div className="customerInfo" />
          <div className="customer_info_checkbox dropdown_side_label">
            <div className="customer_info_header_checkbox">
              <CustomCheckbox
                name={ResultFields.OilChanged}
                control={control}
                label={ResultFieldLabel[ResultFields.OilChanged]}
              />
            </div>
          </div>
        </div>
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            {resultFields.map((entry:MainFields) => (
              <Grid key={entry.field} item xs={12} sm={12} md={6} lg={5} xl={4}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">{entry.label}</label>
                  <ComponentTextInput
                    control={control}
                    required={entry.isRequired}
                    capitalizeFirst
                    errors={errors}
                    inputProps={{ maxLength: 30 }}
                    name={entry.field}
                    id={entry.field}
                    size="small"
                  />
                </div>
              </Grid>
            ))}
            <Grid item md={12} sm={12} xs={12}>
              <div className="div_label_text">
                <label className="label_Style_job">{ResultFieldLabel[ResultFields.Result]}</label>
                <Controller
                  control={control}
                  name={ResultFields.Result}
                  render={({ field: { value } }) => (
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 2056 }}
                      disabled
                      value={value}
                      multiline
                      rows={5}
                      maxRows={5}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </Card>
      <br />
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div>
            <Button className="button_cancel" onClick={handleCancel}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next">Save</Button>
          </div>
        </div>
      </Grid>
    </form>
  );
}
