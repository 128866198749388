/* eslint-disable max-len */
import React from 'react';

export function ChangeGreenIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M12.6742 16.9994C12.5236 16.9994 12.3841 16.9157 12.3115 16.7873C12.239 16.6534 12.239 16.4971 12.3171 16.3632L12.903 15.3865C13.0201 15.1856 13.2824 15.1242 13.4776 15.2414C13.6785 15.3586 13.7399 15.6153 13.6227 15.8162L13.4721 16.0674C15.012 15.7046 16.167 14.3205 16.167 12.6686C16.167 12.4398 16.3567 12.25 16.5854 12.25C16.8142 12.25 17.0039 12.4398 17.0039 12.6686C16.9983 15.0572 15.0566 16.9994 12.6742 16.9994Z" fill="#00A751" />
      <path d="M5.41846 9.74382C5.1897 9.74382 5 9.55407 5 9.32525C5 6.94218 6.94166 5 9.32409 5C9.47474 5 9.61423 5.08371 9.68676 5.21208C9.75929 5.34602 9.75929 5.50229 9.68118 5.63623L9.09534 6.6129C8.97817 6.80823 8.72151 6.8752 8.52065 6.75242C8.32537 6.63522 8.25841 6.3785 8.38116 6.17758L8.53181 5.92644C6.98629 6.2892 5.83692 7.67328 5.83692 9.32525C5.83692 9.55407 5.64722 9.74382 5.41846 9.74382Z" fill="#00A751" />
      <path d="M12.5773 11.3596V13.0004C12.5773 14.3677 12.0305 14.9146 10.6635 14.9146H9.02314C7.65616 14.9146 7.10938 14.3677 7.10938 13.0004V11.3596C7.10938 9.99224 7.65616 9.44531 9.02314 9.44531H10.6635C12.0305 9.44531 12.5773 9.99224 12.5773 11.3596Z" fill="#00A751" />
      <path d="M13.0095 7.10938H11.3691C10.0244 7.10938 9.47768 7.64515 9.46094 8.96226H10.6661C12.3064 8.96226 13.0653 9.72685 13.0653 11.3621V12.5676C14.3876 12.5508 14.9176 12.0039 14.9176 10.6589V9.02365C14.9232 7.65631 14.3764 7.10938 13.0095 7.10938Z" fill="#00A751" />
    </svg>
  );
}
