import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './index.css';
import { ToastContainer, Zoom } from 'react-toastify';
import RouteManager from './Routes/RouteManager';
import './styles/global.scss';
import 'react-toastify/dist/ReactToastify.css';
// import ComponentLoader from './Common/ComponentLoader/ComponentLoader';
import { handleCustomerId } from './utils/CommonFunctions';
import ComponentLoader from './Common/ComponentLoader/ComponentLoader';
import { RootState } from './redux/rootState';
import { setHistoryStack } from './redux/masters/masterSlice';

function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { historyStack } = useSelector((state:RootState) => state.masterDataReducer);

  useEffect(() => {
    dispatch(setHistoryStack([...historyStack, pathname]));
    handleCustomerId(pathname);
  }, [pathname]);

  const { loader } = useSelector((state: RootState) => state.loaderReducer);

  return (
    <>
      <div className="routeManger_main">
        <RouteManager />
      </div>
      <ToastContainer draggable={false} transition={Zoom} autoClose={8000} />
      {loader
        && (
          <div className="loader_styles">
            <ComponentLoader />
          </div>
        )}
    </>
  );
}

export default App;
