export const ALL_TYPE = 5;
export const DEFAULT_FROM_DATE = '2023-07-01';
export const DEFAULT_TO_DATE = '2023-08-04';
export const TYPES = {
  JOB_COUNT: 'JobCounts',
  CUSTOMER_COUNT: 'CustomerCounts',
  CUSTOMER_QUEUE: 'CustomerQueue',
};
export const DURATION = {
  ONE_MONTH: 1,
  SIX_MONTH: 6,
  ONE_YEAR: 12,
  ALL: 0,
};
export const data = [
  {
    name: 'Jan',
    'Job Done': 4000,
    'Job Created': 2400,
    av: 200,
    amt: 2400,
  },
  {
    name: 'Feb',
    'Job Done': 3000,
    'Job Created': 1398,
    av: 500,
    amt: 2210,
  },
  {
    name: 'Mar',
    'Job Done': 2000,
    'Job Created': 9800,
    amt: 2290,
    av: 800,
  },
  {
    name: 'Apr',
    'Job Done': 2780,
    'Job Created': 12000,
    amt: 3990,
    av: 1000,
  },
  {
    name: 'May',
    'Job Done': 1890,
    'Job Created': 4800,
    amt: 2181,
    av: 200,

  },
  {
    name: 'Jun',
    'Job Done': 2390,
    'Job Created': 3800,
    amt: 2500,
    av: 200,

  },
  {
    name: 'Jul',
    'Job Done': 3490,
    'Job Created': 4300,
    amt: 2100,
    av: 200,

  },
  {
    name: 'Aug',
    'Job Done': 3000,
    'Job Created': 1398,
    amt: 2210,
    av: 200,

  },
  {
    name: 'Sep',
    'Job Done': 3000,
    'Job Created': 1398,
    amt: 2210,
    av: 200,

  },
  {
    name: 'Oct',
    'Job Done': 3000,
    'Job Created': 1398,
    amt: 2210,
    av: 200,

  },
  {
    name: 'Nov',
    'Job Done': 3000,
    'Job Created': 1398,
    amt: 2210,
    av: 200,

  },
  {
    name: 'Dec',
    'Job Done': 3000,
    'Job Created': 1398,
    amt: 2210,
    av: 200,
  },

];
