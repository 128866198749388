import {
  Card, TextField, Grid, Select, MenuItem,
} from '@mui/material';
import React from 'react';

export default function ComponentSite() {
  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Site
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Site</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address (Optional)</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Zip</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">State</label>
              <Select
                size="small"
                fullWidth
              >
                <MenuItem value={1}>Uttar Pradesh</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">City</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <Select
                size="small"
              >
                <MenuItem value={1}>+1</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Note</label>
              <TextField
                fullWidth
                multiline
                rows={9}
                maxRows={9}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
