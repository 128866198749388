import {
  TextField, IconButton, Grid, Card, Button,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { JobOrderSiteFormFields, MAIN_TAB, VESSELS_TAB } from '../../utils/constants';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import ComponentSite from './ComponentSite';
import { extRegex, phoneRegex } from '../../../../utils/regex';
import { RootState } from '../../../../redux/rootState';
import { jobOrderClientSiteForm, setSelectedTab } from '../../redux/sliceJobOrder';
import { assignJobOrderClientSite } from '../../utils/helper';
import { ClientSiteFormValues } from '../../utils/types';

export default function ScreenClientSite() {
  const {
    control, getValues, reset, handleSubmit, setValue, watch, trigger,
  } = useForm<ClientSiteFormValues>();
  const { phoneCodes } = useSelector((state: RootState) => state.masterDataReducer);
  const { ClientData, SiteData } = useSelector((state: RootState) => state.jobOrderReducer);

  const dispatch = useDispatch();

  const onSubmit = (values: ClientSiteFormValues) => {
    dispatch(jobOrderClientSiteForm(values));
    dispatch(setSelectedTab(VESSELS_TAB));
    reset();
  };

  useEffect(() => {
    if (ClientData && SiteData) {
      assignJobOrderClientSite(setValue, ClientData, SiteData);
    }
  }, [ClientData, SiteData]);

  return (
    <div className="div_container_CustomerInfo">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_container">
                <div className="customerInfo">
                  Client
                </div>
                <Controller
                  control={control}
                  name={JobOrderSiteFormFields.ClientId}
                  render={({ field: { value } }) => (
                    <TextField
                      size="small"
                      placeholder="Customer ID *"
                      value={value}
                      disabled
                      id={JobOrderSiteFormFields.ClientId}
                      InputProps={{
                        endAdornment: (
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>
                        ),
                      }}
                      className="mob-wd-100"
                    />
                  )}
                />
              </div>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Company</label>
                      <ComponentTextInput
                        control={control}
                        capitalizeFirst
                        isDisable
                        name={JobOrderSiteFormFields.ClientName}
                        id={JobOrderSiteFormFields.ClientName}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Contact</label>
                      <ComponentTextInput
                        control={control}
                        name={JobOrderSiteFormFields.ClientContact}
                        isDisable
                        id={JobOrderSiteFormFields.ClientContact}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Address</label>
                      <ComponentTextInput
                        control={control}
                        className="nonCapitalizeField"
                        name={JobOrderSiteFormFields.ClientAddress}
                        isDisable
                        id={JobOrderSiteFormFields.ClientAddress}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Address (Optional)</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        className="nonCapitalizeField"
                        name={JobOrderSiteFormFields.ClientOptionalAddress}
                        id={JobOrderSiteFormFields.ClientOptionalAddress}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={3} sm={3} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Zip</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={JobOrderSiteFormFields.ClientZip}
                        id={JobOrderSiteFormFields.ClientZip}
                        regEx={extRegex}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4.5} sm={4.5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">State</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={JobOrderSiteFormFields.ClientState}
                        id={JobOrderSiteFormFields.ClientState}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={4.5} sm={4.5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">City</label>
                      <ComponentTextInput
                        control={control}
                        isDisable
                        name={JobOrderSiteFormFields.ClientCity}
                        id={JobOrderSiteFormFields.ClientCity}
                        size="small"
                      />
                    </div>
                  </Grid>
                  <Grid item md={2} sm={2} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Code</label>
                      <ComponentSelectInput
                        control={control}
                        name={JobOrderSiteFormFields.ClientPhoneCode}
                        defaultValue=""
                        disabled
                        size="small"
                        entries={phoneCodes}
                      />
                    </div>
                  </Grid>
                  <Grid item md={5} sm={5} xs={12}>
                    <div className="div_label_text">
                      <label className="label_Style_job">Phone</label>
                      <ComponentTextInput
                        control={control}
                        name={JobOrderSiteFormFields.ClientPhone}
                        id={JobOrderSiteFormFields.ClientPhone}
                        regEx={phoneRegex}
                        isDisable
                        size="small"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <ComponentSite control={control} getValues={getValues} watch={watch} setValue={setValue} trigger={trigger} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => dispatch(setSelectedTab(MAIN_TAB))}>Back</Button>
              </div>
              <div className="button_margin_left">
                <Button type="submit" className="button_save_and_next">Save & Next</Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
