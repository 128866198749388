/* eslint-disable max-len */
import React from 'react';

export function SendMailIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.22816 14.4199H14.3619C14.4224 14.4199 14.4804 14.3959 14.5232 14.3531C14.566 14.3103 14.59 14.2523 14.59 14.1918V7.77959C14.5889 7.74154 14.5794 7.70419 14.5623 7.67019C14.5452 7.63618 14.5208 7.60634 14.4909 7.58275L13.8861 7.06133V4.07747C13.8851 4.01765 13.8606 3.96063 13.8179 3.91869C13.7752 3.87675 13.7178 3.85325 13.658 3.85326H10.1123L9.35881 3.20148C9.20005 3.0712 9.00104 3 8.79567 3C8.5903 3 8.39129 3.0712 8.23253 3.20148L7.47777 3.85326H3.93208C3.87225 3.85325 3.81481 3.87675 3.77214 3.91869C3.72947 3.96063 3.70498 4.01765 3.70396 4.07747V7.06784L3.10562 7.57754C3.07256 7.59973 3.04557 7.62984 3.02713 7.66513C3.00869 7.70043 2.99937 7.73977 3.00003 7.77959V14.1918C3.00003 14.2523 3.02407 14.3103 3.06685 14.3531C3.10963 14.3959 3.16765 14.4199 3.22816 14.4199ZM6.62393 10.9694L3.46019 13.6808V8.27233L6.62393 10.9694ZM14.1285 8.26582V13.6965L10.9609 10.9707L14.1285 8.26582ZM10.5281 11.2027L13.7401 13.9637H3.83301L7.0502 11.2027H10.5281ZM14.0073 7.76395L13.8861 7.86693V7.66748L14.0073 7.76395ZM8.52844 3.55344C8.60504 3.49446 8.69899 3.46247 8.79567 3.46247C8.89234 3.46247 8.9863 3.49446 9.0629 3.55344L9.40965 3.85326H8.18169L8.52844 3.55344ZM4.1602 4.3056H13.4298V8.25669L10.5177 10.7465H7.06454L4.15238 8.26191C4.15763 8.24324 4.16026 8.22394 4.1602 8.20455V4.3056ZM3.70396 7.87996L3.5736 7.77307L3.70396 7.66618V7.87996Z" fill="#0185FF" stroke="#0185FF" strokeWidth="0.2" />
      <path d="M8.13867 9.36472C8.71493 9.52313 9.32934 9.46373 9.86459 9.19787C9.91852 9.17038 9.95932 9.1226 9.97803 9.06503C9.99673 9.00746 9.99179 8.94481 9.96431 8.89088C9.93682 8.83695 9.88904 8.79614 9.83147 8.77744C9.77389 8.75874 9.71125 8.76367 9.65732 8.79116C9.22991 9.00181 8.74151 9.05356 8.27945 8.93716C7.02934 8.45875 7.36696 7.12781 7.37739 7.07176C7.47776 6.69373 7.97181 5.73431 9.05898 5.81643C10.2804 5.91159 10.2765 7.03917 10.2635 7.26469C10.2361 7.73397 9.96626 8.08984 9.74987 8.08984H9.74205C9.7245 8.09094 9.70692 8.08825 9.6905 8.08195C9.67408 8.07566 9.6592 8.0659 9.64689 8.05334C9.59512 7.96434 9.5732 7.8611 9.58432 7.75874L9.73814 6.5386C9.73814 6.52817 9.73814 6.51905 9.73814 6.50992C9.73873 6.45344 9.71835 6.39874 9.68093 6.35642C9.64351 6.31409 9.59172 6.28716 9.53558 6.28084C9.47944 6.27451 9.42296 6.28923 9.37705 6.32216C9.33115 6.35509 9.2991 6.40388 9.28711 6.45909C9.15332 6.35105 8.98834 6.2889 8.81652 6.2818H8.79697C8.3329 6.2818 7.79714 6.65071 7.69937 7.34811C7.65071 7.59444 7.70002 7.85004 7.83682 8.06059C7.97362 8.27114 8.18712 8.42006 8.43197 8.4757C8.57389 8.49964 8.71926 8.49356 8.85869 8.45786C8.99813 8.42216 9.12853 8.35763 9.24148 8.26843C9.25957 8.29747 9.28007 8.32494 9.30275 8.35055C9.35522 8.41094 9.41984 8.45958 9.4924 8.49327C9.56496 8.52697 9.64381 8.54496 9.7238 8.54609C10.2283 8.56955 10.678 8.0025 10.7197 7.29076C10.7706 6.39651 10.2882 5.45404 9.09288 5.36149C7.84667 5.26502 7.11928 6.26225 6.93678 6.95574C6.56657 8.35837 7.51035 9.12748 8.13867 9.36472ZM9.13459 7.67009C9.08773 7.78929 9.00198 7.88916 8.89124 7.95352C8.78051 8.01788 8.65129 8.04295 8.52452 8.02466C8.39366 7.99166 8.28104 7.90847 8.21103 7.79308C8.14102 7.6777 8.11924 7.5394 8.1504 7.40808C8.22079 6.91403 8.57927 6.73414 8.80218 6.73414H8.81131C9.01336 6.73414 9.16327 6.9753 9.16718 6.98051C9.18058 7.00401 9.19826 7.02479 9.21932 7.04178L9.13459 7.67009Z" fill="#0185FF" />
    </svg>
  );
}
