import { PayloadAction } from '@reduxjs/toolkit';
import { ofType } from 'redux-observable';
import {
  Observable, map, debounceTime, switchMap, from, takeUntil, filter, catchError, of,
} from 'rxjs';
import { AxiosError } from 'axios';
import { showErrorToaster } from '../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../Common/Config';
import { makeGetRequest } from '../Common/NetworkOps';
import { GetById, ListingResponse, PaginationResponse } from './type';

// Get Data by Id
export async function getData<T>(url: string): Promise<GetById<T>> {
  const result = await makeGetRequest<GetById<T>>(url);
  return result.data;
}

export function createGetEpicWithData<T, R>(
  actionType: string,
  getDataFn: (data: R) => Promise<GetById<T>>,
  successAction: (payload: T) => PayloadAction<T>,
  failureAction: () => PayloadAction<void>,
) {
  return (action$: Observable<PayloadAction<R>>) => action$.pipe(
    ofType(actionType),
    map((x) => x.payload),
    debounceTime(250),
    switchMap((data: R) => from(getDataFn(data)).pipe(
      map((res: GetById<T>) => {
        if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
          return successAction(res.BMT.Result);
        }
        showErrorToaster(res.BMT.ResponseMessage);
        return failureAction();
      }),
      takeUntil(action$.pipe(filter((action) => action.type === actionType))),
      catchError((error: AxiosError<GetById<T>>) => {
        const errorMessage = error?.response?.data?.BMT?.ResponseStatus as string;
        of(showErrorToaster(errorMessage));
        return of(failureAction());
      }),
    )),
  );
}

// Get Data Without Id
export function createGetEpicWithoutData<T>(
  actionType: string,
  getDataFn: () => Promise<GetById<T>>,
  successAction: (payload: T) => PayloadAction<T>,
  failureAction: () => PayloadAction<void>,
) {
  return (action$: Observable<PayloadAction<void>>) => action$.pipe(
    ofType(actionType),
    debounceTime(250),
    switchMap(() => from(getDataFn()).pipe(
      map((res: GetById<T>) => {
        if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
          return successAction(res.BMT.Result);
        }
        showErrorToaster(res.BMT.ResponseMessage);
        return failureAction();
      }),
      takeUntil(action$.pipe(filter((action) => action.type === actionType))),
      catchError((error: AxiosError<GetById<T>>) => {
        const errorMessage = error?.response?.data?.BMT?.ResponseStatus as string;
        of(showErrorToaster(errorMessage));
        return of(failureAction());
      }),
    )),
  );
}

// Get All Table Listing Data
async function fetchData<T>(url: string): Promise<ListingResponse<T>> {
  const result = await makeGetRequest<ListingResponse<T>>(url);
  return result.data;
}

export function createFetchDataEpic<T, P>(
  actionType: string,
  urlConstructor: (payload: P) => string,
  successAction: (payload: PaginationResponse<T>) => PayloadAction<PaginationResponse<T>>,
  failureAction: () => PayloadAction<void>,
) {
  return (action$: Observable<PayloadAction<P>>) => action$.pipe(
    ofType(actionType),
    debounceTime(250),
    switchMap((action) => from(fetchData<T>(urlConstructor(action.payload))).pipe(
      map((res: ListingResponse<T>) => {
        if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
          return successAction(res.BMT.Result);
        }
        showErrorToaster(res.BMT.ResponseMessage);
        return failureAction();
      }),
      takeUntil(action$.pipe(filter(() => action.type === actionType))),
      catchError((error: AxiosError<ListingResponse<T>>) => {
        const errorMessage = error?.response?.data?.BMT?.ResponseStatus as string;
        of(showErrorToaster(errorMessage));
        return of(failureAction());
      }),
    )),
  );
}
