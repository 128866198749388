import { MasterData } from '../../../utils/type';
import { GridType, VanishType } from './tubeChartTypes';

export const onCLickValues:MasterData[] = [
  { Value: VanishType.Down, Text: VanishType.Down },
  { Value: VanishType.Top, Text: VanishType.Top },
  { Value: VanishType.Left, Text: VanishType.Left },
  { Value: VanishType.Right, Text: VanishType.Right },
  { Value: VanishType.Individual, Text: VanishType.Individual },
];

export const gridTypeValues:MasterData[] = [
  { Value: GridType.LeftEccentric, Text: GridType.LeftEccentric },
  { Value: GridType.TopEccentric, Text: GridType.TopEccentric },
  { Value: GridType.Normal, Text: GridType.Normal },
];

export const sectionValues:MasterData[] = [
  { Value: '1', Text: 'Section 1' },
  { Value: '2', Text: 'Section 2' },
  { Value: '3', Text: 'Section 3' },
];

export const offsetList = {
  2: 'normal',
  0: 'leftEccentric',
  1: 'topEccentric',
};

export enum TubeChartPanelNames {
  Section = 'section',
  OnClick = 'onClick',
  ChartSize = 'chartSize',
  TubeCount = 'tubeCount',
  TotalTubeCount = 'totalTubeCount',
  Size = 'size',
  Zoom = 'zoom',
}
