import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddMaterialListData, MaterialListPayloadListing, PayloadTypeDeleteMaterialList,
  PayloadTypeGetMaterialList, MaterialListState, UpdateMaterialListData,
} from '../utils/types';

const initialState: MaterialListState = {
  MaterialListData: {} as MaterialListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const materialListSlice = createSlice({
  name: 'materialList',
  initialState,
  reducers: {
    getMaterialListStart(state, _action: PayloadAction<PayloadTypeGetMaterialList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successMaterialList(state, action: PayloadAction<MaterialListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        MaterialListData: action?.payload as MaterialListPayloadListing,
      };
    },
    failureMaterialList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateMaterialList(state, _action: PayloadAction<UpdateMaterialListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateMaterialList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateMaterialList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addMaterialListStart(state, _action: PayloadAction<AddMaterialListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddMaterialList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddMaterialList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteMaterialListStart(state, _action: PayloadAction<PayloadTypeDeleteMaterialList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteMaterialList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteMaterialList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getMaterialListStart, successMaterialList, failureMaterialList, updateMaterialList,
  successUpdateMaterialList, failureUpdateMaterialList, resetState, deleteMaterialListStart,
  successDeleteMaterialList, failureDeleteMaterialList,
  addMaterialListStart, successAddMaterialList, failureAddMaterialList,
} = materialListSlice.actions;
export const ListMaterialReducer = materialListSlice.reducer;

export type MaterialTypeActions =
  | ReturnType<typeof getMaterialListStart>
  | ReturnType<typeof successMaterialList>
  | ReturnType<typeof failureMaterialList>

  | ReturnType<typeof updateMaterialList>
  | ReturnType<typeof successUpdateMaterialList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateMaterialList>

  | ReturnType<typeof deleteMaterialListStart>
  | ReturnType<typeof successDeleteMaterialList>
  | ReturnType<typeof failureDeleteMaterialList>

  | ReturnType<typeof addMaterialListStart>
  | ReturnType<typeof successAddMaterialList>
  | ReturnType<typeof failureAddMaterialList>;
