import {
  Button, Card, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DialogBoxTubeChart from './ComponentDialogBox/DialogBoxTubeChart';
import { AddPlaylist } from '../../../../../assets/TableIcons';
import styles from './VesselStyle.module.scss';
import { VesselData, VesselFormValues } from '../../utils/type';
import { RootState } from '../../../../../redux/rootState';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import { assignVesselData, formatUnitPayload, formatvesselData } from '../../utils/helper';
import { UnitInfoFormNames, VesselFormNames } from '../../utils/constants';
import {
  createUnitStart, setDisplayVessels, setDropDownVessels, setNumberOfTubes, setSelectedTab, setTubeLength, setVesselInfo, updateUnitStart,
} from '../../redux/unitInfoSlice';
import { tubeLengthRegex } from '../../../../../utils/regex';
import EndPoints from '../../../../../Routes/EndPoints';
import { showErrorToaster } from '../../../../../Common/ComponentToast/ComponentSuccessToasts';

export default function ScreenVessels() {
  const {
    vesselMaster, unitInfo, numberOfTubes, tubeLength, unitPrice,
    unitId, displayVessels, dropDownVessels, selectedUnit, vesselInfo,
  } = useSelector((state: RootState) => state.unitInfoReducer);
  const {
    control, handleSubmit, setValue, getValues,
  } = useForm<VesselFormValues>();
  const { bidId } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [selectedVessel, setSelectedVessel] = useState<VesselData | null>(null);
  const [manualVessel, setManualVessel] = useState('');

  const handleAction = () => {
    setOpen(!open);
  };

  const handleChange = () => {
    const values = getValues();
    dispatch(setVesselInfo(values));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (vesselMaster.length > 0 && displayVessels.length === 0 && !selectedUnit?.UnitId) {
      const val = formatvesselData(vesselMaster);
      const displayData: VesselData[] = [];
      const dropDownData: VesselData[] = [];

      val.forEach((vessel: VesselData) => {
        if (vessel.vesselType.toLowerCase() === 'other') {
          return;
        }
        if (vessel.display) {
          displayData.push(vessel);
        } else {
          dropDownData.push(vessel);
        }
      });
      dispatch(setDisplayVessels(displayData));
      dispatch(setDropDownVessels(dropDownData));
    }
  }, [vesselMaster]);

  useEffect(() => {
    if (location.pathname.includes(EndPoints.ADD_UNIT) && unitId) {
      navigate(`${EndPoints.BID_WORKSHEET}/${String(bidId)}`);
    }
  }, [unitId]);

  useEffect(() => {
    if (selectedUnit?.UnitId && displayVessels.length === 0) {
      const values = {
        estimatedTubeQty: selectedUnit.VesselsInfo.TubeQtyIsEstimated ?? false,
        providedTubeQty: selectedUnit.VesselsInfo.TubeQtyProvidedByCustomer ?? false,
      };
      dispatch(setVesselInfo(values));
      const val = assignVesselData(selectedUnit.VesselsInfo.VesselIds, vesselMaster);
      const displayData: VesselData[] = [];
      const dropDownData: VesselData[] = [];

      val.forEach((vessel: VesselData) => {
        if (vessel.display) {
          displayData.push(vessel);
        } else if (vessel.vesselType.toLowerCase() !== 'other') {
          dropDownData.push(vessel);
        }
      });
      dispatch(setDisplayVessels(displayData));
      dispatch(setDropDownVessels(dropDownData));
      dispatch(setNumberOfTubes(selectedUnit.UnitPricingInfo.TotalTubeQty));
      dispatch(setTubeLength(selectedUnit.UnitPricingInfo.TotalTubeLength));
    }
  }, [selectedUnit]);

  const handleTubeLength = (id: number, value: number) => {
    if (tubeLengthRegex && String(value).trim()?.length > 0 && !tubeLengthRegex.test(String(value))) {
      return;
    }

    const data = JSON.parse(JSON.stringify(displayVessels));
    const index = data.findIndex((val: VesselData) => val.id === id);
    data[index].length = value || 0;

    let length = 0;
    data.forEach((vessel: VesselData) => {
      length = Math.max(vessel.length, length);
    });

    dispatch(setTubeLength(length));
    dispatch(setDisplayVessels(data));
  };

  const handleTubeNumber = (id: number, value: number) => {
    const regEx = /^[0-9]{1,5}$/;

    if (regEx && String(value).trim()?.length > 0 && !regEx.test(String(value))) {
      return;
    }

    const data = JSON.parse(JSON.stringify(displayVessels));
    const index = data.findIndex((val: VesselData) => val.id === id);

    dispatch(setNumberOfTubes(numberOfTubes - data[index].numberOfTube + value));
    data[index].numberOfTube = value;

    if (value === 0) {
      data[index].length = value;
      handleTubeLength(id, 0);
    }

    dispatch(setDisplayVessels(data));
  };

  const handleAdd = () => {
    if (selectedVessel?.vesselType.toLowerCase() === 'pivot') {
      const newVessel = {
        id: -1,
        vesselName: manualVessel,
        tubeName: `${manualVessel}Name`,
        tubeLength: `${manualVessel}Length`,
        numberOfTube: 0,
        length: 0,
        active: true,
        display: true,
        vesselType: 'manual',
      };
      dispatch(setDisplayVessels([...displayVessels, newVessel]));
      setSelectedVessel(null);
      setManualVessel('');
    } else if (selectedVessel) {
      const index = dropDownVessels.findIndex((value: VesselData) => value.id === selectedVessel.id);
      const vessel = dropDownVessels[index];
      const temp = JSON.parse(JSON.stringify(dropDownVessels));
      temp.splice(index, 1);
      dispatch(setDropDownVessels(temp));
      dispatch(setDisplayVessels([...displayVessels, vessel]));
      setSelectedVessel(null);
    }
  };

  const onSubmit = (values: VesselFormValues) => {
    if (
      !unitInfo.manufacturer || !unitInfo.model
      || !unitInfo.serialNumber || !unitInfo.machineType || !unitInfo.testType
      || (unitInfo.testType === UnitInfoFormNames.fluxLeakagetest && !unitInfo.odTubes)
      || (unitInfo.spotTest && !unitInfo.pct)
    ) {
      showErrorToaster('Please enter all required fields');
      dispatch(setSelectedTab(1));
      return;
    }
    if (!unitId) {
      const payload = formatUnitPayload(values, unitInfo, String(bidId), displayVessels, unitPrice, numberOfTubes, tubeLength);
      dispatch(createUnitStart(payload));
    } else {
      const payload = formatUnitPayload(
        values,
        unitInfo,
        (selectedUnit?.UnitInfo?.BidId) || String(bidId),
        displayVessels,
        unitPrice,
        numberOfTubes,
        tubeLength,
      );
      dispatch(updateUnitStart({ ...payload, UnitId: unitId }));
    }
  };

  useEffect(() => {
    if (vesselInfo) {
      setValue(VesselFormNames.estimatedTubeQty, vesselInfo.estimatedTubeQty);
      setValue(VesselFormNames.providedTubeQty, vesselInfo.providedTubeQty);
    }
  }, [vesselInfo]);

  return (
    <>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Vessels Details
            </div>
            <div className="double_checkbox">
              <CustomCheckbox handleChange={handleChange} control={control} name={VesselFormNames.estimatedTubeQty} label="Tube Qty. is Estimated" />
              <CustomCheckbox
                handleChange={handleChange}
                control={control}
                name={VesselFormNames.providedTubeQty}
                label="Tube Qty. provided by Customer"
              />
            </div>
          </div>
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead style={{ background: '#e5f6ed' }}>
                  <TableRow>
                    <TableCell align="left" style={{ width: '139px' }}>
                      Vessel
                    </TableCell>
                    <TableCell align="left" style={{ width: '265px' }}>
                      # of Tube
                    </TableCell>
                    <TableCell align="left">
                      Tube Length
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    displayVessels.map((vessel) => (
                      <TableRow key={vessel.id}>
                        <TableCell align="left" style={{ background: '#e5f6ed' }}>
                          {vessel.vesselName}
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            name={vessel.tubeName}
                            onChange={(event) => handleTubeNumber(vessel.id, Number(event.target.value))}
                            value={vessel.numberOfTube > 0 ? vessel.numberOfTube : ''}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="left">
                          <TextField
                            size="small"
                            onChange={(event) => handleTubeLength(vessel.id, Number(event.target.value))}
                            name={vessel.tubeLength}
                            type="number"
                            value={vessel.length > 0 ? vessel.length : ''}
                            disabled={vessel.numberOfTube === 0}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  }
                  <TableRow>
                    <TableCell align="left" style={{ background: '#e5f6ed' }}>
                      <div className={styles.tablecell_div_style}>
                        {selectedVessel?.vesselType?.toLowerCase() !== 'pivot' ? (
                          <Select
                            size="small"
                            fullWidth
                            placeholder="Vessels"
                            className={`${styles.select_style} vesselSelectField`}
                            value={selectedVessel?.id}
                            onChange={(event) => {
                              const changedVessel = dropDownVessels.find((vData) => vData.id === event.target.value) || null;
                              setSelectedVessel(changedVessel);
                            }}
                          >
                            {dropDownVessels?.map((vessel: VesselData) => (
                              <MenuItem value={vessel.id}>{vessel.vesselName}</MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <TextField
                            name="manualVesselType"
                            onChange={(e) => setManualVessel(e.target.value)}
                            value={manualVessel}
                            autoFocus
                            fullWidth
                            autoComplete="off"
                            inputProps={{ maxLength: 50 }}
                            className={`${styles.manualVessel} nonCapitalizeField`}
                            size="small"
                          />
                        )}
                        <button
                          type="button"
                          style={{ backgroundColor: '#e5f6ed' }}
                          onClick={handleAdd}
                          disabled={!selectedVessel || (selectedVessel?.vesselType.toLowerCase() === 'pivot' && manualVessel.length === 0)}
                        >
                          <AddPlaylist />
                        </button>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <TextField size="small" disabled />
                    </TableCell>
                    <TableCell align="left">
                      <TextField size="small" disabled />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div className="vessel_button_style">
              <Button disabled={numberOfTubes === 0} className="button_save_and_next" size="medium" type="submit">Save</Button>
            </div>
          </div>
        </form>
      </Card>
      <DialogBoxTubeChart show={open} setOpenFrom={handleAction} />
    </>
  );
}
