import {
  Grid, Card, Select, Button, TextField, Tooltip, IconButton, MenuItem,
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import '../StyleBidComponents.scss';

export default function ComponentBidEditCustomerTab(): JSX.Element {
  return (
    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="container_label_14px">
                Bid Total - $7,997,1
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ height: '30px', fontSize: '10px !important' }}>
                  <TextField
                    size="small"
                    placeholder="Customer"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Search Here..">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Company</label>
                    <TextField size="small" />
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Address</label>
                    <TextField size="small" />
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Address(Optioanl)</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Zip</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer"> State</label>
                    <Select size="small">
                      <MenuItem value={1}>Texas</MenuItem>
                    </Select>
                  </div>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer"> City</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer"> Country</label>
                    <Select size="small">
                      <MenuItem value={1}>Texas</MenuItem>
                    </Select>
                  </div>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel">Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button className="button_save_and_next">Ok</Button>
                    </div>
                  </div>
                </Grid>

              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
