import { DataHeaderCellType } from '../../../../../../utils/interface';

export function getTableHeaderData(): DataHeaderCellType[] {
  const headerData = [
    {
      colWidth: 'wdth-3',
      header: 'SN.',
    },
    {
      colWidth: 'wdth-3',
      header: 'Action',
    },
    {
      colWidth: 'wdth-5',
      header: 'Bid Number',
    },
    {
      colWidth: 'wdth-6',
      header: 'Total Bid #',
    },
  ];
  return headerData;
}
