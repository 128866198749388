/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect, useMemo,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { AddDefaultListData, DefaultTypeList } from '../utils/types';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { DefaultListFormValue, DefaultValues } from '../utils/ConstantsDefaultList';
import { RootState } from '../../../../redux/rootState';
import { defaultListSchema } from '../utils/validations';
import {
  addDefaultListStart, getUnitMasterStart, resetState, updateDefaultList,
} from '../redux/sliceDefaultTypeList';
import RequiredFiled from '../../../../Common/RequiredField';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentDefaultListDialog({
  show, setOpenFrom, rowId, handleData,
}: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const {
    isSuccess, DefaultListData, styleType, types,
  } = useSelector((state: RootState) => state.ListDefaultReducer);

  const dispatch = useDispatch();

  const {
    formState: { errors }, control, handleSubmit, setValue, reset,
  } = useForm<AddDefaultListData>({
    defaultValues: DefaultValues,
    resolver: yupResolver(defaultListSchema as unknown as ObjectSchema<AddDefaultListData>),
  });

  const resetValues = () => {
    setValue(DefaultListFormValue.Model, '');
    setValue(DefaultListFormValue.TypeId, '');
    setValue(DefaultListFormValue.StyleId, '');
    setValue(DefaultListFormValue.Notes, '');
  };

  const onSubmit = (data: AddDefaultListData) => {
    if (rowId) {
      const updateData = {
        Id: rowId,
        Model: data.Model,
        TypeId: Number(data.TypeId),
        StyleId: Number(data.StyleId),
        Notes: data.Notes,
      };
      dispatch(updateDefaultList(updateData));
      reset();
      setOpenFrom();
    } else {
      const bodyData = {
        Model: data.Model,
        TypeId: Number(data.TypeId),
        StyleId: Number(data.StyleId),
        Notes: data.Notes,
      };
      dispatch(addDefaultListStart(bodyData));
      reset();
      setOpenFrom();
    }
  };

  const tableData: DefaultTypeList[] = useMemo(() => DefaultListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      id: String(item?.Id),
      sn: index,
      action: 'edit&delete',
      Model: item?.Model,
      Type: item?.Type,
      Style: item?.Style,
      Notes: item?.Notes,
      StyleId: item?.StyleId,
      TypeId: item?.TypeId,
    };
    return transformedDataItem;
  }), [DefaultListData]);

  useEffect(() => {
    if (rowId) {
      tableData?.forEach((rowData) => {
        if (rowData?.id === rowId) {
          setValue(DefaultListFormValue.Model, rowData.Model);
          setValue(DefaultListFormValue.Notes, rowData.Notes);
          setValue(DefaultListFormValue.StyleId, rowData.StyleId);
          setValue(DefaultListFormValue.TypeId, rowData.TypeId);
        }
      });
    }
  }, [rowId, show]);

  useEffect(() => {
    if (isSuccess) {
      resetValues();
      if (handleData) {
        handleData();
      }
      dispatch(resetState());
    }
  }, [isSuccess]);

  const handleClose = () => {
    setOpenFrom();
    reset();
  };

  const getAnalystHeading = () => {
    if (rowId) {
      return 'Edit Default List';
    }
    return 'Add Default List';
  };

  useEffect(() => {
    dispatch(getUnitMasterStart());
  }, [show]);

  return (
    <div>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {getAnalystHeading()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={4} sm={4} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Model
                    {' '}
                    <RequiredFiled />
                    {' '}
                  </label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 10 }}
                    name={DefaultListFormValue.Model}
                    size="small"
                    id={DefaultListFormValue.Model}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Type
                    {' '}
                    <RequiredFiled />
                    {' '}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    defaultValue=""
                    name={DefaultListFormValue.TypeId}
                    size="small"
                    entries={types}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={4} sm={4} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Style
                    {' '}
                    <RequiredFiled />
                    {' '}
                  </label>
                  <ComponentSelectInput
                    control={control}
                    defaultValue=""
                    name={DefaultListFormValue.StyleId}
                    size="small"
                    entries={styleType}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Note</label>
                  <Controller
                    control={control}
                    name={DefaultListFormValue.Notes}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        inputProps={{ maxLength: 255 }}
                        value={value}
                        multiline
                        rows={5}
                        maxRows={5}
                        onChange={(e) => onChange(e.target.value)}
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentDefaultListDialog;
