import { Button, Card } from '@mui/material';
import React, {
  useState, useRef, ChangeEvent, DragEvent, useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import style from './theme.module.scss';
import UploadIcon from '../../../../assets/UploadFile.svg';
import { startCreateTheme, startUploadLogo } from '../../redux/addCustomerSlice';
import { ThemesFormInput } from '../../utils/types';
import { MainThemesDefaultValue, ThemesFormValue } from '../../utils/helper';
import ColorPicker from './ColorPicker';
import { themesColors } from '../../utils/constants';
import { RootState } from '../../../../redux/rootState';
import { showErrorToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import EndPoints from '../../../../Routes/EndPoints';

function Themes() {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [file, setFile] = useState<string>('');
  const {
    themeLogoURL, isSuccess, customerId: newCustomerID, themeDetails,
  } = useSelector((state: RootState) => state.addCustomerReducer);

  const [mainColor, setMainColor] = useState<string>(themeDetails?.MainColor);
  const [borderColor, setBorderColor] = useState<string>(themeDetails?.BorderColor);
  const [firstFontColor, setFirstFontColor] = useState<string>(themeDetails?.Font1Color);
  const [secondFontColor, setSecondFontColor] = useState<string>(themeDetails?.Font2Color);
  const [penColor, setPenColor] = useState<string>(themeDetails?.PenColor);
  const [backgroundColor, setBackgroundColor] = useState<string>(themeDetails?.BackGroundColor);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerId } = useParams();

  const {
    handleSubmit, control, setValue,
  } = useForm<ThemesFormInput>({
    defaultValues: MainThemesDefaultValue,
  });

  const onSubmit = (values: ThemesFormInput) => {
    const payload = {
      CustId: newCustomerID,
      MainColor: values.MainColor,
      Font1Color: values.FirstFontColor,
      Font2Color: values.SecondFontColor,
      BorderColor: values.BorderColor,
      BackGroundColor: values.BackgroundColor,
      PenColor: values.PenColor,
      Logo: themeLogoURL,
    };
    const updatePayload = {
      CustId: customerId,
      MainColor: values.MainColor,
      Font1Color: values.FirstFontColor,
      Font2Color: values.SecondFontColor,
      BorderColor: values.BorderColor,
      BackGroundColor: values.BackgroundColor,
      PenColor: values.PenColor,
      Logo: file,
    };

    if (isSuccess && newCustomerID) {
      dispatch(startCreateTheme(payload));
    } else if (customerId) {
      dispatch(startCreateTheme(updatePayload));
    } else {
      showErrorToaster('Theme logo is not uploaded');
    }
  };

  function handleFile(
    files: FileList | File[],
    event: React.ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
  ) {
    event.preventDefault();

    if (files) {
      const fileList = files instanceof FileList ? Array.from(files) : files;

      if (fileList.length > 0) {
        const newFile = fileList[0];
        const fileSizeInBytes = newFile.size;
        const maxSizeInBytes = 1 * 1024 * 1024; // 1mb
        const allowedFormats = ['image/jpeg', 'image/png'];

        if (fileSizeInBytes > maxSizeInBytes) {
          showErrorToaster('File size exceeds the maximum limit 1 MB');
        } else if (!allowedFormats.includes(newFile.type)) {
          showErrorToaster('Only JPG and PNG formats are allowed');
        } else {
          const formData = new FormData();
          formData.append('ImageFile', newFile);
          formData.append('Type', 'CustLogo');
          formData.append('CustomerId', String(customerId || newCustomerID));
          dispatch(startUploadLogo(formData));
        }
      }
    }
  }

  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  // handle drag events
  const handleDrag = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files, e);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files, e);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleMainColorChange = (color: string) => {
    setMainColor(color);
    setValue(ThemesFormValue.Main_Color, color);
  };

  const handleBorderColorChange = (color: string) => {
    setValue(ThemesFormValue.Border_Color, color);
    setBorderColor(color);
  };

  const handleFirstFontColorChange = (color: string) => {
    setValue(ThemesFormValue.First_Font_Color, color);
    setFirstFontColor(color);
  };

  const handleSecondFontColorChange = (color: string) => {
    setValue(ThemesFormValue.Second_Font_Color, color);
    setSecondFontColor(color);
  };

  const handlePenColorChange = (color: string) => {
    setValue(ThemesFormValue.Pen_Color, color);
    setPenColor(color);
  };

  const handleBackgroundColorChange = (color: string) => {
    setValue(ThemesFormValue.Background_Color, color);
    setBackgroundColor(color);
  };

  useEffect(() => {
    if (customerId || newCustomerID) {
      setValue(ThemesFormValue.Main_Color, themeDetails?.MainColor);
      setValue(ThemesFormValue.Border_Color, themeDetails?.BorderColor);
      setValue(ThemesFormValue.First_Font_Color, themeDetails?.Font1Color);
      setValue(ThemesFormValue.Second_Font_Color, themeDetails?.Font2Color);
      setValue(ThemesFormValue.Pen_Color, themeDetails?.PenColor);
      setValue(ThemesFormValue.Background_Color, themeDetails?.BackGroundColor);
      setFile(themeDetails?.Logo);
    }
  }, [customerId, newCustomerID]);

  return (
    <form className={style.form_file_upload} onDragEnter={handleDrag} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <div className="themes_container">
          <div className="customerInfo">
            <span className={style.theme_left_border} />
            <span>Theme</span>
          </div>
        </div>
        <div className={style.theme_container}>
          <div className={style.update_logo}>
            Upload Logo
          </div>
          <input ref={inputRef} type="file" accept="image/png, image/jpeg" className={style.input_file_upload} onChange={handleChange} />
          <label className={style.label_file_upload}>
            <div>
              <img src={UploadIcon} alt="Icon" />
              <p className={style.drag_drop_text}>Upload Logo Drag & Drop your file here OR</p>
              <button type="button" className={style.upload_button} onClick={onButtonClick}>
                Upload a file
              </button>
            </div>
          </label>
          {dragActive && (
            <div
              className={style.drag_file_element}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
          <ColorPicker
            colors={themesColors}
            onColorChange={handleMainColorChange}
            control={control}
            colorHeading="Main Color"
            name={ThemesFormValue.Main_Color}
            selectedColorAll={mainColor}
            setColor={setMainColor}
          />
          <ColorPicker
            colors={themesColors}
            onColorChange={handleBorderColorChange}
            control={control}
            colorHeading="Border Color"
            name={ThemesFormValue.Border_Color}
            selectedColorAll={borderColor}
            setColor={setBorderColor}
          />
          <ColorPicker
            colors={themesColors}
            onColorChange={handleFirstFontColorChange}
            control={control}
            colorHeading="Font 1 Color"
            name={ThemesFormValue.First_Font_Color}
            selectedColorAll={firstFontColor}
            setColor={setFirstFontColor}
          />
          <ColorPicker
            colors={themesColors}
            onColorChange={handleSecondFontColorChange}
            control={control}
            colorHeading="Font 2 Color"
            name={ThemesFormValue.Second_Font_Color}
            selectedColorAll={secondFontColor}
            setColor={setSecondFontColor}
          />
          <ColorPicker
            colors={themesColors}
            onColorChange={handlePenColorChange}
            control={control}
            colorHeading="Pen Color"
            name={ThemesFormValue.Pen_Color}
            selectedColorAll={penColor}
            setColor={setPenColor}
          />
          <ColorPicker
            colors={themesColors}
            onColorChange={handleBackgroundColorChange}
            control={control}
            colorHeading="Background Color"
            name={ThemesFormValue.Background_Color}
            selectedColorAll={backgroundColor}
            setColor={setBackgroundColor}
          />
        </div>
      </Card>
      <div className={style.save_cancel}>
        <div>
          <Button className="button_cancel" onClick={() => navigate(`/${EndPoints.Customers}`)}>Back</Button>
        </div>
        <div className="button_margin_left">
          <Button type="submit" className="button_save_and_next">Save</Button>
        </div>
      </div>
    </form>
  );
}

export default Themes;
