import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function RecommendationSection() {
  const { reportPdfResponse2 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          <div>&nbsp;</div>
          <div>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">Recommendations</div>
      </div>
      <table className="w100 logoTable">
        <tr>
          <td>
            <div className="commonTableBorderStyle mt20 p30 w100">
              <pre><div>{reportPdfResponse2?.Recommendations?.[0]?.Recommendation}</div></pre>
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default RecommendationSection;
