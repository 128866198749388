export const tempData = {
  Data: [
    { id: '1', jobOrder: 'JobA' },
    { id: '2', jobOrder: 'JobB' },
    { id: '3', jobOrder: 'JobC' },
  ],
  TotalRecords: 3,
};

export enum FollowForm {
  codes = 'codes',
  recommendations = 'recommendations',
  inspectionField = 'inspectionField',
}

export const FollowDefaultValue = {
  recommendations: '',
};

export const inspectionTempData = {
  Data: [
    {
      id: '1', vessel: 'VesselA', indication: 'IndicationA', tubes: 10, percentage: 20,
    },
    {
      id: '2', vessel: 'VesselB', indication: 'IndicationB', tubes: 15, percentage: 30,
    },
    {
      id: '3', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '4', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '5', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '6', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '7', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '8', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
    {
      id: '9', vessel: 'VesselC', indication: 'IndicationC', tubes: 12, percentage: 25,
    },
  ],
  TotalRecords: 9,
};
