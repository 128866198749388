/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Button, Grid, IconButton, Modal, TextField, Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { IAddSite, SiteInfo } from '../utils/Type';
import { SiteInfoFields } from '../utils/constants';
import CustomCheckbox from '../../../../../../../../Common/ComponentCustomCheckBox';
import RequiredFiled from '../../../../../../../../utils/RequiredField';
import ComponentTextInput from '../../../../../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../../../../redux/rootState';
import {
  errorState, getPinCodeDetailsStart, removePinCodeDetails,
} from '../../../../../../../../redux/masters/masterSlice';
// eslint-disable-next-line import/no-cycle
import AddSMCModal from '../../../../../../../../Common/AddSMCModal';
import { addSitePayload } from '../utils/helper';
import { addSiteStart } from '../../../../redux/addJobSlice';
import { extRegex, phoneRegex } from '../../../../../../../../utils/regex';
import { mainFormSchema } from '../utils/validation';
import { RHFAutocompleteField } from '../../../../../../../../Common/ComponentAutoSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export function AddSiteInfo({ show, handleClose, mode }: IAddSite) {
  const {
    control, handleSubmit, watch, setValue, reset, formState: { errors }, trigger,
  } = useForm<SiteInfo>({
    resolver: yupResolver(mainFormSchema as ObjectSchema<SiteInfo>),
  });
  const {
    countries, pinCodeDetails, phoneCodes, isError, citySuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const dispatch = useDispatch();
  const { siteTypes } = useSelector((state: RootState) => state.jobStarterReducer);
  const [showSMCModal, setShowSMCModal] = useState(false);
  const { smcList } = useSelector((state: RootState) => state.getSmcUserReducer);

  const country = watch(SiteInfoFields.Country);
  const zip = watch(SiteInfoFields.Zip);
  const smc = watch(SiteInfoFields.SMC);
  const city = watch(SiteInfoFields.City);
  const state = watch(SiteInfoFields.State);

  const handleModalClose = () => {
    handleClose();
    reset();
    dispatch(removePinCodeDetails());
  };

  useEffect(() => {
    if (state?.length > 0) {
      trigger(SiteInfoFields.State);
    }
  }, [state]);

  useEffect(() => {
    if (zip?.length >= 5) {
      const queryPayload = {
        pinCode: zip,
        countryCode: country,
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (zip?.length < 5) {
      dispatch(errorState(true));
      setValue(SiteInfoFields.State, '');
      setValue(SiteInfoFields.City, '');
    }
  }, [country, zip]);

  useEffect(() => {
    dispatch(removePinCodeDetails());
    dispatch(errorState(false));
  }, []);

  useEffect(() => {
    if (pinCodeDetails) {
      setValue(SiteInfoFields.State, pinCodeDetails?.StateName);
    }
  }, [pinCodeDetails]);

  const handleTabCell = (smcId: string) => {
    setValue(SiteInfoFields.SMCId, smcId);
    const foundSmcName = smcList.find((item) => item.SmcId === smcId);
    if (foundSmcName) {
      const smcName = foundSmcName.SmcName;
      setValue(SiteInfoFields.SMCName, smcName);
      trigger(SiteInfoFields.SMCName);
    }
    setShowSMCModal(false);
  };

  const onSubmit = (values: SiteInfo) => {
    const payload = addSitePayload(values);
    handleModalClose();
    dispatch(addSiteStart(payload));
  };

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="customerInfo_container flex-row">
              <div className="customerInfo">
                {mode}
              </div>
              <div className="customerInfoHeaderFilter">
                <CustomCheckbox
                  name={SiteInfoFields.SMC}
                  control={control}
                  label="SMC"
                />
                <IconButton
                  aria-label="close"
                  onClick={handleModalClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="customerInfo_main_container div_job_container_info customModalScroll">
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Site
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 30 }}
                      name={SiteInfoFields.Site}
                      id={SiteInfoFields.Site}
                      errors={errors}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer"> Contact </label>
                    <ComponentTextInput
                      control={control}
                      regEx={/^[A-Za-z ]+$/}
                      errors={errors}
                      inputProps={{ maxLength: 16 }}
                      name={SiteInfoFields.Contact}
                      id={SiteInfoFields.Contact}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Address
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 200 }}
                      errors={errors}
                      name={SiteInfoFields.Address}
                      id={SiteInfoFields.Address}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Address (Optional)</label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 200 }}
                      name={SiteInfoFields.OptionalAddress}
                      errors={errors}
                      id={SiteInfoFields.OptionalAddress}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Country
                      <RequiredFiled />
                    </label>
                    <ComponentSelectInput
                      name={SiteInfoFields.Country}
                      control={control}
                      errors={errors}
                      size="small"
                      entries={countries}
                      defaultValue="US"
                    />
                  </div>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Zip
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 10 }}
                      name={SiteInfoFields.Zip}
                      errors={errors}
                      id={SiteInfoFields.Zip}
                      regEx={extRegex}
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      State
                      <RequiredFiled />
                    </label>
                    <ComponentTextInput
                      control={control}
                      inputProps={{ maxLength: 2 }}
                      name={SiteInfoFields.State}
                      errors={errors}
                      id={SiteInfoFields.State}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      City
                      <RequiredFiled />
                    </label>
                    {renderCityInput() ? (
                      <RHFAutocompleteField
                        options={citySuggestions || []}
                        control={control}
                        name={SiteInfoFields.SITE_OTHER_CITY}
                      />
                    )
                      : (
                        <ComponentSelectInput
                          name={SiteInfoFields.City}
                          defaultValue=""
                          control={control}
                          errors={errors}
                          size="small"
                          entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                        />
                      )}
                  </div>
                </Grid>

                <Grid item md={1.5} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Code
                    </label>
                    <ComponentSelectInput
                      name={SiteInfoFields.PhoneCode}
                      control={control}
                      size="small"
                      errors={errors}
                      entries={phoneCodes}
                      defaultValue="+1"
                    />
                  </div>
                </Grid>

                <Grid item md={3.5} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Phone
                    </label>
                    <ComponentTextInput
                      control={control}
                      name={SiteInfoFields.Phone}
                      id={SiteInfoFields.Phone}
                      errors={errors}
                      regEx={phoneRegex}
                      defaultValue=""
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Cell</label>
                    <ComponentTextInput
                      control={control}
                      name={SiteInfoFields.Cell}
                      errors={errors}
                      id={SiteInfoFields.Cell}
                      regEx={phoneRegex}
                      defaultValue=""
                      size="small"
                    />
                  </div>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Site Type
                    </label>
                    <ComponentSelectInput
                      name={SiteInfoFields.SiteType}
                      control={control}
                      size="small"
                      entries={siteTypes}
                      defaultValue="11"
                    />
                  </div>
                </Grid>

                {smc
                  && (
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_Customer">
                          Safety Management Company
                          <RequiredFiled />
                        </label>
                        <Controller
                          control={control}
                          name={SiteInfoFields.SMCName}
                          render={({ field: { value } }) => (
                            <TextField
                              size="small"
                              placeholder="Site"
                              value={value}
                              onClick={() => {
                                setShowSMCModal(true);
                              }}
                              inputProps={{ maxLength: 255 }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <Tooltip title="Search here..">
                                    <IconButton onClick={() => setShowSMCModal(true)}>
                                      <SearchIcon />
                                    </IconButton>
                                  </Tooltip>
                                ),
                              }}
                              error={!!errors[SiteInfoFields.SMCName]}
                              helperText={!!errors[SiteInfoFields.SMCName] && 'Safety Management Company is required'}
                            />
                          )}
                        />
                      </div>
                    </Grid>
                  )}

                <Grid item md={12} sm={12} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_job">Note</label>
                    <Controller
                      control={control}
                      name={SiteInfoFields.Note}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 2056 }}
                          value={value}
                          onChange={onChange}
                          multiline
                          rows={5}
                          maxRows={5}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div className="button_margin_left">
                      <Button type="submit" disabled={isError} className="button_save_and_next"> Save </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </Box>
      </Modal>
      <AddSMCModal show={showSMCModal} setOpenFrom={() => setShowSMCModal(false)} handleTabCell={handleTabCell} />
    </div>
  );
}
