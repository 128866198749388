import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DUE_TAB } from '../utils/constants';
import {
  FiltersDataTypes, InspectionLetterResult, ListPayload, UpdateStatusPayload,
} from '../utils/types';

const initialState = {
  selectedTab: DUE_TAB,
  isLoading: true,
  dueListData: {} as InspectionLetterResult,
  filersData: {} as FiltersDataTypes,
};

export const sliceInspectionLetter = createSlice({
  name: 'InspectionLetterSlice',
  initialState,
  reducers: {

    // Tabs Select State
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },

    // Reset State

    removeSelectedJobOrder() {
      return initialState;
    },

    // Get Due List Data
    getDueListDataStart(state, _action: PayloadAction<ListPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDueListDataSuccess(state, action: PayloadAction<InspectionLetterResult>) {
      return {
        ...state,
        isLoading: false,
        dueListData: action.payload,
      };
    },
    getDueListDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    setFiltersData(state, action: PayloadAction<FiltersDataTypes>) {
      return {
        ...state,
        filersData: action.payload as FiltersDataTypes,
      };
    },
    // Update State Pas Due
    updateStatusForPasDueStart(state, _action: PayloadAction<UpdateStatusPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateStatusForPasDueStartSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateStatusForPasDueStartFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});
export const {
  setSelectedTab, removeSelectedJobOrder, getDueListDataStart, getDueListDataSuccess, getDueListDataFailure,
  setFiltersData, updateStatusForPasDueStart, updateStatusForPasDueStartSuccess, updateStatusForPasDueStartFailure,
} = sliceInspectionLetter.actions;
export const inspectionLetterReducer = sliceInspectionLetter.reducer;

export type InspectionLetterActions =
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof removeSelectedJobOrder>
  | ReturnType<typeof getDueListDataStart>
  | ReturnType<typeof getDueListDataSuccess>
  | ReturnType<typeof getDueListDataFailure>
  | ReturnType<typeof setFiltersData>
  | ReturnType<typeof updateStatusForPasDueStart>
  | ReturnType<typeof updateStatusForPasDueStartSuccess>
  | ReturnType<typeof updateStatusForPasDueStartFailure>;
