/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  useEffect,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { StartTimeComponentPropTypes } from './utils/type';
import { CommonDateInput } from '../../../Common/CommonDateInput';
import { StartTimeDefaultData, StartTimeFields } from '../utils/constant';
import { TimeDateFormInfo } from '../utils/defectType';
import { startTimeSchema } from '../utils/validation';
import {
  dateTimeStart, getDefectByIdStart, resetDefectData, updateVesselDefectStart,
} from '../redux/DefectRedux/sliceVesselDefect';
import RequiredFiled from '../../../Common/RequiredField';
import Common24HTimeInput from '../../../Common/Common24HTimePicker';
import { RootState } from '../../../redux/rootState';
import { EditStartTimePayload } from '../utils/helper/EditStartFinishTimeHelper';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function StartTimeComponent({
  show, setOpenFrom, label, callback, rowId, setRowId,
}: StartTimeComponentPropTypes): JSX.Element {
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<TimeDateFormInfo>({
    resolver: yupResolver(startTimeSchema),
    defaultValues: StartTimeDefaultData as unknown as TimeDateFormInfo,
  });
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  const { defectDataById } = useSelector((state: RootState) => state.VesselDefectReducer);

  // const flightOutDate = watch();

  // const minFlightInDate = flightOutDate?.add(1, 'day');
  const handleSave = (values: TimeDateFormInfo) => {
    if (rowId) {
      const payLoad = EditStartTimePayload(values, defectDataById);
      dispatch(updateVesselDefectStart({ payLoad, callback }));
      setOpenFrom();
    } else {
      const payLoad = {
        JobOrder: jobOrderId || '',
        VesselId: vesselId || '',
        DefectId: '',
        Date: dayjs(values.date).format('DD-MM-YYYY'),
        Time: dayjs(values.time).format('HH:mm:ss'),
        List: 'Start',
        DefectType: 'Time Entry',
      };
      dispatch(dateTimeStart({ payLoad, callback }));
      setOpenFrom();
    }
  };

  useEffect(() => {
    if (rowId && show) {
      const payload = {
        id: rowId,
      };
      dispatch(getDefectByIdStart(payload));
    } return () => {
      setRowId('');
      dispatch(resetDefectData());
    };
  }, [rowId, show]);

  useEffect(() => {
    if (defectDataById) {
      if (defectDataById?.Date) {
        const parts = defectDataById?.Date.split('-');
        const formattedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
        setValue(StartTimeFields.date, dayjs(formattedDate));
      }
      setValue(StartTimeFields.time, dayjs(defectDataById?.Time, 'HH:mm:ss'));
    }
  }, [defectDataById]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {rowId ? 'Edit Start Time' : label}
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Date
                    <RequiredFiled />
                  </label>
                  <CommonDateInput
                    // minDate={minFlightInDate}
                    control={control}
                    errors={errors}
                    name={StartTimeFields.date}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Time
                    <RequiredFiled />
                  </label>
                  <Common24HTimeInput
                    control={control}
                    errors={errors}
                    name={StartTimeFields.time}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button type="button" className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default StartTimeComponent;
