import React from 'react';
import { ComponentAddFreonCylinder } from './Components/ComponentAddFreonCylinder';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';

function ScreenAddFreonCylinder() {
  return (
    <>
      <ComponentPageHeader subHeading="Freon Cylinders" />
      <ComponentAddFreonCylinder />
    </>
  );
}

export default ScreenAddFreonCylinder;
