import React from 'react';
import './StylePageHeader.scss';
import { XlsxIcon } from '../../assets/TableIcons/XlsxIcon';
import { PrintIcon } from '../../assets/TableIcons';

interface SubHeading {
  subHeading?: string
  fieldId?: string;
  fieldName?: string;
  printIcon?: boolean;
  downloadExcel?: () => void;
  handlePrintClick?: () => void;
}

export default function ComponentPageHeader({
  subHeading, fieldId, fieldName, downloadExcel, printIcon, handlePrintClick,
}: SubHeading) {
  return (
    <div className="sub_header_container">
      <div className="sub_heading">{subHeading}</div>
      <div className="print_container">
        {fieldId && (
          <>
            <span className="customerID">
              {fieldName}
              {' '}
              :
            </span>
            <div className="job_input_div">
              {/* <input disabled value={fieldId} className="job_input_style" /> */}
              <div className="job_input_style">{fieldId}</div>
            </div>
          </>
        )}
        {printIcon
          && (
            <div onClick={handlePrintClick}>
              <PrintIcon />
            </div>
          )}
        {downloadExcel
          && (
            <div onClick={downloadExcel}>
              <XlsxIcon />
            </div>
          )}
      </div>
    </div>
  );
}
