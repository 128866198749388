import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  KitPriceListActions, getKitPriceStart, successGetKitPrice,
  failureGetKitPrice, updateKitPrice, successUpdateKitPrice, failureUpdateKitPrice,

} from './sliceKitPrice';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePutRequest } from '../../../../Common/NetworkOps';
import { GetAllKitPriceApiResponse, UpdateKitPriceApiResponseTypes, UpdateKitPriceData } from '../utils/TypesKitPriceList';
import { showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';

async function GetKitPriceList(): Promise<GetAllKitPriceApiResponse> {
  const url = `${Config.system.getKitPriceList}`;
  const result = await makeGetRequest<GetAllKitPriceApiResponse>(url);
  return result?.data;
}

export const epicGetKitPriceList = (action$: Observable<KitPriceListActions>) => action$.pipe(
  filter(getKitPriceStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(() => from(GetKitPriceList()).pipe(
    map((res: GetAllKitPriceApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetKitPrice(res.BMT.Result);
      }

      return failureGetKitPrice(res?.BMT.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getKitPriceStart.match))),
    catchError((error: AxiosError<GetAllKitPriceApiResponse>) => of(failureGetKitPrice(error.response?.data.BMT.ResponseMessage as string))),
  )),
);

async function UpdateKitPrice(data: UpdateKitPriceData): Promise<UpdateKitPriceApiResponseTypes> {
  const url = `${Config.system.updateKitPriceList}`;
  const result = await makePutRequest<UpdateKitPriceApiResponseTypes>(url, data);
  return result?.data;
}

export const epicUpdateKitPrices = (action$: Observable<KitPriceListActions>) => action$.pipe(
  filter(updateKitPrice.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateKitPrice(data)).pipe(
    mergeMap((res: UpdateKitPriceApiResponseTypes) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateKitPrice(), getKitPriceStart());
      }
      return of(failureUpdateKitPrice(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateKitPrice.match))),
    catchError((error: AxiosError<UpdateKitPriceApiResponseTypes>) => of(failureUpdateKitPrice(error.response?.data.BMT.ResponseMessage as string))),
  )),
);
