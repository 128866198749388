export const customOptions = {
  options: ['inline', 'blockType', 'list'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  blockType: {
    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'UnorderedList', 'OrderedList'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
