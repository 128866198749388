/* eslint-disable max-len */
import React from 'react';

export function DeleteIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.875 11.25C1.53125 11.25 1.23688 11.1275 0.991877 10.8825C0.746877 10.6375 0.624586 10.3433 0.625002 10V1.875C0.447919 1.875 0.299377 1.815 0.179377 1.695C0.0593773 1.575 -0.000414504 1.42667 2.16263e-06 1.25C2.16263e-06 1.07292 0.0600022 0.924377 0.180002 0.804377C0.300002 0.684377 0.448335 0.624585 0.625002 0.625002H3.125C3.125 0.447919 3.185 0.299377 3.305 0.179377C3.425 0.0593771 3.57334 -0.000414504 3.75 2.16263e-06H6.25C6.42709 2.16263e-06 6.57563 0.0600022 6.69563 0.180002C6.81563 0.300002 6.87542 0.448335 6.875 0.625002H9.375C9.55209 0.625002 9.70063 0.685002 9.82063 0.805002C9.94063 0.925002 10.0004 1.07334 10 1.25C10 1.42709 9.94 1.57563 9.82 1.69563C9.7 1.81563 9.55167 1.87542 9.375 1.875V10C9.375 10.3438 9.2525 10.6381 9.0075 10.8831C8.7625 11.1281 8.46834 11.2504 8.125 11.25H1.875ZM1.875 1.875V10H8.125V1.875H1.875ZM3.125 8.125C3.125 8.30208 3.185 8.45063 3.305 8.57063C3.425 8.69063 3.57334 8.75042 3.75 8.75C3.92709 8.75 4.07563 8.69 4.19563 8.57C4.31563 8.45 4.37542 8.30167 4.375 8.125V3.75C4.375 3.57292 4.315 3.42438 4.195 3.30438C4.075 3.18438 3.92667 3.12459 3.75 3.125C3.57292 3.125 3.42438 3.185 3.30438 3.305C3.18438 3.425 3.12459 3.57334 3.125 3.75V8.125ZM5.625 8.125C5.625 8.30208 5.685 8.45063 5.805 8.57063C5.925 8.69063 6.07334 8.75042 6.25 8.75C6.42709 8.75 6.57563 8.69 6.69563 8.57C6.81563 8.45 6.87542 8.30167 6.875 8.125V3.75C6.875 3.57292 6.815 3.42438 6.695 3.30438C6.575 3.18438 6.42667 3.12459 6.25 3.125C6.07292 3.125 5.92438 3.185 5.80438 3.305C5.68438 3.425 5.62459 3.57334 5.625 3.75V8.125Z" fill="#F06548" />
    </svg>
  );
}
