import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';

function ComponentExpenseCustomer() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const customerData = reportPdfResponse4?.Expense_Report?.CustomerData;
  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Customer
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Customer Name
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Location
        </td>
      </tr>
      <tr>
        <td className="textCenter">{customerData?.CustomerId}</td>
        <td className="textCenter">{customerData?.CustomerName}</td>
        <td className="textCenter">{customerData?.Location}</td>
      </tr>
    </table>
  );
}

export default ComponentExpenseCustomer;
