import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'react-redux';
import { ScannedReceiptsDataType, TypesReceiptsDialogBox } from '../utils/types';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import ComponentAddReceipt from './ComponentAddReceipt';
import { deleteScannedReceiptsStart, getScannedReceipts } from '../redux/expenseReportSlice';
import DialogScannedImage from './ComponentScannedImage';
import { RootState } from '../../../redux/rootState';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentScannedReceipts({ show, setOpenFrom, jobOrderId }: TypesReceiptsDialogBox) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedReceiptId, setSelectedReceiptId] = useState('');
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [filePath, setFilePath] = useState('');
  const dispatch = useDispatch();
  const { ScannedReceiptsData } = useSelector((state: RootState) => state.expenseReportReducer);

  const handleClose = () => {
    setOpenFrom();
  };

  const ScannedReceiptsColumn = useMemo(() => [
    { key: 'sn', label: 'SN' },
    { key: 'action', label: 'Action' },
    { key: 'file', label: 'File' },
  ], []);

  const handleData = () => {
    const payload = {
      jobOrderId,
      page,
      rowsPerPage,
    };
    dispatch(getScannedReceipts(payload));
  };

  useEffect(() => {
    if (show) {
      handleData();
    }
  }, [jobOrderId, show]);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedReceiptId(id);
  };

  const handleDeleteModal = () => {
    const payload = {
      id: selectedReceiptId,
      jobOrderId,
      page,
      rowsPerPage,
      searchQuery: '',
    };
    dispatch(deleteScannedReceiptsStart(payload));
    setDeleteModal(false);
  };

  const handleButtonClick = () => {
    setOpen(!open);
  };

  const handleAction = () => {
    setOpen(true);
  };

  const tableData: ScannedReceiptsDataType[] = useMemo(() => ScannedReceiptsData?.Data?.map((item, index) => {
    const filename = item?.FilePath?.split('/').pop()?.split('\\').pop() || '';
    const transformedDataItem = {
      sn: index,
      action: 'eye&delete',
      file: filename || '',
      id: String(item.Id),
    };
    return transformedDataItem;
  }), [ScannedReceiptsData]);

  const handleView = (id: string) => {
    const item = ScannedReceiptsData?.Data.find((obj) => String(obj.Id) === id);
    setFilePath(item?.FilePath || '');
    setOpenImage(true);
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            Scanned Receipts
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="customerID">
                Job Order #
              </span>
              <div className="job_input_div">
                <div className="job_input_style">{jobOrderId}</div>
              </div>
            </div>
            <Button
              className="button_save_and_next"
              onClick={handleAction}
            >
              Add
            </Button>
          </div>
          <div style={{ marginTop: '14px' }}>
            <DataTable<ScannedReceiptsDataType>
              data={tableData}
              totalRecords={ScannedReceiptsData?.TotalRecords}
              columns={ScannedReceiptsColumn}
              handleData={handleData}
              page={page}
              setPage={setPage}
              handleViews={handleView}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              handleDelete={handleDelete}
            />
          </div>
        </DialogContent>
      </Dialog>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
      { open && (<ComponentAddReceipt show={open} setOpenFrom={handleButtonClick} jobOrderId={jobOrderId} page={page} rowsPerPage={rowsPerPage} />)}
      { openImage && (<DialogScannedImage show={openImage} setOpenImage={setOpenImage} filePath={filePath} />)}
    </>
  );
}

export default ComponentScannedReceipts;
