import {
  Grid, IconButton, TextField,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import taiLogo from '../../../assets/tailogo.png';
import '../Styles.scss';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import EndPoints from '../../../Routes/EndPoints';
import { errorMessageState, resetLoginStates, sendLoginData } from './redux/sliceLogin';
import ComponentLeftVector from '../../../Common/LeftVector/ComponentLeftVector';
import { loginValidationSchema } from '../../../utils/Schema/ValidationSchema';
import { FormTypes } from './utils/types';
import { initialValuesLogin } from './utils/constantsLogin';
import { RootState } from '../../../redux/rootState';
import password_hide_icon from '../../../assets/password_hide_icon.svg';
import password_show_icon from '../../../assets/password_show_icon.svg';
import { stateReset } from '../ScreenOtpVerify/redux/sliceOtpVerify';

function ScreenLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const [showPassword, setShowPassword] = useState(false);
  const {
    otpSent, errorMessage, isError, isLoading,
  } = useSelector((state: RootState) => state.authReducer);
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleForgot = () => {
    dispatch(resetLoginStates());
    dispatch(stateReset());
    navigate(EndPoints.SCREEN_FORGOT_PASSWORD);
  };

  const endAdornment = (
    <div className="password_show_hide">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? (
          <img
            src={password_show_icon}
            alt="Icon"
            width={100}
            height={100}
          />
        ) : (
          <img
            src={password_hide_icon}
            alt="Icon"
            width={100}
            height={100}
          />
        )}
      </IconButton>
    </div>
  );
  const onSubmit = (values: FormTypes) => {
    const loginData = { Email: values.Email, Password: values.Password };
    dispatch(sendLoginData(loginData));
  };

  useEffect(() => {
    if (otpSent) {
      navigate(EndPoints.SCREEN_OTP);
      dispatch(resetLoginStates());
      dispatch(stateReset());
    }
  }, [otpSent]);

  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: initialValuesLogin,
    resolver: yupResolver(loginValidationSchema(translations)),
  });

  const { Email, Password } = watch();

  useEffect(() => {
    dispatch(errorMessageState());
  }, [Email, Password]);

  return (
    <div className="div-password">
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <ComponentLeftVector />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <div className="mobStyle">
            <div className="rightContainer">
              <img src={taiLogo} alt="TAI Logo" className="taiLogo" />
              <div className="loginContainer" style={{ paddingRight: '0px' }}>
                <h2>
                  {translations.signIn}
                </h2>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="login_email_field">
                    <div className="div_label_Email">
                      <label htmlFor="email" className="labelStyle">{translations.email}</label>
                    </div>
                    <TextField
                      autoFocus
                      className="text_field_margin nonCapitalizeField"
                      fullWidth
                      id="email"
                      size="small"
                      inputProps={{ maxLength: 64 }}
                      placeholder="Enter your email ID"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('Email')}
                      error={!!errors.Email}
                      helperText={errors.Email?.message}
                    />
                  </div>
                  <div className="sub_div login_password_field">
                    <div className="div_label_Email">
                      <label className="labelStyle">{translations.password}</label>
                    </div>
                    <TextField
                      className="text_field_margin password_ padding_0 nonCapitalizeField"
                      fullWidth
                      size="small"
                      id="password"
                      placeholder="Enter your password"
                      type={showPassword ? 'text' : 'password'}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('Password')}
                      error={!!errors.Password}
                      inputProps={{ maxLength: 16 }}
                      helperText={errors.Password?.message}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </div>
                  <div className="errorMessage">{isError ? errorMessage : ''}</div>
                  <div className="style_forgot_password">
                    <button type="button" className="forgotLabelStyle" onClick={handleForgot}>{translations.forgotPassword}</button>
                  </div>
                  <LoadingButton variant="contained" loading={isLoading} fullWidth className="button-color" type="submit">
                    {translations.login}
                  </LoadingButton>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenLogin;
