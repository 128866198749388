import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../Common/Config';
import { makeGetRequest, makePatchRequest } from '../../../Common/NetworkOps';
import {
  GetById, GetListingPayload, ListingResponse,
} from '../../../utils/type';
import {
  GetRecmByIdResponse, GetRecomByIdPayload, GetRecomendationsList, GetStartupTextListRes,
  SetRecomTextPayload,
  UpdateRecomPayload,
} from '../utils/types';
import {
  getRecommendationByIdStart,
  getRecommendationFailure, getRecommendationsStart, getRecommendationsSuccess, RecommendationAction,
  getRecommendationByIdSuccess, getRecommendationByIdFailure,
  getStartupTextStart,
  getStartupTextSuccess,
  getStartupTextFailure,
  getFollowUpTextStart,
  getFollowUpTextSuccess,
  getFollowupTextFailure,
  setRecomText,
  updateRecomStart,
  updateRecomSuccess,
  updateRecomFailure,
} from './recommendationSlice';
import EndPoints from '../../../Routes/EndPoints';

async function getRecommendations(data: GetListingPayload): Promise<ListingResponse<GetRecomendationsList>> {
  const url = `${Config.recommendations.getRecommendations}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<ListingResponse<GetRecomendationsList>>(url);
  return result.data;
}

export const getRecommendationsEpic = (action$ : Observable<RecommendationAction>) => action$.pipe(
  ofType(getRecommendationsStart.type),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:GetListingPayload) => from(getRecommendations(data)).pipe(
    map((res: ListingResponse<GetRecomendationsList>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getRecommendationsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getRecommendationFailure();
    }),
    takeUntil(action$.pipe(filter(getRecommendationsStart.match))),
    catchError((error) => of(getRecommendationFailure(error))),
  )),
);

async function getRecmById(data: GetRecomByIdPayload): Promise<GetById<GetRecmByIdResponse>> {
  const url = `${Config.recommendations.getRecmById}?jobOrder=${data.recomId}`;
  const result = await makeGetRequest<GetById<GetRecmByIdResponse>>(url);
  return result.data;
}

export const getRecmByIdEpic = (action$ : Observable<RecommendationAction>) => action$.pipe(
  ofType(getRecommendationByIdStart.type),
  map((x) => x.payload),
  mergeMap((data:GetRecomByIdPayload) => from(getRecmById(data)).pipe(
    mergeMap((res: GetById<GetRecmByIdResponse>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const recomTextPayload:SetRecomTextPayload = {
          value: res.BMT.Result.RecommendationText,
          replaceText: true,
          preAdd: false,
        };
        return of(getRecommendationByIdSuccess(res.BMT.Result), setRecomText(recomTextPayload));
      }
      if (res.BMT.ResponseCode === Config.NO_SUCH_RECORD) {
        const { navigate } = data;
        showErrorToaster(res.BMT.ResponseMessage);
        navigate(`/${EndPoints.RECOMMENDATIONS}`);
        return of(getRecommendationByIdFailure());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getRecommendationByIdFailure());
    }),
    takeUntil(action$.pipe(filter(getRecommendationByIdStart.match))),
    catchError((error) => of(getRecommendationByIdFailure(error))),
  )),
);

async function getStartupText(): Promise<GetById<GetStartupTextListRes[]>> {
  const url = `${Config.recommendations.getStartupText}`;
  const result = await makeGetRequest<GetById<GetStartupTextListRes[]>>(url);
  return result.data;
}

export const getStartupTextEpic = (action$ : Observable<RecommendationAction>) => action$.pipe(
  ofType(getStartupTextStart.type),
  map((x) => x.payload),
  mergeMap(() => from(getStartupText()).pipe(
    map((res: GetById<GetStartupTextListRes[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getStartupTextSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getStartupTextFailure();
    }),
    takeUntil(action$.pipe(filter(getStartupTextStart.match))),
    catchError((error) => of(getStartupTextFailure(error))),
  )),
);

async function getFollowUpText(jobOrderId:string): Promise<GetById<string>> {
  const url = `${Config.recommendations.getFollowUpText}?jobOrder=${jobOrderId}`;
  const result = await makeGetRequest<GetById<string>>(url);
  return result.data;
}

export const getFollowupTextEpic = (action$ : Observable<RecommendationAction>) => action$.pipe(
  ofType(getFollowUpTextStart.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getFollowUpText(data)).pipe(
    mergeMap((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        const payload:SetRecomTextPayload = {
          value: res.BMT.Result,
          replaceText: false,
          preAdd: false,
        };
        return of(getFollowUpTextSuccess(res.BMT.Result), setRecomText(payload));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(getFollowupTextFailure());
    }),
    takeUntil(action$.pipe(filter(getFollowUpTextStart.match))),
    catchError((error) => of(getFollowupTextFailure(error))),
  )),
);

async function updateRecom(payload:UpdateRecomPayload): Promise<GetById<string>> {
  const url = `${Config.recommendations.getRecmById}`;
  const result = await makePatchRequest<GetById<string>>(url, payload);
  return result.data;
}

export const updateRecomEpic = (action$ : Observable<RecommendationAction>) => action$.pipe(
  ofType(updateRecomStart.type),
  map((x) => x.payload),
  mergeMap((data: UpdateRecomPayload) => from(updateRecom(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return updateRecomSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateRecomFailure();
    }),
    takeUntil(action$.pipe(filter(updateRecomStart.match))),
    catchError((error) => of(updateRecomFailure(error))),
  )),
);
