/* eslint-disable max-len */
import React from 'react';

export function DataSheetIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 2V21.2H6H11.2H20V2H4ZM7.2 2.4C7.4212 2.4 7.6 2.5788 7.6 2.8C7.6 3.0212 7.4212 3.2 7.2 3.2C6.9788 3.2 6.8 3.0212 6.8 2.8C6.8 2.5788 6.9788 2.4 7.2 2.4ZM6 2.4C6.2212 2.4 6.4 2.5788 6.4 2.8C6.4 3.0212 6.2212 3.2 6 3.2C5.7788 3.2 5.6 3.0212 5.6 2.8C5.6 2.5788 5.7788 2.4 6 2.4ZM4.8 2.4C5.0212 2.4 5.2 2.5788 5.2 2.8C5.2 3.0212 5.0212 3.2 4.8 3.2C4.5788 3.2 4.4 3.0212 4.4 2.8C4.4 2.5788 4.5788 2.4 4.8 2.4ZM19.6 20.8H11.2H6H4.4V3.6H19.6V20.8Z" fill="#092245" />
      <path d="M5.59961 5.19922H9.59961V5.99922H5.59961V5.19922Z" fill="#092245" />
      <path d="M5.59961 6.80078H9.59961V7.20078H5.59961V6.80078Z" fill="#092245" />
      <path d="M5.59961 8H9.59961V8.4H5.59961V8Z" fill="#092245" />
      <path d="M5.59961 9.19922H9.59961V9.59922H5.59961V9.19922Z" fill="#092245" />
      <path d="M5.59961 10.3994H9.59961V10.7994H5.59961V10.3994Z" fill="#092245" />
      <path d="M5.59961 11.6006H9.59961V12.0006H5.59961V11.6006Z" fill="#092245" />
      <path d="M5.59961 12.8008H9.59961V13.2008H5.59961V12.8008Z" fill="#092245" />
      <path d="M5.59961 14H9.59961V14.4H5.59961V14Z" fill="#092245" />
      <path d="M5.59961 15.1992H9.59961V15.5992H5.59961V15.1992Z" fill="#092245" />
      <path d="M5.59961 16.3994H9.59961V16.7994H5.59961V16.3994Z" fill="#092245" />
      <path d="M5.59961 17.6006H9.59961V18.0006H5.59961V17.6006Z" fill="#092245" />
      <path d="M13.1997 5.19922H15.1997V5.99922H13.1997V5.19922Z" fill="#092245" />
      <path d="M13.1997 6.80078H15.1997V7.20078H13.1997V6.80078Z" fill="#092245" />
      <path d="M13.1997 8H15.1997V8.4H13.1997V8Z" fill="#092245" />
      <path d="M13.1997 9.19922H15.1997V9.59922H13.1997V9.19922Z" fill="#092245" />
      <path d="M13.1997 10.3994H15.1997V10.7994H13.1997V10.3994Z" fill="#092245" />
      <path d="M13.1997 11.6006H15.1997V12.0006H13.1997V11.6006Z" fill="#092245" />
      <path d="M13.1997 12.8008H15.1997V13.2008H13.1997V12.8008Z" fill="#092245" />
      <path d="M13.1997 14H15.1997V14.4H13.1997V14Z" fill="#092245" />
      <path d="M13.1997 15.1992H15.1997V15.5992H13.1997V15.1992Z" fill="#092245" />
      <path d="M13.1997 16.3994H15.1997V16.7994H13.1997V16.3994Z" fill="#092245" />
      <path d="M13.1997 17.6006H15.1997V18.0006H13.1997V17.6006Z" fill="#092245" />
      <path d="M16 5.19922H18V5.99922H16V5.19922Z" fill="#092245" />
      <path d="M16 6.80078H18V7.20078H16V6.80078Z" fill="#092245" />
      <path d="M16 8H18V8.4H16V8Z" fill="#092245" />
      <path d="M16 9.19922H18V9.59922H16V9.19922Z" fill="#092245" />
      <path d="M16 10.3994H18V10.7994H16V10.3994Z" fill="#092245" />
      <path d="M16 11.6006H18V12.0006H16V11.6006Z" fill="#092245" />
      <path d="M16 12.8008H18V13.2008H16V12.8008Z" fill="#092245" />
      <path d="M16 14H18V14.4H16V14Z" fill="#092245" />
      <path d="M16 15.1992H18V15.5992H16V15.1992Z" fill="#092245" />
      <path d="M16 16.3994H18V16.7994H16V16.3994Z" fill="#092245" />
      <path d="M16 17.6006H18V18.0006H16V17.6006Z" fill="#092245" />
      <path d="M10.4004 5.19922H12.4004V5.99922H10.4004V5.19922Z" fill="#092245" />
      <path d="M10.4004 6.80078H12.4004V7.20078H10.4004V6.80078Z" fill="#092245" />
      <path d="M10.4004 8H12.4004V8.4H10.4004V8Z" fill="#092245" />
      <path d="M10.4004 9.19922H12.4004V9.59922H10.4004V9.19922Z" fill="#092245" />
      <path d="M10.4004 10.3994H12.4004V10.7994H10.4004V10.3994Z" fill="#092245" />
      <path d="M10.4004 11.6006H12.4004V12.0006H10.4004V11.6006Z" fill="#092245" />
      <path d="M10.4004 12.8008H12.4004V13.2008H10.4004V12.8008Z" fill="#092245" />
      <path d="M10.4004 14H12.4004V14.4H10.4004V14Z" fill="#092245" />
      <path d="M10.4004 15.1992H12.4004V15.5992H10.4004V15.1992Z" fill="#092245" />
      <path d="M10.4004 16.3994H12.4004V16.7994H10.4004V16.3994Z" fill="#092245" />
      <path d="M10.4004 17.6006H12.4004V18.0006H10.4004V17.6006Z" fill="#092245" />
      <path d="M10.4 19.9992C10.6209 19.9992 10.8 19.8201 10.8 19.5992C10.8 19.3783 10.6209 19.1992 10.4 19.1992C10.1791 19.1992 10 19.3783 10 19.5992C10 19.8201 10.1791 19.9992 10.4 19.9992Z" fill="#092245" />
      <path d="M11.5997 19.9992C11.8206 19.9992 11.9997 19.8201 11.9997 19.5992C11.9997 19.3783 11.8206 19.1992 11.5997 19.1992C11.3788 19.1992 11.1997 19.3783 11.1997 19.5992C11.1997 19.8201 11.3788 19.9992 11.5997 19.9992Z" fill="#092245" />
      <path d="M12.8004 19.9992C13.0213 19.9992 13.2004 19.8201 13.2004 19.5992C13.2004 19.3783 13.0213 19.1992 12.8004 19.1992C12.5795 19.1992 12.4004 19.3783 12.4004 19.5992C12.4004 19.8201 12.5795 19.9992 12.8004 19.9992Z" fill="#092245" />
      <path d="M13.9996 19.9992C14.2205 19.9992 14.3996 19.8201 14.3996 19.5992C14.3996 19.3783 14.2205 19.1992 13.9996 19.1992C13.7787 19.1992 13.5996 19.3783 13.5996 19.5992C13.5996 19.8201 13.7787 19.9992 13.9996 19.9992Z" fill="#092245" />
    </svg>
  );
}
