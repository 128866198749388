import React from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { DeleteModalIcon } from '../../media/images/DeleteModalIcon';
// import './DeleteModalStyle.scss';
import { TypesDeleteModal } from './TypeDelete';
import styles from './DeleteModalStyle.module.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function ComponentDeleteModal({ show, setOpenFrom, ...props }: TypesDeleteModal): JSX.Element {
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.deleteIcon}>
          <DeleteModalIcon />
        </div>
        <div className={styles.modalModalHeading}>
          Are you sure?
        </div>
        <div className={styles.modalModalDescription}>
          Are you sure you want to delete this record(s)?
        </div>

        <div className={styles.divButtons}>
          <button
            type="button"
            className={`btn ${styles.deleteBtn}`}
            onClick={() => {
              if (props?.handleDelete) {
                props.handleDelete();
              }
            }}
          >
            Yes, Delete it!
          </button>
          <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>Cancel</button>
        </div>
      </Box>
    </Modal>
  );
}
