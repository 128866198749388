import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { RootState } from '../../../redux/rootState';
import { DynamicInputFieldsProps } from '../types';

function DynamicInputFields({
  handleInputChange, bidsItems, removeKit, setBidsItems,
}: DynamicInputFieldsProps) {
  const { bidPdfDetailsData } = useSelector((state: RootState) => state.bidPdfSliceReducer);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      margin: '0px 30px',
      gap: '30px',
    }}
    >
      {bidPdfDetailsData?.Items?.map((field, index: number) => (
        <div className="bidItemsGroup">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="itemTextClass">
              Item
              {' '}
              {index + 1}
            </div>
          </div>
          {
            field.Services?.map((subField, i: number) => (
              <Grid container spacing={2} className="customServiceList">
                <Grid item xs={12} sm={12} md={5}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      {`Service ${i + 1}`}
                    </label>
                    <input
                      className="inputBoxHeight"
                      name="services"
                      id={`services_${i}`}
                      value={subField.Service}
                      onChange={(e) => handleInputChange(i, 'services', e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Model / Type
                    </label>
                    <input
                      className="inputBoxHeight"
                      name="model"
                      id={`model_${i}`}
                      value={subField.ModelType}
                      onChange={(e) => handleInputChange(i, 'model', e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Tube Qty
                    </label>
                    <input
                      className="inputBoxHeight"
                      name="tubeQty"
                      id={`tubeQty_${index}`}
                      value={subField.TubeQty}
                      onChange={(e) => handleInputChange(index, 'tubeQty', e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">
                      Location
                    </label>
                    <input
                      className="inputBoxHeight"
                      name="location"
                      id={`location_${index}`}
                      value={subField.Location}
                      onChange={(e) => handleInputChange(index, 'location', e.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
            ))
          }
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Total
                </label>
                <input
                  className="inputBoxHeight"
                  name="total"
                  id={`total_${index}`}
                  value={field.TotalBidAmount}
                  onChange={(e) => handleInputChange(index, 'total', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Remarks
                </label>
                <input
                  className="inputBoxHeight"
                  name="remark"
                  id={`remark_${index}`}
                  value={field.Services.length > 1 ? bidPdfDetailsData?.ForMoreThanOneUnitBid : bidPdfDetailsData?.ForSingleUnitBid}
                  onChange={(e) => handleInputChange(index, 'remark', e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ))}

      {bidsItems?.map((field, index: number) => (
        <div className="bidItemsGroup">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="itemTextClass">
              Item
              {' '}
              {(bidPdfDetailsData?.Items?.length || 0) + index + 1}
            </div>
            <div
              className="closeIconClass"
              onClick={() => removeKit(index)}
            >
              <CloseIcon />
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Service
                </label>
                <input
                  className="inputBoxHeight"
                  name="services"
                  id={`services_${index}`}
                  value={field.services}
                  onChange={(e) => handleInputChange(index, 'services', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Model / Type
                </label>
                <input
                  className="inputBoxHeight"
                  name="model"
                  id={`model_${index}`}
                  value={field.model}
                  onChange={(e) => handleInputChange(index, 'model', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Tube Qty
                </label>
                <input
                  className="inputBoxHeight"
                  name="tubeQty"
                  id={`tubeQty_${index}`}
                  value={field.tubeQty}
                  onChange={(e) => handleInputChange(index, 'tubeQty', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Location
                </label>
                <input
                  className="inputBoxHeight"
                  name="location"
                  id={`location_${index}`}
                  value={field.location}
                  onChange={(e) => handleInputChange(index, 'location', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Total
                </label>
                <input
                  className="inputBoxHeight"
                  name="total"
                  id={`total_${index}`}
                  value={field.total}
                  onChange={(e) => handleInputChange(index, 'total', e.target.value)}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div className="div_label_text">
                <label className="label_Style_Customer">
                  Remarks
                </label>
                <input
                  className="inputBoxHeight"
                  name="remark"
                  id={`remark_${index}`}
                  value={field.remark}
                  onChange={(e) => handleInputChange(index, 'remark', e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      ))}
      <div className="marginTopButton">
        <Button
          onClick={() => {
            const newField = {
              services: '',
              model: '',
              tubeQty: '',
              location: '',
              total: '',
              remark: '',
            };
            setBidsItems([...bidsItems, newField]);
          }}
          className="button_save_and_next mb-3"
        >
          Add Item

        </Button>
      </div>
    </div>
  );
}

export default DynamicInputFields;
