import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Checkbox, TextField, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, MenuItem, Card,
} from '@mui/material';
// import moment from 'moment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { getTableHeaderData } from './utils/DataHeaderCell';
import ComponentTableHeader from '../../../../../Common/TableHeader/ComponentTableHeader';
import { DeleteIcon, EditIcon } from '../../../../../assets/TableIcons';

const theme = createTheme({
  palette: {
    primary: {
      main: '#092245',
      contrastText: '#fff',
    },
  },
});

interface TextType {
  buttonText: string | null;
}

export default function ComponentUnits({ buttonText = null }: TextType): JSX.Element {
  const [pages, setPages] = useState('10');

  const handleChange = (event: SelectChangeEvent) => {
    setPages(event.target.value);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value - 1);
  };
  const headerData = getTableHeaderData();
  const ITEM_PER_PAGE = 1;

  return (
    <>
      <div className="div_button_group">
        <Button className="button_save_and_next">Change Job Order</Button>
        <Button className="button_edit_schedule">Edit Schedule</Button>
      </div>
      <Card>
        <div className="add_bid_div">
          <TextField
            variant="outlined"
            size="small"
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {
            buttonText
            && (
              <Button className="button_save_and_next">
                {buttonText}
              </Button>
            )
          }
        </div>
        <div>
          <ThemeProvider theme={theme}>
            <TableContainer component={Paper} className="customPaper">
              {/* <TableHeader /> */}
              <Table stickyHeader className="tableStyle">
                <TableHead>
                  <TableRow>
                    {
                      headerData.map(({ colWidth, header }) => (
                        <ComponentTableHeader key={colWidth} colWidth={colWidth} header={header} />
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* { */}
                  {/* listData?.length === 0 ? (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: 'hidden' }}
                                >
                                    <TableCell align="center" className="mt-16">
                                        No Record Found!!
                                    </TableCell>

                                </TableRow>
                            ) */}
                  {/* : listData?.map((row) => ( */}
                  <TableRow
                    // sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: 'hidden' }}
                    // key={row.id}
                    className="table-row-height"
                  >
                    <TableCell className="wdth-3">
                      <div className="table_cell">
                        <span>1</span>
                        <span className="table_checkbox_style"><Checkbox defaultChecked color="success" /></span>
                      </div>
                    </TableCell>
                    <TableCell className="wdth-3">
                      <div className="tableCell-edit">
                        <EditIcon />
                        <DeleteIcon />
                      </div>
                    </TableCell>
                    <TableCell className="wdth-3">Trane</TableCell>
                    <TableCell className="wdth-3">CVHF1280</TableCell>
                    <TableCell className="wdth-3">2109</TableCell>
                    <TableCell className="wdth-3">B09</TableCell>
                    <TableCell className="wdth-3">abc</TableCell>
                    <TableCell className="wdth-5">2/24/2022</TableCell>
                    <TableCell className="wdth-5">2/24/2022</TableCell>
                    <TableCell className="wdth-3"><Checkbox defaultChecked color="success" /></TableCell>
                    <TableCell className="wdth-3">abc</TableCell>
                    <TableCell className="wdth-3" />
                    <TableCell className="wdth-3" />
                    <TableCell className="wdth-3" />
                  </TableRow>
                  {/* )) */}
                  {/* // } */}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="table-pagination-style">
              <div>
                <span className="font-size">Show</span>
                <span className="select-style">
                  <Select
                    value={pages}
                    className="table-select-style"
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </Select>
                </span>
                <span className="font-size">entries</span>
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(100 / ITEM_PER_PAGE)}
                  page={currentPage + 1}
                  onChange={handleChangePagination}
                  color="primary"
                  shape="rounded"
                />
              </Stack>
            </div>
          </ThemeProvider>
        </div>
      </Card>
    </>
  );
}
