import { KonvaEventObject } from 'konva/lib/Node';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Stage, Layer, Rect as KonvaRect, Image as KonvaImage, Circle as KonvaCircle, Line as KonvaLine, Arrow as KonvaArrow, Text as KonvaText, Transformer,
} from 'react-konva';
import useImage from 'use-image';
import Button from '@mui/material/Button';
import { Backdrop, CircularProgress } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useSelector } from 'react-redux';
import Konva from 'konva';
import { DrawAction, PAINT_OPTIONS } from './PaintConstants';
import {
  Arrow, Circle, Rectangle, Scribble,
  TextElementType,
} from './PaintTypes';
import '../utils/StyleImageEditor.scss';
import ColorSketchPicker from './ComponentColor';
import {
  onDelete, onStageMouseDown, onStageMouseMove, onTextClick, onTextDblClick,
} from '../utils/helper';
import { PaintOption } from '../utils/types';
import { StripChartData } from '../../VesselInformation/utils/type';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { RootState } from '../../../redux/rootState';

const ComponentImageEditorStrip = React.memo(({ stripChartDataById, rowId }: { stripChartDataById?: StripChartData, rowId?: string }) => {
  const stageRef = useRef<Konva.Stage | null>(null);
  const transformerRef = useRef<Konva.Transformer | null>(null);
  const textRef = useRef<Konva.Text | null>(null);
  const [scribbles, setScribbles] = useState<Scribble[]>([]);
  const [rectangles, setRectangles] = useState<Rectangle[]>([]);
  const [circles, setCircles] = useState<Circle[]>([]);
  const [arrows, setArrows] = useState<Arrow[]>([]);
  const [texts, setTexts] = useState<TextElementType[]>([]);
  const [color, setColor] = useState('rgba(0, 0, 0, 1)');
  const [drawAction, setDrawAction] = useState<DrawAction>(DrawAction.Scribble);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isSaveDisable, setIsSaveDisable] = useState(true);

  const [isPaintRefs, setIsPaintRefs] = useState<boolean>(false);
  const [currentShapeRefs, setCurrentShapeRefs] = useState<string>('');
  const onStageMouseUp = useCallback(() => {
    setIsPaintRefs(false);
  }, []);

  const diagramRef = useRef<null>(null);
  const { isLoading } = useSelector((state:RootState) => state.VesselInformationReducer);

  const onShapeClick = useCallback(
    (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
      if (drawAction !== DrawAction.Select) return;
      const { currentTarget } = e;
      transformerRef?.current?.nodes([currentTarget]);
    },
    [drawAction],
  );
  const isDraggable = drawAction === DrawAction.Select;

  const onExportClick = useCallback(() => {
    const dataURL = stageRef?.current?.toDataURL({ pixelRatio: 0.8 });
    if (rowId && dataURL) {
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = `Strip_image_${rowId}.png`;
      link.click();
    }
  }, [stripChartDataById]);

  const onClear = useCallback(() => {
    setRectangles([]);
    setCircles([]);
    setArrows([]);
    setScribbles([]);
    setTexts([]);
  }, []);

  const url = stripChartDataById?.ImageFile;
  const [image, status] = useImage(url || '', 'anonymous', 'origin');

  const loadingImage = (ImageStatus: string) => {
    if (ImageStatus === 'loading') {
      setIsImageLoading(true);
      setIsSaveDisable(true);
    } else if (ImageStatus === 'failed') {
      setIsImageLoading(false);
      setIsSaveDisable(true);
      showErrorToaster('Failed to load image, Try again');
    } else if (ImageStatus === 'loaded') {
      setIsImageLoading(false);
      setIsSaveDisable(false);
      showSuccessToaster('Image loaded successfully');
    }
  };

  useEffect(() => {
    loadingImage(status);
  }, [status]);

  const handleDelete = () => {
    onDelete({
      transformerRef, setRectangles, setCircles, setScribbles, setArrows, setTexts,
    });
  };

  const handleMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    onStageMouseDown({
      e,
      drawAction,
      setIsPaintRefs,
      stageRef,
      setCurrentShapeRefs,
      setScribbles,
      setCircles,
      setRectangles,
      setArrows,
      setTexts,
      color,
      transformerRef,
    });
  };

  const handleMouseMove = () => {
    onStageMouseMove({
      drawAction, isPaintRefs, stageRef, currentShapeRefs, setScribbles, setCircles, setRectangles, setArrows,
    });
  };

  return (
    <>
      {
        isImageLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isImageLoading && isLoading}
        >
          <CircularProgress />
        </Backdrop>
        )
        }
      <div className="EditorHeaderButtonDiv">
        <ButtonGroup variant="contained">
          {PAINT_OPTIONS.map(({ id, label, icon }: PaintOption) => (
            <div className="iconStyle" key={id}>
              <Button
                aria-label={label}
                onClick={() => setDrawAction(id)}
                startIcon={icon}
                className={id === drawAction ? 'outlinedColor' : 'containedColor'}
              />
            </div>
          ))}
          <ColorSketchPicker
            color={color}
            onChangeComplete={(selectedColor: string) => setColor(selectedColor)}
          />
          <IconButton aria-label="Clear" onClick={onClear}>
            <CloseTwoToneIcon />
          </IconButton>
        </ButtonGroup>
        <div className="EditorDeleteBtn">
          <Button
            startIcon={<DeleteForeverTwoToneIcon />}
            variant="contained"
            color="error"
            onClick={handleDelete}
            size="small"
          >
            Delete
          </Button>
        </div>
      </div>
      <div className="konvaDiv">
        <div className="editorStateStyle">
          <Stage
            // width={WIDTH - 2}
            // height={HEIGHT - 2}
            width={image?.naturalWidth || 1 - 2}
            height={image?.naturalHeight || 1 - 2}
            ref={stageRef}
            onMouseUp={onStageMouseUp}
            onTouchEnd={onStageMouseUp}
            onMouseDown={(e) => handleMouseDown(e)}
            onTouchStart={(e) => handleMouseDown(e)}
            onMouseMove={handleMouseMove}
            onTouchMove={handleMouseMove}
          >
            <Layer>
              <KonvaRect
                x={0}
                y={0}
                // height={HEIGHT - 2}
                // width={WIDTH - 2}
                height={image?.naturalHeight || 1 - 2}
                width={image?.naturalWidth || 1 - 2}
                fill="white"
                id="bg"
              />
              {image && (
                <KonvaImage
                  ref={diagramRef}
                  image={image}
                  x={0}
                  y={0}
                  // height={HEIGHT - 100}
                  // width={WIDTH - 100}
                  height={image?.naturalHeight || 1}
                  width={image?.naturalWidth || 1}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                // draggable={isDraggable}
                />
              )}
              {rectangles?.map((rectangle) => (
                <KonvaRect
                  key={rectangle.id}
                  x={rectangle?.x}
                  y={rectangle?.y}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  height={rectangle?.height}
                  width={rectangle?.width}
                  stroke={rectangle?.color}
                  id={rectangle?.id}
                  strokeWidth={4}
                  draggable={isDraggable}
                />
              ))}
              {circles?.map((circle) => (
                <KonvaCircle
                  key={circle.id}
                  id={circle.id}
                  x={circle?.x}
                  y={circle?.y}
                  radius={circle?.radius}
                  stroke={circle?.color}
                  strokeWidth={4}
                  draggable={isDraggable}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                />
              ))}
              {scribbles.map((scribble) => (
                <KonvaLine
                  key={scribble.id}
                  id={scribble.id}
                  lineCap="round"
                  lineJoin="round"
                  stroke={scribble?.color}
                  strokeWidth={4}
                  points={scribble.points}
                  name={DrawAction.Scribble}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  draggable={isDraggable}
                />
              ))}
              {arrows.map((arrow) => (
                <KonvaArrow
                  key={arrow.id}
                  id={arrow.id}
                  points={arrow.points}
                  fill={arrow.color}
                  stroke={arrow.color}
                  strokeWidth={4}
                  onClick={onShapeClick}
                  onTap={onShapeClick}
                  draggable={isDraggable}
                />
              ))}
              {texts.map((text) => (
                <KonvaText
                  key={text.id}
                  id={text.id}
                  x={text.x}
                  y={text.y}
                  text={text.text}
                  fontSize={20}
                  fill={text.color}
                  draggable={isDraggable}
                  onDblClick={(e) => onTextDblClick(e, transformerRef, setTexts)}
                  onDblTap={(e) => onTextDblClick(e, transformerRef, setTexts)}
                  onClick={(e) => onTextClick(e, transformerRef)}
                  onTap={(e) => onTextClick(e, transformerRef)}
                  ref={textRef}
                />
              ))}
              <Transformer ref={transformerRef} />
            </Layer>
          </Stage>
        </div>
        <div className="editorSave">
          <Button
            startIcon={<SaveIcon />}
            color="secondary"
            disabled={isSaveDisable}
            className="button_save_and_next"
            onClick={onExportClick}
            size="small"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
});

export default ComponentImageEditorStrip;
