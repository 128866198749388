import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecommendationCodesApiResult, RecommendationCodesSliceState } from './Type';

const initialState: RecommendationCodesSliceState = {
  isLoading: false,
  recommendationCodesData: {},
  error: '',
  codeCreated: false,
  codeDeleted: false,
};

const RecommendationCodesSlice = createSlice({
  name: 'recommendationCodesSlice',
  initialState,
  reducers: {
    getRecommendationCodesData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onSuccessRecommendationCodesData(state, action: PayloadAction<RecommendationCodesApiResult>) {
      return {
        ...state,
        isLoading: false,
        recommendationCodesData: action?.payload as RecommendationCodesApiResult,
      };
    },
    onFailureRecommendationCodesData(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    createRecommendationCodesData(state, _action) {
      return {
        ...state,
        codeCreated: false,
      };
    },
    onSuccessCreateRecommendationCodesData(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        codeCreated: action.payload,
      };
    },
    onFailureCreateRecommendationCodesData(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        codeCreated: action.payload,
      };
    },
    onDeleteRecommendationCodes(state, _action) {
      return {
        ...state,
        codeDeleted: false,
      };
    },
    onSuccessDeleteRecommendationCodes(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        codeDeleted: action.payload,
      };
    },
  },
});

export const {
  getRecommendationCodesData, onSuccessRecommendationCodesData, onFailureRecommendationCodesData,
  createRecommendationCodesData, onSuccessCreateRecommendationCodesData, onFailureCreateRecommendationCodesData,
  onDeleteRecommendationCodes, onSuccessDeleteRecommendationCodes,
} = RecommendationCodesSlice.actions;
export const RecommendationCodesReducer = RecommendationCodesSlice.reducer;

export type RecommendationCodesActions =
  | ReturnType<typeof getRecommendationCodesData>
  | ReturnType<typeof onSuccessRecommendationCodesData>
  | ReturnType<typeof createRecommendationCodesData>
  | ReturnType<typeof onFailureRecommendationCodesData>
  | ReturnType<typeof onSuccessCreateRecommendationCodesData>
  | ReturnType<typeof onFailureCreateRecommendationCodesData>
  | ReturnType<typeof onDeleteRecommendationCodes>
  | ReturnType<typeof onSuccessDeleteRecommendationCodes>;
