/* eslint-disable max-len */
import React from 'react';

export function OilReportIconNew() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.35246 9.68645C10.7705 9.68645 11.9242 8.50933 11.9242 7.06247C11.9242 5.6156 10.7705 4.43848 9.35246 4.43848C7.93388 4.43848 6.77979 5.6156 6.77979 7.06247C6.77979 8.50933 7.93388 9.68645 9.35246 9.68645ZM11.3968 7.06247C11.3968 7.54064 11.2379 7.98146 10.9717 8.33371L9.61613 6.95106V4.99428C10.6192 5.12674 11.3968 6.00338 11.3968 7.06247ZM9.08879 4.99425V7.06236C9.08879 7.13314 9.11716 7.20248 9.16603 7.25261L10.5991 8.71418C10.2537 8.98618 9.82144 9.14858 9.3525 9.14858C8.22469 9.14858 7.30716 8.21273 7.30716 7.06243C7.30716 6.00335 8.08516 5.12666 9.08879 4.99425Z" fill="#092245" />
      <path d="M17.451 8.13928C17.451 7.8918 17.254 7.69043 17.012 7.69043H13.1593C12.9172 7.69043 12.7202 7.89176 12.7202 8.13928V9.23701C12.7202 9.48449 12.9172 9.68586 13.1593 9.68586H17.0119C17.254 9.68586 17.451 9.48453 17.451 9.23701V8.13928H17.451ZM16.9237 9.14802H13.2476V8.22831H16.9236V9.14802H16.9237Z" fill="#092245" />
      <path d="M18.9319 14.5977V12.1361C18.9319 11.9876 18.8139 11.8672 18.6683 11.8672C18.5226 11.8672 18.4046 11.9876 18.4046 12.1361V14.4383C18.1929 14.3939 17.9738 14.3706 17.7494 14.3706C15.9572 14.3706 14.4991 15.8573 14.4991 17.6848C14.4991 18.2957 14.6621 18.8683 14.9458 19.3603H5.86914V12.1361C5.86914 11.9876 5.75108 11.8672 5.60547 11.8672C5.45985 11.8672 5.3418 11.9876 5.3418 12.1361V19.3893C5.3418 19.6699 5.56514 19.8981 5.83968 19.8981H15.3319C15.9273 20.574 16.7905 20.9999 17.7494 20.9999C19.5416 20.9999 20.9998 19.5128 20.9998 17.6848C20.9998 16.2825 20.1412 15.081 18.9319 14.5977ZM17.7494 20.4621C16.248 20.4621 15.0265 19.2162 15.0265 17.6848C15.0265 16.154 16.248 14.9085 17.7494 14.9085C19.2509 14.9085 20.4724 16.1539 20.4724 17.6848C20.4724 19.2162 19.2509 20.4621 17.7494 20.4621Z" fill="#092245" />
      <path d="M5.34188 10.8952C5.34188 11.0437 5.45993 11.1641 5.60555 11.1641C5.75116 11.1641 5.86922 11.0437 5.86922 10.8952V4.46322C5.86922 4.11244 5.74747 3.7902 5.54494 3.53784H17.4974C17.9976 3.53784 18.4047 3.95295 18.4047 4.46322V10.8952C18.4047 11.0437 18.5227 11.1641 18.6683 11.1641C18.814 11.1641 18.932 11.0437 18.932 10.8952V4.46322C18.932 3.65642 18.2884 3 17.4974 3H4.20441C4.14422 3 4.08892 3.0208 4.04452 3.05543C3.44251 3.22923 3 3.79432 3 4.46322V6.11594C3 6.37517 3.20637 6.58608 3.45998 6.58608H5.34188V10.8952ZM3.52734 6.04824V4.46322C3.52734 3.95298 3.93434 3.53784 4.43461 3.53784C4.93488 3.53784 5.34188 3.95295 5.34188 4.46322V6.04824H3.52734Z" fill="#092245" />
      <path d="M18.0538 15.9843C18.0221 15.9356 17.9255 15.7871 17.7494 15.7871C17.5735 15.7871 17.4769 15.9352 17.4451 15.9841C17.4112 16.036 17.3716 16.0953 17.3281 16.1603C16.8814 16.8282 16.4453 17.5229 16.4453 18.0327C16.4453 18.8919 17.1013 19.3414 17.7494 19.3414C18.3975 19.3414 19.0535 18.8919 19.0535 18.0327C19.0535 17.5229 18.6174 16.8282 18.1707 16.1603C18.1271 16.0952 18.0875 16.0359 18.0538 15.9843ZM17.7494 18.8036C17.3627 18.8036 16.9726 18.5652 16.9726 18.0327C16.9726 17.6529 17.4698 16.9029 17.7494 16.4848C18.0289 16.9029 18.5261 17.6529 18.5261 18.0327C18.5261 18.5652 18.136 18.8036 17.7494 18.8036Z" fill="#092245" />
      <path d="M6.77979 18.343C6.77979 18.4915 6.89784 18.6119 7.04345 18.6119H13.531C13.6766 18.6119 13.7947 18.4915 13.7947 18.343C13.7947 18.1944 13.6766 18.0741 13.531 18.0741H12.4972V14.8434C12.4972 14.6948 12.3791 14.5745 12.2335 14.5745H11.1996V11.8205C11.1996 11.672 11.0816 11.5516 10.936 11.5516H9.63846C9.49284 11.5516 9.37479 11.672 9.37479 11.8205V14.2431H8.60462V12.9945C8.60462 12.8459 8.48657 12.7256 8.34096 12.7256H7.30712V11.5502C7.30712 11.4016 7.18907 11.2812 7.04345 11.2812C6.89784 11.2812 6.77979 11.4016 6.77979 11.5502V18.343ZM11.9698 15.1123V18.0741H11.1996V15.1123H11.9698ZM9.90213 12.0894H10.6723V14.8434V18.0741H9.90213V14.512V12.0894ZM9.37479 14.781V18.0741H8.60462V14.781H9.37479ZM8.07729 14.512V18.0741H7.30712V13.2634H8.07729V14.512Z" fill="#092245" />
      <path d="M17.4513 12.9945C17.4513 12.846 17.3333 12.7256 17.1877 12.7256H12.748C12.6024 12.7256 12.4844 12.846 12.4844 12.9945C12.4844 13.1431 12.6024 13.2634 12.748 13.2634H17.1876C17.3333 13.2634 17.4513 13.143 17.4513 12.9945Z" fill="#092245" />
      <path d="M12.748 12.0886H15.6869C15.8325 12.0886 15.9505 11.9683 15.9505 11.8197C15.9505 11.6712 15.8325 11.5508 15.6869 11.5508H12.748C12.6024 11.5508 12.4844 11.6712 12.4844 11.8197C12.4844 11.9683 12.6024 12.0886 12.748 12.0886Z" fill="#092245" />
      <path d="M17.4939 4.88732C17.4939 4.63984 17.297 4.43848 17.0549 4.43848H13.2022C12.9601 4.43848 12.7632 4.63981 12.7632 4.88732V5.98606C12.7632 6.233 12.9602 6.43387 13.2022 6.43387H17.0549C17.297 6.43387 17.4939 6.23297 17.4939 5.98606V4.88732ZM16.9666 5.89603H13.2905V4.97632H16.9666V5.89603Z" fill="#092245" />
    </svg>
  );
}
