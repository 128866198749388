import { Button, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import {
  EquipmentRequestTableColumns, InventoryManagementTableColumns, REQUEST, inventoryContent, sendEquipmentType,
} from './utils/constants';
import inventoryImage from '../../assets/Images/InventoryManagement/InventoryRequest.gif';
import sendEquipmentImage from '../../assets/Images/InventoryManagement/sendRequest.gif';
import requestEquipmentImage from '../../assets/Images/InventoryManagement/requestEquipment.gif';
import EndPoints from '../../Routes/EndPoints';
import {
  getEquipmentRequestDataStart,
  onDeleteEquipmentByAdmin, resetInventoryEquipmentRequest, setEquipmentRequestTab,
} from './TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { setLoader } from '../../redux/loaderSlice';
import { RootState } from '../../redux/rootState';
import { EquipmentRequestListData, UpcomingJobsEquipmentList } from './utils/types';
import './Style.scss';
import { formatEquipmentRequestListData } from './utils/helper';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { getUpcomingJobsStart } from './InventoryLandingPage/redux/inventorySlice';

export default function InventoryManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const { isLoading, equipmentRequestData } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);
  const { upcomingJobsData, isLoadingUpcomingJobs } = useSelector((state: RootState) => state.inventoryReducer);

  const handleData = () => {
    const payload = {
      page: 1,
      searchQuery: '',
      rowsPerPage: 4,
    };
    dispatch(getUpcomingJobsStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoading || isLoadingUpcomingJobs));
  }, [isLoading, isLoadingUpcomingJobs]);

  const handleEquipmentRequestData = () => {
    const payload = {
      page: 1,
      searchQuery: '',
      rowsPerPage: 4,
    };
    dispatch(getEquipmentRequestDataStart(payload));
  };

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleDeleteModal = () => {
    dispatch(onDeleteEquipmentByAdmin({ selectedId, callback: handleEquipmentRequestData }));
    setDeleteModal(false);
  };

  useEffect(() => {
    handleEquipmentRequestData();
    handleData();
  }, []);

  const handleButtonClick = () => {
    dispatch(resetInventoryEquipmentRequest());
    navigate(EndPoints.TEST_EQUIPMENT_REQUEST_FORM);
  };

  const handleEdit = (id: string) => {
    dispatch(setEquipmentRequestTab(REQUEST));
    const roleValue = equipmentRequestData?.Data?.find((element) => element?.RequestId === id);
    navigate(`${EndPoints.TEST_EQUIPMENT_REQUEST_FORM}/${id}/${roleValue?.RoleId}`);
  };

  const upcomingJobsListData: UpcomingJobsEquipmentList[] = useMemo(() => upcomingJobsData?.Data?.map((item) => {
    const transformedDataItems = {
      product: item?.ProductNumber,
      description: item?.Description,
      job: item?.JobNumber,
      startDate: item?.StartDate,
      endDate: item?.EndDate,
      analyst: item?.Analyst,
      id: item?.ProductNumber,
    };
    return transformedDataItems;
  }), [upcomingJobsData]);

  return (
    <>
      <ComponentPageHeader subHeading="Inventory Management" />
      <div className="div_job_container_info customBodyWithoutBtnContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="headingDataTable">Equipment needed for upcoming Jobs</div>
            <DataTable<UpcomingJobsEquipmentList>
              data={upcomingJobsListData}
              totalRecords={10}
              columns={InventoryManagementTableColumns}
              handleData={handleData}
            />
            {upcomingJobsData?.Data?.length > 0
              && (
                <div className="save_and_next_div bottomButtons">
                  <Button onClick={() => navigate(EndPoints.EQUIPMENT_NEEDED_FOR_JOBS)} className="button_save_and_next">View All</Button>
                </div>
              )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
              >
                <div onClick={() => navigate(EndPoints.INVENTORY_LANDING_PAGE)} className="inventoryCards">
                  <div className="inventoryContent">
                    <div className="textInventory">INVENTORY</div>
                    <div className="textDescriptionInventory">
                      {inventoryContent.inventory}
                    </div>
                  </div>
                  <div className="inventoryImage">
                    <img
                      className="imageSize"
                      src={inventoryImage}
                      alt="inventoryImage"
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
              >
                <div onClick={() => navigate(`/${EndPoints.SEND_EQUIPMENT_SCREEN}/${sendEquipmentType.SEND_EQUIPMENT}`)} className="inventoryCards">
                  <div className="inventoryContent">
                    <div className="textInventory">SEND EQUIPMENT</div>
                    <div className="textDescriptionInventory">
                      {inventoryContent.sendEquipment}
                    </div>
                  </div>
                  <div className="inventoryImage">
                    <img
                      className="imageSize"
                      src={sendEquipmentImage}
                      alt="inventoryImage"
                    />
                  </div>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
              >
                <div
                  className="inventoryCards"
                  onClick={() => navigate(`/${EndPoints.SEND_EQUIPMENT_SCREEN}/${sendEquipmentType.RECEIVE_EQUIPMENT}`)}
                >
                  <div className="inventoryContent">
                    <div className="textInventory">RECEIVE EQUIPMENT</div>
                    <div className="textDescriptionInventory">
                      {inventoryContent.receivedEquipment}
                    </div>
                  </div>
                  <div className="inventoryImage">
                    <img
                      className="imageSize"
                      src={requestEquipmentImage}
                      alt="inventoryImage"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <div className="equipmentRequestContainer">
              <div className="headingDataTable">EQUIPMENT REQUEST</div>
              <DataTable<EquipmentRequestListData>
                data={equipmentRequestData?.Data?.length > 0 ? formatEquipmentRequestListData(equipmentRequestData?.Data) : []}
                columns={EquipmentRequestTableColumns}
                totalRecords={10}
                handleData={handleEquipmentRequestData}
                buttonText="Add"
                handleButtonClick={handleButtonClick}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
              {equipmentRequestData?.Data?.length > 0 && (
                <div className="save_and_next_div bottomButtons">
                  <Button onClick={() => navigate(EndPoints.EQUIPMENT_REQUEST)} className="button_save_and_next">View All</Button>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <ComponentDeleteModal
          show={showDeleteModal}
          setOpenFrom={() => setDeleteModal(false)}
          handleDelete={handleDeleteModal}
        />
      </div>
    </>
  );
}
