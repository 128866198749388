/* eslint-disable max-len */
import dayjs from 'dayjs';
import { FileDetailsTypes } from '../../commonModal/utils/type';
import {
  AddStripChartFields, DefectInformationEnum, StripChartData, StripChartFields,
} from '../type';
import { DefectInformationFields, ReplaceFields, TypeVesselDefectList } from '../defectType';

export const VesselInformationTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'jobOrder', label: 'Job Order #' },
  { key: 'vessel', label: 'Vessel' },
  { key: 'material', label: 'Material' },
  { key: 'testEnd', label: 'Test End' },
  { key: 'tubeType', label: 'Tube Type' },
  { key: 'analyst', label: 'Analyst' },
  { key: 'inspectionDate', label: 'Inspection Date' },
];

export const TubeChartTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'section', label: 'Section' },
  { key: 'offset', label: 'Offset' },
  { key: 'numberRows', label: 'Number of Rows' },
  { key: 'rowLength', label: 'Row Length' },
  { key: 'tubeCount', label: 'Tube Count' },
];

export const DefectTableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'section', label: 'Section' },
  { key: 'status', label: 'Status' },
  { key: 'row', label: 'Row' },
  { key: 'tube', label: 'Tube' },
  { key: 'code', label: 'Code' },
  { key: 'location', label: 'Location' },
  { key: 'date', label: 'Date' },
  { key: 'time', label: 'Time' },
  { key: 'defect', label: 'Defect' },
];

export const BayLengthTableColumns = [
  { key: 'position', label: 'Position' },
  { key: 'length', label: 'Length' },
];

export const PhaseChartTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'chart', label: 'Chart' },
  { key: 'frequency', label: 'Frequency' },
  { key: 'material', label: 'Material' },
];

export const StripChartTableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'section', label: 'Section' },
  { key: 'zone', label: 'Zone' },
  { key: 'row', label: 'Row #' },
  { key: 'tube', label: 'Tube' },
  { key: 'loc', label: 'Location' },
  { key: 'title', label: 'Title' },
];

export const ImportFromLibraryTableColumns = [
  { key: 'action', label: 'Action' },
  { key: 'mfg', label: 'Mfg' },
  { key: 'model', label: 'Model' },
  { key: 'vessel', label: 'Vessel' },
  { key: 'tubeCount', label: 'Tube Count' },
];

export function AddStripChartRequest(newFileName:string, selectedFile:string | null, fileDetails: FileDetailsTypes | null, data: AddStripChartFields, jobOrderId: string, vesselId: string) {
  const req = {
    JobOrder: jobOrderId || '',
    VesselId: vesselId || '',
    VesselName: 'abc', // ------Temp --------
    ImageName: newFileName,
    Zone: fileDetails?.zone || '',
    Row: fileDetails?.row || '',
    Tube: fileDetails?.tube || '',
    ImageConfig: selectedFile,
    Section: data?.section || '',
  };
  return req;
}

export function AddStripChartImage(newFileName:string, selectedFile:string | null, jobOrderId: string, vesselId: string) {
  const req = {
    JobOrder: jobOrderId || '',
    VesselId: vesselId || '',
    VesselName: 'abc', // ------Temp --------
    ImageName: newFileName,
    Zone: '',
    Row: 0,
    Tube: 0,
    ImageConfig: selectedFile,
    Section: '',
  };
  return req;
}

export function EditStripChart(data: StripChartFields, stripChartDataById: StripChartData) {
  const req = {
    Id: stripChartDataById.Id,
    StripChartId: stripChartDataById.StripChartId,
    JobOrder: stripChartDataById.JobOrder,
    VesselId: stripChartDataById.VesselId,
    VesselName: stripChartDataById.VesselName,
    ImageName: stripChartDataById.ImageName,
    Zone: data.zone || '',
    Row: data?.row || '',
    Tube: data?.tube || '',
    ImageConfig: stripChartDataById.ImageConfig,
    Section: data.section,
    Location: stripChartDataById.Location,
    Title: data.title,
    ImageFile: stripChartDataById.ImageFile,
  };
  return req;
}

export function SearchReplaceData(data: ReplaceFields, jobOrderId: string, vesselId: string) {
  const req = {
    JobOrder: jobOrderId || '',
    VesselId: vesselId || '',
    Type: data.parameter,
    Section: data.section,
    SearchCode: data.search,
    SearchArea: data.searchArea,
    ReplaceCode: data.replaceWith,
    ReplaceArea: data.replaceWithArea,
  };
  return req;
}

export function UpdateDefectPayload(data: DefectInformationFields, defectDataById: TypeVesselDefectList) {
  return {
    defectId: defectDataById.DefectId,
    jobOrder: defectDataById.JobOrder,
    vesselId: defectDataById.VesselId,
    section: data.section,
    code: data.code,
    location: data.location,
    area: data.area,
    date: dayjs(data.date).format('DD-MM-YYYY'),
    time: dayjs(data.time).format('HH:mm:ss'),
    row: data.row,
    tube: data.tube,
  };
}

export function getAddDefectPayload(data: DefectInformationFields, jobOrderId: string, vesselId: string) {
  return {
    jobOrder: jobOrderId,
    vesselId: vesselId || '',
    section: data.section,
    code: data.code,
    location: data.location,
    area: data.area,
    date: dayjs(data.date).format('DD-MM-YYYY'),
    time: dayjs(data.time).format('HH:mm:ss'),
    row: data.row,
    tube: data.tube,
  };
}

export function setDefectValues(defectDataById:TypeVesselDefectList, setValue: SetValue) {
  if (defectDataById?.Date) {
    const parts = defectDataById?.Date.split('-');
    const formattedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
    setValue(DefectInformationEnum.date, dayjs(formattedDate));
  }
  setValue(DefectInformationEnum.section, defectDataById?.Section);
  setValue(DefectInformationEnum.code, defectDataById?.Code);
  setValue(DefectInformationEnum.location, defectDataById?.Location);
  setValue(DefectInformationEnum.area, defectDataById?.Area);
  setValue(DefectInformationEnum.time, dayjs(defectDataById?.Time, 'HH:mm:ss'));
  setValue(DefectInformationEnum.row, defectDataById?.Row);
  setValue(DefectInformationEnum.tube, defectDataById?.Tube);
}
