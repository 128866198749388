import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { AddModalIcon } from '../../../media/images/AddModalIcon';
import styles from '../../../Common/DeleteModal/DeleteModalStyle.module.scss';
import { TypesDeleteModal } from '../../../Common/DeleteModal/TypeDelete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function ComponentSuccessChangePasswordModal({ show, setOpenFrom }: TypesDeleteModal): JSX.Element {
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.deleteIcon}>
          <AddModalIcon />
        </div>
        <div className={styles.modalModalHeading}>
          Your password has been changed
          successfully !
        </div>
        <div className={styles.divButtons}>
          <button type="button" className={`btn ${styles.cancelBtn}`} onClick={setOpenFrom}>Close</button>
        </div>
      </Box>
    </Modal>
  );
}
