import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { RootState } from '../../redux/rootState';
import DataTable from '../../Common/DataTable';
import ComponentDeleteModal from '../../Common/DeleteModal/ComponentDeleteModal';
import { setLoader } from '../../redux/loaderSlice';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { exportToExcelData } from '../../Common/PageHeader/helper';
import { deleteSiteStart, getSitesStart } from './redux/sliceSites';

interface SiteData {
  sn: number;
  siteID: string,
  site: string,
  address: string,
  country: string,
  city: string,
  state: string,
  zip: string,
  id: string,
}

export default function Sites() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { siteList, isLoading, totalEntries } = useSelector((state: RootState) => state.siteReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getSitesStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedSiteId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      siteId: selectedSiteId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteSiteStart(paramData));
    setShowDeleteModal(false);
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'siteID', label: 'Site ID' },
      { key: 'site', label: 'Site' },
      { key: 'address', label: 'Address' },
      { key: 'country', label: 'Country' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'zip', label: 'Zip' },
    ],
    [],
  );

  const data: SiteData[] = useMemo(() => siteList.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      siteID: item?.SiteId,
      site: item?.SiteName,
      address: item?.Address,
      country: item?.Country,
      city: item?.City,
      state: item?.State,
      zip: item?.Pincode,
      id: item?.SiteId,
    };
    return transformedDataItem;
  }), [siteList]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleEdit = (id: string) => {
    navigate(`${id}`);
  };

  const excelData = useMemo(() => siteList.map((item) => {
    const transformedDataItems = {
      Site: item?.SiteName,
      SiteId: item?.SiteId,
      Address: item?.Address,
      Country: item?.Country,
      City: item?.City,
      State: item?.State,
      Zip: item?.Pincode,
    };
    return transformedDataItems;
  }), [siteList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Sites_list',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="Sites" downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<SiteData>
            data={data}
            columns={columns}
            totalRecords={totalEntries}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />

    </>
  );
}
