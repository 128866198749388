/* eslint-disable max-len */
import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { useSelector } from 'react-redux';
import styles from './DeactivateUserModalStyle.module.scss';
import { DeactivateUserModalPropTypes } from '../../utils/types';
import { RootState } from '../../../../redux/rootState';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 539,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function DeactivateUserModal({
  show, setOpenFrom, rowId, handleDeactivate,
}: DeactivateUserModalPropTypes): JSX.Element {
  const { userManagementListData } = useSelector((state: RootState) => state.userManagementReducer);

  // eslint-disable-next-line consistent-return
  const getText = useMemo(() => {
    if (rowId) {
      const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
      if (selectedRow?.Status === 'Active') {
        return `Are you sure you want to Deactivate this user?
      Deactivated users would no longer be able to access the TAI web & desktop application`;
      }
      return 'Are you sure you want to Activate this user?';
    }
  }, [rowId]);

  // eslint-disable-next-line consistent-return
  const getButtonText = useMemo(() => {
    if (rowId) {
      const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
      if (selectedRow?.Status === 'Active') {
        return 'Yes, Deactivate it!';
      }
      return 'Yes, Activate it!';
    }
  }, [rowId]);

  const isActive = useMemo(() => {
    const selectedRow = userManagementListData?.Data?.find((ele) => ele?.Id === rowId);
    if (selectedRow?.Status === 'Active') {
      return false;
    }
    return true;
  }, [rowId]);

  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.deleteIcon}>
          <NoAccountsIcon style={{ height: '50px', width: '50px', color: !isActive ? 'rgb(240, 101, 72)' : 'rgb(0, 167, 81)' }} />
        </div>
        <div className={styles.modalModalHeading}>
          Are you sure?
        </div>
        <div className={styles.modalModalDescription}>
          {getText}
        </div>

        <div className={styles.divButtons}>
          <button
            type="button"
            style={{ backgroundColor: !isActive ? 'rgb(240, 101, 72)' : 'rgb(0, 167, 81)', border: `1px solid ${!isActive ? 'rgb(240, 101, 72)' : 'rgb(0, 167, 81)'}` }}
            className={`btn ${styles.deleteBtn} `}
            onClick={() => {
              if (handleDeactivate) {
                handleDeactivate();
              }
            }}
          >
            {getButtonText}
          </button>
          <button type="button" className={`btn ${styles.cancelBtn} `} onClick={setOpenFrom}>Cancel</button>
        </div>
      </Box>
    </Modal>
  );
}
