import { Button, Card, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { setSelectedTab } from '../../redux/oilReportSlice';
import { ConcentrationLabel } from '../../utils/constant';
import { ConcentrationFormProps, MainFields } from '../../utils/types';

export function Concentration({ control, errors }:ConcentrationFormProps) {
  const requiredFields:string[] = useMemo(() => [], []);

  const concFields:MainFields[] = useMemo(() => Object.entries(ConcentrationLabel).map(([field, label]) => ({
    field,
    label,
    isRequired: requiredFields.includes(field),
  })), []);

  const dispatch = useDispatch();

  const handleNext = () => {
    dispatch(setSelectedTab(2));
  };

  return (
    <>
      <Card>
        <div className="customerInfo_container" />
        <div className="customerInfo_main_container">
          <Grid container spacing={2}>
            {concFields.map((entry:MainFields) => (
              <Grid key={entry.field} item xs={12} sm={12} md={4} lg={4} xl={3}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">{entry.label}</label>
                  <ComponentTextInput
                    control={control}
                    required={entry.isRequired}
                    capitalizeFirst
                    errors={errors}
                    inputProps={{ maxLength: 30 }}
                    name={entry.field}
                    id={entry.field}
                    size="small"
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </Card>
      <br />
      <Grid item md={12} sm={12} xs={12}>
        <div className="save_and_next_div">
          <div className="button_margin_left">
            <Button className="button_save_and_next" onClick={handleNext}>Next</Button>
          </div>
        </div>
      </Grid>
    </>
  );
}
