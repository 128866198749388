/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, Observable, of, switchMap, takeUntil,
} from 'rxjs';

import { AxiosError } from 'axios';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import { GetById } from '../../../../utils/type';
import {
  createDefectFailure, createDefectStart, createDefectSuccess,
  getDefectByIdFailure, getDefectByIdStart, getDefectByIdSuccess, updateVesselDefectFailure,
  updateVesselDefectStart, updateVesselDefectSuccess,
  deleteVesselDefectFailure, deleteVesselDefectStart, VesselDefectActions, getVesselDefectSuccess,
  deleteVesselDefectSuccess, getVesselDefectStart, getVesselDefectFailure, dateTimeStart, createDateTimeSuccess,
  createDateTimeFailure, addInsertStart, addInsertTimeSuccess, addInsertTimeFailure, searchReplaceStart,
  searchReplaceSuccess, searchReplaceFailure,
  getDefectDropdownStart,
  getDefectDropdownSuccess,
  getDefectDropdownFailure,
} from './sliceVesselDefect';
import {
  CreateVesselDefectType, DateTimeDefectType, DefectRequestData, DeleteDefectPayload, DropDownPayloadType, GetDefectListingPayload,
  InsertDefectTypeApi,
  InsertPayloadDataApi,
  MenuAPIData,
  SearchReplacePayloadFields, SearchReplacePayloadType, TimeDatePayloadDataApi, TypeVesselDefectList,
  UpdateVesselDefectType,
} from '../../utils/defectType';
import {
  createFetchDataEpic, createGetEpicWithData, getData,
} from '../../../../utils/CommonEpic';
import { Empty } from '../../../ScreenAddSmc/Utils/TypeSmc';

function constructVesselDefectListUrl(payload: GetDefectListingPayload): string {
  return `${Config.vesselInformation.getVesselDefect}?JobOrder=${payload.jobOrder}&VesselId=${payload.vesselId}&pageNumber=${payload.page}&pageSize=${payload.rowsPerPage}&searchItem=${payload.searchQuery}`;
}

// Defect Listing Epic
export const getVesselDefectInfoEpic = createFetchDataEpic<TypeVesselDefectList, GetDefectListingPayload>(
  getVesselDefectStart.type,
  constructVesselDefectListUrl,
  getVesselDefectSuccess as Empty,
  getVesselDefectFailure,
);

// Get Vessel Defect By ID
export const getDefectByIdEpic = createGetEpicWithData<TypeVesselDefectList, { id: string }>(
  getDefectByIdStart.type,
  (data) => getData<TypeVesselDefectList>(`${Config.vesselInformation.getVesselDefectById}/${data.id}`),
  getDefectByIdSuccess,
  getDefectByIdFailure,
);

// Delete Vessel Defect By ID
async function deleteVesselDefect(data: DeleteDefectPayload): Promise<GetById<string>> {
  if (data.vesselDefectData.length > 0) {
    const filterData = data.vesselDefectData.filter((item) => String(item.Id) === data.id);
    if (filterData.length > 0 && filterData[0].DefectType === 'Calibration Time') {
      const url = `${Config.vesselInformation.deleteVesselDefect}?id=${filterData[0].Id}&type=${1}`;
      const result = await makeDeleteRequest<GetById<string>>(url);
      return result.data;
    }
    const url = `${Config.vesselInformation.deleteVesselDefect}?id=${data.id}`;
    const result = await makeDeleteRequest<GetById<string>>(url);
    return result.data;
  }
  throw new Error('No vessel defect data found');
}
export const deleteVesselDefectEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(deleteVesselDefectStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: DeleteDefectPayload) => from(deleteVesselDefect(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        const payloadAPI = {
          jobOrder: data.jobOrder,
          vesselId: data.vesselId,
          page: data.page,
          rowsPerPage: data.rowsPerPage,
          searchQuery: data.searchQuery,
        };
        return (deleteVesselDefectSuccess(), getVesselDefectStart(payloadAPI));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return deleteVesselDefectFailure();
    }),
    takeUntil(action$.pipe(filter(deleteVesselDefectStart.match))),
    catchError((error) => of(deleteVesselDefectFailure(error))),
  )),
);

// ---Create Vessel Defect --------->>>>>>>>
async function createVesselDefect(data:DefectRequestData): Promise<GetById<string>> {
  const payload = {
    JobOrder: data.jobOrder,
    VesselId: data.vesselId,
    Section: data.section,
    Code: data.code,
    Location: data.location,
    Area: data.area,
    Date: data.date,
    Time: data.time,
    Row: Number(data.row),
    Tube: Number(data.tube),
  };
  const url = `${Config.vesselInformation.createVesselDefect}`;
  const result = await makePostRequest<GetById<string>>(url, payload);
  return result.data;
}
export const CreateVesselDefectEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(createDefectStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap(({ payLoad, callback }: CreateVesselDefectType) => from(createVesselDefect(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return createDefectSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createDefectFailure();
    }),
    takeUntil(action$.pipe(filter(createDefectStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.Result as string;
      of(showErrorToaster(errorMessage));
      return of(createDefectFailure());
    }),
  )),
);

// ---Update Vessel Defect --------->>>>>>>
async function updateVesselDefect(data:DefectRequestData): Promise<GetById<string>> {
  const payload = {
    DefectId: data.defectId,
    JobOrder: data.jobOrder,
    VesselId: data.vesselId,
    Section: data.section,
    Code: data.code,
    Location: data.location,
    Area: data.area,
    Date: data.date,
    Time: data.time,
    Row: Number(data.row),
    Tube: Number(data.tube),
  };
  const url = `${Config.vesselInformation.updateVesselDefect}`;
  const result = await makePostRequest<GetById<string>>(url, payload);
  return result.data;
}
export const UpdateVesselDefectEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(updateVesselDefectStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap(({ payLoad, callback }:UpdateVesselDefectType) => from(updateVesselDefect(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE && callback) {
        showSuccessToaster(res.BMT.ResponseMessage);
        callback();
        return updateVesselDefectSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateVesselDefectFailure();
    }),
    takeUntil(action$.pipe(filter(updateVesselDefectStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.Result as string;
      of(showErrorToaster(errorMessage));
      return of(updateVesselDefectFailure());
    }),
  )),
);

async function startFinishTime(data:TimeDatePayloadDataApi): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.startFinishDefectTimeUrl}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const AddTimeDateEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(dateTimeStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, callback }: DateTimeDefectType) => from(startFinishTime(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        if (callback) {
          callback();
        }
        return createDateTimeSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createDateTimeFailure();
    }),
    takeUntil(action$.pipe(filter(dateTimeStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(createDateTimeFailure());
    }),
  )),
);

async function insertDefectTime(data:InsertPayloadDataApi): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.insertDefectTimeUrl}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const InsertDefectTimeEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(addInsertStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, callback, setOpenFrom }: InsertDefectTypeApi) => from(insertDefectTime(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        if (callback) {
          callback();
        }
        if (setOpenFrom) {
          setOpenFrom();
        }
        return addInsertTimeSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return addInsertTimeFailure();
    }),
    takeUntil(action$.pipe(filter(addInsertStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(addInsertTimeFailure());
    }),
  )),
);

async function searchReplaceDefect(data:SearchReplacePayloadFields): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.searchReplaceDefectUrl}`;
  const result = await makePostRequest<GetById<string>>(url, data);
  return result.data;
}
export const SearchReplaceDefectEpic = (action$: Observable<VesselDefectActions>) => action$.pipe(
  ofType(searchReplaceStart.type),
  map((x) => x.payload),
  switchMap(({ payLoad, callback }: SearchReplacePayloadType) => from(searchReplaceDefect(payLoad)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        if (callback) {
          callback();
        }
        return searchReplaceSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return searchReplaceFailure();
    }),
    takeUntil(action$.pipe(filter(searchReplaceStart.match))),
    catchError((error) => of(searchReplaceFailure(error))),
  )),
);

export const getDefectDropDownDataEpic = createGetEpicWithData<MenuAPIData, DropDownPayloadType>(
  getDefectDropdownStart.type,
  (data) => getData<MenuAPIData>(`${Config.vesselInformation.defectDropDownUrl}?param=${data.param}&jobOrder=${data.jobOrder}&vesselId=${data.vesselId}`),
  getDefectDropdownSuccess,
  getDefectDropdownFailure,
);
