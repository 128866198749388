import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentStyleListTable from './Components';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenStyleList() {
  const { StyleListData } = useSelector((state: RootState) => state.ListStyleReducer);

  const excelData = useMemo(() => StyleListData?.Data?.map((item) => {
    const transformedDataItems = {
      Description: item?.Description,
    };
    return transformedDataItems;
  }), [StyleListData]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Unit_style_list',
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="UNIT STYLE LIST" downloadExcel={downloadExcel} />
      </Grid>
      <div className="whiteSpace" />
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentStyleListTable />
      </Grid>
    </Grid>
  );
}

export default ScreenStyleList;
