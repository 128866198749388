import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UpdateHazardAnalystPayload, HazardAnalysisResult, HazardAnalysisDataById,
  HazardAnalysisResponse,
  HazardAnalysisQueryPayload,
} from '../utils/types';

const initialState = {
  isLoading: false,
  hazardAnalysisListData: [] as HazardAnalysisResponse[],
  hazardAnalysisById: {} as HazardAnalysisDataById,
  TotalRecords: 0 as number,
  listData: {} as HazardAnalysisQueryPayload,
};

export const sliceHazardAnalysis = createSlice({
  name: 'HazardAnalysisEpic',
  initialState,
  reducers: {
    // Get Hazard List ------------->>>>>>>>>>>>>>>
    getHazardAnalysisData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getHazardAnalysisSuccess(state, action: PayloadAction<HazardAnalysisResult>) {
      return {
        ...state,
        isLoading: false,
        hazardAnalysisListData: action.payload.Result,
        TotalRecords: action.payload.TotalRecord,
      };
    },
    getHazardAnalysisFailure(state) {
      return {
        ...state,
        isLoading: false,
        hazardAnalysisListData: [] as HazardAnalysisResponse[],
      };
    },

    // Get Hazard By ID --------->>>>>>>>>>>>
    getHazardAnalysisById(state, _action) {
      return {
        ...state,
        isLoading: true,
        hazardAnalysisById: {} as HazardAnalysisDataById,
      };
    },
    getHazardAnalysisSuccessById(state, action: PayloadAction<HazardAnalysisDataById>) {
      return {
        ...state,
        isLoading: false,
        hazardAnalysisById: action.payload,
      };
    },
    getHazardAnalysisFailureById(state) {
      return {
        ...state,
        isLoading: false,
        hazardAnalysisById: {} as HazardAnalysisDataById,
      };
    },
    resetHazardAnalysisData(state) {
      return {
        ...state,
        hazardAnalysisById: {} as HazardAnalysisDataById,
        listData: {} as HazardAnalysisQueryPayload,
      };
    },

    // Update Hazard Analysis Data ----------->>>>>>>>>>>>>
    updateHazardAnalysisStart(state, _action: PayloadAction<UpdateHazardAnalystPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateHazardAnalysisSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateHazardAnalysisFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    ListData(state, action: PayloadAction<HazardAnalysisQueryPayload>) {
      return {
        ...state,
        listData: action.payload as HazardAnalysisQueryPayload,
      };
    },
  },
});

export const {
  getHazardAnalysisData, getHazardAnalysisSuccess, getHazardAnalysisFailure,
  getHazardAnalysisById, getHazardAnalysisSuccessById, getHazardAnalysisFailureById,
  updateHazardAnalysisStart, updateHazardAnalysisSuccess, updateHazardAnalysisFailure,
  resetHazardAnalysisData, ListData,
} = sliceHazardAnalysis.actions;
export const hazardAnalysisReducer = sliceHazardAnalysis.reducer;

export type HazardAnalysisActions =
  | ReturnType<typeof getHazardAnalysisData>
  | ReturnType<typeof getHazardAnalysisSuccess>
  | ReturnType<typeof getHazardAnalysisFailure>
  | ReturnType<typeof getHazardAnalysisById>
  | ReturnType<typeof getHazardAnalysisSuccessById>
  | ReturnType<typeof getHazardAnalysisFailureById>
  | ReturnType<typeof updateHazardAnalysisStart>
  | ReturnType<typeof updateHazardAnalysisSuccess>
  | ReturnType<typeof updateHazardAnalysisFailure>
  | ReturnType<typeof resetHazardAnalysisData>
  | ReturnType<typeof ListData>;
