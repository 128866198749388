import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UpdatePhotoPayload, PhotosResult, PhotosResultDataById, VesselResultDataById,
} from '../utils/types';

const initialState = {
  isLoading: false,
  photosListData: {} as PhotosResult,
  photoDataById: {} as PhotosResultDataById,
  vesselDataById: {} as VesselResultDataById[],
};

export const slicePhotos = createSlice({
  name: 'PhotosEpic',
  initialState,
  reducers: {

    // Get Photos List ------------->>>>>>>>>>>>>>>
    getPhotosData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getPhotosSuccess(state, action: PayloadAction<PhotosResult>) {
      return {
        ...state,
        isLoading: false,
        photosListData: action.payload,
      };
    },
    getPhotosFailure(state) {
      return {
        ...state,
        isLoading: false,
        photosListData: {} as PhotosResult,
      };
    },

    // Get Photo By ID --------->>>>>>>>>>>>
    getPhotoById(state, _action) {
      return {
        ...state,
        isLoading: true,
        photoDataById: {} as PhotosResultDataById,
      };
    },
    getPhotoSuccessById(state, action: PayloadAction<PhotosResultDataById>) {
      return {
        ...state,
        isLoading: false,
        photoDataById: action.payload,
      };
    },
    getPhotoFailureById(state) {
      return {
        ...state,
        isLoading: false,
        photoDataById: {} as PhotosResultDataById,
      };
    },
    resetPhotoData(state) {
      return {
        ...state,
        photoDataById: {} as PhotosResultDataById,
      };
    },

    // Update Photo Data ----------->>>>>>>>>>>>>
    updatePhotoStart(state, _action: PayloadAction<UpdatePhotoPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updatePhotoSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updatePhotoFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get All Vessel ID --------->>>>>>>>>>>>
    getVesselById(state, _action) {
      return {
        ...state,
        // isLoading: true,
      };
    },
    getVesselSuccessById(state, action: PayloadAction<VesselResultDataById[]>) {
      return {
        ...state,
        // isLoading: false,
        vesselDataById: action.payload,
      };
    },
    getVesselFailureById(state) {
      return {
        ...state,
        // isLoading: false,
      };
    },
    // Update Photo ----------->>>>>>>>>>>>>
    updatePhotoEditStart(state, _action: PayloadAction<FormData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updatePhotoEditSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updatePhotoEditFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getPhotosData, getPhotosSuccess, getPhotosFailure,
  getPhotoById, getPhotoSuccessById, getPhotoFailureById,
  updatePhotoStart, updatePhotoSuccess, updatePhotoFailure,
  getVesselById, getVesselSuccessById, getVesselFailureById,
  updatePhotoEditStart, updatePhotoEditSuccess, updatePhotoEditFailure,
  resetPhotoData,
} = slicePhotos.actions;
export const photosReducer = slicePhotos.reducer;

export type PhotosActions =
  | ReturnType<typeof getPhotosData>
  | ReturnType<typeof getPhotosSuccess>
  | ReturnType<typeof getPhotosFailure>
  | ReturnType<typeof getPhotoById>
  | ReturnType<typeof getPhotoSuccessById>
  | ReturnType<typeof getPhotoFailureById>
  | ReturnType<typeof updatePhotoStart>
  | ReturnType<typeof updatePhotoSuccess>
  | ReturnType<typeof updatePhotoFailure>
  | ReturnType<typeof getVesselById>
  | ReturnType<typeof getVesselSuccessById>
  | ReturnType<typeof getVesselFailureById>
  | ReturnType<typeof updatePhotoEditStart>
  | ReturnType<typeof updatePhotoEditSuccess>
  | ReturnType<typeof updatePhotoEditFailure>
  | ReturnType<typeof resetPhotoData>;
