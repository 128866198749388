/* eslint-disable max-len */
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  failureGetListContentsList,
  failureGetReportWorkspaceList,
  failureGetUserDetailsList,
  failureGetWorkspaceById,
  getListContentsListStart,
  getReportWorkspaceListStart,
  getSignatureFailure,
  getSignatureStart,
  getSignatureSuccess,
  getUserDetailsListStart,
  getWorkSpaceByIdStart,
  ReportWorkspaceActions,
  sendEmailFailure,
  sendEmailStart,
  sendEmailSuccess,
  successGetListContentsList,
  successGetReportWorkspaceList,
  successGetWorkspaceById,
  successUserDetailsList,
} from './sliceReportWorkspace';
import Config from '../../../Common/Config';
import { makeFormPostRequest, makeGetRequest } from '../../../Common/NetworkOps';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  GetAllWorkspaceApiResponse, GetContentTypeApiResponse, GetWorkspaceDetailsByIdApiResponse, PayloadReportWorkspaceStart, PayloadWorkspaceDetailsById,
  EmailSendApiResponse,
  GetUserDetailsPayload,
  UserDetailsData,
} from '../utils/types';
import { GetById, MasterData } from '../../../utils/type';
import { createGetEpicWithData, getData } from '../../../utils/CommonEpic';

// Get Report Workspace List -----------------
async function GetWorkSpaceList(data: PayloadReportWorkspaceStart): Promise<GetAllWorkspaceApiResponse> {
  const url = `${Config.workspace.GetAllReporsWorkspaceUrl}?pageNumber=${data.page}&pageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllWorkspaceApiResponse>(url);
  return result?.data;
}

export const epicGetWorkspaceList = (action$: Observable<ReportWorkspaceActions>) => action$.pipe(
  filter(getReportWorkspaceListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadReportWorkspaceStart) => from(GetWorkSpaceList(data)).pipe(
    map((res: GetAllWorkspaceApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetReportWorkspaceList(res?.BMT.Result);
      }
      showErrorToaster(res.BMT?.ResponseMessage);
      return failureGetReportWorkspaceList();
    }),
    takeUntil(action$.pipe(filter(getReportWorkspaceListStart.match))),
    catchError((error: AxiosError<GetAllWorkspaceApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureGetReportWorkspaceList());
    }),
  )),
);

// Get Report Workspace Data by ID -----------
async function GetWorkspaceDetailsById(data: PayloadWorkspaceDetailsById): Promise<GetWorkspaceDetailsByIdApiResponse> {
  const url = `${Config.workspace.GetReporsWorkspaceById}?jobOrder=${data.jobOrderId}`;
  const result = await makeGetRequest<GetWorkspaceDetailsByIdApiResponse>(url);
  return result?.data;
}

export const epicGetWorkspaceDetailsById = (action$: Observable<ReportWorkspaceActions>) => action$.pipe(
  filter(getWorkSpaceByIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadWorkspaceDetailsById) => from(GetWorkspaceDetailsById(data)).pipe(
    map((res: GetWorkspaceDetailsByIdApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetWorkspaceById(res?.BMT?.Result);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return failureGetWorkspaceById();
    }),
    takeUntil(action$.pipe(filter(getWorkSpaceByIdStart.match))),
    catchError((error: AxiosError<GetWorkspaceDetailsByIdApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureGetWorkspaceById());
    }),
  )),
);

// Update Photo
async function sendEmailWorkspace(data: FormData): Promise<EmailSendApiResponse> {
  const url = `${Config.oilReport.sendMail}`;
  const result = await makeFormPostRequest<EmailSendApiResponse>(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return result.data;
}
export const sendEmailWorkspaceEpic = (action$ : Observable<ReportWorkspaceActions>) => action$.pipe(
  filter(sendEmailStart.match),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((payload :FormData) => from(sendEmailWorkspace(payload)).pipe(
    map((res: EmailSendApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return sendEmailSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return (sendEmailFailure());
    }),
    takeUntil(action$.pipe(filter(sendEmailStart.match))),
    catchError((error: AxiosError<GetWorkspaceDetailsByIdApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(sendEmailFailure());
    }),
  )),
);
//  Get All List Contents Data List -----------------
async function GetListContentsList(data:boolean): Promise<GetContentTypeApiResponse> {
  const url = `${Config.workspace.GetListContentURL}`;
  const result = await makeGetRequest<GetContentTypeApiResponse>(data ? `${url}?type=combine` : url);
  return result?.data;
}

export const epicGetListContentsList = (action$: Observable<ReportWorkspaceActions>) => action$.pipe(
  filter(getListContentsListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data:boolean) => from(GetListContentsList(data)).pipe(
    map((res: GetContentTypeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successGetListContentsList(res?.BMT.Result);
      }
      showErrorToaster(res.BMT?.ResponseMessage);
      return failureGetListContentsList();
    }),
    takeUntil(action$.pipe(filter(getListContentsListStart.match))),
    catchError((error: AxiosError<GetContentTypeApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureGetListContentsList());
    }),
  )),
);

// Get Signature List
async function GetSignatureList(): Promise<GetById<MasterData[]>> {
  const url = `${Config.workspace.GetSignatureList}`;
  const result = await makeGetRequest<GetById<MasterData[]>>(url);
  return result?.data;
}

export const epicGetSignatureList = (action$: Observable<ReportWorkspaceActions>) => action$.pipe(
  filter(getSignatureStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(() => from(GetSignatureList()).pipe(
    map((res: GetById<MasterData[]>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getSignatureSuccess(res?.BMT.Result);
      }
      showErrorToaster(res.BMT?.ResponseMessage);
      return getSignatureFailure();
    }),
    takeUntil(action$.pipe(filter(getSignatureStart.match))),
    catchError(() => of(getSignatureFailure())),
  )),
);

export const getUserDetailsWorkSpaceEpic = createGetEpicWithData<UserDetailsData, GetUserDetailsPayload>(
  getUserDetailsListStart.type,
  (data) => getData<UserDetailsData>(`${Config.workspace.GetUserDetailsList}/${data.jobOrderId}`),
  successUserDetailsList,
  failureGetUserDetailsList,
);
