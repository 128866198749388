import React from 'react';
import Loader from '../../assets/TaiLoader.svg';

function ComponentLoader() {
  return (
    <div className="blur-container">
      <img src={Loader} alt="Blurred Background" className="blur-image" />
    </div>
  );
}

export default ComponentLoader;
