/* eslint-disable max-len */
export const LeftElementAndComponent = [
  {
    FirstElement: 'Silicon', FirstComponent: 'Sealant/Coolant/Dirt', SecondElement: 'Moly', SecondComponent: 'Bearings/Assembly Lube',
  },
  {
    FirstElement: 'Iron', FirstComponent: 'Shell/Support/Cylinder', SecondElement: 'Magnesium', SecondComponent: 'Brine/Detergent Additive',
  },
  {
    FirstElement: 'Chromium', FirstComponent: 'Crankshaft/CYL/Rings', SecondElement: 'Sodium', SecondComponent: 'Coolant/Brine',
  },
  {
    FirstElement: 'Aluminum', FirstComponent: 'Bearings/Impeller/Pistons', SecondElement: 'Boron', SecondComponent: 'Coolant/Additive',
  },
  {
    FirstElement: 'Copper', FirstComponent: 'Oil Lines/Bearings/Tubes', SecondElement: 'Barium', SecondComponent: 'Detergent Additive',
  },
  {
    FirstElement: 'Lead', FirstComponent: 'Bearings', SecondElement: 'Phosphorous', SecondComponent: 'Anti-Wear Additive',
  },
  {
    FirstElement: 'Tin', FirstComponent: 'Bearings', SecondElement: 'Calcium', SecondComponent: 'Brine/Detergent Additive',
  },
  {
    FirstElement: 'Nickel', FirstComponent: 'Tubes/Crankshaft', SecondElement: 'Zinc', SecondComponent: 'Anti-Wear Additive',
  },
  {
    FirstElement: 'Silver', FirstComponent: 'Solder/Coolant', SecondElement: '', SecondComponent: '',
  },
];

export const METAL_CONCENTRATIONS = `(Metal Concentrations: selected metallic elements present as microscopic particles suspended in the lubricant are identified and measured in parts per
    million(ppm) by weight. These test results are used to monitor component wear, lubricant contamination, and lubricant additive levels.)`;

export const SOLID_PERCENTAGE = `(Solids as a % Volume: measures the total amount of solid material present in a measured portion of the oil sample. A high solids reading can indicate
        contamination or lubricant breakdown. Results are reported in percent volume.)`;

export const VISCOSITY_DESCRIPTION = `(Viscosity: the measure of a fluids internal resistance to flow at a given temperature in relation to time. 
    Changes in viscosity can indicate dilution,
oxidation, improper servicing, or lubricant breakdown. Results are reported in centistokes (cSt) at 40° C.)`;

export const WATER_DESCRIPTION = `(Water, Karl Fischer: the amount of water suspended in a lubricant, measured by Karl Fischer titration in 
    parts per million (ppm) by weight.)`;

export const TOTAL_ACID_DESCRIPTION = `(Total acid number: measures the total amount of acidic product present in a lubricant. 
    Generally, an increase in TAN above that of the new product
indicates oil oxidation or contamination with an acidic product.)`;

export const pdfColorCode = {
  NORMAL: 'N',
  SEVERE: 'S',
  CAUTION: 'C',
  ABNORMAL: 'AB',
  ABNORMAL_CODE: 'Ab',
};
