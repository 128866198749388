import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import {
  ChangePasswordActions, changePasswordStart, successChangePassword, failureChangePassword,
} from './sliceChangePwd';
import {
  makePutRequest,
} from '../../../Common/NetworkOps';
import {
  ChangePasswordApiResponse, ChangePasswordData,
} from '../utils/ChangePassword';
import Config from '../../../Common/Config';
import { showErrorToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

async function ChangePassword(data: ChangePasswordData): Promise<ChangePasswordApiResponse> {
  const url = `${Config.auth.changePassword}`;
  const result = await makePutRequest<ChangePasswordApiResponse>(url, data);
  return result?.data;
}

export const epicChangePassword = (action$: Observable<ChangePasswordActions>) => action$.pipe(
  filter(changePasswordStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(ChangePassword(data)).pipe(
    mergeMap((res: ChangePasswordApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return of(successChangePassword());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(failureChangePassword(res.BMT.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(changePasswordStart.match))),
    catchError((error: AxiosError<ChangePasswordApiResponse>) => {
      const errorMessage = error.response?.data.BMT.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      failureChangePassword(errorMessage);
      return of();
    }),
  )),
);
