import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddReportListData, ReportListPayloadListing, PayloadTypeDeleteReportList, PayloadTypeGetReportList, ReportListState, UpdateReportListData,
} from '../utils/types';

const initialState: ReportListState = {
  ReportListData: {} as ReportListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const reportListSlice = createSlice({
  name: 'reportList',
  initialState,
  reducers: {
    getReportListStart(state, _action: PayloadAction<PayloadTypeGetReportList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successReportList(state, action: PayloadAction<ReportListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        ReportListData: action?.payload as ReportListPayloadListing,
      };
    },
    failureReportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateReportList(state, _action: PayloadAction<UpdateReportListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateReportList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateReportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addReportListStart(state, _action: PayloadAction<AddReportListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddReportList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddReportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteReportListStart(state, _action: PayloadAction<PayloadTypeDeleteReportList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteReportList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteReportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getReportListStart, successReportList, failureReportList, updateReportList,
  successUpdateReportList, failureUpdateReportList, resetState, deleteReportListStart,
  successDeleteReportList, failureDeleteReportList,
  addReportListStart, successAddReportList, failureAddReportList,
} = reportListSlice.actions;
export const ListReportReducer = reportListSlice.reducer;

export type ReportTypeActions =
  | ReturnType<typeof getReportListStart>
  | ReturnType<typeof successReportList>
  | ReturnType<typeof failureReportList>

  | ReturnType<typeof updateReportList>
  | ReturnType<typeof successUpdateReportList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateReportList>

  | ReturnType<typeof deleteReportListStart>
  | ReturnType<typeof successDeleteReportList>
  | ReturnType<typeof failureDeleteReportList>

  | ReturnType<typeof addReportListStart>
  | ReturnType<typeof successAddReportList>
  | ReturnType<typeof failureAddReportList>;
