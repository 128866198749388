/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useContext, useEffect, useMemo,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { TypesDialogBox } from '../../../../utils/interface';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { AddVesselListData, VesselList } from '../utils/types';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import { VesselListFormValue } from '../utils/ConstantsVesselList';
import { RootState } from '../../../../redux/rootState';
import { vesselListSchema } from '../utils/validations';
import {
  addVesselListStart, resetState, updateVesselList,
} from '../redux/sliceVesselList';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentVesselListDialog({
  show, setOpenFrom, rowId, handleData,
}: TypesDialogBox): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const { isSuccess, VesselListData } = useSelector((state: RootState) => state.ListVesselReducer);

  const dispatch = useDispatch();

  const {
    formState: { errors }, control, handleSubmit, setValue, reset,
  } = useForm<AddVesselListData>({
    defaultValues: {
      Description: '',
    },
    resolver: yupResolver(vesselListSchema as ObjectSchema<AddVesselListData>),
  });

  const resetValues = () => {
    setValue(VesselListFormValue.DESCRIPTION, '');
  };

  const onSubmit = (data: AddVesselListData) => {
    if (rowId) {
      const updateData = {
        Id: rowId,
        Description: data.Description,
      };
      dispatch(updateVesselList(updateData));
      reset();
      setOpenFrom();
    } else {
      const bodyData = {
        Description: data.Description,
      };
      dispatch(addVesselListStart(bodyData));
      reset();
      setOpenFrom();
    }
  };

  const tableData: VesselList[] = useMemo(() => VesselListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      sn: index,
      action: 'edit&delete',
      Description: item.Description,
      Percentage: item.Percentage,
      id: String(item?.Id),
    };
    return transformedDataItem;
  }), [VesselListData]);

  useEffect(() => {
    if (rowId) {
      tableData?.forEach((rowData) => {
        if (rowData?.id === rowId) {
          setValue(VesselListFormValue.DESCRIPTION, rowData.Description);
        }
      });
    }
  }, [rowId]);

  useEffect(() => {
    if (isSuccess) {
      resetValues();
      if (handleData) {
        handleData();
      }
      dispatch(resetState());
    }
  }, [isSuccess]);

  const handleClose = () => {
    setOpenFrom();
    reset();
    setValue(VesselListFormValue.DESCRIPTION, '');
  };

  const getAnalystHeading = () => {
    if (rowId) {
      return 'Edit Description';
    }
    return 'Add Description';
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          {getAnalystHeading()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">{translations.description}</label>
                  <ComponentTextInput
                    control={control}
                    className="nonCapitalizeField"
                    inputProps={{ maxLength: 50 }}
                    name={VesselListFormValue.DESCRIPTION}
                    size="small"
                    id={VesselListFormValue.DESCRIPTION}
                    errors={errors}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">{translations.save}</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ComponentVesselListDialog;
