/* eslint-disable max-len */
import React from 'react';

export function ReportingWorkSpaceIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_13193_5261)">
        <path d="M16.0197 12.1953C15.1532 12.1953 14.3784 12.5949 13.8694 13.2193H10.1071C9.59805 12.5949 8.82332 12.1953 7.95682 12.1953C7.09029 12.1953 6.31555 12.5949 5.80649 13.2193H3V20.9997H4.05469V14.274H5.27116C5.21358 14.4962 5.18289 14.7292 5.18289 14.9692V17.9049H7.42948V19.0852H5.18289V20.9997H6.23757V20.1399H7.42948V20.9997H8.48416V20.1399H9.67603V20.9997H10.7307V19.0852H8.48416V17.9049H10.7307V14.9692C10.7307 14.7292 10.7 14.4963 10.6424 14.274H13.334C13.2765 14.4963 13.2458 14.7292 13.2458 14.9692V17.9049H15.4924V19.0852H13.2458V20.9997H14.3004V20.1399H15.4924V20.9997H16.547V20.1399H17.7389V20.9997H18.7936V19.0852H16.547V17.9049H18.7936V14.9692C18.7936 14.7292 18.7629 14.4963 18.7054 14.274H19.9453V20.9997H21V13.2193H18.17C17.661 12.5949 16.8862 12.1953 16.0197 12.1953ZM9.67603 16.8502H6.23757V14.9692C6.23757 14.0213 7.00883 13.25 7.95682 13.25C8.90481 13.25 9.67603 14.0213 9.67603 14.9692V16.8502ZM17.7389 16.8502H14.3004V14.9692C14.3004 14.0213 15.0717 13.25 16.0197 13.25C16.9676 13.25 17.7389 14.0213 17.7389 14.9692V16.8502H17.7389Z" fill="#092245" />
        <path d="M3 3V10.9467H21V3H3ZM19.9453 9.892H4.05469V4.05469H19.9453V9.892Z" fill="#092245" />
        <path d="M5.35498 5.3916H6.40967V6.44629H5.35498V5.3916Z" fill="#092245" />
        <path d="M5.35498 7.50098H6.40967V8.55566H5.35498V7.50098Z" fill="#092245" />
        <path d="M17.5122 5.3916H18.5669V6.44629H17.5122V5.3916Z" fill="#092245" />
        <path d="M17.5122 7.50098H18.5669V8.55566H17.5122V7.50098Z" fill="#092245" />
        <path d="M16.1282 7.5412H15.0713V6.36448H14.0166V7.5412H12.9619V5.4043H11.9073V7.5412H10.8526V6.36448H9.79789V7.5412H8.74316V5.4043H7.68848V8.59589H16.1282V7.5412Z" fill="#092245" />
      </g>
      <defs>
        <clipPath id="clip0_13193_5261">
          <rect width="18" height="18" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>
  );
}
