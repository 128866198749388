import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import './Style.scss';
import { IAddSiteModal } from './utils/Type';
// eslint-disable-next-line import/no-cycle
import { AssociatedSites } from './components/associatedSites';
import { AddSiteInfo } from './components/addSiteInfo';

export default function AddSiteModal({
  show, setShow, getValues,
}: IAddSiteModal) {
  const [showAddSite, setShowAddSite] = useState(false);

  const handleButtonClick = () => {
    setShowAddSite(true);
    setShow();
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AssociatedSites getValues={getValues} handleClose={setShow} handleButtonClick={handleButtonClick} />
      </Modal>
      <AddSiteInfo show={showAddSite} handleClose={() => setShowAddSite(false)} mode="Site Information" />
    </div>
  );
}
