import { Grid, Card } from '@mui/material';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function ComponentCustComments() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Card className="card_style">
          <div className="customerInfo_container">
            <div className="customerInfo">
              Cust Comments
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <div className="editorContainer">
                  <Editor
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                                        // onEditorStateChange={onEditorStateChange}
                    localization={{
                      locale: 'en',
                    }}
                    toolbar={{
                      blockType: { className: 'demo-option-custom-wide', dropdownClassName: 'demo-dropdown-custom' },
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
