import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, Observable, of, switchMap, takeUntil,
} from 'rxjs';

import { AxiosError } from 'axios';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import { makeGetRequest, makePutRequest } from '../../../../Common/NetworkOps';
import { GetById } from '../../../../utils/type';
import {
  VesselMainActions,
  updateVesselMainFailure, getVesselMainByIdFailure, getVesselMainByIdStart, getVesselMainByIdSuccess, updateVesselMainStart, updateVesselMainSuccess,
  getTubeTypeStart,
  getTubeTypeSuccess,
  getTubeTypeFailure,
  getTestEndStart,
  getTestEndSuccess,
  getTestEndFailure,
  getMaterialStart,
  getMaterialSuccess,
  getMaterialFailure,
  getSupportStart,
  getSupportSuccess,
  getSupportFailure,
  getVesselStart,
  getVesselSuccess,
  getVesselFailure,
} from './vesselMainSlice';
import {
  GetDropDownList, GetVesselMainPayload, VesselMainDataById, VesselMainPayloadAPI,
  VesselPayloadType,
} from '../../utils/mainTypes';
import { createGetEpicWithData, createGetEpicWithoutData, getData } from '../../../../utils/CommonEpic';

async function getVesselMainDataById(data: GetVesselMainPayload): Promise<GetById<VesselMainDataById>> {
  const url = `${Config.vesselInformation.vesselMain.getVesselByIdUrl}?VesselId=${data.vesselId}&JobOrder=${data.jobId}`;
  const result = await makeGetRequest<GetById<VesselMainDataById>>(url);
  return result.data;
}

export const getVesselMainByIdEpic = (action$: Observable<VesselMainActions>) => action$.pipe(
  ofType(getVesselMainByIdStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: GetVesselMainPayload) => from(getVesselMainDataById(data)).pipe(
    map((res: GetById<VesselMainDataById>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getVesselMainByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getVesselMainByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getVesselMainByIdStart.match))),
    catchError((error) => of(getVesselMainByIdFailure(error))),
  )),
);

async function updateVesselMain(data: VesselMainPayloadAPI): Promise<GetById<string>> {
  const url = `${Config.vesselInformation.vesselMain.updateVesselUrl}`;
  const result = await makePutRequest<GetById<string>>(url, data);
  return result.data;
}

export const updateVesselMainEpic = (action$: Observable<VesselMainActions>) => action$.pipe(
  ofType(updateVesselMainStart.type),
  map((x) => x.payload),
  debounceTime(250),
  switchMap((data: VesselMainPayloadAPI) => from(updateVesselMain(data)).pipe(
    map((res: GetById<string>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return (updateVesselMainSuccess());
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return updateVesselMainFailure();
    }),
    takeUntil(action$.pipe(filter(updateVesselMainStart.match))),
    catchError((error: AxiosError<GetById<string>>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseStatus as string;
      of(showErrorToaster(errorMessage));
      return of(updateVesselMainFailure());
    }),
  )),
);

export const getTubeTypeDataEpic = createGetEpicWithoutData<GetDropDownList[]>(
  getTubeTypeStart.type,
  () => getData<GetDropDownList[]>(`${Config.vesselInformation.vesselMain.getTubeTypeListUrl}`),
  getTubeTypeSuccess,
  getTubeTypeFailure,
);

export const getTestEndDataEpic = createGetEpicWithoutData<GetDropDownList[]>(
  getTestEndStart.type,
  () => getData<GetDropDownList[]>(`${Config.vesselInformation.vesselMain.getTestEndListUrl}`),
  getTestEndSuccess,
  getTestEndFailure,
);

export const getMaterialDataEpic = createGetEpicWithoutData<GetDropDownList[]>(
  getMaterialStart.type,
  () => getData<GetDropDownList[]>(`${Config.vesselInformation.vesselMain.getMaterialListUrl}`),
  getMaterialSuccess,
  getMaterialFailure,
);

export const getSupportDataEpic = createGetEpicWithoutData<GetDropDownList[]>(
  getSupportStart.type,
  () => getData<GetDropDownList[]>(`${Config.vesselInformation.vesselMain.getSupportListUrl}`),
  getSupportSuccess,
  getSupportFailure,
);

export const getVesselDataEpic = createGetEpicWithData<GetDropDownList[], VesselPayloadType>(
  getVesselStart.type,
  (data) => getData<GetDropDownList[]>(`${Config.vesselInformation.vesselMain.getVesselListUrl}?jobOrder=${data.id}`),
  getVesselSuccess,
  getVesselFailure,
);
