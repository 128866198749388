import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import './StyleMainTab.scss';
import EndPoints from '../../../../../Routes/EndPoints';
import AnalystInfo from './AnalystInfo';
import { MainFormValues } from '../../utils/types';
import BasedOutInfo from './BasedOutInfo';
import SecondaryBasedForm from './SecondaryBasedForm';
import { MainDefaultValue, analystBody } from '../../utils/constants';
import { mainFormSchema } from '../../utils/validations';
import {
  clearCache, createAnalyst,
  getAnalystDataById, updateAnalyst,
} from '../../redux/AddAnalystSlice';
import { RootState } from '../../../../../redux/rootState';
import { getRequestPayload, setValues } from '../../../AnalystListScreen/utils/helper';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import { scrollToErrorField } from '../../../../../utils/CommonFunctions';

export default function ComponentMainTab() {
  const {
    control, handleSubmit, formState: { errors }, setValue, watch, getValues, trigger,
  } = useForm<MainFormValues>({
    resolver: yupResolver(mainFormSchema),
    defaultValues: MainDefaultValue,
  });
  const { translations } = useContext(LocalizationContext);
  const { isPinError, isBaseOutPinError, isSecondaryBasePinError } = useSelector((state: RootState) => state.AddAnalystSliceReducer);

  const { analystDataById } = useSelector((state: RootState) => state.AddAnalystSliceReducer);
  const dispatch = useDispatch();
  const { analystId } = useParams();
  const navigate = useNavigate();

  const formRef = useRef(null);

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      scrollToErrorField(errorField);
    }
  }, [errors]);

  const onSubmit = (data: MainFormValues) => {
    const reqBody = getRequestPayload(data);
    if (analystId) {
      const body = {
        ...reqBody, AnalystId: analystId,
      };
      return dispatch(updateAnalyst({ reqBody: body, navigate }));
    }
    return dispatch(createAnalyst({ reqBody, navigate }));
  };

  useEffect(() => {
    if (analystId) {
      dispatch(getAnalystDataById(analystId));
    }
    return () => {
      dispatch(clearCache(analystBody));
    };
  }, [analystId]);

  useEffect(() => {
    if (analystDataById) {
      setValues(analystDataById, setValue);
    }
  }, [analystDataById]);

  const handleCancel = () => {
    navigate(`/${EndPoints.ANALYST}`);
  };

  return (
    <div className="div_container_CustomerInfo">
      <form onSubmit={handleSubmit(onSubmit)} noValidate ref={formRef}>
        <Grid container spacing={2}>

          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <AnalystInfo errors={errors} control={control} getValues={getValues} setValue={setValue} watch={watch} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <BasedOutInfo errors={errors} control={control} watch={watch} setValue={setValue} getValues={getValues} trigger={trigger} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <SecondaryBasedForm errors={errors} control={control} setValue={setValue} watch={watch} getValues={getValues} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={handleCancel}>{translations.cancel}</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  disabled={isBaseOutPinError || isPinError || isSecondaryBasePinError}
                  type="submit"
                  className="button_save_and_next"
                >
                  {translations.save}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>

    </div>
  );
}
