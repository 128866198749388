export const JobScheduleTableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'bidId', label: 'Bid #' },
  { key: 'jobId', label: 'Job #' },
  { key: 'bidName', label: 'Bid Name' },
  { key: 'custId', label: 'Cust #' },
  { key: 'customer', label: 'Customer' },
  { key: 'site', label: 'Site' },
  { key: 'city', label: 'City' },
  { key: 'serialToolTip', label: 'Serial #' },
  { key: 'modelToolTip', label: 'Model' },
];

export const JobUnScheduleTableColumns = [
  // { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'bidId', label: 'Bid #' },
  { key: 'jobId', label: 'Job #' },
  { key: 'bidName', label: 'Bid Name' },
  { key: 'custId', label: 'Cust #' },
  { key: 'customer', label: 'Customer' },
  { key: 'site', label: 'Site' },
  { key: 'city', label: 'City' },
  { key: 'serialToolTip', label: 'Serial #' },
  { key: 'modelToolTip', label: 'Model' },
];

export const JobAndSiteTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'mfg', label: 'Mfg' },
  { key: 'model', label: 'Model #' },
  { key: 'serial', label: 'Serial #' },
  { key: 'tubeCount', label: 'Tube Count' },
  { key: 'analystAction', label: 'Schedule' },
  { key: 'reTest', label: 'Re Test' },
  { key: 'jobOrder', label: 'Job Order #' },
  { key: 'vesselToolTip', label: 'Vessel' },
  { key: 'reportAction', label: 'Reports' },
  { key: 'equipmentToolTip', label: 'Equipment' },
];

export const AccommodationTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'accommodation', label: 'Accommodation' },
  { key: 'dateIn', label: 'Date In' },
  { key: 'dateOut', label: 'Date Out' },
  { key: 'city', label: 'City' },
  { key: 'phone', label: 'Phone' },
  { key: 'confirmation', label: 'Confirmation' },
];

export enum JobScheduleFields {
  analyst = 'analyst',
  startDate = 'startDate',
  endDate = 'endDate',
  po = 'po',
  notes = 'notes',
  equipmentCheckBox = 'equipmentCheckBox',
  fluxCheckBox = 'fluxCheckBox',
  smallJobCheckBox = 'smallJobCheckBox',
  dayBeforeCheckBox = 'dayBeforeCheckBox',
  nightJobCheckBox = 'nightJobCheckBox',
  nextDayCheckBox = 'nextDayCheckBox',
  doNotMoveCheckBox = 'doNotMoveCheckBox',
  // job & site
  customerId = 'customerId',
  company = 'company',
  contact = 'contact',
  address = 'address',
  addressOptional = 'addressOptional',
  zip = 'zip',
  state = 'state',
  city = 'city',
  code = 'code',
  phone = 'phone',
  cell = 'cell',
  // Contact
  contactInfo = 'contactInfo',
  cellInfo = 'cellInfo',
  codeInfo = 'codeInfo',
  phoneInfo = 'phoneInfo',
  // Site
  site = 'site',
  siteContact = 'siteContact',
  siteAddress = 'siteAddress',
  siteAddressOptional = 'siteAddressOptional',
  siteZip = 'siteZip',
  siteState = 'siteState',
  siteCity = 'siteCity',
  otherSiteCity = 'otherSiteCity',
  siteCode = 'siteCode',
  sitePhone = 'sitePhone',
  siteCell = 'siteCell',
  siteNotes = 'siteNotes',
  siteCountry = 'siteCountry',
  // Travel
  travelDirection = 'travelDirection',
  flightOutDate = 'flightOutDate',
  flightOutAirline = 'flightOutAirline',
  flightOutDeparture = 'flightOutDeparture',
  flightOutArrival = 'flightOutArrival',
  flightOutSeat = 'flightOutSeat',
  flightInDate = 'flightInDate',
  flightInAirline = 'flightInAirline',
  flightInDeparture = 'flightInDeparture',
  flightInArrival = 'flightInArrival',
  flightInSeat = 'flightInSeat',
  carRentalCompany = 'carRentalCompany',
  carRentalPhone = 'carRentalPhone',
  carRentalPrice = 'carRentalPrice',
  carRentalConfirmation = 'carRentalConfirmation',
  carInstruction = 'carInstruction',

  // hotel
  hotelJobCode = 'hotelJobCode',
  hotelMotel = 'hotelMotel',
  hotelAddress = 'hotelAddress',
  hotelAddressOptional = 'hotelAddressOptional',
  hotelZip = 'hotelZip',
  hotelState = 'hotelState',
  hotelCity = 'hotelCity',
  otherHotelCity = 'otherHotelCity',
  hotelCountry = 'hotelCountry',
  hotelDateIn = 'hotelDateIn',
  hotelDateOut = 'hotelDateOut',
  hotelPrice = 'hotelPrice',
  hotelConfirmation = 'hotelConfirmation',
  late = 'late',
}

export const FIRST_SELECTED_TAB = 1;
