import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, YAxis, CartesianGrid, Tooltip, Legend,
  XAxis,
} from 'recharts';
import { VesselReport } from '../types';
import { Empty } from '../../ScreenAddSmc/Utils/TypeSmc';

function ComponentBarChart({ graphData }: { graphData: VesselReport }) {
  const [graphdata, setGraphData] = useState([]);

  useEffect(() => {
    setGraphData(graphData.DefectComparison as Empty);
  }, [graphData]);

  return (
    <div
      className="barChartContainer"
    >
      {/* <ResponsiveContainer width="500"> */}
      <BarChart
        width={700}
        height={500}
        data={graphdata}
      >
        <CartesianGrid />
        <XAxis dataKey="DefectName" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="DefectCount" fill="#F6131A" />
        <Bar dataKey="RevisedDefectCount" fill="#F4F323" />
      </BarChart>
      {/* </ResponsiveContainer> */}
    </div>
  );
}

export default ComponentBarChart;
