/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
import { KonvaEventObject } from 'konva/lib/Node';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Konva from 'konva';
import { DrawAction } from '../components/PaintConstants';
import {
  CheckDeselectType,
  OnStageMouseMoveParams, OtherFunctionParams, Parameters, PhotosResultDataById,
} from './types';
import { TextElementType } from '../components/PaintTypes';

export function ExportData(photoDataById: PhotosResultDataById, dataURL: string | undefined) {
  if (dataURL) {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    const file = new File([blob], photoDataById?.FileName || 'image.png', { type: mimeString });
    // const file = new File([new Blob([dataURL])], photoDataById?.FileName);
    const formData = new FormData();
    formData.append('Id', String(photoDataById?.Id));
    formData.append('PhotoId', photoDataById?.PhotoId);
    formData.append('Comment', '');
    formData.append('PhotoPath', '');
    formData.append('VesselName', photoDataById?.VesselName);
    formData.append('OldPhoto', '');
    formData.append('FileName', photoDataById?.FileName);
    formData.append('JobOrder', photoDataById?.JobOrder);
    formData.append('ImageFile', file);
    return formData;
  }
}

// -- Text Transform ---

export const onTextTransform = (transformerRef: RefObject<Konva.Transformer>) => {
  if (!transformerRef.current) return;
  const selectedNode = transformerRef.current?.getNode();
  if (selectedNode && selectedNode.getClassName() === 'Text') {
    const scaleX = selectedNode.scaleX();
    const scaleY = selectedNode.scaleY();
    selectedNode.scaleX(1);
    selectedNode.scaleY(1);
    transformerRef.current.attachTo(selectedNode);
    selectedNode.scaleX(scaleX);
    selectedNode.scaleY(scaleY);
    transformerRef?.current?.getLayer()?.batchDraw();
  }
};

export const onTextClick = (e: KonvaEventObject<Event | TouchEvent>, transformerRef: RefObject<Konva.Transformer>) => {
  const { currentTarget } = e;
  transformerRef.current?.nodes([currentTarget]);
  onTextTransform(transformerRef);
};

export const onTextDblClick = (
  e: KonvaEventObject<Event | TouchEvent>,
  transformerRef: RefObject<Konva.Transformer>,
  setTexts: Dispatch<SetStateAction<TextElementType[]>>,
) => {
  const { currentTarget } = e;
  const id = currentTarget.id();
  const newText = window.prompt('Edit text:');
  if (newText !== null && newText !== '') {
    setTexts((prevTexts) => prevTexts.map((text) => (text.id === id ? { ...text, text: newText } : text)));
  }
  onTextTransform(transformerRef);
};

export const onStageMouseMove = ({
  drawAction,
  isPaintRefs,
  stageRef,
  currentShapeRefs,
  setScribbles,
  setCircles,
  setRectangles,
  setArrows,
}: OnStageMouseMoveParams): void => {
  if (drawAction === DrawAction.Select || !isPaintRefs) return;

  const stage = stageRef?.current;
  const id = currentShapeRefs;
  const pos = stage?.getPointerPosition();
  const x = pos?.x || 0;
  const y = pos?.y || 0;

  switch (drawAction) {
    case DrawAction.Scribble: {
      setScribbles((prevScribbles) => prevScribbles?.map((prevScribble) => (prevScribble.id === id
        ? {
          ...prevScribble,
          points: [...prevScribble.points, x, y],
        }
        : prevScribble)));
      break;
    }
    case DrawAction.Circle: {
      setCircles((prevCircles) => prevCircles?.map((prevCircle) => (prevCircle.id === id
        ? {
          ...prevCircle,
          radius:
            ((x - prevCircle.x) ** 2 + (y - prevCircle.y) ** 2) ** 0.5,
        }
        : prevCircle)));
      break;
    }
    case DrawAction.Rectangle: {
      setRectangles((prevRectangles) => prevRectangles?.map((prevRectangle) => (prevRectangle.id === id
        ? {
          ...prevRectangle,
          height: y - prevRectangle.y,
          width: x - prevRectangle.x,
        }
        : prevRectangle)));
      break;
    }
    case DrawAction.Arrow: {
      setArrows((prevArrows) => prevArrows.map((prevArrow) => (prevArrow.id === id
        ? {
          ...prevArrow,
          points: [prevArrow.points[0], prevArrow.points[1], x, y],
        }
        : prevArrow)));
      break;
    }
    default: {
      break;
    }
  }
};

export const onDelete = ({
  transformerRef, setRectangles, setCircles, setScribbles, setArrows, setTexts,
}: OtherFunctionParams) => {
  const selectedNode = transformerRef.current?.getNode();
  if (selectedNode) {
    const id = selectedNode.id();
    switch (selectedNode.getClassName()) {
      case 'Rect':
        setRectangles((prevRectangles) => prevRectangles.filter((rect) => rect.id !== id));
        break;
      case 'Circle':
        setCircles((prevCircles) => prevCircles.filter((circle) => circle.id !== id));
        break;
      case 'Line':
        setScribbles((prevScribbles) => prevScribbles.filter((scribble) => scribble.id !== id));
        break;
      case 'Arrow':
        setArrows((prevArrows) => prevArrows.filter((arrow) => arrow.id !== id));
        break;
      case 'Text':
        setTexts((prevTexts) => prevTexts.filter((text) => text.id !== id));
        break;
      default:
        break;
    }
    if (transformerRef.current) {
      transformerRef.current.detach();
    }
  }
};

export const checkDeselect = ({ e, stageRef, transformerRef }: CheckDeselectType) => {
  const clickedOnEmpty = e.target === stageRef?.current?.find('#bg')?.[0];
  if (clickedOnEmpty) {
    transformerRef?.current?.nodes([]);
  }
};

export const onStageMouseDown = ({
  e, drawAction, setIsPaintRefs, stageRef, setCurrentShapeRefs, setScribbles, setCircles, setRectangles, setArrows, setTexts, color, transformerRef,
}: Parameters) => {
  checkDeselect({ e, stageRef, transformerRef });

  if (drawAction === DrawAction.Select) return;
  setIsPaintRefs(true);
  const stage = stageRef?.current;
  const pos = stage?.getPointerPosition();
  const x = pos?.x || 0;
  const y = pos?.y || 0;
  const id = uuidv4();
  setCurrentShapeRefs(id);

  switch (drawAction) {
    case DrawAction.Scribble: {
      setScribbles((prevScribbles) => [
        ...prevScribbles,
        {
          id,
          points: [x, y],
          color,
        },
      ]);
      break;
    }
    case DrawAction.Circle: {
      setCircles((prevCircles) => [
        ...prevCircles,
        {
          id,
          radius: 1,
          x,
          y,
          color,
        },
      ]);
      break;
    }
    case DrawAction.Rectangle: {
      setRectangles((prevRectangles) => [
        ...prevRectangles,
        {
          id,
          height: 1,
          width: 1,
          x,
          y,
          color,
        },
      ]);
      break;
    }
    case DrawAction.Arrow: {
      setArrows((prevArrows) => [
        ...prevArrows,
        {
          id,
          points: [x, y, x, y],
          color,
        },
      ]);
      break;
    }
    case DrawAction.Text: {
      setTexts((prevTexts) => [
        ...prevTexts,
        {
          id,
          x,
          y,
          color,
          text: 'Double click to edit',
        },
      ]);
      break;
    }
    default: {
      break;
    }
  }
};
