export const MAIN_TAB = 0;
export const COMMENT_TAB = 1;
export const CONTACT_TAB = 2;
export const JOB_TAB = 3;
export const BIDS_TAB = 4;
export const THEMES_TAB = 5;
export const LABEL_MAIN_TAB = 'Main';
export const LABEL_COMMENT_TAB = 'Comments';
export const LABEL_CONTACT_TAB = 'Contacts';
export const LABEL_JOB_TAB = 'Job Order';
export const LABEL_BIDS_TAB = 'Bids';
export const CUSTOMER_ID = 'customerId';
export const SMC_ID = 'smcId';
export const VACATION_TAB = 1;
export const LABEL_VACATION_TAB = 'Leave';
export const LABEL_THEMES_TAB = 'Themes';

export const MainDefaultValue = {
  title: 1,
  contactCode: '+1',
  phone: '',
  alternativePhone: '',
  markup: '',
  customerName: '',
  address: '',
  companyCountryCode: '+1',
  companyPhone: '',
  companyExt: '',
  companyAddress: '',
  companyZip: '',
  companyState: '',
  companyOtherCity: '',
  primaryEmailId: '',
  companyCity: '',
  companyCountry: 'US',
  sameCompanyAddress: false,
  creditCardRequired: false,
  approvedPO: false,
  billToCorporate: '',
  billingAddress: '',
  billingOptionalAddress: '',
  billingZip: '',
  billingState: '',
  billingCity: '',
  billingOtherCity: '',
  billingCountry: 'US',
  contactTitle: 1,
  contactFirstName: '',
  contactLastName: '',
  contactEmailId: '',
  contactCountryCode: '+1',
  contactPhone: '',
  contactAlternativePhone: '',
};

export const ContactDefaultValue = {
  title: 1,
  firstName: '',
  lastName: '',
  emailId: '',
  office: '',
  address: '',
  zip: '',
  state: '',
  city: '',
  country: 'US',
  code: '+1',
  phone: '',
  ext: '',
  cell: '',
  monthlyReport: false,
  ECTReport: false,
  oilReport: false,
};

export const themesColors = [
  {
    id: 1,
    color: '#41578E',
  },
  {
    id: 2,
    color: '#688FA1',
  },
  {
    id: 3,
    color: '#975DD8',
  },
  {
    id: 4,
    color: '#67AA58',
  },
  {
    id: 5,
    color: '#CF625E',
  },
  {
    id: 6,
    color: '#00A751',
  },
  {
    id: 7,
    color: '#F06548',
  },
  {
    id: 8,
    color: '#F7B84B',
  },
  {
    id: 9,
    color: '#FA2B00',
  },
];
