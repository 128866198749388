import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AnalystDetails, AssociateUnitPayload, BidInitialValues,
  BidDetailsValues, CreateBidPayload, DeleteUnitPayload, DriveExpenseResponse,
  DriverExpensePayload, GetAnalystCitypayload, GetBidByIdResponse, GetBidPricingPayload, GetBidPricingResponse,
  GetCustomerAnalystPayload,
  GetCustomerAnalystResponse, GetNearestAnalystResponse, MasterValue, UnitListing, UnitListingPayload, UpdateBidPayload,
} from '../utils/types';
import { PaginationResponse } from '../../../../utils/type';

const initialState: BidInitialValues = {
  analystList: [],
  isLoading: false,
  selectedTab: 1,
  bidId: '',
  analystDetails: {} as AnalystDetails,
  selectedBid: {} as GetBidByIdResponse,
  unitList: [],
  generalUnitList: [],
  totalGeneralUnits: 0,
  totalUnits: 0,
  expenseDetails: {} as DriveExpenseResponse,
  bidPricing: {} as GetBidPricingResponse,
  customerAnalyst: {} as GetCustomerAnalystResponse,
  nearestAnalystDetails: {} as GetNearestAnalystResponse,
  bidPricingInfo: {} as BidDetailsValues,
  bidPricingValues: {} as BidDetailsValues,
};

export const bidSlice = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    removeBidState() {
      return initialState;
    },
    setBidpricingInfo(state, action: PayloadAction<BidDetailsValues>) {
      return {
        ...state,
        bidPricingInfo: action.payload,
      };
    },
    // Get Analyst List
    getAnalystMasterStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAnalystMasterSuccess(state, action:PayloadAction<MasterValue[]>) {
      return {
        ...state,
        isLoading: false,
        analystList: action.payload,
      };
    },
    getAnalystmasterFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Create Bid Start
    createBidStart(state, _action:PayloadAction<CreateBidPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createBidSuccess(state, action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        bidId: action.payload,
      };
    },
    createBidFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Update Bid Start
    updateBidStart(state, _action:PayloadAction<UpdateBidPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateBidSuccess(state, action:PayloadAction<GetBidByIdResponse>) {
      return {
        ...state,
        isLoading: false,
        selectedBid: action.payload,
      };
    },
    updateBidFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Analyst City
    getAnalystCityStart(state, _action:PayloadAction<GetAnalystCitypayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAnalystCitySuccess(state, action:PayloadAction<AnalystDetails>) {
      return {
        ...state,
        isLoading: false,
        analystDetails: action.payload,
      };
    },
    getAnalystCityFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // get Bid By Id
    getBidByIdStart(state, action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
        bidId: action.payload,
      };
    },
    getBidByIdSuccess(state, action:PayloadAction<GetBidByIdResponse>) {
      return {
        ...state,
        selectedBid: action.payload,
        isLoading: false,
      };
    },
    getBidByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Associated Units
    getAssociatedUnitsStart(state, _action:PayloadAction<UnitListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getAssociatedUnitsSuccess(state, action: PayloadAction<PaginationResponse<UnitListing>>) {
      return {
        ...state,
        isLoading: false,
        unitList: action.payload.Data,
        totalUnits: action.payload.TotalRecords,
      };
    },
    getAssociatedUnitsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getGeneralUnitsSuccess(state, action: PayloadAction<PaginationResponse<UnitListing>>) {
      return {
        ...state,
        isLoading: false,
        generalUnitList: action.payload.Data,
        totalGeneralUnits: action.payload.TotalRecords,
      };
    },
    // Get Driver Expense
    getDriverExpenseStart(state, _action:PayloadAction<DriverExpensePayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getDriverExpenseSuccess(state, action:PayloadAction<DriveExpenseResponse>) {
      return {
        ...state,
        isLoading: false,
        expenseDetails: action.payload,
      };
    },
    getDriverExpenseFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Get Bid pricing
    getBidPricingStart(state, _action:PayloadAction<GetBidPricingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getBidPricingSuccess(state, action:PayloadAction<GetBidPricingResponse>) {
      return {
        ...state,
        isLoading: false,
        bidPricing: action.payload,
      };
    },
    getBidPricingFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get Customer Analyst
    getCustomerAnalystStart(state, _action:PayloadAction<GetCustomerAnalystPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getCustomerAnalystSuccess(state, action:PayloadAction<GetCustomerAnalystResponse>) {
      return {
        ...state,
        isLoading: false,
        customerAnalyst: action.payload,
      };
    },
    getCustomerAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Get nearest Analyst
    getNearestAnalystStart(state, _action:PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getNearestAnalystSuccess(state, action:PayloadAction<GetNearestAnalystResponse>) {
      return {
        ...state,
        isLoading: false,
        nearestAnalystDetails: action.payload,
      };
    },
    getNearestAnalystFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Associate Units to Bid
    associateUnitsStart(state, _action:PayloadAction<AssociateUnitPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    associatedUnitsSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    associatedUnitsFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    // Delete Unit
    deleteUnitStart(state, _action:PayloadAction<DeleteUnitPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteUnitSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteUnitFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getBidPricingValues(state, action: PayloadAction<BidDetailsValues>) {
      return {
        ...state,
        bidPricingValues: action.payload,
      };
    },
  },
});

export const {
  getAnalystMasterStart, getAnalystMasterSuccess, getAnalystmasterFailure, setSelectedTab,
  createBidStart, createBidSuccess, createBidFailure, getAnalystCityStart, getAnalystCitySuccess,
  getAnalystCityFailure, getBidByIdStart, getBidByIdSuccess, getBidByIdFailure, getAssociatedUnitsStart,
  getAssociatedUnitsSuccess, getAssociatedUnitsFailure, getDriverExpenseStart, getDriverExpenseSuccess,
  getDriverExpenseFailure, getBidPricingStart, getBidPricingSuccess, getBidPricingFailure, removeBidState,
  getCustomerAnalystStart, getNearestAnalystSuccess, getCustomerAnalystSuccess, getCustomerAnalystFailure,
  getNearestAnalystStart, getNearestAnalystFailure, setBidpricingInfo, updateBidFailure, updateBidStart, updateBidSuccess,
  getGeneralUnitsSuccess, associateUnitsStart, associatedUnitsSuccess, associatedUnitsFailure, deleteUnitStart, deleteUnitFailure,
  deleteUnitSuccess, getBidPricingValues,
} = bidSlice.actions;
export const bidsReducer = bidSlice.reducer;

export type BidsActions =
    | ReturnType<typeof getAnalystMasterStart>
    | ReturnType<typeof getAnalystMasterSuccess>
    | ReturnType<typeof getAnalystmasterFailure>
    | ReturnType<typeof setSelectedTab>
    | ReturnType<typeof createBidStart>
    | ReturnType<typeof createBidSuccess>
    | ReturnType<typeof createBidFailure>
    | ReturnType<typeof getAnalystCityStart>
    | ReturnType<typeof getAnalystCitySuccess>
    | ReturnType<typeof getAnalystCityFailure>
    | ReturnType<typeof getBidByIdStart>
    | ReturnType<typeof getBidByIdSuccess>
    | ReturnType<typeof getBidByIdFailure>
    | ReturnType<typeof getAssociatedUnitsStart>
    | ReturnType<typeof getAssociatedUnitsSuccess>
    | ReturnType<typeof getAssociatedUnitsFailure>
    | ReturnType<typeof getDriverExpenseStart>
    | ReturnType<typeof getDriverExpenseSuccess>
    | ReturnType<typeof getDriverExpenseFailure>
    | ReturnType<typeof getBidPricingStart>
    | ReturnType<typeof getBidPricingSuccess>
    | ReturnType<typeof getBidPricingFailure>
    | ReturnType<typeof removeBidState>
    | ReturnType<typeof getCustomerAnalystStart>
    | ReturnType<typeof getNearestAnalystSuccess>
    | ReturnType<typeof getCustomerAnalystSuccess>
    | ReturnType<typeof getCustomerAnalystFailure>
    | ReturnType<typeof getNearestAnalystStart>
    | ReturnType<typeof getNearestAnalystFailure>
    | ReturnType<typeof setBidpricingInfo>
    | ReturnType<typeof updateBidFailure>
    | ReturnType<typeof updateBidStart>
    | ReturnType<typeof updateBidSuccess>
    | ReturnType<typeof getGeneralUnitsSuccess>
    | ReturnType<typeof associateUnitsStart>
    | ReturnType<typeof associatedUnitsSuccess>
    | ReturnType<typeof associatedUnitsFailure>
    | ReturnType<typeof deleteUnitFailure>
    | ReturnType<typeof deleteUnitStart>
    | ReturnType<typeof deleteUnitSuccess>
    | ReturnType<typeof getBidPricingValues>;
