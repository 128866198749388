import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OilKitsInitialState, GetOilKitsListPayload,
  GetOilKitsResponseData, GetOilKitsSearchResponseData,
} from '../utils/TypeOilKits.d';

const initialState: OilKitsInitialState = {
  isLoading: false,
  oilKitsListPayload: {} as GetOilKitsListPayload,
  oilKitsListData: {} as GetOilKitsResponseData,
  oilKitResult: {} as GetOilKitsSearchResponseData,
  searchKitId: '',
  selectedId: '',
  isDeleteRecord: false,
};

export const oilKitsSlice = createSlice({
  name: 'oilKits',
  initialState,
  reducers: {
    resetOilKitsState() {
      return initialState;
    },
    getOilKitsListData(state, action: PayloadAction<GetOilKitsListPayload>) {
      return {
        ...state,
        isLoading: true,
        oilKitsListPayload: action.payload,
      };
    },
    gotOilKitsListData(state, action: PayloadAction<GetOilKitsResponseData>) {
      return {
        ...state,
        isLoading: false,
        oilKitsListData: action.payload,
      };
    },
    gotOilKitsListDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getSearchOilKit(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
        searchKitId: action.payload,
      };
    },
    gotSearchOilKit(state, action: PayloadAction<GetOilKitsSearchResponseData>) {
      return {
        ...state,
        isLoading: false,
        oilKitResult: action.payload,
      };
    },
    clearSearchOilKit(state, action: PayloadAction<GetOilKitsSearchResponseData>) {
      return {
        ...state,
        oilKitResult: action.payload,
      };
    },
    gotSearchOilKitFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    onDeleteListKit(state, action: PayloadAction<string | undefined>) {
      return {
        ...state,
        isLoading: false,
        selectedId: action.payload,
      };
    },
    onDeletedListKit(state) {
      return {
        ...state,
        isLoading: false,
        isDeleteRecord: true,
      };
    },
    onDeleteFailureListKit(state) {
      return {
        ...state,
        isDeleteRecord: false,
      };
    },
  },
});

export const {
  getOilKitsListData, gotOilKitsListData, gotOilKitsListDataFailure, getSearchOilKit,
  gotSearchOilKit, gotSearchOilKitFailure, onDeleteListKit, onDeletedListKit, onDeleteFailureListKit,
  clearSearchOilKit, resetOilKitsState,
} = oilKitsSlice.actions;
export const oilKitsSliceReducer = oilKitsSlice.reducer;

export type OilKitsActions =
  | ReturnType<typeof getOilKitsListData>
  | ReturnType<typeof gotOilKitsListData>
  | ReturnType<typeof gotOilKitsListDataFailure>
  | ReturnType<typeof getSearchOilKit>
  | ReturnType<typeof onDeleteListKit>
  | ReturnType<typeof onDeleteFailureListKit>
  | ReturnType<typeof clearSearchOilKit>
  | ReturnType<typeof resetOilKitsState>
  | ReturnType<typeof onDeletedListKit>;
