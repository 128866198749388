import * as XLSX from 'xlsx';

interface ExcelDataProps<T> {
  data: T[];
}

export const exportToExcelData = <T>({ data }: ExcelDataProps<T>, name: string) => {
  const newData = data.map((item, index) => ({
    SN: { t: 's', v: index + 1, s: { alignment: { horizontal: 'left' } } },
    ...item, // You can set the value as per your requirement
  }));

  const ws = XLSX.utils.json_to_sheet(newData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'tais-sheet');

  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const downloadExcelByUrl = (excelUrl: string) => {
  if (excelUrl) {
    // Create a hidden anchor element
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    // Set the URL to the anchor's href attribute
    a.href = excelUrl;

    // Specify the filename for the downloaded file
    a.download = 'excel_data.xlsx';

    // Trigger a click event on the anchor to initiate the download
    a.click();

    // Remove the anchor from the DOM
    document.body.removeChild(a);
  }
};
