import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../Common/TabsStyles';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import { removeSelectedJobOrder, setSelectedTab } from './redux/sliceInspectionLetter';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { DUE_TAB, PAS_DUE_TAB, SYSTEM_LOGS_TAB } from './utils/constants';
import ScreenDue from './InspectionComponents/ScreenDue';
import ScreenPasDue from './InspectionComponents/ScreenPasDue';
import ScreenSystemLogs from './InspectionComponents/ScreenSystemLogs';

export default function ScreenEditJobOrder() {
  const { selectedTab, isLoading } = useSelector((state:RootState) => state.inspectionLetterReducer);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case DUE_TAB:
        return <ScreenDue />;
      case PAS_DUE_TAB:
        return <ScreenPasDue />;
      default:
        return <ScreenSystemLogs />;
    }
  }

  useEffect(() => {
    dispatch(removeSelectedJobOrder());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <ComponentPageHeader subHeading="Re - inspection letter" />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          className="main_tabs"
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            className="tab_styles"
            label="Due"
            style={selectedTab === DUE_TAB ? selectedTabStyles : tabStyles}
            value={DUE_TAB}
          />
          <Tab
            className="tab_styles"
            label="Pas Due"
            style={selectedTab === PAS_DUE_TAB ? selectedTabStyles : tabStyles}
            value={PAS_DUE_TAB}
          />
          <Tab
            className="tab_styles"
            label="System Logs"
            style={selectedTab === SYSTEM_LOGS_TAB ? selectedTabStyles : tabStyles}
            value={SYSTEM_LOGS_TAB}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>
  );
}
