import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddSupportListData, SupportListPayloadListing, PayloadTypeDeleteSupportList,
  PayloadTypeGetSupportList, SupportListState, UpdateSupportListData,
} from '../utils/types';

const initialState: SupportListState = {
  SupportListData: {} as SupportListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const supportListSlice = createSlice({
  name: 'supportList',
  initialState,
  reducers: {
    getSupportListStart(state, _action: PayloadAction<PayloadTypeGetSupportList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successSupportList(state, action: PayloadAction<SupportListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        SupportListData: action?.payload as SupportListPayloadListing,
      };
    },
    failureSupportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateSupportList(state, _action: PayloadAction<UpdateSupportListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateSupportList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateSupportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addSupportListStart(state, _action: PayloadAction<AddSupportListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddSupportList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddSupportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteSupportListStart(state, _action: PayloadAction<PayloadTypeDeleteSupportList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteSupportList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteSupportList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getSupportListStart, successSupportList, failureSupportList, updateSupportList,
  successUpdateSupportList, failureUpdateSupportList, resetState, deleteSupportListStart,
  successDeleteSupportList, failureDeleteSupportList,
  addSupportListStart, successAddSupportList, failureAddSupportList,
} = supportListSlice.actions;
export const ListSupportReducer = supportListSlice.reducer;

export type SupportTypeActions =
  | ReturnType<typeof getSupportListStart>
  | ReturnType<typeof successSupportList>
  | ReturnType<typeof failureSupportList>

  | ReturnType<typeof updateSupportList>
  | ReturnType<typeof successUpdateSupportList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateSupportList>

  | ReturnType<typeof deleteSupportListStart>
  | ReturnType<typeof successDeleteSupportList>
  | ReturnType<typeof failureDeleteSupportList>

  | ReturnType<typeof addSupportListStart>
  | ReturnType<typeof successAddSupportList>
  | ReturnType<typeof failureAddSupportList>;
