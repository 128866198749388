import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddMaterialListApiResponse, AddMaterialListData, DeleteMaterialListResponse, GetAllMaterialListApiResponse,
  PayloadTypeDeleteMaterialList, PayloadTypeGetMaterialList, UpdateMaterialListApiResponse, UpdateMaterialListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addMaterialListStart, deleteMaterialListStart, failureAddMaterialList, failureDeleteMaterialList, failureMaterialList,
  failureUpdateMaterialList, getMaterialListStart,
  MaterialTypeActions, successAddMaterialList, successDeleteMaterialList, successMaterialList, successUpdateMaterialList, updateMaterialList,
} from './sliceMaterialList';

async function GetMaterialList(data: PayloadTypeGetMaterialList): Promise<GetAllMaterialListApiResponse> {
  const url = `${Config.Lists.getMaterialList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllMaterialListApiResponse>(url);
  return result?.data;
}

export const epicGetMaterialList = (action$: Observable<MaterialTypeActions>) => action$.pipe(
  filter(getMaterialListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetMaterialList) => from(GetMaterialList(data)).pipe(
    map((res: GetAllMaterialListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successMaterialList(res?.BMT?.Result);
      }
      return failureMaterialList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getMaterialListStart.match))),
    catchError((error: AxiosError<GetAllMaterialListApiResponse>) => of(failureMaterialList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateMaterialList(data: UpdateMaterialListData): Promise<UpdateMaterialListApiResponse> {
  const url = `${Config.Lists.updateMaterialList}`;
  const result = await makePostRequest<UpdateMaterialListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateMaterialList = (action$: Observable<MaterialTypeActions>) => action$.pipe(
  filter(updateMaterialList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateMaterialList(data)).pipe(
    mergeMap((res: UpdateMaterialListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateMaterialList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateMaterialList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateMaterialList.match))),
    catchError((error: AxiosError<UpdateMaterialListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateMaterialList(errorMessage));
    }),
  )),
);

async function AddMaterialList(data: AddMaterialListData): Promise<AddMaterialListApiResponse> {
  const url = `${Config.Lists.addMaterialList}`;
  const result = await makePostRequest<AddMaterialListApiResponse>(url, data);
  return result?.data;
}

export const epicAddMaterialList = (action$: Observable<MaterialTypeActions>) => action$.pipe(
  filter(addMaterialListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddMaterialList(data)).pipe(
    mergeMap((res: AddMaterialListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddMaterialList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddMaterialList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addMaterialListStart.match))),
    catchError((error: AxiosError<AddMaterialListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddMaterialList(errorMessage));
    }),
  )),
);

async function DeleteMaterialList(data: PayloadTypeDeleteMaterialList): Promise<DeleteMaterialListResponse> {
  const url = `${Config.Lists.deleteMaterialList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteMaterialListResponse>(url);
  return result?.data;
}

export const epicDeleteMaterialList = (action$: Observable<MaterialTypeActions>) => action$.pipe(
  filter(deleteMaterialListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteMaterialList) => from(DeleteMaterialList(data)).pipe(
    mergeMap((res: DeleteMaterialListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteMaterialList(), getMaterialListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteMaterialList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteMaterialListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteMaterialListResponse>) => of(failureDeleteMaterialList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
