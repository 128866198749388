/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddStripChartComponentPropTypes } from './utils/type';
import { AddStripChartEnum, AddStripChartFields } from '../utils/type';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { getDefectDropdownStart } from '../redux/DefectRedux/sliceVesselDefect';
import { RootState } from '../../../redux/rootState';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function AddStripChartComponent({ show, setOpenFrom, handleSave }: AddStripChartComponentPropTypes): JSX.Element {
  const { control, handleSubmit, formState: { errors } } = useForm<AddStripChartFields>();
  const { allDefectDropdownData } = useSelector((state: RootState) => state.VesselDefectReducer);

  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();

  const onSubmit = (data: AddStripChartFields) => {
    if (handleSave) {
      handleSave(data);
    }
  };

  useEffect(() => {
    const payload = {
      param: 'abc', // ---- Temp  ------->>>>>>
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getDefectDropdownStart(payload));
  }, []);

  const filteredSections = allDefectDropdownData?.Section?.filter((section) => section.Text !== 'All');

  const handleCancel = () => {
    setOpenFrom(false);
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleCancel()}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Specify section
          <IconButton
            aria-label="close"
            onClick={() => handleCancel()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_Customer">
                    Section
                  </label>
                  <ComponentSelectInput
                    control={control}
                    name={AddStripChartEnum.section}
                    size="small"
                    errors={errors}
                    entries={filteredSections || []}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={() => handleCancel()}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddStripChartComponent;
