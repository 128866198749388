import React, {
  useMemo, useState, useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel } from '../../media/images/Cancel';
// import { LocalizationContext } from '../Localization/LocalizationProvider';
import './Style.scss';
import DataTable from '../DataTable';
import { TypesHotelSearchModal } from './Type';
import { RootState } from '../../redux/rootState';
import { getHotelList } from '../../Screens/JobScheduling/UnscheduledJobs/addJob/redux/jobScheduleSlice';
import { formatHotelListData } from '../../Screens/JobScheduling/UnscheduledJobs/utils/helper';
import { AccommodationTableData } from '../../Screens/JobScheduling/UnscheduledJobs/utils/types';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  // height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  height: 'auto',
  '@media (max-width: 768px)': {
    p: '6px 6px 15px',
    height: 'auto',
  },
};
export default function HotelListingModal({
  show, setOpenFrom, selectedRadio, setSelectedRadio, onSelect,
}: TypesHotelSearchModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { hotelListData } = useSelector((state: RootState) => state.jobScheduleReducer);
  const dispatch = useDispatch();

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getHotelList(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns = useMemo(
    () => [
      { key: 'sn', label: 'SN' },
      { key: 'city', label: 'City' },
      { key: 'state', label: 'State' },
      { key: 'motel', label: 'Motel' },
      { key: 'job', label: 'Job#' },
      { key: 'phone', label: 'Phone' },
      { key: 'dateIn', label: 'Date In' },
      { key: 'price', label: 'Price' },
    ],
    [],
  );

  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">
              Hotels
            </div>
            <div className="cancel-icon" onClick={setOpenFrom}>
              <Cancel />
            </div>
          </div>
          <DataTable<AccommodationTableData>
            data={formatHotelListData(hotelListData?.Data)}
            columns={columns}
            totalRecords={hotelListData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            radioButtonColumns={['sn']}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
          />
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button onClick={setOpenFrom} className="button_cancel">Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  onClick={onSelect}
                  disabled={selectedRadio === ''}
                  className={selectedRadio === '' ? ' disableBtn' : 'button_save_and_next'}
                >
                  Select
                </Button>
              </div>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
