/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { InsertTimeComponentPropTypes } from './utils/type';
import { CommonDateInput } from '../../../Common/CommonDateInput';
import { InsertTimeDefaultData, InsertTimeFields } from '../utils/constant';
import { TimeDateFormInfo } from '../utils/defectType';
import { insertTimeSchema } from '../utils/validation';
import { addInsertStart } from '../redux/DefectRedux/sliceVesselDefect';
import RequiredFiled from '../../../Common/RequiredField';
import Common24HTimeInput from '../../../Common/Common24HTimePicker';
import { RootState } from '../../../redux/rootState';
import { convertDateFormatTo } from '../../../utils/CommonFunctions';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function InsertTimeComponent({
  show, setOpenFrom, callback, rowId, startTime, endTime,
}: InsertTimeComponentPropTypes): JSX.Element {
  const {
    control, handleSubmit, formState: { errors },
  } = useForm<TimeDateFormInfo>({
    resolver: yupResolver(insertTimeSchema),
    defaultValues: InsertTimeDefaultData as unknown as TimeDateFormInfo,
  });
  const { vesselDefectList } = useSelector((state: RootState) => state.VesselDefectReducer);

  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();

  const [defectId, setDefectId] = useState('');

  const disableStartDate = useMemo(() => dayjs(convertDateFormatTo(startTime?.Date || ''), 'MM/DD/YYYY'), [startTime?.Date]);
  const disableStartTime = useMemo(() => dayjs(startTime?.Time || '', 'hh-mm-ss'), [startTime?.Time]);

  const disableEndDate = useMemo(() => dayjs(convertDateFormatTo(endTime?.Date || ''), 'MM/DD/YYYY'), [endTime?.Date]);
  const disableEndTime = useMemo(() => dayjs(endTime?.Time || '', 'hh-mm-ss'), [endTime?.Time]);

  const handleSave = (values: TimeDateFormInfo) => {
    const payLoad = {
      JobOrder: jobOrderId || '',
      VesselId: vesselId || '',
      DefectId: defectId,
      CalibrationTime: `${dayjs(values.date).format('DD-MM-YYYY')}/${dayjs(values.time).format('HH:mm:ss')}`,
    };
    dispatch(addInsertStart({ payLoad, callback, setOpenFrom }));
  };

  useEffect(() => {
    const data = vesselDefectList.filter((item) => String(item.Id) === rowId);
    setDefectId(data[0]?.DefectId);
  }, [rowId]);

  return (
    <div>
      <Dialog
        maxWidth="sm"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle className="modalHeaderTitle">
          Insert Time
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <form onSubmit={handleSubmit(handleSave)}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Date
                    <RequiredFiled />
                  </label>
                  <CommonDateInput
                    minDate={disableStartDate}
                    maxDate={disableEndDate}
                    control={control}
                    errors={errors}
                    name={InsertTimeFields.date}
                  />
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">
                    Time
                    <RequiredFiled />
                  </label>
                  <Common24HTimeInput
                    control={control}
                    errors={errors}
                    minTime={disableStartTime}
                    maxTime={disableEndTime}
                    name={InsertTimeFields.time}
                  />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel" onClick={setOpenFrom}>Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button type="submit" className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default InsertTimeComponent;
