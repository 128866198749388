import {
  Grid, Card,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/rootState';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import './StyleMainTab.scss';
import { AnalystForm, InterfaceAnalystInfo } from '../../utils/types';
import RequiredFiled from '../../../../../utils/RequiredField';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import { getTeritoryData } from '../../../AnalystListScreen/redux/AnalystListSlice';
import { getMasterDataStart } from '../../../../../redux/masters/masterSlice';
import { analystPinCodeDetailsStart, removeAnalystPinCodeDetails } from '../../redux/AddAnalystSlice';
import { ANALYST_INFO_KEY } from '../../utils/constants';
import { RHFAutocompleteField } from '../../../../../Common/ComponentAutoSelect';
import { CommonDateInput } from '../../../../../Common/CommonDateInput';

export default function AnalystInfo({
  control, errors, setValue, watch,
}: InterfaceAnalystInfo): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const {
    phoneCodes, countries, salutations, citySuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const { teritoryData } = useSelector((state: RootState) => state.AnalystListSliceReducer);
  const { analystPinCodeDetails, isPinError } = useSelector((state: RootState) => state.AddAnalystSliceReducer);
  const dispatch = useDispatch();

  const zipCode = watch(AnalystForm.zip);
  const cCode = watch(AnalystForm.country);
  useEffect(() => {
    if (zipCode && zipCode?.length >= 5) {
      setValue(AnalystForm.analystState, '');
      const queryPayload = {
        pinCode: zipCode,
        countryCode: cCode,
        key: ANALYST_INFO_KEY,
      };
      dispatch(analystPinCodeDetailsStart(queryPayload));
    } else if (zipCode?.length < 5) {
      dispatch(removeAnalystPinCodeDetails());
      setValue(AnalystForm.analystState, '');
    }
  }, [zipCode, cCode]);
  useEffect(() => {
    if (analystPinCodeDetails?.City) {
      setValue(AnalystForm.analystState, analystPinCodeDetails.StateName);
    }
  }, [analystPinCodeDetails]);

  useEffect(() => {
    dispatch(getTeritoryData());
    dispatch(getMasterDataStart());
    dispatch(removeAnalystPinCodeDetails());
  }, []);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(AnalystForm.analystCity);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={1} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="no_label_Style" />
              <ComponentSelectInput
                name={AnalystForm.sirName}
                control={control}
                size="small"
                entries={salutations}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations.firstName}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={AnalystForm.firstName}
                id={AnalystForm.firstName}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.lastName}</label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={AnalystForm.lastName}
                id={AnalystForm.lastName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.address}</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 200 }}
                name={AnalystForm.address}
                className="nonCapitalizeField"
                id={AnalystForm.address}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.country}</label>
              <ComponentSelectInput
                name={AnalystForm.country}
                handleChange={() => setValue(AnalystForm.zip, '')}
                control={control}
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.zip}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                inputProps={{ maxLength: 10 }}
                control={control}
                regEx={/^[0-9]+$/}
                required
                name={AnalystForm.zip}
                id={AnalystForm.zip}
                errors={errors}
                size="small"
              />
            </div>
            {isPinError ? (<span className="errorMessage">Invalid zip code</span>) : ''}
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.state}</label>
              <ComponentTextInput
                control={control}
                isDisable
                name={AnalystForm.analystState}
                id={AnalystForm.analystState}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.city}</label>
              {renderCityInput() ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  name={AnalystForm.otherAnalystCity}
                />
              )
                : (
                  <ComponentSelectInput
                    name={AnalystForm.analystCity}
                    defaultValue=""
                    control={control}
                    size="small"
                    entries={analystPinCodeDetails?.City ? [...analystPinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>

          <Grid item md={1} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.code}</label>
              <ComponentSelectInput
                name={AnalystForm.code}
                control={control}
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.phone}</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 15 }}
                name={AnalystForm.phone}
                regEx={/^[0-9()\-.]+$/}
                id={AnalystForm.phone}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.cell}</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 15 }}
                name={AnalystForm.cell}
                regEx={/^[0-9()\-.]+$/}
                id={AnalystForm.cell}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.email}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                className="nonCapitalizeField"
                inputProps={{ maxLength: 255 }}
                name={AnalystForm.email}
                id={AnalystForm.email}
                errors={errors}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.initials}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                capitalizeFirst
                errors={errors}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 3 }}
                name={AnalystForm.initials}
                id={AnalystForm.initials}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">{translations?.teritory}</label>
              <ComponentSelectInput
                name={AnalystForm.territory}
                control={control}
                size="small"
                entries={teritoryData}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.hireDate}
              </label>
              <CommonDateInput
                control={control}
                errors={errors}
                name={AnalystForm.hireDate}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.lsInitials}
              </label>
              <ComponentTextInput
                control={control}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 3 }}
                name={AnalystForm.lsInitials}
                id={AnalystForm.lsInitials}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
