/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog, DialogContent, DialogTitle, Grid, IconButton, Zoom,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../../Common/DataTable';
import { AddAnalystModalPropTypes, AnalystDataInterface } from '../utils/type';
import { AnalystInterface } from '../redux/type';
import { zoneApiMethod } from '../utils/constant';
import { getFilteredAnalystData, onCreateZone } from '../redux/sliceBoardPosition';
import { RootState } from '../../../redux/rootState';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function AddAnalystModal({
  show, setOpenFrom, selectedZone, handleZonePageData,
}: AddAnalystModalPropTypes): JSX.Element {
  const dispatch = useDispatch();
  const [data, setData] = useState<AnalystDataInterface[]>([]);
  const { FilteredAnalystData } = useSelector((state: RootState) => state.boardPositionReducer);

  const disableSelectButton = useMemo(() => {
    const isSelected = data?.filter((ele) => ele?.isChecked);
    return isSelected?.length > 0;
  }, [data]);

  const handleCallback = () => {
    setOpenFrom();
    handleZonePageData();
  };

  const onSelect = () => {
    const selectedAnalystArray: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        selectedAnalystArray.push(element?.id);
      }
    });
    const payload = {
      ZoneId: selectedZone,
      AnalystIds: selectedAnalystArray,
    };
    dispatch(onCreateZone({ type: zoneApiMethod.SELECT, payload, callback: handleCallback }));
  };

  const handleData = () => {
    dispatch(getFilteredAnalystData());
  };

  const analystDataColumn = useMemo(() => [
    { key: 'sn', label: '#' },
    { key: 'analystName', label: 'Analyst Name' },
  ], []);

  const formatData = (analystData: AnalystInterface[]) => {
    const formattedData = analystData?.map((ele, index) => ({
      sn: index,
      analystName: ele?.AnalystName,
      isChecked: false,
      id: ele?.AnalystId,
    }));
    return formattedData;
  };

  const handleCheckBoxWithKey = (analystId: string) => {
    const updatedData = data?.map((element: AnalystDataInterface) => {
      if (element?.id === analystId) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    if (FilteredAnalystData?.Data) {
      setData(formatData(FilteredAnalystData?.Data));
    }
  }, [FilteredAnalystData?.Data]);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="modalHeaderTitle">
          Select Analyst
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              <DataTable<AnalystDataInterface>
                data={data?.length > 0 ? data : []}
                columns={analystDataColumn}
                totalRecords={FilteredAnalystData?.TotalRecords}
                handleData={handleData}
                checkBoxWithKey={['sn']}
                handleCheckBoxWithKey={handleCheckBoxWithKey}
                statusColumnsWithKey={['sn']}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div style={{ marginRight: '20px' }}>
                  <Button onClick={setOpenFrom} className="button_cancel">Cancel</Button>
                </div>
                <div>
                  <Button
                    disabled={!disableSelectButton}
                    className="button_save_and_next"
                    onClick={onSelect}
                  >
                    Select
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddAnalystModal;
