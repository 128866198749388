export const tabStyles: React.CSSProperties = {
  backgroundColor: 'white',
  color: '#878A99',
  fontSize: '14px',
  fontWeight: '500',
  textTransform: 'capitalize',
  border: '1px solid #CED4DA',
  borderRadius: '4px',
  marginRight: '8px',
};

export const selectedTabStyles: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: '500',
  backgroundColor: '#00A751',
  color: 'white',
  textTransform: 'capitalize',
  borderRadius: '4px',
  border: '1px solid #00A751',
  marginRight: '8px',
};

export const tabsStyles: React.CSSProperties = {
  backgroundColor: 'transparent',
};

export const stylesTabIndicator = { style: { backgroundColor: 'red', display: 'none' } };
