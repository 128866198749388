import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, ForgotTypes } from '../utils/types';

const initialState: AuthState = {
  forgotPasswordData: {},
  isLoading: false,
  otpSent: false,
  isError: false,
  errorMessage: '',
};

export const authSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    sendForgotPasswordData(state, action: PayloadAction<ForgotTypes>) {
      return {
        ...state,
        isLoading: true,
        forgotPasswordData: action.payload,
      };
    },
    forgotPasswordFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    },
    sendOtpVerifyData(state) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
    OtpDataSendSuccess(state) {
      return {
        ...state,
        isLoading: false,
        otpSent: true,
      };
    },
    OtpDataSendFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        otpSent: false,
        errorMessage: action.payload,
      };
    },
    OtpResetState(state) {
      return {
        ...state,
        otpSent: false,
        errorMessage: '',
        isError: false,
      };
    },
    errorMessageState(state) {
      return {
        ...state,
        errorMessage: '',
        isError: false,
      };
    },
  },
});

export const {
  sendForgotPasswordData, forgotPasswordFailure, sendOtpVerifyData, OtpDataSendSuccess, OtpDataSendFailure,
  OtpResetState, errorMessageState,
} = authSlice.actions;
export const forgotPasswordReducer = authSlice.reducer;

export type ForgotPasswordActions =
  | ReturnType<typeof sendForgotPasswordData>
  | ReturnType<typeof sendOtpVerifyData>
  | ReturnType<typeof OtpDataSendSuccess>
  | ReturnType<typeof OtpDataSendFailure>
  | ReturnType<typeof OtpResetState>
  | ReturnType<typeof errorMessageState>
  | ReturnType<typeof forgotPasswordFailure>;
