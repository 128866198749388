import {
  Button, Grid, IconButton, InputAdornment, TextField, Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { red } from '@mui/material/colors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import taiLogo from '../../../assets/tailogo.png';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import EndPoints from '../../../Routes/EndPoints';
import { resetValidationSchema } from '../../../utils/Schema/ValidationSchema';
import ComponentLeftVector from '../../../Common/LeftVector/ComponentLeftVector';
import { FormTypes } from './utils/types';
import { initialValuesReset } from './utils/constantsReset';
import StorageUtils from '../../../utils/StorageUtils';
import constants from '../../../utils/SessionConstants';
import { resetStates, sendResetPasswordData } from './redux/sliceReset';
import { RootState } from '../../../redux/rootState';

function ScreenReset() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const EmailId = StorageUtils.getString(constants.EMAIL_ID);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    isLoading, isSuccess, isError, errorMessage,
  } = useSelector((state: RootState) => state.resetPasswordReducer);

  const handleChangeEmail = () => {
    navigate(EndPoints.SCREEN_FORGOT_PASSWORD);
    dispatch(resetStates());
  };

  const endAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
      >
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const onSubmit = (values: FormTypes) => {
    const data = { Email: EmailId, OTP: values.code, NewPassword: values.password };
    dispatch(sendResetPasswordData(data));
  };

  useEffect(() => {
    if (isSuccess) {
      StorageUtils.flush();
      navigate(EndPoints.SCREEN_SUCCESS);
      dispatch(resetStates());
    }
  }, [isSuccess]);

  const { register, handleSubmit, formState: { errors } } = useForm<FormTypes>({
    defaultValues: initialValuesReset,
    resolver: yupResolver(resetValidationSchema(translations)),
  });

  return (
    <div className="div-password">
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <ComponentLeftVector />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <div className="mobStyle">
            <div className="rightContainer">
              <img src={taiLogo} alt="TAI Logo" className="taiLogo" />
              <div className="loginContainer">
                <h2>{translations.resetAccount}</h2>
                <div className="divContainer">
                  <Typography variant="body1" color="textSecondary">{translations.verificationCode}</Typography>
                  <div className="div_button_change">
                    <Typography variant="body1">{EmailId}</Typography>
                    <Button className="changeButton" variant="text" onClick={handleChangeEmail}>{translations.change}</Button>
                  </div>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="mt-26">
                    <div className="div_label_Email">
                      <label htmlFor="code" className="labelStyle">{translations.enterCode}</label>
                    </div>
                    <TextField
                      fullWidth
                      size="small"
                      className="text_field_margin nonCapitalizeField"
                      type="text"
                      inputProps={{ maxLength: 6 }}
                      placeholder="Enter Verification Code"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('code')}
                      error={!!errors.code}
                      helperText={errors.code?.message}
                    />
                  </div>
                  <div className="div_Create_Password">
                    <div style={{ textAlign: 'left' }}>
                      <label className="labelStyle">{translations.createPassword}</label>
                    </div>
                    <TextField
                      fullWidth
                      size="small"
                      className="text_field_margin nonCapitalizeField"
                      id="password"
                      placeholder="Create Password"
                      type={showPassword ? 'text' : 'password'}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('password')}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      inputProps={{ maxLength: 16 }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        endAdornment,
                      }}
                    />
                  </div>
                  <div className="errorMessage">{isError ? errorMessage : <div />}</div>
                  <div className="div_pass_typo">
                    <Typography variant="body2" color="textSecondary">{translations.passwordMustHave}</Typography>
                  </div>
                  <div className="div_require_typo">
                    <Typography variant="body2" color={red[500]}>
                      {translations.atLeast}
                    </Typography>
                  </div>
                  <LoadingButton variant="contained" loading={isLoading} fullWidth className="button-color" type="submit">
                    {translations.submit}
                  </LoadingButton>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenReset;
