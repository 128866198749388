import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import CommonTableWithMultipleColumn from './commonTableWithMultipleColumn';
import CommonTableWithTwoColumn from './commonTableWithTwoColumn';
import { RootState } from '../../../redux/rootState';

function VesselInfoSection() {
  const { reportPdfResponse1 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const vesselData = useMemo(() => {
    const res = [];
    const len = reportPdfResponse1?.Vessel_Information?.length;
    for (let i = 0; i < len;) {
      res.push(reportPdfResponse1?.Vessel_Information?.slice(i, i + 4));
      i += 4;
    }

    return res;
  }, [reportPdfResponse1]);

  return (
    <div className="p30">
      {vesselData?.map((data, index) => (
        <React.Fragment key={`${data[0]?.Material}-${index + 1}`}>
          <table className="w100">
            <tr>
              <td className={`${index > 0 ? 'textLeft pt30 pb10' : 'textLeft'}`}>
                Part I - Vessel Information
              </td>
              <td className={`${index > 0 ? 'textRight pt30 pb10' : 'textRight'}`}>
                S/N
                {' '}
                {reportPdfResponse1?.JobOrderData?.SerialNumber}
              </td>
            </tr>
            {index === 0 && (
            <tr>
              <td colSpan={2} className="textCenter underlineHeadingText">
                Vessel Information
              </td>
            </tr>
            )}
            <tr>
              <td colSpan={2} className="w100 pb30">
                <CommonTableWithMultipleColumn />
              </td>
            </tr>
            <tr>
              <td className={`${data.length > index ? 'pb30' : ''}`} colSpan={2}>
                <div className="vessel_info_data_box">
                  {data?.map((item, ind) => (
                    <React.Fragment key={`${item.Material}-${ind + 1}`}>
                      <CommonTableWithTwoColumn data={item} />
                    </React.Fragment>
                  ))}
                </div>
              </td>
            </tr>
          </table>
          {/* {data.length > index
          && <div className="page-break" />} */}
        </React.Fragment>
      ))}

      <div className="page-footer">
        <div className="textCenter">
          Analyst:
          {reportPdfResponse1?.JobOrderData?.AnalystName}
        </div>
        <div className="textCenter">* Nominal Wall Thickness</div>
      </div>
    </div>
  );
}

export default VesselInfoSection;
