import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitialStateUserManagement, UserManagementListDataInterface } from './type';

const initialState: InitialStateUserManagement = {
  isLoading: false,
  userManagementListData: {} as UserManagementListDataInterface,
  userCreated: false,
  activateDeactivateUser: false,
};
const userManagementSlice = createSlice({
  name: 'userManagementSlice',
  initialState,
  reducers: {
    onGetUserManagementData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onGotUserManagementData(state, action: PayloadAction<UserManagementListDataInterface>) {
      return {
        ...state,
        isLoading: false,
        userManagementListData: action.payload,
      };
    },
    onFailUserManagementData(state) {
      return {
        ...state,
        isLoading: false,
        userManagementListData: {} as UserManagementListDataInterface,
      };
    },
    onCreateUser(state, _action) {
      return {
        ...state,
        userCreated: false,
      };
    },
    onCreatedUserSuccess(state) {
      return {
        ...state,
        userCreated: true,
      };
    },
    onCreateUserFailure(state) {
      return {
        ...state,
        userCreated: false,
      };
    },
    onActivateDeactivateUser(state, _action) {
      return {
        ...state,
        activateDeactivateUser: false,
      };
    },
    onActivateDeactivateUserSuccess(state) {
      return {
        ...state,
        activateDeactivateUser: true,
      };
    },
    onActivateDeactivateUserFailure(state) {
      return {
        ...state,
        activateDeactivateUser: false,
      };
    },
  },
});

export const {
  onGetUserManagementData, onGotUserManagementData, onFailUserManagementData, onCreateUser,
  onCreatedUserSuccess, onCreateUserFailure, onActivateDeactivateUser, onActivateDeactivateUserSuccess,
  onActivateDeactivateUserFailure,
} = userManagementSlice.actions;
export const userManagementReducer = userManagementSlice.reducer;

export type UserManagementActions =
    | ReturnType<typeof onGetUserManagementData>
    | ReturnType<typeof onGotUserManagementData>
    | ReturnType<typeof onFailUserManagementData>
    | ReturnType<typeof onCreateUser>
    | ReturnType<typeof onCreatedUserSuccess>
    | ReturnType<typeof onCreateUserFailure>
    | ReturnType<typeof onActivateDeactivateUser>
    | ReturnType<typeof onActivateDeactivateUserSuccess>
    | ReturnType<typeof onActivateDeactivateUserFailure>;
