import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import { RootState } from '../../../../redux/rootState';
import {
  deleteContactStart, getContactByIdStart, getContactsStart, removeSelectedContact,
} from '../../redux/addCustomerSlice';
import { ContactColumns } from '../../utils/data';
import { formatTableData } from '../../utils/helper';
import { ContactsData, GetContactByIdPayload } from '../../utils/types';
import AddContactModal from './Components/AddContactModal';

export default function CustomerContacts() {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();
  const {
    contactList, contactTotalEntries, customerId, isLoading,
  } = useSelector((state:RootState) => state.addCustomerReducer);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [mode, setMode] = useState('Add Contact');

  const handleData = async () => {
    const payload = {
      page,
      rowsPerPage,
      searchQuery,
      customerId,
    };

    dispatch(getContactsStart(payload));
  };

  const handleClose = () => {
    setShow(false);
    dispatch(removeSelectedContact());
  };

  const handleButtonClick = () => {
    setMode('Add Contact');
    setShow(true);
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleEdit = (contactId:string) => {
    setMode('Edit Contact');
    dispatch(getContactByIdStart(contactId));
    setShow(true);
  };
  const handleDelete = (id:string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };

  const handleDeleteModal = () => {
    const payload:GetContactByIdPayload = {
      customerId,
      contactId: selectedId,
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(deleteContactStart(payload));
    setDeleteModal(false);
  };

  return (
    <>
      <DataTable<ContactsData>
        handleButtonClick={handleButtonClick}
        data={contactList ? formatTableData(contactList) : []}
        columns={ContactColumns}
        totalRecords={contactTotalEntries}
        buttonText="Add Contact"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        checkBoxColumns={['er', 'or']}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      <AddContactModal
        show={show && !isLoading}
        handleClose={handleClose}
        mode={mode}
        page={page}
        rowsPerPage={rowsPerPage}
        searchQuery={searchQuery}
      />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </>

  );
}
