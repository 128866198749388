export const TableColumns = [
  { key: 'sn', label: '#' },
  { key: 'action', label: 'Action' },
  { key: 'cust', label: 'Cust #' },
  { key: 'company', label: 'Company' },
  { key: 'markUp', label: 'Mark Up' },
  { key: 'address', label: 'Address' },
  { key: 'city', label: 'City' },
  { key: 'state', label: 'State' },
  { key: 'phone', label: 'Phone' },
  { key: 'smc', label: 'SMC' },
];
