/* eslint-disable linebreak-style */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Card, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import edit_icon from '../../../assets/edit_icon.svg';
import { MainDefaultValue, ProfileFormValue } from '../Utils/ConstantProfile';
import { ProfileFormInput } from '../Utils/TypeProfile';
import '../Style/StyleEditProfile.scss';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import {
  getUserProfileStart, resetState, updateProfileImage, updateUserProfile,
} from '../Redux/sliceMyProfile';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { phoneRegex } from '../../../utils/regex';
import { editProfileValidationSchema } from '../Utils/ValidationSchemaProfile';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import DialogProfileImage from './DialogProfileImage';
import ProfileImage from '../../../assets/Images/ProfileImage/ProfileImage.png';

export default function ProfileDetails(): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [editProfile, setEditProfile] = useState(true);
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem(localStorageConstants.USER_ID);
  const { salutations, phoneCodes } = useSelector((state: RootState) => state.masterDataReducer);
  const dispatch = useDispatch();
  const {
    userProfile, isLoading, isSuccess, isImageSuccess,
  } = useSelector((state: RootState) => state.userProfileReducer);

  const {
    handleSubmit, control,
    formState: { errors },
    setValue,
  } = useForm<ProfileFormInput>({
    defaultValues: MainDefaultValue,
    resolver: yupResolver(editProfileValidationSchema(translations)),
  });
  useEffect(() => {
    if (userProfile) {
      [
        { name: ProfileFormValue.FIRST_NAME, value: userProfile.FirstName },
        { name: ProfileFormValue.LAST_NAME, value: userProfile.LastName },
        { name: ProfileFormValue.EMAIL, value: userProfile.Email },
        { name: ProfileFormValue.ADDRESS, value: userProfile.Address },
        { name: ProfileFormValue.CODE, value: userProfile.Code },
        { name: ProfileFormValue.PHONE, value: userProfile.Phone },
        { name: ProfileFormValue.CELL, value: userProfile.Cell },
        { name: ProfileFormValue.TITLE, value: userProfile.Salutation },
        { name: ProfileFormValue.INITIALS, value: userProfile.Initials },
      ].forEach(({ name, value }) => setValue(name, value));
    }
  }, [userProfile]);
  const onSubmit = (values: ProfileFormInput) => {
    const data = {
      Salutation: values.title,
      userid: userId as string,
      FirstName: values.firstName,
      LastName: values.lastName,
      Code: values.code,
      Phone: values.phone,
      Cell: values.cell,
      Address: values.address,
      Initials: values.initials,
    };
    dispatch(updateUserProfile(data));
  };

  useEffect(() => {
    dispatch(getUserProfileStart());
  }, []);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        const fileSizeInBytes = file.size;
        const maxSizeInBytes = 1 * 1024 * 1024; // 1mb
        const allowedFormats = ['image/jpeg', 'image/png'];
        if (fileSizeInBytes > maxSizeInBytes) {
          showErrorToaster('File size exceeds the maximum limit 1 MB');
        } else if (!allowedFormats.includes(file.type)) {
          showErrorToaster('Only JPEG and PNG formats are allowed');
        } else {
          const formData = new FormData();
          formData.append('ImageFile', event.target.files[0]);
          formData.append('Type', 'Profile');
          dispatch(updateProfileImage(formData));
        }
      }
    }
  };
  useEffect(() => {
    if (isImageSuccess) {
      showSuccessToaster('Profile image successfully updated');
      dispatch(getUserProfileStart());
      dispatch(resetState());
    }
  }, [isImageSuccess]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(getUserProfileStart());
      dispatch(resetState());
      setEditProfile(true);
      showSuccessToaster('Profile successfully updated');
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleCancel = () => {
    setEditProfile(true);
    dispatch(getUserProfileStart());
  };
  const handleAction = () => {
    setOpen(!open);
  };
  return (
    <>
      <Grid container spacing={2} className="profile_scroll">
        <Grid item xs={12} sm={12} md={12} lg={4} className="profile_pic_sec">
          <div className="main_parent">
            <div className="top_green" />
            {editProfile ? (
              <div className="bottom-white">
                <div className="profile_name_sec">
                  <div className="user_name">
                    {`${userProfile?.FirstName ? userProfile.FirstName : ''} ${userProfile?.LastName ? userProfile?.LastName : ''}`}
                  </div>
                  <div className="user_email">{userProfile?.Email || ''}</div>
                  <div className="user_contact_no">{`${userProfile?.Phone ? userProfile.Code : ''} ${userProfile?.Phone || ''}`}</div>
                </div>
                <div className="profile_image" onClick={handleAction}>
                  {
                    userProfile?.ImageUrl?.length
                      ? <img src={userProfile?.ImageUrl} alt="Profile Pic" />
                      : <img src={ProfileImage} alt="Profile Pic" />
                  }
                </div>
              </div>
            ) : (
              <div className="bottom-white">
                <br />
                <Button
                  variant="contained"
                  component="label"
                  className="button_save_and_next"
                >
                  Upload Image
                  <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                </Button>
                <div className="profile_image" onClick={handleAction}>
                  {
                    userProfile?.ImageUrl?.length
                      ? <img src={userProfile?.ImageUrl} alt="Profile Pic" />
                      : <img src={ProfileImage} alt="Profile Pic" />
                  }
                </div>
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} className="profile_form_sec">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card className="edit_card">
              <div className="customerInfo_container flex-row">
                {editProfile ? (
                  <div className="customerInfo">
                    Profile Details
                  </div>
                ) : (
                  <div className="customerInfo">
                    Edit Profile
                  </div>
                )}
                <div className="edit_btn_sec">
                  {editProfile ? (
                    <button type="button" onClick={() => setEditProfile(false)}>
                      <img src={edit_icon} alt="Edit Profile" title="Edit Profile" />
                    </button>
                  ) : (<button type="button" onClick={() => setEditProfile(true)}>Back To Profile</button>)}
                </div>
              </div>
              <div className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item md={1.5} sm={6} xs={12}>
                    <label className="label_Style_job">&nbsp;</label>
                    <ComponentSelectInput
                      name={ProfileFormValue.TITLE}
                      control={control}
                      disabled={editProfile && true}
                      size="small"
                      entries={salutations}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.firstName}</label>
                    <ComponentTextInput
                      control={control}
                      isDisable={editProfile && true}
                      name={ProfileFormValue.FIRST_NAME}
                      size="small"
                      inputProps={{ maxLength: 25 }}
                      id={ProfileFormValue.FIRST_NAME}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={4.5} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.lastName}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.LAST_NAME}
                      isDisable={editProfile && true}
                      control={control}
                      inputProps={{ maxLength: 25 }}
                      size="small"
                      id={ProfileFormValue.LAST_NAME}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={1.5} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.initials}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.INITIALS}
                      isDisable={editProfile && true}
                      control={control}
                      inputProps={{ maxLength: 3 }}
                      size="small"
                      id={ProfileFormValue.INITIALS}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.emailId}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.EMAIL}
                      isDisable
                      control={control}
                      size="small"
                      errors={errors}
                      id={ProfileFormValue.EMAIL}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.address}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.ADDRESS}
                      isDisable={editProfile && true}
                      control={control}
                      inputProps={{ maxLength: 255 }}
                      size="small"
                      id={ProfileFormValue.ADDRESS}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={1.5} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.code}</label>
                    <ComponentSelectInput
                      name={ProfileFormValue.CODE}
                      control={control}
                      disabled={editProfile && true}
                      size="small"
                      entries={phoneCodes}
                      className="width-90"
                    />
                  </Grid>
                  <Grid item md={5.25} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.phone}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.PHONE}
                      isDisable={editProfile && true}
                      control={control}
                      inputProps={{ maxLength: 20 }}
                      size="small"
                      regEx={phoneRegex}
                      id={ProfileFormValue.PHONE}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item md={5.25} sm={6} xs={12}>
                    <label className="label_Style_Customer">{translations.cell}</label>
                    <ComponentTextInput
                      name={ProfileFormValue.CELL}
                      isDisable={editProfile && true}
                      control={control}
                      regEx={phoneRegex}
                      inputProps={{ maxLength: 15 }}
                      size="small"
                      id={ProfileFormValue.CELL}
                      errors={errors}
                    />
                  </Grid>
                  {!editProfile && (
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="save_and_next_div">
                        <Button className="button_cancel" type="button" onClick={handleCancel}>Cancel</Button>
                        <div className="button_margin_left">
                          <Button
                            variant="contained"
                            className="button_save_and_next"
                            type="submit"
                          >
                            {translations.saveChange}
                          </Button>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Card>
          </form>
        </Grid>
      </Grid>
      <DialogProfileImage show={open} setOpenFrom={handleAction} />
    </>
  );
}
