import React from 'react';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import ComponentTable from '../../ComponentTable/ComponentTable';

export default function ScreenJobDetails() {
  return (
    <>
      <ComponentPageHeader subHeading="MODIFY JOB" />
      <div className="div_container_CustomerInfo div_job_container_info">
        <ComponentTable buttonText={null} />
      </div>
    </>
  );
}
