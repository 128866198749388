import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  loader: false,
};

export const loaderSlice = createSlice({
  name: 'TAILoader',
  initialState,
  reducers: {
    setLoader(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loader: action.payload,
      };
    },
    // hideLoader(state) {
    //   return {
    //     ...state,
    //     loader: false,
    //   };
    // },
  },
});

export const { setLoader } = loaderSlice.actions;
export const loaderReducer = loaderSlice.reducer;

export type LoaderActions =
      | ReturnType<typeof setLoader>;
//   | ReturnType<typeof hideLoader>;
