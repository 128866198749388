import React from 'react';
import { Grid } from '@mui/material';
import ComponentEditSite from './ComponentEditSite';
import ComponentSiteCustomersTable from './ComponentSiteCustomersTable';

function ScreenAddSite() {
  return (
    <div className="div_sites_container">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <ComponentEditSite />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <ComponentSiteCustomersTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenAddSite;
