import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { PhotoData } from './utils/types';
import ComponentPhotoModel from './components/ComponentPhotoModel';
import { getPhotosData } from './redux/photosSlice';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import EndPoints from '../../Routes/EndPoints';

function ScreenPhoto() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobOrder } = useParams();

  const { photosListData, isLoading } = useSelector((state: RootState) => state.photosReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getPhotosData(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (jobOrder) {
      setSearchQuery(jobOrder);
    } else setSearchQuery('');
  }, [jobOrder]);

  const data: PhotoData[] = useMemo(() => photosListData?.Data?.map((item, index) => {
    const transformedDataItem = {
      jobOrder: item?.JobOrder,
      vessel: item?.VesselName,
      photoFile: item?.FileName,
      action: 'edit&eye',
      sn: index,
      id: `${item.JobOrder}/${String(item.Id)}`,
    };
    return transformedDataItem;
  }), [photosListData]);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'jobOrder', label: 'Job Order #' },
      { key: 'vessel', label: 'Vessel' },
      { key: 'photoFile', label: 'Photo File' },
    ],
    [],
  );

  const handleAction = (id: string) => {
    const ids = id.split('/');
    setOpen(true);
    setRowId(ids[1]);
  };

  const handleViews = (id: string) => {
    navigate(`/${EndPoints.PHOTOS}/${id}`);
    setRowId(id);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="Photo maintenance" />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable<PhotoData>
            data={data}
            columns={columns}
            totalRecords={photosListData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleEdit={handleAction}
            handleViews={handleViews}
          />
        </Grid>
      </Grid>
      {open && (
      <ComponentPhotoModel
        show={open}
        setOpenFrom={() => setOpen(false)}
        rowId={rowId}
        page={page}
        rowsPerPage={rowsPerPage}
        searchQuery={searchQuery}
      />
      )}
    </>
  );
}

export default ScreenPhoto;
