import { SafetyChecklist } from './helper';
import { HazardAnalysisDataById, TypesHazardAnalysisValues } from './types';

export const tempData = {
  Data: [
    { id: '1', jobOrder: 'JobA' },
    { id: '2', jobOrder: 'JobB' },
    { id: '3', jobOrder: 'JobC' },
  ],
  TotalRecords: 3,
};

export function getRequestPayload(data: TypesHazardAnalysisValues, hazardAnalysisById: HazardAnalysisDataById) {
  return {
    RecordId: hazardAnalysisById.RecordId,
    SiteName: hazardAnalysisById.SiteName,

    JobOrder: hazardAnalysisById.JobOrder,
    Outside: data[SafetyChecklist.Outside],
    FireResistantCloths: data[SafetyChecklist.FireResistantCloths],
    HardHat: data[SafetyChecklist.HardHat],
    NoContactLenses: data[SafetyChecklist.NoContactLenses],
    SafteyGlases: data[SafetyChecklist.SafetyGlasses],
    Gloves: data[SafetyChecklist.Gloves],
    Harness: data[SafetyChecklist.Harness],
    RubberGloves: data[SafetyChecklist.RubberGloves],
    HearingProtection: data[SafetyChecklist.HearingProtection],
    LockoutTag: data[SafetyChecklist.LockoutTag],
    SteelToedBoots: data[SafetyChecklist.SteelToedBoots],
    SafteyClassVideo: data[SafetyChecklist.SafetyClassVideo],
    Resporator: data[SafetyChecklist.Respirator],

    TightSpace: data[SafetyChecklist.TightSpaceLess24in],
    ConfirmedSpace: data[SafetyChecklist.ConfirmedSpace],
    DeepWaterBox: data[SafetyChecklist.DeepWaterBox],

    VerticalBundleTopDown: data[SafetyChecklist.VerticalBundleTopDown],
    VerticalBundleBottomUp: data[SafetyChecklist.VerticalBundleBottomUp],

    OfficeBuilding: data[SafetyChecklist.OfficeBuilding],
    School: data[SafetyChecklist.School],
    Factory: data[SafetyChecklist.Factory],
    Hospital: data[SafetyChecklist.Hospital],
    TextileMill: data[SafetyChecklist.TextileMill],
    Ship: data[SafetyChecklist.Ship],
    ChemicalPlant: data[SafetyChecklist.ChemicalPlant],
    EthanolPlant: data[SafetyChecklist.EthanolPlant],
    FossilPower: data[SafetyChecklist.FossilPower],
    NukePower: data[SafetyChecklist.NukePower],

    DoubleLanyard: data[SafetyChecklist.DoubleLanyard],
    RubberBoots: data[SafetyChecklist.RubberBoots],
    FaceShield: data[SafetyChecklist.FaceShield],
    Other: data[SafetyChecklist.OtherExplainInComments],
    Comment: data[SafetyChecklist.Comments],
  };
}
