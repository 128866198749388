import { InputAdornment, TextField } from '@mui/material';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowUpDownIcon, ShuffleIcon,
} from '../../../../assets/TableIcons';
import { UnitData, UpdateExcelPayloadAction } from '../../utils/types';
import { RootState } from '../../../../redux/rootState';
import { getAllUnitSerialNumberStart, setUnitData, updateExcelDataUnit } from '../../redux/oilReportSlice';
import { GetListingPayload } from '../../../../utils/type';
import { ModalPagination } from '../pagination';
import NoData from '../../../../assets/NoData.svg';

export function ReplaceUnit() {
  const {
    selectedUnitSerialNumber,
    allUnitSerialNumbers, excelData, totalUnitSerialNumbers,
  } = useSelector((state:RootState) => state.oilReportReducer);
  const dispatch = useDispatch();

  const [unitPage, setUnitPage] = useState(1);
  const [unitRowsPerPage, setUnitRowsPerPage] = useState(10);
  const [unitSearchQuery, setUnitSearchQuery] = useState('');

  const handleUnitData = () => {
    const payload:GetListingPayload = {
      page: unitPage,
      rowsPerPage: unitRowsPerPage,
      searchQuery: unitSearchQuery,
    };
    dispatch(getAllUnitSerialNumberStart(payload));
  };

  // eslint-disable-next-line max-len
  const selectedUnit:string = useMemo(() => excelData.find((data) => data.ReportId === selectedUnitSerialNumber)?.UnitSerialNumber || '', [selectedUnitSerialNumber]);

  const handleReplaceUnit = (value: string) => {
    const ReplacedValue = excelData.find((data) => data.ReportId === selectedUnitSerialNumber)?.UnitSerialNumber || '';
    const obj:UnitData = {
      ReportId: selectedUnitSerialNumber,
      Value: value,
      ReplacedValue,
    };

    const updateExcelPayload:UpdateExcelPayloadAction = {
      unitSerialNumber: value,
      isReplaced: true,
    };

    dispatch(setUnitData(obj));
    dispatch(updateExcelDataUnit(updateExcelPayload));
  };

  const handleSearchChange = (event:ChangeEvent<HTMLInputElement>) => {
    setUnitSearchQuery(event.target.value);
    const payload:GetListingPayload = {
      page: unitPage,
      rowsPerPage: unitRowsPerPage,
      searchQuery: event.target.value,
    };
    dispatch(getAllUnitSerialNumberStart(payload));
  };

  return (
    <div className="CustomTableContainer CustomTableBorderedContainer">
      <div className="CustomSearchTable">
        <TextField
          className="subHeaderTextfield"
          size="small"
          placeholder="Search..."
          value={unitSearchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div className="oilReportTableContainer">
        <div className="oilReportTableHead">
          <div className="headerTableCell wd-5">
            SN
            {' '}
            <ArrowUpDownIcon />
          </div>
          <div className="headerTableCell wd-45">
            Selected Unit
            {' '}
            <ArrowUpDownIcon />
          </div>
          <div className="headerTableCell wd-45">
            Units
            {' '}
            <ArrowUpDownIcon />
          </div>
        </div>
        {totalUnitSerialNumbers > 0 ? (
          <div className="oilReportTableBody">
            <div className="oilReportTableList">
              <div className="oilReportTableCell wd-5">1</div>
              <div className="oilReportTableCell wd-45">{selectedUnit}</div>
              <div className="oilReportTableCell wd-45">
                <div>{allUnitSerialNumbers?.[0]?.UnitSerialNumber}</div>
                <div
                  className="actionBtn"
                  onClick={() => handleReplaceUnit(allUnitSerialNumbers?.[0]?.UnitSerialNumber)}
                >
                  <ShuffleIcon />
                </div>
              </div>
            </div>
            {allUnitSerialNumbers?.slice(1)?.map((entry) => (
              <div className="oilReportTableList" key={entry.ReportId}>
                <div className="oilReportTableCell noBorder wd-5">&nbsp;</div>
                <div className="oilReportTableCell noBorder wd-45">&nbsp;</div>
                <div className="oilReportTableCell wd-45">
                  <div>{entry.UnitSerialNumber}</div>
                  <div
                    className="actionBtn"
                    onClick={() => handleReplaceUnit(entry.UnitSerialNumber)}
                  >
                    <ShuffleIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="CustomTableContainer CustomTableBorderedContainer allCentered">
            <div className=""><img src={NoData} alt="No data found" /></div>
          </div>
        )}
      </div>
      <ModalPagination
        rowsPerPage={unitRowsPerPage}
        page={unitPage}
        setRowsPerPage={setUnitRowsPerPage}
        setPage={setUnitPage}
        totalEntries={totalUnitSerialNumbers}
        handleData={handleUnitData}
      />
    </div>
  );
}
