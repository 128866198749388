import { toast } from 'react-toastify';

export function showSuccessToaster(msg: string) {
  toast.success(msg, {
    autoClose: 3000,
  });
}

export function showErrorToaster(msg: string) {
  toast.error(msg);
}

export function showWarnToaster(msg: string) {
  toast.warn(msg, {
    className: 'custom-toast',
    position: toast.POSITION.TOP_CENTER,
  });
}
