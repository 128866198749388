/* eslint-disable max-len */
import { ofType } from 'redux-observable';
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';

import {
  AddAnalystBody, AddAnalystLeaveMasterResponseFormat, AddAnalystLeavePayload, AddAnalystLeaveResponseFormat, AddAnalystPayload,
  AddAnalystPayloadData,
  AddAnalystResponseFormat, AnalystLeaveDataApiResponse, AnalystLeaveDataPayload, DeleteAnalystLeavePayload, DeleteAnalystLeaveResponseFormat, GetByIdAnalystResponseFormat,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest,
} from '../../../../Common/NetworkOps';
import {
  addAnalystLeave,
  AddAnalystSliceActions, analystBaseOutPinCodeDetailsFailure, analystBaseOutPinCodeDetailsStart, analystBaseOutPinCodeDetailsSuccess, analystPinCodeDetailsFailure, analystPinCodeDetailsStart, analystPinCodeDetailsSuccess, analystSecondaryBasePinCodeDetailsFailure, analystSecondaryBasePinCodeDetailsStart, analystSecondaryBasePinCodeDetailsSuccess, createAnalyst, createAnalystFailure,
  createAnalystSuccess, deleteAnalystLeave, deleteAnalystLeaveSuccess, getAnalystDataById, getAnalystDataByIdFailure,
  getAnalystDataByIdSuccess, getAnalystLeaveData, getAnalystLeaveDataFailure, getAnalystLeaveDataSuccess, getAnalystLeaveMasterData, getAnalystLeaveMasterDataSuccess, updateAnalyst, updateAnalystFailure,
  updateAnalystSuccess,
} from './AddAnalystSlice';
import EndPoints from '../../../../Routes/EndPoints';
import { addLeaveType } from '../utils/constants';
import { PinCodeApiResponse, PinCodeDetailsQuery } from '../../../../utils/type';

async function addAnalystListData(data: AddAnalystBody): Promise<AddAnalystResponseFormat> {
  const url = `${Config.analyst.createAnalyst}`;
  const result = await makePostRequest<AddAnalystResponseFormat>(url, data);
  return result.data;
}

export const addAnalystDataEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(createAnalyst.type),
  map((x) => x.payload),
  mergeMap((data: AddAnalystPayload) => from(addAnalystListData(data?.reqBody)).pipe(
    map((res: AddAnalystResponseFormat) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        data?.navigate(`/${EndPoints.ANALYST}`);
        return createAnalystSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      data?.navigate(`/${EndPoints.ANALYST}`);
      return createAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(createAnalyst.match))),
    catchError((error) => of(createAnalystFailure(error))),
  )),
);

async function updateAnalystListData(data: AddAnalystBody): Promise<AddAnalystResponseFormat> {
  const url = `${Config.analyst.updateAnalyst}`;
  const result = await makePutRequest<AddAnalystResponseFormat>(url, data);
  return result.data;
}

export const updateAnalystDataEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(updateAnalyst.type),
  map((x) => x.payload),
  mergeMap((data: AddAnalystPayload) => from(updateAnalystListData(data?.reqBody)).pipe(
    map((res: AddAnalystResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        data?.navigate(`/${EndPoints.ANALYST}`);
        return updateAnalystSuccess();
      }
      showErrorToaster(res.BMT.ResponseMessage);
      data?.navigate(`/${EndPoints.ANALYST}`);
      return updateAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(updateAnalyst.match))),
    catchError((error) => of(updateAnalystFailure(error))),
  )),
);

async function getAnalystDataByIdata(data: string): Promise<GetByIdAnalystResponseFormat> {
  const url = `${Config.analyst.analystById}?AnalystId=${data}`;
  const result = await makeGetRequest<GetByIdAnalystResponseFormat>(url);
  return result.data;
}

export const getAnalystDataByIDEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(getAnalystDataById.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(getAnalystDataByIdata(data)).pipe(
    map((res: GetByIdAnalystResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAnalystDataByIdSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystDataByIdFailure();
    }),
    takeUntil(action$.pipe(filter(getAnalystDataById.match))),
    catchError((error) => of(getAnalystDataByIdFailure(error))),
  )),
);

// GET ANALYST LEAVE DATA
async function getAnalystLeaveDataApiCall(data: AnalystLeaveDataPayload): Promise<AnalystLeaveDataApiResponse> {
  const url = `${Config.analyst.getAnalystLeavesList}?AnalystId=${data?.analystId}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<AnalystLeaveDataApiResponse>(url);
  return result.data;
}

export const getAnalystLeaveDataEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(getAnalystLeaveData.type),
  map((x) => x.payload),
  debounceTime(250),
  mergeMap((data: AnalystLeaveDataPayload) => from(getAnalystLeaveDataApiCall(data)).pipe(
    map((res: AnalystLeaveDataApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAnalystLeaveDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystLeaveDataFailure(false);
    }),
    takeUntil(action$.pipe(filter(getAnalystLeaveData.match))),
    catchError((error) => of(showErrorToaster(error?.response?.data?.BMT.ResponseMessage), getAnalystLeaveDataFailure(false))),
  )),
);

// DELETE ANALYST LEAVE
async function deleteAnalystLeaveApiCall(data: string): Promise<DeleteAnalystLeaveResponseFormat> {
  const url = `${Config.analyst.deleteAnalystLeave}?Id=${data}`;
  const result = await makeDeleteRequest<DeleteAnalystLeaveResponseFormat>(url);
  return result.data;
}

export const deleteAnalystLeaveEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(deleteAnalystLeave.type),
  map((x) => x.payload),
  mergeMap(({ selectedId, callback }: DeleteAnalystLeavePayload) => from(deleteAnalystLeaveApiCall(selectedId)).pipe(
    map((res: DeleteAnalystLeaveResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        callback();
        showSuccessToaster(res.BMT.ResponseMessage);
        return deleteAnalystLeaveSuccess(true);
      }
      callback();
      showErrorToaster(res.BMT.ResponseMessage);
      return deleteAnalystLeaveSuccess(false);
    }),
    takeUntil(action$.pipe(filter(deleteAnalystLeave.match))),
    catchError((error) => of(showErrorToaster(error?.response?.Data?.BMT.ResponseMessage), deleteAnalystLeaveSuccess(false))),
  )),
);

// ADD ANALYST LEAVE
async function addAnalystLeaveApiCall(data: AddAnalystPayloadData, type: number): Promise<AddAnalystLeaveResponseFormat> {
  if (type === addLeaveType.BALANCE_LEAVE_ADD_UPDATE) {
    const url = `${Config.analyst.addAnalystBalanceLeave}`;
    const result = await makePostRequest<AddAnalystLeaveResponseFormat>(url, data);
    return result.data;
  }
  const url = `${Config.analyst.addUpdateAnalystLeave}`;
  const result = await makePostRequest<AddAnalystLeaveResponseFormat>(url, data);
  return result.data;
}

export const addAnalystLeaveEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(addAnalystLeave.type),
  map((x) => x.payload),
  mergeMap(({ payload, type, callback }: AddAnalystLeavePayload) => from(addAnalystLeaveApiCall(payload, type)).pipe(
    map((res: AddAnalystLeaveResponseFormat) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        if (type === addLeaveType.MAIN_LEAVE_ADD_UPDATE) {
          showSuccessToaster(res.BMT.ResponseMessage);
          callback();
          return deleteAnalystLeaveSuccess(false);
        }
        if (type === addLeaveType.BALANCE_LEAVE_ADD_UPDATE) {
          showSuccessToaster(res.BMT.ResponseMessage);
          callback();
          return deleteAnalystLeaveSuccess(false);
        }
      }
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        if (type === addLeaveType.MAIN_LEAVE_ADD_UPDATE) {
          showSuccessToaster(res.BMT.ResponseMessage);
          callback();
          return deleteAnalystLeaveSuccess(false);
        }
        if (type === addLeaveType.BALANCE_LEAVE_ADD_UPDATE) {
          showSuccessToaster(res.BMT.ResponseMessage);
          callback();
          return deleteAnalystLeaveSuccess(false);
        }
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return createAnalystFailure();
    }),
    takeUntil(action$.pipe(filter(addAnalystLeave.match))),
    catchError((error: AxiosError<AddAnalystLeaveResponseFormat>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(createAnalystFailure());
    }),
  )),
);

// GET ANALYST MASTER LEAVE DATA
async function getAnalystMasterLeaveDataApiCall(): Promise<AddAnalystLeaveMasterResponseFormat> {
  const url = Config.analyst.msteventslist;
  const result = await makeGetRequest<AddAnalystLeaveMasterResponseFormat>(url);
  return result.data;
}

export const getAnalystMasterLeaveDataEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(getAnalystLeaveMasterData.type),
  map((x) => x.payload),
  mergeMap(() => from(getAnalystMasterLeaveDataApiCall()).pipe(
    map((res: AddAnalystLeaveMasterResponseFormat) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getAnalystLeaveMasterDataSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return getAnalystLeaveDataFailure(false);
    }),
    takeUntil(action$.pipe(filter(getAnalystLeaveMasterData.match))),
    catchError((error: AxiosError<AddAnalystLeaveResponseFormat>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(createAnalystFailure());
    }),
  )),
);

async function getPinCodeAnalystDetails(data: PinCodeDetailsQuery): Promise<PinCodeApiResponse> {
  const url = `${Config.auth.getPincodeDetails}?PinCode=${data.pinCode}&CountryCode=${data.countryCode}`;
  const result = await makeGetRequest<PinCodeApiResponse>(url);
  return result.data;
}

export const getAnalystPinCodeEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(analystPinCodeDetailsStart.type),
  debounceTime(500),
  map((x) => x.payload),
  mergeMap((data: PinCodeDetailsQuery) => from(getPinCodeAnalystDetails(data)).pipe(
    map((res: PinCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return analystPinCodeDetailsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return analystPinCodeDetailsFailure();
    }),
    takeUntil(action$.pipe(filter(analystPinCodeDetailsStart.match))),
    catchError((error) => of(analystPinCodeDetailsFailure(error))),
  )),
);

async function getPinCodeBaseOut(data: PinCodeDetailsQuery): Promise<PinCodeApiResponse> {
  const url = `${Config.auth.getPincodeDetails}?PinCode=${data.pinCode}&CountryCode=${data.countryCode}`;
  const result = await makeGetRequest<PinCodeApiResponse>(url);
  return result.data;
}

export const getAnalystBasePinCodeEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(analystBaseOutPinCodeDetailsStart.type),
  debounceTime(500),
  map((x) => x.payload),
  mergeMap((data: PinCodeDetailsQuery) => from(getPinCodeBaseOut(data)).pipe(
    map((res: PinCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return analystBaseOutPinCodeDetailsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return analystBaseOutPinCodeDetailsFailure();
    }),
    takeUntil(action$.pipe(filter(analystBaseOutPinCodeDetailsStart.match))),
    catchError((error) => of(analystBaseOutPinCodeDetailsFailure(error))),
  )),
);

async function getPinCodeSecondaryBaseOut(data: PinCodeDetailsQuery): Promise<PinCodeApiResponse> {
  const url = `${Config.auth.getPincodeDetails}?PinCode=${data.pinCode}&CountryCode=${data.countryCode}`;
  const result = await makeGetRequest<PinCodeApiResponse>(url);
  return result.data;
}

export const getAnalystSecondaryBasePinCodeEpic = (action$: Observable<AddAnalystSliceActions>) => action$.pipe(
  ofType(analystSecondaryBasePinCodeDetailsStart.type),
  debounceTime(500),
  map((x) => x.payload),
  mergeMap((data: PinCodeDetailsQuery) => from(getPinCodeSecondaryBaseOut(data)).pipe(
    map((res: PinCodeApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return analystSecondaryBasePinCodeDetailsSuccess(res.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return analystSecondaryBasePinCodeDetailsFailure();
    }),
    takeUntil(action$.pipe(filter(analystSecondaryBasePinCodeDetailsStart.match))),
    catchError((error) => of(analystSecondaryBasePinCodeDetailsFailure(error))),
  )),
);
