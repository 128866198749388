import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddEquipmentCategoryListData, EquipmentCategoryListPayloadListing, PayloadTypeDeleteEquipmentCategoryList,
  PayloadTypeGetEquipmentCategoryList, EquipmentCategoryListState, UpdateEquipmentCategoryListData,
} from '../utils/types';

const initialState: EquipmentCategoryListState = {
  EquipmentCategoryListData: {} as EquipmentCategoryListPayloadListing,
  isLoading: false,
  error: '',
  isSuccess: false,
};

const equipmentCategoryListSlice = createSlice({
  name: 'equipmentCategoryList',
  initialState,
  reducers: {
    getEquipmentCategoryListStart(state, _action: PayloadAction<PayloadTypeGetEquipmentCategoryList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successEquipmentCategoryList(state, action: PayloadAction<EquipmentCategoryListPayloadListing>) {
      return {
        ...state,
        isLoading: false,
        EquipmentCategoryListData: action?.payload as EquipmentCategoryListPayloadListing,
      };
    },
    failureEquipmentCategoryList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    updateEquipmentCategoryList(state, _action: PayloadAction<UpdateEquipmentCategoryListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateEquipmentCategoryList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureUpdateEquipmentCategoryList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    resetState(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: '',
      };
    },

    addEquipmentCategoryListStart(state, _action: PayloadAction<AddEquipmentCategoryListData>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successAddEquipmentCategoryList(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureAddEquipmentCategoryList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },

    deleteEquipmentCategoryListStart(state, _action: PayloadAction<PayloadTypeDeleteEquipmentCategoryList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successDeleteEquipmentCategoryList(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteEquipmentCategoryList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
  },

});

export const {
  getEquipmentCategoryListStart, successEquipmentCategoryList, failureEquipmentCategoryList, updateEquipmentCategoryList,
  successUpdateEquipmentCategoryList, failureUpdateEquipmentCategoryList, resetState, deleteEquipmentCategoryListStart,
  successDeleteEquipmentCategoryList, failureDeleteEquipmentCategoryList,
  addEquipmentCategoryListStart, successAddEquipmentCategoryList, failureAddEquipmentCategoryList,
} = equipmentCategoryListSlice.actions;
export const ListEquipmentCategoryReducer = equipmentCategoryListSlice.reducer;

export type EquipmentCategoryTypeActions =
  | ReturnType<typeof getEquipmentCategoryListStart>
  | ReturnType<typeof successEquipmentCategoryList>
  | ReturnType<typeof failureEquipmentCategoryList>

  | ReturnType<typeof updateEquipmentCategoryList>
  | ReturnType<typeof successUpdateEquipmentCategoryList>
  | ReturnType<typeof resetState>
  | ReturnType<typeof failureUpdateEquipmentCategoryList>

  | ReturnType<typeof deleteEquipmentCategoryListStart>
  | ReturnType<typeof successDeleteEquipmentCategoryList>
  | ReturnType<typeof failureDeleteEquipmentCategoryList>

  | ReturnType<typeof addEquipmentCategoryListStart>
  | ReturnType<typeof successAddEquipmentCategoryList>
  | ReturnType<typeof failureAddEquipmentCategoryList>;
