import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { EquipmentRequestTableColumns, REQUEST } from '../utils/constants';
import EndPoints from '../../../Routes/EndPoints';
import {
  getEquipmentRequestDataStart, onDeleteEquipmentByAdmin, setEquipmentRequestTab,
} from '../TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { setLoader } from '../../../redux/loaderSlice';
import { formatEquipmentRequestListData } from '../utils/helper';
import { RootState } from '../../../redux/rootState';

export default function EquipmentRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');

  const { isLoading, equipmentRequestData } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setSelectedId(id);
  };
  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getEquipmentRequestDataStart(payload));
  };

  const handleDeleteModal = () => {
    dispatch(onDeleteEquipmentByAdmin({ selectedId, callback: handleData }));
    setDeleteModal(false);
  };

  const handleEdit = (id: string) => {
    dispatch(setEquipmentRequestTab(REQUEST));
    const roleValue = equipmentRequestData?.Data?.find((element) => element?.RequestId === id);
    navigate(`${EndPoints.TEST_EQUIPMENT_REQUEST_FORM}/${id}/${roleValue?.RoleId}`);
  };

  useEffect(() => {
    handleData();
  }, []);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: equipmentRequestData?.Data?.length > 0 ? equipmentRequestData?.Data : [],
      },
      'EQUIPMENT REQUEST',
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="EQUIPMENT REQUEST" downloadExcel={downloadExcel} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
          <DataTable
            data={equipmentRequestData?.Data?.length > 0 ? formatEquipmentRequestListData(equipmentRequestData?.Data) : []}
            columns={EquipmentRequestTableColumns}
            totalRecords={equipmentRequestData?.TotalRecords}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            handleDelete={handleDelete}
            buttonText="Add"
            handleButtonClick={() => navigate(EndPoints.TEST_EQUIPMENT_REQUEST_FORM)}
            handleEdit={handleEdit}
          />
        </Grid>
      </Grid>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />

    </>
  );
}
