import React, { forwardRef } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Button, Checkbox, Dialog, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Select, TextField, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TypesDialogBox } from '../../../../../../utils/interface';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Zoom ref={ref} {...props} />);

function DialogBoxAddContact({ show, setOpenFrom }: TypesDialogBox): JSX.Element {
  return (
    <div>
      <Dialog
        maxWidth="lg"
        PaperProps={{
          className: 'paperStyle',
        }}
        open={show}
        transitionDuration={500}
        TransitionComponent={Transition}
        keepMounted
        onClose={setOpenFrom}
      >
        <DialogTitle className="dialogTitle_style">
          <div className="customerInfo">Add Contact</div>
          <IconButton
            aria-label="close"
            onClick={setOpenFrom}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialogContent_style">
          <div className="dialogContent_style_div">
            <Grid container spacing={2}>
              <Grid item md={1} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">&nbsp;</label>
                  <Select
                    size="small"
                    fullWidth
                    value={1}
                  >
                    <MenuItem value={1}>Mr.</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={5} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">First Name</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Last Name</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Email ID</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Office</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Address</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Address (Optional)</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Zip</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">State</label>
                  <Select
                    size="small"
                    fullWidth
                    value={1}
                  >
                    <MenuItem value={1}>Uttar Pradesh</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">City</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Country</label>
                  <Select
                    size="small"
                    fullWidth
                    value={1}
                  >
                    <MenuItem value={1}>INDIA</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={1} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Code</label>
                  <Select
                    size="small"
                    fullWidth
                    value={1}
                  >
                    <MenuItem value={1}>+1</MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Phone</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={1} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Ext</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Cell</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="checkBox-style">
                  <div className="checkbox_container_style">
                    <div className="customerID">
                      Receive Monthly Report
                    </div>
                    <Checkbox defaultChecked color="success" />
                  </div>
                  <div className="checkbox_container_style">
                    <div className="customerID">
                      ECT Report (in PDF)
                    </div>
                    <Checkbox defaultChecked color="success" />
                  </div>
                  <div className="checkbox_container_style">
                    <div className="customerID">
                      Oil Report (in PDF)
                    </div>
                    <Checkbox defaultChecked color="success" />
                  </div>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="save_and_next_div">
                  <div>
                    <Button className="button_cancel">Cancel</Button>
                  </div>
                  <div className="button_margin_left">
                    <Button className="button_save_and_next">Save</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogBoxAddContact;
