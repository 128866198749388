import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from 'draft-js';
import { CalibrationProcedureApiData, CalibrationProcedureSliceState } from './Type';

const initialState: CalibrationProcedureSliceState = {
  isLoading: false,
  calibrationProcedureData: [],
  error: '',
  selectedTab: 1,
  eddyCurrentDescription: EditorState.createEmpty(),
  fluxDescription: EditorState.createEmpty(),
  absDiffDescription: EditorState.createEmpty(),
  rfecDescription: EditorState.createEmpty(),
  inspectionProcedureDescription: EditorState.createEmpty(),
};

const CalibrationProcedureSlice = createSlice({
  name: 'calibrationProcedureSlice',
  initialState,
  reducers: {
    setEddyCurrentDescription(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        eddyCurrentDescription: action.payload,
      };
    },
    setFluxDescription(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        fluxDescription: action.payload,
      };
    },
    setAbsDiffDescription(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        absDiffDescription: action.payload,
      };
    },
    setRfecDescription(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        rfecDescription: action.payload,
      };
    },
    setInspectionProcedureDescription(state, action: PayloadAction<EditorState>) {
      return {
        ...state,
        inspectionProcedureDescription: action.payload,
      };
    },
    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    getCalibrationProcedureData(state, _action) {
      return {
        ...state,
        isLoading: true,
      };
    },
    onSuccessCalibrationProcedureData(state, action: PayloadAction<CalibrationProcedureApiData[]>) {
      return {
        ...state,
        isLoading: false,
        calibrationProcedureData: action?.payload,
      };
    },
    onFailureCalibrationProcedureData(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    createCalibrationProcedure(state, _action) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getCalibrationProcedureData, onSuccessCalibrationProcedureData, onFailureCalibrationProcedureData,
  createCalibrationProcedure, setSelectedTab, setEddyCurrentDescription, setFluxDescription, setAbsDiffDescription,
  setRfecDescription, setInspectionProcedureDescription,
} = CalibrationProcedureSlice.actions;
export const CalibrationProcedureReducer = CalibrationProcedureSlice.reducer;

export type CalibrationProcedureActions =
  | ReturnType<typeof getCalibrationProcedureData>
  | ReturnType<typeof onSuccessCalibrationProcedureData>
  | ReturnType<typeof createCalibrationProcedure>
  | ReturnType<typeof onFailureCalibrationProcedureData>
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof setEddyCurrentDescription>
  | ReturnType<typeof setFluxDescription>
  | ReturnType<typeof setAbsDiffDescription>
  | ReturnType<typeof setRfecDescription>
  | ReturnType<typeof setInspectionProcedureDescription>;
