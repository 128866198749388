import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardInitialState, GetDashboardPayload, GetDashboardResult } from '../utils/TypeDashboard';
import { ALL_TYPE, DEFAULT_FROM_DATE, DEFAULT_TO_DATE } from '../utils/Constant';

const initialState: DashboardInitialState = {
  isLoading: true,
  dashboardData: {} as GetDashboardResult,
  errorMessage: '',
  type: ALL_TYPE,
  fromDate: DEFAULT_FROM_DATE,
  toDate: DEFAULT_TO_DATE,
  queryParameter: {} as GetDashboardPayload,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    resetDashboardState() {
      return initialState;
    },
    getDashboardData(state, action: PayloadAction<GetDashboardPayload>) {
      return {
        ...state,
        isLoading: true,
        queryParameter: action.payload,
      };
    },
    getDashboardDataSuccess(state, action: PayloadAction<GetDashboardResult>) {
      return {
        ...state,
        isLoading: false,
        dashboardData: action.payload,
      };
    },
    setQueryParameters(state, action: PayloadAction<GetDashboardPayload>) {
      return {
        ...state,
        isLoading: false,
        type: action.payload.type,
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate,
      };
    },
    getDashboardDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
  },
});

export const {
  getDashboardData, getDashboardDataSuccess, getDashboardDataFailure, setQueryParameters,
  resetDashboardState,
} = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;

export type DashBoardActions =
  | ReturnType<typeof getDashboardData>
  | ReturnType<typeof getDashboardDataSuccess>
  | ReturnType<typeof setQueryParameters>
  | ReturnType<typeof resetDashboardState>
  | ReturnType<typeof getDashboardDataFailure>;
