import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangePasswordData } from '../utils/ChangePassword';

interface ChangePasswordState {
  ChangePassword: ChangePasswordData[];
  isLoading: boolean;
  error: string;
  isSuccess: boolean;
}

const initialState: ChangePasswordState = {
  ChangePassword: [] as ChangePasswordData[],
  isLoading: false,
  error: '',
  isSuccess: false,
};

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {

    changePasswordStart(state, action: PayloadAction<ChangePasswordData>) {
      return {
        ...state,
        isLoading: true,
        ChangePasswordData: action.payload,
      };
    },
    successChangePassword(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    failureChangePassword(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    setChangePasswordModal(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isSuccess: action.payload,
      };
    },
  },
});

export const {
  changePasswordStart, successChangePassword, failureChangePassword, setChangePasswordModal,
} = changePasswordSlice.actions;
export const ChangePasswordReducer = changePasswordSlice.reducer;

export type ChangePasswordActions =
  | ReturnType<typeof changePasswordStart>
  | ReturnType<typeof successChangePassword>
  | ReturnType<typeof failureChangePassword>
  | ReturnType<typeof setChangePasswordModal>;
