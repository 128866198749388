import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  BayLengthFormDetails, BayLengthInitialValues, BaylengthPayload, BaylengthResponse,
} from '../../utils/type';

const initialState:BayLengthInitialValues = {
  baylengthData: {} as BaylengthResponse,
  isLoading: false,
  bayLengthDetails: {
    bayLengthSupport: '',
    bayLengthTubes: '',
    spacingValues: [],
  },
  isBayLengthChanged: false,
  jobId: '',
  vesselId: '',
};

const BayLengthSlice = createSlice({
  name: 'bayLengthSlice',
  initialState,
  reducers: {
    setIsBayLengthChanged(state, action:PayloadAction<boolean>) {
      return {
        ...state,
        isBayLengthChanged: action.payload,
      };
    },

    setBayLengthDetails(state, action:PayloadAction<BayLengthFormDetails>) {
      return {
        ...state,
        bayLengthDetails: action.payload,
      };
    },

    getBayLengthByIdStart(state, action:PayloadAction<BaylengthPayload>) {
      return {
        ...state,
        isLoading: true,
        jobId: action.payload.jobId,
        vesselId: action.payload.vesselId,
      };
    },
    getBayLengthByIdSuccess(state, action:PayloadAction<BaylengthResponse>) {
      return {
        ...state,
        isLoading: false,
        baylengthData: action.payload,
        bayLengthDetails: {
          bayLengthSupport: action.payload.Support,
          bayLengthTubes: action.payload.TubeLength,
          spacingValues: action.payload.Configuration,
        },
        isBayLengthChanged: false,
      };
    },
    getBayLengthByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateBayLengthStart(state, _action:PayloadAction<BaylengthResponse>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateBayLengthSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateBayLengthFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getBayLengthByIdStart, getBayLengthByIdFailure, getBayLengthByIdSuccess,
  updateBayLengthFailure, updateBayLengthStart, updateBayLengthSuccess, setBayLengthDetails,
  setIsBayLengthChanged,
} = BayLengthSlice.actions;

export const BayLengthReducer = BayLengthSlice.reducer;

export type BayLengthActions =
    | ReturnType<typeof getBayLengthByIdFailure>
    | ReturnType<typeof getBayLengthByIdStart>
    | ReturnType<typeof getBayLengthByIdSuccess>
    | ReturnType<typeof updateBayLengthFailure>
    | ReturnType<typeof updateBayLengthStart>
    | ReturnType<typeof updateBayLengthSuccess>
    | ReturnType<typeof setBayLengthDetails>
    | ReturnType<typeof setIsBayLengthChanged>;
