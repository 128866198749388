import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddManufacturerListApiResponse, AddManufacturerListData, DeleteManufacturerListResponse, GetAllManufacturerListApiResponse,
  PayloadTypeDeleteManufacturerList, PayloadTypeGetManufacturerList, UpdateManufacturerListApiResponse, UpdateManufacturerListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addManufacturerListStart, deleteManufacturerListStart, failureAddManufacturerList, failureDeleteManufacturerList, failureManufacturerList,
  failureUpdateManufacturerList, getManufacturerListStart,
  ManufacturerTypeActions, successAddManufacturerList, successDeleteManufacturerList, successManufacturerList,
  successUpdateManufacturerList, updateManufacturerList,
} from './sliceManufacturerList';

async function GetManufacturerList(data: PayloadTypeGetManufacturerList): Promise<GetAllManufacturerListApiResponse> {
  const url = `${Config.Lists.getManufacturerList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllManufacturerListApiResponse>(url);
  return result?.data;
}

export const epicGetManufacturerList = (action$: Observable<ManufacturerTypeActions>) => action$.pipe(
  filter(getManufacturerListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetManufacturerList) => from(GetManufacturerList(data)).pipe(
    map((res: GetAllManufacturerListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successManufacturerList(res?.BMT?.Result);
      }
      return failureManufacturerList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getManufacturerListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<GetAllManufacturerListApiResponse>) => of(failureManufacturerList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateManufacturerList(data: UpdateManufacturerListData): Promise<UpdateManufacturerListApiResponse> {
  const url = `${Config.Lists.updateManufacturerList}`;
  const result = await makePostRequest<UpdateManufacturerListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateManufacturerList = (action$: Observable<ManufacturerTypeActions>) => action$.pipe(
  filter(updateManufacturerList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateManufacturerList(data)).pipe(
    mergeMap((res: UpdateManufacturerListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateManufacturerList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateManufacturerList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateManufacturerList.match))),
    catchError((error: AxiosError<UpdateManufacturerListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateManufacturerList(errorMessage));
    }),
  )),
);

async function AddManufacturerList(data: AddManufacturerListData): Promise<AddManufacturerListApiResponse> {
  const url = `${Config.Lists.addManufacturerList}`;
  const result = await makePostRequest<AddManufacturerListApiResponse>(url, data);
  return result?.data;
}

export const epicAddManufacturerList = (action$: Observable<ManufacturerTypeActions>) => action$.pipe(
  filter(addManufacturerListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddManufacturerList(data)).pipe(
    mergeMap((res: AddManufacturerListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddManufacturerList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddManufacturerList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addManufacturerListStart.match))),
    catchError((error: AxiosError<AddManufacturerListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddManufacturerList(errorMessage));
    }),
  )),
);

async function DeleteManufacturerList(data: PayloadTypeDeleteManufacturerList): Promise<DeleteManufacturerListResponse> {
  const url = `${Config.Lists.deleteManufacturerList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteManufacturerListResponse>(url);
  return result?.data;
}

export const epicDeleteManufacturerList = (action$: Observable<ManufacturerTypeActions>) => action$.pipe(
  filter(deleteManufacturerListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteManufacturerList) => from(DeleteManufacturerList(data)).pipe(
    mergeMap((res: DeleteManufacturerListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteManufacturerList(), getManufacturerListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteManufacturerList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteManufacturerListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteManufacturerListResponse>) => of(failureDeleteManufacturerList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
