import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import { ComponentFreonCylinderTable } from './Components/ComponentFreonCylinderTable';
import { RootState } from '../../../../redux/rootState';
import { exportToExcelData } from '../../../../Common/PageHeader/helper';

function ScreenFreonCylinder() {
  const { freonList } = useSelector((state: RootState) => state.FreonListReducer);

  const excelData = useMemo(() => freonList?.map((item) => {
    const transformedDataItems = {
      NumberOfKit: item.NumberOfKit,
      CustomerName: item.CustomerName,
      PurchaseOrderNumber: item.PurchaseOrderNumber,
      ShipDate: item.ShipDate,
      BillAmount: `$ ${item.BillAmount}`,
      Billed: item.Billed ? 'Yes' : 'No',
      ShipVia: item.ShipVia,
      CreatedBy: item.CreatedBy,
    };
    return transformedDataItems;
  }), [freonList]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Freon_Cylinder_list',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Freon Cylinders" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentFreonCylinderTable />
      </Grid>
    </Grid>
  );
}

export default ScreenFreonCylinder;
