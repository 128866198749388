import React from 'react';
import { useSelector } from 'react-redux';
import NoData from '../../../../assets/NoData.svg';
import { ReplaceUnit } from './replaceUnit';
import { RootState } from '../../../../redux/rootState';
import { ReplaceCompartment } from './replaceCompartment';

export function SelectedUnitComp() {
  const {
    selectedUnitSerialNumber, selectedCompartment,
  } = useSelector((state:RootState) => state.oilReportReducer);

  if (selectedUnitSerialNumber) {
    return (
      <div className="col col-md-6">
        <ReplaceUnit />
      </div>
    );
  }

  if (selectedCompartment) {
    return (
      <div className="col col-md-6">
        <ReplaceCompartment />
      </div>
    );
  }

  return (
    <div className="col col-md-6">
      <div className="CustomTableContainer CustomTableBorderedContainer allCentered">
        <div className=""><img src={NoData} alt="No data found" /></div>
      </div>
    </div>
  );
}
