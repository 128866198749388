import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import { ExpenseReportItem } from '../../types';

function ComponentExpenseMainTable() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  const expenseMainData = reportPdfResponse4?.Expense_Report?.expenseReport;
  const totalCheque = reportPdfResponse4?.Expense_Report?.TotalChequeFields;
  const totalCash = reportPdfResponse4?.Expense_Report?.TotalCashFields;

  const expenseData = (cashComp:number, chequeComp:number) => (
    <td className="textCenter">
      <tr style={{ display: 'flex' }}>
        <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
          {cashComp}
        </td>
        <td className="textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
          {chequeComp}
        </td>
      </tr>
    </td>
  );

  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        <tr>
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            Per Diem
          </td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            <td className="tableGreenHeading textCenter boldText">
              {maindata.ExpDate}
            </td>
          ))}
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            Cash
          </td>
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            Charge
          </td>
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            {/* Travel */}
          </td>
          <td className="tableGreenHeading boldText">
            <tr style={{ display: 'flex' }}>
              <td className="tableGreenHeading textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
                Cash
              </td>
              <td className="tableGreenHeading textCenter" style={{ flexGrow: 1, alignContent: 'center', border: 'none' }}>
                Charge
              </td>
            </tr>
          </td>
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Lodging</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashLodge, maindata.Chequefields.ChequeLodge)
          ))}
          <td className="textCenter">{totalCash?.CashLodge}</td>
          <td className="textCenter">{totalCheque?.ChequeLodge}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Breakfast</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashBreak, maindata.Chequefields.ChequeBreak)
          ))}
          <td className="textCenter">{totalCash?.CashBreak}</td>
          <td className="textCenter">{totalCheque?.ChequeBreak}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Lunch</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashLunch, maindata.Chequefields.ChequeLunch)
          ))}
          <td className="textCenter">{totalCash?.CashLunch}</td>
          <td className="textCenter">{totalCheque?.ChequeLunch}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Dinner</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashDinner, maindata.Chequefields.ChequeDinner)
          ))}
          <td className="textCenter">{totalCash?.CashDinner}</td>
          <td className="textCenter">{totalCheque?.ChequeDinner}</td>
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            Travel
          </td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            <td className="tableGreenHeading textCenter boldText">
              {maindata.ExpDate}
            </td>
          ))}
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Rental Car</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashCar, maindata.Chequefields.ChequeCar)
          ))}
          <td className="textCenter">{totalCash?.CashCar}</td>
          <td className="textCenter">{totalCheque?.ChequeCar}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Parking/Tolls</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashPark, maindata.Chequefields.ChequePark)
          ))}
          <td className="textCenter">{totalCash?.CashPark}</td>
          <td className="textCenter">{totalCheque?.ChequePark}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Gas</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashGas, maindata.Chequefields.ChequeGas)
          ))}
          <td className="textCenter">{totalCash?.CashGas}</td>
          <td className="textCenter">{totalCheque?.ChequeGas}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Airfare</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashAir, maindata.Chequefields.ChequeAir)
          ))}
          <td className="textCenter">{totalCash?.CashAir}</td>
          <td className="textCenter">{totalCheque?.ChequeAir}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Sky Caps</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashSkycap, maindata.Chequefields.ChequeSkycap)
          ))}
          <td className="textCenter">{totalCash?.CashSkycap}</td>
          <td className="textCenter">{totalCheque?.ChequeSkycap}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Taxi</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashTaxi, maindata.Chequefields.ChequeTaxi)
          ))}
          <td className="textCenter">{totalCash?.CashTaxi}</td>
          <td className="textCenter">{totalCheque?.ChequeTaxi}</td>
        </tr>
        <tr>
          <td className="tableGreenHeading textCenter boldText underlineHeadingText">
            Other
          </td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            <td className="tableGreenHeading textCenter boldText">
              {maindata.ExpDate}
            </td>
          ))}
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
          <td className="tableGreenHeading textCenter boldText">
            {}
          </td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Entertainment</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashEnter, maindata.Chequefields.ChequeEnter)
          ))}
          <td className="textCenter">{totalCash?.CashEnter}</td>
          <td className="textCenter">{totalCheque?.ChequeEnter}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Phone</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashPhone, maindata.Chequefields.ChequePhone)
          ))}
          <td className="textCenter">{totalCash?.CashPhone}</td>
          <td className="textCenter">{totalCheque?.ChequePhone}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter">Misc</td>
          {expenseMainData?.map((maindata:ExpenseReportItem) => (
            expenseData(maindata.Cashfields.CashMisc, maindata.Chequefields.ChequeMisc)
          ))}
          <td className="textCenter">{totalCash?.CashMisc}</td>
          <td className="textCenter">{totalCheque?.ChequeMisc}</td>
        </tr>
      </table>
    </div>
  );
}

export default ComponentExpenseMainTable;
