import React, { useRef } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ComponentCustomerInfo from './components/customerSiteInfo';
import {
  selectedTabStyles, tabStyles, tabsStyles, stylesTabIndicator,
} from '../../../../Common/TabsStyles';
import ComponentPageHeader from '../../../../Common/PageHeader/ComponentPageHeader';
import ComponentTravelInfo from './components/travelInfo';
import { setSelectedTab } from './redux/jobScheduleSlice';
import { RootState } from '../../../../redux/rootState';

export default function EditJobSchedule() {
  const { jobId } = useParams();
  const targetDivRef = useRef<HTMLDivElement | null>(null);
  const goToTop = () => {
    const targetDiv = targetDivRef.current;
    targetDiv?.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const TABS = [
    { id: 1, label: 'Units', component: <ComponentCustomerInfo /> },
    { id: 2, label: 'Travel Info', component: <ComponentTravelInfo /> },
  ];
  const dispatch = useDispatch();
  const { selectedTab } = useSelector((state: RootState) => state.jobScheduleReducer);

  const handleChangeTab = (tabId: number) => {
    dispatch(setSelectedTab(tabId));
  };

  return (
    <>
      <ComponentPageHeader subHeading={`JOB # ${jobId}`} fieldId={jobId ?? ''} fieldName="Job ID" />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab - 1}
          onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.id}
              label={tab.label}
              style={selectedTab === tab.id ? selectedTabStyles : tabStyles}
              className="tab_styles"
              onClick={goToTop}
            />
          ))}
        </Tabs>
      </div>
      <div className="div_job_container_info" ref={targetDivRef}>
        <div className="div_container_CustomerInfo">
          {TABS[selectedTab - 1].component}
        </div>
      </div>
    </>
  );
}
