export const zoneApiMethod = {
  ADD: 'add',
  UPDATE: 'update',
  SELECT: 'select',
};

export const zoneMessages = {
  ADD_ZONE: 'Zone has been added successfully',
  EDIT_ZONE: 'Zone has been updated successfully',
};
