/* eslint-disable max-len */
import React from 'react';

export function ProfileRoundIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.28158 8.99999H1.71908C1.36608 8.99999 1.10508 8.65149 1.23858 8.33099C1.85658 6.84899 3.30858 5.99999 5.00008 5.99999C6.69208 5.99999 8.14408 6.84899 8.76208 8.33099C8.89558 8.65149 8.63458 8.99999 8.28158 8.99999ZM2.95858 2.99999C2.95858 1.89699 3.87458 0.999992 5.00008 0.999992C6.12608 0.999992 7.04158 1.89699 7.04158 2.99999C7.04158 4.10299 6.12608 4.99999 5.00008 4.99999C3.87458 4.99999 2.95858 4.10299 2.95858 2.99999ZM9.97808 8.81799C9.60708 7.13849 8.44608 5.89898 6.91858 5.33648C7.72808 4.69798 8.20008 3.66547 8.02658 2.53497C7.82558 1.22347 6.71158 0.173988 5.36758 0.0209884C3.51158 -0.190512 1.93758 1.22449 1.93758 2.99999C1.93758 3.94499 2.38458 4.78698 3.08208 5.33648C1.55358 5.89898 0.393578 7.13849 0.0220784 8.81799C-0.112922 9.42849 0.389579 9.99999 1.02708 9.99999H8.97308C9.61108 9.99999 10.1131 9.42849 9.97808 8.81799Z" fill="#495057" />
    </svg>
  );
}
