import React, { useEffect } from 'react';
import { Grid, Card } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../../redux/rootState';
import { assignSiteValues } from '../../utils/helper';
import { phoneRegex } from '../../../../../../utils/regex';
import { ISiteInfo } from '../../../utils/types';
import { JobScheduleFields } from '../../../utils/constants';
import { RHFAutocompleteField } from '../../../../../../Common/ComponentAutoSelect';
import RequiredFiled from '../../../../../../Common/RequiredField';

export default function ComponentSiteInfo({
  control, setValue, watch, errors,
}: ISiteInfo) {
  const {
    pinCodeDetails, phoneCodes, countries, citySuggestions,
  } = useSelector((state: RootState) => state.masterDataReducer);
  const { selectedSite } = useSelector((state: RootState) => state.jobStarterReducer);
  useEffect(() => {
    if (selectedSite?.SiteId) {
      assignSiteValues(setValue, selectedSite);
    }
  }, [selectedSite]);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(JobScheduleFields.siteCity);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo morePadding">
          Site
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Site</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.site}
                id={JobScheduleFields.site}
                isDisable
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.siteContact}
                id={JobScheduleFields.siteContact}
                inputProps={{ maxLength: 16 }}
                regEx={/^[A-Za-z ]+$/}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address</label>
              <ComponentTextInput
                control={control}
                className="nonCapitalizeField"
                name={JobScheduleFields.siteAddress}
                id={JobScheduleFields.siteAddress}
                inputProps={{ maxLength: 200 }}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address (Optional)</label>
              <ComponentTextInput
                control={control}
                className="nonCapitalizeField"
                inputProps={{ maxLength: 200 }}
                name={JobScheduleFields.siteAddressOptional}
                id={JobScheduleFields.siteAddressOptional}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={3} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Zip</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.siteZip}
                id={JobScheduleFields.siteZip}
                inputProps={{ maxLength: 10 }}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">State</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.siteState}
                id={JobScheduleFields.siteState}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={4} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">
                City
                <RequiredFiled />
              </label>
              {city === 'Other' ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  name={JobScheduleFields.otherSiteCity}
                  errors={errors}
                  setValue={setValue}
                />
              )
                : (
                  <ComponentSelectInput
                    name={JobScheduleFields.siteCity}
                    control={control}
                    size="small"
                    errors={errors}
                    entries={pinCodeDetails?.City ? [...pinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Country</label>
              <ComponentSelectInput
                name={JobScheduleFields.siteCountry}
                control={control}
                defaultValue="US"
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <ComponentSelectInput
                name={JobScheduleFields.siteCode}
                control={control}
                defaultValue="+1"
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>
          <Grid item md={4} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.sitePhone}
                regEx={phoneRegex}
                inputProps={{ maxLength: 16 }}
                id={JobScheduleFields.sitePhone}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={6} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <ComponentTextInput
                control={control}
                name={JobScheduleFields.siteCell}
                id={JobScheduleFields.siteCell}
                regEx={phoneRegex}
                inputProps={{ maxLength: 16 }}
                size="small"
              />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Note</label>
              <ComponentTextInput
                control={control}
                className="nonCapitalizeField"
                name={JobScheduleFields.siteNotes}
                id={JobScheduleFields.siteNotes}
                size="small"
                inputProps={{ maxLength: 2056 }}
                rowSize={8}
                multiline
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
