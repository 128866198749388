import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetListingPayload, PaginationResponse } from '../../../utils/type';
import {
  CustomerContactListInterface,
  DeleteOilReportPayload,
  GetAllUnitSerialNumbers,
  GetComponentSerialNumbers,
  GetExcelDataPayload, GetExcelResponse, OilReportInitialState, OilReportPdfDataResultType, ReplaceUnitCompPayload,
  ReportData, ReportDetailsById, UnitData, UpdateExcelPayloadAction, UploadExcelPayload,
} from '../utils/types';

const initialState: OilReportInitialState = {
  selectedTab: 0,
  isLoading: false,
  oilReportList: [],
  selectedOilReport: {} as ReportDetailsById,
  isPdfDataLoading: false,
  oilReportPdfData: {} as OilReportPdfDataResultType,
  excelData: [],
  totalExcelData: 0,
  totalOilReports: 0,
  allUnitSerialNumbers: [],
  totalUnitSerialNumbers: 0,
  allComponentSerialNumbers: [],
  totalComponentSerialNumbers: 0,
  selectedUnitSerialNumber: '',
  selectedCompartment: '',
  compData: [],
  unitData: [],
  isContactLoading: false,
  CustomerContactList: {} as CustomerContactListInterface,
  selectedDocumentId: '',
  isExcelDataFetched: false,
};

const OilReportSlice = createSlice({
  name: 'oilReport',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },

    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },

    removeExcelDetails(state) {
      return {
        ...state,
        isExcelDataFetched: false,
        excelData: [],
        totalExcelData: 0,
      };
    },

    updateExcelDataUnit(state, action: PayloadAction<UpdateExcelPayloadAction>) {
      const { excelData, selectedUnitSerialNumber } = state;
      const index = excelData.findIndex((unit) => unit.ReportId === selectedUnitSerialNumber);
      const { unitSerialNumber, isReplaced } = action.payload;
      if (index !== -1) {
        const updatedExcelData = [...excelData];
        updatedExcelData[index] = {
          ...updatedExcelData[index],
          UnitSerialNumber: unitSerialNumber,
          isUnitReplaced: isReplaced,
        };
        return {
          ...state,
          excelData: updatedExcelData,
        };
      }

      return state;
    },

    updateExcelDataComp(state, action: PayloadAction<UpdateExcelPayloadAction>) {
      const { excelData, selectedCompartment } = state;
      const index = excelData.findIndex((unit) => unit.ReportId === selectedCompartment);
      const { unitSerialNumber, isReplaced } = action.payload;
      if (index !== -1) {
        const updatedExcelData = [...excelData];
        updatedExcelData[index] = {
          ...updatedExcelData[index],
          CompartmentSerialNumber: unitSerialNumber,
          isCompReplaced: isReplaced,
        };
        return {
          ...state,
          excelData: updatedExcelData,
        };
      }

      return state;
    },

    setSelectedUnitSerialNumber(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedUnitSerialNumber: action.payload,
      };
    },

    setSelectedUnitCompartment(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedCompartment: action.payload,
      };
    },

    setCompData(state, action: PayloadAction<UnitData>) {
      const { compData } = state;
      const newUnitData = [...compData];
      const ind = compData.findIndex((data) => data.ReportId === action.payload.ReportId);
      if (ind > -1) {
        newUnitData[ind] = action.payload;
        return {
          ...state,
          compData: newUnitData,
        };
      }
      return {
        ...state,
        compData: [...state.compData, action.payload],
      };
    },

    setUnitData(state, action: PayloadAction<UnitData>) {
      const { unitData } = state;
      const newUnitData = [...unitData];
      const ind = unitData.findIndex((data) => data.ReportId === action.payload.ReportId);
      if (ind > -1) {
        newUnitData[ind] = action.payload;
        return {
          ...state,
          unitData: newUnitData,
        };
      }
      return {
        ...state,
        unitData: [...state.unitData, action.payload],
      };
    },

    removeUnitData(state, action: PayloadAction<string>) {
      const { unitData } = state;
      const newUnitData = [...unitData];

      const index = newUnitData.findIndex((detail) => detail.ReportId === action.payload);

      if (index !== -1) {
        newUnitData.splice(index, 1);
        return {
          ...state,
          unitData: newUnitData,
        };
      }

      return state;
    },

    removeCompData(state, action: PayloadAction<string>) {
      const { compData } = state;
      const newUnitData = [...compData];

      const index = newUnitData.findIndex((detail) => detail.ReportId === action.payload);

      if (index !== -1) {
        newUnitData.splice(index, 1);
        return {
          ...state,
          compData: newUnitData,
        };
      }

      return state;
    },

    getOilReportsStart(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getOilReportSuccess(state, action: PayloadAction<PaginationResponse<ReportData>>) {
      return {
        ...state,
        isLoading: false,
        oilReportList: action.payload.Data,
        totalOilReports: action.payload.TotalRecords,
      };
    },

    getOilReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getOilReportByIdStart(state, _action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getOilReportByIdSuccess(state, action: PayloadAction<ReportDetailsById>) {
      return {
        ...state,
        isLoading: false,
        selectedOilReport: action.payload,
      };
    },

    getOilReportByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateOilReportStart(state, _action: PayloadAction<ReportDetailsById>) {
      return {
        ...state,
        isLoading: true,
        excelData: [],
        totalExcelData: 0,
        selectedDocumentId: '',
      };
    },

    updateOilReportSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    updateOilReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteOilReportStart(state, _action: PayloadAction<DeleteOilReportPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    deleteOilReportSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteOilReportFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getOilReportPdfDataStart(state, _action) {
      return {
        ...state,
        isPdfDataLoading: true,
      };
    },

    getOilReportPdfDataSuccess(state, action: PayloadAction<OilReportPdfDataResultType>) {
      return {
        ...state,
        isPdfDataLoading: false,
        oilReportPdfData: action.payload,
      };
    },

    getOilReportPdfDataFailure(state) {
      return {
        ...state,
        isPdfDataLoading: false,
      };
    },
    uploadExcelStart(state, _action: PayloadAction<UploadExcelPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    uploadExcelSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        selectedDocumentId: action.payload,
      };
    },

    uploadExcelFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getExcelDataStart(state, _action: PayloadAction<GetExcelDataPayload>) {
      return {
        ...state,
        isLoading: true,
        isExcelDataFetched: false,
        unitData: [],
        compData: [],
      };
    },

    getExcelDataSuccess(state, action: PayloadAction<PaginationResponse<GetExcelResponse>>) {
      return {
        ...state,
        isLoading: false,
        excelData: action.payload.Data,
        totalExcelData: action.payload.TotalRecords,
        isExcelDataFetched: true,
      };
    },

    getExcelDataFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getAllUnitSerialNumberStart(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getAllUnitSerialNumberSuccess(state, action: PayloadAction<PaginationResponse<GetAllUnitSerialNumbers>>) {
      return {
        ...state,
        isLoading: false,
        allUnitSerialNumbers: action.payload.Data,
        totalUnitSerialNumbers: action.payload.TotalRecords,
      };
    },

    getAllUnitSerialNumberFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    getAllComponentSerialNumbersStart(state, _action: PayloadAction<GetListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getAllComponentSerialNumbersSuccess(state, action: PayloadAction<PaginationResponse<GetComponentSerialNumbers>>) {
      return {
        ...state,
        isLoading: false,
        allComponentSerialNumbers: action.payload.Data,
        totalComponentSerialNumbers: action.payload.TotalRecords,
      };
    },

    getAllComponentSerialNumbersFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    replaceUnitCompStart(state, _action: PayloadAction<ReplaceUnitCompPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    replaceUnitCompSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    replaceUnitCompFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    getCustomerContactList(state, _action) {
      return {
        ...state,
        isContactLoading: true,
        CustomerContactList: initialState.CustomerContactList,
      };
    },

    getCustomerContactListSuccess(state, action: PayloadAction<CustomerContactListInterface>) {
      return {
        ...state,
        isContactLoading: false,
        CustomerContactList: action.payload,
      };
    },

    getCustomerContactListFailure(state) {
      return {
        ...state,
        isContactLoading: false,
      };
    },
  },
});

export const {
  resetState, setSelectedTab, getOilReportsStart, getOilReportSuccess, getOilReportFailure,
  getOilReportByIdStart, getOilReportByIdSuccess, getOilReportByIdFailure, updateOilReportStart,
  updateOilReportSuccess, updateOilReportFailure, uploadExcelStart, uploadExcelSuccess, uploadExcelFailure,
  getExcelDataStart, getExcelDataSuccess, getExcelDataFailure, getAllUnitSerialNumberStart, getAllUnitSerialNumberSuccess,
  getAllUnitSerialNumberFailure, getAllComponentSerialNumbersStart,
  getAllComponentSerialNumbersSuccess, getAllComponentSerialNumbersFailure, setSelectedUnitSerialNumber, setUnitData,
  updateExcelDataUnit, removeUnitData, updateExcelDataComp, setSelectedUnitCompartment, setCompData, removeCompData,
  replaceUnitCompStart, replaceUnitCompSuccess, replaceUnitCompFailure, getOilReportPdfDataStart,
  getOilReportPdfDataSuccess, getOilReportPdfDataFailure, getCustomerContactList, getCustomerContactListSuccess,
  getCustomerContactListFailure, removeExcelDetails, deleteOilReportSuccess, deleteOilReportFailure, deleteOilReportStart,
} = OilReportSlice.actions;
export const oilReportReducer = OilReportSlice.reducer;

export type OilReportActions =
  | ReturnType<typeof resetState>
  | ReturnType<typeof setSelectedTab>
  | ReturnType<typeof getOilReportsStart>
  | ReturnType<typeof getOilReportSuccess>
  | ReturnType<typeof getOilReportFailure>
  | ReturnType<typeof getOilReportByIdStart>
  | ReturnType<typeof getOilReportByIdSuccess>
  | ReturnType<typeof getOilReportByIdFailure>
  | ReturnType<typeof updateOilReportStart>
  | ReturnType<typeof updateOilReportSuccess>
  | ReturnType<typeof updateOilReportFailure>
  | ReturnType<typeof uploadExcelStart>
  | ReturnType<typeof uploadExcelSuccess>
  | ReturnType<typeof uploadExcelFailure>
  | ReturnType<typeof getExcelDataStart>
  | ReturnType<typeof getExcelDataSuccess>
  | ReturnType<typeof getExcelDataFailure>
  | ReturnType<typeof getAllUnitSerialNumberStart>
  | ReturnType<typeof getAllUnitSerialNumberSuccess>
  | ReturnType<typeof getAllUnitSerialNumberFailure>
  | ReturnType<typeof getAllComponentSerialNumbersStart>
  | ReturnType<typeof getAllComponentSerialNumbersSuccess>
  | ReturnType<typeof getAllComponentSerialNumbersFailure>
  | ReturnType<typeof setSelectedUnitSerialNumber>
  | ReturnType<typeof setUnitData>
  | ReturnType<typeof updateExcelDataUnit>
  | ReturnType<typeof removeUnitData>
  | ReturnType<typeof updateExcelDataComp>
  | ReturnType<typeof setSelectedUnitCompartment>
  | ReturnType<typeof setCompData>
  | ReturnType<typeof removeCompData>
  | ReturnType<typeof replaceUnitCompStart>
  | ReturnType<typeof replaceUnitCompSuccess>
  | ReturnType<typeof replaceUnitCompFailure>
  | ReturnType<typeof getOilReportPdfDataStart>
  | ReturnType<typeof getOilReportPdfDataSuccess>
  | ReturnType<typeof getOilReportPdfDataFailure>
  | ReturnType<typeof getCustomerContactList>
  | ReturnType<typeof getCustomerContactListSuccess>
  | ReturnType<typeof getCustomerContactListFailure>
  | ReturnType<typeof removeExcelDetails>
  | ReturnType<typeof deleteOilReportSuccess>
  | ReturnType<typeof deleteOilReportFailure>
  | ReturnType<typeof deleteOilReportStart>;
