import { Grid, Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import '../../utils/styles.scss';
import rightArrow from '../../../../../assets/rightarrowactivity.svg';
import { RootState } from '../../../../../redux/rootState';
import { getEquipmentHistoryDataStart } from '../../../../InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import { EquipmentHistoryDataInterface } from '../../../../InventoryManagement/TestEquipmentRequestScreen/redux/type';

export default function ComponentHistory() {
  const dispatch = useDispatch();
  const { reqId } = useParams();

  const { equipmentHistoryData, equipmentHistoryLoading } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);

  useEffect(() => {
    if (reqId) {
      dispatch(getEquipmentHistoryDataStart(reqId));
    }
  }, [reqId]);

  useEffect(() => {
    dispatch(setLoader(equipmentHistoryLoading));
  }, [equipmentHistoryLoading]);

  const getAdminName = (des: string) => {
    const adminName: string[] = des?.length > 0 ? des?.split(' ') : [];
    const adminFirstName = adminName?.length > 0 ? adminName[0] : [];
    const adminLastName = adminName?.length > 0 ? adminName[1] : [];
    const combineName = adminFirstName?.length > 0 && adminLastName?.length > 0 ? `${adminFirstName[0]} ${adminLastName[0]}` : '';
    return combineName;
  };

  const getFormattedDate = (date: string) => {
    const parsedTimestamp = dayjs(date);
    return parsedTimestamp.format('MMMM D, YYYY [at] h:mm A');
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Activity History
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              {equipmentHistoryData?.length > 0 && equipmentHistoryData?.map((element: EquipmentHistoryDataInterface) => {
                const combineName = getAdminName(element?.Description);
                const formattedDate = getFormattedDate(element?.Time);
                return (
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="activityHistoryMainContainer">
                      <div className="activityHistoryLeftSide">
                        <div className="activityHistoryLetters">
                          <span className="activityHistoryLetter">{combineName}</span>
                        </div>
                        <div className="activityHistoryDetails">
                          <div className="activityHistoryName">
                            &quot;
                            {element?.AdminNote}
                            &quot;
                          </div>
                          <div className="activityHistoryName">
                            {element?.Description}
                          </div>
                          <div className="activitycontainer">
                            <span className="activityHistoryCreate">{`Request ${element?.Status}`}</span>
                            <span className="activityIcon">
                              <img src={rightArrow} alt="" />
                            </span>
                            <span className={element?.Status !== 'Rejected' ? 'activityHistoryDone' : 'activityHistoryNotDone'}>Done</span>
                          </div>
                        </div>
                      </div>
                      <div className="activityHistoryRightSide">
                        {formattedDate}
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
