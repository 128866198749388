import { ASMESectionVEnum, AsmeByIdResponse } from '../type';

export function assignAsmeValues(asmeDetails:AsmeByIdResponse, setValue:SetValue) {
  [
    { name: ASMESectionVEnum.maximumScanSpeedDuringRecording, value: asmeDetails.MaxScanSpeed },
    { name: ASMESectionVEnum.NSerialNumberOfEddyCurrentInstrument, value: asmeDetails.SerialNum },
    { name: ASMESectionVEnum.probeCableLength, value: asmeDetails.LengthProbe },
    { name: ASMESectionVEnum.probeCableType, value: asmeDetails.TypeProbe },
    { name: ASMESectionVEnum.extensionCableLength, value: asmeDetails.LengthExtention },
    { name: ASMESectionVEnum.extensionCableType, value: asmeDetails.TypeExtention },
    { name: ASMESectionVEnum.probeMfg, value: asmeDetails.ProbeManufacture },
    { name: ASMESectionVEnum.probeSerialNumber, value: asmeDetails.ProbeSerialNumber },
    { name: ASMESectionVEnum.probeScanDirection, value: asmeDetails.ProbeScanDirection },
  ].forEach(({ name, value }) => setValue(name, value));
}
