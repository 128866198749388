/* eslint-disable max-len */
import React from 'react';

export function VectorPlus() {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 0C4.70064 0 0 4.70064 0 10.5C0 16.2994 4.70064 21 10.5 21C16.2994 21 21 16.2994 21 10.5C21 4.70064 16.2994 0 10.5 0ZM15.96 11.34H11.34V15.96C11.34 16.1828 11.2515 16.3964 11.094 16.554C10.9364 16.7115 10.7228 16.8 10.5 16.8C10.2772 16.8 10.0636 16.7115 9.90603 16.554C9.7485 16.3964 9.66 16.1828 9.66 15.96V11.34H5.04C4.81722 11.34 4.60356 11.2515 4.44603 11.094C4.2885 10.9364 4.2 10.7228 4.2 10.5C4.2 10.2772 4.2885 10.0636 4.44603 9.90603C4.60356 9.7485 4.81722 9.66 5.04 9.66H9.66V5.04C9.66 4.81722 9.7485 4.60356 9.90603 4.44603C10.0636 4.2885 10.2772 4.2 10.5 4.2C10.7228 4.2 10.9364 4.2885 11.094 4.44603C11.2515 4.60356 11.34 4.81722 11.34 5.04V9.66H15.96C16.1828 9.66 16.3964 9.7485 16.554 9.90603C16.7115 10.0636 16.8 10.2772 16.8 10.5C16.8 10.7228 16.7115 10.9364 16.554 11.094C16.3964 11.2515 16.1828 11.34 15.96 11.34Z" fill="#212529" />
    </svg>
  );
}
