import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import {
  Button, Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, Grid, TextField, InputAdornment,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { TypesViewOilKitsComponentModal } from '../../utils/types';
import { IconList } from '../../../../../../utils/StaticIcons';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import { EditIcon } from '../../../../../../assets/TableIcons';
import searchIcon from '../../../../../../assets/search_icon.svg';
import { clearSearchOilKit, getSearchOilKit } from '../../../ScreenOilKits/redux/oilKitsSlice';
import { RootState } from '../../../../../../redux/rootState';

const theme = createTheme({
  palette: {
    primary: {
      main: '#092245',
      contrastText: '#fff',
    },
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 10px 10px 10px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 1,
  },
};
export default function ViewOilKitSelectorComponent({ show, setOpenFrom }: TypesViewOilKitsComponentModal): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { oilKitResult } = useSelector((state: RootState) => state.oilKitsSliceReducer);

  const onSearch = () => {
    dispatch(getSearchOilKit(searchValue));
  };

  const handleEdit = (kitId: string) => {
    navigate(`${kitId}`);
    dispatch(clearSearchOilKit({
      CustId: '',
      OilKitId: '',
      ShipDate: '',
    }));
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="viewOilKitsComponent">
            <div className="viewOilKitsComponent_heading">Oil Kit Selector</div>
            <div className="viewOilKitsComponent_crossIcon" onClick={setOpenFrom}>
              <img src={IconList.closeIcon} alt="" />
            </div>
          </div>
          <div className="viewOilKitsComponent_dataTableStyle">
            <Grid container spacing={2} sx={{ margin: '20px 0px', alignItems: 'center' }}>
              <Grid item md={2} sm={6} xs={12}>
                <TextField
                  className="subHeaderTextfield"
                  size="small"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder=" Kit Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={searchIcon} alt="" />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={2} sm={6} xs={12}>
                <div className="divStyleButton">
                  <Button onClick={onSearch} className="addBtn">
                    Search
                  </Button>
                </div>
              </Grid>
            </Grid>
            <ThemeProvider theme={theme}>
              <TableContainer component={Paper} className="customPaper">
                <Table stickyHeader className="tableStyle">
                  <TableHead>
                    <TableRow>
                      <TableCell className="wdth-3">Name</TableCell>
                      <TableCell className="wdth-3">Action</TableCell>
                      <TableCell className="wdth-3">Customer</TableCell>
                      <TableCell className="wdth-3">Ship Date</TableCell>
                    </TableRow>
                  </TableHead>
                  {oilKitResult?.OilKitId && (
                    <TableBody>
                      <TableRow
                        className="table-row-height"
                      >
                        <TableCell className="wdth-3">{oilKitResult?.OilKitId}</TableCell>
                        <TableCell className="wdth-3">
                          <div onClick={() => handleEdit(oilKitResult?.OilKitId)} className="tableCell-edit">
                            <div><EditIcon /></div>
                          </div>
                        </TableCell>
                        <TableCell className="wdth-3">{oilKitResult?.CustId}</TableCell>
                        <TableCell className="wdth-3">{oilKitResult?.ShipDate}</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </ThemeProvider>
          </div>
          <div className="viewOilKitsComponent_saveAndNextDiv">
            <div>
              <Button onClick={setOpenFrom} className="viewOilKitsComponent_buttonCancel">{translations.cancel}</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
