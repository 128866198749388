import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AdditionalMenuItem, CategoryItem, ColorMasterDataInitialState, DeleteDefectPayload, MasterAPIResult, TreeNodeData,
} from '../utils/TypeAnalystMenu';

const initialState: ColorMasterDataInitialState = {
  colorCode: [],
  isLoading: false,
  isSuccess: false,
  message: '',
  treeDataList: [] as TreeNodeData[],
  treeData: {} as CategoryItem,
  categories: [],
};

export const defectCodeSlice = createSlice({
  name: 'defectCode',
  initialState,
  reducers: {
    getColorDataStart(state) {
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    },
    getColorDataSuccess(state, action: PayloadAction<MasterAPIResult>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        colorCode: action.payload.Colors,
        categories: action.payload.Categories,
      };
    },
    getColorDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    getTreeDataStart(state) {
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    },
    getTreeDataSuccess(state, action: PayloadAction<TreeNodeData[]>) {
      return {
        ...state,
        isLoading: false,
        treeDataList: action.payload,
      };
    },
    getTreeDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    createTreeDataStart(state, _action: PayloadAction<AdditionalMenuItem>) {
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    },
    createTreeDataSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
      };
    },
    createTreeDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    deleteTreeDataStart(state, _action: PayloadAction<DeleteDefectPayload>) {
      return {
        ...state,
        isSuccess: false,
        isLoading: true,
      };
    },
    deleteTreeDataSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: action.payload,
      };
    },
    deleteTreeDataFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        message: action.payload,
      };
    },
    setTreeData(state, action: PayloadAction<CategoryItem>) {
      return {
        ...state,
        treeData: action.payload,
      };
    },
    resetDefectState() {
      return initialState;
    },
  },
});

export const {
  getColorDataStart, getColorDataSuccess, getColorDataFailure, getTreeDataStart, getTreeDataSuccess, getTreeDataFailure,
  createTreeDataStart, createTreeDataSuccess, createTreeDataFailure, deleteTreeDataStart, deleteTreeDataSuccess, deleteTreeDataFailure,
  setTreeData, resetDefectState,
} = defectCodeSlice.actions;
export const defectCodeReducer = defectCodeSlice.reducer;

export type DefectCodeActions =
  | ReturnType<typeof getColorDataStart>
  | ReturnType<typeof getColorDataSuccess>
  | ReturnType<typeof getColorDataFailure>
  | ReturnType<typeof getTreeDataStart>
  | ReturnType<typeof getTreeDataSuccess>
  | ReturnType<typeof getTreeDataFailure>
  | ReturnType<typeof createTreeDataStart>
  | ReturnType<typeof createTreeDataSuccess>
  | ReturnType<typeof createTreeDataFailure>
  | ReturnType<typeof deleteTreeDataStart>
  | ReturnType<typeof deleteTreeDataSuccess>
  | ReturnType<typeof deleteTreeDataFailure>
  | ReturnType<typeof setTreeData>
  | ReturnType<typeof resetDefectState>;
