import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  GridType, TubeChartGridProps,
} from '../../../utils/tubeChartTypes';
import { setRelativeCord } from '../../../redux/tubeChart/tubeChartSlice';
import { RootState } from '../../../../../redux/rootState';

export function TubeChartGrid({
  polygon, setPolyCircles, polyCircles,
}:TubeChartGridProps) {
  const dispatch = useDispatch();
  const { vanishType, scale } = useSelector((state:RootState) => state.TubeChartReducer);

  const getRowClassName = (i:number) => {
    switch (polygon.gridType) {
      case GridType.LeftEccentric:
        return i % 2 !== 0 ? styles.eccentricMargin : '';
      case GridType.TopEccentric:
        return styles.rowItemInverted;
      default:
        return '';
    }
  };

  useEffect(() => {
    setPolyCircles([...polygon.circles]);
  }, []);

  const handleCircleClick = (x:number, y:number) => {
    polygon.toggleVisibility(x, y, vanishType);
    setPolyCircles([...polygon.getCircles()]);
  };

  const handleMouseEnter = (x:number, y:number) => {
    dispatch(setRelativeCord({ x: polyCircles[x][y].relX, y: polyCircles[x][y].relY }));
  };

  const handleMouseLeave = () => {
    dispatch(setRelativeCord({ x: 0, y: 0 }));
  };

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <div
        className={`${styles.mainPolygonClass} ${polygon.gridType === GridType.TopEccentric ? styles.gridInvertedRow : styles.polygrame}`}
        style={{ transform: `scale(${scale})` }}
        onMouseLeave={handleMouseLeave}
      >
        {polyCircles.map((row, i) => (
          <div key={`row_${row[i]}_${i + 1}`} className={`${styles.gridRow} ${getRowClassName(i)}`}>
            {row.map((data, j) => (
              <div
                onMouseEnter={() => {
                  handleMouseEnter(i, j);
                }}
                onMouseLeave={handleMouseLeave}
                key={`col_${row[i]}_${j + 8}`}
                className={
                      `${data.visible ? styles.dotButton : styles.dotButtonInactive} 
                      ${(j % 2 !== 0 && polygon.gridType === GridType.TopEccentric) ? styles.invertedmargin : ''}`
                    }
                style={{ backgroundColor: data.defectColor }}
                onClick={() => handleCircleClick(i, j)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}
