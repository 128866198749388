import React from 'react';
import { useSelector } from 'react-redux';
import { ImageData } from '../types';
// import phaseChart from '../../../assets/phase-chart.svg';
// import StripChartTable from './stripChartTable';
import { RootState } from '../../../redux/rootState';

function ComponentStripsChart({ newDetails: newDeta̵ils }: { newDetails:ImageData }) {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  // const CalibrationStripChartData = reportPdfResponse3?.Calibration_Strip_Charts;
  return (
    <div className="p30">
      <div className="pageHeader">
        <div className="topHeader">
          {/* <div>Test Instrument Used Highline Automation, EC14</div> */}
          <div>{}</div>
          <div>{`S/N ${reportPdfResponse3?.JobOrderData?.SerialNumber}`}</div>
        </div>
        <div className="underlineHeadingText textCenter">Calibration Page</div>
      </div>
      <div className="phaseChartImageContainer">
        <img src={newDeta̵ils?.ImageConfig || ''} alt="Strip chart" />
      </div>
    </div>
  );
}

export default ComponentStripsChart;
