import {
  Grid, Card, Select, TextField, Button, Tooltip, IconButton, MenuItem,
} from '@mui/material';
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomCheckbox from '../../../../utils/custom-input/inputCheckBox';
import '../StyleBidComponents.scss';

export default function ComponentBidEditMainTab(): JSX.Element {
  return (

    <div className="div_container_CustomerInfo">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="container_label_14px">
                Bid Total - $7,997,1
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomCheckbox type="checkbox" name="acceptedTos" label="West Cost" />
                <div style={{ height: '30px', fontSize: '10px !important' }}>
                  <TextField
                    size="small"
                    placeholder="Customer"
                    InputProps={{
                      endAdornment: (
                        <Tooltip title="Search Here..">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Bid Name</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <DemoItem label="Date">
                    <DesktopDatePicker defaultValue={dayjs('2023-06-17')} />
                  </DemoItem>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Company Name</label>
                    <TextField size="small" />
                  </div>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Location</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Site Zip</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Site State</label>
                    <Select size="small">
                      <MenuItem value={1}>Texas</MenuItem>
                    </Select>
                  </div>
                </Grid>

                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Site City</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Site Country</label>
                    <Select size="small">
                      <MenuItem value={1}>Texas</MenuItem>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Bid By</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Analyst</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Distance Mile(One Way)</label>
                    <TextField size="small" />
                  </div>
                </Grid>
                {' '}
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_Customer">Work Days</label>
                    <TextField size="small" />
                  </div>
                </Grid>

                <Grid item md={12} sm={12} xs={12}>
                  <div className="save_and_next_div">
                    <div>
                      <Button className="button_cancel">Cancel</Button>
                    </div>
                    <div className="button_margin_left">
                      <Button className="button_save_and_next">Ok</Button>
                    </div>
                  </div>
                </Grid>

              </Grid>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
