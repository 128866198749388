import { UnitDetails } from '../../../../utils/types';
import { AddReportPayload } from './Type';

export enum AddReportFields {
  condenser = 'condenser',
  vesselTwo = 'vesselTwo',
  vesselThree = 'vesselThree',
}

export const ReportFormDefault = {
};

// const jobOrderPattern = /^(\d+-\d+)\((.*?)\)-\((\d+)\)$/;
export const jobOrderPattern = /^(\d+-\d+)\((.*?)\)-\((\d+)\)-\((\d+)\)$/;

export const generatePayload = (unitVesselId: string, jobString: string | undefined, unitEditData: UnitDetails): AddReportPayload => {
  if (!jobString) {
    return {
      Id: 0,
      JobOrder: '',
      VesselId: '',
      UnitId: unitEditData?.UnitId || '',
      UnitVesselId: unitVesselId,
    };
  }

  const match = jobString.match(jobOrderPattern);
  if (match) {
    const [, jobOrder, , vesselId, id] = match;
    return {
      Id: Number(id) || 0,
      JobOrder: jobOrder,
      VesselId: vesselId,
      UnitId: unitEditData?.UnitId || '',
      UnitVesselId: unitVesselId,
    };
  }

  return {
    Id: 0,
    JobOrder: '',
    VesselId: '',
    UnitId: unitEditData?.UnitId || '',
    UnitVesselId: unitVesselId,
  };
};
