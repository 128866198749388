/* eslint-disable max-len */
import React from 'react';

export function SelectedCheckBoxIcon() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" stroke="#00A751" />
      <path d="M8 14.898L11.6 18.5714L20 10" stroke="#00A751" strokeWidth="1.5" strokeMiterlimit="10" />
    </svg>
  );
}
