import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  TypeVesselDefectList, InitialStateVesselDefect, GetDefectListingPayload, CreateVesselDefectType,
  UpdateVesselDefectType,
  DeleteDefectPayload,
  DateTimeDefectType,
  SearchReplacePayloadType,
  InsertDefectTypeApi,
  DropDownPayloadType,
  MenuAPIData,
  TimeEntry,
  PaginationDefectResponse,
} from '../../utils/defectType';

const initialState: InitialStateVesselDefect = {
  isLoading: false,
  vesselDefectList: [],
  startTime: {} as TimeEntry | null,
  endTime: {} as TimeEntry | null,
  defectDataById: {} as TypeVesselDefectList,
  totalVesselDefect: 0,
  allDefectDropdownData: {} as MenuAPIData,
};
const VesselDefectSlice = createSlice({
  name: 'VesselDefectSlice',
  initialState,
  reducers: {

    // Get Vessel defect List ---------------
    getVesselDefectStart(state, _action: PayloadAction<GetDefectListingPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getVesselDefectSuccess(state, action: PayloadAction<PaginationDefectResponse<TypeVesselDefectList>>) {
      return {
        ...state,
        isLoading: false,
        vesselDefectList: action.payload.Data,
        totalVesselDefect: action.payload.TotalRecords,
        startTime: action.payload.start,
        endTime: action.payload.finish,
      };
    },

    getVesselDefectFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // Delete Vessel Defect By Id ----------------
    deleteVesselDefectStart(state, _action: PayloadAction<DeleteDefectPayload>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    deleteVesselDefectSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    deleteVesselDefectFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Vessel Defect By ID
    getDefectByIdStart(state, _action: PayloadAction<{ id: string }>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getDefectByIdSuccess(state, action: PayloadAction<TypeVesselDefectList>) {
      return {
        ...state,
        isLoading: false,
        defectDataById: action.payload,
      };
    },
    getDefectByIdFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ----Create Vessel Defect  --------->>>>>>
    createDefectStart(state, _action: PayloadAction<CreateVesselDefectType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createDefectSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    createDefectFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ----Update Vessel Defect  --------->>>>>>>
    updateVesselDefectStart(state, _action: PayloadAction<UpdateVesselDefectType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateVesselDefectSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    updateVesselDefectFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    resetDefectData(state) {
      return {
        ...state,
        defectDataById: {} as TypeVesselDefectList,
        allDefectDropdownData: {} as MenuAPIData,
      };
    },

    // ---- Add Time Date Vessel Defect  --------->>>>>>
    dateTimeStart(state, _action: PayloadAction<DateTimeDefectType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    createDateTimeSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    createDateTimeFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ---- Insert Time Vessel Defect  --------->>>>>>
    addInsertStart(state, _action: PayloadAction<InsertDefectTypeApi>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    addInsertTimeSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    addInsertTimeFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // ---- Search and Replace Vessel Defect  --------->>>>>>
    searchReplaceStart(state, _action: PayloadAction<SearchReplacePayloadType>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    searchReplaceSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    searchReplaceFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },

    // -- Get Vessel Defect Dropdown Data
    getDefectDropdownStart(state, _action: PayloadAction<DropDownPayloadType>) {
      return {
        ...state,
        isLoading: true,
      };
    },

    getDefectDropdownSuccess(state, action: PayloadAction<MenuAPIData>) {
      return {
        ...state,
        isLoading: false,
        allDefectDropdownData: action.payload,
      };
    },
    getDefectDropdownFailure(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
  },
});

export const {
  getVesselDefectStart, getVesselDefectSuccess, getVesselDefectFailure,
  deleteVesselDefectStart, deleteVesselDefectSuccess, deleteVesselDefectFailure,
  getDefectByIdStart, getDefectByIdSuccess, getDefectByIdFailure,
  updateVesselDefectStart, updateVesselDefectSuccess, updateVesselDefectFailure,
  createDefectStart, createDefectSuccess, createDefectFailure, resetDefectData,
  dateTimeStart, createDateTimeSuccess, createDateTimeFailure,
  addInsertStart, addInsertTimeSuccess, addInsertTimeFailure,
  searchReplaceStart, searchReplaceSuccess, searchReplaceFailure,
  getDefectDropdownStart, getDefectDropdownSuccess, getDefectDropdownFailure,
} = VesselDefectSlice.actions;
export const VesselDefectReducer = VesselDefectSlice.reducer;

export type VesselDefectActions =
  | ReturnType<typeof getVesselDefectStart>
  | ReturnType<typeof getVesselDefectSuccess>
  | ReturnType<typeof getVesselDefectFailure>
  | ReturnType<typeof deleteVesselDefectStart>
  | ReturnType<typeof deleteVesselDefectSuccess>
  | ReturnType<typeof deleteVesselDefectFailure>
  | ReturnType<typeof getDefectByIdStart>
  | ReturnType<typeof getDefectByIdSuccess>
  | ReturnType<typeof getDefectByIdFailure>
  | ReturnType<typeof updateVesselDefectStart>
  | ReturnType<typeof updateVesselDefectSuccess>
  | ReturnType<typeof updateVesselDefectFailure>
  | ReturnType<typeof createDefectStart>
  | ReturnType<typeof createDefectSuccess>
  | ReturnType<typeof createDefectFailure>
  | ReturnType<typeof resetDefectData>
  | ReturnType<typeof dateTimeStart>
  | ReturnType<typeof createDateTimeSuccess>
  | ReturnType<typeof createDateTimeFailure>
  | ReturnType<typeof addInsertStart>
  | ReturnType<typeof addInsertTimeSuccess>
  | ReturnType<typeof addInsertTimeFailure>
  | ReturnType<typeof searchReplaceStart>
  | ReturnType<typeof searchReplaceSuccess>
  | ReturnType<typeof searchReplaceFailure>
  | ReturnType<typeof getDefectDropdownStart>
  | ReturnType<typeof getDefectDropdownSuccess>
  | ReturnType<typeof getDefectDropdownFailure>;
