import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddTubeListApiResponse, AddTubeListData, DeleteTubeListResponse, GetAllTubeListApiResponse,
  PayloadTypeDeleteTubeList, PayloadTypeGetTubeList, UpdateTubeListApiResponse, UpdateTubeListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addTubeListStart, deleteTubeListStart, failureAddTubeList, failureDeleteTubeList, failureTubeList,
  failureUpdateTubeList, getTubeListStart,
  TubeTypeActions, successAddTubeList, successDeleteTubeList, successTubeList, successUpdateTubeList, updateTubeList,
} from './sliceTubeList';

async function GetTubeList(data: PayloadTypeGetTubeList): Promise<GetAllTubeListApiResponse> {
  const url = `${Config.Lists.getTubeList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllTubeListApiResponse>(url);
  return result?.data;
}

export const epicGetTubeList = (action$: Observable<TubeTypeActions>) => action$.pipe(
  filter(getTubeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetTubeList) => from(GetTubeList(data)).pipe(
    map((res: GetAllTubeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successTubeList(res?.BMT?.Result);
      }
      return failureTubeList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getTubeListStart.match))),
    catchError((error: AxiosError<GetAllTubeListApiResponse>) => of(failureTubeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateTubeList(data: UpdateTubeListData): Promise<UpdateTubeListApiResponse> {
  const url = `${Config.Lists.updateTubeList}`;
  const result = await makePostRequest<UpdateTubeListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateTubeList = (action$: Observable<TubeTypeActions>) => action$.pipe(
  filter(updateTubeList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateTubeList(data)).pipe(
    mergeMap((res: UpdateTubeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateTubeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateTubeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateTubeList.match))),
    catchError((error: AxiosError<UpdateTubeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateTubeList(errorMessage));
    }),
  )),
);

async function AddTubeList(data: AddTubeListData): Promise<AddTubeListApiResponse> {
  const url = `${Config.Lists.addTubeList}`;
  const result = await makePostRequest<AddTubeListApiResponse>(url, data);
  return result?.data;
}

export const epicAddTubeList = (action$: Observable<TubeTypeActions>) => action$.pipe(
  filter(addTubeListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddTubeList(data)).pipe(
    mergeMap((res: AddTubeListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddTubeList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddTubeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addTubeListStart.match))),
    catchError((error: AxiosError<AddTubeListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddTubeList(errorMessage));
    }),
  )),
);

async function DeleteTubeList(data: PayloadTypeDeleteTubeList): Promise<DeleteTubeListResponse> {
  const url = `${Config.Lists.deleteTubeList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteTubeListResponse>(url);
  return result?.data;
}

export const epicDeleteTubeList = (action$: Observable<TubeTypeActions>) => action$.pipe(
  filter(deleteTubeListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteTubeList) => from(DeleteTubeList(data)).pipe(
    mergeMap((res: DeleteTubeListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteTubeList(), getTubeListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteTubeList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteTubeListStart.match))),
    catchError((error: AxiosError<DeleteTubeListResponse>) => of(failureDeleteTubeList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
