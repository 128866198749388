import React from 'react';
import TextField from '@mui/material/TextField';

interface NumberInputProps {
  value: number | string;
  onChange: (newValue: string) => void;
  regex: string;
  className?: string;
  maxValue: number;
  autoComplete? : string
}

function GraphInput({
  value, onChange, regex, className, maxValue, autoComplete = 'off',
}:NumberInputProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;

    inputValue = inputValue.replace(new RegExp(regex, 'g'), '');

    if (inputValue !== '') {
      inputValue = Math.min(maxValue, Math.max(0, parseInt(inputValue, 10))).toString();
    }

    onChange(inputValue);
  };

  return (
    <TextField
      size="small"
      className={className || ''}
      type="number"
      fullWidth
      autoComplete={autoComplete}
      value={value.toString()}
      onInput={handleInputChange}
    />
  );
}

export default GraphInput;
