/* eslint-disable max-len */
import React from 'react';

export function DeleteSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 309">
        <path id="Vector" d="M7.83399 19C7.37565 19 6.98315 18.8367 6.65649 18.51C6.32982 18.1833 6.16677 17.7911 6.16732 17.3333V6.5C5.93121 6.5 5.73315 6.42 5.57315 6.26C5.41315 6.1 5.33343 5.90223 5.33399 5.66667C5.33399 5.43056 5.41399 5.2325 5.57399 5.0725C5.73399 4.9125 5.93177 4.83278 6.16732 4.83334H9.50065C9.50065 4.59723 9.58065 4.39917 9.74065 4.23917C9.90065 4.07917 10.0984 3.99945 10.334 4H13.6673C13.9034 4 14.1015 4.08 14.2615 4.24C14.4215 4.4 14.5012 4.59778 14.5007 4.83334H17.834C18.0701 4.83334 18.2682 4.91334 18.4282 5.07334C18.5882 5.23334 18.6679 5.43111 18.6673 5.66667C18.6673 5.90278 18.5873 6.10084 18.4273 6.26084C18.2673 6.42084 18.0695 6.50056 17.834 6.5V17.3333C17.834 17.7917 17.6707 18.1842 17.344 18.5108C17.0173 18.8375 16.6251 19.0006 16.1673 19H7.83399ZM7.83399 6.5V17.3333H16.1673V6.5H7.83399ZM9.50065 14.8333C9.50065 15.0694 9.58065 15.2675 9.74065 15.4275C9.90065 15.5875 10.0984 15.6672 10.334 15.6667C10.5701 15.6667 10.7682 15.5867 10.9282 15.4267C11.0882 15.2667 11.1679 15.0689 11.1673 14.8333V9C11.1673 8.76389 11.0873 8.56584 10.9273 8.40584C10.7673 8.24584 10.5695 8.16611 10.334 8.16667C10.0979 8.16667 9.89982 8.24667 9.73982 8.40667C9.57982 8.56667 9.5001 8.76445 9.50065 9V14.8333ZM12.834 14.8333C12.834 15.0694 12.914 15.2675 13.074 15.4275C13.234 15.5875 13.4318 15.6672 13.6673 15.6667C13.9034 15.6667 14.1015 15.5867 14.2615 15.4267C14.4215 15.2667 14.5012 15.0689 14.5007 14.8333V9C14.5007 8.76389 14.4207 8.56584 14.2607 8.40584C14.1007 8.24584 13.9029 8.16611 13.6673 8.16667C13.4312 8.16667 13.2332 8.24667 13.0732 8.40667C12.9132 8.56667 12.8334 8.76445 12.834 9V14.8333Z" fill="#F06548" />
      </g>
    </svg>
  );
}
