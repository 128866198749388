/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Button, Grid, TextField,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { ZoneIcon } from '../../assets/Icons/BoardPositionIcons/ZoneIcon';
import AddAnalystModal from './components/AddAnalystModal';
import NoData from '../../assets/NoData.svg';
import {
  getFilteredAnalystData, onCreateZone, onGetBoardPositionData,
} from './redux/sliceBoardPosition';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { zoneApiMethod } from './utils/constant';
import { AnalystDataInterface } from './utils/type';
import { AnalystInterface } from './redux/type';
import DraggableTable from '../../Common/DraggableTable.tsx';

export default function BoardPosition() {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState<number>(0);
  const [showAnalystModal, setShowAnalystModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState<number>(0);
  const [zoneName, setZoneName] = useState<string | undefined>('');
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    isLoading, BoardPositionListData, FilteredAnalystData, isBoardAnalystLoading,
  } = useSelector((state: RootState) => state.boardPositionReducer);

  const BoardPositionColumn = useMemo(() => [
    { key: 'analystName', label: 'Analyst Name' },
  ], []);

  const formatData = (analystData: AnalystInterface[]) => {
    const formattedData = analystData?.map((ele, index) => ({
      sn: index,
      analystName: ele?.AnalystName,
      isChecked: false,
      id: ele?.AnalystId,
    }));
    return formattedData;
  };
  const handleData = () => {
    dispatch(getFilteredAnalystData());
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    dispatch(setLoader(isBoardAnalystLoading));
  }, [isBoardAnalystLoading]);

  const handleZonePageData = () => {
    handleData();
    dispatch(onGetBoardPositionData());
  };

  useEffect(() => {
    handleZonePageData();
  }, []);

  const handleEditClick = (zoneId: number) => {
    const currentZone = BoardPositionListData?.find((ele) => ele.ZoneId === zoneId);
    setZoneName(currentZone?.ZoneName);
    setIsEditing(zoneId);
  };

  const onAddAnalystClick = (id: number) => {
    setSelectedZone(id);
    setShowAnalystModal(true);
  };

  const handleBlurFunction = (value: string) => {
    if (value === '') {
      setError(true);
      setErrorMessage('Zone is required');
    } else {
      setIsEditing(0);
      setError(false);
      setErrorMessage('');
      const payload = {
        ZoneId: isEditing,
        ZoneName: zoneName,
      };
      dispatch(onCreateZone({ type: zoneApiMethod.UPDATE, payload, callback: handleZonePageData }));
    }
  };

  const onAddZoneClick = () => {
    const payload = {
      ZoneId: 0,
      ZoneName: '',
    };
    dispatch(onCreateZone({ type: zoneApiMethod.ADD, payload, callback: handleZonePageData }));
  };

  const renderZoneDetails = () => {
    if (!(BoardPositionListData?.length > 0)) {
      return (
        <div style={{
          width: '100%',
          minHeight: '30vh',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderBottomLeftRadius: '6px',
          borderBottomRightRadius: '6px',
        }}
        >
          <img src={NoData} alt="No data found" />

        </div>
      );
    }
    return (
      <div style={{
        display: 'flex', flexDirection: 'row', gap: '2px', overflowY: 'hidden',
      }}
      >
        {BoardPositionListData?.map((element) => (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <div
              style={{
                border: '1px solid #00A751',
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                display: 'flex',
                flexDirection: 'row',
                backgroundColor: 'white',
                padding: '10px',
                alignItems: 'center',
                position: 'sticky',
                top: '0px',
              }}
            >
              {isEditing === element?.ZoneId
                ? (
                  <div style={{ width: '359px' }}>
                    <TextField
                      autoFocus
                      fullWidth
                      className="nonCapitalizeField"
                      inputProps={{ maxLength: 20 }}
                      size="small"
                      onBlur={(e) => handleBlurFunction(e.target.value)}
                      value={zoneName}
                      onChange={(e) => {
                        setZoneName(e?.target?.value);
                        setError(false);
                        setErrorMessage('');
                      }}
                      error={error}
                      helperText={errorMessage}
                      autoComplete="off"
                    />
                  </div>
                )
                : (
                  <>
                    <ZoneIcon />
                    <div
                      style={{
                        fontWeight: '700',
                        fontSize: '15px',
                        color: '#212529',
                        width: '201px',
                        margin: '0px 9px',
                      }}
                      onClick={() => handleEditClick(element?.ZoneId)}
                    >
                      {element?.ZoneName}

                    </div>
                    <Button
                      className="button_save_and_next"
                      style={{ width: '120px' }}
                      onClick={() => onAddAnalystClick(element?.ZoneId)}
                    >
                      Add Analyst
                    </Button>
                  </>
                )}
              <div />
            </div>
            <div style={{ maxHeight: '54vh', overflow: 'auto' }}>
              <DraggableTable
                data={element?.AnalystDetails}
                handleZonePageData={handleZonePageData}
                zoneId={element.ZoneId}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <ComponentPageHeader subHeading="Board Position" />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="div_job_container_info customBodyWithoutBtnContainer">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5} className="dataTableCustomMargin">
                <div style={{ backgroundColor: '#ffffff', padding: '20px' }}>
                  <div style={{ height: '40px' }}>
                    <div style={{
                      fontWeight: '600',
                      fontSize: '15px',
                      color: '#212529',
                    }}
                    >
                      General Zone
                    </div>
                  </div>
                  <div style={{
                    width: '100%', height: '2px', backgroundColor: '#E9EBEC', margin: '10px 0px',
                  }}
                  />
                  <DataTable<AnalystDataInterface>
                    data={FilteredAnalystData?.Data?.length > 0 ? formatData(FilteredAnalystData?.Data) : []}
                    totalRecords={FilteredAnalystData?.TotalRecords}
                    columns={BoardPositionColumn}
                    handleData={handleData}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div style={{ backgroundColor: '#ffffff', padding: '20px' }}>
                  <div style={{
                    height: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                  }}
                  >
                    <div style={{
                      fontWeight: '600',
                      fontSize: '15px',
                      color: '#212529',
                    }}
                    >
                      Zone
                    </div>
                    <Button onClick={onAddZoneClick} className="button_save_and_next">Add Zone</Button>
                  </div>
                  <div style={{
                    width: '100%', height: '2px', backgroundColor: '#E9EBEC', margin: '10px 0px 20px 0px',
                  }}
                  />
                  {renderZoneDetails()}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {showAnalystModal
        && (
          <AddAnalystModal
            show={showAnalystModal}
            setOpenFrom={() => setShowAnalystModal(false)}
            selectedZone={selectedZone}
            handleZonePageData={handleZonePageData}
          />
        )}
    </>
  );
}
