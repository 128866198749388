import { SiteListingDetails } from '../../../../utils/types';
import { SiteInfo, SitesData } from './Type';

export function formatSitesTableData(data: SiteListingDetails[]) {
  if (data.length === 0) {
    return [];
  }

  const res: SitesData[] = [];

  data.forEach((col: SiteListingDetails) => {
    const obj = {
      select: '',
      siteID: '',
      site: '',
      address: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      id: '',
    };

    obj.siteID = col?.SiteId;
    obj.site = col.SiteName;
    obj.address = col.Address;
    obj.city = col.City;
    obj.state = col.State;
    obj.id = col.SiteId;
    obj.country = col.Country;
    obj.zip = col?.Pincode;
    res.push(obj);
  });

  return res;
}

export function addSitePayload(values: SiteInfo) {
  return {
    SMC: values.smc,
    SiteName: values.site,
    Contact: values.contact,
    Address: values.address,
    OptionalAddress: values.optionalAddress,
    Pincode: values.zip || '',
    CityName: values.city === 'Other' ? values?.siteOtherCity : values.city,
    IsOtherCity: values?.city === 'Other',
    StateName: values.state,
    CountryCode: values.country,
    PhoneCode: values.phoneCode,
    Phone: values.phone,
    Cell: values.cell,
    SiteTypeId: values.siteType || 11,
    SmcId: values.SMCId,
    Note: values.note,
  };
}
