import { CustomerApiData, CustomerData } from './TypeCustomer';

export function formatTableData(data: CustomerApiData[]) {
  const res:CustomerData[] = [];

  data.forEach((col:CustomerApiData) => {
    const obj = {
      select: '',
      action: 'edit&delete',
      markUp: '',
      cust: '',
      company: '',
      address: '',
      city: '',
      state: '',
      phone: '',
      smc: '',
      id: '',
    };

    obj.markUp = col.Markup;
    obj.cust = col.Custid;
    obj.company = col.Customername;
    obj.address = col.Address;
    obj.city = col.CityName;
    obj.state = col.StateName;
    obj.phone = col.Phone;
    obj.smc = col.SmcName;
    obj.id = col.Custid;

    res.push(obj);
  });

  return res;
}
