/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';
import PhotosComp from './componentPhotos';

function ComponentPhotos() {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <>
      { reportPdfResponse3?.Photos?.map((data, index) => (
        <>
          { index > 0 && <div className="page-break" />}
          <PhotosComp vesselName={data.VesselName} imageUrl={data.PhotoPath} key={data.PhotoId} />
        </>
      ))}
    </>

  );
}

export default ComponentPhotos;
