/* eslint-disable max-len */
export const InventoryManagementTableColumns = [
  { key: 'product', label: 'Product#' },
  { key: 'description', label: 'Description' },
  { key: 'job', label: 'Job Order #' },
  { key: 'startDate', label: 'Start Date' },
  { key: 'endDate', label: 'End Date' },
  { key: 'analyst', label: 'Analyst' },
];

export const EquipmentRequestTableColumns = [
  { key: 'action', label: 'Action' },
  { key: 'job', label: 'Job Order #' },
  { key: 'vessel', label: 'Vessel' },
  { key: 'requestedBy', label: 'Requested By' },
];

export const InventoryTableColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'status', label: 'Status' },
  { key: 'equipmentType', label: 'Equipment Type' },
  { key: 'category', label: 'Category' },
  { key: 'product', label: 'Product#' },
  { key: 'description', label: 'Description' },
  { key: 'serial', label: 'Serial#' },
  { key: 'issuesTo', label: 'Issued To' },
];

export const InventoryIssueHistoryColumns = [
  { key: 'sn', label: 'SN' },
  { key: 'analyst', label: 'Analyst' },
  { key: 'issued', label: 'Issued' },
  { key: 'returned', label: 'Returned' },
];

export enum InventoryFormFields {
  discarded = 'discarded',
  status = 'status',
  assignedFor = 'assignedFor',
  serialised = 'serialised',
  product = 'product',
  material = 'material',
  description = 'description',
  uns = 'uns',
  onHand = 'onHand',
  serial = 'serial',
  category = 'category',
  equipmentType = 'equipmentType',
  location = 'location',
  specification = 'specification',
}

export enum InventoryIssueEnum {
  serial = 'serial',
  analyst = 'analyst',
  product = 'product',
  issuedDate = 'issuedDate',
  description = 'description',
}
export const REQUEST = 1;
export const EQUIPMENT_NEEDED = 2;
export const HISTORY = 3;

export const sendEquipmentType = {
  SEND_EQUIPMENT: 1,
  RECEIVE_EQUIPMENT: 2,
};

export const inventoryContent = {
  sendEquipment: 'Parked Equipment requests are visible here. Admin has the right to approve/disapprove it and based on the approval it can be assigned to the analyst for a Job.',
  receivedEquipment: 'A list of all the issued Equipment is visible here. Admin reserves the right to raise a receive request from the analyst as well. When Tai Services office receives the equipment, it will be added back to the available inventory.',
  inventory: 'A List of the products available and discarded from the inventory is visible here. Admin reserves the right to discard or delete a product from the inventory. Additionally, Add, Issue & Return functionality is visible under the module.',
};
