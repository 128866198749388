import { Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OTPInput from 'otp-input-react';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import taiLogo from '../../../assets/tailogo.png';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import EndPoints from '../../../Routes/EndPoints';
import ComponentLeftVector from '../../../Common/LeftVector/ComponentLeftVector';
import { TypeResendTimer } from './utils/types';
import { stateReset, sendOtpData, errorSet } from './redux/sliceOtpVerify';
import { RootState } from '../../../redux/rootState';
import StorageUtils from '../../../utils/StorageUtils';
import constants from '../../../utils/SessionConstants';
import { OtpSendSuccess, sendOtpVerifyData } from '../ScreenLogIn/redux/sliceLogin';
import LocalStorageUtils from '../../../utils/LocalStorageUtils';

function ResendTimer({ seconds, TenSeconds, minutes }: TypeResendTimer): JSX.Element {
  if (seconds < TenSeconds) {
    return (
      <Typography variant="body1" color={grey[900]}>
        0
        {minutes}
        :0
        {seconds}
      </Typography>
    );
  }
  return (
    <Typography variant="body1" color={grey[900]}>
      0
      {minutes}
      :
      {seconds}
    </Typography>
  );
}

export default function ScreenOtpVerify() {
  const navigate = useNavigate();
  const { translations } = useContext(LocalizationContext);
  const [OTP, setOTP] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(1);
  const dispatch = useDispatch();
  const TenSeconds = 10;
  const {
    otpDataSuccess, isSuccess, isError, errorMessage,
  } = useSelector((state: RootState) => state.otpReducer);

  const { otpSent } = useSelector((state: RootState) => state.authReducer);

  const EmailId = StorageUtils.getString(constants.EMAIL_ID);

  const handleVerify = () => {
    const body = {
      EmailId,
      OTP,
    };
    dispatch(sendOtpData(body));
  };

  useEffect(() => {
    if (isSuccess) {
      LocalStorageUtils(otpDataSuccess);
      StorageUtils.removeString(constants.EMAIL_ID);
      navigate(EndPoints.DASHBOARD);
      dispatch(stateReset());
    }
  }, [isSuccess]);

  const handleChange = () => {
    navigate(EndPoints.LOGIN_PAGE);
  };
  const handleEnable = () => {
    const body = {
      EmailId,
      OtpType: 'LoginOTP',
    };
    dispatch(sendOtpVerifyData(body));
  };

  useEffect(() => {
    if (otpSent) {
      setMinutes(2);
      setSeconds(1);
      setButtonDisable(true);
      dispatch(OtpSendSuccess());
    }
  }, [otpSent]);

  const otpHandleChange = (otpValue: string) => {
    setOTP(otpValue);
    dispatch(errorSet());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setButtonDisable(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (OTP.length === 6) {
      (document.getElementById('submitBtn') as HTMLFormElement).focus();
    }
  }, [OTP]);

  return (
    <div className="div-password">
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <ComponentLeftVector />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <div className="mobStyle">
            <div className="rightContainer">
              <img src={taiLogo} alt="TAI Logo" className="taiLogo" />
              <div className="loginContainer">
                <h2>{translations.otpVerify}</h2>
                <div className="divContainer">
                  {isError ? <div className="wrongOPT">{isError ? errorMessage : ''}</div>
                    : (
                      <>
                        <Typography variant="body1" color="textSecondary">{translations.verificationCode}</Typography>
                        <div className="div_button_change">
                          <Typography variant="body1">{EmailId}</Typography>
                          <Button className="changeButton" variant="text" onClick={handleChange}>{translations.change}</Button>
                        </div>
                      </>
                    )}
                </div>
                <div className="otpSection">
                  <OTPInput
                    value={OTP}
                    inputClassName={isError ? 'otp_error' : ''}
                    onChange={otpHandleChange}
                    autoFocus
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                  />
                </div>
                {/* <div className="errorMessage">{isError ? errorMessage : ''}</div> */}
                <div className="div_button_resend">
                  <Button variant="text" className="reset_button-style" disabled={buttonDisable} onClick={handleEnable}>
                    {translations.resendOtp}
                  </Button>
                  <ResendTimer seconds={seconds} minutes={minutes} TenSeconds={TenSeconds} />
                </div>
                <div className="disabledButtonStyle">
                  <LoadingButton
                    variant="contained"
                    id="submitBtn"
                    fullWidth
                    className="button-color"
                    onClick={handleVerify}
                    disabled={OTP?.length < 6}
                  >
                    {translations.verify}
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
