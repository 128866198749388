export interface DeactivateUserModalPropTypes {
  show: boolean,
  setOpenFrom: () => void,
  rowId?: string,
  handleDeactivate: () => void,
}

export interface AddUserManagementTypes {
  show: boolean,
  setOpenFrom: () => void,
  rowId?: string,
  handleCallback: () => void
}

export interface AddUserManagementFields {
  userId: string | undefined,
  initials: string | undefined,
  password: string | undefined,
  role: string | undefined,
  analyst: string | undefined,
}

export enum AddUserManagementFieldsEnum {
  userId = 'userId',
  initials = 'initials',
  password = 'password',
  role = 'role',
  analyst = 'analyst',
}
