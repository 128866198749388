import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { Cancel } from '../../../media/images/Cancel';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { TypesCustomerSearchModal } from '../Utils/TypeSmc';
import DataTable from '../../../Common/DataTable';
import '../Style/StyleSmc.scss';
import { RootState } from '../../../redux/rootState';
import { getUserListStart, sendAddSmcUsersData } from '../Redux/sliceAddSmc';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};

export interface TypeUserList {
  select: string;
  name: string;
  role: string;
  email: string;
  id: string;
}

export default function AddUsers({ show, setOpenFrom }: TypesCustomerSearchModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('');
  const dispatch = useDispatch();
  const { userList, totalUsers, smcId } = useSelector((state: RootState) => state.addSmcDetailsReducer);
  const { userId } = useSelector((state: RootState) => state.otpReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getUserListStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const { translations } = useContext(LocalizationContext);
  const columns = useMemo(
    () => [
      { key: 'select', label: translations.select },
      { key: 'name', label: translations.name },
      { key: 'role', label: translations.role },
      { key: 'email', label: translations.emailId },
    ],
    [],
  );
  const data: TypeUserList[] = useMemo(() => userList.map((item) => {
    const transformedDataItem = {
      name: item?.FirstName,
      role: item?.RoleName,
      email: item?.EmailId,
      select: '',
      id: item?.UserId,
    };
    return transformedDataItem;
  }), [userList]);

  const handleClose = () => {
    setOpenFrom();
    setSearchQuery('');
    setPage(1);
    setRowsPerPage(10);
    setSelectedRadio('');
  };

  const handleSave = () => {
    if (!selectedRadio) return;
    handleClose();
    const bodyPayload = {
      SmcUserId: String(selectedRadio),
      SmcId: String(smcId),
      LoginUserId: String(userId),
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(sendAddSmcUsersData(bodyPayload));
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">Employee Details</div>
            <div className="close_modal" onClick={handleClose}>
              <Cancel />
            </div>
          </div>
          <DataTable<TypeUserList>
            data={data}
            columns={columns}
            totalRecords={totalUsers}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            radioButtonColumns={['select']}
          />
          {data.length > 0 && (
            <div className="modal_footer">
              <div className="footer_button_sec">
                <button type="button" className="popup_cancel_btn" onClick={handleClose}>Cancel</button>
                <button type="button" className="popup_submit_btn" onClick={handleSave}>Select</button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
