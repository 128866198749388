import React from 'react';
import { FieldErrors } from 'react-hook-form';
import CalenderIcon from '../../assets/CalenderIcon.svg';
import CalenderRedIcon from '../../assets/CalenderRedIcon.svg';

function NewCalenderIcon({ errors, name }: { errors: FieldErrors, name: string }) {
  return errors[name] ? (
    <img src={CalenderRedIcon} alt="Icon" width={25} height={25} />
  ) : (
    <img src={CalenderIcon} alt="Icon" width={25} height={25} />
  );
}

export default NewCalenderIcon;
