import {
  Button, Card, Grid, TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequiredFiled from '../../../../utils/RequiredField';
import { BayLengthEnum, BayLengthFormDetails, UpperGridProps } from '../../utils/type';
import ConfirmationModal from '../../../../Common/ConfirmationModal/ConfirmationModal';
import { ConfirmationIcon } from '../../../../assets/Icons/AnalystIcons/ConfirmationIcon';
import { setBayLengthDetails, setIsBayLengthChanged } from '../../redux/baylength/baylengthSlice';
import { RootState } from '../../../../redux/rootState';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';

export function UpperGrid({ setShowPipeStructureComponent }:UpperGridProps) {
  const { bayLengthDetails, baylengthData, isBayLengthChanged } = useSelector((state:RootState) => state.BayLengthReducer);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [supports, setSupports] = useState('');
  const [tubeLength, setTubeLength] = useState('');
  const dispatch = useDispatch();
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  useEffect(() => {
    if (baylengthData?.Support) {
      setSupports(baylengthData?.Support);
      setTubeLength(baylengthData?.TubeLength);
    }
  }, [baylengthData?.Support, baylengthData?.TubeLength]);

  const onFinalSubmit = () => {
    // if baylength details changed only then recalculate the baylength details
    if (isBayLengthChanged) {
      const len = (Number(supports) || 0) + 1;
      const value = (Number(tubeLength) / len);
      const temp = Array(len).fill(value);
      const details:BayLengthFormDetails = {
        bayLengthSupport: supports,
        bayLengthTubes: tubeLength,
        spacingValues: temp,
      };
      dispatch(setBayLengthDetails(details));
    }
    setShowPipeStructureComponent(true);
    setShowConfirmationModal(false);
    dispatch(setIsBayLengthChanged(false));
  };

  const handleGenerate = () => {
  // if isBayLengthChanged change then show the popup else open the pipe component
    if (isBayLengthChanged) {
      setShowConfirmationModal(true);
    } else {
      onFinalSubmit();
    }
  };

  const handleConfirmationClose = () => {
    setSupports(bayLengthDetails?.bayLengthSupport || '');
    setTubeLength(bayLengthDetails?.bayLengthTubes || '');
    setShowConfirmationModal(false);
  };

  const handleSupportChange = (value:string) => {
    const regex = /^(?:[1-9]|1\d|2\d|30)$/;
    if (value.trim()?.length > 0 && !regex.test(value)) {
      return;
    }
    setSupports(value);
    dispatch(setIsBayLengthChanged(true));
  };

  const handleTubeLengthChange = (value:string) => {
    const regex = /^\d{0,4}$/;
    if (value.trim()?.length > 0 && !regex.test(value)) {
      return;
    }
    setTubeLength(value);
    dispatch(setIsBayLengthChanged(true));
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                #Supports
                <RequiredFiled />
              </label>
              <TextField
                name={BayLengthEnum.support}
                size="small"
                disabled={roleId === ANALYST_ROLE_ID}
                defaultValue=""
                value={supports}
                onChange={(e) => {
                  handleSupportChange(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={roleId === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Tube Length
                <RequiredFiled />
              </label>
              <TextField
                name={BayLengthEnum.tubeLength}
                size="small"
                disabled
                value={tubeLength}
                defaultValue=""
                onChange={(e) => {
                  handleTubeLengthChange(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <div className="div_label_text">
              <label style={{ visibility: 'hidden' }} className={roleId === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Generate Cross-Section
              </label>
              <Button
                className="button_save_and_next"
                onClick={handleGenerate}
                disabled={(roleId === ANALYST_ROLE_ID) || (supports === '' || tubeLength === '')}
              >
                Generate Cross-Section
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {
        showConfirmationModal && (
          <ConfirmationModal
            confirmationIcon={<ConfirmationIcon />}
            show={showConfirmationModal}
            setOpenFrom={handleConfirmationClose}
            handleConfirmation={onFinalSubmit}
            text="Changing the values will clear the Bay Length diagram and set it to default, Are you sure you want to proceed?"
          />
        )
      }
    </Card>
  );
}
