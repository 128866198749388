import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SitesListResult, PaginationResponse, PayloadTypeDeleteSites, PayloadTypeGetSitesStart,
  SitesListState, SiteType, PayloadTypeGetSiteDetailsById, SiteDetailsByIdResult, PayloadUpdateSite, CustomerData,
} from '../utils/types';

const initialState: SitesListState = {
  siteList: [] as SitesListResult[],
  siteTypeData: [] as SiteType[],
  totalEntries: 0,
  isLoading: false,
  error: '',
  siteDetailsById: {} as SiteDetailsByIdResult,
  customersList: [] as CustomerData[],
};

const siteSlice = createSlice({
  name: 'sitesModule',
  initialState,
  reducers: {
    getSitesStart(state, _action: PayloadAction<PayloadTypeGetSitesStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSites(state, action: PayloadAction<PaginationResponse<SitesListResult>>) {
      return {
        ...state,
        isLoading: false,
        siteList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureGetSites(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // --------------------------------------------------> delete api
    deleteSiteStart(state, _action: PayloadAction<PayloadTypeDeleteSites>) {
      return {
        ...state,
        isLoading: true,
        deletingRow: _action.payload,
      };
    },
    successDeleteSite(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureDeleteSite(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    getSiteTypeStart(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    getSiteTypeSuccess(state, action: PayloadAction<SiteType[]>) {
      return {
        ...state,
        isLoading: false,
        siteTypeData: action.payload,
      };
    },
    getSiteTypeFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    getSiteByIdStart(state, _action: PayloadAction<PayloadTypeGetSiteDetailsById>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSiteById(state, action: PayloadAction<SiteDetailsByIdResult>) {
      return {
        ...state,
        isLoading: false,
        siteDetailsById: action?.payload as SiteDetailsByIdResult,
      };
    },
    failureGetSiteById(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    updateSiteByIdStart(state, _action: PayloadAction<PayloadUpdateSite>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successUpdateSiteById(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureUpdateSiteById(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    getCustomersStart(state, _action: PayloadAction<PayloadTypeGetSitesStart>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetCustomers(state, action: PayloadAction<PaginationResponse<CustomerData>>) {
      return {
        ...state,
        isLoading: false,
        customersList: action?.payload.Data,
        totalEntries: action.payload.TotalRecords,
      };
    },
    failureGetCustomers(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    resetSiteState() {
      return initialState;
    },
  },
});

export const {
  getSitesStart, successGetSites, failureGetSites, deleteSiteStart, successDeleteSite, failureDeleteSite,
  getSiteTypeStart, getSiteTypeSuccess, getSiteTypeFailure, getSiteByIdStart, successGetSiteById, failureGetSiteById,
  updateSiteByIdStart, successUpdateSiteById, failureUpdateSiteById, getCustomersStart, successGetCustomers, failureGetCustomers, resetSiteState,
} = siteSlice.actions;
export const siteReducer = siteSlice.reducer;

export type SitesActions =
  | ReturnType<typeof getSitesStart>
  | ReturnType<typeof successGetSites>
  | ReturnType<typeof failureGetSites>
  | ReturnType<typeof deleteSiteStart>
  | ReturnType<typeof successDeleteSite>
  | ReturnType<typeof failureDeleteSite>
  | ReturnType<typeof getSiteTypeStart>
  | ReturnType<typeof getSiteTypeSuccess>
  | ReturnType<typeof getSiteTypeFailure>
  | ReturnType<typeof getSiteByIdStart>
  | ReturnType<typeof successGetSiteById>
  | ReturnType<typeof failureGetSiteById>
  | ReturnType<typeof updateSiteByIdStart>
  | ReturnType<typeof successUpdateSiteById>
  | ReturnType<typeof failureUpdateSiteById>
  | ReturnType<typeof getCustomersStart>
  | ReturnType<typeof successGetCustomers>
  | ReturnType<typeof failureGetCustomers>
  | ReturnType<typeof resetSiteState>;
