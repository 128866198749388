/* eslint-disable max-len */
import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Grid, Card, TextField, IconButton, Tooltip,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { useParams } from 'react-router-dom';
import CustomCheckbox from '../../../../../Common/ComponentCustomCheckBox';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { RootState } from '../../../../../redux/rootState';
import { RequestInformationInterface } from '../../utils/types';
import { RequestFormFields } from '../../utils/constants';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import RequiredFiled from '../../../../../Common/RequiredField';
import SelectJobModal from '../SelectJobModal/SelectJobModal';
import {
  getEquipmentDetailsByRequestIdStart, getEquipmentMasterStart, getEquipmentMasterStatusStart, setComment,
} from '../../redux/equipmentRequestSlice';
import { getUnitByIdStart, resetUnitInfo } from '../../../../Job/ScreenUnitInformation/redux/unitInfoSlice';
import { setLoader } from '../../../../../redux/loaderSlice';
import { Entries } from '../../../InventoryLandingPage/redux/type';
import ComponentRequestMiddleForm from '../ComponentRequestMiddleForm/ComponentRequestMiddleForm';
import { getUnitMasterStart } from '../../../../Job/ScreenTestEquipmentRequest/redux/sliceTestEquipments';

export default function ComponentRequest({
  control, errors, setValue, reset,
}: RequestInformationInterface): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { requestId } = useParams();

  const {
    inventoryEquipmentMasterData,
    selectedRowId, equipmentDetailsLoading, equipmentDetailsByRequestIdData,
    inventoryEquipmentMasterStatusData,
  } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);
  const { manufacturerData } = useSelector((state: RootState) => state.equipmentReducer);
  const { selectedUnit } = useSelector((state: RootState) => state.unitInfoReducer);

  useEffect(() => () => {
    dispatch(getEquipmentMasterStatusStart());
    dispatch(resetUnitInfo());
  }, []);

  useEffect(() => {
    if (selectedUnit?.UnitInfo && setValue) {
      const unitManufacturer = manufacturerData?.find((ele) => Number(ele?.Value) === Number(selectedUnit?.UnitInfo?.ManufacturerId));
      setValue(RequestFormFields.safetyEquipmentRequired, selectedUnit?.UnitInfo?.SafetyEquipmentRequired);
      setValue(RequestFormFields.outside, selectedUnit?.UnitInfo?.Outside);
      setValue(RequestFormFields.flux, selectedUnit?.UnitInfo?.TestType === 'FLT');
      setValue(RequestFormFields.absDiff, selectedUnit?.UnitInfo?.TestType === 'ADT');
      setValue(RequestFormFields.crsDiff, selectedUnit?.UnitInfo?.TestType === 'ECT');
      setValue(RequestFormFields.tubeLength, String(selectedUnit?.UnitPricingInfo?.TotalTubeLength));
      setValue(RequestFormFields.tubeCount, String(selectedUnit?.UnitPricingInfo?.TotalTubeQty));
      setValue(RequestFormFields.serialId, selectedUnit?.UnitInfo?.SerialNo);
      setValue(RequestFormFields.model, selectedUnit?.UnitInfo?.Model);
      if (unitManufacturer?.Text) {
        setValue(RequestFormFields.manufacturer, unitManufacturer?.Text);
      }
    }
  }, [selectedUnit]);

  const onSelect = () => {
    if (selectedRowId !== '') {
      dispatch(getUnitByIdStart(String(selectedRowId)));
      dispatch(getEquipmentMasterStart(selectedRowId));
      if (setValue) {
        setValue(RequestFormFields.jobId, selectedRowId);
      }
    }
  };

  useEffect(() => {
    dispatch(setLoader(equipmentDetailsLoading));
  }, [equipmentDetailsLoading]);

  const openJobModal = () => {
    if (!requestId) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (requestId) {
      dispatch(getUnitMasterStart());
      dispatch(getEquipmentDetailsByRequestIdStart(requestId));
      return;
    }
    reset();
  }, [requestId]);

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    if (setValue && equipmentDetailsByRequestIdData?.RequestId && inventoryEquipmentMasterData?.Vessel?.length > 0) {
      const unitManufacturer = inventoryEquipmentMasterStatusData?.find((ele: Entries) => Number(ele?.Value) === Number(equipmentDetailsByRequestIdData?.Status));
      const getVessel = inventoryEquipmentMasterData?.Vessel?.find((ele) => Number(ele?.Value) === Number(equipmentDetailsByRequestIdData?.VesselId));
      if (getVessel?.Value) {
        setValue(RequestFormFields.vessel, Number(equipmentDetailsByRequestIdData?.VesselId));
      }
      setValue(RequestFormFields.jobId, equipmentDetailsByRequestIdData?.UnitId);
      setValue(RequestFormFields.stage, unitManufacturer?.Text);
      setValue(RequestFormFields.tubeType, equipmentDetailsByRequestIdData?.TubeId);
      setValue(RequestFormFields.workHrs, equipmentDetailsByRequestIdData?.WorkHours);
      setValue(RequestFormFields.material, equipmentDetailsByRequestIdData?.MaterialId);
      setValue(RequestFormFields.OD, equipmentDetailsByRequestIdData?.OuterDiameter);
      setValue(RequestFormFields.ID, equipmentDetailsByRequestIdData?.InnerDiameter);
      setValue(RequestFormFields.retestOf, equipmentDetailsByRequestIdData?.RetestOf);
      setValue(RequestFormFields.NWT, equipmentDetailsByRequestIdData?.Nwt);
      setValue(RequestFormFields.NWTBellLand, equipmentDetailsByRequestIdData?.NwtBellLand);
      setValue(RequestFormFields.NWTUnderFins, equipmentDetailsByRequestIdData?.NwtUnderfils);
      if (equipmentDetailsByRequestIdData?.AdminComments) {
        setValue(RequestFormFields.note, equipmentDetailsByRequestIdData?.AdminComments);
      }
      setValue(RequestFormFields.vertical, true);
      const convertedNote = htmlToEditorState(equipmentDetailsByRequestIdData?.Notes);
      dispatch(setComment(convertedNote));
      return;
    }
    if (setValue) {
      setValue(RequestFormFields.stage, 'New');
    }
  }, [equipmentDetailsByRequestIdData, inventoryEquipmentMasterData?.Vessel]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <div className="customerInfo_container">
              <div className="inline_checkbox_in_a_row">
                <CustomCheckbox isDisable={!!requestId} name={RequestFormFields.vertical} control={control} label="Vertical" />
                <CustomCheckbox isDisable name={RequestFormFields.safetyEquipmentRequired} control={control} label="Safety Equipment Required" />
                <CustomCheckbox isDisable name={RequestFormFields.outside} control={control} label="Outside" />
                <CustomCheckbox isDisable name={RequestFormFields.flux} control={control} label="Flux" />
                <CustomCheckbox isDisable name={RequestFormFields.absDiff} control={control} label="Abs/Diff" />
                <CustomCheckbox isDisable name={RequestFormFields.crsDiff} control={control} label="Crs/Diff" />
              </div>
            </div>
            <div className="customerInfo_main_container">
              <Grid container spacing={2}>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_disabled">Stage</label>
                    <ComponentTextInput
                      control={control}
                      isDisable
                      errors={errors}
                      name={RequestFormFields.stage}
                      id={RequestFormFields.stage}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_disabled">
                      Job Order #
                      <RequiredFiled />
                    </label>
                    <Controller
                      name={RequestFormFields.jobId}
                      control={control}
                      render={({ field: { value } }) => (
                        <TextField
                          size="small"
                          value={value}
                          autoComplete="off"
                          required
                          disabled={!!requestId}
                          InputProps={{
                            endAdornment: (
                              <Tooltip title={requestId ? '' : translations.searchHere}>
                                <IconButton onClick={openJobModal}>
                                  <SearchIcon />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_disabled">Manufacturer</label>
                    <ComponentTextInput
                      name={RequestFormFields.manufacturer}
                      control={control}
                      isDisable
                      id={RequestFormFields.manufacturer}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_disabled">Serial #</label>
                    <ComponentTextInput
                      control={control}
                      errors={errors}
                      isDisable
                      inputProps={{ maxLength: 255 }}
                      name={RequestFormFields.serialId}
                      id={RequestFormFields.serialId}
                      size="small"
                    />
                  </div>
                </Grid>
                <Grid item md={2} sm={6} xs={12}>
                  <div className="div_label_text">
                    <label className="label_Style_disabled">Model</label>
                    <ComponentTextInput
                      control={control}
                      errors={errors}
                      isDisable
                      inputProps={{ maxLength: 255 }}
                      name={RequestFormFields.model}
                      id={RequestFormFields.model}
                      size="small"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <ComponentRequestMiddleForm control={control} errors={errors} />
        </Grid>
      </Grid>
      {showModal
        && (
          <SelectJobModal
            show={showModal}
            setOpenFrom={() => setShowModal(false)}
            onSelect={onSelect}
          />
        )}
    </>
  );
}
