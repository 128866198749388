import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from '../../../../../Common/DataTable';
import { RootState } from '../../../../../redux/rootState';
import { setLoader } from '../../../../../redux/loaderSlice';
import { formatEquipmentNeededData } from '../../../../InventoryManagement/TestEquipmentRequestScreen/utils/helper';
import { EquipmentNeededKeyData } from '../../../../InventoryManagement/TestEquipmentRequestScreen/utils/types';
import { getEquipmentNeededDataStart } from '../../../../InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import localStorageConstants from '../../../../../utils/LocalStorageConstants';
import { ADMIN_ROLE_ID } from '../../../../../Routes/constant';
import AddEquipmentModal from '../../../../InventoryManagement/TestEquipmentRequestScreen/Components/AddEquipmentModal/AddEquipmentModal';
import UnlinkComponentModal from '../../../../../Common/UnlinkComponentModal/UnlinkComponentModal';
import { sendAndReceiveEquipmentType } from '../../../../InventoryManagement/SendEquipmentScreen/utils/constants';
import { sendEquipmentStart } from '../../../../InventoryManagement/SendEquipmentScreen/redux/sendEquipmentScreenSlice';
import { ADMIN_REQUEST } from '../../../ScreenUnitInformation/utils/constants';

export default function ComponentEquipmentNeed() {
  const { reqId, statusId } = useParams();
  const dispatch = useDispatch();
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { equipmentNeededData, equipmentNeededLoading } = useSelector((state: RootState) => state.inventoryEquipmentRequestReducer);
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [linkOfId, setLinkOfId] = useState('');

  const handleData = () => {
    const params = {
      requestId: reqId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getEquipmentNeededDataStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const getAddEquipmentButton = () => {
    if (Number(roleId) === ADMIN_ROLE_ID || Number(statusId) === ADMIN_REQUEST) {
      return 'Add';
    }
    return '';
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'status', label: 'Status' },
      { key: 'product', label: 'Product #' },
      { key: 'category', label: 'Category' },
      { key: 'description', label: 'Description' },
      { key: 'serial', label: 'Serial #' },
    ],
    [],
  );

  const FOcolumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'status', label: 'Status' },
      { key: 'product', label: 'Product #' },
      { key: 'category', label: 'Category' },
      { key: 'description', label: 'Description' },
      { key: 'serial', label: 'Serial #' },
    ],
    [],
  );

  const handleLinkOff = (id: string) => {
    setLinkOfId(id);
    setShowUnlinkModal(true);
  };

  const unlinkCallback = () => {
    setShowUnlinkModal(false);
    handleData();
  };

  const handleUnlink = () => {
    dispatch(sendEquipmentStart({
      type: sendAndReceiveEquipmentType.UNLINK,
      callback: unlinkCallback,
      payload: {
        requestId: reqId,
        productId: linkOfId,
      },
    }));
  };

  useEffect(() => {
    dispatch(setLoader(equipmentNeededLoading));
  }, [equipmentNeededLoading]);

  return (
    <>
      <DataTable<EquipmentNeededKeyData>
        data={equipmentNeededData?.Data?.length > 0 ? formatEquipmentNeededData(equipmentNeededData?.Data) : []}
        columns={roleId === '2' ? FOcolumns : columns}
        totalRecords={equipmentNeededData?.TotalRecords}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        handleData={handleData}
        setSearchQuery={setSearchQuery}
        statusColumnsWithKey={['status']}
        buttonText={getAddEquipmentButton()}
        handleButtonClick={() => setShowAddEquipmentModal(true)}
        handleLinkOff={handleLinkOff}
      />
      {
        showAddEquipmentModal && (
          <AddEquipmentModal
            show={showAddEquipmentModal}
            setOpenFrom={() => setShowAddEquipmentModal(false)}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            testEquipmentReqId={reqId}
            typeId={1}
            equipmentPage={page}
            equipmentRowsPerPage={rowsPerPage}
            equipmentSearchQuery={searchQuery}
          />
        )
      }
      {showUnlinkModal && (
        <UnlinkComponentModal
          handleUnlink={handleUnlink}
          show={showUnlinkModal}
          setOpenFrom={() => setShowUnlinkModal(false)}
        />
      )}
    </>
  );
}
