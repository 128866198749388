import {
  Grid, Card, TextField, MenuItem, Select,
} from '@mui/material';
import React from 'react';

export default function ComponentClient() {
  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Client
        </div>
        <div className="container_job_search">
          <span className="customerID">Customer #</span>
          <div className="job_input_div">
            <input className="job_input_style" />
          </div>
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Company</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Address (Optional)</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Zip</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">State</label>
              <Select
                size="small"
                fullWidth
              >
                <MenuItem value={1}>Uttar Pradesh</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={4.5} sm={4.5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">City</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <Select
                size="small"
              >
                <MenuItem value={1}>+1</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div style={{ padding: '20px 0px' }}>
              <div className="contact_info_text">Contact Information(Contractor)</div>
            </div>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Contact</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Cell</label>
              <TextField size="small" />
            </div>
          </Grid>
          <Grid item md={2} sm={2} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Code</label>
              <Select
                size="small"
              >
                <MenuItem value={1}>+1</MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_job">Phone</label>
              <TextField size="small" />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
