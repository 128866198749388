/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import TubeChartLayout from './tubeChartLayout';

function TubeBundleLayoutSection() {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <>
      {
        reportPdfResponse3?.Tube_Bundle_Layout?.map((tubeDetals) => (
          tubeDetals?.map((details) => (
            <>
              <TubeChartLayout tubeDetails={details} serialNumber={`S/N ${reportPdfResponse3?.JobOrderData?.SerialNumber}`} />
              <div className="page-break" />
            </>
          ))
        ))
}
    </>

  );
}

export default TubeBundleLayoutSection;
