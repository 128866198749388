import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useParams } from 'react-router-dom';
import { SiteForm, assignSiteDetailsValues } from '../utils/helpers';
import ComponentEditSiteMethods from './ComponentEditSiteMethods';
import { MainFormValues } from '../utils/types';
import { mainFormSchema } from '../utils/validations';
import { MainDefaultValue } from '../utils/constants';
import { setLoader } from '../../../redux/loaderSlice';
import { RootState } from '../../../redux/rootState';
import { getSiteByIdStart, getSiteTypeStart, resetSiteState } from '../redux/sliceSites';
import { getPinCodeDetailsStart, removePinCodeDetails } from '../../../redux/masters/masterSlice';

function ComponentEditSite() {
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const { siteDetailsById, isLoading } = useSelector((state: RootState) => state.siteReducer);
  const { pinCodeDetails } = useSelector((state: RootState) => state.masterDataReducer);

  const {
    control, handleSubmit, formState: { errors }, watch, setValue, trigger, getValues,
  } = useForm<MainFormValues>({
    resolver: yupResolver(mainFormSchema as ObjectSchema<MainFormValues>),
    defaultValues: MainDefaultValue,
  });

  const siteZip = watch(SiteForm.zip);
  const countryName = watch(SiteForm.country);

  useEffect(() => {
    if (siteZip && siteZip?.length >= 5) {
      dispatch(removePinCodeDetails());
      setValue(SiteForm.state, '');
      // setValue(SiteForm.city, '');
      const queryPayload = {
        pinCode: siteZip,
        countryCode: getValues(SiteForm.country),
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
    } else if (siteZip?.length < 5) {
      dispatch(removePinCodeDetails());
      setValue(SiteForm.state, '');
      // setValue(SiteForm.city, '');
    }
  }, [siteZip, countryName]);

  useEffect(() => {
    if (pinCodeDetails?.City && siteZip) {
      setValue(SiteForm.state, pinCodeDetails.StateName);
      trigger([SiteForm.state]);
    }
  }, [pinCodeDetails]);

  useEffect(() => {
    if (siteId) {
      const payload = {
        siteId: String(siteId),
      };
      dispatch(getSiteByIdStart(payload));
    }
    return () => {
      dispatch(resetSiteState());
    };
  }, [siteId]);

  useEffect(() => {
    if (siteDetailsById) {
      assignSiteDetailsValues(siteDetailsById, setValue);
    }
  }, [siteDetailsById]);

  useEffect(() => {
    dispatch(getSiteTypeStart());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <ComponentEditSiteMethods control={control} errors={errors} watch={watch} setValue={setValue} handleSubmit={handleSubmit} />
  );
}

export default ComponentEditSite;
