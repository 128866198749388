import { ProgramColumn } from './types';

export const ReportWorkspaceTableColumns = [
  { key: 'sn', label: '#' },
  { key: 'jobOrder', label: 'Job Order #' },
];

// Data for Combination Report
export const combinationReportColumns: ProgramColumn[] = [
  {
    id: 13,
    displayName: 'Header with Logo',
    name: 'headerWithLogo',
    value: true,
  },
  {
    id: 14,
    displayName: 'Header w/o Logo',
    name: 'headerWithoutLogo',
    value: true,
  },
  {
    id: 15,
    displayName: 'Table Of Contents',
    name: 'tableOfContents',
    value: true,
  },
  {
    id: 16,
    displayName: 'Calibration Procedure',
    name: 'calibrationProcedure',
    value: true,
  },
  {
    id: 17,
    displayName: 'Explanation of Abbreviations',
    name: 'explanationOfAbbreviations',
    value: true,
  },
  {
    id: 18,
    displayName: 'Inspection Procedures',
    name: 'inspectionProcedures',
    value: true,
  },
];

// Data for Job Orders
export const jobOrderColumns: ProgramColumn[] = [
  {
    id: 1,
    displayName: 'Vessel Information',
    name: 'vesselInfo',
    value: false,
    innerCheck: [
      {
        id: 100, displayName: 'Column 2', name: 'column2', value: false,
      },
      {
        id: 200, displayName: 'Column 2', name: 'column2', value: false,
      },
      {
        id: 300, displayName: 'Column 3', name: 'column3', value: true,
      },
    ],
  },
  {
    id: 2, displayName: 'Defect Graph', name: 'defectGraph', value: false,
  },
  {
    id: 3, displayName: 'Summary of Inspection', name: 'summaryInspection', value: false,
  },
  {
    id: 4, displayName: 'Data Sheets', name: 'dataSheets', value: false,
  },
  {
    id: 5, displayName: 'Tube Charts', name: 'tubeCharts', value: false,
  },
  {
    id: 6, displayName: 'Photos', name: 'photos', value: false,
  },
  {
    id: 7, displayName: 'Strip charts', name: 'stripCharts', value: false,
  },
  {
    id: 8, displayName: 'Phase charts', name: 'phaseCharts', value: false,
  },
  {
    id: 9, displayName: 'Expense Report', name: 'expenseReport', value: false,
  },
  {
    id: 10, displayName: 'Job Order', name: 'jobOrder', value: false,
  },
  {
    id: 11, displayName: 'Hazard Analysis', name: 'hazardAnalysis', value: false,
  },
  {
    id: 12, displayName: 'Recommendations', name: 'recommendations', value: false,
  },
];
