import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddSmcDetailsTypes, AddSmcFormInput, PayloadTypeUnlinkSmc, PayloadTypeUnlinkSmcSite,
  PayloadTypeUnlinkSmcUser, PayloadTypeGetSmcSiteList, PayloadTypeGetSmcCustomerList,
  PayloadTypeGetSmcUserList, PayloadTypeGetUserList, UserDataResult, SmcUserTypeApiBody,
  PayloadTypeGetSmcUserByIdList, SmcUserListByIdResult, GetSmcCustomerListResult, UpdateSmcPayload, PaginationResponse,
} from '../Utils/TypeSmc';
import { DETAILS_TAB, USERS_TAB } from '../Utils/ConstantSmc';

interface SmcUserTabListResult {
  UserId: string;
  FirstName: string;
  LastName: string;
  Email: string;
}

interface GetSmcSitesListResult {
  SiteId: string;
  SiteName: string;
  Address: string;
  City: string;
  State: string;
}

const initialState = {
  smcUsersList: [] as SmcUserTabListResult[],
  smcAddDetailsData: {} as AddSmcDetailsTypes,
  smcCustomerList: [] as GetSmcCustomerListResult[],
  totalSubscribedCustomers: 0,
  totalSubscribedSites: 0,
  totalSmcUsers: 0,
  smcId: '',
  userList: [] as UserDataResult[],
  totalUsers: 0,
  smcSitesList: [] as GetSmcSitesListResult[],
  UnlinkSmcCustomerRow: {} as PayloadTypeUnlinkSmc,
  UnlinkSmcSiteRow: {} as PayloadTypeUnlinkSmcSite,
  UnlinkSmcUserRow: {} as PayloadTypeUnlinkSmcUser,
  selectedSmcDetails: {} as SmcUserListByIdResult,
  smcUpdateData: {},
  isLoading: false,
  error: '',
  selectedTab: DETAILS_TAB,
  selectedSiteId: '',
};

export const addSmcDetailsSlice = createSlice({
  name: 'addSmc',
  initialState,
  reducers: {

    removeSelectedSMC() {
      return initialState;
    },

    setSelectedTab(state, action: PayloadAction<number>) {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },

    sendSmcDetailsData(state, _action: PayloadAction<AddSmcFormInput>) {
      return {
        ...state,
        isLoading: true,
        addSmcDetailsData: _action.payload,
      };
    },
    successAddingSmc(state, action: PayloadAction<string>) { //  // new one
      return {
        ...state,
        isLoading: false,
        smcId: action?.payload, // new one
        selectedTab: USERS_TAB,
      };
    },
    errorAddingSmc(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },

    // ------------------------------------------------------> // SMC USERS TAB

    getSmcUserListStart(state, _action: PayloadAction<PayloadTypeGetSmcUserList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSmcUserList(state, action: PayloadAction<PaginationResponse<SmcUserTabListResult>>) {
      return {
        ...state,
        isLoading: false,
        smcUsersList: action?.payload?.Data,
        totalSmcUsers: action.payload?.TotalRecords,
      };
    },
    failureGetSmcUserList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // -------------------------------------> // SMC DETAILS TAB CUSTOMER LISTING

    getSmcCustomerListStart(state, _action: PayloadAction<PayloadTypeGetSmcCustomerList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSmcCustomerList(state, action: PayloadAction<PaginationResponse<GetSmcCustomerListResult>>) {
      return {
        ...state,
        isLoading: false,
        smcCustomerList: action?.payload.Data,
        totalSubscribedCustomers: action.payload.TotalRecords,
      };
    },
    failureGetSmcCustomerList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // ---------------------------------------> // SMC DETAILS TAB SITES LISTING

    getSmcSitesListStart(state, _action: PayloadAction<PayloadTypeGetSmcSiteList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSmcSitesList(state, action: PayloadAction<PaginationResponse<GetSmcSitesListResult>>) {
      return {
        ...state,
        isLoading: false,
        smcSitesList: action?.payload?.Data,
        totalSubscribedSites: action?.payload.TotalRecords,
      };
    },
    failureGetSmcSitesList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // ----------------------------> // UNLINKING:-SMC DETAILS TAB CUSTOMER LISTING

    unlinkSmcCustomer(state, _action: PayloadAction<PayloadTypeUnlinkSmc>) {
      return {
        ...state,
        isLoading: true,
        UnlinkSmcCustomerRow: _action.payload,
      };
    },
    successUnlinkSmcCustomer(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureUnlinkSmcCustomer(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // ---------------------------------------> // UNLINKING:-SMC DETAILS TAB Sites LISTING

    unlinkSmcSites(state, _action: PayloadAction<PayloadTypeUnlinkSmcSite>) {
      return {
        ...state,
        isLoading: true,
        UnlinkSmcSiteRow: _action.payload,
      };
    },
    successUnlinkSmcSites(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureUnlinkSmcSites(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // ----------------------------------------------> // UNLINKING:-SMC USERS TAB CUSTOMER LISTING

    unlinkSmcUser(state, _action: PayloadAction<PayloadTypeUnlinkSmcUser>) {
      return {
        ...state,
        isLoading: true,
        UnlinkSmcUserRow: _action.payload,
      };
    },
    successUnlinkSmcUser(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    failureUnlinkSmcUser(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // ------------------------------------> Update Smc
    updateSmc(state, action: PayloadAction<UpdateSmcPayload>) {
      return {
        ...state,
        loading: true,
        smcUpdateData: action.payload,
      };
    },
    successUpdateSmc(state) {
      return {
        ...state,
        loading: false,
        isSuccess: true,
      };
    },
    failureUpdateSmc(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: false,
        isSuccess: false,
        responseMessage: action.payload,
      };
    },
    // -------------------------------------> // SMC USERS TAB ADD Users LISTING

    getUserListStart(state, _action: PayloadAction<PayloadTypeGetUserList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetUserList(state, action: PayloadAction<PaginationResponse<UserDataResult>>) {
      return {
        ...state,
        isLoading: false,
        userList: action?.payload?.Data,
        totalUsers: action?.payload?.TotalRecords,
      };
    },
    failureGetUserList(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // -------------------------------------------->> Post Api ADD Smc Users

    sendAddSmcUsersData(state, _action: PayloadAction<SmcUserTypeApiBody>) {
      return {
        ...state,
        isLoading: true,
        smcUserData: _action.payload,
      };
    },
    successAddSmcUsers(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    errorAddSmcUsers(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // -------------------------------------------------------------------> // SMC USERS TAB Get List By ID
    getSmcUserByIdStart(state, _action: PayloadAction<PayloadTypeGetSmcUserByIdList>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    successGetSmcUserById(state, action: PayloadAction<SmcUserListByIdResult>) {
      return {
        ...state,
        isLoading: false,
        selectedSmcDetails: action?.payload as SmcUserListByIdResult,
        smcId: action.payload?.SmcId,
      };
    },
    failureGetSmcUserById(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    setSelectedSiteId(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedSiteId: action.payload,
      };
    },
  },
});
export const {
  sendSmcDetailsData, successAddingSmc, errorAddingSmc, getSmcUserListStart, successGetSmcUserList, failureGetSmcUserList,
  getSmcCustomerListStart, successGetSmcCustomerList, failureGetSmcCustomerList,
  unlinkSmcCustomer, successUnlinkSmcCustomer, failureUnlinkSmcCustomer,
  unlinkSmcUser, successUnlinkSmcUser, failureUnlinkSmcUser,
  getSmcSitesListStart, successGetSmcSitesList, failureGetSmcSitesList,
  unlinkSmcSites, successUnlinkSmcSites, failureUnlinkSmcSites,
  updateSmc, successUpdateSmc, failureUpdateSmc,
  getUserListStart, successGetUserList, failureGetUserList,
  sendAddSmcUsersData, successAddSmcUsers, errorAddSmcUsers, setSelectedTab,
  getSmcUserByIdStart, successGetSmcUserById, failureGetSmcUserById, removeSelectedSMC,
  setSelectedSiteId,
} = addSmcDetailsSlice.actions;
export const addSmcDetailsReducer = addSmcDetailsSlice.reducer;
export type TypeSendSmcDetailsData = ReturnType<typeof sendSmcDetailsData>;

export type AddSmcActions =
  | ReturnType<typeof sendSmcDetailsData>
  | ReturnType<typeof successAddingSmc>
  | ReturnType<typeof errorAddingSmc>
  | ReturnType<typeof getSmcUserListStart>
  | ReturnType<typeof successGetSmcUserList>
  | ReturnType<typeof failureGetSmcUserList>
  | ReturnType<typeof getSmcCustomerListStart>
  | ReturnType<typeof successGetSmcCustomerList>
  | ReturnType<typeof failureGetSmcCustomerList>
  | ReturnType<typeof unlinkSmcCustomer>
  | ReturnType<typeof successUnlinkSmcCustomer>
  | ReturnType<typeof failureUnlinkSmcCustomer>
  | ReturnType<typeof unlinkSmcUser>
  | ReturnType<typeof successUnlinkSmcUser>
  | ReturnType<typeof failureUnlinkSmcUser>
  | ReturnType<typeof getSmcSitesListStart>
  | ReturnType<typeof successGetSmcSitesList>
  | ReturnType<typeof failureGetSmcSitesList>
  | ReturnType<typeof unlinkSmcSites>
  | ReturnType<typeof successUnlinkSmcSites>
  | ReturnType<typeof failureUnlinkSmcSites>
  | ReturnType<typeof updateSmc>
  | ReturnType<typeof successUpdateSmc>
  | ReturnType<typeof failureUpdateSmc>
  | ReturnType<typeof getUserListStart>
  | ReturnType<typeof successGetUserList>
  | ReturnType<typeof failureGetUserList>
  | ReturnType<typeof sendAddSmcUsersData>
  | ReturnType<typeof successAddSmcUsers>
  | ReturnType<typeof errorAddSmcUsers>
  | ReturnType<typeof getSmcUserByIdStart>
  | ReturnType<typeof successGetSmcUserById>
  | ReturnType<typeof failureGetSmcUserById>
  | ReturnType<typeof removeSelectedSMC>
  | ReturnType<typeof setSelectedSiteId>
  | ReturnType<typeof setSelectedTab>;
