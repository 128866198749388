import { EquipmentNeededListingFields } from '../redux/type';
import { EquipmentNeededKeyData, GeneralUnitList, JobDataInterface } from './types';

export function formatGeneralUnitData(data: GeneralUnitList[]) {
  const res: JobDataInterface[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: GeneralUnitList, index) => {
    const obj = {
      sn: index,
      unitId: '',
      model: '',
      vesselToolTip: [''],
      typeOfTest: '',
      id: '',
    };
    obj.id = col.UnitId;
    obj.unitId = col.UnitId;
    obj.model = col.Model;
    obj.vesselToolTip = col?.Vessels?.split(',') || [''];
    res.push(obj);
  });

  return res;
}

export function formatEquipmentNeededData(data: EquipmentNeededListingFields[]) {
  const res: EquipmentNeededKeyData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: EquipmentNeededListingFields, index) => {
    const obj = {
      sn: index,
      action: 'linkOff',
      status: '',
      product: '',
      category: '',
      description: '',
      serial: '',
      id: '',
      isChecked: false,
    };
    obj.id = col.ProductNumber;
    obj.status = col.Status;
    obj.product = col.ProductNumber;
    obj.description = col.Description;
    obj.category = col.Category;
    obj.serial = col.SerialNumber;
    res.push(obj);
  });

  return res;
}
