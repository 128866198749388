import {
  Grid, Card,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ComponentTextInput from '../../../../../Common/ComponentTextinput/ComponentTextInput';
import './StyleMainTab.scss';
import { AnalystForm, InterfaceAnalystSecondaryInfo } from '../../utils/types';
import { LocalizationContext } from '../../../../../Common/Localization/LocalizationProvider';
import RequiredFiled from '../../../../../utils/RequiredField';
import ComponentSelectInput from '../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../redux/rootState';
import { analystBaseOutPinCodeDetailsStart, removeAnalystBaseOutPinCodeDetails } from '../../redux/AddAnalystSlice';
import { ANALYST_BASE_OUT_INFO_KEY } from '../../utils/constants';
import { RHFAutocompleteField } from '../../../../../Common/ComponentAutoSelect';

export default function BasedOutInfo({
  control, errors, setValue, watch, trigger,
}: InterfaceAnalystSecondaryInfo): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const { countries, citySuggestions } = useSelector((state: RootState) => state.masterDataReducer);
  const { isBaseOutPinError, analystBaseOutPinCodeDetails } = useSelector((state: RootState) => state.AddAnalystSliceReducer);
  const companyZip = watch(AnalystForm.boZip);
  const countryName = watch(AnalystForm.boCountry);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyZip && companyZip?.length >= 5) {
      setValue(AnalystForm.boState, '');
      const queryPayload = {
        pinCode: companyZip,
        countryCode: countryName,
        key: ANALYST_BASE_OUT_INFO_KEY,
      };
      dispatch(analystBaseOutPinCodeDetailsStart(queryPayload));
    } else if (companyZip?.length < 5) {
      dispatch(removeAnalystBaseOutPinCodeDetails());
      setValue(AnalystForm.boState, '');
    }
  }, [companyZip, countryName]);

  useEffect(() => {
    if (analystBaseOutPinCodeDetails?.City) {
      setValue(AnalystForm.boState, analystBaseOutPinCodeDetails?.StateName);
      trigger([AnalystForm.boState]);
    }
  }, [analystBaseOutPinCodeDetails]);

  useEffect(() => {
    dispatch(removeAnalystBaseOutPinCodeDetails());
  }, []);

  const otherCity = {
    Value: 'Other',
    Text: 'Other',
  };

  const city = watch(AnalystForm.boCity);

  const renderCityInput = () => {
    if (city === 'Other') {
      return true;
    }
    return false;
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Based Out of
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.country}
              </label>
              <ComponentSelectInput
                name={AnalystForm.boCountry}
                required
                errors={errors}
                control={control}
                handleChange={() => setValue(AnalystForm.boZip, '')}
                size="small"
                entries={countries}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.zip}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                errors={errors}
                regEx={/^[0-9]+$/}
                inputProps={{ maxLength: 10 }}
                name={AnalystForm.boZip}
                id={AnalystForm.boZip}
                size="small"
              />
            </div>
            {isBaseOutPinError ? (<span className="errorMessage">Invalid zip code</span>) : ''}
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.state}
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                errors={errors}
                isDisable
                name={AnalystForm.boState}
                id={AnalystForm.boState}
                size="small"
              />
            </div>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                {translations?.city}
                <RequiredFiled />
              </label>
              {renderCityInput() ? (
                <RHFAutocompleteField
                  options={citySuggestions || []}
                  control={control}
                  name={AnalystForm.otherBOCity}
                />
              )
                : (
                  <ComponentSelectInput
                    name={AnalystForm.boCity}
                    defaultValue=""
                    control={control}
                    size="small"
                    entries={analystBaseOutPinCodeDetails?.City ? [...analystBaseOutPinCodeDetails.City, otherCity] : []}
                  />
                )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
