import { EditorState } from 'draft-js';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';

export enum RequestForm {
  vertical = 'vertical',
  safetyEquipmentRequired = 'safetyEquipmentRequired',
  outside = 'outside',
  flux = 'flux',
  absDiff = 'absDiff',
  crsDiff = 'crsDiff',
  jobId = 'jobId',
  manufacturer = 'manufacturer',
  serialId = 'serialId',
  model = 'model',
  vessel = 'vessel',
  tubeType = 'tubeType',
  material = 'material',
  OD = 'OD',
  ID = 'ID',
  tubeLength = 'tubeLength',
  tubeCount = 'tubeCount',
  NWT = 'NWT',
  NWTBellLand = 'NWTBellLand',
  NWTUnderFins = 'NWTUnderFins',
  workHrs = 'workHrs',
  note = 'note',
  retestOf = 'retestOf',
  adminNote = 'adminNote',
}

export interface RequestMainFormValues {
  vertical: boolean;
  safetyEquipmentRequired?: boolean;
  outside?: boolean;
  flux?: boolean;
  absDiff?: boolean;
  crsDiff?: boolean;
  stage?: string;
  jobId: string;
  manufacturer: string;
  serialId: string;
  model: string;
  vessel: string;
  tubeType: string;
  material: string;
  OD: string;
  ID: string;
  tubeLength: string;
  tubeCount: string;
  NWT: string;
  NWTBellLand: string;
  NWTUnderFins: string;
  workHrs: string;
  retestOf: string;
  note: string;
  adminNote: string;
}
export interface RequestInformation {
  control: Control<RequestMainFormValues>;
  errors: FieldErrors<RequestMainFormValues>;
  watch: UseFormWatch<RequestMainFormValues>;
  setValue: SetValue;
  setComment: (newEditorState: EditorState) => void,
  comment: EditorState,
}

export interface EquipmentTypes {
  Value: string;
  Text: string;
}

interface EquipmentType {
  TubeLength: string;
  TubeCount: string;
  Value: string;
  Text: string;
}

interface MaterialType {
  Value: string;
  Text: string;
}

interface TubeType {
  Value: string;
  Text: string;
}

export interface ManufactureType {
  Value: string;
  Text: string;
}

export interface ResultType {
  Vessel: EquipmentType[];
  Material: MaterialType[];
  Tube: TubeType[];
}

export interface UnitMasterType {
  Manufacture: ManufactureType[];
}

export interface EquipmentResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: ResultType;
  }
}

export interface CreateData {
  RequestId?: string;
  UnitId: string;
  JobId: string;
  VesselId: number | null;
  TubeId: number | null;
  MaterialId: number | null;
  OuterDiameter: number;
  InnerDiameter: number;
  Nwt: string;
  NwtBellLand: string;
  NwtUnderFills: string;
  WorkHrs: number;
  RetestOf: string;
  Note: string;
  Vertical: boolean;
  ApprovalDate: string;
  Status: number;
  AdminComments?: string
}

export interface CreateDataPayload {
  payload: CreateData,
  callback: () => void,
  isAdmin: boolean,
  navigate: NavigateFunction
}
export interface EquipmentListResult {
  EquipmentName: string;
  Location: string;
  Available: string;
  Cost: string;
}

export interface EquipmentInitialState {
  addEquipmentData: CreateData;
  isLoading: boolean;
  isSuccess: boolean;
  message: string;
  vesselsData: EquipmentType[];
  materialData: MaterialType[];
  tubeTypesData: TubeType[];
  manufacturerData: ManufactureType[];
  equipmentList: EquipmentListResult[];
  totalEntries: number;
  isRequestCreated: boolean;
}

export interface UnitMasterResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: UnitMasterType;
  }
}

export interface CreateEquipmentResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface PayloadTypeEquipmentListStart {
  rowsPerPage: number;
  page: number;
  searchQuery: string;
}

export interface GetEquipmentListApiData {
  TotalRecords: number;
  Data: EquipmentListResult[];
}

export interface GetAllEquipmentApiResponse { // get all Smc list:- API responses
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: GetEquipmentListApiData;
  }
}
