export const errorTypeMessage = {
  MIN_DATE_ERROR_MESSAGE: 'Choose a date that is greater than or equal to January 1, 1990',
  INVALID_DATE_ERROR_MESSAGE: 'Your date is not valid',
  MAX_DATE_ERROR_MESSAGE: 'Choose a date that is Less than or equal to December 31, 2099',
  OPTIONALITY_ERROR_MESSAGE: 'Date is required',
  NULLABLE_ERROR_MESSAGE: 'Date cannot be null',
};

export const errorType = {
  MIN_DATE: 'min',
  INVALID_DATE: 'typeError',
  MAX_DATE: 'max',
  OPTIONALITY: 'optionality',
  NULLABLE: 'nullable',
};
