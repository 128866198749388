import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../../Common/DeleteModal/ComponentDeleteModal';
import { DefaultTypeList } from '../utils/types';
import { deleteDefaultListStart, getDefaultListStart } from '../redux/sliceDefaultTypeList';
import ComponentDefaultListDialog from './ComponentDefaultListDialog';

function ComponentDefaultListTable() {
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDefaultListId, setSelectedDefaultListId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const { isLoading, DefaultListData } = useSelector((state: RootState) => state.ListDefaultReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getDefaultListStart(params));
  };

  const handleAction = (id: string) => {
    setOpen(true);
    setRowId(id);
  };

  const handleButtonClick = () => {
    setRowId('');
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const TableColumns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'Model', label: 'Model' },
      { key: 'Style', label: 'Style' },
      { key: 'Type', label: 'Type' },
      { key: 'Notes', label: 'Notes' },
    ],
    [],
  );

  const tableData: DefaultTypeList[] = useMemo(() => DefaultListData?.Data?.map((item, index) => {
    const transformedDataItem : DefaultTypeList = {
      id: String(item?.Id),
      sn: index,
      action: 'edit&delete',
      Model: item?.Model,
      Style: item?.Style,
      Type: item?.Type,
      Notes: item?.Notes,
      StyleId: item?.StyleId,
      TypeId: item?.TypeId,
    };
    return transformedDataItem;
  }), [DefaultListData]);

  const handleDelete = (id: string) => {
    setShowDeleteModal(true);
    setSelectedDefaultListId(id);
  };

  const handelDeleteModal = () => {
    const paramData = {
      Id: selectedDefaultListId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deleteDefaultListStart(paramData));
    setShowDeleteModal(false);
  };

  return (
    <>
      <DataTable<DefaultTypeList>
        handleButtonClick={handleButtonClick}
        data={tableData}
        columns={TableColumns}
        totalRecords={DefaultListData?.TotalRecords}
        buttonText="Add"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleEdit={handleAction}
        handleDelete={handleDelete}
      />
      <ComponentDefaultListDialog show={open} setOpenFrom={handleButtonClick} rowId={rowId} handleData={handleData} />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setShowDeleteModal(false)}
        handleDelete={handelDeleteModal}
      />
    </>
  );
}

export default ComponentDefaultListTable;
