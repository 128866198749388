import { EquipmentRequestListingFields } from '../../../InventoryManagement/TestEquipmentRequestScreen/redux/type';
import { SideUnitDetailsNames, UnitInfoFormNames } from './constants';
import {
  CreateUnitPayload,
  GetUnitByIdResponse,
  GetUnitPriceResponse, GetVesselInfo,
  OtherVessel,
  ReqEqData, UnitInfo, UnitInfoFormValues, UnitPricingInfo, Vessel, VesselData, VesselFormValues, VesselInfo, VesselList,
} from './type';

export const formatvesselData = (vesselMaster: VesselList[]) => {
  const res: VesselData[] = vesselMaster.map((vessel: VesselList) => {
    const newVessel = {
      id: vessel.Id,
      vesselName: vessel.VesselName,
      tubeName: `${vessel.VesselName}Name`,
      tubeLength: `${vessel.VesselName}Length`,
      numberOfTube: 0,
      length: 0,
      active: !vessel.DefaultDisplay,
      display: vessel.DefaultDisplay,
      vesselType: vessel.VesselType,
    };
    return newVessel;
  });

  return res;
};

export const assignVesselData = (vesselList: GetVesselInfo[], vesselMaster: VesselList[]) => {
  const temp = JSON.parse(JSON.stringify(vesselMaster));
  const res: VesselData[] = temp.map((vessel: VesselList) => {
    const activeVessel = vesselList.find((ele: GetVesselInfo) => ele.VesselId === vessel.Id);
    if (activeVessel) {
      return {
        id: vessel.Id,
        vesselName: vessel.VesselName,
        tubeName: `${vessel.VesselName}Name`,
        tubeLength: `${vessel.VesselName}Length`,
        numberOfTube: activeVessel.NumberOfTubes,
        length: activeVessel.TubeLength,
        active: false,
        display: true,
        vesselType: vessel.VesselType,
      };
    }
    const newVessel = {
      id: vessel.Id,
      vesselName: vessel.VesselName,
      tubeName: `${vessel.VesselName}Name`,
      tubeLength: `${vessel.VesselName}Length`,
      numberOfTube: 0,
      length: 0,
      active: true,
      display: false,
      vesselType: vessel.VesselType,
    };
    return newVessel;
  });
  return res;
};

export const assignUnitValues = (setValue: SetValue, details: GetUnitByIdResponse) => {
  const { UnitInfo: unitInfo } = details;

  if (unitInfo.OdOfTubes > 0) {
    setValue(UnitInfoFormNames.odTubes, unitInfo.OdOfTubes);
  }

  [
    { name: UnitInfoFormNames.safetyEqRequired, value: unitInfo.SafetyEquipmentRequired },
    { name: UnitInfoFormNames.safetyCharges, value: unitInfo.SafetyCharge },
    { name: UnitInfoFormNames.testType, value: unitInfo.TestType },
    { name: UnitInfoFormNames.machineType, value: unitInfo.MachineTypeId },
    { name: UnitInfoFormNames.location, value: unitInfo.Location },
    { name: UnitInfoFormNames.manufacturer, value: unitInfo.ManufacturerId },
    { name: UnitInfoFormNames.model, value: unitInfo.Model },
    { name: UnitInfoFormNames.type, value: unitInfo.TypeId },
    { name: UnitInfoFormNames.style, value: unitInfo.StyleId },
    { name: UnitInfoFormNames.serialNumber, value: unitInfo.SerialNo },
    { name: UnitInfoFormNames.PONumber, value: unitInfo.PoNumber },
    { name: UnitInfoFormNames.jobTime, value: unitInfo.JobTime },
    { name: UnitInfoFormNames.spotTest, value: unitInfo.SpotTest },
    { name: UnitInfoFormNames.pct, value: unitInfo.SpotPercentage },
    { name: UnitInfoFormNames.leak, value: unitInfo.Leak },
    { name: UnitInfoFormNames.outside, value: unitInfo.Outside },
  ].forEach(({ name, value }) => setValue(name, value));
};

export const assignUnitPriceValues = (setValue: SetValue, unitPrice: GetUnitPriceResponse) => {
  [
    { name: SideUnitDetailsNames.absShipping, value: unitPrice.AbsDiffShipping },
    { name: SideUnitDetailsNames.calculatedDays, value: unitPrice.CalculatedDays },
    { name: SideUnitDetailsNames.fluxLeakageDays, value: unitPrice.FluxLekageDays },
    { name: SideUnitDetailsNames.fluxShipping, value: unitPrice.FluxShipping },
    { name: SideUnitDetailsNames.safetyCharges, value: unitPrice.SafetyChargePrice },
    { name: SideUnitDetailsNames.tubeCharge, value: unitPrice.TubeChargePrice },
  ].forEach(({ name, value }) => setValue(name, value));
};

// eslint-disable-next-line max-len
export const formatUnitPayload = (values: VesselFormValues, unitInfo: UnitInfoFormValues, bidId: string, displayVessels: VesselData[], unitPrice: GetUnitPriceResponse, numberOfTubes: number, tubeLength: number): CreateUnitPayload => {
  const unitInfoPayload: UnitInfo = {
    BidId: bidId,
    TestType: unitInfo.testType, // ECT, FLT, ADT
    SafetyEquipmentRequired: unitInfo.safetyEqRequired || false,
    SafetyCharge: unitInfo.safetyCharges || false,
    OdOfTubes: Number(unitInfo.odTubes) || 0,
    MachineTypeId: unitInfo.machineType,
    Location: unitInfo.location || '',
    ManufacturerId: unitInfo.manufacturer,
    Model: unitInfo.model || '',
    TypeId: unitInfo.type || null,
    StyleId: unitInfo.style || null,
    SerialNo: unitInfo.serialNumber,
    PoNumber: unitInfo.PONumber || '',
    Leak: unitInfo.leak || false,
    Outside: unitInfo.outside || false,
    JobTime: unitInfo.jobTime || '',
    SpotTest: unitInfo.spotTest || false,
    SpotPercentage: unitInfo.pct || 0,
  };

  const vessels: Vessel[] = [];
  const otherVessels: OtherVessel[] = [];

  displayVessels.forEach((vessel: VesselData) => {
    if (vessel.numberOfTube > 0) {
      if (vessel.id > -1) {
        vessels.push({
          VesselId: vessel.id,
          NumberOfTubes: vessel.numberOfTube,
          TubeLength: vessel.length,
        });
      } else {
        otherVessels.push({
          VesselName: vessel.vesselName,
          NumberOfTubes: vessel.numberOfTube,
          TubeLength: vessel.length,
        });
      }
    }
  });

  const vesselpayload: VesselInfo = {
    TubeQtyIsEstimated: values.estimatedTubeQty || false,
    TubeQtyProvidedByCustomer: values.providedTubeQty || false,
    VesselIds: vessels,
    OtherVessels: otherVessels,
  };

  const UnitPricing: UnitPricingInfo = {
    TubeCharge: unitPrice.TubeChargePrice,
    CalculatedDays: unitPrice.CalculatedDays,
    FluxLeakageDays: unitPrice.FluxLekageDays,
    FluxShipping: unitPrice.FluxShipping,
    AbsDiffShipping: unitPrice.AbsDiffShipping,
    SafetyCharges: unitPrice.SafetyChargePrice,
    TotalTubeQty: numberOfTubes,
    TotalTubeLength: tubeLength,
    MinimumJob: unitPrice.MinimumJob,
  };

  return {
    UnitInfo: unitInfoPayload,
    VesselsInfo: vesselpayload,
    UnitPricingInfo: UnitPricing,
  };
};

export const assignUnitInfo = (setValue: SetValue, unitInfo: UnitInfoFormValues) => {
  if (unitInfo.odTubes > 0) {
    setValue(UnitInfoFormNames.odTubes, unitInfo.odTubes);
  }

  [
    { name: UnitInfoFormNames.PONumber, value: unitInfo.PONumber },
    { name: UnitInfoFormNames.testType, value: unitInfo.testType },
    { name: UnitInfoFormNames.jobTime, value: unitInfo.jobTime },
    { name: UnitInfoFormNames.leak, value: unitInfo.leak },
    { name: UnitInfoFormNames.location, value: unitInfo.location },
    { name: UnitInfoFormNames.machineType, value: unitInfo.machineType },
    { name: UnitInfoFormNames.manufacturer, value: unitInfo.manufacturer },
    { name: UnitInfoFormNames.model, value: unitInfo.model },
    { name: UnitInfoFormNames.outside, value: unitInfo.outside },
    { name: UnitInfoFormNames.pct, value: unitInfo.spotTest ? unitInfo.pct : '' },
    { name: UnitInfoFormNames.safetyCharges, value: unitInfo.safetyCharges },
    { name: UnitInfoFormNames.safetyEqRequired, value: unitInfo.safetyEqRequired },
    { name: UnitInfoFormNames.model, value: unitInfo.model },
    { name: UnitInfoFormNames.type, value: unitInfo.type },
    { name: UnitInfoFormNames.style, value: unitInfo.style },
    { name: UnitInfoFormNames.serialNumber, value: unitInfo.serialNumber },
    { name: UnitInfoFormNames.PONumber, value: unitInfo.PONumber },
    { name: UnitInfoFormNames.spotTest, value: unitInfo.spotTest },
  ].forEach(({ name, value }) => setValue(name, value));
};

export function formatEquipmentRequestListData(data: EquipmentRequestListingFields[]) {
  const res: ReqEqData[] = [];

  if (data?.length === 0) return [];

  data?.forEach((col: EquipmentRequestListingFields, index) => {
    const obj = {
      sn: index,
      jobId: '',
      vessel: '',
      requestedBy: '',
      action: 'view',
      status: '',
      viewOnly: false,
      id: '',
    };
    obj.id = col.RequestId;
    obj.jobId = col.UnitId;
    obj.vessel = col.Vessel;
    obj.requestedBy = col.RequestedBy;
    obj.status = col.Status;
    obj.viewOnly = col.Status !== 'Rejected';
    res.push(obj);
  });

  return res;
}
