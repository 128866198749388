/* eslint-disable max-len */
import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';
import {
  addEquipmentByAdminFailure,
  addEquipmentByAdminStart,
  addEquipmentByAdminSuccess,
  createEquipmentRequestStart,
  createEquipmentRequestSuccess,
  equipmentRequestRejected,
  getEquipmentDetailsByRequestIdFailure,
  getEquipmentDetailsByRequestIdStart,
  getEquipmentDetailsByRequestIdSuccess,
  getEquipmentHistoryDataFailure,
  getEquipmentHistoryDataStart,
  getEquipmentHistoryDataSuccess,
  getEquipmentMasterFailure,
  getEquipmentMasterStart, getEquipmentMasterStatusFailure, getEquipmentMasterStatusStart, getEquipmentMasterStatusSuccess, getEquipmentMasterSuccess,
  getEquipmentNeededDataFailure,
  getEquipmentNeededDataStart,
  getEquipmentNeededDataSuccess,
  getEquipmentRequestDataFailure, getEquipmentRequestDataStart, getEquipmentRequestDataSuccess,
  InventoryEquipmentRequestSliceActions,
  onDeleteEquipmentByAdmin,
  onDeleteEquipmentByAdminSuccess,
  setEquipmentRequestTab,
} from './equipmentRequestSlice';
import {
  AddEquipmentByAdminPayload,
  AddEquipmentPayload,
  EquipmentDetailsByRequestIdResponse,
  EquipmentHistoryResponse,
  EquipmentNeededListDataResponse,
  EquipmentNeededListingPayload,
  EquipmentRequestDeletePayload,
  EquipmentRequestDeleteResponse,
  EquipmentRequestFieldsPayload,
  EquipmentRequestListDataResponse, EquipmentRequestListingPayload,
  EquipmentRequestMasterStatusResponse, EquipmentRequestPayload, EquipmentRequestPostResponse,
  InventoryMasterResponse,
} from './type';
import { getUnitByIdStart } from '../../../Job/ScreenUnitInformation/redux/unitInfoSlice';
import EndPoints from '../../../../Routes/EndPoints';
import { EQUIPMENT_NEEDED } from '../../utils/constants';
import { statusVariable } from '../utils/constants';

async function getEquipmentMaster(data: string): Promise<InventoryMasterResponse> {
  const url = `${Config.inventory.getEquipmentMaster}?unitid=${data}`;
  const result = await makeGetRequest<InventoryMasterResponse>(url);
  return result.data;
}

// GET INVENTORY EQUIPMENT REQUEST MATER DATA
export const inventoryEquipmentRequestMaster = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentMasterStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getEquipmentMaster(data)).pipe(
    map((res: InventoryMasterResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentMasterSuccess(res.BMT.Result);
      }
      return getEquipmentMasterFailure(false);
    }),
    takeUntil(action$.pipe(filter(getEquipmentMasterStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentMasterFailure(false))),
  )),
);

async function createEquipmentRequestApiCall(data: EquipmentRequestFieldsPayload): Promise<EquipmentRequestPostResponse> {
  const url = Config.inventoryEquipmentRequest.equipmentRequest;
  const result = await makePostRequest<EquipmentRequestPostResponse>(url, data);
  return result.data;
}

// CREATE EQUIPMENT REQUEST
export const createEquipmentRequestEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(createEquipmentRequestStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({
    payload, navigate, isAdmin, status,
  }: EquipmentRequestPayload) => from(createEquipmentRequestApiCall(payload)).pipe(
    map((res: EquipmentRequestPostResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        if (isAdmin) {
          navigate(`${EndPoints.TEST_EQUIPMENT_REQUEST_FORM}/${res.BMT.Result}`);
          showSuccessToaster(res?.BMT?.ResponseMessage as string);
          return setEquipmentRequestTab(EQUIPMENT_NEEDED);
        }
        return createEquipmentRequestSuccess(true);
      }
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        if (status === statusVariable.REJECTED) {
          showSuccessToaster(res?.BMT?.ResponseMessage as string);
          return equipmentRequestRejected(true);
        }
        if (isAdmin) {
          showSuccessToaster(res?.BMT?.ResponseMessage as string);
          return setEquipmentRequestTab(EQUIPMENT_NEEDED);
        }
        navigate(-1);
      }
      showErrorToaster(res?.BMT?.ResponseMessage as string);
      return createEquipmentRequestSuccess(false);
    }),
    takeUntil(action$.pipe(filter(createEquipmentRequestStart.match))),
    catchError((error: AxiosError<EquipmentRequestPostResponse>) => {
      const errorMessage = error.response?.data.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(createEquipmentRequestSuccess(false));
    }),
  )),
);

async function getEquipmentRequestDataApiCall(data: EquipmentRequestListingPayload): Promise<EquipmentRequestListDataResponse> {
  // eslint-disable-next-line max-len
  if (data?.unitId) {
    const url = `${Config.inventoryEquipmentRequest.getEquipmentRequest}?unitid=${data?.unitId}&pageSize=${data?.rowsPerPage}&pageNumber=${data?.page}&searchItem=${data?.searchQuery}`;
    const result = await makeGetRequest<EquipmentRequestListDataResponse>(url);
    return result.data;
  }
  const url = `${Config.inventoryEquipmentRequest.getEquipmentRequest}?unitid=&pageSize=${data?.rowsPerPage}&pageNumber=${data?.page}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<EquipmentRequestListDataResponse>(url);
  return result.data;
}

// GET INVENTORY EQUIPMENT REQUEST LIST DATA
export const equipmentRequestDataEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentRequestDataStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: EquipmentRequestListingPayload) => from(getEquipmentRequestDataApiCall(data)).pipe(
    map((res: EquipmentRequestListDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentRequestDataSuccess(res.BMT.Result);
      }
      return getEquipmentRequestDataFailure(false);
    }),
    takeUntil(action$.pipe(filter(getEquipmentRequestDataStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentRequestDataFailure(false))),
  )),
);

async function getEquipmentMasterStatus(): Promise<EquipmentRequestMasterStatusResponse> {
  const url = Config.inventoryEquipmentRequest.getEquipmentMasterStatus;
  const result = await makeGetRequest<EquipmentRequestMasterStatusResponse>(url);
  return result.data;
}

// GET INVENTORY EQUIPMENT MASTER STATUS
export const inventoryEquipmentRequestStatusMaster = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentMasterStatusStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(() => from(getEquipmentMasterStatus()).pipe(
    map((res: EquipmentRequestMasterStatusResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentMasterStatusSuccess(res.BMT.Result);
      }
      return getEquipmentMasterFailure(false);
    }),
    takeUntil(action$.pipe(filter(getEquipmentMasterStatusStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentMasterStatusFailure(false))),
  )),
);

async function getEquipmentNeededStatus(data: EquipmentNeededListingPayload): Promise<EquipmentNeededListDataResponse> {
  const url = `${Config.inventoryEquipmentRequest.equipmentNeededList}?RequestId=${data?.requestId}&pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}`;
  const result = await makeGetRequest<EquipmentNeededListDataResponse>(url);
  return result.data;
}

// GET INVENTORY EQUIPMENT NEEDED DATA
export const inventoryEquipmentNeededMaster = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentNeededDataStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: EquipmentNeededListingPayload) => from(getEquipmentNeededStatus(data)).pipe(
    map((res: EquipmentNeededListDataResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentNeededDataSuccess(res.BMT.Result);
      }
      return getEquipmentNeededDataFailure(false);
    }),
    takeUntil(action$.pipe(filter(getEquipmentNeededDataStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentNeededDataFailure(false))),
  )),
);

async function addEquipmentByAdmin(data: AddEquipmentByAdminPayload): Promise<EquipmentRequestPostResponse> {
  const url = Config.inventoryEquipmentRequest.addEquipment;
  const result = await makePostRequest<EquipmentRequestPostResponse>(url, data);
  return result.data;
}

// ADD EQUIPMENT NEEDED
export const addEquipmentByAdminEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(addEquipmentByAdminStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap(({ payload, callback }: AddEquipmentPayload) => from(addEquipmentByAdmin(payload)).pipe(
    map((res: EquipmentRequestPostResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        callback();
        showSuccessToaster(res?.BMT?.ResponseMessage as string);
        return addEquipmentByAdminSuccess(true);
      }
      callback();
      showErrorToaster(res?.BMT?.ResponseMessage as string);
      return addEquipmentByAdminFailure(false);
    }),
    takeUntil(action$.pipe(filter(addEquipmentByAdminStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), addEquipmentByAdminFailure(false))),
  )),
);

async function deleteEquipmentApiCall(data: string): Promise<EquipmentRequestDeleteResponse> {
  const url = `${Config.inventoryEquipmentRequest.deleteEquipmentRequest}?requestId=${data}`;
  const result = await makeDeleteRequest<EquipmentRequestDeleteResponse>(url);
  return result.data;
}

// DELETE INVENTORY EQUIPMENT NEEDED DATA
export const inventoryEquipmentDeleteEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(onDeleteEquipmentByAdmin.match),
  map((x) => x.payload),
  mergeMap(({ selectedId, callback }: EquipmentRequestDeletePayload) => from(deleteEquipmentApiCall(selectedId)).pipe(
    map((res: EquipmentRequestDeleteResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage as string);
        callback();
        return onDeleteEquipmentByAdminSuccess(true);
      }
      callback();
      showErrorToaster(res?.BMT?.ResponseMessage as string);
      return onDeleteEquipmentByAdminSuccess(false);
    }),
    takeUntil(action$.pipe(filter(onDeleteEquipmentByAdmin.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), onDeleteEquipmentByAdminSuccess(false))),
  )),
);

async function getEquipmentHistoryApiCall(data: string): Promise<EquipmentHistoryResponse> {
  const url = `${Config.inventoryEquipmentRequest.getActivityHistory}?RequestId=${data}`;
  const result = await makeGetRequest<EquipmentHistoryResponse>(url);
  return result.data;
}

// GET EQUIPMENT HISTORY
export const inventoryEquipmentHistoryEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentHistoryDataStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getEquipmentHistoryApiCall(data)).pipe(
    map((res: EquipmentHistoryResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getEquipmentHistoryDataSuccess(res.BMT.Result);
      }
      return getEquipmentHistoryDataFailure([]);
    }),
    takeUntil(action$.pipe(filter(getEquipmentHistoryDataStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentHistoryDataFailure([]))),
  )),
);

async function getEquipmentDetailsByIdApiCall(data: string): Promise<EquipmentDetailsByRequestIdResponse> {
  const url = `${Config.inventoryEquipmentRequest.getEquipmentRequestbyid}?RequestId=${data}`;
  const result = await makeGetRequest<EquipmentDetailsByRequestIdResponse>(url);
  return result.data;
}

// GET EQUIPMENT REQUEST DETAILS BY REQUEST ID
export const inventoryEquipmentDetailsByIdEpic = (action$: Observable<InventoryEquipmentRequestSliceActions>) => action$.pipe(
  filter(getEquipmentDetailsByRequestIdStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: string) => from(getEquipmentDetailsByIdApiCall(data)).pipe(
    mergeMap((res: EquipmentDetailsByRequestIdResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return of(getUnitByIdStart(res?.BMT?.Result?.UnitId), getEquipmentMasterStart(res?.BMT?.Result?.UnitId), getEquipmentDetailsByRequestIdSuccess(res.BMT.Result));
      }
      return of(getEquipmentDetailsByRequestIdFailure(false));
    }),
    takeUntil(action$.pipe(filter(getEquipmentDetailsByRequestIdStart.match))),
    catchError((error) => of(showErrorToaster(error.response?.data.BMT?.ResponseMessage as string), getEquipmentDetailsByRequestIdFailure(false))),
  )),
);
