export const SendEquipmentListColumn = [
  { key: 'requestId', label: 'Request ID' },
  { key: 'jobNumber', label: 'Job Order #' },
  { key: 'analyst', label: 'Analyst' },
  { key: 'jobDates', label: 'Job Dates' },
];

export const SelectedRequestColumn = [
  { key: 'action', label: 'Action' },
  { key: 'status', label: 'Status' },
  { key: 'product', label: 'Product #' },
  { key: 'serial', label: 'Serial #' },
  { key: 'category', label: 'Category' },
  { key: 'description', label: 'Description' },
];

export const SEND_EQUIPMENT_CONFIRMATION = 'Are you sure you want to mark these Equipment as Sent?';
export const RECEIVE_EQUIPMENT_CONFIRMATION = 'Are you sure you want to mark these Equipment as Received?';
export const SEND_EQUIPMENT_CONFIRMATION_INFO_TEXT = `It seems some of the Equipment are unavailable in the 
Inventory. Please select any alternative Equipment`;
export const RECEIVE_EQUIPMENT_CONFIRMATION_INFO_TEXT = `Are you sure you want to additionally send these 
equipment to the Analyst? The status of these equipment will be changed to Unavailable`;

export const sendAndReceiveEquipmentType = {
  MARK_AS_SEND: 1,
  MARK_AS_RECEIVE: 2,
  UNLINK: 3,
};

export const sendAndReceivePdfType = {
  SEND_HEADER: 'Shipping List',
  RECEIVE_HEADER: 'Receipt List',
};
