import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import ComponentMarkupsTable from './Components';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import { RootState } from '../../../redux/rootState';
import { exportToExcelData } from '../../../Common/PageHeader/helper';

function ScreenMarkupsList() {
  const { SystemMarkup } = useSelector((state: RootState) => state.SystemMarkupReducer);

  const excelData = useMemo(() => SystemMarkup?.Data?.map((item) => {
    const transformedDataItem = {
      Description: item.Description,
      Percentage: `${item.Percentage} %`,

    };
    return transformedDataItem;
  }), [SystemMarkup]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Markups_list',
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="MARKUP" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <ComponentMarkupsTable />
      </Grid>
    </Grid>
  );
}

export default ScreenMarkupsList;
