export function reportHeading(value:string) {
  switch (value) {
    case 'ECT':
      return 'Eddy Current';
    case 'FLT':
      return 'Flux Test';
    case 'ADT':
      return 'Absolute Differential';
    default:
      return '';
  }
}
