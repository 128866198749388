/* eslint-disable no-mixed-operators */
import {
  Grid, Card, Button, Accordion, AccordionSummary, Typography, AccordionDetails,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { CustomExpandIcon } from '../../../../../../utils/AccordionUtils/AccordionUtils';
import DataTable from '../../../../../../Common/DataTable';
import { AccommodationTableColumns, JobScheduleFields } from '../../../utils/constants';
import { AccommodationTableData, JobScheduleFormInfo } from '../../../utils/types';
import { accommodationJobsData, getFormValues } from '../../../utils/helper';
import HotelListingModal from '../../../../../../Common/HotelListingModal';
import FlightOut from './component/FlightOut';
import FlightIn from './component/FlightIn';
import CarRental from './component/CarRental';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import DialogBoxHotelEdit from '../ComponentHotelEditDialogBox/DialogBoxHotelEdit';
import {
  getCommentData,
  getJobTravelListData,
  onAddAccommodationScheduleFailure, onCreateJobSchedule, onDeleteAccommodationSchedule, setSelectedTab,
} from '../../redux/jobScheduleSlice';
import ComponentDeleteModal from '../../../../../../Common/DeleteModal/ComponentDeleteModal';
import { RootState } from '../../../../../../redux/rootState';
import { setLoader } from '../../../../../../redux/loaderSlice';
import { assignTravelValues } from '../../utils/helper';
import { customOptions } from '../../../../../../Common/TextEditorOptions';
import { travelInfoFormSchema } from '../../utils/validations';
import { TravelDefaultData } from '../../utils/constants';
import { Empty } from '../../../../../ScreenAddSmc/Utils/TypeSmc';

export default function ComponentTravelInfo() {
  const dispatch = useDispatch();
  const { bidId } = useParams();
  const navigate = useNavigate();
  dayjs.extend(utc);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [openHotelListing, setOpenHotelListing] = useState(false);
  const {
    control, setValue, getValues, formState: { errors }, handleSubmit, watch,
  } = useForm<JobScheduleFormInfo>({
    resolver: yupResolver(travelInfoFormSchema as unknown as ObjectSchema<JobScheduleFormInfo>),
    defaultValues: TravelDefaultData as Empty,
  });
  const [flightOutdepartureTime, setFlightOutDipartureTime] = useState<Dayjs>();
  const [flightOutarivalTime, setFlightOutArivalTime] = useState<Dayjs>();
  const [flightIndepartureTime, setFlightInDipartureTime] = useState<Dayjs>();
  const [flightInarivalTime, setFlightInArivalTime] = useState<Dayjs>();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [hotelId, setHotelId] = useState('');
  const [comment, setComment] = useState(EditorState.createEmpty());
  const [commentInstructions, setInstructionsComment] = useState(EditorState.createEmpty());
  const {
    jobTravelListData, isTravelListLoading, commentData, isCommentLoading, addedAccommadation, travelDataById,
  } = useSelector((state: RootState) => state.jobScheduleReducer);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [openSpecialInstructionAccordion, setOpenSpecialInstructionAccordion] = useState(false);
  const [openCustomerInstructionAccordion, setOpenCustomerInstructionAccordion] = useState(false);

  // Function to update the editor state when content changes
  const handleComment = (newEditorState: EditorState) => {
    setComment(newEditorState);
  };

  const handleInstructionsComment = (newEditorState: EditorState) => {
    setInstructionsComment(newEditorState);
  };

  useEffect(() => {
    dispatch(getCommentData(bidId));
  }, []);

  // Function to get the HTML content from the editor
  const getHtmlFromEditorHTMLtoString = (newEditorState: EditorState): string => {
    const contentState = newEditorState.getCurrentContent();
    return stateToHTML(contentState);
  };

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const handleSave = (values: JobScheduleFormInfo) => {
    const commentValues = getHtmlFromEditorHTMLtoString(comment);
    const instructionsValues = getHtmlFromEditorHTMLtoString(commentInstructions);

    const travelDetailsValues = getValues();
    const travelsDetails = {
      ...travelDetailsValues,
      flightOutdepartureTime,
      flightOutarivalTime,
      flightIndepartureTime,
      flightInarivalTime,
    };
    const payloadJobForm = getFormValues(values, bidId, travelsDetails, commentValues, instructionsValues);
    const formPayload = {
      navigate,
      payload: payloadJobForm,
    };
    dispatch(onCreateJobSchedule(formPayload));
  };

  useEffect(() => {
    if (commentData?.Comment) {
      const convertedComment = htmlToEditorState(commentData?.Comment);
      setComment(convertedComment);
    }
    if (commentData?.Instruction) {
      const convertedInstruction = htmlToEditorState(commentData?.Instruction);
      setInstructionsComment(convertedInstruction);
    }
  }, [commentData]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    dispatch(setLoader(isTravelListLoading || isCommentLoading));
  }, [isTravelListLoading, isCommentLoading]);

  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
      BidId: bidId,
    };
    dispatch(getJobTravelListData(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = (customerId: string) => {
    setDeleteModal(true);
    setSelectedId(customerId);
  };

  const handleDeleteModal = () => {
    dispatch(onDeleteAccommodationSchedule(selectedId));
    setDeleteModal(false);
  };

  const handleEdit = (id: string) => {
    setHotelId(id);
    setOpen(true);
  };

  useEffect(() => {
    if (addedAccommadation) {
      handleData();
      dispatch(onAddAccommodationScheduleFailure());
    }
  }, [addedAccommadation]);

  const setTravelInfo = () => {
    if (travelDataById?.FlightOutDate) {
      const FlightOutDate = dayjs(new Date(travelDataById?.FlightOutDate));
      if (FlightOutDate) {
        setValue(JobScheduleFields.flightOutDate, FlightOutDate);
      }
    }
    if (travelDataById?.FlightInDate) {
      const FlightInDate = dayjs(new Date(travelDataById?.FlightInDate));
      if (FlightInDate) {
        setValue(JobScheduleFields.flightInDate, FlightInDate);
      }
    }
    if (travelDataById?.FlightOutDepartureTime) {
      const FlightOutDepartureTime = dayjs(travelDataById?.FlightOutDepartureTime, 'hh:mm A');
      if (FlightOutDepartureTime) {
        setFlightOutDipartureTime(FlightOutDepartureTime);
      }
    }
    if (travelDataById?.FlightOutArrivalTime) {
      const FlightOutArrivalTime = dayjs(travelDataById?.FlightOutArrivalTime, 'hh:mm A');
      if (FlightOutArrivalTime) {
        setFlightOutArivalTime(FlightOutArrivalTime);
      }
    }
    if (travelDataById?.FlightInDepartureTime) {
      const FlightInDepartureTime = dayjs(travelDataById?.FlightInDepartureTime, 'hh:mm A');
      if (FlightInDepartureTime) {
        setFlightInDipartureTime(FlightInDepartureTime);
      }
    }
    if (travelDataById?.FlightInArrivalTime) {
      const FlightInArrivalTime = dayjs(travelDataById?.FlightInArrivalTime, 'hh:mm A');
      if (FlightInArrivalTime) {
        setFlightInArivalTime(FlightInArrivalTime);
      }
    }
    assignTravelValues(setValue, travelDataById);
  };

  useEffect(() => {
    if (travelDataById) {
      setTravelInfo();
    }
  }, [travelDataById]);

  const flightInDate = watch(JobScheduleFields.flightInDate);
  const flightOutDate = watch(JobScheduleFields.flightOutDate);

  const isSaveButtonDisabled = useMemo(() => {
    if (flightInDate?.isSame(flightOutDate, 'day') || flightInDate?.isBefore(flightOutDate, 'day')) {
      return true;
    }
    if (flightOutdepartureTime && flightOutarivalTime) {
      if (flightOutdepartureTime?.isBefore(flightOutarivalTime) || flightOutdepartureTime.isSame(flightOutarivalTime)) {
        return true;
      }
    }
    if (flightIndepartureTime && flightInarivalTime) {
      if (flightIndepartureTime?.isBefore(flightInarivalTime) || flightIndepartureTime.isSame(flightInarivalTime)) {
        return true;
      }
    }
    return false;
  }, [flightInDate, flightOutDate, flightOutdepartureTime, flightOutarivalTime, flightIndepartureTime, flightInarivalTime]);

  return (
    <div className="div_container_CustomerInfo">
      <form onSubmit={handleSubmit(handleSave)}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                className="accordionHeading"
                onClick={handleExpand}
                expandIcon={(
                  <div />
                )}
              >
                <div className="customAccordionHeader">
                  <div className="leftSideHeading">
                    <CustomExpandIcon />
                    <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Directions</Typography>
                  </div>
                  <div className="rightSideHeading">
                    {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails className="borderTop">
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Card>
                      <div className="customerInfo_main_container">
                        <Grid container spacing={2}>
                          <Grid item md={12} sm={12} xs={12}>
                            <div className="div_label_text">
                              <label className="label_Style_job">Directions</label>
                              <ComponentTextInput
                                control={control}
                                inputProps={{ maxLength: 200 }}
                                name={JobScheduleFields.travelDirection}
                                id={JobScheduleFields.travelDirection}
                                size="small"
                                rowSize={8}
                                multiline
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FlightOut
              control={control}
              errors={errors}
              flightOutdepartureTime={flightOutdepartureTime}
              setFlightOutDipartureTime={setFlightOutDipartureTime}
              flightOutarivalTime={flightOutarivalTime}
              setFlightOutArivalTime={setFlightOutArivalTime}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <FlightIn
              control={control}
              errors={errors}
              flightOutDate={flightOutDate}
              flightIndepartureTime={flightIndepartureTime}
              setFlightInDipartureTime={setFlightInDipartureTime}
              flightInarivalTime={flightInarivalTime}
              setFlightInArivalTime={setFlightInArivalTime}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <CarRental control={control} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                className="accordionHeading"
                onClick={() => setOpenSpecialInstructionAccordion(!openSpecialInstructionAccordion)}
                expandIcon={(
                  <div />
                )}
              >
                <div className="customAccordionHeader">
                  <div className="leftSideHeading">
                    <CustomExpandIcon />
                    <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Customer Comments</Typography>
                  </div>
                  <div className="rightSideHeading">
                    {openSpecialInstructionAccordion ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="borderTop">
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Card>
                      <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Card className="comment-card">
                            <div className="customerInfo_main_container">
                              <Grid container spacing={2}>
                                <Grid item md={12} sm={12} xs={12}>
                                  <div className="editorContainer">
                                    <Editor
                                      editorState={comment}
                                      onEditorStateChange={handleComment}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      localization={{ locale: 'en' }}
                                      toolbar={customOptions}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Card>

                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Accordion>
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                className="accordionHeading"
                onClick={() => setOpenCustomerInstructionAccordion(!openCustomerInstructionAccordion)}
                expandIcon={(
                  <div />
                )}
              >
                <div className="customAccordionHeader">
                  <div className="leftSideHeading">
                    <CustomExpandIcon />
                    <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Customer Instructions</Typography>
                  </div>
                  <div className="rightSideHeading">
                    {openCustomerInstructionAccordion ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className="borderTop">
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Card>
                      <div className="customerInfo_main_container">
                        <Grid container spacing={2}>
                          <Grid item md={12} sm={12} xs={12}>
                            <Card className="comment-card">
                              <Grid container spacing={2}>
                                <Grid item md={12} sm={12} xs={12}>
                                  <div className="editorContainer">
                                    <Editor
                                      editorState={commentInstructions}
                                      onEditorStateChange={handleInstructionsComment}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="demo-editor"
                                      localization={{ locale: 'en' }}
                                      toolbar={customOptions}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '0px' }}>
          <Grid item md={12} sm={12} xs={12}>
            <DataTable<AccommodationTableData>
              data={accommodationJobsData(jobTravelListData?.Data)}
              handleButtonClick={() => {
                setSelectedRadio('');
                setHotelId('');
                setOpen(true);
              }}
              buttonText="Add Accommodation"
              columns={AccommodationTableColumns}
              totalRecords={jobTravelListData?.TotalRecords}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleData={handleData}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => dispatch(setSelectedTab(1))}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                // onClick={handleSave}
                  type="submit"
                  disabled={isSaveButtonDisabled}
                  className={isSaveButtonDisabled ? 'disableBtn' : 'button_save_and_next'}
                >
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
      <DialogBoxHotelEdit
        hotelId={hotelId}
        selectedRadio={selectedRadio}
        onClickFind={() => {
          setOpen(false);
          setOpenHotelListing(true);
        }}
        setSelectedRadio={() => setSelectedRadio('')}
        show={open}
        setOpenFrom={() => setOpen(false)}
      />
      <HotelListingModal
        selectedRadio={selectedRadio}
        onSelect={() => {
          setOpenHotelListing(false);
          setOpen(true);
        }}
        setSelectedRadio={setSelectedRadio}
        show={openHotelListing}
        setOpenFrom={() => setOpenHotelListing(false)}
      />
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </div>
  );
}
