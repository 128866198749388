import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResetDataTypes, ResetTypes } from '../utils/types';

const initialState: ResetDataTypes = {
  resetPasswordData: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

export const resetSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    sendResetPasswordData(state, action: PayloadAction<ResetTypes>) {
      return {
        ...state,
        isLoading: true,
        resetPasswordData: action.payload,
      };
    },
    resetPasswordSuccess(state) {
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    },
    resetPasswordFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: action.payload,
      };
    },
    resetStates(state) {
      return {
        ...state,
        isSuccess: false,
        isError: false,
        errorMessage: '',
      };
    },
  },
});

export const {
  sendResetPasswordData, resetPasswordSuccess, resetPasswordFailure, resetStates,
} = resetSlice.actions;
export const resetPasswordReducer = resetSlice.reducer;

export type ResetPasswordActions =
  | ReturnType<typeof sendResetPasswordData>
  | ReturnType<typeof resetPasswordSuccess>
  | ReturnType<typeof resetStates>
  | ReturnType<typeof resetPasswordFailure>;
