import { combineReducers } from '@reduxjs/toolkit';
import { todoReducer } from './todos/todoSlice';
import counter from './counter';
import { authReducer } from '../Screens/Login/ScreenLogIn/redux/sliceLogin';
import { addSmcDetailsReducer } from '../Screens/ScreenAddSmc/Redux/sliceAddSmc';
import { otpReducer } from '../Screens/Login/ScreenOtpVerify/redux/sliceOtpVerify';
import { forgotPasswordReducer } from '../Screens/Login/ScreenForgotPassword/redux/sliceForgotPassword';
import { resetPasswordReducer } from '../Screens/Login/ScreenReset/redux/sliceReset';
import { userProfileReducer } from '../Screens/Profile/Redux/sliceMyProfile';
import { addCustomerReducer } from '../Screens/addCustomer/redux/addCustomerSlice';
import { logoutReducer } from '../Screens/Logout/redux/sliceLogout';
import { getSmcUserReducer } from '../Screens/smc/Redux/sliceSmc';
import { masterDataReducer } from './masters/masterSlice';
import { customerReducer } from '../Screens/customer/redux/customerSlice';
import { loaderReducer } from './loaderSlice';
import { KitPriceReducer } from '../Screens/System/KitPrices/Redux/sliceKitPrice';
import { SystemPriceListReducer } from '../Screens/System/PriceList/Redux/slicePriceList';
import { createFreonReducer } from '../Screens/Kits/FreonCylinder/AddFreonCylinder/redux/sliceAddFreonCylinder';
import { SystemMarkupReducer } from '../Screens/System/Markups/Redux/sliceMarkups';
import { jobStarterReducer } from '../Screens/Job/jobStarter/addJob/redux/addJobSlice';
import { jobsReducer } from '../Screens/Job/jobStarter/redux/jobStarterSlice';
import { FreonListReducer } from '../Screens/Kits/FreonCylinder/FreonCylinderList/redux/sliceListFreonCylinder';
import { dashboardReducer } from '../Screens/ComponentNavBar/redux/dashboardSlice';
import { unitInfoReducer } from '../Screens/Job/ScreenUnitInformation/redux/unitInfoSlice';
import { bidsReducer } from '../Screens/Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import { oilKitsSliceReducer } from '../Screens/Kits/OilKits/ScreenOilKits/redux/oilKitsSlice';
import { addOilKitsReducer } from '../Screens/Kits/OilKits/ScreenAddOilKits/redux/addOilKitsSlice';
import { ChangePasswordReducer } from '../Screens/ChangePassword/Redux/sliceChangePwd';
import { AnalystListSliceReducer } from '../Screens/AnalystScreen/AnalystListScreen/redux/AnalystListSlice';
import { AddAnalystSliceReducer } from '../Screens/AnalystScreen/AnalystAddScreen/redux/AddAnalystSlice';
import { jobScheduleReducer } from '../Screens/JobScheduling/UnscheduledJobs/addJob/redux/jobScheduleSlice';
import { equipmentReducer } from '../Screens/Job/ScreenTestEquipmentRequest/redux/sliceTestEquipments';
import { ListTestEndReducer } from '../Screens/Lists/TestEndLists/redux/sliceTestEndList';
import { ListSupportReducer } from '../Screens/Lists/SupportLists/redux/sliceSupportList';
import { ListManufacturerReducer } from '../Screens/Lists/ManufacturerLists/redux/sliceManufacturerList';
import { ListMaterialReducer } from '../Screens/Lists/MaterialLists/redux/sliceMaterialList';
import { ListVesselReducer } from '../Screens/Lists/VesselLists/redux/sliceVesselList';
import { ListTubeReducer } from '../Screens/Lists/TubeList/redux/sliceTubeList';
import { ListTypeReducer } from '../Screens/Lists/UnitTypeLists/redux/sliceTypeList';
import { ListStyleReducer } from '../Screens/Lists/UnitStyleLists/redux/sliceStyleList';
import { ListReportReducer } from '../Screens/Lists/ReportList/redux/sliceReportList';
import { bidPdfSliceReducer } from '../Screens/ScreenBidPdf/redux/bidPdfSlice';
import { CalenderReducer } from '../Screens/calender/redux/calenderSlice';
import { ListDefaultReducer } from '../Screens/Lists/DefaultTypeStyleList/redux/sliceDefaultTypeList';
import { siteReducer } from '../Screens/Sites/redux/sliceSites';
import { CalibrationProcedureReducer } from '../Screens/Utilities/calibrationProcedure/Redux/sliceCalibration';
import { RecommendationCodesReducer } from '../Screens/Utilities/recommendationCodes/Redux/sliceRecommendationCodes';
import { inventoryReducer } from '../Screens/InventoryManagement/InventoryLandingPage/redux/inventorySlice';
import { inventoryEquipmentRequestReducer } from '../Screens/InventoryManagement/TestEquipmentRequestScreen/redux/equipmentRequestSlice';
import { defectCodeReducer } from '../Screens/Utilities/AnalystDefectMenu/redux/sliceDefectCode';
import { ListDefectCodeReducer } from '../Screens/Utilities/DefectCode/redux/sliceDefectCodeList';
import { ListEquipmentCategoryReducer } from '../Screens/Lists/EquipmentCategoryLists/redux/sliceEquipmentCategoryList';
import { sendEquipmentRequestReducer } from '../Screens/InventoryManagement/SendEquipmentScreen/redux/sendEquipmentScreenSlice';
import { oilReportReducer } from '../Screens/oilReport/redux/oilReportSlice';
import { userManagementReducer } from '../Screens/UserManagement/redux/sliceUserManagement';
import { VesselInformationReducer } from '../Screens/VesselInformation/redux/sliceVesselInformation';
import { jobOrderReducer } from '../Screens/jobOrder/redux/sliceJobOrder';
import { boardPositionReducer } from '../Screens/boardPosition/redux/sliceBoardPosition';
import { inspectionSummaryReducer } from '../Screens/inspectionSummary/redux/sliceInspectionSummary';
import { VesselDefectReducer } from '../Screens/VesselInformation/redux/DefectRedux/sliceVesselDefect';
import { recommendationReducer } from '../Screens/ScreenRecommendations/redux/recommendationSlice';
import { expenseReportReducer } from '../Screens/expenseReports/redux/expenseReportSlice';
import { dataSheetReducer } from '../Screens/datasheets/redux/sliceDataSheet';
import { BayLengthReducer } from '../Screens/VesselInformation/redux/baylength/baylengthSlice';
import { VesselMainReducer } from '../Screens/VesselInformation/redux/VesselMainRedux/vesselMainSlice';
import { photosReducer } from '../Screens/photos/redux/photosSlice';
import { TubeChartReducer } from '../Screens/VesselInformation/redux/tubeChart/tubeChartSlice';
import { hazardAnalysisReducer } from '../Screens/ScreenHazardAnalysis/redux/hazardAnalysisSlice';
import { reportPdfSliceReducer } from '../Screens/ScreenReportPdf/redux/reportPdfSlice';
import { reportWorkspaceReducer } from '../Screens/taiReportingSystem/redux/sliceReportWorkspace';
import { inspectionLetterReducer } from '../Screens/inspectionLetter/redux/sliceInspectionLetter';

export const rootReducer = combineReducers({
  counter,
  todoReducer,
  authReducer,
  addSmcDetailsReducer,
  otpReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  userProfileReducer,
  addCustomerReducer,
  logoutReducer,
  getSmcUserReducer,
  masterDataReducer,
  customerReducer,
  loaderReducer,
  KitPriceReducer,
  SystemPriceListReducer,
  SystemMarkupReducer,
  jobStarterReducer,
  jobsReducer,
  createFreonReducer,
  FreonListReducer,
  dashboardReducer,
  unitInfoReducer,
  bidsReducer,
  oilKitsSliceReducer,
  addOilKitsReducer,
  ChangePasswordReducer,
  AddAnalystSliceReducer,
  AnalystListSliceReducer,
  jobScheduleReducer,
  equipmentReducer,
  ListTypeReducer,
  ListTestEndReducer,
  ListSupportReducer,
  ListManufacturerReducer,
  ListMaterialReducer,
  ListVesselReducer,
  ListTubeReducer,
  ListStyleReducer,
  ListReportReducer,
  ListDefaultReducer,
  bidPdfSliceReducer,
  CalenderReducer,
  siteReducer,
  CalibrationProcedureReducer,
  RecommendationCodesReducer,
  inventoryReducer,
  inventoryEquipmentRequestReducer,
  defectCodeReducer,
  ListDefectCodeReducer,
  ListEquipmentCategoryReducer,
  sendEquipmentRequestReducer,
  oilReportReducer,
  userManagementReducer,
  VesselInformationReducer,
  jobOrderReducer,
  boardPositionReducer,
  inspectionSummaryReducer,
  VesselDefectReducer,
  recommendationReducer,
  expenseReportReducer,
  dataSheetReducer,
  BayLengthReducer,
  VesselMainReducer,
  photosReducer,
  TubeChartReducer,
  hazardAnalysisReducer,
  reportPdfSliceReducer,
  reportWorkspaceReducer,
  inspectionLetterReducer,
});
