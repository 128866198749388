import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  Table, TableCell, TableContainer, TableHead, TableRow, Paper, TableBody, MenuItem, Card,
} from '@mui/material';
// import moment from 'moment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './TableStyle.scss';
import TableHeader from './TableHeader';
import { DeleteIcon, EditIcon } from '../../assets/TableIcons';
import { getTableHeaderData } from './Common/DataHeaderCell';
import ComponentSubHeader from '../ComponentNavBar/ComponentSubHeader';
import ComponentTableHeader from '../../Common/TableHeader/ComponentTableHeader';

const theme = createTheme({
  palette: {
    primary: {
      main: '#092245',
      contrastText: '#fff',
    },
  },
});

export default function TableData() {
  // const dispatch = useDispatch();
  const [pages, setPages] = useState('10');

  const handleChange = (event: SelectChangeEvent) => {
    setPages(event.target.value);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value - 1);
  };
  const headerData = getTableHeaderData();
  const ITEM_PER_PAGE = 1;
  //   const {
  //     listDataDashboard,
  //     searchData,
  //   } = useSelector((store) => store.reducerPages);

  //   useEffect(() => {
  //     if (searchData !== undefined && searchData !== '' && currentPage !== 0) {
  //       setCurrentPage(0);
  //     }
  //   }, [searchData]);

  //   useEffect(() => {
  //     dispatch(dashboardDetails({ size: ITEM_PER_PAGE, page: currentPage, searchStr: searchData }));
  //   }, [currentPage, searchData]);

  // const listData = listDataDashboard.rows;
  // const totalPages = listDataDashboard.count;

  return (
    <ThemeProvider theme={theme}>
      <div>
        <ComponentSubHeader subHeading="Customer" />
      </div>
      <TableHeader />
      <Card className="table-Card-style">
        <TableContainer component={Paper} className="customPaper">
          {/* <TableHeader /> */}
          <Table stickyHeader className="tableStyle">
            <TableHead>
              <TableRow>
                {
                  headerData.map(({ colWidth, header }) => (
                    <ComponentTableHeader key={colWidth} colWidth={colWidth} header={header} />
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {/* { */}
              {/* listData?.length === 0 ? (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: 'hidden' }}
                                >
                                    <TableCell align="center" className="mt-16">
                                        No Record Found!!
                                    </TableCell>

                                </TableRow>
                            ) */}
              {/* : listData?.map((row) => ( */}
              <TableRow
                // sx={{ '&:last-child td, &:last-child th': { border: 0 }, borderBottom: 'hidden' }}
                // key={row.id}
                className="table-row-height"
              >
                <TableCell className="wdth-3">1</TableCell>
                <TableCell className="wdth-3">
                  <div className="tableCell-edit">
                    <div><EditIcon /></div>
                    <div><DeleteIcon /></div>
                  </div>
                </TableCell>
                <TableCell className="wdth-5">AE</TableCell>
                <TableCell className="wdth-6">1003</TableCell>
                <TableCell className="wdth-10">Climate Control System,INC</TableCell>
                <TableCell className="wdth-10">7745 Durham Circle</TableCell>
                <TableCell className="wdth-10">Juana Diaz</TableCell>
                <TableCell className="wdth-5">TX</TableCell>
                <TableCell className="wdth-6">3369874587</TableCell>
                <TableCell className="wdth-5">TAI ID</TableCell>
              </TableRow>
              {/* )) */}
              {/* // } */}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="table-pagination-style">
          <div>
            <span className="font-size">Show</span>
            <span className="select-style">
              <Select
                value={pages}
                className="table-select-style"
                onChange={handleChange}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
              </Select>
            </span>
            <span className="font-size">entries</span>
          </div>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(100 / ITEM_PER_PAGE)}
              page={currentPage + 1}
              onChange={handleChangePagination}
              color="primary"
              shape="rounded"
            />
          </Stack>
        </div>
      </Card>
    </ThemeProvider>
  );
}
