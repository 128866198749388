import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddReportListApiResponse, AddReportListData, DeleteReportListResponse, GetAllReportListApiResponse,
  PayloadTypeDeleteReportList, PayloadTypeGetReportList, UpdateReportListApiResponse, UpdateReportListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addReportListStart, deleteReportListStart, failureAddReportList, failureDeleteReportList, failureReportList,
  failureUpdateReportList, getReportListStart,
  ReportTypeActions, successAddReportList, successDeleteReportList, successReportList, successUpdateReportList, updateReportList,
} from './sliceReportList';

async function GetReportList(data: PayloadTypeGetReportList): Promise<GetAllReportListApiResponse> {
  const url = `${Config.Lists.getReportList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllReportListApiResponse>(url);
  return result?.data;
}

export const epicGetReportList = (action$: Observable<ReportTypeActions>) => action$.pipe(
  filter(getReportListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetReportList) => from(GetReportList(data)).pipe(
    map((res: GetAllReportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successReportList(res?.BMT?.Result);
      }
      return failureReportList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getReportListStart.match))),
    catchError((error: AxiosError<GetAllReportListApiResponse>) => of(failureReportList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateReportList(data: UpdateReportListData): Promise<UpdateReportListApiResponse> {
  const url = `${Config.Lists.updateReportList}`;
  const result = await makePostRequest<UpdateReportListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateReportList = (action$: Observable<ReportTypeActions>) => action$.pipe(
  filter(updateReportList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateReportList(data)).pipe(
    mergeMap((res: UpdateReportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateReportList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateReportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateReportList.match))),
    catchError((error: AxiosError<UpdateReportListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateReportList(errorMessage));
    }),
  )),
);

async function AddReportList(data: AddReportListData): Promise<AddReportListApiResponse> {
  const url = `${Config.Lists.addReportList}`;
  const result = await makePostRequest<AddReportListApiResponse>(url, data);
  return result?.data;
}

export const epicAddReportList = (action$: Observable<ReportTypeActions>) => action$.pipe(
  filter(addReportListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddReportList(data)).pipe(
    mergeMap((res: AddReportListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddReportList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddReportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addReportListStart.match))),
    catchError((error: AxiosError<AddReportListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddReportList(errorMessage));
    }),
  )),
);

async function DeleteReportList(data: PayloadTypeDeleteReportList): Promise<DeleteReportListResponse> {
  const url = `${Config.Lists.deleteReportList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteReportListResponse>(url);
  return result?.data;
}

export const epicDeleteReportList = (action$: Observable<ReportTypeActions>) => action$.pipe(
  filter(deleteReportListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteReportList) => from(DeleteReportList(data)).pipe(
    mergeMap((res: DeleteReportListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteReportList(), getReportListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteReportList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteReportListStart.match))),
    catchError((error: AxiosError<DeleteReportListResponse>) => of(failureDeleteReportList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
