import { Card } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetTubeChartPayload, TubeChartConfiguration, TubeChartTableRow } from '../../utils/tubeChartTypes';
import { TubeChartTableColumns } from '../../utils/helper';
import DataTable from '../../../../Common/DataTable';
import { getTubeChartsStart } from '../../redux/tubeChart/tubeChartSlice';
import { RootState } from '../../../../redux/rootState';
import localStorageConstants from '../../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../../Routes/constant';

export default function TubeList() {
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  const { tubeCharts } = useSelector((state:RootState) => state.TubeChartReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  useEffect(() => {
    const payload:GetTubeChartPayload = {
      JobOrder: String(jobOrderId),
      VesselId: String(vesselId),
      Type: 1,
    };
    dispatch(getTubeChartsStart(payload));
  }, []);

  const tableData: TubeChartTableRow[] = useMemo(() => {
    if (tubeCharts.length > 0) {
      const transformedData = tubeCharts.map((ele:TubeChartConfiguration, index) => ({
        sn: index,
        action: 'edit',
        section: ele.Section,
        offset: ele.Offset,
        numberRows: ele.NumberOfRows,
        rowLength: ele.RowLength,
        tubeCount: ele.TubeCount,
        id: String(ele.Id),
      }));
      return transformedData;
    }
    return [];
  }, [tubeCharts]);

  const handleEdit = (id: string) => {
    window.open(`/tube-chart/${id}?jobOrder=${jobOrderId}&vessel=${vesselId}`);
  };

  const handleButtonClick = () => {
    window.open(`/tube-chart?type=all&jobOrder=${jobOrderId}&vessel=${vesselId}`);
  };

  return (
    <Card>
      {(roleId === ANALYST_ROLE_ID) ? (
        <DataTable<TubeChartTableRow>
          data={tableData}
          columns={TubeChartTableColumns}
          totalRecords={10}
          handleData={() => {}}
        />
      ) : (
        <DataTable<TubeChartTableRow>
          data={tableData}
          columns={TubeChartTableColumns}
          totalRecords={10}
          handleData={() => {}}
          buttonText="Multi-Offset"
          handleButtonClick={handleButtonClick}
          handleEdit={handleEdit}
        />
      )}
      {/* <div className="customerInfo_container">
        <div className="customerInfo">
          Header Information
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Tube Size
                <RequiredFiled />
              </label>
              <tComponentSelectInput
                name={TubeChartEnum.tubeSize}
                control={control}
                size="small"
                entries={[]}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Number Of Rows
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={TubeChartEnum.numberOfRows}
                id={TubeChartEnum.numberOfRows}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Row Length
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={TubeChartEnum.rowLength}
                id={TubeChartEnum.rowLength}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Vessel Top
                <RequiredFiled />
              </label>
              <tComponentSelectInput
                name={TubeChartEnum.vesselTop}
                control={control}
                size="small"
                entries={[]}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                Offset
                <RequiredFiled />
              </label>
              <tComponentSelectInput
                name={TubeChartEnum.offset}
                control={control}
                size="small"
                entries={[]}
              />
            </div>
          </Grid>
          <Grid item md={2} sm={6} xs={12}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                # of Tube
              </label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                name={TubeChartEnum.numberOfTube}
                id={TubeChartEnum.numberOfTube}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
        </Grid>
      </div> */}
    </Card>
  );
}
