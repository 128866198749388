/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable max-len */
import axios, { InternalAxiosRequestConfig } from 'axios';
import { debugLog } from './Logger';
import localStorageConstants from '../utils/LocalStorageConstants';
import Config from './Config';
import { Empty } from '../Screens/ScreenAddSmc/Utils/TypeSmc';
import { FORBIDDEN_CODE, REFRESH_TOKEN_CODE } from '../utils/Constant';

const TAG = 'NetworkOps: ';
const unAuthRoutes: string[] = [Config.auth.generateOTP, Config.auth.forgotPassword, Config.auth.resetPassword, Config.auth.sendOtp, Config.auth.otpVerify];

const API_TIMEOUT = 10000;

export const instance = axios.create({
  timeout: API_TIMEOUT,
});

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    let newConfig = config;
    const configUrl = config.url ?? '';
    const token = localStorage.getItem(localStorageConstants.TOKEN) || '';
    const userId = localStorage.getItem(localStorageConstants.USER_ID) || '';
    try {
      const isTokenRequired = !unAuthRoutes.includes(configUrl);
      if (isTokenRequired) {
        // const token = '';
        newConfig = {
          ...newConfig,
          // @ts-ignore
          headers: {
            ...newConfig.headers,
            // Authorization: '',
            Authorization: `Bearer ${token}`,
            LoginUserId: userId,
          },
          timeout: API_TIMEOUT,
        };
      } else {
        newConfig = {
          ...newConfig,
          timeout: API_TIMEOUT,
          // @ts-ignore
          headers: {
            ...newConfig.headers,
          },
        };
      }
    } catch (e) {
      debugLog(TAG, `Error in interceptor request: ${(e as Error).message}`);
    }
    return newConfig;
  },
  (error) => {
    debugLog(TAG, `Error in interceptor request: ${(error as Error).message}`);
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let failedQueue: Empty = [];

const processQueue = (error: Empty, token = null) => {
  failedQueue.forEach((prom: Empty) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => response,
  (err) => {
    const originalRequest = err.config;
    if (err.response.status === REFRESH_TOKEN_CODE && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest);
          })
          .catch((error) => Promise.reject(error));
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const token = localStorage.getItem(localStorageConstants.REFRESH_TOKEN) || '';
      const userId = localStorage.getItem(localStorageConstants.USER_ID) || '';
      const url = Config.auth.refreshToken;
      const payload = {
        UserId: userId,
        RefreshToken: token,
      };
      return new Promise((resolve, reject) => {
        axios
          .post(url, payload)
          .then(({ data }) => {
            if (data?.BMT?.Result) {
              localStorage.setItem(localStorageConstants.TOKEN, data?.BMT?.Result?.Token);
              localStorage.setItem(localStorageConstants.REFRESH_TOKEN, data?.BMT?.Result?.RefreshToken);
            }
            axios.defaults.headers.common.Authorization = `Bearer ${data?.BMT?.Result?.Token}`;
            originalRequest.headers.Authorization = `Bearer ${data?.BMT?.Result?.Token}`;
            processQueue(null, data?.BMT?.Result?.Token);
            resolve(axios(originalRequest));
          })
          .catch((e) => {
            localStorage.clear();
            processQueue(e, null);
            window.location.reload();
            reject(e);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  },
);

export const makeGetRequest = <T>(URL: string) => instance.get<T>(URL);
export const makePostRequest = <T>(URL: string, data = {}) => instance.post<T>(
  URL,
  { ...data },
);
export const makePutRequest = <T>(URL: string, data = {}) => instance.put<T>(URL, { ...data });
export const makePatchRequest = <T>(URL: string, data = {}) => instance.patch<T>(URL, { ...data });
export const makeDeleteRequest = <T>(URL: string) => instance.delete<T>(URL);
export const makeFormPostRequest = <T>(URL: string, data = {}, config = {}) => instance.post<T>(
  URL,
  data,
  config,
);
