import React from 'react';
import InspectionLetterTableComponent from './InspectionLetterTableComponent';

function ScreenSystemLogs() {
  return (
    <InspectionLetterTableComponent />
  );
}

export default ScreenSystemLogs;
