import React, { useState } from 'react';
import {
  Grid, Card, Typography, TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Accordion, AccordionDetails, AccordionSummary, CustomExpandIcon,
} from '../../../../../../utils/AccordionUtils/AccordionUtils';
import ComponentSiteInfo from './siteInfo';
import ComponentTextInput from '../../../../../../Common/ComponentTextinput/ComponentTextInput';
import { extRegex, phoneRegex } from '../../../../../../utils/regex';
import ComponentSelectInput from '../../../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../../../redux/rootState';

import { ISiteInfo } from '../../../utils/types';
import { JobScheduleFields } from '../../../utils/constants';

function ClientInfo({
  control, setValue, watch, errors,
}: ISiteInfo) {
  const [expanded, setExpanded] = useState(false);
  const { phoneCodes } = useSelector((state: RootState) => state.masterDataReducer);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="panel2d-content"
        id="panel2d-header"
        className="accordionHeading"
        onClick={handleExpand}
        expandIcon={(
          <div />
        )}
      >
        <div className="customAccordionHeader">
          <div className="leftSideHeading">
            <CustomExpandIcon />
            <Typography style={{ marginLeft: '8px', marginRight: '8px' }} className="typography_Accordion">Job & Site</Typography>
          </div>
          <div className="rightSideHeading">
            {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails className="borderTop">
        <form>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Card>
                <div className="customerInfo_container">
                  <div className="customerInfo">
                    Client
                  </div>
                  <div className="div_label_text commonCenter">
                    <label className="label_Style_job">Customer #</label>
                    <Controller
                      control={control}
                      name={JobScheduleFields.customerId}
                      render={({ field: { value } }) => (
                        <TextField
                          size="small"
                          disabled
                          placeholder="Customer Id *"
                          value={value}
                          className="mob-wd-100"
                        />
                      )}
                    />
                  </div>

                </div>
                <div className="customerInfo_main_container">
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Company</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={JobScheduleFields.company}
                          id={JobScheduleFields.company}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Contact</label>
                        <ComponentTextInput
                          control={control}
                          name={JobScheduleFields.contact}
                          isDisable
                          id={JobScheduleFields.contact}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Address</label>
                        <ComponentTextInput
                          control={control}
                          name={JobScheduleFields.address}
                          isDisable
                          id={JobScheduleFields.address}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Address (Optional)</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={JobScheduleFields.addressOptional}
                          id={JobScheduleFields.addressOptional}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={3} sm={3} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Zip</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={JobScheduleFields.zip}
                          id={JobScheduleFields.zip}
                          regEx={extRegex}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={4.5} sm={4.5} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">State</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={JobScheduleFields.state}
                          id={JobScheduleFields.state}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={4.5} sm={4.5} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">City</label>
                        <ComponentTextInput
                          control={control}
                          isDisable
                          name={JobScheduleFields.city}
                          id={JobScheduleFields.city}
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} sm={2} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Code</label>
                        <ComponentSelectInput
                          name={JobScheduleFields.code}
                          control={control}
                          disabled
                          defaultValue="+1"
                          size="small"
                          entries={phoneCodes}
                        />
                      </div>
                    </Grid>
                    <Grid item md={5} sm={5} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Phone</label>
                        <ComponentTextInput
                          control={control}
                          name={JobScheduleFields.phone}
                          id={JobScheduleFields.phone}
                          regEx={phoneRegex}
                          isDisable
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <div style={{ padding: '20px 0px' }}>
                        <div className="contact_info_text">Contact Information</div>
                      </div>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Contact</label>
                        <ComponentTextInput
                          control={control}
                          regEx={phoneRegex}
                          name={JobScheduleFields.contactInfo}
                          id={JobScheduleFields.contactInfo}
                          size="small"
                          isDisable
                        />
                      </div>
                    </Grid>
                    <Grid item md={5} sm={5} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Cell</label>
                        <ComponentTextInput
                          control={control}
                          name={JobScheduleFields.cellInfo}
                          defaultValue=""
                          id={JobScheduleFields.cellInfo}
                          regEx={phoneRegex}
                          size="small"
                          isDisable
                        />
                      </div>
                    </Grid>
                    <Grid item md={2} sm={2} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Code</label>
                        <ComponentSelectInput
                          name={JobScheduleFields.codeInfo}
                          control={control}
                          defaultValue="+1"
                          size="small"
                          entries={phoneCodes}
                          disabled
                        />
                      </div>
                    </Grid>
                    <Grid item md={5} sm={5} xs={12}>
                      <div className="div_label_text">
                        <label className="label_Style_job">Phone</label>
                        <ComponentTextInput
                          control={control}
                          name={JobScheduleFields.phoneInfo}
                          id={JobScheduleFields.phoneInfo}
                          defaultValue=""
                          regEx={phoneRegex}
                          size="small"
                          isDisable
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ComponentSiteInfo
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
              />
            </Grid>
          </Grid>
        </form>
      </AccordionDetails>
    </Accordion>
  );
}

export default ClientInfo;
