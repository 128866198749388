import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootState';

function ComponentHazardTable() {
  const { reportPdfResponse4 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <div style={{ marginTop: '20px' }}>
      <table className="bordered-table w100">
        <tr>
          <td className=" tableGreenHeading textCenter boldText">Job Order</td>
          <td className="textCenter">{reportPdfResponse4?.Hazard_Analysis?.[0]?.JobOrder}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter boldText">Site</td>
          <td className="textCenter">{reportPdfResponse4?.Hazard_Analysis?.[0]?.SiteName}</td>
        </tr>
        <tr>
          <td className=" tableGreenHeading textCenter boldText">Analyst</td>
          <td className="textCenter">{reportPdfResponse4?.JobOrderData?.AnalystName}</td>
        </tr>
      </table>
    </div>
  );
}

export default ComponentHazardTable;
