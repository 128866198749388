/* eslint-disable max-len */
import React from 'react';

export function EditIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1854 3.6856L8.24046 1.76353L9.29688 0.707107L11.2304 2.64063L10.1854 3.6856ZM0.5 11.4375V9.48836L6.625 3.36336L8.57414 5.3125L2.44914 11.4375H0.5Z" stroke="#00A751" />
    </svg>
  );
}
