import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationContext } from '../Localization/LocalizationProvider';
import './Styles.scss';
// eslint-disable-next-line import/no-cycle
import DataTable from '../DataTable';
import { RootState } from '../../redux/rootState';
import { IAddSMCModal, SMCData } from './utils/types';
import { getSmcUserStart } from '../../Screens/smc/Redux/sliceSmc';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: '6px 24px 24px 24px',
  '@media (max-width: 1200px)': {
    width: '95%',
  },
  '@media (max-width: 768px)': {
    width: '95%',
    height: 'auto',
    p: 2,
  },
};
export default function AddSMCModal({ show, setOpenFrom, handleTabCell }: IAddSMCModal) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { smcList, totalEntries } = useSelector((state: RootState) => state.getSmcUserReducer);
  const [selectedRadio, setSelectedRadio] = useState('');

  const dispatch = useDispatch();
  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };

    dispatch(getSmcUserStart(params));
  };

  useEffect(() => {
    if (smcList.length === 0) {
      handleData();
    }
  }, []);

  const { translations } = useContext(LocalizationContext);
  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      // { key: 'sn', label: 'SN' },
      { key: 'SmcName', label: 'SMC' },
      { key: 'TaiIdNo', label: 'TAI ID Number' },
      { key: 'Subscriber', label: 'Subscriber' },
    ],
    [],
  );
  const data: SMCData[] = useMemo(() => smcList.map((item, index) => {
    const transformedDataItem = {
      SmcName: item?.SmcName,
      TaiIdNo: item?.TaiIdNo,
      Subscriber: item?.Subscriber,
      sn: index,
      id: item?.SmcId,
    };
    return transformedDataItem;
  }), [smcList]);

  const handleSave = () => {
    handleTabCell(selectedRadio);
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={setOpenFrom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="customerInfo_container flex-row">
            <div className="customerInfo">
              {translations.SMC}
            </div>
            <div onClick={setOpenFrom}>
              <CloseIcon />
            </div>
          </div>
          <DataTable<SMCData>
            data={data}
            columns={columns}
            totalRecords={totalEntries}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleData={handleData}
            selectedRadio={selectedRadio}
            setSelectedRadio={setSelectedRadio}
            radioButtonColumns={['select']}
          />
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={setOpenFrom}>{translations.cancel}</Button>
              </div>
              <div className="button_margin_left">
                <Button className="button_save_and_next" disabled={!selectedRadio} onClick={handleSave}>{translations.select}</Button>
              </div>
            </div>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
