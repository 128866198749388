import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import Config from '../../../../Common/Config';
import {
  makeDeleteRequest, makeGetRequest, makePostRequest,
} from '../../../../Common/NetworkOps';
import {
  AddTestEndListApiResponse, AddTestEndListData, DeleteTestEndListResponse, GetAllTestEndListApiResponse,
  PayloadTypeDeleteTestEndList, PayloadTypeGetTestEndList, UpdateTestEndListApiResponse, UpdateTestEndListData,
} from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  addTestEndListStart, deleteTestEndListStart, failureAddTestEndList, failureDeleteTestEndList, failureTestEndList,
  failureUpdateTestEndList, getTestEndListStart,
  TestEndTypeActions, successAddTestEndList, successDeleteTestEndList, successTestEndList, successUpdateTestEndList, updateTestEndList,
} from './sliceTestEndList';

async function GetTestEndList(data: PayloadTypeGetTestEndList): Promise<GetAllTestEndListApiResponse> {
  const url = `${Config.Lists.getTestEndList}?PageNumber=${data.page}&PageSize=${data.rowsPerPage}&searchItem=${data.searchQuery}`;
  const result = await makeGetRequest<GetAllTestEndListApiResponse>(url);
  return result?.data;
}

export const epicGetTestEndList = (action$: Observable<TestEndTypeActions>) => action$.pipe(
  filter(getTestEndListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeGetTestEndList) => from(GetTestEndList(data)).pipe(
    map((res: GetAllTestEndListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return successTestEndList(res?.BMT?.Result);
      }
      return failureTestEndList(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getTestEndListStart.match))),
    catchError((error: AxiosError<GetAllTestEndListApiResponse>) => of(failureTestEndList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);

async function UpdateTestEndList(data: UpdateTestEndListData): Promise<UpdateTestEndListApiResponse> {
  const url = `${Config.Lists.updateTestEndList}`;
  const result = await makePostRequest<UpdateTestEndListApiResponse>(url, data);
  return result?.data;
}

export const epicUpdateTestEndList = (action$: Observable<TestEndTypeActions>) => action$.pipe(
  filter(updateTestEndList.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(UpdateTestEndList(data)).pipe(
    mergeMap((res: UpdateTestEndListApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successUpdateTestEndList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureUpdateTestEndList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(updateTestEndList.match))),
    catchError((error: AxiosError<UpdateTestEndListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureUpdateTestEndList(errorMessage));
    }),
  )),
);

async function AddTestEndList(data: AddTestEndListData): Promise<AddTestEndListApiResponse> {
  const url = `${Config.Lists.addTestEndList}`;
  const result = await makePostRequest<AddTestEndListApiResponse>(url, data);
  return result?.data;
}

export const epicAddTestEndList = (action$: Observable<TestEndTypeActions>) => action$.pipe(
  filter(addTestEndListStart.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(AddTestEndList(data)).pipe(
    mergeMap((res: AddTestEndListApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successAddTestEndList());
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureAddTestEndList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(addTestEndListStart.match))),
    catchError((error: AxiosError<AddTestEndListApiResponse>) => {
      const errorMessage = error?.response?.data?.BMT?.ResponseMessage as string;
      of(showErrorToaster(errorMessage));
      return of(failureAddTestEndList(errorMessage));
    }),
  )),
);

async function DeleteTestEndList(data: PayloadTypeDeleteTestEndList): Promise<DeleteTestEndListResponse> {
  const url = `${Config.Lists.deleteTestEndList}?Id=${data.Id}`;
  const result = await makeDeleteRequest<DeleteTestEndListResponse>(url);
  return result?.data;
}

export const epicDeleteTestEndList = (action$: Observable<TestEndTypeActions>) => action$.pipe(
  filter(deleteTestEndListStart.match),
  map((x) => x.payload),
  mergeMap((data: PayloadTypeDeleteTestEndList) => from(DeleteTestEndList(data)).pipe(
    mergeMap((res: DeleteTestEndListResponse) => {
      const payload = {
        rowsPerPage: data?.rowsPerPage,
        page: data?.page,
        searchQuery: data?.searchQuery,
      };
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(successDeleteTestEndList(), getTestEndListStart(payload));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(failureDeleteTestEndList(res?.BMT?.ResponseMessage));
    }),
    takeUntil(action$.pipe(filter(deleteTestEndListStart.match))),
    // eslint-disable-next-line max-len
    catchError((error: AxiosError<DeleteTestEndListResponse>) => of(failureDeleteTestEndList(error?.response?.data?.BMT?.ResponseMessage as string))),
  )),
);
