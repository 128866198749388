import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerInitialState, DeleteCustomer, GetCustomerPayload, GetCustomerResult,
} from '../utils/TypeCustomer';

const initialState: CustomerInitialState = {
  customers: [],
  totalEntries: 0,
  isLoading: false,
  customerPayload: {} as GetCustomerPayload,
};

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomersStart(state, action:PayloadAction<GetCustomerPayload>) {
      return {
        ...state,
        isLoading: true,
        customerPayload: action.payload,
      };
    },
    getCustomersSuccess(state, action:PayloadAction<GetCustomerResult>) {
      return {
        ...state,
        isLoading: false,
        totalEntries: action.payload.TotalRecords,
        customers: action.payload.Data,
      };
    },
    getCustomersFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },
    // Delete Customer
    deleteCustomerStart(state, _action: PayloadAction<DeleteCustomer>) {
      return {
        ...state,
        isLoading: true,
      };
    },
    deleteCustomerSuccess(state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    deleteCustomerFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    },

  },
});

export const {
  getCustomersStart, getCustomersSuccess, getCustomersFailure, deleteCustomerStart,
  deleteCustomerSuccess, deleteCustomerFailure,
} = customerSlice.actions;
export const customerReducer = customerSlice.reducer;

export type CustomerActions =
    | ReturnType<typeof getCustomersStart>
    | ReturnType<typeof getCustomersSuccess>
    | ReturnType<typeof getCustomersFailure>
    | ReturnType<typeof deleteCustomerStart>
    | ReturnType<typeof deleteCustomerSuccess>
    | ReturnType<typeof deleteCustomerFailure>;
