/* eslint-disable max-len */
import React from 'react';

export function AttachmentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
      <path d="M11.5 15.75V6.75C11.5 6.335 11.165 6 10.75 6C10.335 6 10 6.335 10 6.75V15.75C10 18.095 8.095 20 5.75 20C3.405 20 1.5 18.095 1.5 15.75V4.25C1.5 2.735 2.735 1.5 4.25 1.5C5.765 1.5 7 2.735 7 4.25V15C7 15.69 6.44 16.25 5.75 16.25C5.06 16.25 4.5 15.69 4.5 15V5.25C4.5 4.835 4.165 4.5 3.75 4.5C3.335 4.5 3 4.835 3 5.25V15C3 16.515 4.235 17.75 5.75 17.75C7.265 17.75 8.5 16.515 8.5 15V4.25C8.5 1.905 6.595 0 4.25 0C1.905 0 0 1.905 0 4.25V15.75C0 18.92 2.58 21.5 5.75 21.5C8.92 21.5 11.5 18.92 11.5 15.75Z" fill="white" />
    </svg>
  );
}
