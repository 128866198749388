// import React, { useState } from 'react';
// import { SketchPicker, ColorResult } from 'react-color';
// import '../utils/ColorSketchPicker.scss';
// import { IProps } from '../utils/types';

// function ColorSketchPicker({ color: initialColor, onChangeComplete }: IProps) {
//   const [displayColorPicker, setDisplayColorPicker] = useState(false);
//   const [color, setColor] = useState(initialColor || '#000');

//   const handleClick = () => {
//     setDisplayColorPicker(!displayColorPicker);
//   };

//   const handleClose = () => {
//     setDisplayColorPicker(false);
//   };

//   const handleChange = (colorResult: ColorResult) => {
//     setColor(colorResult.hex);
//   };

//   const handleComplete = (colorResult: ColorResult) => {
//     onChangeComplete(colorResult.hex);
//   };

//   return (
//     <div className="color-sketch-picker-container">
//       <div className="color-sketch-picker-swatch" onClick={handleClick}>
//         <div className="color-sketch-picker-color-box" style={{ backgroundColor: color }} />
//       </div>
//       {displayColorPicker ? (
//         <div className="color-sketch-picker-popover">
//           <div className="color-sketch-picker-cover" onClick={handleClose} />
//           <SketchPicker
//             color={color}
//             onChange={handleChange}
//             onChangeComplete={handleComplete}
//           />
//         </div>
//       ) : null}
//     </div>
//   );
// }

// export default ColorSketchPicker;

import React, { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';
import '../utils/ColorSketchPicker.scss';
import { IProps } from '../utils/types';

function ColorSketchPicker({ color: initialColor, onChangeComplete }: IProps) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(initialColor || 'rgba(0, 0, 0, 1)');

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (colorResult: ColorResult) => {
    const rgbaColor = `rgba(${colorResult.rgb.r}, ${colorResult.rgb.g}, ${colorResult.rgb.b}, ${colorResult.rgb.a})`;
    setColor(rgbaColor);
  };

  const handleComplete = (colorResult: ColorResult) => {
    const rgbaColor = `rgba(${colorResult.rgb.r}, ${colorResult.rgb.g}, ${colorResult.rgb.b}, ${colorResult.rgb.a})`;
    onChangeComplete(rgbaColor);
  };

  return (
    <div className="color-sketch-picker-container">
      <div className="color-sketch-picker-swatch" onClick={handleClick}>
        <div className="color-sketch-picker-color-box" style={{ backgroundColor: color }} />
      </div>
      {displayColorPicker ? (
        <div className="color-sketch-picker-popover">
          <div className="color-sketch-picker-cover" onClick={handleClose} />
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={handleComplete}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ColorSketchPicker;
