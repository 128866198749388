import { Dayjs } from 'dayjs';
import {
  Control, FieldErrors, UseFormTrigger, UseFormWatch,
} from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Empty = any;

export interface MainFormValues {
  sirName: string | Empty,
  firstName: string | Empty,
  lastName: string | Empty,
  address: string | Empty,
  zip: string | Empty,
  state: string | Empty,
  city: string | Empty,
  otherAnalystCity: string | Empty,
  country: string | Empty,
  code: string | Empty,
  phone: string | Empty,
  cell: string | Empty,
  email: string | Empty,
  initials: string | Empty,
  territory: string | Empty,
  hireDate: Dayjs | null | Empty,
  lsInitials: string | Empty,

  boZip: string | Empty,
  boState: string | Empty,
  boCity: string | Empty,
  otherBOCity: string | Empty,
  boCountry: string | Empty,

  sbZip: string | Empty,
  sbState: string | Empty,
  sbCity: string | Empty,
  otherSBCity: string | Empty,
  sbCountry: string | Empty,
}

export interface InterfaceAnalystInfo {
  control: Control<MainFormValues>;
  errors?: FieldErrors<MainFormValues>;
  watch: UseFormWatch<MainFormValues>;
  setValue: SetValue;
  getValues: Empty;
}

export interface InterfaceAnalystSecondaryInfo {
  control: Control<MainFormValues>;
  errors?: FieldErrors<MainFormValues>;
  watch: UseFormWatch<MainFormValues>;
  setValue: SetValue;
  getValues?: Empty;
  trigger: UseFormTrigger<MainFormValues>;
}
export interface SecondaryBasedFormInfo {
  control: Control<MainFormValues>;
  errors: FieldErrors<MainFormValues>;
  setValue: SetValue;
  watch: UseFormWatch<MainFormValues>;
  getValues?: Empty;
}

export enum AnalystForm {
  sirName = 'sirName',
  firstName = 'firstName',
  lastName = 'lastName',
  address = 'address',
  zip = 'zip',
  analystState = 'state',
  analystCity = 'city',
  otherAnalystCity = 'otherAnalystCity',
  country = 'country',
  code = 'code',
  phone = 'phone',
  cell = 'cell',
  email = 'email',
  initials = 'initials',
  territory = 'territory',
  hireDate = 'hireDate',
  lsInitials = 'lsInitials',
  boZip = 'boZip',
  boState = 'boState',
  boCity = 'boCity',
  otherBOCity = 'otherBOCity',
  boCountry = 'boCountry',
  sbZip = 'sbZip',
  sbState = 'sbState',
  sbCity = 'sbCity',
  otherSBCity = 'otherSBCity',
  sbCountry = 'sbCountry',
}

export interface AddAnalystBody {
  SalutationId: number,
  FirstName: string,
  LastName: string,
  Address: string,
  Pincode: string,
  StateName: string,
  CityName: string,
  CountryName: string,
  PhoneCode: string,
  Phone: string,
  Cell: string,
  Email: string,
  Initials: string,
  TerritoryId: number,
  HireDate: string,
  LsInitials: string,
  BasePincode: string,
  BaseStateName: string,
  BaseCityName: string,
  BaseCountryName: string,
  SecondaryPincode: string,
  SecondaryStateName: string,
  SecondaryCityName: string,
  SecondaryCountryName: string,
}

export interface AddAnalystPayload {
  reqBody: AddAnalystBody,
  navigate: Empty
}
export interface AddAnalystResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface GetByIdAnalystResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: AddAnalystBody;
  }
}

export interface CityDetails {
  Value: string;
  Text: string;
}
export interface PinCodeDetails {
  StateName: string;
  StateCode: string;
  City: CityDetails[];
}

export interface AnalystLeaveDataPayload {
  analystId: number,
  page: number;
  rowsPerPage: number;
  searchQuery: string;
}

export interface AnalystLeaveData {
  Id: number,
  AnalystId: string,
  Contact: string,
  StartDate: string
  EndDate: string,
  EventId: number,
  Note: string,
}
export interface AnalystLeaveDataResult {
  TotalRecords: number;
  Data: AnalystLeaveData[];
  BalanceLeaveId: number;
  AllowedLeave: number;
  VacationSickLeaveSum: number;
}
export interface AnalystLeaveDataApiResponse {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: AnalystLeaveDataResult;
  }
}

export interface DeleteAnalystLeaveResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: null;
  }
}

export interface DeleteAnalystLeavePayload {
  selectedId: string;
  callback: () => void;
}

export interface AddAnalystPayloadData {
  EventId?: string
  AnalystName?: string,
  Contact?: string,
  StartDate?: string,
  EndDate?: string,
  Note?: string,
  AnalystId?: string,
  LeaveTypeId?: number,
  Description?: string,
  BalanceLeaveId?: number,
}
export interface AddAnalystLeavePayload {
  payload: AddAnalystPayloadData,
  type: number;
  callback: () => void;
}

export interface AddAnalystLeaveResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: string;
  }
}

export interface AnalystLeaveMasterDataResult {
  Value: number | string,
  Text: string,
  ColorCode: string,
}
export interface AnalystLeaveMasterDataInterface {
  TotalRecords: number,
  Data: AnalystLeaveMasterDataResult[]
}

export interface AddAnalystLeaveMasterResponseFormat {
  BMT: {
    ResponseCode: number;
    ResponseStatus: string;
    ResponseMessage: string;
    Result: AnalystLeaveMasterDataInterface;
  }
}
export interface AddAnalystInitialState {
  isLoading: boolean,
  analystDataById: AddAnalystBody,
  analystPinCodeDetails: PinCodeDetails | null;
  isPinError: boolean;
  analystBaseOutPinCodeDetails: PinCodeDetails | null;
  isBaseOutPinError: boolean;
  selectedTab: number;
  analystSecondaryBasePinCodeDetails: PinCodeDetails | null,
  isSecondaryBasePinError: boolean,
  isAnalystLeaveDataLoading: boolean,
  analystLeaveData: AnalystLeaveDataResult,
  deletedLeave: boolean,
  analystLeaveMasterData: AnalystLeaveMasterDataInterface,
}

export interface VacationData {
  id: string;
  sn: number;
  action: string;
  from: string;
  to: string;
}

export interface TypesComponentVacationModal {
  show: boolean,
  setOpenFrom: () => void;
  handleCloseComponentVacationModal: () => void;
  rowId?: string
}

export enum AddLeaveFormEnum {
  leaveType = 'leaveType',
  description = 'description',
  analyst = 'analyst',
  contact = 'contact',

}

export interface AddLeaveFormFields {
  leaveType: string | undefined | number,
  description: string | undefined,
  analyst: string,
  contact: string,
}

export enum LeaveDayFormEnum {
  leaveDaysAllowed = 'leaveDaysAllowed',
  leaveDaysRemaining = 'leaveDaysRemaining',
}

export interface LeaveDaysFormFields {
  leaveDaysAllowed: string | undefined,
  leaveDaysRemaining: string | undefined,
}
