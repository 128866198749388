/* eslint-disable react/no-danger */
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';
import { DocumentParts } from '../constant';

function CalibrationProcedureSection() {
  const { reportPdfResponse4, checkedSections } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  return (
    <div className="p30">
      <table className="w100 logoTable">
        <tr>
          <td>
            <div className="commonTableBorderStyle mt20 p30 w100">
              <div className="boldHeading textCenter mt20">Calibration Procedure</div>
              <div className="commonText mb30 mt20" dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Caliberation_Procedure?.Description }} />
              <div className="subHeading mt40 textCenter">Calibration Reference Standard</div>
              <div className="commonText mt20" dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Calibration_Reference_Standard }} />
              { checkedSections?.includes(DocumentParts.Inspection_Procedure)
              && (
                <>
                  <div className="subHeading mt40 textCenter">Inspection Procedure</div>
                  <div
                    className="commonText mt20"
                    dangerouslySetInnerHTML={{ __html: reportPdfResponse4?.Inspection_Procedure?.Description }}
                  />
                </>
              )}
            </div>
          </td>
        </tr>
      </table>
    </div>
  );
}

export default CalibrationProcedureSection;
