import React from 'react';
import ComponentExpenseCustomer from './ComponentExpenseCustomer';
import ComponentExpenseMainTable from './ComponentExpenseMainTable';
import ComponentExpenseAnalystTable from './ComponentExpenseAnalystTable';

function ExpenseReport() {
  return (
    <div className="ExpenseReportPDFDiv">
      <ComponentExpenseCustomer />
      <ComponentExpenseMainTable />
      <ComponentExpenseAnalystTable />
    </div>
  );
}

export default ExpenseReport;
