import React, { useEffect, useState } from 'react';
import { TubeChartGrid } from '../../VesselInformation/components/TubeChart/Diagram/tubeChartGrid';
import { Polygon } from '../../VesselInformation/components/TubeChart/Diagram/gridClass';
import { PolygonInterface, TubeConfiguration } from '../../VesselInformation/utils/tubeChartTypes';
import { TubeChartLayoutProps } from '../types';

function TubeChartLayout({ tubeDetails, serialNumber }:TubeChartLayoutProps) {
  const [poly, setPoly] = useState<PolygonInterface>();

  useEffect(() => {
    const chartDetails:TubeConfiguration = {
      CircleInfos: tubeDetails?.CircleInfos,
      IsOte: tubeDetails?.IsOte,
      TubeSize: tubeDetails?.TubeSize,
      NumOfRows: tubeDetails?.NumOfRows,
      RowLength: tubeDetails?.RowLength,
      Panel: tubeDetails?.Panel,
      Group: tubeDetails?.Group,
      Layout: tubeDetails?.Layout,
      IsTestEndChanges: tubeDetails.OriginalTestEnd,
      ChartId: tubeDetails.ChartId,
      VesselId: tubeDetails.VesselId,
      VesselTop: tubeDetails.VesselTop,
      Offset: tubeDetails.Offset,
      OriginalTestEnd: tubeDetails.OriginalTestEnd,
      RemovedConfig: tubeDetails.RemovedConfig,
    };
    const temp = new Polygon(chartDetails);
    setPoly(temp);
    temp.displayDefects(tubeDetails.Defects);
  }, [tubeDetails]);

  return (
    <div className="p30 noScrollWidth">
      <div className="pageHeader">
        <div className="topHeader">
          <div>Part VII - Tube Bundle Layout</div>
          <div>{serialNumber}</div>
        </div>
        <div className="bigHeadingText textCenter">
          {tubeDetails?.VesselName}
        </div>
      </div>
      <div className="tubeBundleHeader">
        <div className="tubesNumberedArrow">
          <div>
            Tubes Numbered
            <br />
            {' '}
            Left to Right
          </div>
          <div className="mt20">
            Rows Numbered
            <br />
            {' '}
            Top to Bottom
          </div>
        </div>
        <div className="topVesselArrow">Top of Vessel</div>
        <div>Inlet End</div>
      </div>
      {poly && (
      <TubeChartGrid
        polygon={poly}
        polyCircles={poly?.circles}
        setPolyCircles={() => {}}
      />
      )}
      <div className="legendContainer">
        <div className="legendItems">
          <div className="tubeChartLegend" style={{ backgroundColor: 'red' }} />
          <div> = Red Colored Tube</div>
        </div>
        <div className="legendItems">
          <div className="tubeChartLegend" style={{ backgroundColor: 'green' }} />
          <div> = Green Colored Tube</div>
        </div>
        <div className="legendItems">
          <div className="tubeChartLegend" style={{ backgroundColor: 'blue' }} />
          <div> = Blue Colored Tube</div>
        </div>
      </div>
    </div>
  );
}

export default TubeChartLayout;
