import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Grid, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import '../Style/StyleSmc.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { ObjectSchema } from 'yup';
import ComponentAddCustomer from './ComponentAddCustomer/ComponentAddCustomer';
import ComponentAddSmc from './ComponentAddSMC/ComponentAddSmc';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import ComponentSubscribedCustomer from './ComponentSubscribedCustomer';
import ComponentSubscribedSites from './ComponentSubscribedSites';
import { addSmcDetailsValidationSchema } from '../Utils/SchemaSmc';
import {
  AddSmcFormInput, Empty, GetSmcCustomerListResult, UpdateSmcPayload,
} from '../Utils/TypeSmc';
import { AddSmcFormValue, defaultValuesSmcForm } from '../Utils/ConstantSmc';
import { sendSmcDetailsData, updateSmc } from '../Redux/sliceAddSmc';
import { RootState } from '../../../redux/rootState';
import { CustomerApiData } from '../../customer/utils/TypeCustomer';
import { getPinCodeDetailsStart, removePinCodeDetails } from '../../../redux/masters/masterSlice';
import ComponentAddSite from './ComponentAddSites/ComponentAddSite';
import { assignSmcValues } from '../Utils/helper';
import EndPoints from '../../../Routes/EndPoints';
import { debugLog } from '../../../Common/Logger';
import { DEFAULT_LENGTH, MAX_LENGTH_FOR_ZIP_API_CALL, MIN_LENGTH_FOR_ZIP_CLEAR } from './constant';
import { scrollToErrorField } from '../../../utils/CommonFunctions';

export default function ComponentDetails() {
  const { translations } = useContext(LocalizationContext);
  const [subscribedCustomer, setSubscribedCustomer] = useState<CustomerApiData[]>([]);
  const [filteredSubscribedCustomer, setFilteredSubscribedCustomer] = useState<CustomerApiData[]>([]);
  const { customers } = useSelector((state: RootState) => state.customerReducer);
  const { pinCodeDetails, isError } = useSelector((state: RootState) => state.masterDataReducer);
  const { selectedSmcDetails } = useSelector((state: RootState) => state.addSmcDetailsReducer);
  const { userId } = useSelector((state: RootState) => state.otpReducer);
  const [open, setOpen] = useState(false);
  const [openSiteCustomer, setOpenSiteCustomer] = useState(false);
  const [openSite, setOpenSite] = useState(false);
  const [filteredSubscribedSite, setFilteredSubscribedSite] = useState<Empty>([]);
  const [customerId, setCustomerId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { smcId } = useParams();

  const { selectedSiteId } = useSelector((state: RootState) => state.addSmcDetailsReducer);
  const {
    generalSites, associatedSites,
  } = useSelector((state: RootState) => state.jobStarterReducer);

  debugLog(associatedSites);
  const {
    handleSubmit, control, register, formState: { errors }, setValue, watch, getValues,
  } = useForm<AddSmcFormInput>({
    defaultValues: defaultValuesSmcForm,
    resolver: yupResolver(addSmcDetailsValidationSchema(translations) as ObjectSchema<AddSmcFormInput>),
  });

  useEffect(() => {
    if (errors) {
      const errorField = Object.keys(errors)[0];
      scrollToErrorField(errorField);
    }
  }, [errors]);

  const updateSmcData = (values: AddSmcFormInput) => {
    const smcCustomers: string[] = [];
    const smcSites: string[] = [];
    filteredSubscribedCustomer.forEach((customer: GetSmcCustomerListResult) => {
      smcCustomers.push(customer.Custid);
    });
    filteredSubscribedSite?.forEach((ele: Empty) => {
      smcSites.push(ele.SiteId);
    });

    const data: UpdateSmcPayload = {
      SmcId: String(smcId),
      SmcName: values.smcName,
      TaiIdNo: values.taiIdNo,
      PhoneCode: values.phoneCode,
      PhoneNumber: values.phoneNumber,
      Url: values.url,
      Address: values.address,
      AddressOptional: values.addressOptional,
      SMCCustomers: smcCustomers,
      SMCSites: smcSites,
      CityName: values.city === 'Other' ? values?.otherCity : values?.city,
      IsOtherCity: values?.city === 'Other',
      ZipCode: values.zip,
      LoginUserId: String(userId),
      CountryCode: values.country,
    };

    dispatch(updateSmc(data));
  };

  useEffect(() => {
    if (selectedSiteId) {
      setValue(AddSmcFormValue.TEMP_SMC_SITE, selectedSiteId);
    }
  }, [selectedSiteId]);

  const onSubmit = (FieldValues: AddSmcFormInput) => {
    if (smcId) {
      updateSmcData(FieldValues);
    } else {
      const smcCustomers: string[] = [];
      const smcSites: string[] = [];
      filteredSubscribedSite?.forEach((ele: Empty) => {
        smcSites.push(ele.SiteId);
      });
      filteredSubscribedCustomer.forEach((customer: GetSmcCustomerListResult) => {
        smcCustomers.push(customer.Custid);
      });
      const payload = {
        ...FieldValues,
        smcCustomers,
        smcSites,
      };
      dispatch(sendSmcDetailsData(payload));
    }
  };

  const handleTabCell = (custId: string) => {
    const selectedCustomer = customers?.find((customer) => customer.Custid === custId);
    setValue(AddSmcFormValue.TEMP_CUSTOMERS, selectedCustomer?.Customername);
    if (selectedCustomer) {
      setSubscribedCustomer([...subscribedCustomer, selectedCustomer]);
    }
  };

  const handleTabCellSite = (custId: string) => {
    const selectedCustomer = customers?.find((customer) => customer.Custid === custId);
    setValue(AddSmcFormValue.TEMP_SMC_CUSTOMER, selectedCustomer?.Customername);
    setCustomerId(custId);
  };

  const onAddCustomerListSite = () => {
    if (customerId && selectedSiteId) {
      const selectedSite = associatedSites?.find((ele) => ele.SiteId === selectedSiteId);
      setFilteredSubscribedSite([...filteredSubscribedSite, selectedSite]);
      setValue(AddSmcFormValue.TEMP_SMC_SITE, '');
      setValue(AddSmcFormValue.TEMP_SMC_CUSTOMER, '');
      setCustomerId('');
      return;
    }
    if (selectedSiteId) {
      const selectedSite = generalSites?.find((ele) => ele.SiteId === selectedSiteId);
      setFilteredSubscribedSite([...filteredSubscribedSite, selectedSite]);
      setValue(AddSmcFormValue.TEMP_SMC_SITE, '');
      setValue(AddSmcFormValue.TEMP_SMC_CUSTOMER, '');
      setCustomerId('');
    }
  };
  const onAddCustomerList = () => {
    if (subscribedCustomer?.length > 0) {
      setFilteredSubscribedCustomer(subscribedCustomer);
      setValue(AddSmcFormValue.TEMP_CUSTOMERS, '');
    }
  };

  const companyZip = watch(AddSmcFormValue.ZIP);
  const countryName = watch(AddSmcFormValue.COUNTRY);

  useEffect(() => {
    const isValidCompanyZip = companyZip && companyZip?.length > DEFAULT_LENGTH;
    if (isValidCompanyZip) {
      dispatch(removePinCodeDetails());
      setValue(AddSmcFormValue.STATE, '');
      setValue(AddSmcFormValue.OTHER_CITY, '');
      setValue(AddSmcFormValue.CITY, '');
      const queryPayload = {
        pinCode: companyZip,
        countryCode: getValues(AddSmcFormValue.COUNTRY),
      };
      dispatch(getPinCodeDetailsStart(queryPayload));
      return;
    }
    dispatch(removePinCodeDetails());
    setValue(AddSmcFormValue.STATE, '');
    setValue(AddSmcFormValue.OTHER_CITY, '');
    setValue(AddSmcFormValue.CITY, '');
  }, [companyZip, countryName]);

  const stateValue = watch(AddSmcFormValue.STATE);

  const isSmcButtonDisabled = useMemo(() => {
    if (companyZip?.length === MIN_LENGTH_FOR_ZIP_CLEAR) {
      return false;
    }
    if (companyZip?.length > MIN_LENGTH_FOR_ZIP_CLEAR && companyZip?.length < MAX_LENGTH_FOR_ZIP_API_CALL) {
      return true;
    }
    if (companyZip?.length > MIN_LENGTH_FOR_ZIP_CLEAR && stateValue?.length > MIN_LENGTH_FOR_ZIP_CLEAR) {
      return false;
    }
    return true;
  }, [countryName, companyZip, isError, stateValue]);

  useEffect(() => {
    if (pinCodeDetails) {
      setValue(AddSmcFormValue.STATE, pinCodeDetails?.StateName);
    }
  }, [pinCodeDetails]);

  useEffect(() => {
    if (selectedSmcDetails) {
      assignSmcValues(selectedSmcDetails, setValue);
    }
  }, [selectedSmcDetails]);

  useEffect(() => {
    setSubscribedCustomer(filteredSubscribedCustomer);
  }, [filteredSubscribedCustomer]);

  return (
    <div className="div_container_CustomerInfo" id="add_customer_of_smc">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <ComponentAddSmc control={control} errors={errors} register={register} watch={watch} setValue={setValue} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ComponentAddCustomer
                subscribedCustomer={filteredSubscribedCustomer}
                watch={watch}
                open={open}
                onAddCustomerList={onAddCustomerList}
                setOpen={setOpen}
                handleTabCell={handleTabCell}
                setValue={setValue}
                control={control}
              />
            </Grid>
            <Grid item sm={12}>
              <ComponentAddSite
                watch={watch}
                customerId={customerId}
                open={openSiteCustomer}
                onAddCustomerList={onAddCustomerListSite}
                handleTabCell={handleTabCellSite}
                setOpen={setOpenSiteCustomer}
                setValue={setValue}
                control={control}
                setOpenSite={setOpenSite}
                openSite={openSite}
                filteredSubscribedSite={filteredSubscribedSite}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ComponentSubscribedCustomer subscribedCustomer={filteredSubscribedCustomer} setSubscribedCustomer={setFilteredSubscribedCustomer} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ComponentSubscribedSites setFilteredSubscribedSite={setFilteredSubscribedSite} filteredSubscribedSite={filteredSubscribedSite} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={() => navigate(EndPoints.SMC)}>{translations.cancel}</Button>
            </div>
            <div className="button_margin_left">
              <Button
                className="button_save_and_next"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={isSmcButtonDisabled || isError}
              >
                {translations.save}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
