export const AddUpdateApiType = {
  ADD: 1,
  UPDATE: 2,
};

export const initialAddUserManagementFields = {
  userId: '',
  initials: '',
  password: '',
  role: '',
  analyst: '',
};
