import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import tickIconRed from '../../../assets/Icons/tick-icon-red.svg';
import { RootState } from '../../../redux/rootState';
import DataSheetTopTable from './dataSheetTopTable';

function DataSheetTable() {
  const { reportPdfResponse2 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const dataSheetResult = useMemo(() => {
    const res = [];
    const len = reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.length;
    for (let i = 0; i < len;) {
      res.push(reportPdfResponse2?.Data_Sheets?.ResultDatasheet?.slice(i, i + 20));
      i += 20;
    }

    return res;
  }, [reportPdfResponse2]);

  return (
    <>
      {dataSheetResult.map((dataResult, index) => (
        <>
          <div className="pageHeader">
            <div className="topHeader">
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>
                Part VI - Data Sheets
                { index > 0
                && (
                <>
                  <br />
                  Data Sheets - Page
                  {' '}
                  {index + 1}
                </>
                )}
              </div>
              <div className={`${index > 0 ? 'pt30 pb10' : ''}`}>{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</div>
            </div>
            { index === 0
            && <div className="underlineHeadingText textCenter">Data Sheet</div>}
          </div>
          { index === 0
          && <DataSheetTopTable />}
          <table className="bordered-table w100 mt40">
            <tr>
              <td className="tableGreenHeading textCenter boldText w6">
                Row
              </td>
              <td className="tableGreenHeading textCenter boldText w6">
                Tube
              </td>
              <td className="tableGreenHeading textCenter boldText w17">
                Vessel
              </td>
              <td className="tableGreenHeading textCenter boldText w55">
                Description
              </td>
              <td className="tableGreenHeading textCenter boldText w8">
                Area
              </td>
              <td className="tableGreenHeading textCenter boldText w8">
                Action Req.
              </td>
            </tr>
            {dataResult.map((details) => (
              <tr>
                <td className="textCenter">{details.Row}</td>
                <td className="textCenter">{details.Tube}</td>
                <td className="textCenter">{details.VesselName}</td>
                <td className="textCenter">{details.DefectDescription}</td>
                <td className="textCenter">{details.Area}</td>
                <td className="textCenter">{details.ActionRequired && <img src={tickIconRed} alt="icon" width="18px" />}</td>
              </tr>
            ))}
          </table>
          <div className="page-break" />
        </>
      ))}
    </>
  );
}

export default DataSheetTable;
