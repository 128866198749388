import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  ANALYST_NOTE_TAB, CLIENT_SITE_TAB, COMMENTS_TAB, MAIN_TAB, VESSELS_TAB,
} from '../utils/constants';
import ScreenMain from './ComponentMain';
import ScreenClientSite from './ComponentClientSite';
import ScreenVessels from './ComponentVessels';
import ScreenAnalystNote from './ComponentAnalystNote';
import ScreenComments from './ComponentCommets';
import { RootState } from '../../../redux/rootState';
import { removeSelectedJobOrder, setSelectedTab } from '../redux/sliceJobOrder';
import { setLoader } from '../../../redux/loaderSlice';

export default function ScreenEditJobOrder() {
  const { selectedTab, isLoading } = useSelector((state:RootState) => state.jobOrderReducer);
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case MAIN_TAB:
        return <ScreenMain />;
      case CLIENT_SITE_TAB:
        return <ScreenClientSite />;
      case VESSELS_TAB:
        return <ScreenVessels />;
      case ANALYST_NOTE_TAB:
        return <ScreenAnalystNote />;
      default:
        return <ScreenComments />;
    }
  }

  useEffect(() => {
    dispatch(removeSelectedJobOrder());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <>
      <ComponentPageHeader subHeading="Job Order" />
      <div className="container_job">
        <Tabs
          variant="scrollable"
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          className="main_tabs"
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            className="tab_styles"
            label="Main"
            disabled={selectedTab !== MAIN_TAB}
            style={selectedTab === MAIN_TAB ? selectedTabStyles : tabStyles}
            value={MAIN_TAB}
          />
          <Tab
            className="tab_styles"
            label="Client/Site"
            disabled={selectedTab !== CLIENT_SITE_TAB}
            style={selectedTab === CLIENT_SITE_TAB ? selectedTabStyles : tabStyles}
            value={CLIENT_SITE_TAB}
          />
          <Tab
            className="tab_styles"
            label="Vessels"
            disabled={selectedTab !== VESSELS_TAB}
            style={selectedTab === VESSELS_TAB ? selectedTabStyles : tabStyles}
            value={VESSELS_TAB}
          />
          <Tab
            className="tab_styles"
            label="Analyst Note"
            disabled={selectedTab !== ANALYST_NOTE_TAB}
            style={selectedTab === ANALYST_NOTE_TAB ? selectedTabStyles : tabStyles}
            value={ANALYST_NOTE_TAB}
          />
          <Tab
            className="tab_styles"
            label="Comments"
            disabled={selectedTab !== COMMENTS_TAB}
            style={selectedTab === COMMENTS_TAB ? selectedTabStyles : tabStyles}
            value={COMMENTS_TAB}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>
  );
}
