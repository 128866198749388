/* eslint-disable max-len */
import React from 'react';

export function FiltersLinesIcon() {
  return (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.17917 9H12.3597M2.13403 4.90972H14.4049M6.22431 13.0903H10.3146" stroke="#344054" strokeWidth="1.36615" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
