export const ContactColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'status', label: 'Status' },
  { key: 'name', label: 'Name' },
  { key: 'office', label: 'Office' },
  { key: 'email', label: 'Email' },
  { key: 'phone', label: 'Phone' },
  { key: 'ext', label: 'Ext' },
  { key: 'cell', label: 'Cell' },
  { key: 'er', label: 'ER' },
  { key: 'or', label: 'OR' },
];
