/* eslint-disable max-len */
import React from 'react';

export function JobOrderIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.04 4.75586H6.36388C6.21362 4.75586 6.0918 4.87388 6.0918 5.01953C6.0918 5.16518 6.21362 5.2832 6.36388 5.2832H15.0399C15.1902 5.2832 15.312 5.16518 15.312 5.01953C15.312 4.87388 15.1902 4.75586 15.04 4.75586Z" fill="#092245" />
      <path d="M13.7038 6.25977C13.7038 6.11411 13.582 5.99609 13.4317 5.99609H7.97228C7.82202 5.99609 7.7002 6.11411 7.7002 6.25977C7.7002 6.40542 7.82202 6.52344 7.97228 6.52344H13.4317C13.582 6.52344 13.7038 6.40542 13.7038 6.25977Z" fill="#092245" />
      <path d="M6.18796 8.83983L6.86872 9.39888C6.91806 9.43938 6.98046 9.46139 7.04464 9.46139C7.05233 9.46139 7.06005 9.46107 7.06778 9.46044C7.13994 9.45446 7.20669 9.42089 7.2532 9.3671L8.53553 7.88421C8.63203 7.77259 8.61693 7.6063 8.50175 7.51278C8.38653 7.4192 8.21493 7.43389 8.11843 7.54552L7.01184 8.82517L6.53982 8.43753C6.42519 8.34339 6.25348 8.35713 6.15633 8.46823C6.05917 8.57932 6.07332 8.74568 6.18796 8.83983Z" fill="#092245" />
      <path d="M6.18798 11.3555L6.86874 11.9145C6.91808 11.955 6.98047 11.977 7.04465 11.977C7.05234 11.977 7.06007 11.9767 7.0678 11.9761C7.13995 11.9701 7.20671 11.9365 7.25321 11.8827L8.53554 10.3998C8.63204 10.2882 8.61695 10.1219 8.50177 10.0284C8.38655 9.93479 8.21495 9.94956 8.11845 10.0611L7.01186 11.3408L6.53984 10.9532C6.4252 10.859 6.2535 10.8727 6.15634 10.9839C6.05915 11.0949 6.07334 11.2613 6.18798 11.3555Z" fill="#092245" />
      <path d="M6.18796 13.873L6.86872 14.4321C6.91806 14.4726 6.98046 14.4946 7.04464 14.4946C7.05233 14.4946 7.06005 14.4943 7.06778 14.4936C7.13994 14.4877 7.20669 14.4541 7.2532 14.4003L8.53553 12.9174C8.63203 12.8058 8.61693 12.6395 8.50175 12.546C8.38653 12.4524 8.21493 12.4671 8.11843 12.5787L7.01184 13.8584L6.53982 13.4707C6.42519 13.3766 6.25348 13.3903 6.15633 13.5014C6.05917 13.6125 6.07332 13.7789 6.18796 13.873Z" fill="#092245" />
      <path d="M6.18778 16.3875L6.86854 16.9475C6.91792 16.9881 6.98039 17.0102 7.04464 17.0102C7.05229 17.0102 7.06002 17.0099 7.06771 17.0092C7.13994 17.0033 7.20673 16.9696 7.25324 16.9158L8.53556 15.4319C8.63206 15.3203 8.61686 15.154 8.50164 15.0605C8.38642 14.9671 8.21486 14.9817 8.11836 15.0934L7.01188 16.3737L6.53997 15.9855C6.42537 15.8912 6.2537 15.9048 6.15647 16.0159C6.05921 16.1268 6.07325 16.2932 6.18778 16.3875Z" fill="#092245" />
      <path d="M12.829 8.8125H9.72961C9.57934 8.8125 9.45752 8.93052 9.45752 9.07617C9.45752 9.22182 9.57934 9.33984 9.72961 9.33984H12.829C12.9793 9.33984 13.1011 9.22182 13.1011 9.07617C13.1011 8.93052 12.9793 8.8125 12.829 8.8125Z" fill="#092245" />
      <path d="M15.0166 7.57129H11.7989C11.6487 7.57129 11.5269 7.68931 11.5269 7.83496C11.5269 7.98061 11.6487 8.09863 11.7989 8.09863H15.0166C15.1669 8.09863 15.2887 7.98061 15.2887 7.83496C15.2887 7.68931 15.1669 7.57129 15.0166 7.57129Z" fill="#092245" />
      <path d="M10.5142 7.57129H9.72961C9.57934 7.57129 9.45752 7.68931 9.45752 7.83496C9.45752 7.98061 9.57934 8.09863 9.72961 8.09863H10.5142C10.6645 8.09863 10.7863 7.98061 10.7863 7.83496C10.7863 7.68931 10.6645 7.57129 10.5142 7.57129Z" fill="#092245" />
      <path d="M9.72961 11.3301C9.57934 11.3301 9.45752 11.4481 9.45752 11.5938C9.45752 11.7394 9.57934 11.8574 9.72961 11.8574H12.829C12.9793 11.8574 13.1011 11.7394 13.1011 11.5938C13.1011 11.4481 12.9793 11.3301 12.829 11.3301H9.72961Z" fill="#092245" />
      <path d="M15.2904 12.8682C15.2904 12.7225 15.1686 12.6045 15.0183 12.6045H9.72961C9.57934 12.6045 9.45752 12.7225 9.45752 12.8682C9.45752 13.0138 9.57934 13.1318 9.72961 13.1318H15.0183C15.1686 13.1318 15.2904 13.0138 15.2904 12.8682Z" fill="#092245" />
      <path d="M9.72961 16.3613C9.57934 16.3613 9.45752 16.4793 9.45752 16.625C9.45752 16.7707 9.57934 16.8887 9.72961 16.8887H12.1647C12.315 16.8887 12.4368 16.7707 12.4368 16.625C12.4368 16.4793 12.315 16.3613 12.1647 16.3613H9.72961Z" fill="#092245" />
      <path d="M15.0167 10.0869H14.232C14.0818 10.0869 13.96 10.2049 13.96 10.3506C13.96 10.4962 14.0818 10.6143 14.232 10.6143H15.0167C15.1669 10.6143 15.2888 10.4962 15.2888 10.3506C15.2888 10.2049 15.1669 10.0869 15.0167 10.0869Z" fill="#092245" />
      <path d="M13.2204 10.3506C13.2204 10.2049 13.0985 10.0869 12.9483 10.0869H9.72961C9.57934 10.0869 9.45752 10.2049 9.45752 10.3506C9.45752 10.4962 9.57934 10.6143 9.72961 10.6143H12.9483C13.0985 10.6143 13.2204 10.4962 13.2204 10.3506Z" fill="#092245" />
      <path d="M9.72961 14.374H10.3774C10.5277 14.374 10.6495 14.256 10.6495 14.1104C10.6495 13.9647 10.5277 13.8467 10.3774 13.8467H9.72961C9.57934 13.8467 9.45752 13.9647 9.45752 14.1104C9.45752 14.256 9.57931 14.374 9.72961 14.374Z" fill="#092245" />
      <path d="M12.8269 14.374C12.9772 14.374 13.099 14.256 13.099 14.1104C13.099 13.9647 12.9772 13.8467 12.8269 13.8467H11.34C11.1897 13.8467 11.0679 13.9647 11.0679 14.1104C11.0679 14.256 11.1897 14.374 11.34 14.374H12.8269Z" fill="#092245" />
      <path d="M13.5587 15.3838C13.5587 15.2381 13.4369 15.1201 13.2866 15.1201H9.72961C9.57934 15.1201 9.45752 15.2381 9.45752 15.3838C9.45752 15.5294 9.57934 15.6475 9.72961 15.6475H13.2866C13.4369 15.6475 13.5587 15.5294 13.5587 15.3838Z" fill="#092245" />
      <path d="M17.1435 17.4314C17.0264 17.34 16.8551 17.3579 16.7609 17.4713L15.9132 18.4912L15.5816 18.2074C15.4689 18.111 15.297 18.1215 15.1975 18.2306C15.0981 18.3397 15.1088 18.5063 15.2214 18.6027L15.7664 19.0691C15.8163 19.1118 15.8804 19.1352 15.9465 19.1352C15.9541 19.1352 15.9618 19.1348 15.9695 19.1342C16.0436 19.1281 16.1118 19.093 16.1584 19.0369L17.1847 17.8022C17.2789 17.6887 17.2605 17.5227 17.1435 17.4314Z" fill="#092245" />
      <path d="M17.001 15.6763V10.117C17.001 9.97134 16.8791 9.85332 16.7289 9.85332C16.5786 9.85332 16.4568 9.97134 16.4568 10.117V15.5741C16.3697 15.5662 16.282 15.5616 16.1941 15.5616C14.6616 15.5616 13.4129 16.7586 13.3894 18.2384H5.0552C4.99701 18.2384 4.94785 18.1903 4.94785 18.1333V3.63141C4.94785 3.57502 4.99701 3.52734 5.0552 3.52734H16.3494C16.4076 3.52734 16.4568 3.57498 16.4568 3.63141V8.9002C16.4568 9.04586 16.5786 9.16388 16.7289 9.16388C16.8791 9.16388 17.001 9.04586 17.001 8.9002V3.63141C17.001 3.28325 16.7087 3 16.3494 3H5.0552C4.69593 3 4.40364 3.28325 4.40364 3.63141V4.3603H3.65152C3.29226 4.3603 3 4.64355 3 4.99171V12.5914C3 12.7371 3.12182 12.8551 3.27209 12.8551C3.42235 12.8551 3.54417 12.7371 3.54417 12.5914V4.99171C3.54417 4.93532 3.59333 4.88764 3.65152 4.88764H4.40364V18.1333C4.40364 18.482 4.69593 18.7657 5.05516 18.7657H13.4332C13.4875 19.0591 13.5904 19.3397 13.7389 19.5986H3.65152C3.59333 19.5986 3.54417 19.5505 3.54417 19.4936V13.8092C3.54417 13.6636 3.42235 13.5456 3.27209 13.5456C3.12182 13.5456 3 13.6636 3 13.8092V19.4936C3 19.8423 3.29229 20.126 3.65152 20.126H14.1138C14.1201 20.126 14.1263 20.1255 14.1325 20.1251C14.6637 20.6825 15.4079 21 16.1941 21C17.7413 21 19 19.7802 19 18.2808C19 17.0801 18.1714 16.0175 17.001 15.6763ZM16.1941 20.4727C15.5259 20.4727 14.8952 20.1882 14.4637 19.6924C14.1634 19.3471 13.9828 18.9274 13.9414 18.4769C13.9354 18.4154 13.9324 18.3513 13.9324 18.2808C13.9324 17.0722 14.947 16.089 16.1941 16.089C16.3557 16.089 16.5163 16.1054 16.6717 16.1379C17.7055 16.3534 18.4559 17.2547 18.4559 18.2808C18.4559 19.4894 17.4412 20.4727 16.1941 20.4727Z" fill="#092245" />
    </svg>
  );
}
