import React from 'react';
import { SummaryInspProps } from '../types';

function SummaryInspectionTable({ summaryDetails }: SummaryInspProps) {
  return (
    <div className="w100 centerAlign mt40">
      <table className="bordered-table w70 noTableBreak">
        <tr>
          <td colSpan={3} className="tableGreenHeading textCenter boldText">
            {`${summaryDetails.VesselName}: ${summaryDetails.TubeCount} Tubes`}
          </td>
        </tr>
        <tr>
          <td colSpan={3} className="tableGreenHeading textCenter boldText">
            Tubes Tested:
          </td>
        </tr>
        <tr>
          <td className="textRight w48">Significant/Measurable Indications</td>
          <td className="textCenter w26">Tubes Marked</td>
          <td className="textCenter w26">Percent of Bundle</td>
        </tr>
        {summaryDetails?.Defects?.map((details) => (
          <tr>
            <td className="textRight w48">{details.Defect}</td>
            <td className="textCenter w26">{details.DefectTypeCount}</td>
            <td className="textCenter w26">{details.Percentage}</td>
          </tr>
        ))}
        <tr>
          <td className="textRight w48">Totals</td>
          <td className="textCenter w26">{summaryDetails?.TotalDefects}</td>
          <td className="textCenter w26">{summaryDetails?.TotalPercentage}</td>
        </tr>
      </table>
    </div>
  );
}

export default SummaryInspectionTable;
