import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, Card, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import style from '../utils/reportingStyle.module.scss';
import { GeneratePDFListingInterface, TypesPDFValues } from '../utils/types';
import { setLoader } from '../../../redux/loaderSlice';
import { RootState } from '../../../redux/rootState';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
// import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import { DefaultPDFValues, GenerateForms, PDFFormSchema } from '../utils/helper';
import { debugLog } from '../../../Common/Logger';
import { getListContentsListStart, getSignatureStart } from '../redux/sliceReportWorkspace';
import ScreenReportPdf from '../../ScreenReportPdf';
import { GetReportPdfPayload } from '../../ScreenReportPdf/types';
import {
  getReportPdf1Start, getReportPdf2Start, getReportPdf3Start, getReportPdf4Start,
  setCheckedSections,
} from '../../ScreenReportPdf/redux/reportPdfSlice';
import { DocumentParts } from '../../ScreenReportPdf/constant';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';

function ComponentGeneratePDF() {
  const dispatch = useDispatch();
  const {
    control, handleSubmit, formState: { errors }, setValue,
  } = useForm<TypesPDFValues>({
    resolver: yupResolver(PDFFormSchema as ObjectSchema<TypesPDFValues>),
    defaultValues: DefaultPDFValues,
  });
  const [data, setData] = useState<GeneratePDFListingInterface[]>([]);
  const { allListContents, isLoading, signatureData } = useSelector((state:RootState) => state.reportWorkspaceReducer);
  const printRef = useRef(null);
  const { jobOrderId } = useParams();

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
    removeAfterPrint: true,
    copyStyles: true,
  });

  const columns = useMemo(
    () => [
      { key: 'sn', label: 'SN' },
      { key: 'report', label: 'Report' },
    ],
    [],
  );

  const handleData = () => {
    dispatch(getListContentsListStart(false));
  };

  useEffect(() => {
    handleData();
    setValue(GenerateForms.jobOrder, String(jobOrderId));
    dispatch(getSignatureStart());
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (allListContents?.length > 0) {
      const transformedData = allListContents?.map((ele, index) => ({
        sn: index,
        report: ele.Title,
        id: String(ele.PartNumber),
        isChecked: false,
      }));
      transformedData[0].isChecked = true;
      setData(transformedData);
    }
  }, [allListContents]);

  const handleButtonClick = () => {
    let str1 = '';
    let str2 = '';
    let str3 = '';
    let str4 = '';

    data?.forEach((element) => {
      if (element?.isChecked) {
        if (Number(element?.id) >= 0 && Number(element?.id) <= Number(DocumentParts.Defect_Graph)) {
          if (Number(element?.id) === Number(DocumentParts.Table_of_Contents)) {
            str1 += '';
          } else {
            str1 += `${element?.id},`;
          }
        } else if (Number(element?.id) >= Number(DocumentParts.Summary_of_Inspection) && Number(element?.id) <= Number(DocumentParts.Data_Sheets)) {
          str2 += `${element?.id},`;
        } else if (Number(element?.id) >= Number(DocumentParts.Tube_Charts) && Number(element?.id) < Number(DocumentParts.Phase_Charts)) {
          str3 += `${element?.id},`;
        } else {
          str4 += `${element?.id},`;
        }
      }
    });

    dispatch(setCheckedSections(str1 + str2 + str3 + str4));

    if (str1.length > 0) {
      const payload:GetReportPdfPayload = {
        jobOrderId: String(jobOrderId),
        Sections: str1.slice(0, -1),
      };
      dispatch(getReportPdf1Start(payload));
    }

    if (str2.length > 0) {
      const payload2:GetReportPdfPayload = {
        jobOrderId: String(jobOrderId),
        Sections: str2.slice(0, -1),
      };
      dispatch(getReportPdf2Start(payload2));
    }

    if (str3.length > 0) {
      const payload3:GetReportPdfPayload = {
        jobOrderId: String(jobOrderId),
        Sections: str3.slice(0, -1),
      };
      dispatch(getReportPdf3Start(payload3));
    }

    if (str4.length > 0) {
      const payload4:GetReportPdfPayload = {
        jobOrderId: String(jobOrderId),
        Sections: str4.slice(0, -1),
      };
      dispatch(getReportPdf4Start(payload4));
    }

    dispatch(setLoader(true));
    setTimeout(() => {
      handlePrint();
      dispatch(setLoader(false));
    }, 4000);
  };

  const handleCheckBoxWithKey = (id: string) => {
    // const updatedData = data?.map((element: GeneratePDFListingInterface) => {
    //   if (element?.id === id) {
    //     return {
    //       ...element,
    //       isChecked: !element?.isChecked,
    //     };
    //   }
    //   return element;
    // });

    const updatedData = [...data];
    const effId = updatedData.findIndex((ele:GeneratePDFListingInterface) => ele.id === id);

    if (effId > -1) {
      updatedData[effId].isChecked = !updatedData[effId].isChecked;
    }

    if (id === '1') {
      updatedData[1].isChecked = !updatedData[effId].isChecked;
    }

    if (id === '2') {
      updatedData[0].isChecked = !updatedData[effId].isChecked;
    }

    setData([...updatedData]);
  };

  const onSubmit = (values: TypesPDFValues) => {
    debugLog('🚀 ~ onSubmit ~ values:', values);
  };

  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);
  debugLog('🚀 ~ ComponentGeneratePDF ~ jobSelected:', jobSelected);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ComponentPageHeader subHeading="Generate PDF" />
        <div style={{ padding: '20px', height: '86vh', overflow: 'auto' }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <div className={style.tableHeading}>
                <span className={style.tableHeadingText}>
                  PDF in Box
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card className="customerInfo_main_container">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">Job Order #</label>
                      <ComponentTextInput
                        control={control}
                        inputProps={{ maxLength: 255 }}
                        className="nonCapitalizeField"
                        name={GenerateForms.jobOrder}
                        isDisable
                        id={GenerateForms.jobOrder}
                        size="small"
                        errors={errors}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">Signature</label>
                      <ComponentSelectInput
                        name={GenerateForms.signature}
                        control={control}
                        errors={errors}
                        size="small"
                        entries={signatureData}
                      />
                    </div>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className="div_label_text">
                      <label className="label_Style_Customer">Report Style</label>
                      <ComponentSelectInput
                        name={GenerateForms.reportStyle}
                        control={control}
                        errors={errors}
                        size="small"
                        entries={[]}
                      />
                    </div>
                  </Grid> */}
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <DataTable<GeneratePDFListingInterface>
                data={data}
                columns={columns}
                totalRecords={data?.length}
                checkBoxWithKey={['sn']}
                handleCheckBoxWithKey={handleCheckBoxWithKey}
                handleData={handleData}
              />
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div" style={{ marginTop: '20px' }}>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  onClick={() => handleButtonClick()}
                  className="button_save_and_next"
                >
                  Save & View
                </Button>
              </div>
            </div>
          </Grid>
        </div>
      </form>
      <ScreenReportPdf printRef={printRef} />
    </>
  );
}

export default ComponentGeneratePDF;
