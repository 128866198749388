import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import FirstPageSection from './firstPageSection';
import TableContentSection from './tableContentSection';
import VesselBayLengthInfoSection from './vesselBayLengthInfoSection';
import VesselInfoSection from './VesselInfoSection';
import SummaryInspectionSection from './summaryInspectionSection';
import RecommendationSection from './recommendationSection';
import DataSheetSection from './dataSheetSection';
import CalibrationProcedureSection from './calibrationProcedureSection';
import AbbreviationsSection from './abbreviationsSection';
import BartChartNewSection from './BartChartNewSection';
import LineChartSection from './LineChartSection';
import TubeBundleLayoutSection from './TubeBundleLayoutSection';
import { RootState } from '../../../redux/rootState';
import StripChartSection from './StripChartSection';
import ExpenseReport from './ComponentsExpenseReport';
import HazardAnalysis from './ComponentHazardAnalysisReport';
import { DocumentParts } from '../constant';
import ComponentPhotos from './ComponentPhotos';

const ComponentToPrint = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    checkedSections, reportPdfResponse3,
  } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <div ref={ref} style={{ margin: '8px' }} className="printable-content">
      <div className="w100">
        <FirstPageSection />
        <div className="page-break" />
        { checkedSections?.includes(DocumentParts.Table_of_Contents)
       && (
       <>
         <TableContentSection />
         <div className="page-break" />
       </>
       )}
        { checkedSections?.includes(DocumentParts.Vessel_Information)
        && (
        <>
          <VesselInfoSection />
          <div className="page-break" />
        </>
        )}
        <VesselBayLengthInfoSection />

        {checkedSections?.includes(DocumentParts.Defect_Graph) && <BartChartNewSection />}

        {checkedSections?.includes(DocumentParts.Summary_of_Inspection)
          && (
          <>
            <SummaryInspectionSection />
            <div className="page-break" />
          </>
          )}

        {checkedSections?.includes(DocumentParts.Recommendations)
            && (
            <>
              <RecommendationSection />
              <div className="page-break" />
            </>
            )}

        {checkedSections?.includes(DocumentParts.Data_Sheets) && (
          <DataSheetSection />

        )}

        {checkedSections?.includes(DocumentParts.Tube_Charts) && <TubeBundleLayoutSection />}
        {checkedSections?.includes(DocumentParts.Photos) && (
          <>
            <ComponentPhotos />
            <div className="page-break" />
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Strip_Charts) && (
          <>
            <StripChartSection data={reportPdfResponse3?.Calibration_Strip_Charts} showTable />
            <div className="page-break" />
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Phase_Charts) && (
          <>
            <LineChartSection />
            <div className="page-break" />
          </>
        ) }
        {checkedSections?.includes(DocumentParts.Calibration_Procedure) && (
          <>
            <CalibrationProcedureSection />
            <div className="page-break" />
          </>
        )}
        {checkedSections?.includes(DocumentParts.Explanation_Of_Abbreviations) && (
          <>
            <AbbreviationsSection />
            <div className="page-break" />
          </>
        )}
        {checkedSections?.includes(DocumentParts.Hazard_Analysis) && (
        <HazardAnalysis />
        )}
        {checkedSections?.includes(DocumentParts.Expense_Report) && (
        <>
          <ExpenseReport />
          <div className="page-break" />
        </>
        )}
      </div>
    </div>
  );
});

export default ComponentToPrint;
