import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { debugLog } from '../../../../Common/Logger';
import { TubeChartConfiguration, TubeChartEnum, TubeChartInterface } from '../../utils/tubeChartTypes';
import TubeDetail from './tubeDetail';
import TubeList from './tubeList';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';

function TubeChartComponent() {
  const {
    control, handleSubmit, setValue,
  } = useForm<TubeChartInterface>();

  const { isLoading, tubeCharts } = useSelector((state:RootState) => state.TubeChartReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const onSubmit = (data: TubeChartInterface) => {
    debugLog(data);
  };

  useEffect(() => {
    if (tubeCharts?.[0]?.ChartId) {
      setValue(TubeChartEnum.originalTestEnd, tubeCharts[0].OriginalTestEnd ? 'original' : 'reverse');
      setValue(TubeChartEnum.vesselTop, tubeCharts[0].VesselTop);

      const totalTubeCount = tubeCharts.reduce((acc:number, curr:TubeChartConfiguration) => acc + Number(curr.TubeCount), 0);

      setValue(TubeChartEnum.totalTubes, String(totalTubeCount));
    }
  }, [tubeCharts?.[0]?.ChartId]);

  return (
    <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <TubeList />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TubeDetail control={control} />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  disabled={Boolean(roleId === ANALYST_ROLE_ID)}
                  className="button_save_and_next"
                >
                  Edit Drawing
                </Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  className="button_save_and_next"
                  onClick={() => setImportFromLibrary(true)}
                >
                  Import From Library
                </Button>
              </div>
            </div>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
}

export default TubeChartComponent;
