import {
  Grid, Card, Button,
} from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequiredFiled from '../../../../../../utils/RequiredField';
import { IconList } from '../../../../../../utils/StaticIcons';
import { LocalizationContext } from '../../../../../../Common/Localization/LocalizationProvider';
import {
  isKitRangeFilled,
  setKitsRange,
  setOpenViewKitsModal,
} from '../../redux/addOilKitsSlice';
import { KitInfo, TypeOilKitsInformation } from '../../utils/types';
import { RootState } from '../../../../../../redux/rootState';
import { DeleteSvg } from '../../../../../../assets/TableIcons';

export default function KitsAssignedComponent({ kitId }: TypeOilKitsInformation): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const { isFilled } = useSelector((state: RootState) => state.addOilKitsReducer);
  const [inputFields, setInputFields] = useState<KitInfo[]>([
    { kitsStart: '', kitsEnd: '' },
  ]);

  const openViewOilKitsList = () => {
    dispatch(setOpenViewKitsModal(true));
  };

  const removeKit = (index: number) => {
    const tempData = [...inputFields];
    tempData.splice(index, 1);
    setInputFields(tempData);
  };

  useEffect(() => {
    const filled = inputFields.every((field) => field.kitsStart !== '' && field.kitsEnd !== '');
    dispatch(isKitRangeFilled(filled));
  }, [inputFields]);

  const handleInputChange = (index: number, field: keyof KitInfo, value: string) => {
    const newInputFields = [...inputFields];
    newInputFields[index][field] = value;
    if (value.length <= 9) {
      setInputFields(newInputFields);
    }
  };

  useEffect(() => {
    const formattedArray = inputFields.map((field: KitInfo) => `${field.kitsStart}-${field.kitsEnd}`);
    dispatch(setKitsRange(formattedArray));
  }, [dispatch, inputFields]);

  const viewOilKitsButtonClass = useMemo(() => (!kitId ? 'disableViewOilKitsBtn' : 'button_save_and_next'), [kitId]);

  const addMoreKits = () => {
    if (isFilled) {
      const newField = { kitsStart: '', kitsEnd: '' };
      setInputFields([...inputFields, newField]);
    }
  };

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="KitsAssigned">
          {translations.kitsAssignedOilKits}
        </div>
      </div>
      <div className="KitsAssigned_main_container">
        <Grid container spacing={2}>
          {inputFields.map((field, index: number) => (
            <>
              <Grid item xs={11} sm={11} md={7} className="asignedKits">
                <div className="div_label_text inputAssignedKits">
                  <div className="div_label">
                    <label className="label_Style_Customer">{translations.KitOilKits}</label>
                    {!kitId && <RequiredFiled />}
                  </div>
                  <input
                    style={{ height: '40px' }}
                    name="kitsStart"
                    id={`kitsStart_${index}`}
                    value={field.kitsStart}
                    onChange={(e) => handleInputChange(index, 'kitsStart', e.target.value)}
                    required={!kitId}
                    type="number"
                    className="no-arrows"
                  />
                </div>
                <div className="div_label_text textTo">
                  <label className="label_Style_Customer">
                    &nbsp;
                  </label>
                  <span>{translations.toOilKits}</span>
                </div>
                <div className="div_label_text inputAssignedKits">
                  <label className="label_Style_Customer">{translations.KitOilKits}</label>
                  <input
                    style={{ height: '40px' }}
                    name="kitsEnd"
                    id={`kitsEnd_${index}`}
                    value={field.kitsEnd}
                    onChange={(e) => handleInputChange(index, 'kitsEnd', e.target.value)}
                    type="number"
                    className="no-arrows"
                  />
                </div>
              </Grid>
              {index !== 0
                && (
                  <Grid item xs={1} sm={1} md={1} className="addMoreBtn">
                    <div
                      onClick={() => removeKit(index)}
                      className="add_kits_icon"
                    >
                      <DeleteSvg />
                    </div>
                  </Grid>
                )}
              {index === 0
                && (
                  <Grid item xs={1} sm={1} md={1} className="addMoreBtn">
                    <div
                      onClick={addMoreKits}
                      className="add_kits_icon"
                    >
                      <img
                        src={isFilled ? IconList.selectedAddKitsIcon : IconList.addKitsIcon}
                        width={30}
                        height={30}
                        alt=""
                      />
                    </div>
                  </Grid>
                )}
            </>
          ))}
          <Grid item xs={12} sm={12} md={4} className="addAssignedBtnContainer">
            <Button disabled={!kitId} onClick={openViewOilKitsList} className={viewOilKitsButtonClass}>
              {translations.viewOilKitOilKits}
            </Button>
          </Grid>
        </Grid>
      </div>

    </Card>
  );
}
