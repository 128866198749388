import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { makeGetRequest } from '../../../Common/NetworkOps';
import Config from '../../../Common/Config';
import {
  GetReportPdfPayload, ReportPdfResponse1, ReportPdfResponse2, ReportPdfResponse3,
  ReportPdfResponse4,
} from '../types';
import {
  getReportPdf1Failure, getReportPdf1Start, getReportPdf1Success, getReportPdf2Failure, getReportPdf2Start,
  getReportPdf2Success, getReportPdf3Failure, getReportPdf3Start, getReportPdf3Success, getReportPdf4Failure,
  getReportPdf4Start, getReportPdf4Success, ReportPdfActions,
} from './reportPdfSlice';
import { GetById } from '../../../utils/type';
import { showErrorToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';

async function getReportPdf1(data: GetReportPdfPayload): Promise<GetById<ReportPdfResponse1>> {
  const url = `${Config.workspace.reportPdf}/part1?JobOrder=${data.jobOrderId}&Sections=${data.Sections}`;
  const result = await makeGetRequest<GetById<ReportPdfResponse1>>(url);
  return result?.data;
}

export const getReportPdf1Epic = (action$: Observable<ReportPdfActions>) => action$.pipe(
  filter(getReportPdf1Start.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetReportPdfPayload) => from(getReportPdf1(data)).pipe(
    map((res: GetById<ReportPdfResponse1>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getReportPdf1Success(res.BMT.Result);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return getReportPdf1Failure();
    }),
    takeUntil(action$.pipe(filter(getReportPdf1Start.match))),
    catchError((err: AxiosError<GetById<string>>) => {
      const { response } = err;
      showErrorToaster(String(response?.data?.BMT?.ResponseMessage));
      return of(getReportPdf1Failure());
    }),
  )),
);

async function getReportPdf2(data: GetReportPdfPayload): Promise<GetById<ReportPdfResponse2>> {
  const url = `${Config.workspace.reportPdf}/part2?JobOrder=${data.jobOrderId}&Sections=${data.Sections}`;
  const result = await makeGetRequest<GetById<ReportPdfResponse2>>(url);
  return result?.data;
}

export const getReportPdf2Epic = (action$: Observable<ReportPdfActions>) => action$.pipe(
  filter(getReportPdf2Start.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetReportPdfPayload) => from(getReportPdf2(data)).pipe(
    map((res: GetById<ReportPdfResponse2>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getReportPdf2Success(res.BMT.Result);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return getReportPdf2Failure();
    }),
    takeUntil(action$.pipe(filter(getReportPdf2Start.match))),
    catchError(() => of(getReportPdf2Failure())),
  )),
);

async function getReportPdf3(data: GetReportPdfPayload): Promise<GetById<ReportPdfResponse3>> {
  const url = `${Config.workspace.reportPdf}/part3?JobOrder=${data.jobOrderId}&Sections=${data.Sections}`;
  const result = await makeGetRequest<GetById<ReportPdfResponse3>>(url);
  return result?.data;
}

export const getReportPdf3Epic = (action$: Observable<ReportPdfActions>) => action$.pipe(
  filter(getReportPdf3Start.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetReportPdfPayload) => from(getReportPdf3(data)).pipe(
    map((res: GetById<ReportPdfResponse3>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getReportPdf3Success(res.BMT.Result);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return getReportPdf3Failure();
    }),
    takeUntil(action$.pipe(filter(getReportPdf3Start.match))),
    catchError(() => of(getReportPdf3Failure())),
  )),
);

async function getReportPdf4(data: GetReportPdfPayload): Promise<GetById<ReportPdfResponse4>> {
  const url = `${Config.workspace.reportPdf}/part4?JobOrder=${data.jobOrderId}&Sections=${data.Sections}`;
  const result = await makeGetRequest<GetById<ReportPdfResponse4>>(url);
  return result?.data;
}

export const getReportPdf4Epic = (action$: Observable<ReportPdfActions>) => action$.pipe(
  filter(getReportPdf4Start.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: GetReportPdfPayload) => from(getReportPdf4(data)).pipe(
    map((res: GetById<ReportPdfResponse4>) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return getReportPdf4Success(res.BMT.Result);
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return getReportPdf4Failure();
    }),
    takeUntil(action$.pipe(filter(getReportPdf4Start.match))),
    catchError(() => of(getReportPdf4Failure())),
  )),
);
