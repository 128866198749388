import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl, FormControlLabel, RadioGroup, Radio,
} from '@mui/material';
import { IComponentRadioButton } from './Type';
import './Style.scss';

function CustomRadioButton({
  control, name, label, required, defaultValue, className, handleChange, isDisable, options,
}: IComponentRadioButton) {
  const handleEventChange = (val: string, onChange: (val: string) => void) => {
    if (isDisable) return;
    onChange(val);
    if (handleChange) {
      handleChange(val);
    }
  };

  return (
    <FormControl>
      {label && (
      <label className="container_label" htmlFor={name}>
        {label}
        {required && <span className="errorMessage">*</span>}
      </label>
      )}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required }}
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            row
            name={name}
            value={value}
            onChange={(event) => handleEventChange(event.target.value, onChange)}
            className={className}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                disabled={isDisable}
                control={<Radio color="success" size="medium" />}
                label={option.label}
                labelPlacement="start"
                className="custom-radio-label"
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
}

export default CustomRadioButton;
