/* eslint-disable max-len */
import React from 'react';

export function BlackArrowIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.7073 6.33066L2.51702 0.905485C2.48641 0.885211 2.45088 0.873611 2.4142 0.87192C2.37753 0.870229 2.34108 0.878509 2.30873 0.895881C2.27639 0.913253 2.24936 0.939067 2.23052 0.970577C2.21167 1.00209 2.20172 1.03811 2.20172 1.07483V11.9252C2.20172 11.9619 2.21167 11.9979 2.23052 12.0294C2.24936 12.0609 2.27639 12.0868 2.30873 12.1041C2.34108 12.1215 2.37753 12.1298 2.4142 12.1281C2.45088 12.1264 2.48641 12.1148 2.51702 12.0945L10.7073 6.66935C10.7353 6.65082 10.7582 6.62566 10.7741 6.59611C10.79 6.56656 10.7983 6.53354 10.7983 6.5C10.7983 6.46646 10.79 6.43344 10.7741 6.40389C10.7582 6.37434 10.7353 6.34918 10.7073 6.33066Z" fill="black" />
    </svg>
  );
}
