import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import { GeneratePDFInBoxListingInterface } from '../utils/types';
import ComponentSendEmailModel from './ComponentSendEmailModel';
import { getCustomerContactList } from '../../oilReport/redux/oilReportSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';

function ComponentPDFinBox() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { custId } = useParams();
  const [data, setData] = useState<GeneratePDFInBoxListingInterface[]>([]);
  const { CustomerContactList, isContactLoading } = useSelector((state: RootState) => state.oilReportReducer);
  const [emailsData, setEmailsData] = useState('');

  const columns = useMemo(
    () => [
      { key: 'sn', label: 'SN' },
      { key: 'Name', label: 'Name' },
      { key: 'Address', label: 'Address' },
      { key: 'EC_Report', label: 'EC Report' },
      { key: 'Oil_Report', label: 'Oil Report' },
    ],
    [],
  );

  const tableData: GeneratePDFInBoxListingInterface[] = useMemo(() => {
    if (CustomerContactList?.Data?.length > 0) {
      const transformedData = CustomerContactList?.Data?.map((ele, index) => ({
        sn: index,
        Name: ele.FirstName,
        Address: ele.Email,
        EC_Report: ele.EctReport ? 'Yes' : 'No',
        Oil_Report: ele.OilReport ? 'Yes' : 'No',
        id: `${String(ele.Email)}`,
      }));
      return transformedData;
    }
    return [];
  }, [CustomerContactList]);

  useEffect(() => {
    if (CustomerContactList) {
      setData(tableData);
    }
  }, [CustomerContactList]);

  const handleButtonClick = () => {
    const jobIds: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        jobIds.push(element?.id);
      }
    });
    const payload = {
      selectedIds: jobIds,
    };
    const emailString = payload.selectedIds.join(', ');
    setEmailsData(emailString);
    setOpen(true);
  };

  const handleData = () => {
    const payload = {
      customerId: custId, page, rowsPerPage, searchQuery,
    };
    dispatch(getCustomerContactList(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: GeneratePDFInBoxListingInterface) => {
      if (element?.id === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  useEffect(() => {
    dispatch(setLoader(isContactLoading));
  }, [isContactLoading]);

  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  return (
    <>
      <ComponentPageHeader subHeading="Generate PDF" />
      <div style={{ padding: '20px', height: '86vh', overflow: 'auto' }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <DataTable<GeneratePDFInBoxListingInterface>
              data={data}
              columns={columns}
              totalRecords={CustomerContactList.TotalRecords}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              checkBoxWithKey={['sn']}
              handleCheckBoxWithKey={handleCheckBoxWithKey}
              handleData={handleData}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div" style={{ marginTop: '20px' }}>
              <div className="button_margin_left">
                <Button
                  type="button"
                  disabled={jobSelected}
                  onClick={() => handleButtonClick()}
                  className="button_save_and_next"
                >
                  Save & View
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
        { open && (
          <ComponentSendEmailModel
            show={open}
            setOpenFrom={() => setOpen(false)}
            emailsData={emailsData}
          />
        )}
      </div>
    </>
  );
}

export default ComponentPDFinBox;
