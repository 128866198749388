export const JobTableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'jobId', label: 'Job #' },
  { key: 'custId', label: 'Cust #' },
  { key: 'customer', label: 'Customer' },
  { key: 'site', label: 'Site' },
  { key: 'state', label: 'State' },
  { key: 'city', label: 'City' },
];
