import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { BayLengthTableColumns } from '../../utils/helper';
import { BayLengthInterface, BayLengthListingInterface, BaylengthPayload } from '../../utils/type';
import DataTable from '../../../../Common/DataTable';
import { BayLengthValue } from '../../utils/constant';
import { bayLengthSchema } from '../../utils/validation';
import { UpperGrid } from './upperGrid';
import PipeStructure from '../../commonModal/pipeStructure';
import { getBayLengthByIdStart } from '../../redux/baylength/baylengthSlice';
import { RootState } from '../../../../redux/rootState';
import { setLoader } from '../../../../redux/loaderSlice';

function BayLengthComponent() {
  const dispatch = useDispatch();
  const { jobOrderId, vesselId } = useParams();
  // const navigate = useNavigate();
  const [showPipeStructureComponent, setShowPipeStructureComponent] = useState(false);
  const {
    control, handleSubmit, formState: { errors },
  } = useForm<BayLengthInterface>({
    resolver: yupResolver(bayLengthSchema as ObjectSchema<BayLengthInterface>),
    defaultValues: BayLengthValue,
  });

  const { baylengthData, isLoading } = useSelector((state:RootState) => state.BayLengthReducer);

  const onSubmit = () => {
    setShowPipeStructureComponent(true);
  };

  useEffect(() => {
    const payload:BaylengthPayload = {
      jobId: String(jobOrderId),
      vesselId: String(vesselId),
    };
    dispatch(getBayLengthByIdStart(payload));
  }, []);

  const tableData:BayLengthListingInterface[] = useMemo(() => baylengthData?.Configuration?.map((value, index) => ({
    position: index + 1,
    length: value.toFixed(2),
    id: `${value}-${index + 1}`,
  })), [baylengthData?.Configuration]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <UpperGrid control={control} errors={errors} setShowPipeStructureComponent={setShowPipeStructureComponent} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <DataTable<BayLengthListingInterface>
              data={tableData}
              columns={BayLengthTableColumns}
              totalRecords={10}
              handleData={() => {}}
            />
          </Grid>
          {/* <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
            </div>
          </Grid> */}
        </Grid>
      </div>
      {showPipeStructureComponent && (
      <PipeStructure
        show={showPipeStructureComponent}
        setOpenFrom={() => setShowPipeStructureComponent(false)}
      />
      )}
    </form>
  );
}

export default BayLengthComponent;
