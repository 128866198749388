import {
  catchError, debounceTime, filter, from, map, mergeMap, Observable, of, takeUntil,
} from 'rxjs';
import { AxiosError } from 'axios';
import { ofType } from 'redux-observable';
import { showSuccessToaster, showErrorToaster } from '../../../../Common/ComponentToast/ComponentSuccessToasts';
import {
  createRecommendationCodesData,
  getRecommendationCodesData,
  onDeleteRecommendationCodes,
  onFailureCreateRecommendationCodesData, onFailureRecommendationCodesData,
  onSuccessCreateRecommendationCodesData, onSuccessDeleteRecommendationCodes, onSuccessRecommendationCodesData, RecommendationCodesActions,
} from './sliceRecommendationCodes';
import {
  CreateRecommendationCodesPayload,
  PayloadRecommendationPayload, RecommendationCodesApiResponse, RecommendationCodesDeleteApiResponse, RecommendationCodesPostApiResponse,
} from './Type';
import Config from '../../../../Common/Config';
import { makeDeleteRequest, makeGetRequest, makePostRequest } from '../../../../Common/NetworkOps';

async function getRecommendationCodesApiCall(data: PayloadRecommendationPayload): Promise<RecommendationCodesApiResponse> {
  // eslint-disable-next-line max-len
  const url = `${Config.utilities.getCalibration}?pageNumber=${data?.page}&pageSize=${data?.rowsPerPage}&searchItem=${data?.searchQuery}&Type=${data?.type}`;
  const result = await makeGetRequest<RecommendationCodesApiResponse>(url);
  return result?.data;
}

export const epicGetRecommendationCodesData = (action$: Observable<RecommendationCodesActions>) => action$.pipe(
  filter(getRecommendationCodesData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data: PayloadRecommendationPayload) => from(getRecommendationCodesApiCall(data)).pipe(
    map((res: RecommendationCodesApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        return onSuccessRecommendationCodesData(res?.BMT.Result);
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return onFailureRecommendationCodesData(res?.BMT?.ResponseMessage);
    }),
    takeUntil(action$.pipe(filter(getRecommendationCodesData.match))),
    catchError((error: AxiosError<RecommendationCodesApiResponse>) => of(onFailureRecommendationCodesData(
      error.response?.data.BMT.ResponseMessage as string,
    ))),
  )),
);

async function createRecommendationCodesApiCall(data: CreateRecommendationCodesPayload): Promise<RecommendationCodesPostApiResponse> {
  const url = Config.utilities.createCalibration;
  const result = await makePostRequest<RecommendationCodesPostApiResponse>(url, data);
  return result?.data;
}

export const epicCreateRecommendationCodesData = (action$: Observable<RecommendationCodesActions>) => action$.pipe(
  filter(createRecommendationCodesData.match),
  debounceTime(250),
  map((x) => x.payload),
  mergeMap((data) => from(createRecommendationCodesApiCall(data)).pipe(
    mergeMap((res: RecommendationCodesPostApiResponse) => {
      if (res.BMT.ResponseCode === Config.POST_SUCCESS_CODE || res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res?.BMT?.ResponseMessage);
        return of(onSuccessCreateRecommendationCodesData(true));
      }
      showErrorToaster(res?.BMT?.ResponseMessage);
      return of(onFailureCreateRecommendationCodesData(true));
    }),
    takeUntil(action$.pipe(filter(createRecommendationCodesData.match))),
    catchError(() => of(onFailureCreateRecommendationCodesData(true))),
  )),
);

async function deleteContact(data: string): Promise<RecommendationCodesDeleteApiResponse> {
  const url = `${Config.utilities.deleteUtility}?Id=${data}`;
  const result = await makeDeleteRequest<RecommendationCodesDeleteApiResponse>(url);
  return result.data;
}

export const epicDeleteRecommendationCodes = (action$: Observable<RecommendationCodesActions>) => action$.pipe(
  ofType(onDeleteRecommendationCodes.type),
  map((x) => x.payload),
  mergeMap((data: string) => from(deleteContact(data)).pipe(
    mergeMap((res: RecommendationCodesDeleteApiResponse) => {
      if (res.BMT.ResponseCode === Config.SUCCESS_CODE) {
        showSuccessToaster(res.BMT.ResponseMessage);
        return of(onSuccessDeleteRecommendationCodes(true));
      }
      showErrorToaster(res.BMT.ResponseMessage);
      return of(onSuccessDeleteRecommendationCodes(true));
    }),
    takeUntil(action$.pipe(filter(onDeleteRecommendationCodes.match))),
    catchError(() => of(onSuccessDeleteRecommendationCodes(true))),
  )),
);
