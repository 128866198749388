import React, { useState } from 'react';
import {
  Table, TableContainer, TableHead, TableRow, Paper, TableBody, MenuItem, Card,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import './StyleEntryDataTable.scss';
import ComponentTablePageHeader from './ComponentTablePageHeader';
import { getTableHeaderData } from '../Utils/DataBidEntryHeaderCell';
import ComponentCustomerTableRow from './ComponentBidEntryTableRow';
import ComponentTableHeader from '../../../../../Common/TableHeader/ComponentTableHeader';

const theme = createTheme({
  palette: {
    primary: {
      main: '#092245',
      contrastText: '#fff',
    },
  },
});

export default function ComponentEntryDataTable() {
  const [pages, setPages] = useState('10');
  const handleChange = (event: SelectChangeEvent) => {
    setPages(event.target.value);
  };
  const [currentPage, setCurrentPage] = useState(0);
  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value - 1);
  };
  const headerData = getTableHeaderData();
  const ITEM_PER_PAGE = 1;

  return (
    <div className="div_customer_container_info">
      <Card>
        <ThemeProvider theme={theme}>
          <ComponentTablePageHeader />
          <TableContainer component={Paper} className="customPaper">
            <Table stickyHeader className="tableStyle">
              <TableHead>
                <TableRow>
                  { headerData.map(({ colWidth, header }) => (
                    <ComponentTableHeader key={colWidth} colWidth={colWidth} header={header} />
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <ComponentCustomerTableRow
                  sno={1}
                  description="not now"
                  amount={9000}
                  priceDes={90}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <div className="table-pagination-style">
            <div>
              <span className="font-size">Show</span>
              <span className="select-style">
                <Select
                  value={pages}
                  className="table-select-style"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>10</MenuItem>
                </Select>
              </span>
              <span className="font-size">entries</span>
            </div>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(100 / ITEM_PER_PAGE)}
                page={currentPage + 1}
                onChange={handleChangePagination}
                color="primary"
                shape="rounded"
              />
            </Stack>
          </div>
        </ThemeProvider>
      </Card>
    </div>
  );
}
