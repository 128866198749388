/* eslint-disable max-len */
import React from 'react';

export function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
      <g clipPath="url(#clip0_5988_18417)">
        <path d="M9.60297 3.05986C9.33827 2.44349 8.98234 1.91179 8.53535 1.46481C8.08827 1.0178 7.5566 0.66193 6.9402 0.397117C6.32388 0.132304 5.67728 0 5.00016 0C4.36217 0 3.7448 0.120332 3.14793 0.36127C2.55119 0.602163 2.02059 0.94177 1.55615 1.38018L0.709702 0.540229C0.579473 0.405668 0.429794 0.375317 0.260457 0.44904C0.0868116 0.522922 0 0.651007 0 0.833273V3.74995C0 3.86278 0.0412509 3.96045 0.12373 4.04291C0.206254 4.12539 0.30392 4.16664 0.41675 4.16664H3.33343C3.51577 4.16664 3.64378 4.07982 3.71757 3.9062C3.79132 3.73693 3.76097 3.58723 3.62638 3.45696L2.73444 2.55856C3.03836 2.27208 3.38554 2.05183 3.77615 1.89772C4.1668 1.74364 4.57474 1.66655 5.00011 1.66655C5.45146 1.66655 5.8823 1.75452 6.29246 1.93022C6.70269 2.10603 7.05737 2.34366 7.35689 2.64316C7.65638 2.94259 7.89401 3.29738 8.06983 3.70759C8.24548 4.11775 8.33343 4.54845 8.33343 4.99993C8.33343 5.45139 8.2455 5.88209 8.06983 6.29223C7.89401 6.70239 7.65638 7.05714 7.35689 7.35666C7.05737 7.65616 6.70255 7.89386 6.29246 8.0696C5.8823 8.24534 5.45146 8.33323 5.00011 8.33323C4.4836 8.33323 3.99532 8.22038 3.53522 7.99476C3.07519 7.76915 2.68673 7.45015 2.36988 7.03764C2.33953 6.99427 2.2896 6.96819 2.22014 6.95945C2.15501 6.95945 2.10074 6.97895 2.05735 7.01796L1.16542 7.91645C1.13074 7.95134 1.11229 7.99576 1.1101 8.04992C1.10796 8.10431 1.12212 8.15308 1.15247 8.19648C1.62561 8.76941 2.19847 9.2132 2.87121 9.52777C3.54395 9.84241 4.25365 9.99982 5.00016 9.99982C5.67728 9.99982 6.32388 9.86738 6.9402 9.60268C7.5566 9.338 8.08811 8.98198 8.53526 8.53501C8.98232 8.08786 9.33818 7.55628 9.60295 6.93995C9.86772 6.32363 9.99998 5.67684 9.99998 4.99986C10 4.32266 9.86765 3.67621 9.60297 3.05986Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_5988_18417">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
