import * as yup from 'yup';
import { maxDatePicker, minDatePicker } from '../../../../../utils/Constant';

const customerSiteFormSchema = yup.object().shape({
  customerId: yup.string().required(),
  customerName: yup.string(),
  customerContact: yup.string(),
  customerAddress: yup.string(),
  customerOptionalAdress: yup.string(),
  customerZip: yup.string(),
  customerState: yup.string(),
  customerCity: yup.string(),
  customerCountry: yup.string(),
  customerPhoneCode: yup.string(),
  customerPhone: yup.string(),
  customerSMC: yup.string(),
  contact: yup.string(),
  cell: yup.string(),
  phoneCode: yup.string(),
  phone: yup.string(),
  siteId: yup.string().required(),
  siteName: yup.string(),
  siteContact: yup.string(),
  siteAddress: yup.string(),
  siteOptionalAddress: yup.string(),
  siteZip: yup.string(),
  siteState: yup.string(),
  siteCity: yup.string(),
  siteCountry: yup.string(),
  sitePhoneCode: yup.string(),
  sitePhone: yup.string(),
  siteCell: yup.string(),
  siteType: yup.string(),
  siteSMC: yup.string(),
  siteNote: yup.string(),
});

export default customerSiteFormSchema;

export const addAnalystFormSchema = yup.object().shape({
  startDate: yup.date().min(minDatePicker).max(maxDatePicker).required(),
  endDate: yup.date().min(minDatePicker).max(maxDatePicker).required(),
});

export const travelInfoFormSchema = yup.object().shape({
  flightOutDate: yup.date().min(minDatePicker).max(maxDatePicker),
  flightInDate: yup.date().min(minDatePicker).max(maxDatePicker),
});

export const hotelInfoSchema = yup.object().shape({
  hotelDateOut: yup.date().min(minDatePicker).max(maxDatePicker),
  hotelDateIn: yup.date().min(minDatePicker).max(maxDatePicker),
});
