import dayjs from 'dayjs';
import { TimeDateFormInfo, TypeVesselDefectList } from '../defectType';

export function EditFinishTimePayload(values: TimeDateFormInfo, defectDataById: TypeVesselDefectList) {
  return {
    defectId: defectDataById.DefectId,
    jobOrder: defectDataById.JobOrder,
    vesselId: defectDataById.VesselId,
    section: defectDataById.Section || '',
    code: defectDataById.Code,
    location: defectDataById.Location,
    area: defectDataById.Area || '',
    date: dayjs(values.date).format('DD-MM-YYYY'),
    time: dayjs(values.time).format('HH:mm:ss'),
    row: String(defectDataById.Row),
    tube: String(defectDataById.Tube),
  };
}

export function EditStartTimePayload(values: TimeDateFormInfo, defectDataById: TypeVesselDefectList) {
  return {
    defectId: defectDataById.DefectId,
    jobOrder: defectDataById.JobOrder,
    vesselId: defectDataById.VesselId,
    section: defectDataById.Section || '',
    code: defectDataById.Code,
    location: defectDataById.Location,
    area: defectDataById.Area || '',
    date: dayjs(values.date).format('DD-MM-YYYY'),
    time: dayjs(values.time).format('HH:mm:ss'),
    row: String(defectDataById.Row),
    tube: String(defectDataById.Tube),
  };
}
