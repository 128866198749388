/* eslint-disable max-len */
import React from 'react';

export function AddIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M8 0C3.58144 0 0 3.58144 0 8C0 12.4186 3.58144 16 8 16C12.4186 16 16 12.4186 16 8C16 3.58144 12.4186 0 8 0ZM12.16 8.64H8.64V12.16C8.64 12.3297 8.57257 12.4925 8.45255 12.6125C8.33252 12.7326 8.16974 12.8 8 12.8C7.83026 12.8 7.66747 12.7326 7.54745 12.6125C7.42743 12.4925 7.36 12.3297 7.36 12.16V8.64H3.84C3.67026 8.64 3.50747 8.57257 3.38745 8.45255C3.26743 8.33252 3.2 8.16974 3.2 8C3.2 7.83026 3.26743 7.66747 3.38745 7.54745C3.50747 7.42743 3.67026 7.36 3.84 7.36H7.36V3.84C7.36 3.67026 7.42743 3.50747 7.54745 3.38745C7.66747 3.26743 7.83026 3.2 8 3.2C8.16974 3.2 8.33252 3.26743 8.45255 3.38745C8.57257 3.50747 8.64 3.67026 8.64 3.84V7.36H12.16C12.3297 7.36 12.4925 7.42743 12.6125 7.54745C12.7326 7.66747 12.8 7.83026 12.8 8C12.8 8.16974 12.7326 8.33252 12.6125 8.45255C12.4925 8.57257 12.3297 8.64 12.16 8.64Z" fill="#00A751" />
    </svg>
  );
}
