/* eslint-disable max-len */
import React from 'react';

export function PrintIcon() {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="28" height="28" rx="2.5" fill="#F06548" stroke="#F06548" />
      <path d="M10.383 19.5123H7.6978C7.46041 19.5123 7.23275 19.418 7.06489 19.2501C6.89704 19.0823 6.80273 18.8546 6.80273 18.6172V14.1419C6.80273 13.6671 6.99134 13.2118 7.32705 12.8761C7.66276 12.5404 8.11809 12.3518 8.59286 12.3518H19.3336C19.8084 12.3518 20.2637 12.5404 20.5994 12.8761C20.9351 13.2118 21.1237 13.6671 21.1237 14.1419V18.6172C21.1237 18.8546 21.0294 19.0823 20.8616 19.2501C20.6937 19.418 20.466 19.5123 20.2287 19.5123H17.5435M10.383 7.87646H17.5435V12.3518H10.383V7.87646ZM10.383 17.7221H17.5435V21.3024H10.383V17.7221Z" stroke="white" strokeWidth="1.3" strokeLinejoin="round" />
      <path d="M9.8829 15.0369C9.8829 15.2551 9.70602 15.432 9.48784 15.432C9.26965 15.432 9.09277 15.2551 9.09277 15.0369C9.09277 14.8187 9.26965 14.6418 9.48784 14.6418C9.70602 14.6418 9.8829 14.8187 9.8829 15.0369Z" fill="white" stroke="white" />
    </svg>
  );
}
