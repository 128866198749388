import {
  Box, Button, Grid, Tab, Tabs,
} from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import DataTable from '../../../../../../../../Common/DataTable';
import ComponentDeleteModal from '../../../../../../../../Common/DeleteModal/ComponentDeleteModal';
import { LocalizationContext } from '../../../../../../../../Common/Localization/LocalizationProvider';
import {
  selectedTabStyles, stylesTabIndicator, tabsStyles, tabStyles,
} from '../../../../../../../../Common/TabsStyles';
import { Cancel } from '../../../../../../../../media/images/Cancel';
import { RootState } from '../../../../../../../../redux/rootState';
import { getAssociatedSitesStart, getGeneralSitesStart, getSiteByIdStart } from '../../../../redux/addJobSlice';
import { CustomerSiteFormFields, Datatablestyle } from '../../../../utils/constants';
import { IAssociatedSites, SitesData } from '../utils/Type';
import { formatSitesTableData } from '../utils/helper';

export function AssociatedSites({ handleClose, handleButtonClick, getValues }: IAssociatedSites) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState<SitesData[] | []>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeTab, setActiveTab] = React.useState(1);
  const [selectedRadio, setSelectedRadio] = useState('');
  const {
    generalSites, associatedSites, totalAssociatedSites, totalgeneralSites,
  } = useSelector((state:RootState) => state.jobStarterReducer);
  const { otherCustomerdetails } = useSelector((state:RootState) => state.addCustomerReducer);

  const dispatch = useDispatch();
  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    const customerId = getValues(CustomerSiteFormFields.CustomerId);
    if (activeTab === 1) {
      dispatch(getAssociatedSitesStart({ ...payload, customerId: otherCustomerdetails?.Comments?.CustomerId || customerId }));
    } else {
      dispatch(getGeneralSitesStart(payload));
    }
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const { translations } = useContext(LocalizationContext);
  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'siteID', label: 'Site ID' },
      { key: 'site', label: translations.site },
      { key: 'address', label: translations.address },
      { key: 'country', label: translations.country },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
      { key: 'zip', label: 'Zip' },
    ],
    [],
  );

  const handleEdit = () => {

  };

  const handleSave = () => {
    dispatch(getSiteByIdStart(selectedRadio));
    handleClose();
  };

  const TABS = [
    { id: 1, label: 'Associated Sites' },
    { id: 2, label: 'General Sites' },
  ];

  const handleChangeTab = (tabId: number) => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };

    if (tabId === 2 && generalSites.length === 0) {
      dispatch(getGeneralSitesStart(payload));
    } else if (tabId === 1 && associatedSites.length === 0) {
      const customerId = getValues(CustomerSiteFormFields.CustomerId);
      dispatch(getAssociatedSitesStart({ ...payload, customerId: otherCustomerdetails?.Comments?.CustomerId || customerId }));
    }
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (activeTab === 1) {
      setData(formatSitesTableData(associatedSites));
      setTotalRecords(totalAssociatedSites);
    } else {
      setData(formatSitesTableData(generalSites));
      setTotalRecords(totalgeneralSites);
    }
  }, [associatedSites, generalSites, activeTab]);

  return (
    <>
      <Box sx={Datatablestyle}>
        <div className="customerInfo_container flex-row">
          <div className="customerInfo">
            {translations.siteSelector}
          </div>
          <div className="cancel-icon" onClick={handleClose}>
            <Cancel />
          </div>

        </div>
        <div className="customModalBtnContainer">
          <Tabs
            variant="scrollable"
            value={activeTab - 1}
            onChange={(_, newValue) => handleChangeTab(TABS[newValue].id)}
            style={tabsStyles}
            TabIndicatorProps={stylesTabIndicator}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.id}
                label={tab.label}
                style={activeTab === tab.id ? selectedTabStyles : tabStyles}
                className="tab_styles"
              />
            ))}
          </Tabs>
        </div>
        {/* </div> */}
        <DataTable<SitesData>
          handleButtonClick={handleButtonClick}
          data={data}
          columns={columns}
          totalRecords={totalRecords}
          buttonText="Add New Site"
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          handleDelete={handleDelete}
          radioButtonColumns={['select']}
          handleEdit={handleEdit}
          customClassName="tableWithExtraTab"
        />
        {
          data.length > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={handleClose}>{translations.cancel}</Button>
                </div>
                <div className="button_margin_left">
                  {/* Pass Id Of The Selected Customer In The Handle Change */}
                  <Button className="button_save_and_next" onClick={handleSave}>{translations.select}</Button>
                </div>
              </div>
            </Grid>
          )
        }
      </Box>
      <ComponentDeleteModal
        show={showDeleteModal}
        setOpenFrom={() => setDeleteModal(false)}
        handleDelete={handleDeleteModal}
      />
    </>
  );
}
