import * as Yup from 'yup';
import { maxDatePicker, minDatePicker } from '../../../utils/Constant';

export const TableColumns = [
  // { key: 'sn', label: 'SN' },
  { key: 'action', label: 'Action' },
  { key: 'reportNumber', label: 'Report #' },
  { key: 'kitNumber', label: 'Kit #' },
  { key: 'reportDate', label: 'Report Date' },
  { key: 'compSerialNumber', label: 'Comp. Serial #' },
  { key: 'customerNumber', label: 'Cust #' },
  { key: 'location', label: 'Location' },
  { key: 'serialNumber', label: 'Serial #' },
  { key: 'sampleNumber', label: 'Sample #' },
  { key: 'date', label: 'Date' },
];

export enum MainFormFields {
  TaiReportNumber = 'taiReportNumber',
  SampleNumber = 'sampleNumber',
  ReportDate = 'reportDate',
  SerialNumber = 'serialNumber',
  DateReceived = 'dateRecieved',
  Location = 'location',
  DateDrawn = 'dateDrawn',
  UnitMake = 'unitMake',
  UnitModel = 'unitModel',
  DateTested = 'dateTested',
  CustomerNumber = 'customerNumber',
  Manufacturer = 'manufacturer',
  Model = 'model',
  KitNumber = 'kitNumber',
  UnitNumber = 'unitNumber',
  CompanySerialNumber = 'companySerialNumber',
  Component = 'component',
}

export enum ConcentrationFields {
  Silicon = 'silicon',
  Iron = 'iron',
  Chromium = 'chromium',
  Aluminium = 'aluminium',
  Copper = 'copper',
  Lead = 'lead',
  Tin = 'tin',
  Nickel = 'nickel',
  Molybdenum = 'molybdenum',
  Magnesium = 'magnesium',
  Sodium = 'sodium',
  Boron = 'boron',
  Barium = 'barium',
  Phosphorus = 'phosphorus',
  Calcium = 'calcium',
  Visc = 'visc',
  Zinc = 'zinc',
  WaterKF = 'waterKF',
  TAN = 'tan',
  Potassium = 'potassium',
  Manganese = 'manganese',
  Titanium = 'titanium',
  Vanadium = 'vanadium',
}

export enum ResultFields {
  OilChanged = 'oilChanged',
  FluidType = 'fluidType',
  FluidBrand = 'fluidBrand',
  FluidGrade = 'fluidGrade',
  MHMRUnit = 'mhMrUnit',
  CompAge = 'compAge',
  FilterAge = 'filterAge',
  MhOil = 'mhOil',
  OilAdded = 'oilAdded',
  Condition = 'condition',
  Result = 'result',
}

export const ResultFieldLabel = {
  [ResultFields.OilChanged]: 'Oil Changed',
  [ResultFields.FluidType]: 'Fluid Type',
  [ResultFields.FluidBrand]: 'Fluid Brand',
  [ResultFields.FluidGrade]: 'Fluid Grade',
  [ResultFields.MHMRUnit]: 'MH/MR Unit',
  [ResultFields.CompAge]: 'Comp Age',
  [ResultFields.FilterAge]: 'Filter Age',
  [ResultFields.MhOil]: 'MH Oil',
  [ResultFields.OilAdded]: 'Oil Added',
  [ResultFields.Condition]: 'Condition',
  [ResultFields.Result]: 'Result',
};

export const ConcentrationLabel = {
  [ConcentrationFields.Silicon]: 'Silicon',
  [ConcentrationFields.Iron]: 'Iron',
  [ConcentrationFields.Chromium]: 'Chromium',
  [ConcentrationFields.Aluminium]: 'Aluminium',
  [ConcentrationFields.Copper]: 'Copper',
  [ConcentrationFields.Lead]: 'Lead',
  [ConcentrationFields.Tin]: 'Tin',
  [ConcentrationFields.Nickel]: 'Nickel',
  [ConcentrationFields.Molybdenum]: 'Molybdenum',
  [ConcentrationFields.Magnesium]: 'Magnesium',
  [ConcentrationFields.Sodium]: 'Sodium',
  [ConcentrationFields.Boron]: 'Boron',
  [ConcentrationFields.Barium]: 'Barium',
  [ConcentrationFields.Phosphorus]: 'Phosphorus',
  [ConcentrationFields.Calcium]: 'Calcium',
  [ConcentrationFields.Visc]: 'Visc',
  [ConcentrationFields.Zinc]: 'Zinc',
  [ConcentrationFields.WaterKF]: 'Water KF',
  [ConcentrationFields.TAN]: 'TAN',
  [ConcentrationFields.Potassium]: 'Potassium',
  [ConcentrationFields.Manganese]: 'Manganese',
  [ConcentrationFields.Titanium]: 'Titanium',
  [ConcentrationFields.Vanadium]: 'Vanadium',
};

export const MainFormLabel = {
  [MainFormFields.TaiReportNumber]: 'TAI Report #',
  [MainFormFields.SampleNumber]: 'Sample #',
  [MainFormFields.ReportDate]: 'Report Date',
  [MainFormFields.SerialNumber]: 'Serial #',
  [MainFormFields.DateReceived]: 'Date Received',
  [MainFormFields.Location]: 'Location',
  [MainFormFields.DateDrawn]: 'Date Drawn',
  [MainFormFields.UnitMake]: 'Unit Make',
  [MainFormFields.UnitModel]: 'Unit Model',
  [MainFormFields.DateTested]: 'Date Tested',
  [MainFormFields.CustomerNumber]: 'Customer #',
  [MainFormFields.Manufacturer]: 'Manufacturer',
  [MainFormFields.Model]: 'Model',
  [MainFormFields.KitNumber]: 'Kit #',
  [MainFormFields.UnitNumber]: 'Unit #',
  [MainFormFields.CompanySerialNumber]: 'Company Serial #',
  [MainFormFields.Component]: 'Component',
};

export const MainFormSchema = Yup.object({
  [MainFormFields.ReportDate]: Yup.date().min(minDatePicker).max(maxDatePicker),
  [MainFormFields.DateReceived]: Yup.date().min(minDatePicker).max(maxDatePicker),
  [MainFormFields.DateDrawn]: Yup.date().min(minDatePicker).max(maxDatePicker),
  [MainFormFields.DateTested]: Yup.date().min(minDatePicker).max(maxDatePicker),
});
