/* eslint-disable max-len */
import * as Yup from 'yup';

export const addUserManagementSchema = Yup.object({
  userId: Yup.string().required('UserId is required').matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email format'),
  initials: Yup.string().required('Initials is required'),
  password: Yup.string().test({
    name: 'customValidation',
    test(value) {
      const roleValue = this.resolve(Yup.ref('role'));
      const regEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-zA-Z]).{8,}$/;
      if (roleValue === '4') {
        if (value && regEx.test(value)) {
          return true;
        }
        return false;
      }
      return true;
    },
    message: 'Password should contain at least one upper case, lower case, number & special character. Also the length of the password should be at least 8 characters.',
  }),
  role: Yup.string()
    .required('Role is required'),
  analyst: Yup.string().test({
    name: 'customValidation',
    test(value) {
      const roleValue = this.resolve(Yup.ref('role'));
      return roleValue === '4' ? !!value : true;
    },
    message: 'Analyst is required',
  }),
});
