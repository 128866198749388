import {
  Grid, Card,
} from '@mui/material';
import { useSelector } from 'react-redux';
import React from 'react';
import { IComponentPrimaryContact, PrimaryContactForms } from '../../utils/types';
import './StyleMainTab.scss';
import ComponentTextInput from '../../../../Common/ComponentTextinput/ComponentTextInput';
import ComponentSelectInput from '../../../../Common/ComponentSelectInput';
import { RootState } from '../../../../redux/rootState';
import RequiredFiled from '../../../../utils/RequiredField';
import { phoneRegex } from '../../../../utils/regex';

export default function PrimaryContact({ control, errors }: IComponentPrimaryContact): JSX.Element {
  const { phoneCodes, salutations } = useSelector((state: RootState) => state.masterDataReducer);

  return (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          Primary Contact
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="div_label_text">
              <label className="no_label_Style" />
              <ComponentSelectInput
                name={PrimaryContactForms.title}
                required
                control={control}
                errors={errors}
                size="small"
                entries={salutations}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">
                First Name
                <RequiredFiled />
              </label>
              <ComponentTextInput
                control={control}
                required
                capitalizeFirst
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                errors={errors}
                name={PrimaryContactForms.firstName}
                id={PrimaryContactForms.firstName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Last Name</label>
              <ComponentTextInput
                control={control}
                capitalizeFirst
                name={PrimaryContactForms.lastName}
                regEx={/^[A-Za-z ]+$/}
                inputProps={{ maxLength: 25 }}
                id={PrimaryContactForms.lastName}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Email ID</label>
              <ComponentTextInput
                control={control}
                inputProps={{ maxLength: 255 }}
                className="nonCapitalizeField"
                name={PrimaryContactForms.primaryEmailId}
                id={PrimaryContactForms.primaryEmailId}
                size="small"
                errors={errors}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Code</label>
              <ComponentSelectInput
                name={PrimaryContactForms.contactCode}
                control={control}
                errors={errors}
                size="small"
                entries={phoneCodes}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={5} lg={3} xl={4}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Phone</label>
              <ComponentTextInput
                control={control}
                name={PrimaryContactForms.phone}
                regEx={phoneRegex}
                id={PrimaryContactForms.phone}
                size="small"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
            <div className="div_label_text">
              <label className="label_Style_Customer">Alternative Phone</label>
              <ComponentTextInput
                control={control}
                name={PrimaryContactForms.alternativePhone}
                regEx={phoneRegex}
                id={PrimaryContactForms.alternativePhone}
                size="small"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
}
