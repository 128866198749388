/* eslint-disable react/jsx-no-useless-fragment */
import { Grid } from '@mui/material';
import React from 'react';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { Field, FieldsProps } from '../utils/type';
import { fieldsData } from '../utils/helper';

function ComponentGraphFields({ control, errors }:FieldsProps): JSX.Element {
  return (
    <>
      {
      fieldsData?.map((field: Field) => (
        <Grid item xs={12} sm={6} md={3} key={field.name}>
          <div className="div_label_text">
            <label className={field.disable ? 'label_Disable' : 'label_Style_Customer'}>{field.label}</label>
            <ComponentTextInput
              control={control}
              isDisable={field.disable}
              name={field.name}
              size="small"
              inputProps={{ maxLength: field.maxLength }}
              id={field.name}
              errors={errors}
            />
          </div>
        </Grid>
      ))
    }
    </>
  );
}

export default ComponentGraphFields;
