import {
  Card, Grid, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import styles from '../../index.module.scss';
import { RootState } from '../../../../redux/rootState';
import {
  AnalystDetails, FormatMonthData, GetAllEventsQuery, MonthGridProps,
} from '../../utils/types';
import { formatMonthData, getCalendarIcon } from '../../utils/helper';
import { Entries } from '../../../../Common/ComponentSelectInput/types';
import {
  getAllEventsStart, setSelectedAnalyst, setSelectedMonth, setSelectedYear,
} from '../../redux/calenderSlice';
import { gridClassname, months } from '../../utils/constant';
import CustomPopper from '../grid/customPopper';
import CustomTooltip from '../grid/customTooltip';
import arrowIcon from '../../../../assets/Icons/CalendarIcons/arrowIcon.svg';

export default function MonthGrid(props: MonthGridProps) {
  const {
    selectedAnalyst, eventList, selectedYear, selectedMonth, publicHolidays,
    showWeekCalendar, selectedWeek,
  } = useSelector((state:RootState) => state.CalenderReducer);

  const {
    handlePopperClick, selectedEventInfo,
    popperVisibility, setPopperVisibility, handleEventClick,
    distanceFromBottom, setShowMonthView,
  } = props;

  const dispatch = useDispatch();

  const currentYear = useMemo(() => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }, []);

  const years: Entries[] = useMemo(() => {
    const arr = Array.from({ length: currentYear - 1999 }, (_, index) => index + 2000);

    return arr.map((ele) => ({
      Text: String(ele),
      Value: String(ele),
    }));
  }, []);

  const handleSelectedMonth = (value: string) => {
    dispatch(setSelectedMonth(value));
  };

  const monthsData = useMemo(() => {
    const events = eventList.find((data:AnalystDetails) => data.AnalystId === selectedAnalyst.Value)?.Events;
    if (events) {
      const payload:FormatMonthData = {
        selectedYear,
        selectedMonth,
        events,
        publicHolidays,
        selectedAnalyst,
      };

      return formatMonthData(payload);
    }
    return [];
  }, [selectedAnalyst, eventList]);

  const weekNameData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const handleBack = () => {
    setShowMonthView(false);
    const payload:GetAllEventsQuery = {
      month: showWeekCalendar ? '' : selectedMonth,
      year: selectedYear,
      week: showWeekCalendar ? selectedWeek : '',
      analystId: '',
    };
    dispatch(getAllEventsStart(payload));
    dispatch(setSelectedAnalyst({} as Entries));
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <div className="div_job_container_info customBodyWithoutBtnContainer">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <div className={styles.labellingContainer}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={3} xl={4} className={styles.monthCalendarAnalystNameContainer}>
                        <div className={styles.monthCalendarAnalystName}>{selectedAnalyst?.Text}</div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={8}>
                        <div className={`${styles.labellingBox} ${styles.backButtonMonthFilter}`}>
                          <div className={styles.calendarLabelling}>
                            Job Schedule
                            <span className={styles.jobScheduleColor} />
                          </div>
                          <div className={styles.calendarLabelling}>
                            Appointment
                            <span className={styles.appointmentColor} />
                          </div>
                          <div className={styles.calendarLabelling}>
                            Sick-Leave
                            <span className={styles.sickLeaveColor} />
                          </div>
                          <div className={styles.calendarLabelling}>
                            Vacation
                            <span className={styles.vacationColor} />
                          </div>
                          <Grid item xs={12} sm={12} md={2} lg={2} className={styles.customMonthCalendarFilterBox}>
                            <Select
                              size="small"
                              value={selectedMonth}
                              className={`${styles.customCalendarFilterSelect} ${styles.customMonthCalendarFilter}`}
                              onChange={(event: SelectChangeEvent<string | string>) => handleSelectedMonth(event.target.value)}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '220px',
                                    maxWidth: '220px',
                                  },
                                },
                              }}
                            >
                              {months.map((item: Entries) => (
                                <MenuItem className="menu-item-enter" value={item.Value} key={item.Value}>
                                  {item.Text}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid item xs={12} sm={12} md={2} lg={2} className={styles.customMonthCalendarFilterBox}>
                            {/* <label>
                              Year
                            </label> */}
                            <Select
                              size="small"
                              value={selectedYear}
                              className={`${styles.customCalendarFilterSelect} ${styles.customMonthCalendarFilter}`}
                              onChange={(event: SelectChangeEvent<string | string>) => dispatch(setSelectedYear(event.target.value))}
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: '220px', // Adjust the max height value as needed
                                    maxWidth: '220px',
                                  },
                                },
                              }}
                            >
                              {years.map((item: Entries) => (
                                <MenuItem className="menu-item-enter" value={item.Value} key={item.Value}>
                                  {item.Text}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <div onClick={handleBack}>
                            <img alt="arrowIcon" className={styles.arrowIconStyle} src={arrowIcon} />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className={styles.mobScrollMonthCalendar}>
                  <div className={`${styles.monthCalendarList} ${styles.monthCalendarSevenList} mt-3`}>
                    {weekNameData?.map((weekName) => (
                      <div
                        className={`${styles.monthCalendarBox} ${styles.noMonthBorder}
                        ${styles.monthCalendarBoxHeight} ${weekName === 'Sunday' ? styles.calendarRedTextColor : ''}`}
                      >
                        {weekName}
                      </div>
                    ))}
                  </div>
                  <div className={styles.monthCalendarList}>
                    {monthsData.map((data) => (
                      <div className={styles.monthCalendarSevenList}>
                        {data.map((item, index) => (
                          item.eventId > 0 ? (
                            <>
                              <CustomTooltip
                                content={`${gridClassname[item.eventId - 1]?.eventName}`}
                                TooltipBgColor="custom-tooltip-red"
                                TooltipBorderColor="#00A751"
                                isWeekFullWidth={false}
                                className={`${styles.monthCalendarBox} ${item.className}`}
                                onClick={() => handlePopperClick(item, String(selectedAnalyst?.Value))}
                              >
                                <div>
                                  {item.day}
                                  {' '}
                                  <img src={getCalendarIcon(item.eventId)} width={30} height={30} alt="Icons" />
                                </div>
                              </CustomTooltip>
                              {selectedEventInfo && selectedEventInfo?.uniqueId === item?.uniqueId && (
                              <CustomPopper
                                show={popperVisibility}
                                eventInfo={selectedEventInfo}
                                handleClose={() => setPopperVisibility(false)}
                                calenderDay={index}
                                monthView
                                distanceFromBottom={distanceFromBottom !== null ? distanceFromBottom : 0}
                              />
                              )}
                            </>
                          ) : (
                            <div
                              className={`${styles.monthCalendarBox}  ${item.className}`}
                              onClick={() => {
                                if (item.day === -1) return;
                                handleEventClick(String(selectedAnalyst?.Value), item.day);
                              }}
                            >
                              {item.day === -1 ? '' : item.day}
                            </div>
                          )
                        ))}
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
