export enum UnitInfoFormNames {
  safetyEqRequired = 'safetyEqRequired',
  safetyCharges = 'safetyCharges',
  testType = 'testType',
  odTubes = 'odTubes',
  machineType = 'machineType',
  location = 'location',
  manufacturer = 'manufacturer',
  model = 'model',
  type = 'type',
  style = 'style',
  serialNumber = 'serialNumber',
  PONumber = 'PONumber',
  sendReportTo = 'sendReportTo',
  leak = 'leak',
  outside = 'outside',
  jobTime = 'jobTime',
  retest = 'retest',
  previousJob = 'previousJob',
  date = 'date',
  spotTest = 'spotTest',
  pct = 'pct',
  eddyCurrentTest = 'ECT',
  fluxLeakagetest = 'FLT',
  absTest = 'ADT',
}

export enum SideUnitDetailsNames {
  tubeCharge = 'tubeCharge',
  calculatedDays = 'calculatedDays',
  fluxLeakageDays = 'fluxLeakageDays',
  fluxShipping = 'fluxShipping',
  absShipping = 'absShipping',
  safetyCharges = 'safetyCharges',
}

export enum VesselFormNames {
  estimatedTubeQty = 'estimatedTubeQty',
  providedTubeQty = 'providedTubeQty',
}

export const VIEW_REQUEST = 1;
export const ACCEPT_REQUEST = 2;
export const REJECT_REQUEST = 3;
export const ADMIN_REQUEST = 4;
