import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../Common/DataTable';
import { DataSheetsData } from './utils/types';
import { getDataSheet } from './redux/sliceDataSheet';
import { RootState } from '../../redux/rootState';
import { setLoader } from '../../redux/loaderSlice';
import { exportToExcelData } from '../../Common/PageHeader/helper';

function ScreenDataSheets() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const { DataSheet, isLoading, TotalRecords } = useSelector((state: RootState) => state.dataSheetReducer);

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getDataSheet(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const data: DataSheetsData[] = useMemo(() => {
    if (DataSheet?.length > 0) {
      const transformedData = DataSheet?.map((item, index) => ({
        sn: index,
        jobOrder: item?.JobOrder,
        action: 'edit',
        id: item?.JobOrder,
      }));
      return transformedData;
    }
    return [];
  }, [DataSheet]);

  const excelData = useMemo(() => DataSheet?.map((item) => {
    const transformedDataItem = {
      jobOrder: item?.JobOrder,
    };
    return transformedDataItem;
  }), [DataSheet]);

  const columns = useMemo(
    () => [
      // { key: 'sn', label: 'SN' },
      { key: 'action', label: 'Action' },
      { key: 'jobOrder', label: 'Job Order' },
    ],
    [],
  );

  const handleEdit = (id: string) => {
    Navigate(`${id}`);
  };

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: excelData,
      },
      'Data_sheet',
    );
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="Data Sheets" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<DataSheetsData>
          data={data}
          columns={columns}
          totalRecords={TotalRecords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleEdit={handleEdit}
        />
      </Grid>
    </Grid>
  );
}

export default ScreenDataSheets;
