import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function BarChartTable() {
  const { reportPdfResponse2 } = useSelector((state:RootState) => state.reportPdfSliceReducer);
  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Location
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Model
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Serial Number
        </td>
      </tr>
      <tr>
        <td className="textCenter">UNIVERSITY OF PENNSYLVANIA</td>
        <td className="textCenter">{reportPdfResponse2?.JobOrderData?.Model}</td>
        <td className="textCenter">{`S/N ${reportPdfResponse2?.JobOrderData?.SerialNumber}`}</td>
      </tr>
      <tr>
        <td className="textCenter p10" colSpan={3}>
          Note: A graph indicating the number of tubes marked for each category will be generated when tubes are marked.
          Indications from previous inspections will be displayed as cross hatch bars.
        </td>
      </tr>
    </table>
  );
}

export default BarChartTable;
