import React, { useCallback } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import styles from './ModalStyle.module.scss';
import { ComponentEquipmentReturnInterface, InventoryData } from '../../utils/types';
import { EquipmentReturnIcon } from '../../../../assets/Icons/InventoryIcons/EquipmentReturnIcon';
import { RootState } from '../../../../redux/rootState';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 497,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 5,
  '@media (max-width: 768px)': {
    width: '90%',
  },
};
export default function ComponentEquipmentReturn({
  show, setOpenFrom, onClickYes, rowId,
}: ComponentEquipmentReturnInterface): JSX.Element {
  const { inventoryData } = useSelector((state: RootState) => state.inventoryReducer);

  const EquipmentReturnText = useCallback(() => {
    const openedFormData = inventoryData?.Data?.find((row: InventoryData) => Number(row.ProductNumber) === Number(rowId));
    return `Return  Product ${rowId} From ${openedFormData?.AnalystName}?`;
  }, [rowId]);

  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.EquipmentReturnIcon}>
          <EquipmentReturnIcon />
        </div>
        <div className={styles.modalModalDescription}>{EquipmentReturnText()}</div>
        <div className={styles.divButtons}>
          <button
            type="button"
            className={`btn ${styles.yesBtn}`}
            onClick={() => {
              if (onClickYes) {
                onClickYes();
              }
            }}
          >
            Yes
          </button>
          <button type="button" className={`btn ${styles.noBtn}`} onClick={setOpenFrom}>No</button>
        </div>
      </Box>
    </Modal>
  );
}
