import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import { Card } from '@mui/material';
import { useDispatch } from 'react-redux';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import DataTable from '../../../Common/DataTable';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { GetSmcSitesListResult, SMCSubscribedSitesData } from '../Utils/TypeSmc';
import '../Style/StyleSmc.scss';
import { getSmcSitesListStart } from '../Redux/sliceAddSmc';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { DEFAULT_RECORD_COUNT, DEFAULT_ROW_PER_PAGE } from './constant';
import { Empty } from '../../Kits/FreonCylinder/AddFreonCylinder/utils/types';

interface TypeTableCell {
  filteredSubscribedSite: GetSmcSitesListResult[];
  setFilteredSubscribedSite:Empty;
}

export default function ComponentSubscribedSites({
  filteredSubscribedSite,
  setFilteredSubscribedSite,
}: TypeTableCell) {
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [siteTableData, setSiteTableData] = useState<Empty>([]);
  const [rowCount, setRowCount] = useState(DEFAULT_RECORD_COUNT);

  const dispatch = useDispatch();
  const handleData = () => {
    const params = {
      page,
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      smcId: 'SMC3',
    };
    if (params.smcId) {
      dispatch(getSmcSitesListStart(params));
    }
  };

  useEffect(() => {
    setSiteTableData(filteredSubscribedSite);
  }, [filteredSubscribedSite]);

  const handleLinkOff = (siteId: string) => {
    setShowModal(true);
    setSelectedSiteId(siteId);
  };
  const handleDeleteData = (siteId: string) => {
    const updatedData = siteTableData?.filter((entry: Empty) => entry.SiteId !== siteId);
    setFilteredSubscribedSite(updatedData);
    setSiteTableData(updatedData);
  };

  const handelModal = () => {
    handleDeleteData(selectedSiteId);
    setShowModal(false);
  };

  const columns = useMemo(
    () => [
      { key: 'sn', label: translations.sn },
      { key: 'action', label: translations.action },
      { key: 'site', label: translations.site },
      { key: 'address', label: translations.address },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
    ],
    [],
  );

  const data: Empty = siteTableData?.map((item: Empty, index: number) => {
    const transformedDataItem = {
      sn: index,
      action: 'linkOff',
      site: item?.SiteName,
      address: item?.Address,
      city: item?.City,
      state: item?.State,
      id: item?.SiteId,
    };
    return transformedDataItem;
  });

  const seeMoreClick = () => {
    setRowCount(rowCount + DEFAULT_RECORD_COUNT);
  };

  const seeLessClick = () => {
    setRowCount(rowCount - DEFAULT_RECORD_COUNT);
  };

  return (
    <Card>
      <div className="separate_section">
        <div className="customerInfo_container">
          <div className="customerInfo">
            {translations.subscribedSites}
          </div>
        </div>
        <DataTable<SMCSubscribedSitesData>
          data={data?.slice(0, rowCount) || []}
          columns={columns}
          totalRecords={50}
          page={page}
          setPage={setPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          handleLinkOff={handleLinkOff}

        />
      </div>
      <div className="alignArrow">
        {rowCount < data?.length && (
          <div onClick={seeMoreClick} className="customerSeeMore">
            <ArrowDownward />
          </div>
        )}
        {rowCount > DEFAULT_RECORD_COUNT && (
          <div onClick={seeLessClick} className="customerSeeMore">
            <ArrowUpward />
          </div>
        )}
      </div>
      <ComponentDeleteModal
        show={showModal}
        setOpenFrom={() => setShowModal(false)}
        handleDelete={handelModal}
      />
    </Card>
  );
}
