import { Grid, TextField } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import taiLogo from '../../../assets/tailogo.png';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import EndPoints from '../../../Routes/EndPoints';
import ComponentLeftVector from '../../../Common/LeftVector/ComponentLeftVector';
import { forgotValidationSchema } from '../../../utils/Schema/ValidationSchema';
import { FormTypes } from './utils/types';
import { initialValuesForgotPassword } from './utils/constantsForgotPassword';
import { OtpResetState, errorMessageState, sendForgotPasswordData } from './redux/sliceForgotPassword';
import { RootState } from '../../../redux/rootState';

function ScreenForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translations } = useContext(LocalizationContext);
  const {
    isLoading, isError, otpSent, errorMessage,
  } = useSelector((state: RootState) => state.forgotPasswordReducer);

  const handleBackToLogin = () => {
    navigate(EndPoints.LOGIN_PAGE);
  };

  const onSubmit = (values: FormTypes) => {
    const data = { email: values.email };
    dispatch(sendForgotPasswordData(data));
  };

  useEffect(() => {
    if (otpSent) {
      navigate(EndPoints.SCREEN_ACCOUNT_RESET);
      dispatch(OtpResetState());
    }
  }, [otpSent]);

  const {
    register, watch, handleSubmit, formState: { errors },
  } = useForm<FormTypes>({
    defaultValues: initialValuesForgotPassword,
    resolver: yupResolver(forgotValidationSchema(translations)),
  });

  const { email } = watch();

  useEffect(() => {
    dispatch(errorMessageState());
  }, [email]);

  return (
    <div className="div-password">
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <ComponentLeftVector />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <div className="mobStyle">
            <div className="rightContainer">
              <img src={taiLogo} alt="TAI Logo" className="taiLogo" />
              <div className="loginContainer">
                <h2>{translations.forgotPassword}</h2>
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="login_email_field">
                    <div className="div_label_Email">
                      <label className="labelStyle">{translations.email}</label>
                    </div>
                    <TextField
                      autoFocus
                      className="text_field_margin nonCapitalizeField"
                      fullWidth
                      size="small"
                      type="text"
                      placeholder="Enter your email ID"
                      inputProps={{ maxLength: 64 }}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...register('email')}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  </div>
                  <div className="errorMessage">{isError ? errorMessage : ''}</div>
                  <p className="verifyCodeLabel mt-3">{translations.enterYourEmail}</p>
                  <LoadingButton variant="contained" loading={isLoading} fullWidth className="button-color" type="submit">
                    {translations.sendCode}
                  </LoadingButton>
                  <div className="button_back_login">
                    <button type="button" className="backToLogIn" onClick={handleBackToLogin}>{translations.backLogin}</button>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ScreenForgotPassword;
