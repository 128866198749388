import { LangTypes } from './Types/langTypes';

const english: LangTypes = {
  signIn: 'Sign into your account',
  email: 'Email',
  password: 'Password',
  forgotPassword: 'Forgot Password',
  login: 'Login',
  enterYourEmail: 'Enter your email address above to get a verification code',
  sendCode: 'Send Verification Code',
  backLogin: 'Back to login',
  changedSuccessfully: 'Your password has been reset successfully !',
  otpVerify: 'OTP Verify',
  verificationCode: 'Verification Code sent to',
  change: 'Change',
  resendOtp: 'Resend OTP',
  verify: 'Verify',
  resetAccount: 'Reset your account',
  enterCode: 'Verification Code',
  createPassword: 'Password',
  passwordMustHave: 'Password must have',
  // atLeast: 'At least 1 upper-case and 1 lower-case letter minimum 8 and maximum 16 character',
  atLeast: `The password should be at least 8 characters long. The password should contain
  at least one uppercase letter, one lowercase letter, one special char and one number.`,
  submit: 'Submit',
  invalidEmailFormat: 'Invalid email format',
  emailRequired: 'Email required',
  passwordRequired: 'Password required',
  LowercaseCharacter: 'Must Contain One Lowercase Character',
  uppercaseCharacter: 'Must Contain One Uppercase Character',
  numberCharacter: 'Must Contain One Number Character',
  specialCaseCharacter: 'Must Contain  One Special Case Character',
  mustContainCharacters: 'Must Contain 8 Characters',
  maxCharacters: 'Password can not be more then 16 characters',
  otpRequired: 'OTP required',
  otpMustHave: 'OTP must have 6 characters',
  example: 'example',
  SMC: 'Safety Management Company ',
  Customer: 'Customer',
  firstName: 'First Name',
  lastName: 'Last Name',
  emailId: 'Email Id',
  address: 'Address',
  code: 'Code',
  phone: 'Phone',
  cell: 'Cell',
  editProfile: 'Edit Profile',
  cancel: 'Cancel',
  saveChange: 'Save Change',
  uploadNewImage: 'Upload New Image',
  sn: 'SN',
  action: 'Action',
  jobs: 'Jobs',
  site: 'Site',
  city: 'City',
  state: 'State',
  subscribedSites: 'Subscribed Sites',
  markup: 'Mark Up',
  CustHash: 'Cust#',
  Company: 'Company',
  subscribedCustomers: 'Subscribed Customers',
  close: 'Close',
  save: 'Save',
  addSmc: 'Add SMC',
  smcName: 'SMC Name',
  taiIdNumber: 'TAI ID Number',
  url: 'URL',
  addressOptional: 'Address(Optional)',
  addSite: 'Add Site',
  customer: 'Customer',
  addCustomer: 'Add Customer',
  SafetyManagementCompany: 'SAFETY MANAGEMENT COMPANY',
  date: 'Date',
  country: 'Country',
  zip: 'Zip',
  select: 'Select',
  siteSelector: 'Site Selector',
  users: 'Users',
  addUsers: 'Add Users',
  contact: 'Contact',
  smcNameRequired: 'SMC Name is Required',
  maxSmcNameLength: 'max 255 Required',
  companyAlphaNumeric: 'Company name should be alphanumeric',
  companyNot255: 'Company name should not exceed 255 characters',
  companyNot50: 'Company name should not exceed 50 characters',
  nameValidation: 'Name should not exceed 25 characters',
  phoneRequired: 'Phone number is required',
  phoneMatches: 'Phone number should only contain digits and special characters',
  not20: 'Phone number should not exceed 20 numbers',
  min6: 'Phone number should have 6 numbers',
  cellNot20: 'Cell number should not exceed 20 numbers',
  cellMin6: 'Cell number should have 6 numbers',
  addressRequired: 'Address is required',
  addressMatches: 'Address should be alphanumeric with special characters',
  AddressNot200: 'Address should not exceed 255 characters',
  addressOptionalMatches: 'Optional Address should be alphanumeric with special characters',
  invalidUrlFormat: 'Invalid URL format',
  urlNot100: 'URL should not exceed 100 characters',
  dropdownRequired: 'Dropdown field is required',
  name: 'Name',
  role: 'Role',
  requiredField: 'This field is Required',
  alphaNumeric: 'should be in Alpha numeric format',
  invalidEmail: 'Please enter a valid email address',
  cellMatches: 'Please enter a valid cell Number',
  employeeDetails: 'Employee Details',
  titleValidation: 'Title is required',
  firstNameValidation: 'First Name is required',
  contactCodeValidation: 'Contact Code is required',
  customerNameValidation: 'Customer Name is required',
  addressValidation: 'Address is required',
  zipValidation: 'Zip is required',
  stateValidation: 'State is required',
  cityValidation: 'City is required',
  countryValidation: 'Company Country is required',
  phoneValidation: 'Phone is required',
  wantLogout: 'Are you sure you want to Logout?',
  yesLogout: 'Yes, Logout!',
  editOilPrice: 'Edit Oil Kits Price',
  editPrice: 'Edit Price',
  price: 'Price',
  addMarkup: 'Add Markup',
  description: 'Description',
  percentage: 'Percentage',
  editMarkup: 'Edit Markup',
  priceListEdit: 'Price List Edit',
  minTubeQty: 'Min. Tube Qty.',
  markupError: 'Markup is required',
  initials: 'Initials',
  max2: 'Maximum 3 characters are allowed',
  shipDate: 'Ship Date',
  shipVia: 'Ship Via',
  blueCylinders: '# of Blue Cylinders',
  cylindersPriceEach: 'Cylinders Price (each)',
  billing: 'Billing',
  additionalAmount: 'Additional Amount',
  billAmount: 'Bill Amount',
  invoice: 'Invoice #',
  invoiceDate: 'Invoice Date',
  PO: 'PO #',
  ok: 'Ok',
  revenueAnalytics: 'Revenue Analytics',
  viewAll: 'View All',
  sales: 'Sales',
  revenue: 'Revenue',
  profit: 'Profit',
  numberOfOrder: 'Number of order receive for Freon Cylinder/Oil Kits',
  oilKits: 'Oil Kits',
  freonCylinders: 'Freon Cylinders',
  noCustomers: 'No of customers onboarded',
  noJobsCreated: 'No of jobs created',
  noJobsQueue: 'No of jobs in Queue',
  all: 'All',
  oneMonth: '1M',
  sixMonth: '6M',
  oneYear: '1Y',
  priceOilKits: 'Price (each)',
  additionalAmtOilKits: 'Additional Amt',
  billAmountOilKits: 'Bill Amount',
  invoiceOilKits: 'Invoice #',
  PDOilKits: 'PO #',
  customerOilKits: 'Customer',
  contactOilKits: 'Contact',
  shipDateOilKits: 'Ship Date',
  shipViaOilKits: 'Ship Via',
  kitsAssignedOilKits: 'Kits Assigned',
  KitOilKits: 'Kit #',
  toOilKits: 'To',
  viewOilKitOilKits: 'View Oil Kit',
  Ok: 'Ok',
  hashOilLits: '# of Kits',
  customerTag: 'Customer #',
  searchHere: 'Search Here..',
  kitsNo: 'kitsNo',
  delete: 'Delete',
  viewOilKIts: 'View Oil Kits',
  billingOilKits: 'Billing',
  analyst: 'Analyst',
  carrier: 'Carrier',
  teritory: 'Territory',
  hireDate: 'Hire Date',
  lsInitials: 'LS Initials',
  analystID: 'Analyst ID :',
  analystHeading: 'Analyst',
  bidPdfContent: `If this quote is accepted and work performed hereunder by TAI,
  TAI’s total liability in connection with the work is limited to
  the amount paid to TAI for the work. IN NO EVENT WILL TAI BE
  LIABLE FOR ANY INDIRECT OR CONSEQUENTIAL LOSS, DAMAGE,
  COST OR EXPENSE OF ANY KIND WHATEVER AND HOWEVER CAUSED,
  EVEN IF TAI HAS BEEN ADVISED OF THEIR POSSIBILITY.
  No action arising out of TAI’s work, regardless of
  the form of action, may be brought more than one year
  after the cause of action accrued. Exclusive jurisdiction
  and venue for any such action against TAI shall be
  in the Courts of Cobb County, Georgia.`,
  bidPdfSubContent: `THIS BID INCLUDES A WRITTEN REPORT PLUS ALL
  KNOWN EXPENSES AND IS VALID FOR A PERIOD OF THIRTY DAYS.`,
  thankYouText: 'THANK YOU !',
  JobCreated: 'Job Created',
  JobDone: 'Job Done',
  UnscheduledJobs: 'Unscheduled Jobs',
  ScheduledJobs: 'Scheduled Jobs',
  expenseReports: 'Expense Reports',
  Job_Order: 'Job Order',
};

export default english;
