import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PhaseChartTableColumns } from '../utils/helper';
import { PhaseListingInterface } from '../utils/type';
import DataTable from '../../../Common/DataTable';
import {
  deletePhaseChartStart, getPhaseChartStart, resetPhaseChartData,
} from '../redux/sliceVesselInformation';
import PhaseChart from '../commonModal/phaseChart/phaseChart';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import ComponentDeleteModal from '../../../Common/DeleteModal/ComponentDeleteModal';
import { ANALYST_ROLE_ID } from '../../../Routes/constant';
import localStorageConstants from '../../../utils/LocalStorageConstants';

function PhaseChartComponent() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [phaseId, setPhaseId] = useState('');
  const [showDeleteModal, setDeleteModal] = useState(false);
  const { jobOrderId, vesselId } = useParams();
  const navigate = useNavigate();
  const [showPhaseChartComponent, setShowPhaseChartComponent] = useState(false);
  const {
    phaseChartList, totalPhaseChart, isLoading,
  } = useSelector((state: RootState) => state.VesselInformationReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);

  const tableData: PhaseListingInterface[] = useMemo(() => {
    if (phaseChartList?.length > 0) {
      const transformedData = phaseChartList?.map((ele, index) => ({
        sn: index,
        action: 'edit&delete',
        chart: ele?.PhaseChartId,
        frequency: ele?.Frequency,
        material: ele?.MaterialName,
        id: String(ele?.Id),
      }));
      return transformedData;
    }
    return [];
  }, [phaseChartList]);

  const handleData = () => {
    const params = {
      jobOrder: jobOrderId,
      vesselId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(getPhaseChartStart(params));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleButtonClick = () => {
    dispatch(resetPhaseChartData());
    setPhaseId('');
    setShowPhaseChartComponent(true);
  };

  const handleEdit = (id: string) => {
    setPhaseId(id);
    setShowPhaseChartComponent(true);
  };

  const handleDelete = (id: string) => {
    setDeleteModal(true);
    setPhaseId(id);
  };

  const handleDeleteModal = () => {
    const payload = {
      jobOrder: jobOrderId || '',
      vesselId: vesselId || '',
      id: phaseId,
      page,
      rowsPerPage,
      searchQuery,
    };
    dispatch(deletePhaseChartStart(payload));
    setDeleteModal(false);
  };

  const handleCloseModel = () => {
    dispatch(resetPhaseChartData());
    setPhaseId('');
    setDeleteModal(false);
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleClosePhaseChart = () => {
    dispatch(resetPhaseChartData());
    setPhaseId('');
    setShowPhaseChartComponent(false);
  };

  return (
    <div className="div_container_CustomerInfo" id="edit_vessel_defect_search_row">
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
              {(roleId === ANALYST_ROLE_ID) ? (
                <DataTable<PhaseListingInterface>
                  data={tableData?.length > 0 ? tableData : []}
                  columns={PhaseChartTableColumns}
                  totalRecords={totalPhaseChart}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleData={handleData}
                />
              ) : (
                <DataTable<PhaseListingInterface>
                  data={tableData?.length > 0 ? tableData : []}
                  columns={PhaseChartTableColumns}
                  totalRecords={totalPhaseChart}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleData={handleData}
                  buttonText="New Chart"
                  handleButtonClick={handleButtonClick}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div">
            <div>
              <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {!(roleId === ANALYST_ROLE_ID) && (
        <>
          {showPhaseChartComponent && (
          <PhaseChart
            show={showPhaseChartComponent}
            setOpenFrom={handleClosePhaseChart}
            phaseId={phaseId}
            page={page}
            rowsPerPage={rowsPerPage}
            searchQuery={searchQuery}
          />
          )}
          <ComponentDeleteModal
            show={showDeleteModal}
            setOpenFrom={handleCloseModel}
            handleDelete={handleDeleteModal}
          />
        </>
      )}
    </div>
  );
}

export default PhaseChartComponent;
