import React, { useEffect } from 'react';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  selectedTabStyles, stylesTabIndicator, tabStyles, tabsStyles,
} from '../../../Common/TabsStyles';
import {
  ABS_DIFF, EDDY_CURRENT, FLUX, LABEL_ABS_DIFF, LABEL_EDDY_CURRENT, LABEL_FLUX, LABEL_RFEC, RFEC,
} from './constant';
import EddyCurrentComponent from './component/EddyCurrentComponent';
import FluxComponent from './component/FluxComponent';
import AbsDiffComponent from './component/AbsDiffComponent';
import RefcComponent from './component/RefcComponent';
import {
  getCalibrationProcedureData,
  setAbsDiffDescription, setEddyCurrentDescription,
  setFluxDescription,
  setRfecDescription, setSelectedTab,
} from './Redux/sliceCalibration';
import { RootState } from '../../../redux/rootState';
import {
  DEFAULT_ADT,
  DEFAULT_ECT, DEFAULT_FLT, DEFAULT_RFEC, DEFAULT_VALUE_CALIBRATION,
} from '../constant';
import { CalibrationProcedureApiData } from './Redux/Type';
import { setLoader } from '../../../redux/loaderSlice';

export default function ScreenCalibrationProcedure() {
  const dispatch = useDispatch();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedTab(newValue));
  };

  const { selectedTab, calibrationProcedureData, isLoading } = useSelector((state: RootState) => state.CalibrationProcedureReducer);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const htmlToEditorState = (html: string): EditorState => {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const getDescriptionByType = (type: string) => calibrationProcedureData?.find((ele: CalibrationProcedureApiData) => ele?.TestType === type);

  useEffect(() => {
    if (calibrationProcedureData?.length > 0) {
      const eddyDescription = getDescriptionByType(DEFAULT_ECT);
      if (eddyDescription?.Description) {
        const convertedEddyCurrent = htmlToEditorState(eddyDescription?.Description);
        dispatch(setEddyCurrentDescription(convertedEddyCurrent));
      }
      const fluxDescription = getDescriptionByType(DEFAULT_FLT);
      if (fluxDescription?.Description) {
        const convertedFlux = htmlToEditorState(fluxDescription?.Description);
        dispatch(setFluxDescription(convertedFlux));
      }
      const absDiffDescription = getDescriptionByType(DEFAULT_ADT);
      if (absDiffDescription?.Description) {
        const convertedAbsDiff = htmlToEditorState(absDiffDescription?.Description);
        dispatch(setAbsDiffDescription(convertedAbsDiff));
      }
      const rfecDescription = getDescriptionByType(DEFAULT_RFEC);
      if (rfecDescription?.Description) {
        const convertedRFEC = htmlToEditorState(rfecDescription?.Description);
        dispatch(setRfecDescription(convertedRFEC));
      }
    }
  }, [calibrationProcedureData]);

  useEffect(() => {
    dispatch(getCalibrationProcedureData(DEFAULT_VALUE_CALIBRATION));
  }, []);

  function showSelectedTabComponent(): JSX.Element {
    switch (selectedTab) {
      case EDDY_CURRENT:
        return <EddyCurrentComponent />;
      case FLUX:
        return <FluxComponent />;
      case ABS_DIFF:
        return <AbsDiffComponent />;
      default:
        return <RefcComponent />;
    }
  }

  return (
    <>
      <ComponentPageHeader subHeading="CALIBRATION PROCEDURE" />
      <div className="container_job">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          style={tabsStyles}
          TabIndicatorProps={stylesTabIndicator}
        >
          <Tab
            label={LABEL_EDDY_CURRENT}
            style={selectedTab === EDDY_CURRENT ? selectedTabStyles : tabStyles}
            value={EDDY_CURRENT}
          />
          <Tab
            label={LABEL_FLUX}
            style={selectedTab === FLUX ? selectedTabStyles : tabStyles}
            value={FLUX}
          />
          <Tab
            label={LABEL_ABS_DIFF}
            style={selectedTab === ABS_DIFF ? selectedTabStyles : tabStyles}
            value={ABS_DIFF}
          />
          <Tab
            label={LABEL_RFEC}
            style={selectedTab === RFEC ? selectedTabStyles : tabStyles}
            value={RFEC}
          />
        </Tabs>
      </div>
      <div className="div_job_container_info">
        {showSelectedTabComponent()}
      </div>
    </>

  );
}
