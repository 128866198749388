import React, { useMemo } from 'react';
import { VesselBaylengthGraphProps } from '../types';
import BayDetailsTable from './commonTableWithoutHeaderColumn';

function VesselBayLengthGraph({ bayDetails }:VesselBaylengthGraphProps) {
  const configuration = useMemo(() => {
    if (!bayDetails.IsOTE) {
      return bayDetails?.Configuration;
    }
    return [...bayDetails.Configuration].reverse();
  }, [bayDetails.IsOTE]);

  return (
    <>
      <div className="bayLengthHeader">
        <div className="pt30">
          {bayDetails.VesselName}
          {' '}
          (Length =
          {' '}
          {`${bayDetails.TubeLength}`}
          {' '}
          inches)
        </div>
        <div>S = Intermediate Support</div>
      </div>
      <div className="w100 mt10 noTableBreak">
        <div className="cylinder-label-box">
          <div className="label">{bayDetails.IsOTE ? 'Opp. Test End' : 'Test End'}</div>
          <div className="label">{bayDetails.IsOTE ? 'Test End' : 'Opp. Test End'}</div>
        </div>
        <div className="cylinder-container">
          {configuration.map((value, index) => (
            <div className="bay" style={{ width: `${(value / Number(bayDetails?.TubeLength)) * 100}%` }} key={`${value}-${index + 1}`}>
              {index === 0
              && <div className="left-arc" />}
              <div className="content">
                <p className="size fs14">
                  {value.toFixed(2)}
                  &quot;
                </p>
                <p className="fs14">
                  Bay
                  {index + 1}
                </p>
                <div className="arc" />
              </div>
              <div className="s-label fs14">{`S${index + 1}`}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="bayLengthTableBox w100 mt30 mb30">
        {bayDetails?.Configuration?.length > 0
        && (
        <div className="textCenter centerAlign">
          <BayDetailsTable data={bayDetails?.Configuration} />
        </div>
        )}
      </div>
    </>
  );
}

export default VesselBayLengthGraph;
