import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import DataTable from '../../../Common/DataTable';
import { exportToExcelData } from '../../../Common/PageHeader/helper';
import { InventoryManagementTableColumns } from '../utils/constants';
import { getUpcomingJobsStart } from '../InventoryLandingPage/redux/inventorySlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { UpcomingJobsEquipmentList } from '../utils/types';

export default function EquipmentNeededForJobs() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const { upcomingJobsData, isLoadingUpcomingJobs } = useSelector((state: RootState) => state.inventoryReducer);
  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    dispatch(getUpcomingJobsStart(payload));
  };

  useEffect(() => {
    dispatch(setLoader(isLoadingUpcomingJobs));
  }, [isLoadingUpcomingJobs]);

  const downloadExcel = () => {
    exportToExcelData(
      {
        data: upcomingJobsData?.Data,
      },
      'EQUIPMENT NEEDED FOR UPCOMING JOBS',
    );
  };

  const upcomingJobsListData: UpcomingJobsEquipmentList[] = useMemo(() => upcomingJobsData?.Data?.map((item) => {
    const transformedDataItems = {
      product: item?.ProductNumber,
      description: item?.Description,
      job: item?.JobNumber,
      startDate: item?.StartDate,
      endDate: item?.EndDate,
      analyst: item?.Analyst,
      id: item?.ProductNumber,
    };
    return transformedDataItems;
  }), [upcomingJobsData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <ComponentPageHeader subHeading="EQUIPMENT NEEDED FOR UPCOMING JOBS" downloadExcel={downloadExcel} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="dataTableCustomMargin">
        <DataTable<UpcomingJobsEquipmentList>
          data={upcomingJobsListData}
          columns={InventoryManagementTableColumns}
          totalRecords={upcomingJobsData?.TotalRecords}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
        />
      </Grid>
    </Grid>
  );
}
