/* eslint-disable max-len */
import React from 'react';

export function CloseIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.81927 7.99479L11.4943 4.31979C11.7276 4.08646 11.7276 3.73646 11.4943 3.50313C11.2609 3.26979 10.9109 3.26979 10.6776 3.50313L7.0026 7.17813L3.3276 3.50313C3.09427 3.26979 2.74427 3.26979 2.51094 3.50313C2.2776 3.73646 2.2776 4.08646 2.51094 4.31979L6.18594 7.99479L2.51094 11.6698C2.39427 11.7865 2.33594 11.9031 2.33594 12.0781C2.33594 12.4281 2.56927 12.6615 2.91927 12.6615C3.09427 12.6615 3.21094 12.6031 3.3276 12.4865L7.0026 8.81146L10.6776 12.4865C10.7943 12.6031 10.9109 12.6615 11.0859 12.6615C11.2609 12.6615 11.3776 12.6031 11.4943 12.4865C11.7276 12.2531 11.7276 11.9031 11.4943 11.6698L7.81927 7.99479Z" fill="#F06548" />
    </svg>
  );
}
