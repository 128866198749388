import React from 'react';
import './StyleInputCheckBox.scss';
import { TCustomCheckbox } from './TypeCustomComponent';

function Checkbox({
  label, id, name, checked, disable,
}: TCustomCheckbox) {
  return (
    <div className="container_checkmark_div">
      <label className="container_label" htmlFor={id}>{label}</label>
      <label className="container_checkmark">
        <input type="checkbox" id={id} name={name} value={label} defaultChecked={checked} disabled={disable} />
        <span className="checkmark" />
      </label>
    </div>
  );
}
export default Checkbox;

export function CheckboxCustom({ label }: TCustomCheckbox):JSX.Element {
  return (
    <div className="container_checkmark_div">
      <label className="container_checkmark">
        {label}
        <input type="checkbox" />
        <span className="checkmark-without-label" />
      </label>
    </div>
  );
}
