import {
  Button, Card, Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectSchema } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CustomCheckbox from '../../../Common/ComponentCustomCheckBox';
import ComponentPageHeader from '../../../Common/PageHeader/ComponentPageHeader';
import {
  checkboxSections, DefaultHazardAnalysisValues, HazardAnalysisFormSchema,
  SafetyChecklist,
  setHazardValues,
} from '../utils/helper';
import { TypesHazardAnalysisValues } from '../utils/types';
import styles from '../utils/styles.module.scss';
import { getHazardAnalysisById, updateHazardAnalysisStart } from '../redux/hazardAnalysisSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';
import { getRequestPayload } from '../utils/constants';
import RequiredFiled from '../../../Common/RequiredField';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';

function ComponentHazardForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobId } = useParams();

  const { hazardAnalysisById, isLoading } = useSelector((state: RootState) => state.hazardAnalysisReducer);

  const {
    control, formState: { errors }, handleSubmit, setValue, watch, trigger,
  } = useForm<TypesHazardAnalysisValues>({
    defaultValues: DefaultHazardAnalysisValues,
    resolver: yupResolver(HazardAnalysisFormSchema as ObjectSchema<TypesHazardAnalysisValues>),
  });

  const onSubmit = (values: TypesHazardAnalysisValues) => {
    const reqBody = getRequestPayload(values, hazardAnalysisById);
    dispatch(updateHazardAnalysisStart({ payload: reqBody, navigate }));
  };

  useEffect(() => {
    if (hazardAnalysisById) {
      setHazardValues(hazardAnalysisById, setValue);
    }
  }, [hazardAnalysisById]);

  useEffect(() => {
    if (jobId) {
      dispatch(getHazardAnalysisById({ id: jobId }));
    }
  }, [jobId]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const otherComment = watch(SafetyChecklist.OtherExplainInComments);

  useEffect(() => {
    if (otherComment === false) {
      setValue(SafetyChecklist.Comments, '');
      trigger(SafetyChecklist.Comments);
    }
  }, [otherComment]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <ComponentPageHeader subHeading="Hazard Analysis" />
      <div style={{ padding: '20px', height: '87vh', overflow: 'auto' }}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <div className="customerInfo_container">
                <div className="customerInfo" />
                <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px',
                  }}
                  >
                    <span className="customerID">
                      Site Name
                    </span>
                    <div className="job_input_div" style={{ width: '200px' }}>
                      <div className="job_input_style">{hazardAnalysisById.SiteName}</div>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px',
                  }}
                  >
                    <span className="customerID">
                      Job Order #
                    </span>
                    <div className="job_input_div" style={{ width: '100px' }}>
                      <div className="job_input_style">{hazardAnalysisById.JobOrder}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
          {checkboxSections.map(({ section, checkboxes }) => (
            <Grid item md={12} sm={12} xs={12} key={section}>
              <Card>
                <div className="customerInfo_container">
                  <div className="customerInfo">{section}</div>
                </div>
                <div className={`${styles.container_div} customerInfo_main_container`}>
                  {checkboxes.map(({ label, name, type }) => (
                    <div
                      key={name}
                      className="div_label_text"
                      style={{ padding: '6px' }}
                    >
                      {type !== 'textField' ? (
                        <CustomCheckbox
                          control={control}
                          errors={errors}
                          name={name}
                          label={<div style={{ fontSize: '12px' }}>{label}</div>}
                        />
                      )
                        : <div />}
                    </div>
                  ))}
                </div>
              </Card>
            </Grid>
          ))}
          {otherComment && (
          <Card className={styles.commentsCard}>
            <div className="otherCommentInputStyle">
              <label className={otherComment ? 'label_Style_job' : 'label_Disable'}>
                Comments
                {otherComment && <RequiredFiled />}
              </label>
              <ComponentTextInput
                control={control}
                type="text"
                className={styles.commentsText}
                name={SafetyChecklist.Comments}
                inputProps={{ maxLength: 500 }}
                id={SafetyChecklist.Comments}
                isDisable={!otherComment}
                errors={errors}
                multiline
                rowSize={9}
                size="small"
              />
            </div>
          </Card>
          )}
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button type="submit" className="button_save_and_next">Save</Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}

export default ComponentHazardForm;
