/* eslint-disable linebreak-style */
import humburger_menu_left from '../assets/Icons/humburger_menu_left.svg';
import humburger_close_icon from '../assets/Icons/humburger_close_icon.svg';
import addKitsIcon from '../assets/Icons/OilKitsIcons/addKitsIcon.svg';
import closeIcon from '../assets/Icons/OilKitsIcons/closeIcon.svg';
import selectedAddKitsIcon from '../assets/Icons/OilKitsIcons/selectedAddKitIcon.svg';
import bidEditIcon from '../assets/Icons/bidEditIcon.svg';
import crossIcon from '../assets/Icons/crossIcon.svg';
import nextArrowIcon from '../assets/Icons/nextArrowIcon.svg';
import beforeArrowIcon from '../assets/Icons/beforeArrowIcon.svg';
import nightJobIcon from '../assets/Icons/nightJobIcon.svg';
import equipemntIcon from '../assets/Icons/equipemntIcon.svg';
import smallJobIcon from '../assets/Icons/smallJobIcon.svg';
import fluxIcon from '../assets/Icons/fluxIcon.svg';
import jobIcon from '../assets/Icons/CalendarIcons/jobIcon.svg';
import leaveIcon from '../assets/Icons/CalendarIcons/leaveIcon.svg';

export const IconList = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  humburger_menu_left,
  humburger_close_icon,
  addKitsIcon,
  closeIcon,
  selectedAddKitsIcon,
  bidEditIcon,
  crossIcon,
  nextArrowIcon,
  beforeArrowIcon,
  nightJobIcon,
  equipemntIcon,
  smallJobIcon,
  fluxIcon,
  jobIcon,
  leaveIcon,
};
