import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootState';

function StripChartTable() {
  const { reportPdfResponse3 } = useSelector((state:RootState) => state.reportPdfSliceReducer);

  const CalibrationStripChartData = reportPdfResponse3?.Calibration_Table;

  return (
    <table className="bordered-table w100">
      <tr>
        <td className="tableGreenHeading textCenter boldText">
          Tube Type
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Material
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Nom Wall Thick
        </td>
        <td className="tableGreenHeading textCenter boldText">
          End Wall Thick
        </td>
        <td className="tableGreenHeading textCenter boldText">
          OD
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Test Type
        </td>
        <td className="tableGreenHeading textCenter boldText">
          Probe Diameter
        </td>
      </tr>
      <tr>
        <td className="textCenter">{CalibrationStripChartData?.TubeType}</td>
        <td className="textCenter">{CalibrationStripChartData?.Material}</td>
        <td className="textCenter">{CalibrationStripChartData?.NominalWallThickness}</td>
        <td className="textCenter">{CalibrationStripChartData?.EndWallThickness}</td>
        <td className="textCenter">{CalibrationStripChartData?.OuterDiameter}</td>
        <td className="textCenter">{CalibrationStripChartData?.TestType}</td>
        <td className="textCenter">{CalibrationStripChartData?.ProbeDiameter}</td>
      </tr>
    </table>
  );
}

export default StripChartTable;
