import React, { useEffect, useMemo } from 'react';
import { Button, Card, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import ComponentSelectInput from '../../../Common/ComponentSelectInput';
import RequiredFiled from '../../../utils/RequiredField';
import ComponentTextInput from '../../../Common/ComponentTextinput/ComponentTextInput';
import { CommonDateInput } from '../../../Common/CommonDateInput';
import { VesselInformationFields } from '../utils/type';
import { mainFormSchema } from '../utils/validation';
import { MainDefaultValue } from '../utils/constant';
import { RootState } from '../../../redux/rootState';
import { VesselInformationMainEnum } from '../utils/mainTypes.d';
import FirstComponent from './MainComponents/FirstComponent';
import { formElements } from '../utils/mainConstant';
import { getAnalystMasterStart } from '../../Job/ScreenBidWorkSheetJob/redux/bidWorksheetSlice';
import {
  getMaterialStart, getSupportStart, getTestEndStart, getTubeTypeStart, getVesselMainByIdStart, getVesselStart, updateVesselMainStart,
} from '../redux/VesselMainRedux/vesselMainSlice';
import { getPayload, setValues } from '../utils/helper/vesselmain';
import { setLoader } from '../../../redux/loaderSlice';
import localStorageConstants from '../../../utils/LocalStorageConstants';
import { ANALYST_ROLE_ID } from '../../../Routes/constant';
import { getJobOrderById, removeSelectedJobOrder } from '../../jobOrder/redux/sliceJobOrder';

function MainComponent() {
  const { jobOrderId, vesselId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control, handleSubmit, formState: { errors }, setValue, watch,
  } = useForm<VesselInformationFields>({
    resolver: yupResolver(mainFormSchema as ObjectSchema<VesselInformationFields>),
    defaultValues: MainDefaultValue as unknown as VesselInformationFields,
  });

  const { analystList } = useSelector((state: RootState) => state.bidsReducer);
  const { vesselMainData, isLoading } = useSelector((state: RootState) => state.VesselMainReducer);
  const { JobOrderMainData } = useSelector((state:RootState) => state.jobOrderReducer);
  const roleId = localStorage.getItem(localStorageConstants.ROLE_ID);
  const inspectionDate = watch(VesselInformationMainEnum.inspectionDate);

  const onSubmit = (data: VesselInformationFields) => {
    const payload = getPayload(data, vesselMainData);
    dispatch(updateVesselMainStart(payload));
  };

  useEffect(() => {
    dispatch(getTubeTypeStart());
    dispatch(getTestEndStart());
    dispatch(getMaterialStart());
    dispatch(getSupportStart());
    dispatch(getAnalystMasterStart());
    dispatch(getJobOrderById({ id: String(jobOrderId) }));

    return (() => {
      dispatch(removeSelectedJobOrder());
    });
  }, []);

  const startDate = useMemo(() => dayjs(JobOrderMainData.StartDate, 'MM/DD/YYYY'), [JobOrderMainData.StartDate]);

  const endDate = useMemo(() => dayjs(JobOrderMainData.EndDate, 'MM/DD/YYYY'), [JobOrderMainData.EndDate]);

  useEffect(() => {
    if (jobOrderId) {
      dispatch(getVesselStart({ id: jobOrderId }));
    }
  }, [jobOrderId]);

  useEffect(() => {
    if (vesselMainData) {
      setValues(vesselMainData, setValue);
    }
  }, [vesselMainData]);

  useEffect(() => {
    const payload = {
      jobId: jobOrderId || '',
      vesselId: vesselId || '',
    };
    dispatch(getVesselMainByIdStart(payload));
  }, []);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const tubeTextFields = useMemo(
    () => formElements({ roleId }),
    [roleId],
  );

  const secondComponent = () => (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          {tubeTextFields.map((item) => (
            <Grid item md={3} sm={6} xs={12} key={item.name}>
              <div className="div_label_text">
                <label className={item.disabled ? 'label_Disable' : 'label_Style_Customer'}>
                  {item.label}
                  {item.required && <RequiredFiled />}
                </label>
                <ComponentTextInput
                  control={control}
                  name={item.name}
                  id={item.name}
                  size="small"
                  isDisable={item.disabled}
                  errors={errors}
                  regEx={item.regEx}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Card>
  );

  const thirdComponent = () => (
    <Card>
      <div className="customerInfo_container">
        <div className="customerInfo">
          {' '}
        </div>
      </div>
      <div className="customerInfo_main_container">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Analyst
              </label>
              <ComponentSelectInput
                name={VesselInformationMainEnum.analyst}
                control={control}
                size="small"
                disabled={Boolean(String(roleId) === ANALYST_ROLE_ID)}
                entries={Array.isArray(analystList) ? analystList : []}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Inspection Date
                <RequiredFiled />
              </label>
              <CommonDateInput
                control={control}
                errors={errors}
                disabled={Boolean(String(roleId) === ANALYST_ROLE_ID)}
                name={VesselInformationMainEnum.inspectionDate}
                minDate={startDate}
                maxDate={endDate}
                format="DD/MM/YYYY"
              />
            </div>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <div className="div_label_text">
              <label className={String(roleId) === ANALYST_ROLE_ID ? 'label_Disable' : 'label_Style_Customer'}>
                Retest Date
                <RequiredFiled />
              </label>
              <CommonDateInput
                control={control}
                errors={errors}
                minDate={startDate.isBefore(inspectionDate) ? inspectionDate : startDate}
                maxDate={endDate}
                disabled={Boolean(String(roleId) === ANALYST_ROLE_ID)}
                name={VesselInformationMainEnum.retestDate}
                format="DD/MM/YYYY"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
  return (
    <div className="div_container_CustomerInfo">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <FirstComponent control={control} errors={errors} jobOrderId={jobOrderId || ''} watch={watch} setValue={setValue} />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {secondComponent()}
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {thirdComponent()}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <div className="save_and_next_div">
              <div>
                <Button className="button_cancel" onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div className="button_margin_left">
                <Button
                  type="submit"
                  disabled={Boolean(String(roleId) === ANALYST_ROLE_ID)}
                  className="button_save_and_next"
                >
                  Save
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default MainComponent;
