import React, { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../../Common/DataTable';
import { LocalizationContext } from '../../../../Common/Localization/LocalizationProvider';
import { RootState } from '../../../../redux/rootState';
import { Empty, PayloadTypeUnlinkSmcSite, SMCSubscribedSiteData } from '../../Utils/TypeSmc';
import { getSmcSitesListStart, unlinkSmcSites } from '../../Redux/sliceAddSmc';
import UnlinkComponentModal from '../../../../Common/UnlinkComponentModal/UnlinkComponentModal';

function SubscribedSites() {
  const { smcId, totalSubscribedSites, smcSitesList } = useSelector((state: RootState) => state?.addSmcDetailsReducer);
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleData = () => {
    const params = {
      page,
      rowsPerPage,
      smcId: String(smcId),
      searchQuery,
    };
    if (params.smcId) {
      dispatch(getSmcSitesListStart(params));
    }
  };

  const columns = useMemo(
    () => [
      // { key: 'sn', label: translations.sn },
      { key: 'action', label: translations.action },
      { key: 'siteName', label: translations.Company },
      { key: 'address', label: translations.address },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
    ],
    [],
  );

  const handleLinkOff = (CustomerId: string) => {
    setShowModal(true);
    setSelectedCustomerId(CustomerId);
  };

  const handleDeleteData = () => {
    const payload:PayloadTypeUnlinkSmcSite = {
      SmcId: smcId,
      SiteId: selectedCustomerId,
      page,
      rowsPerPage,
      searchQuery,
    };
    setShowModal(false);
    dispatch(unlinkSmcSites(payload));
  };

  function formatTableData(data: Empty) {
    const res: Empty = [];

    data.forEach((col: Empty, index: number) => {
      const obj = {
        sn: index,
        action: 'linkOff',
        siteName: '',
        address: '',
        city: '',
        state: '',
        id: '',
      };

      obj.siteName = col.SiteName;
      obj.address = col.Address;
      obj.city = col.City;
      obj.state = col.State;
      obj.id = col.SiteId;

      res.push(obj);
    });

    return res;
  }

  return (
    <>
      <DataTable<SMCSubscribedSiteData>
        data={smcSitesList?.length > 0 ? formatTableData(smcSitesList) : []}
        columns={columns}
        totalRecords={totalSubscribedSites}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleData={handleData}
        handleLinkOff={handleLinkOff}
      />

      <UnlinkComponentModal
        show={showModal}
        setOpenFrom={() => setShowModal(false)}
        handleUnlink={handleDeleteData}
      />
    </>

  );
}

export default SubscribedSites;
