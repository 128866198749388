import * as Yup from 'yup';
import { LangTypes } from '../../../assets/lang/Types/langTypes';

export const editProfileValidationSchema = (translations: LangTypes) => Yup.object().shape({
  firstName: Yup
    .string()
    .max(25, translations.nameValidation),
  lastName: Yup
    .string()
    .max(25, translations.nameValidation),
  email: Yup.string(),
  phone: Yup
    .string(),
  cell: Yup
    .string(),
  address: Yup
    .string(),
  code: Yup
    .string(),
  title: Yup
    .string(),
  initials: Yup
    .string(),
});
