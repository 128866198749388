import { Grid, Card, TextField } from '@mui/material';
import React from 'react';

export default function ComponentFlightInfo() {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Flight Out
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Day / Date</label>
                  <TextField size="small" />
                </div>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Airline</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Lv /Time</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Send Report To</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">$ / Seat</label>
                  <TextField size="small" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Flight In
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Day / Date</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Airline</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Lv /Time</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Send Report To</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">$ / Seat</label>
                  <TextField size="small" />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <div className="customerInfo_container">
            <div className="customerInfo">
              Car Rental
            </div>
          </div>
          <div className="customerInfo_main_container">
            <Grid container spacing={2}>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Company</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Phone</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Price</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={3} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Confirmation #</label>
                  <TextField size="small" />
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className="div_label_text">
                  <label className="label_Style_job">Instructions</label>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={3}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}
