import React, {
  useMemo, useContext, useState, useEffect,
} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Cancel } from '../../../media/images/Cancel';
import { LocalizationContext } from '../../../Common/Localization/LocalizationProvider';
import { TypeSiteSelectorModal } from '../Utils/TypeSmc';
import DataTable from '../../../Common/DataTable';
import '../Style/StyleSmc.scss';
import { getAssociatedSitesStart, getGeneralSitesStart } from '../../Job/jobStarter/addJob/redux/addJobSlice';
import { RootState } from '../../../redux/rootState';
import { SitesData } from '../../Job/jobStarter/addJob/components/customerSiteInfo/addSiteModal/utils/Type';
import { formatSitesTableData } from '../../Job/jobStarter/addJob/components/customerSiteInfo/addSiteModal/utils/helper';
import { setSelectedSiteId } from '../Redux/sliceAddSmc';
import { AddSmcFormValue } from '../Utils/ConstantSmc';
import { Empty } from '../../Kits/FreonCylinder/AddFreonCylinder/utils/types';
import { debugLog } from '../../../Common/Logger';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 2,
  '@media (max-width: 768px)': {
    width: '95%',
    maxHeight: '90vh',
    padding: '6px',
  },
};

export default function ComponentSiteSelectorModal({
  show, setOpenFrom, watchCustomer, setValue, filteredSubscribedSite, customerId,
}: TypeSiteSelectorModal): JSX.Element {
  const { translations } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState('');
  const [data, setData] = useState<SitesData[] | []>([]);
  const [totalData, setTotalData] = useState<number>();

  const {
    generalSites, associatedSites, totalgeneralSites, totalAssociatedSites,
  } = useSelector((state: RootState) => state.jobStarterReducer);
  debugLog(showDeleteModal, 'showDeleteModal');
  const handleData = () => {
    const payload = {
      page,
      searchQuery,
      rowsPerPage,
    };
    if (watchCustomer) {
      return dispatch(getAssociatedSitesStart({ ...payload, customerId }));
    }
    return dispatch(getGeneralSitesStart(payload));
  };

  useEffect(() => {
    handleData();
  }, []);

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      { key: 'select', label: 'Select' },
      { key: 'site', label: translations.site },
      { key: 'address', label: translations.address },
      { key: 'country', label: translations.country },
      { key: 'city', label: translations.city },
      { key: 'state', label: translations.state },
    ],
    [],
  );
  function removeDuplicatesBySmallArray(bigArray: Empty, smallArray: Empty) {
    if (smallArray?.length === 0) {
      return bigArray;
    }
    const smallCustidSet = new Set(smallArray?.map((obj: Empty) => obj?.SiteId));
    const resultArray: Empty = [];

    bigArray?.forEach((obj: Empty) => {
      if (!smallCustidSet.has(obj.SiteId)) {
        resultArray.push(obj);
      }
    });
    return resultArray;
  }

  useEffect(() => {
    if (watchCustomer) {
      const associatedfilteredData = removeDuplicatesBySmallArray(associatedSites, filteredSubscribedSite);
      setTotalData(totalAssociatedSites);
      return setData(formatSitesTableData(associatedfilteredData));
    }
    const generalfilteredData = removeDuplicatesBySmallArray(generalSites, filteredSubscribedSite);
    setTotalData(totalgeneralSites);
    return setData(formatSitesTableData(generalfilteredData));
  }, [associatedSites, generalSites, filteredSubscribedSite]);

  const handleSave = () => {
    const filterWithId = data?.filter((ele: Empty) => ele.SiteId !== selectedRadio);
    dispatch(setSelectedSiteId(selectedRadio));
    setOpenFrom();
    setValue(AddSmcFormValue.TEMP_SMC_SITE, '');
    setData(filterWithId);
  };
  return (
    <Modal
      open={show}
      onClose={setOpenFrom}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="customerInfo_container mob-header-inline">
          <div className="customerInfo">
            {translations.siteSelector}
          </div>
          <div className="cancel-icon" onClick={setOpenFrom}>
            <Cancel />
          </div>

        </div>
        <DataTable<SitesData>
          data={data}
          columns={columns}
          totalRecords={Number(totalData)}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleData={handleData}
          radioButtonColumns={['select']}
          selectedRadio={selectedRadio}
          setSelectedRadio={setSelectedRadio}
          handleDelete={handleDelete}
        />
        {
          data?.length > 0 && (
            <Grid item md={12} sm={12} xs={12}>
              <div className="save_and_next_div">
                <div>
                  <Button className="button_cancel" onClick={setOpenFrom}>{translations.cancel}</Button>
                </div>
                <div className="button_margin_left">
                  {/* Pass Id Of The Selected Customer In The Handle Change */}
                  <Button className="button_save_and_next" onClick={handleSave}>{translations.select}</Button>
                </div>
              </div>
            </Grid>
          )
        }
      </Box>
    </Modal>
  );
}
