import {
  Button, Dialog, DialogContent, DialogTitle, IconButton, Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import React, {
  forwardRef, useRef, DragEvent, useState, ChangeEvent,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadIcon from '../../../assets/UploadFile.svg';
import style from '../utils/expenseStyle.module.scss';
import { TypesReceiptsDialogBox } from '../utils/types';
import { showErrorToaster, showSuccessToaster } from '../../../Common/ComponentToast/ComponentSuccessToasts';
import { AddScannedReceiptsImage } from '../redux/expenseReportSlice';
import { RootState } from '../../../redux/rootState';
import { setLoader } from '../../../redux/loaderSlice';

const Transition = forwardRef((
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) => <Zoom ref={ref} {...props} />);

function ComponentAddReceipt({
  show, setOpenFrom, jobOrderId, page, rowsPerPage,
}: TypesReceiptsDialogBox) {
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [payloadData, setPayloadData] = useState<FormData | null>(null);
  const { isLoading } = useSelector((state: RootState) => state.expenseReportReducer);

  const dispatch = useDispatch();

  const handleClose = () => {
    setPayloadData(null);
    setOpenFrom();
  };

  function handleFile(
    files: FileList | File[],
    event: React.ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>,
  ) {
    event.preventDefault();

    if (files) {
      const fileList = files instanceof FileList ? Array.from(files) : files;
      if (fileList.length > 0) {
        const newFile = fileList[0];
        const fileSizeInBytes = newFile.size;
        const maxSizeInBytes = 1 * 1024 * 1024; // 1mb
        const allowedFormats = ['image/jpeg', 'image/png'];

        if (fileSizeInBytes > maxSizeInBytes) {
          showErrorToaster('File size exceeds the maximum limit 1 MB');
        } else if (!allowedFormats.includes(newFile.type)) {
          showErrorToaster('Only JPG and PNG formats are allowed');
        } else {
          const formData = new FormData();
          formData.append('ImageFile', newFile);
          formData.append('JobOrder', jobOrderId || '');
          formData.append('page', String(page));
          formData.append('rowsPerPage', String(rowsPerPage));
          showSuccessToaster('File selected successfully');
          setPayloadData(formData);
        }
      }
    }
  }

  // ref
  const inputRef = useRef<HTMLInputElement>(null);

  // handle drag events
  const handleDrag = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files, e);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files, e);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleSave = () => {
    if (payloadData) {
      dispatch(AddScannedReceiptsImage(payloadData));
      handleClose();
    }
  };

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        className: 'paperStyle',
      }}
      open={show}
      transitionDuration={600}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle className="modalHeaderTitle">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Scanned Receipts
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '16px 24px' }}>
        <div>
          <input ref={inputRef} type="file" accept="image/png, image/jpeg" className={style.input_file_upload} onChange={handleChange} />
          <label className={style.label_file_upload}>
            <img src={UploadIcon} alt="Icon" />
            <p className={style.drag_drop_text}>Drag & Drop your file here OR</p>
            <button type="button" className={style.upload_button} onClick={onButtonClick}>
              Browser File
            </button>
          </label>
          {dragActive && (
            <div
              className={style.drag_file_element}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
          )}
        </div>
        <div className={style.save_cancel}>
          <div>
            <Button className="button_cancel" onClick={handleClose}>Cancel</Button>
          </div>
          <div className="button_margin_left">
            <Button type="submit" className="button_save_and_next" onClick={handleSave} disabled={!payloadData}>Save</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ComponentAddReceipt;
