import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../Common/DataTable';
// import { DueListDataTypes } from '../utils/types';
// import ComponentSendEmailModel from './ComponentSendEmailModel';
// import { getCustomerContactList } from '../../oilReport/redux/oilReportSlice';
import { RootState } from '../../../redux/rootState';
// import { setLoader } from '../../../redux/loaderSlice';
import { DueListDataTypes } from '../utils/types';
import { debugLog } from '../../../Common/Logger';
import ComponentInspectionLetterEmailModal from './ComponentInspectionLetterEmailModal';
import { getDueListDataStart, updateStatusForPasDueStart } from '../redux/sliceInspectionLetter';
import { setLoader } from '../../../redux/loaderSlice';

function InspectionLetterTableComponent() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState<DueListDataTypes[]>([]);
  const [showInspectionLetterEmailModal, setShowInspectionLetterEmailModal] = useState(false);
  const {
    selectedTab, filersData, dueListData, isLoading,
  } = useSelector((state: RootState) => state.inspectionLetterReducer);

  const columns = useMemo(
    () => {
      const baseColumns = [
        { key: 'sn', label: '#' },
        { key: 'site', label: 'Site' },
        { key: 'location', label: 'Location' },
        { key: 'vessel', label: 'Vessel' },
        { key: 'model', label: 'Model' },
        { key: 'serial', label: 'Serial #' },
        { key: 'lastTested', label: 'Last-Tested' },
        { key: 'reTestDate', label: 'Re-Test Date' },
      ];

      if (selectedTab !== 2 && selectedTab !== 3) {
        baseColumns.splice(1, 0, { key: 'action', label: 'Action' });
      }

      return baseColumns;
    },
    [selectedTab],
  );

  const tableData: DueListDataTypes[] = useMemo(() => {
    if (dueListData?.ReInspections?.length > 0) {
      const transformedData = dueListData?.ReInspections?.map((ele, index) => ({
        sn: index,
        action: 'close',
        site: ele.SiteName,
        location: ele.Location,
        vessel: ele.VesselName,
        model: ele.Model,
        serial: ele.SerialNumber,
        lastTested: ele.LastTested,
        reTestDate: ele.ReTestDate,
        id: `${String(ele.JobOrder)}_${ele.VesselId}`,
      }));
      return transformedData;
    }
    return [];
  }, [dueListData]);

  useEffect(() => {
    if (dueListData) {
      setData(tableData);
    }
  }, [dueListData]);

  useEffect(() => {
    dispatch(setLoader(isLoading));
  }, [isLoading]);

  const handleButtonClick = () => {
    const jobIds: string[] = [];
    data?.forEach((element) => {
      if (element?.isChecked) {
        jobIds.push(element?.id);
      }
    });
    const payload = {
      selectedIds: jobIds,
    };
    debugLog(payload);
    // const emailString = payload.selectedIds.join(', ');
    // setEmailsData(emailString);
    // setOpen(true);
  };

  const handleData = () => {
    const payload = {
      customerId: filersData.customerId || '',
      siteId: filersData.siteId || '',
      fromDate: filersData.fromDate || '',
      toDate: filersData.toDate || '',
      page,
      rowsPerPage,
      searchQuery,
      type: selectedTab,
    };
    dispatch(getDueListDataStart(payload));
  };

  useEffect(() => {
    handleData();
  }, [selectedTab, filersData]);

  const handleCheckBoxWithKey = (id: string) => {
    const updatedData = data?.map((element: DueListDataTypes) => {
      if (element?.id === id) {
        return {
          ...element,
          isChecked: !element?.isChecked,
        };
      }
      return element;
    });
    setData(updatedData);
  };

  //   useEffect(() => {
  //     dispatch(setLoader(isContactLoading));
  //   }, [isContactLoading]);

  const jobSelected = useMemo(() => data?.every((ele) => !ele?.isChecked), [data]);

  const handleEmail = () => {
    setShowInspectionLetterEmailModal(!showInspectionLetterEmailModal);
  };

  const handleClose = (id: string) => {
    const ids = id.split('_');
    const payload = {
      jobOrderId: ids[0],
      vesselId: ids[1],
    };
    debugLog(id);
    dispatch(updateStatusForPasDueStart({ payload, callback: handleData }));
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <DataTable<DueListDataTypes>
            data={data}
            columns={columns}
            totalRecords={dueListData?.TotalRecord}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            checkBoxWithKey={['sn']}
            handleCheckBoxWithKey={handleCheckBoxWithKey}
            handleData={handleData}
            filter
            handleEmail={handleEmail}
            handleClose={handleClose}
            // customClassName="ReportTableListStyle"
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className="save_and_next_div" style={{ marginTop: '20px' }}>
            <div className="button_margin_left">
              <Button
                type="button"
                disabled={jobSelected}
                onClick={() => handleButtonClick()}
                className="button_save_and_next"
              >
                Save & Next
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {showInspectionLetterEmailModal && (
        <ComponentInspectionLetterEmailModal
          show={showInspectionLetterEmailModal}
          setOpenFrom={() => setShowInspectionLetterEmailModal(false)}
          // onClickPreview={onClickPreview}
          // customerId={getCustomerId}
          reportNumber=""
        />
      )}
    </>
  );
}

export default InspectionLetterTableComponent;
